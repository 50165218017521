import React, { useState, useEffect } from 'react'

import '../css/OptimizeSetupFile.css'

import GetFile from '../services/getFile'
import { baseColors, recommendColors } from '../services/plotColors'

import { utils } from 'xlsx'

import { useTranslation } from 'react-i18next'
import Setup from '../pages/Setup'

const OptimizeSetupFile = (props) => {
    const { t } = useTranslation(['core'])
    
    const [ workbook, setWorkbook ] = useState()
    const [ activeTab, setActiveTab ] = useState('Vehicle_data')

    const VEHICLE_ID = props.vehicleId
    const TRACK_ID = props.trackId

    const setup = props.setup
    const vehicleItems = props.vehicleItems

    useEffect(() => {
        const setupItems = []
        setup.setup_items.forEach(item => {
            const vehicleItem = vehicleItems.find(vehicleItem => vehicleItem.id === item.vehicle_item_mapping_id)
            const setupItem = {
                Parameter: vehicleItem.item.name,
                Constrain: vehicleItem.is_constrain_default ? 'ON' : '-',
                Base: isNaN(item.value) ? item.value : Number(Number(item.value).toFixed(3)),
                VehicleItemMappingId: item.vehicle_item_mapping_id,
                SetupItemId: item.id
            }
            setupItems.push(setupItem)
        })

        const worksheet = utils.json_to_sheet(setupItems)
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, 'Vehicle_data')
        setWorkbook(workbook)
    }, [VEHICLE_ID, TRACK_ID])

    useEffect(() => {
        if (workbook === undefined) return
        displaySetupFile(workbook)
    }, [workbook])

    useEffect(() => {
        if (!props.df_showSetup || !props.df_showSetup.columns.includes('Recommend')) return
        addRecommendColumn()
    }, [props.df_showSetup])

    const copyHandler = () => {
        copyPasteMode()

        selectTableContents()
        let textToCopy = document.getSelection().toString()
        textToCopy = textToCopy[0] === '\n' ? textToCopy.slice(1) : textToCopy
        navigator.clipboard.writeText(textToCopy)

        const sel = window.getSelection()
        sel.removeAllRanges()
        document.getElementById('cloned-table').remove()

        viewMode()

        function selectTableContents() {
            const activeTable = document.querySelector('.setup-tab-container.active table')
            const tableElement = document.getElementById(activeTable.id)

            const clonedTable = tableElement.cloneNode(true)
            clonedTable.id = 'cloned-table'

            const targetColumnName = 'Recommend'
            let recommendColumnIndex = []

            for (let i = 0; i < clonedTable.rows[0].cells.length; i++) {
                if (clonedTable.rows[0].cells[i].textContent.trim().includes(targetColumnName)) {
                    recommendColumnIndex.push(i)
                }

                clonedTable.rows[0].cells[i].textContent = clonedTable.rows[0].cells[i].textContent.split('　✖')[0]
            }

            if (recommendColumnIndex.length > 0) {
                recommendColumnIndex = recommendColumnIndex.sort((a, b) => b - a)
                for (let i = 0; i < clonedTable.rows.length; i++) {
                    recommendColumnIndex.forEach(index => {
                        clonedTable.rows[i].deleteCell(index)
                    })
                }
            }

            const range = document.createRange()
            const sel = window.getSelection()
            sel.removeAllRanges()

            tableElement.parentElement.appendChild(clonedTable)

            try {
                range.selectNodeContents(clonedTable)
                sel.addRange(range)
            } catch (e) {
                range.selectNode(clonedTable)
                sel.addRange(range)
            }
        }
    }

    const pasteHandler = () => {
        copyPasteMode()

        paste()

        viewMode()

        async function paste() {
            const activeTable = document.querySelector('.setup-tab-container.active table')
            const tableElement = document.getElementById(activeTable.id)

            const pasteData = await navigator.clipboard.readText()
            const pasteDataRows = pasteData.split('\r\n')

            if (pasteDataRows[pasteDataRows.length - 1] === '') pasteDataRows.pop()

            const pasteDataCellContents = pasteContentToArray(pasteDataRows)

            const parameterIndex = pasteDataCellContents[0].indexOf('Parameter')
            const constrainIndex = pasteDataCellContents[0].indexOf('Constrain')
            const baseIndex = pasteDataCellContents[0].findIndex(h => h.includes('Base'))

            if (parameterIndex === -1 || constrainIndex === -1 || baseIndex === -1) {
                return alert('The copied data does must have a Parameter, Constrain, and at least one Base. Please copy and paste again.')
            }

            const constrainOptions = ['-', 'ON', 'env']
            let baseOptions = {}

            if ((VEHICLE_ID === 'SuperFormula' && TRACK_ID === 'Suzuka') || (VEHICLE_ID === 'Gr86' && TRACK_ID === 'Burirum')) {
                baseOptions = await GetFile(`data/setup_options/setup_options_${VEHICLE_ID}_${TRACK_ID}.json`)
            }

            tableElement.innerHTML = ''

            pasteDataCellContents.forEach((pasteDataRow, rowIndex) => {
                const tr = document.createElement('tr')
                tableElement.appendChild(tr)

                const isHeader = rowIndex === 0

                pasteDataRow.forEach((pasteDataColumn, columnIndex) => {
                    const tdTh = document.createElement(isHeader ? 'th' : 'td')
                    if (!isHeader && columnIndex === constrainIndex) {
                        const select = createSelect(constrainOptions)
                        select.value = pasteDataColumn
                        tdTh.appendChild(select)
                    } else if (!isHeader && columnIndex !== 0 && baseOptions[pasteDataRow[0]]) {
                        const select = createSelect(baseOptions[pasteDataRow[0]])
                        select.value = pasteDataColumn
                        tdTh.appendChild(select)
                    } else {
                        tdTh.innerText = pasteDataColumn
                        const setColumns = ['Parameter', 'Constrain', 'Base']
                        if (isHeader && !setColumns.includes(pasteDataColumn)) {
                            tdTh.classList.add('th-base-header')

                            const deleteButton = document.createElement('span')
                            deleteButton.classList.add('delete-button')
                            deleteButton.innerText = '　✖'

                            deleteButton.addEventListener('click', removeColumnHandler)
                            tdTh.appendChild(deleteButton)
                        }
                        tdTh.contentEditable = isHeader || columnIndex === 0 ? false : true
                    }
                    tdTh.dataset.row = rowIndex
                    tdTh.dataset.column = columnIndex
                    tr.appendChild(tdTh)
                })
            })
        }

        function pasteContentToArray(pasteDataRows) {
            const pasteDataCellContents = []

            pasteDataRows.forEach(pasteDataRow => {
                const pasteRow = []
                const pasteDataColumns = pasteDataRow.split('\t')
                pasteDataColumns.forEach(pasteDataColumn => {
                    pasteRow.push(pasteDataColumn)
                })

                if (pasteRow[0] !== '') pasteDataCellContents.push(pasteRow)
            })

            return pasteDataCellContents
        }
    }

    const addRecommendColumn = () => {
        const df_showSetup = props.df_showSetup

        const tr = document.querySelector(`#vehicle-data-table th[data-row='0']`).parentElement

        const thRecommendHeader = tr.querySelector('.th-recommend-header')
        if (thRecommendHeader) {
            thRecommendHeader.classList.remove('th-recommend-header')
            tr.querySelectorAll('.th-recommend').forEach((th, i) => {
                th.innerText = `Recommend_${i}`
                th.classList.add(`th-header-${recommendColors[(i) % recommendColors.length].slice(1)}`)

                const deleteButton = document.createElement('span')
                deleteButton.classList.add('delete-button')
                deleteButton.innerText = '　✖'

                deleteButton.addEventListener('click', removeColumnHandler)
                th.appendChild(deleteButton)
            })
        }

        const th = document.createElement('th')
        th.classList.add('th-recommend-header')
        th.classList.add('th-recommend')
        th.innerText = 'Recommend'
        th.dataset.row = 0
        th.dataset.column = tr.children.length
        tr.appendChild(th)

        df_showSetup.values.forEach((row, rowIndex) => {
            const tr = document.querySelector(`#vehicle-data-table td[data-row='${rowIndex + 1}']`).parentElement
            const td = document.createElement('td')
            td.classList.add('td-recommend')
            td.innerText = row[0]
            td.dataset.row = rowIndex + 1
            td.dataset.column = tr.children.length
            tr.appendChild(td)
        })
    }

    // TODO: improve code
    async function displaySetupFile(workbook) {   
        const tabButtons = document.getElementById('sheet-tabs-button-container')
        const tabContainers = document.getElementById('sheet-tabs-container')

        workbook.SheetNames.forEach(async (sheetName, index) => {
            if (sheetName === 'Combo_name') return
            const tabButton = document.createElement('div')
            tabButton.classList.add('tab-button')
            if (index === 0) tabButton.classList.add('active')
            tabButton.id = `tab-button-${sheetName}`
            tabButton.innerText = sheetName
            tabButtons.appendChild(tabButton)
    
            const tabContainer = document.createElement('div')
            tabContainer.classList.add('setup-tab-container')
            if (index === 0) tabContainer.classList.add('active')
            tabContainer.id = `setup-tab-container-${sheetName}`
            tabContainers.appendChild(tabContainer)
    
            tabButton.addEventListener('click', () => {
                document.querySelector('.setup-tab-container.active').classList.remove('active')
                document.querySelector('.tab-button.active').classList.remove('active')
                tabButton.classList.add('active')
                tabContainer.classList.add('active')
                setActiveTab(sheetName)
            })
    
            const worksheet = workbook.Sheets[sheetName]
            const wsContent = utils.sheet_to_json(worksheet, {header: 1, display: true })
    
            let tabTable
            switch (sheetName) {
                case 'Vehicle_data':
                    tabTable = await createVehicleDataTable(wsContent)
                    break
                case 'Driver_map':
                    tabTable = createDriverMapTable(wsContent)
                    break
                case 'Request':
                    tabTable = await createRequestTable(wsContent)
                    break
                case 'Environment':
                    tabTable = await createEnvironmentTable(wsContent)
                    break
                default:
                    break
            }

            tabTable.dataset.sheetName = sheetName
            tabContainer.appendChild(tabTable)

            if (sheetName === 'Environment') {
                const weatherDiv = document.createElement('div')
                weatherDiv.classList.add('weather-div')
                tabContainer.appendChild(weatherDiv)

                const h1 = document.createElement('h1')
                h1.innerText = t('optimizeSetupFile.weatherForecast')
                weatherDiv.appendChild(h1)

                const setWeather = document.createElement('div')
                setWeather.classList.add('set-weather')
                setWeather.innerText = t('optimizeSetupFile.setToCurrentTime')
                weatherDiv.appendChild(setWeather)
            }
        })
    
        async function createVehicleDataTable(wsContent) {
            const table = document.createElement('table')
            table.classList.add('tab-table')
            table.id = 'vehicle-data-table'

            const headerCount = wsContent[0].length - 2
    
            let constrainIndex, baseIndex, vehicleItemMappingIdIndex, setupItemIdIndex

            let baseOptions = {}

            for (const vehicleItem of vehicleItems) {
                if (vehicleItem.category_values.length === 0) {
                    continue
                }

                baseOptions[vehicleItem.item.name] = vehicleItem.category_values.map(categoryValue => categoryValue.name)
            }

            const constrainOptions = ['-', 'ON', 'env']
        
            wsContent.forEach((row, rowIndex) => {
                const tr = document.createElement('tr')
                table.appendChild(tr)
    
                const isHeader = rowIndex === 0
    
                if (rowIndex === 0) {
                    constrainIndex = row.indexOf('Constrain')
                    baseIndex = row.indexOf('Base')
                    vehicleItemMappingIdIndex = row.indexOf('VehicleItemMappingId')
                    setupItemIdIndex = row.indexOf('SetupItemId')
                }
    
                for (let i = 0; i < headerCount; i++) {
                    const cell = row[i] !== undefined && row[i] !== null ? row[i] : ''    
                    const tdTh = document.createElement(isHeader ? 'th' : 'td')
                    tdTh.dataset.setupitemid = row[setupItemIdIndex]
                    tdTh.dataset.vehicleitemmappingid = row[vehicleItemMappingIdIndex]

                    if (!isHeader && i === constrainIndex) {
                        const select = createSelect(constrainOptions)
                        select.value = cell
                        tdTh.appendChild(select)
                        tdTh.classList.add('selecter-td')
                    } else if (!isHeader && i === baseIndex && baseOptions[row[0]]) {
                        const select = createSelect(baseOptions[row[0]])
                        select.value = cell
                        tdTh.appendChild(select)
                        tdTh.classList.add('selecter-td')
                    } else {
                        tdTh.innerText = cell
                        if (isHeader && cell === 'Base') {
                            tdTh.classList.add('th-base-header')
                        }
                        tdTh.contentEditable = isHeader || i === 0 ? false : true
                    }
                    
                    tdTh.dataset.row = rowIndex
                    tdTh.dataset.column = i
                    if (i === baseIndex) tdTh.classList.add('td-base')
                    tr.appendChild(tdTh)
                }
            })
    
            return table
        }
    
        function createDriverMapTable(wsContent) {
            const table = document.createElement('table')
            table.classList.add('tab-table')
            table.id = 'driver-map-table'
    
            const headerCount = wsContent[0].length
            // const driverMapOptions = ['-', 'US', 'Keep', 'OS']
            const driverMapOptions = ['-', 'US3', 'US2', 'US1', 'Keep', 'OS1', 'OS2', 'OS3']
            const frontRearOptions = ['-', 'More Front', 'Keep', 'More Rear']
            const withOptionsColumnIndeces = []
            const withOptionsColumnIndeces_AeroMech = []
        
            wsContent.forEach(async (row, rowIndex) => {
                const tr = document.createElement('tr')
                table.appendChild(tr)
    
                const isHeader = rowIndex === 0
    
                if (rowIndex === 0) {
                    row.forEach((cell, index) => {
                        // if (!cell.includes('Ride Height')) withOptionsColumnIndeces.push(index)
                        if (cell.includes('Handling')) withOptionsColumnIndeces.push(index)
                        if (cell.includes('Aero Balance')) withOptionsColumnIndeces_AeroMech.push(index)
                        if (cell.includes('mechanical Balance')) withOptionsColumnIndeces_AeroMech.push(index)
                    })
                }
    
                for (let i = 0; i < headerCount; i++) {
                    const cell = row[i] !== undefined && row[i] !== null ? row[i] : ''
    
                    const tdTh = document.createElement(isHeader ? 'th' : 'td')                
    
                    if (!isHeader && i > 0 && withOptionsColumnIndeces.includes(i)) {
                        const select = createSelect(driverMapOptions)
                        select.value = cell
                        tdTh.appendChild(select)
                        tdTh.classList.add('selecter-td')
                    } else if (!isHeader && i > 0 && withOptionsColumnIndeces_AeroMech.includes(i)) {
                        const select = createSelect(frontRearOptions)
                        select.value = cell
                        tdTh.appendChild(select)
                        tdTh.classList.add('selecter-td')
                    } else {
                        tdTh.innerText = cell
                        tdTh.contentEditable = isHeader || i === 0 ? false : true
                    }
                    
                    tdTh.dataset.row = rowIndex
                    tdTh.dataset.column = i
                    tr.appendChild(tdTh)
                }
            })
    
            return table
        }

        async function createRequestTable(wsContent) {
            const table = document.createElement('table')
            table.classList.add('tab-table')
            table.id = 'request-data-table'

            const headerCount = wsContent[0].length

            wsContent.forEach((row, rowIndex) => {
                const tr = document.createElement('tr')
                table.appendChild(tr)
    
                const isHeader = rowIndex === 0

                let parameterName = ''
    
                for (let i = 0; i < headerCount; i++) {
                    const cell = row[i] !== undefined && row[i] !== null ? row[i] : ''

                    const tdTh = document.createElement(isHeader ? 'th' : 'td')

                    const frontRearOptions = ['-', 'More Front', 'Keep', 'More Rear']
                    const increaseDecreaseOptions = ['-', 'Increase', 'Keep', 'Decrease']

                    if (i === 0) {
                        parameterName = cell
                    }

                    if (i === 1 && !isHeader && parameterName.includes('_Total')) {
                        const select = createSelect(increaseDecreaseOptions)
                        select.value = cell
                        tdTh.appendChild(select)
                        tdTh.classList.add('selecter-td')
                    } else if (i === 1 && !isHeader) {
                        const select = createSelect(frontRearOptions)
                        select.value = cell
                        tdTh.appendChild(select)
                        tdTh.classList.add('selecter-td')
                    } else {
                        tdTh.innerText = cell
                    }

                    tdTh.dataset.row = rowIndex
                    tdTh.dataset.column = i
                    tr.appendChild(tdTh)
                }
            })

            return table
        }

        async function createEnvironmentTable(wsContent) {
            const table = document.createElement('table')
            table.classList.add('tab-table')
            table.id = 'environment-table'
        
            wsContent.forEach((row, rowIndex) => {
                const tr = document.createElement('tr')
                table.appendChild(tr)

                if (rowIndex === 0) {
                    const thParameter = document.createElement('th')
                    thParameter.innerText = 'Parameter'
                    thParameter.dataset.row = rowIndex
                    thParameter.dataset.column = 0
                    tr.appendChild(thParameter)

                    const th = document.createElement('th')
                    th.innerText = ''
                    th.dataset.row = rowIndex
                    th.dataset.column = 1
                    tr.appendChild(th)
                } else {
                    const tdParameter = document.createElement('td')
                    tdParameter.innerText = row[0]
                    tdParameter.dataset.row = rowIndex
                    tdParameter.dataset.column = 0
                    tr.appendChild(tdParameter)

                    const td = document.createElement('td')
                    // TODO: change to slider component
                    // td.innerHTML = '<Slider defaultValue={50} aria-label='Default' valueLabelDisplay='auto' />'
                    td.innerHTML = `
                        <input type='range' min='0' max='100' value='${row[2]}' class='slider' oninput='${row[0]}.value=rangeInput.value'>
                        <output name='${row[0]}' id='${row[0]}'>${row[2]}</output>
                    `
                    td.dataset.row = rowIndex
                    td.dataset.column = 1
                    tr.appendChild(td)
                }
            })
    
            return table
        }
    }

    // const displaySetupFile = (workbook) => {
    //     const sheetTabs = workbook.SheetNames.map((sheetName, index) => {
    //         if (sheetName === 'Combo_name') return null

    //         return (
    //             <div key={sheetName} className={`tab-button ${index === 0 ? 'active' : ''}`} onClick={() => handleTabClick(sheetName)}>
    //                 {sheetName}
    //             </div>
    //         )
    //     })
    // }

    const addColumnHandler = () => {
        const activeTable = document.querySelector('.setup-tab-container.active table')
        const tableElement = document.getElementById(activeTable.id)

        const targetColumnName = 'Base'
        let baseColumnIndex = -1
        let baseColumnCount = 0

        for (let i = 0; i < tableElement.rows[0].cells.length; i++) {
            const columnName = tableElement.rows[0].cells[i].textContent.trim()
            if (columnName.includes(targetColumnName)) {
                if (baseColumnIndex === -1) baseColumnIndex = tableElement.rows[0].cells[i].dataset.column
                baseColumnCount++
            }
        }

        const columnCount = tableElement.rows[0].cells.length
        const rowCount = tableElement.rows.length

        const lastColumnIndex = tableElement.rows[0].cells.length - 1
        const lastColumn = tableElement.rows[0].cells[lastColumnIndex].innerText

        const newColumnIndex = columnCount

        const newColumnHeader = document.createElement('th')
        newColumnHeader.classList.add(`th-header-${baseColors[(baseColumnCount) % baseColors.length].slice(1)}`)
        newColumnHeader.innerHTML = `Base${baseColumnCount + 1}`
        newColumnHeader.dataset.row = 0
        newColumnHeader.dataset.column = newColumnIndex

        const deleteButton = document.createElement('span')
        deleteButton.classList.add('delete-button')
        deleteButton.innerText = '　✖'
        
        deleteButton.addEventListener('click', removeColumnHandler)
        newColumnHeader.appendChild(deleteButton)

        if (lastColumn === 'Recommend') {
            tableElement.rows[0].insertBefore(newColumnHeader, tableElement.rows[0].cells[lastColumnIndex])
        } else {
            tableElement.rows[0].appendChild(newColumnHeader)
        }

        for (let i = 1; i < rowCount; i++) {
            const originalBase = tableElement.querySelector(`td[data-row='${i}'][data-column='${baseColumnIndex}']`)
            let selectValue
            if (originalBase.childElementCount > 0) {
                const select = originalBase.children[0]
                selectValue = select.value
            }
            const newRowCell = document.createElement('td')
            newRowCell.innerHTML = originalBase.innerHTML
            if (selectValue) newRowCell.querySelector('select').value = selectValue
            newRowCell.dataset.row = i
            newRowCell.dataset.column = newColumnIndex
            newRowCell.contentEditable = true
            if (lastColumn === 'Recommend') {
                tableElement.rows[i].insertBefore(newRowCell, tableElement.rows[i].cells[lastColumnIndex])
            } else {
                tableElement.rows[i].appendChild(newRowCell)
            }
        }
    }

    const removeColumnHandler = (e) => {
        const tdElement = e.target.parentElement
        const columnIndex = tdElement.dataset.column
        
        const activeTable = document.querySelector('.setup-tab-container.active table')
        const tableElement = document.getElementById(activeTable.id)
        const rows = tableElement.rows

        for (let i = 0; i < rows.length; i++) {
            if (i === 0) {
                const cell = rows[i].querySelector(`th[data-row='${i}'][data-column='${columnIndex}']`)
                cell.remove()
            } else {
                const cell = rows[i].querySelector(`td[data-row='${i}'][data-column='${columnIndex}']`)
                cell.remove()
            }
        }
    }

    return (
        <>
            <div className='setup-file-container'>
                <div className='setup-file-btn-area'>
                    <div className='copy-paste-buttons'>
                        { activeTab === 'Vehicle_data' && (
                            <>
                                <div className='add-remove-column-button' onClick={ addColumnHandler }>＋</div>
                                {/* <div className='add-remove-column-button' onClick={ removeColumnHandler }>－</div> */}
                            </>
                        )}
                        <div className='copy-paste-button' id='copy-button' onClick={ copyHandler }>Copy All</div>
                        <div className='copy-paste-button' id='paste-button' onClick={ pasteHandler }>Paste All</div>
                    </div>
                    <div className='tab-buttons' id='sheet-tabs-button-container'></div>
                </div>
                <div className='tab-containers' id='sheet-tabs-container'></div>
            </div>
        </>
    )

}

export default OptimizeSetupFile

function createSelect(selectOptions) {
    const select = document.createElement('select')

    selectOptions.forEach(option => {
        const optionElement = document.createElement('option')
        optionElement.value = option
        optionElement.innerText = option
        select.appendChild(optionElement)
    })

    return select
}

function copyPasteMode() {
    const activeTable = document.querySelector('.setup-tab-container.active table')
    document.querySelectorAll(`#${activeTable.id} td`).forEach(td => {
        if (td.childElementCount > 0) {
            const select = td.children[0]
            const selectValue = select.value
            const selectOptions = [...select.options].map(option => option.value)

            td.dataset.value = selectValue
            td.dataset.options = selectOptions.join(',')
            td.innerText = selectValue
        } else {
            td.contentEditable = false
        }
    })
}

function viewMode() {
    const activeTable = document.querySelector('.setup-tab-container.active table')
    document.querySelectorAll(`#${activeTable.id} td`).forEach(td => {
        if (td.dataset.options) {
            const selectOptions = td.dataset.options.split(',')
            if (!(selectOptions.includes(td.innerText))) selectOptions.push(td.innerText)

            const select = createSelect(selectOptions)
            select.value = td.innerText
            td.innerText = ''
            td.appendChild(select)

            delete td.dataset.value
            delete td.dataset.options
        } else {
            td.contentEditable = true
        }
    })
}

