import EditVehicleDatabaseTable from './EditVehicleDatabaseTable'
import EditVehicleCircuitDatabaseTable from './EditVehicleCircuitDatabaseTable'
import TrigerButton from './TrigerButton';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next'

const DatabaseUpdateVehiclesDataOrganism = (props) => {
    const { t } = useTranslation(['strategy'])

    const vehicleEnergyInfoData = props.vehicleEnergyInfoData
    const vehicleCircuitEnergyInfoData =props.vehicleCircuitEnergyInfoData
    const [vehicleEnergyInfoCell, setVehicleEnergyInfoCell] = useState({})
    const [vehicleCircuitEnergyInfoCell, setVehicleCircuitEnergyInfoCell] = useState({})
    const [updatedVehicleEnergyInfoData, setUpdatedVehicleEnergyInfoData] = useState({})
    const [updatedVehicleCircuitEnergyInfoData, setUpdatedVehicleCircuitEnergyInfoData] = useState({})
    const [initialVehicleEnergyInfoData, setInitialVehicleEnergyInfoData] = useState({})
    const [initialVehicleCircuitEnergyInfoData, setInitialVehicleCircuitEnergyInfoData] = useState({})

    const handleCellChange = ((event, tableName) => {
        const { name, value } = event.target
        let newData = {}
        if (tableName === 'vehicleEnergyInfo') {
            newData = {
                ...vehicleEnergyInfoCell,
                [name]: parseFloat(value) || null
            }
            setVehicleEnergyInfoCell(newData)
            setUpdatedVehicleEnergyInfoData(newData)
        } else if (tableName === 'vehicleCircuitEnergyInfo') {
            newData = {
                ...vehicleCircuitEnergyInfoCell,
                [name]: parseFloat(value) || null
            }
            setVehicleCircuitEnergyInfoCell(newData)
            setUpdatedVehicleCircuitEnergyInfoData(newData)
        }
    })

    useEffect(() => {
      props.handleUpdateVehicleEnergyInfoDataChange(updatedVehicleEnergyInfoData)
    }, [updatedVehicleEnergyInfoData])

    useEffect(() => {
      props.handleUpdateVehicleCircuitEnergyInfoDataChange(updatedVehicleCircuitEnergyInfoData)
    },[updatedVehicleCircuitEnergyInfoData])

    useEffect(() => {
        const initialVehicleEnergyInfoCell = { ...vehicleEnergyInfoData }
        const initialVehicleCircuitEnergyInfoCell = { ...vehicleCircuitEnergyInfoData }
        setVehicleEnergyInfoCell(initialVehicleEnergyInfoCell)
        setVehicleCircuitEnergyInfoCell(initialVehicleCircuitEnergyInfoCell)
        setInitialVehicleEnergyInfoData(initialVehicleEnergyInfoCell)
        setInitialVehicleCircuitEnergyInfoData(initialVehicleCircuitEnergyInfoCell)
    }, [vehicleEnergyInfoData, vehicleCircuitEnergyInfoData])

    useEffect(() => {
        if (Object.keys(updatedVehicleEnergyInfoData).length ) {
            setInitialVehicleEnergyInfoData(updatedVehicleEnergyInfoData)
        }
        if (Object.keys(updatedVehicleCircuitEnergyInfoData).length) {
            setInitialVehicleCircuitEnergyInfoData(updatedVehicleCircuitEnergyInfoData)
       }
        
    }, [props.isUpdateButtonClicked])

    return (
        <>  
            <EditVehicleDatabaseTable
                vehicleId={props.selectedVehicleId}
                circuitId={props.selectedCircuitId}
                cells={vehicleEnergyInfoCell}
                handleCellChange={handleCellChange}
                isUpdateButtonClicked={props.isUpdateButtonClicked}
                initialVehicleEnergyInfoData={initialVehicleEnergyInfoData}
                
                />
            <br></br>
            <EditVehicleCircuitDatabaseTable
                vehicleId={props.selectedVehicleId}
                circuitId={props.selectedCircuitId}
                cells={vehicleCircuitEnergyInfoCell}
                handleCellChange={handleCellChange}
                isUpdateButtonClicked={props.isUpdateButtonClicked}
                initialVehicleCircuitEnergyInfoData={initialVehicleCircuitEnergyInfoData}
            />
            <br></br>
            <TrigerButton
                buttonLabel={t('editVehicleDatabase.update')}
                handleTrigerButtonClick={props.handleUpdateButtonClick}
            />
        </>
    )
}

export default DatabaseUpdateVehiclesDataOrganism