import React from 'react'
import ReactDOM from 'react-dom/client'
import './strategy/css/index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { AuthContextProvider } from './authContext'

import './i18n/LangConfig'
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_SENTRY_ENABLED) {
  Sentry.init({
    dsn: "https://8ae969ac9defcb046d005c6724e19ee7@o4506887145652224.ingest.us.sentry.io/4506925980647424", // ブラウザ側に配信されてしまうため機密情報扱いしない
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/staging\.gridcore\.jp/, /^https:\/\/gridcore\.jp/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <AuthContextProvider>
        <BrowserRouter>
                <App />
        </BrowserRouter>
    </AuthContextProvider>
)