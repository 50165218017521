import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../components/Header'
import DatabaseUpdateVehiclesDataOrganism from './DatabaseUpdateVehiclesDataOrganism'
import DatabaseUpdateVehiclesKeysOrganism from './DatabaseUpdateVehiclesKeysOrganism'

import { useTranslation } from 'react-i18next'

const EditVehicleDatabaseTemplate = (props) => {
    const { t } = useTranslation(['strategy'])

    const navigate = useNavigate()
    return (
        <div style={{ margin: '0 16px' }}> 
            <div className='vehicle-selected-top-text' style={{textAlign: 'right'}}>
                <Header />
                <button
                    className='vehicle-button'
                    style={{
                        backgroundColor:'#696969',
                        color: 'white',
                        margin: '0 5px 1px 0',
                        width: '200px',
                        height: '50px',
                    }}
                    onClick={() => {
                    navigate('/admin')
                }}>
                    {t('general.managementPage')}
                </button>
            </div>

            <DatabaseUpdateVehiclesKeysOrganism
                vehicleList={props.vehicleList}
                circuitList={props.circuitList}
                handleVehicleSelect={props.handleVehicleSelect}
                handleCircuitSelect={props.handleCircuitSelect}
                selectedVehicleId={props.selectedVehicleId}
                />

            <DatabaseUpdateVehiclesDataOrganism
                selectedCircuitId={props.selectedCircuitId}
                selectedVehicleId={props.selectedVehicleId}
                vehicleEnergyInfoData={props.vehicleEnergyInfoData}
                vehicleCircuitEnergyInfoData={props.vehicleCircuitEnergyInfoData}
                handleUpdateButtonClick={props.handleUpdateButtonClick}
                isUpdateButtonClicked={props.isUpdateButtonClicked}
                handleUpdateVehicleEnergyInfoDataChange={props.handleUpdateVehicleEnergyInfoDataChange}
                handleUpdateVehicleCircuitEnergyInfoDataChange={props.handleUpdateVehicleCircuitEnergyInfoDataChange}
                updateData={props.updateData}
                />
        </div>
    )
}
export default EditVehicleDatabaseTemplate