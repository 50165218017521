import { useState, useEffect } from 'react'

import { groupLapDataByCar } from '../services/Util'

import { Line } from 'react-chartjs-2'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'

import { useLiveData } from '../../DataContext'

import upImage from '../image/scale-bar-arrow.png'

import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

// Set chart color
const plotColor = [
    '#7289DA', '#FFF000', '#E9967A', '#F08080', '#FFC0CB',
    '#FF69B4', '#FF1493','#FF6347', '#FFD700', '#FFFACD',
    '#FFE4B5','#BDB76B', '#EE82EE', '#BA55D3', '#4B0082', 
    '#6A5ACD','#7FFF00', '#2E8B57', '#00FFFF', '#4682B4',
    '#800000','#008080', '#191970', '#D2691E'
]

const gridColor = '#5A5A5A'
const labelColor = 'black'

const LapTimeChart = (props) => {
    const { t } = useTranslation(['strategy'])

    const [ xMin, setXMin ] = useState(0)
    const [ xMax, setXMax ] = useState(30)

    const [xSlideMax, setXSlideMax] = useState(100)
    const [xSlideMin, setXSlideMin] = useState(0)

    const [slideMax, setSlideMax] = useState(150)
    const [slideMin, setSlideMin] = useState(50)

    const [carList, setCarList] = useState([])
    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                display: true,
                position: 'bottom',
                title: {
                    display: true,
                    text: 'LAP',
                    color: labelColor,
                },
                ticks: {
                    color: labelColor
                },
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: gridColor
                }
            },
            y: {
                type: 'linear',
                display: true,
                title: {
                    display: true,
                    text: 'TIME(s)',
                    color: labelColor,
                },
                ticks: {
                    color: labelColor,
                },
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: gridColor
                }
            }
        },
        plugins: {
            legend: {
                position: 'left',
                labels: {
                    color: 'white',
                    padding: 10,
                    font: {
                        size: 16
                    },
                    usePointStyle: true
                }
            }
        }
    })
    const [chartData, setChartData] = useState({
        labels: [],
        datasets:  []
    })
    const [yMin, setYMin] = useState(80)
    const [yMax, setYMax] = useState(100)
    const [color, setColor] = useState({})

    const { raceDetailId, lapData, carClasses } = useLiveData()

    const [allLapDatas, setAllLapDatas] = useState([])
    const [carsNotIncluded, setCarsNotIncluded] = useState([])

    useEffect(() => {
        const getAllLapData = (raceDetailId) => {
            return new Promise ((resolve, reject) => {
                if (!raceDetailId) return resolve([])
        
                fetch(`/api/race/getAllLapData/${raceDetailId}`)
                    .then(response => response.json())
                    .then(json => {
                        setAllLapDatas(json.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
        getAllLapData(raceDetailId)
    }, [])

    useEffect(() => {
        const sortCB = document.getElementById('sortCB')
        if (sortCB) {
            createChart(sortCB.checked)
        }
    }, [lapData, props, carsNotIncluded])

    useEffect(() => {
        const carsNotIncludedTemp = carClasses
            .filter(className => !className.isVisible)
            .map(className => className.cars)
            .flat()

        setCarsNotIncluded(carsNotIncludedTemp)
    }, [carClasses])

    useEffect(() => {
        if (props.moreLapData) {
            if (lapData.length > 0) {
                const newLapData = lapData.filter((data) => {
                    return !allLapDatas.some((allLapData) => {
                        return allLapData.CAR_NUMBER === data.CAR_NUMBER && allLapData.LAP_NUMBER === data.LAP_NUMBER
                    })
                })
                const newAllLapDatas = allLapDatas.concat(newLapData)
                setAllLapDatas(newAllLapDatas)
            }
        }
    }, [props, lapData])

    useEffect(() => {
        setOptions(prevOptions => ({
            ...prevOptions,
            scales: {
                ...prevOptions.scales,
                x: {
                    ...(prevOptions.scales?.x || {}),
                    min: Math.trunc(xMin),
                    max: Math.trunc(xMax)
                }
            }
        }))
    }, [xMin, xMax])

    useEffect(() => {
        setOptions(prevOptions => ({
            ...prevOptions,
            scales: {
                ...prevOptions.scales,
                y: {
                    ...(prevOptions.scales?.y || {}),
                    min: Math.trunc(yMin), 
                    max: Math.trunc(yMax) 
                }
            }
        }))
    }, [yMin, yMax])

    const createChart = (sortIsCheck) => {
        let storeDatasets = []
        let labels = []

        let chartObj = {}
        if (!sortIsCheck) {
            chartObj = chartValWithoutSort()
        } else {
            chartObj = chartValWithSort()
        }
        
        storeDatasets = chartObj.storeDatasets
        labels = chartObj.labels.sort((a, b) => a - b)

        let maxX = -Infinity
        let maxY = -Infinity
        let minY = Infinity

        storeDatasets.forEach((dataset) => {
            dataset.data.forEach((data) => {
                if (data.x > maxX) maxX = data.x
                if (data.y > maxY) maxY = data.y
                if (data.y < minY) minY = data.y
            })
        })

        setYMax(Math.ceil(maxY))
        setYMin(Math.floor(minY))
        setXMax(Math.ceil(maxX))

        setSlideMax(Math.ceil(maxY) + 10)
        setSlideMin(Math.floor(minY) - 10)
        setXSlideMax(Math.ceil(maxX))

        setChartData({
            labels,
            datasets: storeDatasets
        })
    }

    const chartValWithoutSort = () => {
        let storeDatasets = []
        let labels = []

        let carListArr = []
        let colorObj = {}
        const baseLapDatas = props.moreLapData ? allLapDatas : lapData
        const filteredLapDatas = baseLapDatas.filter(lapData => !carsNotIncluded.includes(lapData.CAR_NUMBER))
        const groupLapDataByCarObj = groupLapDataByCar(filteredLapDatas)

        for (const [carNumber, lapData] of Object.entries(groupLapDataByCarObj)) {
            const drivers = [...new Set(lapData.map(lap => lap.DRIVER_NAME))]
            const legendLabel = `${carNumber} ${drivers.join('\n')}`

            lapData.forEach((eachLap) => {
                if (!labels.includes(eachLap.LAP_NUMBER)) {
                    labels.push(eachLap.LAP_NUMBER)
                }

                const findCar = storeDatasets.find(el => el.label === legendLabel)
                if (findCar) {
                    findCar.data.push({x: eachLap.LAP_NUMBER, y: eachLap.TIME})
                } else {
                    carListArr.push(eachLap.CAR_NUMBER)
                    colorObj[eachLap.CAR_NUMBER] = colorObj[eachLap.CAR_NUMBER] ? colorObj[eachLap.CAR_NUMBER] : plotColor[carListArr.length%plotColor.length]
                    storeDatasets.push({
                        label: legendLabel,
                        data: [{x: eachLap.LAP_NUMBER, y: eachLap.TIME}],
                        borderColor: colorObj[eachLap.CAR_NUMBER],
                        backgroundColor: colorObj[eachLap.CAR_NUMBER],
                        borderWidth: 1
                    })
                }
            })
        }

        setCarList(carListArr)
        setColor(colorObj)

        return {
            storeDatasets,
            labels
        }
    }

    const chartValWithSort = () => {
        let yMinData = 200
        let yMaxData = 0
        let storeDatasets = []
        let labels = []

        const baseLapDatas = props.moreLapData ? allLapDatas : lapData
        const filteredLapDatas = baseLapDatas.filter(lapData => !carsNotIncluded.includes(lapData.CAR_NUMBER))

        for (let i=0; i<carList.length; i++) {
            let getLapDataByCar = filteredLapDatas.filter(el => el.CAR_NUMBER === carList[i])
            getLapDataByCar.sort((a, b) => a.TIME - b.TIME)

            let newData = []
            let driverName = ''
            Object.entries(getLapDataByCar).forEach((data) => {
                const lap = data[0]
                const eachData = data[1]

                if (!labels.includes(parseInt(lap) + 1)) {
                    labels.push(parseInt(lap) + 1)
                }

                newData.push({x: parseInt(lap) + 1, y: eachData.TIME})
                driverName = eachData.DRIVER_NAME.slice(0, 5)
                if (eachData.TIME > yMaxData) {
                    yMaxData = eachData.TIME > 200 ? 200 : Math.ceil(eachData.TIME) + 1
                } else if (eachData.TIME < yMinData) {
                    yMinData = Math.floor(eachData.TIME) - 1
                }
            })

            storeDatasets.push({
                label: `${carList[i]} ${driverName}`,
                data: newData,
                borderColor: color[carList[i]],
                backgroundColor: color[carList[i]],
                borderWidth: 1
            })
        }

        return {
            yMinData,
            yMaxData,
            storeDatasets,
            labels
        }
    }


    const handleXChange = (_, newValue) => {
        setXMin(newValue[0])
        setXMax(newValue[1])
    }

    const handleChange = (_, newValue) => {
        setYMin(newValue[0])
        setYMax(newValue[1])
    }

    const sortHandler = (e) => {
        const isChecked = e.target.checked
        createChart(isChecked)
    }

    const onClickMaxUp = (e) => {
        const isX = e.target.className.charAt(0) === 'x'

        if (isX) {
            setXSlideMax(xSlideMax+10)
        } else {
            setSlideMax(slideMax+10)
        }
    }
    
    const onClickMaxDown = (e) => {
        const isX = e.target.className.charAt(0) === 'x'

        if (isX) {
            setXSlideMax(xSlideMax-10)
            if (xMax > xSlideMax-10) {
                setXMax(xSlideMax-10)
            }
        } else {
            setSlideMax(slideMax-10)
            if (yMax > slideMax-10) {
                setYMax(slideMax-10)
            }
        }   
    }
    
    const onClickMinUp = (e) => {
        const isX = e.target.className.charAt(0) === 'x'

        if (isX) {
            setXSlideMin(xSlideMin+10)
            if (xMin < xSlideMin+10) {
                setXMin(xSlideMin+10)
            }
        } else {
            setSlideMin(slideMin+10)
            if (yMin < slideMin+10) {
                setYMin(slideMin+10)
            }
        }
    }
    
    const onClickMinDown = (e) => {
        const isX = e.target.className.charAt(0) === 'x'

        if (isX) {
            if (xSlideMin === 0) return
            setXSlideMin(xSlideMin-10)
        } else {
            setSlideMin(slideMin-10)
        }
    }

    return (
        <>
            { 
                Object.keys(lapData).length > 0 ? 
                    (
                        <div>
                            <div>
                                <input id='sortCB' type='checkbox' onChange={ sortHandler }/>{t('general.sort')}
                            </div>
                            <div className='lapTime-container'>
                                <div className='lapTime-flex-container'>
                                    <Line options={options} data={chartData} />
                                    
                                    <Stack sx={{ height: '90%' }} spacing={1}>
                                        <div className='flex updown'>
                                            <img alt='' className='y-max-up' src={upImage} onClick={onClickMaxUp}/>
                                            <img alt='' className='y-max-down' src={upImage} onClick={onClickMaxDown} style={{transform: 'rotate(180deg)'}}/>
                                        </div>
                                        <Slider
                                            max={slideMax}
                                            min={slideMin}
                                            orientation='vertical'
                                            value={[yMin, yMax]}
                                            onChange={handleChange}
                                            valueLabelDisplay='auto'
                                        />
                                        <div className='flex updown'>
                                            <img alt='' className='y-min-up' src={upImage} onClick={onClickMinUp}/>
                                            <img alt='' className='y-min-down' src={upImage} onClick={onClickMinDown} style={{transform: 'rotate(180deg)'}}/>
                                        </div>
                                    </Stack>
                                </div>
                                <Stack className='stack-slider-flex' sx={{ width: '100%' }} spacing={1}>
                                    <div className='flex updown landscape'>
                                        <img alt='' className='x-min-up' src={upImage} onClick={onClickMinUp} style={{transform: 'rotate(90deg)'}}/>
                                        <img alt='' className='x-min-down' src={upImage} onClick={onClickMinDown} style={{transform: 'rotate(270deg)'}}/>
                                    </div>
                                    <Slider
                                        max={xSlideMax}
                                        min={xSlideMin}
                                        value={[xMin, xMax]}
                                        onChange={handleXChange}
                                        orientation='horizontal'
                                        valueLabelDisplay='auto'
                                        className='chart-slider'
                                    />
                                    <div className='flex updown landscape'>
                                        <img alt='' className='x-max-up' src={upImage} onClick={onClickMaxUp} style={{transform: 'rotate(90deg)'}}/>
                                        <img alt='' className='x-max-down' src={upImage} onClick={onClickMaxDown} style={{transform: 'rotate(270deg)'}}/>
                                    </div>
                                </Stack>
                            </div>
                        </div>
                    ) : 
                    (
                        <div>{t('general.lapTimeNotRecorded')}</div>
                    )
            }
        </>
    )
}

export default LapTimeChart