import search_columns from './search_columns'

// 各手法で最適解探索
const compare_drivermap = (list, Obj_circuit_map, df_base, df_scatter, df_mapDriver, method, defaultAvoidBottoming) => {
    let df_ans = df_scatter.iloc({'rows':list}).resetIndex()
    let driver_map = df_mapDriver[search_columns(df_mapDriver, method)].values
    let listAns
    
    for (let i = 0; i < driver_map.length; i++) {

        let row_name = search_columns(df_base, Obj_circuit_map[i].UI_Parameter)
        let base_data = df_base.iloc({'rows':[0]}).loc({'columns':[row_name]}).values[0][0]

        // let scat_range = df_ans[row_name].max() - df_ans[row_name].min()
        let us_range = base_data - df_ans[row_name].min()
        let os_range = df_ans[row_name].max() - base_data
        let keep_range = 15 //Keepの時の±のレンジ
        let handling_range_1 =  [40] //Keepの時の±のレンジ
        let handling_range_2 =  [30, 70] //Keepの時の±のレンジ
        let handling_range_3 =  [60] //Keepの時の±のレンジ

        if (method === 'Handling') {
            if (driver_map[i] === 'US') {
                df_ans = df_ans.query(df_ans[row_name].gt(base_data))
            } else if (driver_map[i] === 'OS') {
                df_ans = df_ans.query(df_ans[row_name].lt(base_data))
            } else if (driver_map[i] === 'Keep') {
                df_ans = df_ans.query(df_ans[row_name].gt(base_data - us_range*keep_range/100)).resetIndex()
                df_ans = df_ans.query(df_ans[row_name].lt(base_data + os_range*keep_range/100))
                console.log('Keep :' + df_ans['id'].values.length)
            } else if (driver_map[i] === 'US1') {
                df_ans = df_ans.query(df_ans[row_name].gt(base_data)).resetIndex()
                df_ans = df_ans.query(df_ans[row_name].lt(base_data + handling_range_1[0]*os_range/100))
                console.log('US1 :' + df_ans['id'].values.length)
            } else if (driver_map[i] === 'US2') {
                df_ans = df_ans.query(df_ans[row_name].gt(base_data + handling_range_2[0]*os_range/100)).resetIndex()
                df_ans = df_ans.query(df_ans[row_name].lt(base_data + handling_range_2[1]*os_range/100))
                console.log('US2 :' + df_ans['id'].values.length)
            } else if (driver_map[i] === 'US3') {
                df_ans = df_ans.query(df_ans[row_name].gt(base_data + handling_range_3[0]*os_range/100))
                console.log('US3 :' + df_ans['id'].values.length)
            } else if (driver_map[i] === 'OS1') {
                df_ans = df_ans.query(df_ans[row_name].lt(base_data)).resetIndex()
                df_ans = df_ans.query(df_ans[row_name].gt(base_data - handling_range_1[0]*us_range/100))
                console.log('OS1 :' + df_ans['id'].values.length)
            } else if (driver_map[i] === 'OS2') {
                df_ans = df_ans.query(df_ans[row_name].lt(base_data - handling_range_2[0]*us_range/100)).resetIndex()
                df_ans = df_ans.query(df_ans[row_name].gt(base_data - handling_range_2[1]*us_range/100))
                console.log('OS2 :' + df_ans['id'].values.length)
            } else if (driver_map[i] === 'OS3') {
                df_ans = df_ans.query(df_ans[row_name].lt(base_data - handling_range_3[0]*us_range/100))
                console.log('OS3 :' + df_ans['id'].values.length)
            }

            df_ans = df_ans.resetIndex()
            listAns = df_ans['id'].values
            
        } else if (method === 'Aero') {
            if (driver_map[i] === 'More Front') {
                df_ans = df_ans.query(df_ans[row_name].gt(base_data))
            } else if (driver_map[i] === 'Keep') {
                df_ans = df_ans.query(df_ans[row_name].gt(base_data - us_range*keep_range/100)).resetIndex()
                df_ans = df_ans.query(df_ans[row_name].lt(base_data + os_range*keep_range/100))
            } else if (driver_map[i] === 'More Rear') {
                df_ans = df_ans.query(df_ans[row_name].lt(base_data))
            }
            df_ans = df_ans.resetIndex()
            listAns = df_ans['id'].values  
        } else if (method === 'mechanical') {
            if (driver_map[i] === 'More Front') {
                df_ans = df_ans.query(df_ans[row_name].gt(base_data))
            } else if (driver_map[i] === 'Keep') {
                console.log('Mechanical keep at ' + df_mapDriver.index[i])
                df_ans = df_ans.query(df_ans[row_name].gt(base_data - us_range*keep_range/100)).resetIndex()
                df_ans = df_ans.query(df_ans[row_name].lt(base_data + os_range*keep_range/100))
            } else if (driver_map[i] === 'More Rear') {
                console.log('Mechanical become more rear at ' + df_mapDriver.index[i])
                df_ans = df_ans.query(df_ans[row_name].lt(base_data))
            }
            df_ans = df_ans.resetIndex()
            listAns = df_ans['id'].values
        } else if (method === 'Ride Height') {
            if (driver_map[i] === '-') {
                df_ans = df_ans.query(df_ans[row_name].gt(base_data + defaultAvoidBottoming))
            } else {
                df_ans = df_ans.query(df_ans[row_name].gt(base_data + driver_map[i]))
            }
            df_ans = df_ans.resetIndex()
            listAns = df_ans['id'].values
        }
    }

    return listAns
}

export default compare_drivermap