const EditVehicleDatabaseTable = (props) => {
    return (
        <>
            <div style={{ fontWeight: 'bold', fontSize: '24px', paddingBottom: '8px' }}>VehicleEnergyInfo</div>
            <div style={{ overflowX: 'auto', overflowY: 'auto', maxWidth: '100%', maxHeight: '600px' }}>
                <table border='1'>
                    <thead>
                        <tr>

                            {props.cells?Object.keys(props.cells).map((columnName, index) =>
                                <th key={index} style={headerStyle}>
                                    {columnName}
                                </th>
                                ):null
                            }
                        </tr>
                    </thead>
                    <tbody>
                            <tr>
                            {props.cells ? Object.entries(props.cells).map(([columnName, cellData], index) => {
                                const backgroundColor = cellData != props.initialVehicleEnergyInfoData[columnName]
                                ? '#fff462'
                                    : '#d3d3d3'
                            return(
                                <td key={index} style={{ padding: 0 }}>
                                    <input
                                        type="text"
                                        value={cellData}
                                        name={columnName}
                                        onChange={(event) => props.handleCellChange(event, 'vehicleEnergyInfo')}
                                        style={
                                            !props.isUpdateButtonClicked ?
                                            {
                                                textAlign: 'center',
                                                fontSize: '16px',
                                                border: 'none',
                                                backgroundColor: backgroundColor,
                                                width: '100%',
                                                boxSizing: 'border-box',
                                            }
                                            :null
                                        }
                                    />
                                </td>)
                            }) : null}
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
)


}
export default EditVehicleDatabaseTable

const headerStyle = {
    fontSize: '16px',
    textAlign: 'center',
    position: 'sticky',
    top: 0,
    backgroundColor: '#242424',
    zIndex: 150,
    minHeight: '30px',
    maxHeight: '30px',
    minWidth: '250px',
    padding: '8px 16px'
}

