// //Tableデータ作成
import { baseColors, recommendColors } from '../services/plotColors'

const SetupTable = (df, type, Obj_parameter_combination) => {
    const rows = []

    const KPIrows = ['Roll_Stiff_Total', 'Roll_Stiff_Balance', 'Heave_Total', 'Heave_Ratio', 'Sprung_Resonance_Ratio']

    let baseCount = 0
    let recommendCount = 0

    for (let i = 0; i < df.index.length + 1; i++) {
        // tr要素を生成
        const cells = []

        if (type === 'kpi' && i > 0) {
            let text = df.index[i - 1]

            if (!KPIrows.includes(text)) continue
        }

        // th・td部分のループ
        for (let j = 0; j < df.columns.length + 1; j++) {
            // 1行目のtr要素の時
            if (i === 0) {
                const columnName = df.columns[j - 1]
                let isRecommendHeader = false
                let headerColor = ''
                if (columnName) {
                    isRecommendHeader = columnName === 'Recommend'
                }
                let text = j === 0 ? 'parameter' : df.columns[j - 1]
                if (text[text.length - 1] === '✖') {
                    text = text.slice(0, -1)
                }

                if (text.includes('Recommend') && text !== 'Recommend') {
                    headerColor = `th-header-${recommendColors[(recommendCount) % recommendColors.length].slice(1)}`
                    recommendCount++
                } else if (text.includes('Base')) {
                    headerColor = `th-header-${baseColors[(baseCount) % baseColors.length].slice(1)}`
                    baseCount++
                }
                const th = (
                <td
                    key={j}
                    className={`
                        table-header ${j < 3 ? `td-fixed-column td-fixed-column-${j}` : ''}
                        ${df.columns[j - 1]}
                        ${df.columns[j - 1] === 'Base' ? 'th-base-header' : ''}
                        ${isRecommendHeader ? 'th-recommend-header' : ''}
                        ${headerColor}
                    `}
                >
                    {text}
                </td>
                )
                cells.push(th)
            } else {
                let text = j === 0 ? df.index[i - 1] : df.iloc({ rows: [i - 1], columns: [j - 1] }).values[0]
                const classList = ['td-content']

                if (!isNaN(text)) {
                    const decimal = type === 'laptime' ? 3 : 2
                    text = parseFloat(text).toFixed(decimal)
                    const recommended = parseFloat(text)
                    let triangle = ''
                    let highlightClass = false

                    if (j === df.columns.length) {
                        let base = df.iloc({ rows: [i - 1], columns: [0] }).values[0][0]
                        const parameterName = df.index[i - 1]
                        const parameterCombination = Object.keys(Obj_parameter_combination).find((key) =>
                            key.includes(parameterName)
                        )

                        if (parameterCombination) {
                            const value = Obj_parameter_combination[parameterCombination].find((p) => p.name === base)
                            if (value) base = value.value
                        }

                        base = parseFloat(parseFloat(base).toFixed(decimal))

                        if (recommended > base) {
                            if (type === 'laptime') {
                                highlightClass = 'td-up-green'

                            } else if (type === 'setup' || type === 'kpi') {
                                highlightClass = 'td-up-red'
                            }
                            
                            } else if (recommended < base) {
                            if (type === 'laptime') {
                                highlightClass = 'td-down-red'

                            } else if (type === 'setup' || type === 'kpi') {
                                highlightClass = 'td-down-green'
                            }
                        }

                        if (highlightClass) {
                            text = <span className={highlightClass}>{`${triangle}${recommended}`}</span>
                        } else {
                            text = recommended
                        }
                    }
                }

                if (j < 3) {
                    classList.push('td-fixed-column')
                    classList.push(`td-fixed-column-${j}`)
                }

                const td = (
                    <td
                        key={j}
                        className={`${classList.join(' ')} ${j < 3 ? `td-fixed-column td-fixed-column-${j}` : ''}`}
                    >
                        {text}
                    </td>
                )
                cells.push(td)
            }
        }

        // tr要素をrowsに追加
        rows.push(<tr key={i}>{cells}</tr>)
    }

    return (
        <table>
            <thead>{rows[0]}</thead>
            <tbody>{rows.slice(1)}</tbody>
        </table>
    )
}

export default SetupTable
