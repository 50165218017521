import React from 'react'
import TimeFormatter from './TimeFormatter'

const LapDataWithFuelDataTable = ({ carLapData, fuelStartEndData, setFuelStartEndData, fuelStartEndDiffData, fuelEndPreviousEndDiffData, correctionFactor, fuelOperationData, setFuelOperationData, handleCellChange, handlePaste }) => {
    return (
        <div>
            <table border='1'>
                <thead>
                    <tr>
                        <th style={{ textAlign: 'center', padding: '0 10px 0 10px' }} rowSpan={2}> lap </th>
                        <th style={{ textAlign: 'center', padding: '0 20px 0 20px'}} rowSpan={2}> lap time </th>
                        <th style={{ textAlign: 'center' }} rowSpan={2}> pit <br></br> count </th>
                        <th style={{ textAlign: 'center' }} colSpan={5}> Fuel Used (kg) </th>
                        <th style={{ textAlign: 'center' }} colSpan={3}> Fuel Operation (kg) </th>
                    </tr>
                    <tr>
                        <th style={{ textAlign: 'center' }}> start </th>
                        <th style={{ textAlign: 'center' }}> end </th>
                        <th style={{ textAlign: 'center', width: '80px' }}> end - start </th>
                        <th style={{ textAlign: 'center', width: '80px' }}> end - previous </th>
                        <th style={{ textAlign: 'center', width: '80px' }}> actual </th>
                        <th style={{ textAlign: 'center' }}> order </th>
                        <th style={{ textAlign: 'center' }}> in </th>
                        <th style={{ textAlign: 'center' }}> out </th>
                    </tr>
                </thead>
                <tbody>
                    {carLapData.map((item, rowIndex) => (
                        <tr key={rowIndex}>
                            <td style={{ textAlign: 'center' }}>{item.LAP_NUMBER}</td>
                            <td style={{ textAlign: 'center' }}><TimeFormatter seconds={item.TIME} decimalPlaces={3} /></td>
                            <td style={{ textAlign: 'center' }}>{item.PIT_STOP_TIME}</td>

                            {fuelStartEndData[rowIndex].map((cell, cellIndex) => (
                                <td key={cellIndex} style={{ padding: '0px' }}>
                                    <input 
                                        value={cell}
                                        onChange={(e) => handleCellChange(e, rowIndex, cellIndex, fuelStartEndData, setFuelStartEndData)}
                                        onPaste={(e) => handlePaste(e, rowIndex, cellIndex, fuelStartEndData, setFuelStartEndData)}
                                        style={{
                                            width: '80px',
                                            height: '100%',
                                            textAlign: 'center',
                                            fontSize: '16px',
                                            border: 'none',
                                            backgroundColor: cell ? '#fff462' : '#d3d3d3'
                                        }}
                                    />
                                </td>
                            ))}

                            <td style={{ textAlign: 'center' }}>{fuelStartEndDiffData[rowIndex] ? fuelStartEndDiffData[rowIndex].toFixed(2) : ''}</td>
                            <td style={{ textAlign: 'center' }}>{fuelEndPreviousEndDiffData[rowIndex] ? fuelEndPreviousEndDiffData[rowIndex].toFixed(2) : ''}</td>
                            <td style={{ textAlign: 'center' }}>{fuelEndPreviousEndDiffData[rowIndex] ? (correctionFactor * fuelEndPreviousEndDiffData[rowIndex]).toFixed(2) : ''}</td>

                            {fuelOperationData[rowIndex].map((cell, cellIndex) => (
                                <td key={cellIndex} style={{ padding: '0px' }}>
                                    <input 
                                        value={cell}
                                        onChange={(e) => handleCellChange(e, rowIndex, cellIndex, fuelOperationData, setFuelOperationData)}
                                        onPaste={(e) => handlePaste(e, rowIndex, cellIndex, fuelOperationData, setFuelOperationData)}
                                        style={{
                                            width: '80px',
                                            textAlign: 'center',
                                            fontSize: '16px',
                                            border: 'none',
                                            backgroundColor: cell ? '#fff462' : '#d3d3d3' // この行で条件付きスタイルを適用
                                        }}
                                    />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default LapDataWithFuelDataTable