import { TableCell } from '@mui/material'
import styled from 'styled-components'
// import { useLiveData } from '../../DataContext'

// const { isLightMode } = useLiveData()


export const StyledHeaderTableCell = styled(TableCell)`
    font-size: 18px;
    line-height: 1rem;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-family: 'Titillium Web', sans-serif;
    border: none;
`

export const StyledTableCell = styled(TableCell)`
    padding: 0px 8px;
    text-align: center;
    font-size:min(1.8vw,20px);
    font-weight: 700;
    border: none;
    color: ${props => props.isLightMode ? '#000' : '#fff'};
    cursor: default;
    font-family: 'Titillium Web', sans-serif;
`

export const StyledHeaderLowerTableCell = styled(TableCell)`
    line-height: 1rem;
    padding: 5px 16px;
    vertical-align: bottom;
    text-align: center;
    color: #fff;
    font-family: 'Inter', sans-serif;
`

export const StyledHeaderUpperTableCell = styled(TableCell)`
    padding: 0;
    min-height: 60px;
    line-height: 1rem;
    color: #fff;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    text-align: center;
    border: none;
`

export const SecTableCell = styled(TableCell)`
    font-family: 'Titillium Web', sans-serif;
    padding: 0 0.1vw 0 0.1vw;
    text-align: center;
    position: relative;
    font-size:min(1.8vw,20px);
    font-weight: 700;
    cursor: default;
`

export const StyledTableCellSmall = styled(TableCell)`
    font-family: 'Titillium Web', sans-serif;
    padding: 0px 0px;
    text-align: center;
    font-size:min(1.8vw,20px);
    font-weight: 700;
    border: none;
    color: ${props => props.isLightMode ? '#000' : '#fff'}; /* isLightMode の値に応じて色を変更 */

`
export const StyledHeaderTableCellSmall = styled(TableCell)`
    line-height: 1rem;
    padding: 0px 0px 10px;
    vertical-align: bottom;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    border: none;
`

export const StyledAnalysisTable = styled(TableCell)`
    line-height: 1rem;
    padding: 10px 10px 10px 10px;
    vertical-align: bottom;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
    border: none;
`