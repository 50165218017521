import { useContext, useEffect, useState } from 'react'
import { useDebounce } from '../lib/hooks/useDebounce'
import { useLiveData } from '../../DataContext'
import '../css/RaceManagement.css'

import AuthContext from '../../authContext'
import { TableContainer, Paper, Table, TableHead, TableRow, TableBody } from '@mui/material'
import { StyledHeaderTableCell, StyledTableCell } from '../components/TableStyle'
import TimeFormatter from './TimeFormatter'

import { useTranslation } from 'react-i18next'

const RaceManagement = (props) => {
    const { t } = useTranslation(['strategy', 'general'])

    const { raceDetailId, sectorData, sectorLength } = useLiveData()
    const [totalLap, setTotalLap] = useState(0)
    const [bestLap, setBestLap] = useState({lap: 0, time: 0})
    const [allLapData, setAllLapData] = useState([])
    const [allSectorData, setAllSectorData] = useState([])
    const [cars, setCars] = useState([])
    const [initalRaceManagementCar, setInitialRaceManagementCar] = useState(true)
    const [tableData, setTableData] = useState([])
    const [newSetupChange, setNewSetupChange] = useState({})
    const [newDriverComment, setNewDriverComment] = useState({})
    const [newNotes, setNewNotes] = useState({})

    const ctx = useContext(AuthContext)
    const raceManagementCar = props.raceManagementCar
    const setRaceManagementCar = props.setRaceManagementCar
    const setupChange = props.setupChange
    const setSetupChange = props.setSetupChange
    const driverComment = props.driverComment
    const setDriverComment = props.setDriverComment
    const notes = props.notes
    const setNotes = props.setNotes

    const debounedSetupChange = useDebounce(newSetupChange, 1000)
    const debounedDriverComment = useDebounce(newDriverComment, 1000)
    const debounedNotes = useDebounce(newNotes, 1000)

    useEffect(() => {
        const getData = async () => {
            const allCarData = await getAllCarData(raceDetailId)
            setCars(allCarData)
        }
        getData()
    }, [raceDetailId])

    const getAllData = async () => {
        const allNewLapData = await getAllLapDataFromCarNumber(raceDetailId, raceManagementCar)
        const allNewSectorData = await getAllSectorDataFromCarNumber(raceDetailId, raceManagementCar)

        setAllLapData(allNewLapData)
        setAllSectorData(allNewSectorData)
        
        if (allNewLapData.length > 0) {
            const fastestTime = Math.min(...allNewLapData.map(lap => lap.TIME));
            const bestLap = allNewLapData.find(lap => lap.TIME === fastestTime);
            setBestLap({lap: bestLap.LAP_NUMBER, time: bestLap.TIME});
        }
    }

    useEffect(() => {
        if (!raceManagementCar) return

        if (initalRaceManagementCar) {
            getAllData()
            setInitialRaceManagementCar(false)
            if (tableData.length === 0) {
                const firstRow = {
                    rowId: 1,
                    outing: '',
                    timeStamp: '',
                    lapNo: 1,
                    time: '',
                    speed: '',
                    Sec1: '',
                    Sec2: '',
                    Sec3: '',
                    Sec4: '',
                    tyreSet: '',
                    setupChange: '',
                    driver: '',
                    driverComment: '',
                    notes: '',
                    engineMap: ''
                }
                setTableData([firstRow])
            }
        }
    }, [raceManagementCar])

    useEffect(() => {
        if (!raceManagementCar || initalRaceManagementCar) return

        const lastFiveSectorData = sectorData.slice(-5)
        const currentCarSectorData = lastFiveSectorData.find(lap => Number(lap.CAR_NUMBER) === Number(raceManagementCar))
        if (currentCarSectorData !== undefined) {
            getAllData()
        }
    }, [sectorData])

    useEffect(() => {
        if (allSectorData.length === 0) return

        const outingLaps = []
        const newTableData = allLapData.map((lap, index) => {
            let outing = 'None'; 
            let time = lap?.TIME || lap?.TIME === 0 ? <TimeFormatter seconds={lap.TIME} decimalPlaces={3} /> : '-'
            let timeStamp = ''
            if (index > 0) {
                const previousLap = allLapData[index - 1]
                const twoLapsAgo = allLapData[index - 2] 
                if (previousLap.PIT_STOP_TIME === lap.PIT_STOP_TIME) {
                    if (twoLapsAgo?.PIT_STOP_TIME !== lap?.PIT_STOP_TIME && lap?.LAP_NUMBER !== 1 && lap?.LAP_NUMBER !== 2) {
                        time = 'Out'
                        outingLaps.push(lap.LAP_NUMBER)
                    } else {
                        if (lap.LAP_NUMBER === 1) {
                            time = 'Out'
                            outingLaps.push(lap.LAP_NUMBER)
                        } else {
                            outing = ''
                        }
                    }
                } else {
                    time = 'In'
                }
            }

            const sectorData = allSectorData.filter(sector => sector.LAP_NUMBER === lap.LAP_NUMBER)
            const sec1 = sectorData.find(sector => sector.SECTOR_NUMBER === 1)
            const sec2 = sectorData.find(sector => sector.SECTOR_NUMBER === 2)
            const sec3 = sectorData.find(sector => sector.SECTOR_NUMBER === 3)
            const sec4 = sectorData.find(sector => sector.SECTOR_NUMBER === 4)
            if (outing !== '') {
                if (sec1.CREATED_AT) {
                    const dateObject = new Date(sec1.CREATED_AT)
                    const hours = dateObject.getHours()
                    const minutes = dateObject.getMinutes()   

                    timeStamp = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                } 
            }
            const row = {
                rowId: index + 1,
                outing: outingLaps.includes(lap.LAP_NUMBER) ? lap.PIT_STOP_TIME + 1 : lap.LAP_NUMBER === 1 ? 1 : '',
                timeStamp: time !== 'In' ? timeStamp : '',
                lapNo: lap.LAP_NUMBER,
                time: lap.LAP_NUMBER === 1 ? 'Out' : time,
                speed: lap?.SPEED ? lap?.SPEED !== 0 ? lap.SPEED : '-' : '-',
                Sec1: sec1?.TIME || sec1?.TIME === 0 ? <TimeFormatter seconds={sec1.TIME} decimalPlaces={3}/> : '-',
                Sec2: sec2?.TIME || sec2?.TIME === 0 ? <TimeFormatter seconds={sec2.TIME} decimalPlaces={3}/> : '-',
                Sec3: sec3?.TIME || sec3?.TIME === 0 ? <TimeFormatter seconds={sec3.TIME} decimalPlaces={3}/> : '-',
                Sec4: sec4?.TIME || sec4?.TIME === 0 ? <TimeFormatter seconds={sec4.TIME} decimalPlaces={3}/> : '-',
                tyreSet: '',
                driver: `${lap.DRIVER_NAME}`,
                engineMap: ''
            }
            return row
        })

        const highestLapNumber = allLapData.length ? Math.max(...allLapData.map(lap => lap.LAP_NUMBER)) : 0
        setTotalLap(highestLapNumber)

        if (newTableData.length <= highestLapNumber) {
            const nextLapNumber = highestLapNumber + 1
            const currentSectorData = allSectorData.filter(sector => sector.LAP_NUMBER === nextLapNumber)
            const sec1 = currentSectorData.find(sector => sector.SECTOR_NUMBER === 1)
            const sec2 = currentSectorData.find(sector => sector.SECTOR_NUMBER === 2)
            const sec3 = currentSectorData.find(sector => sector.SECTOR_NUMBER === 3)
            const sec4 = currentSectorData.find(sector => sector.SECTOR_NUMBER === 4)
            const row = {
                rowId: nextLapNumber,
                outing: '',
                timeStamp: '',
                lapNo: nextLapNumber,
                time: '-',
                speed: '-',
                Sec1: sec1?.TIME || sec1?.TIME === 0 ? <TimeFormatter seconds={sec1.TIME} decimalPlaces={3}/> : '-',
                Sec2: sec2?.TIME || sec2?.TIME === 0 ? <TimeFormatter seconds={sec2.TIME} decimalPlaces={3}/> : '-',
                Sec3: sec3?.TIME || sec3?.TIME === 0 ? <TimeFormatter seconds={sec3.TIME} decimalPlaces={3}/> : '-',
                Sec4: sec4?.TIME || sec4?.TIME === 0 ? <TimeFormatter seconds={sec4.TIME} decimalPlaces={3}/> : '-',
                tyreSet: '',
                driver: `${currentSectorData[0]?.DRIVER_NAME}`,
                engineMap: ''
            }
            newTableData.push(row)
        }

        if (initalRaceManagementCar) {
            setSetupChange(newTableData.reduce((acc, curr) => {
                acc[curr.lapNo] = ''
                return acc
            }, {}))
            setDriverComment(newTableData.reduce((acc, curr) => {
                acc[curr.lapNo] = ''
                return acc
            }, {}))
            setNotes(newTableData.reduce((acc, curr) => {
                acc[curr.lapNo] = ''
                return acc
            }, {}))
            setInitialRaceManagementCar(false)
        }

        setTableData(newTableData)
    }, [allSectorData])

    useEffect(() => {
        const getRaceManagementDataFromDB = async () => {
            const raceManagementDatas = await getRaceManagementData({ raceDetailId, userId: ctx.userId, carNumber: raceManagementCar })
            if (raceManagementDatas.length === 0) return

            raceManagementDatas.forEach(raceManagementData => {
                setSetupChange(prev => ({
                    ...prev,
                    [raceManagementData.LAP_NUMBER]: JSON.parse(raceManagementData.SETUP_CHANGE)
                }))
                setDriverComment(prev => ({
                    ...prev,
                    [raceManagementData.LAP_NUMBER]: JSON.parse(raceManagementData.DRIVER_COMMENT)
                }))
                setNotes(prev => ({
                    ...prev,
                    [raceManagementData.LAP_NUMBER]: JSON.parse(raceManagementData.NOTES)
                }))
            })
        }
        getRaceManagementDataFromDB()
    }, [tableData])


    useEffect(() => {
        if (initalRaceManagementCar) {
            setInitialRaceManagementCar(false)
            return
        }        
        const createOrUpdate = async () => {
            const data = {
                raceDetailId,
                userId: ctx.userId,
                carNumber: raceManagementCar,
                lapNumber: debounedSetupChange.lap,
                setupChange: debounedSetupChange.data,
                type: 'setupChange'
            }
            await createOrUpdateRaceManagement(data)
        }
        createOrUpdate()
    }, [debounedSetupChange])

    useEffect(() => {
        if (initalRaceManagementCar) {
            setInitialRaceManagementCar(false)
            return
        }    
        const createOrUpdate = async () => {
            const data = {
                raceDetailId,
                userId: ctx.userId,
                carNumber: raceManagementCar,
                lapNumber: debounedDriverComment.lap,
                driverComment: debounedDriverComment.data,
                type: 'driverComment'
            }
            await createOrUpdateRaceManagement(data)
        }
        createOrUpdate()
    }, [debounedDriverComment])

    useEffect(() => {
        if (initalRaceManagementCar) {
            setInitialRaceManagementCar(false)
            return
        }    
        const createOrUpdate = async () => {
            const data = {
                raceDetailId,
                userId: ctx.userId,
                carNumber: raceManagementCar,
                lapNumber: debounedNotes.lap,
                notes: debounedNotes.data,
                type: 'notes'
            }
            await createOrUpdateRaceManagement(data)
        }
        createOrUpdate()
    }, [debounedNotes])
      
    const handleCarSelect = (carNumber) => {
        setSetupChange({})
        setDriverComment({})
        setNotes({})
        setTableData([])
        setInitialRaceManagementCar(true)
        setRaceManagementCar(carNumber.target.value)
    }

    const handleTextChange = (input, lap, type) => {
        if (type === 'setupChange') {
            setNewSetupChange({
                data: input.target.value, 
                lap
            })
        } else if (type === 'driverComment') {
            setNewDriverComment({
                data: input.target.value, 
                lap
            })
        } else if (type === 'notes') {
            setNewNotes({
                data: input.target.value, 
                lap
            })
        }
    }

    return (
        <div>
            <div className='race-mana-vehicle-info-top-container'>
                <h1>Vehicle Information</h1>
                <div className='race-mana-vehicle-info-outer-container'>
                    <div className='race-mana-vehicle-information'>
                        {/* TODO: Make values change via modal */}
                        <div className='race-mana-vehicle-info-inner-container'>
                            <div>
                                Mass:
                            </div>
                            <div>
                                1211kg (+75kg、ガス無)
                            </div>
                        </div>
                        <div className='race-mana-vehicle-info-inner-container'>
                            <div>
                                SPG:
                            </div>
                            <div>
                                FR 14 RR 14
                            </div>
                        </div>
                        <div className='race-mana-vehicle-info-inner-container'>
                            <div>
                                Height:
                            </div>
                            <div>
                                FR:161.75 RR:164.50
                            </div>
                        </div>
                        <div className='race-mana-vehicle-info-inner-container'>
                            <div>
                                ARB:
                            </div>
                            <div>
                                FR -50% RR 8.1N/mm
                            </div>
                        </div>
                        <div className='race-mana-vehicle-info-inner-container'>
                            <div>
                                RrWing:
                            </div>
                            <div>
                                7° w/oGF
                            </div>
                        </div>
                        <div className='race-mana-vehicle-info-inner-container'>
                            <div>
                                Damper:
                            </div>
                            <div>
                                Normal/Normal
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1>Car Number</h1>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <select
                                onChange={handleCarSelect}
                                value={raceManagementCar ? raceManagementCar : ''}
                                style={{ textAlign: 'center' }}
                            >
                                <option value={''}>{t('pleaseSelect', {ns: 'general'})}</option>
                                {cars.map((car, carIndex) => (
                                <option key={`Car${car}${carIndex}`} value={car}>{car}</option>
                                ))}
                            </select>
                            </div>
                    </div>
                    <div>
                        <div>
                            Total Laps: {totalLap}
                        </div>
                        <div>
                            Best Lap: {bestLap.lap} (<TimeFormatter seconds={bestLap.time} decimalPlaces={3}/>)
                        </div>
                    </div>
                </div>
            </div>
            <div className='race-mana-notes'>
                <h1>Note</h1>
                {tableData.length > 0 && 
                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} className='live-table' style={{backgroundColor: '#242424'}}>
                        <TableHead className='live-table-header'>
                            <TableRow>
                                <StyledHeaderTableCell align='center'>Outing</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Time</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Lap</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Laptime</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Speed</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Sec1</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Sec2</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Sec3</StyledHeaderTableCell>
                                {sectorLength === 4 && <StyledHeaderTableCell align='center'>Sec4</StyledHeaderTableCell>}
                                <StyledHeaderTableCell align='center'>Tyre Set</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Setup Change</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Car Number</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Driver</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Driver Comment</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Notes</StyledHeaderTableCell>
                                <StyledHeaderTableCell align='center'>Engine Map</StyledHeaderTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell align='center'>{row.outing}</StyledTableCell>
                                    <StyledTableCell align='center'>{row.timeStamp}</StyledTableCell>
                                    <StyledTableCell align='center'>{row.lapNo}</StyledTableCell>
                                    <StyledTableCell align='center'>{row.time}</StyledTableCell>
                                    <StyledTableCell align='center'>{row.speed}</StyledTableCell>
                                    <StyledTableCell align='center'>{row.Sec1}</StyledTableCell>
                                    <StyledTableCell align='center'>{row.Sec2}</StyledTableCell>
                                    <StyledTableCell align='center'>{row.Sec3}</StyledTableCell>
                                    {sectorLength === 4 && <StyledTableCell align='center'>{row.Sec4}</StyledTableCell>}
                                    <StyledTableCell align='center'>
                                        <select>
                                            <option value={'Set 1'}>{'Set 1'}</option>
                                            <option value={'Set 2'}>{'Set 2'}</option>
                                            <option value={'Set 3'}>{'Set 3'}</option>
                                            <option value={'Set 4'}>{'Set 4'}</option>
                                        </select>
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        <textarea
                                            rows={3}
                                            defaultValue={setupChange[row.lapNo] ? setupChange[row.lapNo] : ''}
                                            onChange={(e) => handleTextChange(e, row.lapNo, 'setupChange')}
                                            style={{ width: '100%' }} 
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        {raceManagementCar}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        {row.driver}
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        <textarea
                                            rows={3}
                                            defaultValue={driverComment[row.lapNo] ? driverComment[row.lapNo] : ''}
                                            onChange={(e) => handleTextChange(e, row.lapNo, 'driverComment')}
                                            style={{ width: '100%' }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                        <textarea
                                            rows={3}
                                            defaultValue={notes[row.lapNo] ? notes[row.lapNo] : ''}
                                            onChange={(e) => handleTextChange(e, row.lapNo, 'notes')}
                                            style={{ width: '100%' }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>{row.engineMap}</StyledTableCell>
                                </TableRow>
                            ))}
                        </TableBody>    
                    </Table>
                </TableContainer>    
                }
            </div>
        </div>
    )
}

export default RaceManagement

function getAllCarData(raceDetailId) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/car/carNumbers/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

function getAllLapDataFromCarNumber(raceDetailId, carNumber) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/race/getAllLapDataByCarNumber/${raceDetailId}/${carNumber}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

function getAllSectorDataFromCarNumber(raceDetailId, carNumber) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/race/getAllSectorDataByCarNumber/${raceDetailId}/${carNumber}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

const createOrUpdateRaceManagement = (data) => {
    return new Promise((resolve, reject) => {
        const { raceDetailId, userId, carNumber, lapNumber, setupChange, driverComment, notes, type } = data
        if (!raceDetailId || !userId || !carNumber) return resolve([])

        const url = `/api/raceManagement/createOrUpdate/`
        let body = ''
        if (type === 'setupChange') {
            body = JSON.stringify({ raceDetailId, userId, carNumber, lapNumber, setupChange, type })
        } else if (type === 'driverComment') {
            body = JSON.stringify({ raceDetailId, userId, carNumber, lapNumber, driverComment, type })
        } else if (type === 'notes') {
            body = JSON.stringify({ raceDetailId, userId, carNumber, lapNumber, notes, type })
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        })
            .then((res) => res.json())
            .then((json) => resolve(json.data))
            .catch((error) => {
                console.log(error)
                reject(error)
            })
    })
}

const getRaceManagementData = ({ raceDetailId, userId, carNumber }) => {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId || !userId || !carNumber) return resolve([])

        fetch(`/api/raceManagement/findRaceManagementByRaceIdUserCarNumber/${raceDetailId}/${userId}/${carNumber}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}