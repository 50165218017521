import React from 'react'

import '../css/footerMenu.css'

const FooterMenu = () => {
    return (
        <footer className='footer' />
    )
}

export default FooterMenu