import upImage from '../image/up.png'
import downImage from '../image/down.png'
import stableImage from '../image/stable.png'
import warningImage from '../image/warning.png'

import { colorScale, median } from './Util'

const CalculateAverageAndDegradation = (lapData, lapAverages, targetType, followCarNumber) => {
    const heatMapAverageAndDegradation = {}

    const groupLapData = (lapData) => {
        const groupedData = []
        for(const lap of lapData){
            const carNumber = lap.CAR_NUMBER
    
            let carArray = groupedData.find((lap) => lap.CAR_NUMBER === carNumber)
            if(!carArray){
                carArray = {
                    CAR_NUMBER: carNumber,
                    DRIVER_NAME: lap.DRIVER_NAME,
                    DATA: [],
                }
                groupedData.push(carArray)
            }
            carArray.DATA.push({lapNumber: lap.LAP_NUMBER, lapTime: lap.TIME, position: lap.POS, pit: lap.PIT_STOP_TIME})
        }

        for (const car of groupedData) {
            car.DATA.sort((a, b) => a.lapNumer - b.lapNumer)
        }

        return groupedData
    }

    const pushDefaultDriverAverages = (driverAveragesArray, numberOfLaps) => {
        driverAveragesArray.push({
            average: '-',
            condition: null,
            numberOfLaps: numberOfLaps,
            degradation: '-',
        })
    }

    const setAverageOfAllCars = (groupedData) => {
        const allDriverAverages = []

        groupedData.forEach((car) => {
            const driverAverages = []
            const lapOffSet = 2
            const carLapDatas = car.DATA
            carLapDatas.sort((a, b) => a.lapNumber - b.lapNumber)
            const finalLapNumber = carLapDatas[carLapDatas.length - 1]?.lapNumber

            const pitArrays = []
            const pitData = carLapDatas.filter((data) => data.pit !== undefined)
            if (pitData.length > 0) {
                carLapDatas.forEach(lap => {
                    const pitValue = (lap.pit === undefined) ? 0 : lap.pit
                    const pitArrayIndex = pitArrays.findIndex(arr => arr[0].pit === pitValue)
    
                    if (pitArrayIndex === -1) {
                        pitArrays.push([lap])
                    } else {
                        pitArrays[pitArrayIndex].push(lap)
                    }
                })
            }

            const pitLaps = []
            for (let i = 0; i < pitArrays.length; i++) {
                if (i !== 0) {
                    if (pitArrays[i].length > 2) {
                        pitLaps.push(pitArrays[i][0])
                        pitLaps.push(pitArrays[i][1])
                    } else {
                        pitArrays[i].forEach(lap => pitLaps.push(lap))
                    }
                }
            }

            lapAverages.forEach((numberOfLaps) => {
                if (finalLapNumber >= numberOfLaps) {
                    let start = finalLapNumber - numberOfLaps
                    let end = finalLapNumber

                    let lastNLaps
                    if (pitLaps.length === 0) {
                        if (carLapDatas.length <= numberOfLaps) {
                            return pushDefaultDriverAverages(driverAverages, numberOfLaps)
                        }
                        lastNLaps = carLapDatas.filter(lap => lap.lapNumber > start && lap.lapNumber <= end)
                    } else if (pitLaps[pitLaps.length - 1].lapNumber &&  (pitLaps[pitLaps.length - 1].lapNumber + lapOffSet) > carLapDatas[carLapDatas.length - 1].lapNumber) {
                        return pushDefaultDriverAverages(driverAverages, numberOfLaps)
                    } else {
                        lastNLaps = carLapDatas.filter(lap => lap.lapNumber > start && lap.lapNumber <= end).filter(lap => !pitLaps.includes(lap))
                        const lastPitLap = lastNLaps[lastNLaps.length - 1]?.pit
                        lastNLaps = lastNLaps.filter(lap => lap.pit === lastPitLap)
                        if (numberOfLaps <= 3) {
                            if (lastNLaps.length >= 2) {
                                if (pitArrays[pitArrays.length - 1].length === undefined) return
                            } else {
                                return pushDefaultDriverAverages(driverAverages, numberOfLaps)
                            }        
                        } else if (numberOfLaps < 10 && lastNLaps.length <= 3) {
                            return pushDefaultDriverAverages(driverAverages, numberOfLaps)
                        } else if (numberOfLaps % 5 === 0 && lastNLaps.length <= numberOfLaps - 5) {
                            return pushDefaultDriverAverages(driverAverages, numberOfLaps)
                        }
                    }

                    function deepCopy(obj) {
                        return JSON.parse(JSON.stringify(obj));
                    }
                    
                    const removeOutliers = (dataArray) => {
                        const copiedDataArray = deepCopy(dataArray)
                        
                        const medianTime = median(copiedDataArray.map(item => item.lapTime))
                        const variance = copiedDataArray.reduce((sum, item) => sum + Math.pow(item.lapTime - medianTime, 2), 0) / copiedDataArray.length
                        const stdDev = Math.sqrt(variance)
                      
                        const threshold = medianTime + 2 * stdDev
                      
                        return copiedDataArray.filter(item => item.lapTime <= threshold)
                    }

                    numberOfLaps = lastNLaps.length
                    const filterdLastNLapTimes = removeOutliers(lastNLaps)
                    const totalNLapsTime = filterdLastNLapTimes.reduce((total, lap) => total + Number(lap.lapTime), 0)
                    const mainAverageReturn = Number((totalNLapsTime / filterdLastNLapTimes.length).toFixed(3))

                    let condition = ''
                    const calculateDegradationByLastNLaps = (lastNLaps) => {
                        let sumX = 0
                        let sumY = 0
                        let sumXY = 0
                        let sumXX = 0
                        const n = lastNLaps.length
                    
                        lastNLaps.forEach((lapData) => {
                            sumX += lapData.lapNumber
                            sumY += lapData.lapTime
                            sumXY += lapData.lapNumber * lapData.lapTime
                            sumXX += lapData.lapNumber * lapData.lapNumber
                        })
                    
                        const degradation = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX)
                        return degradation
                    }
        
                    const degradation = calculateDegradationByLastNLaps(filterdLastNLapTimes)
            
                    if (degradation < -0.05) {
                        condition = downImage
                    } else if (degradation >= -0.05 && degradation < 0.05) {
                        condition = stableImage
                    } else if (degradation >= 0.05 && degradation < 1) {
                        condition = upImage
                    } else {
                        condition = warningImage
                    }
        
                    driverAverages.push({
                        average: mainAverageReturn,
                        condition: condition,
                        degradation: degradation,
                        numberOfLaps: numberOfLaps,
                    })
                } else {
                    pushDefaultDriverAverages(driverAverages, numberOfLaps)
                }
            })
            allDriverAverages.push({carNo: car.CAR_NUMBER, averages: driverAverages})
        })
        return allDriverAverages
    }

    const setHeatMap = (averageData, targetType, followCarNumber) => {
        const getAllAveragePerLapAverage = () => {
            averageData.forEach(car => {
                const carNo = car.carNo
                const averages = car.averages
                
                if(averages.length === 0) return

                lapAverages.forEach((lapAverageBase, index) => {
                    if (index < averages.length) {
                        let value
                        if (targetType === 'average') {
                            value = averages[Math.min(index, averages.length - 1)].average
                        } else {
                            value = averages[Math.min(index, averages.length - 1)].degradation
                        }
                        heatMapAverageAndDegradation[lapAverageBase].push({ carNo, average: value })
                    }
                })
            })
        }
    
        const updateHeatMapColor = (lapAverageArray, followCarAvg) => {
            if (lapAverageArray.length > 1) {
                const validEntries = lapAverageArray.filter(car => car.average !== '-')
                const validAverages = validEntries.map(car => car.average)
                
                if (validAverages.length > 0) {
                    const heatMap = colorScale(validAverages, 0xff523c, 0x3a4eff, 'normal', followCarAvg?.average)
                    
                    validEntries.forEach((car, index) => {
                        car.color = heatMap[index]
                    })
                }
            }
        }

        getAllAveragePerLapAverage()
        lapAverages.forEach(lapNumber => {
            const lapAverageArray = heatMapAverageAndDegradation[lapNumber]
            let followCarAvg
            if (followCarNumber) {
                followCarAvg = lapAverageArray.find(car => car.carNo === Number(followCarNumber))
            }
            updateHeatMapColor(lapAverageArray, followCarAvg)
        })

        for (const lapAverage in heatMapAverageAndDegradation) {
            heatMapAverageAndDegradation[lapAverage] = heatMapAverageAndDegradation[lapAverage].map((car) => {
                if (car.color === 0) {
                    car.color = 16777215
                }
                return car
            })
        }
        return heatMapAverageAndDegradation
    }

    lapAverages.forEach((lapAverage) => {
        heatMapAverageAndDegradation[lapAverage] = []
    })

    const groupedData = groupLapData(lapData)
    const averageData = setAverageOfAllCars(groupedData)
    if (followCarNumber) {
        const followCar = averageData.find(car => car.carNo === Number(followCarNumber))?.averages
    
        if (followCar) {
            const deg = followCar.map(car => car.degradation)
            const avg = followCar.map(car => car.average)

            averageData.forEach(car => {
                car.averages.forEach((avgCar, index) => {
                    if (avgCar.degradation !== '-') {
                        if (deg[index] !== '-') {
                            avgCar.degradation = (avgCar.degradation + -deg[index])
                        }
                    } else {
                        avgCar.degradation = '-'
                    }
    
                    if (avgCar.average !== '-') {
                        if (avg[index] !== '-') {
                            avgCar.average = (avgCar.average + -avg[index])
                        }
                    } else {
                        avgCar.average = '-'
                    }
                })
            })
        }
    }
    return {averageData, heatmap: setHeatMap(averageData, targetType, followCarNumber)}
}

export default CalculateAverageAndDegradation