import { useState, useEffect } from 'react'

import CircularProgress from '@mui/material/CircularProgress'
import EditDatabaseModal from './EditDatabaseModal'
import EditDatabaseTable from './EditDatabaseTable'
import { useTranslation } from 'react-i18next'

const calculateMeanAndStandardDeviation = (values) => {
    const mean = values.reduce((accumulator, value) => accumulator + value, 0) / values.length
    const standardDeviation = Math.sqrt(values.map(value => Math.pow(value- mean, 2)).reduce((accumulator, value) => accumulator + value, 0) / values.length)
    return { mean, standardDeviation }
}

const determineBackgroundColor = (carNumber, lapNumber, sectorNumber, missingDatas) => {
    const isMissingData = missingDatas.some(missingData => {
        const isCarAndLapMatch = Number(missingData.carNumber) === carNumber
        const isLapNumberMissing = missingData.missingLapNumbers ? missingData.missingLapNumbers.includes(lapNumber) : false
        const isSectorNumberMissing = missingData.missingSectorNumbers ? missingData.missingSectorNumbers.includes(sectorNumber) && missingData.missingSectorsLapNumber === lapNumber : false
        return isCarAndLapMatch && (isLapNumberMissing || isSectorNumberMissing)
    })

    return isMissingData ? '#fa6a6a' :  '#d3d3d3'
}

const findModalLapDataTableData = (lapNumbersToFind, carNumber, tableDataObject, keys) => {
    
    return lapNumbersToFind.map(lapNumberToFind => {
        const key = `${carNumber}-${lapNumberToFind}`   
        const foundData = tableDataObject[key]
        return foundData || keys.reduce((acc, key) => {
            acc[key] = undefined
            return acc
        }, { LAP_NUMBER: lapNumberToFind })
    })
}

const findModalSectorDataTableData = (lapNumbersToFind, modalSectorNumbers, carNumber, tableDataObject, keys) => 
    lapNumbersToFind.flatMap(lapNumber =>
        modalSectorNumbers.map((modalSectorNumber, index) => {
            let targetLapNumber
            if (modalSectorNumbers[0] > modalSectorNumbers[1]) {
                targetLapNumber = index === 0 ? lapNumber - 1 : lapNumber
            } else if (modalSectorNumbers[0] > modalSectorNumbers[2]) {
                targetLapNumber = index === 2 ? lapNumber + 1 : lapNumber
            } else {
                targetLapNumber = lapNumber
            }

            const key = `${carNumber}-${targetLapNumber}-${modalSectorNumber || ''}`
            const foundData = tableDataObject[key]

            return foundData || keys.reduce((acc, key) => {
                acc[key] = undefined
                return acc
            }, { LAP_NUMBER: targetLapNumber, SECTOR_NUMBER: modalSectorNumber })
        })
    )

const groupByCarNumber = (tableDatas, key) => {
    return tableDatas.reduce((groupedData, tableData) => {
        const { CAR_NUMBER } = tableData
        const value = tableData[key]
        if (!groupedData[CAR_NUMBER]) {
            groupedData[CAR_NUMBER] = []
        }
        groupedData[CAR_NUMBER].push(value)
        return groupedData
    }, {})
}

const groupByCarNumberAndLapNumber = (tableDatas, key) => {
    return tableDatas.reduce((acc, tableData) => {
        const { CAR_NUMBER, LAP_NUMBER } = tableData 
        const value = tableData[key]
        if (!acc[CAR_NUMBER]) {
            acc[CAR_NUMBER] = {}
        }
        if (!acc[CAR_NUMBER][LAP_NUMBER]) {
            acc[CAR_NUMBER][LAP_NUMBER] = []
        }
        acc[CAR_NUMBER][LAP_NUMBER].push(value)
        return acc
    }, {})
}

const findNonSequentialLapNumbers = (lapNumbers) => {
    const numericLapNumbers = lapNumbers.map(Number)
    const sortedLapNumbers = numericLapNumbers.sort((a, b) => a - b)
    const missingLapNumbers = sortedLapNumbers.reduce((resultArray, currentLapNumber, index, array) => {
        const nextLapNumber = array[index + 1]
        if (nextLapNumber && nextLapNumber - currentLapNumber > 1) {
            const range = [...Array(nextLapNumber - currentLapNumber - 1)].map((_, i) => currentLapNumber + i + 1)
            resultArray.push(...range)
        }
        return resultArray
    }, [])

    if (sortedLapNumbers[0] > 1) {
        const initialMissing = [...Array(sortedLapNumbers[0] - 1)].map((_, i) => i + 1)
        missingLapNumbers.unshift(...initialMissing)
    }

    return missingLapNumbers
}

const findNonSequentialCarNumbers = (tableDatas) => {
    const lapDatasGroupedByCarNumber = groupByCarNumber(tableDatas, 'LAP_NUMBER')
    return Object.entries(lapDatasGroupedByCarNumber).map(([carNumber, lapNumbers]) => {
        const missingLapNumbers = findNonSequentialLapNumbers(lapNumbers)
        return missingLapNumbers.length > 0 ? { carNumber, missingLapNumbers } : null
    }).filter(Boolean)
}

const findNonSequentialLapsAndSectors = (tableDatas, sectorNumbers) => {
    const sectorNumbersByCarNumberAndLapNumber = groupByCarNumberAndLapNumber(tableDatas, 'SECTOR_NUMBER')
    const carNumbers = Object.keys(sectorNumbersByCarNumberAndLapNumber)

    return carNumbers.flatMap(carNumber => {
        const lapNumbers = Object.keys(sectorNumbersByCarNumberAndLapNumber[carNumber]).sort((a, b) => a - b)
        const missingLapNumbers = findNonSequentialLapNumbers(lapNumbers)
        const missingLapNumbersAndSectorNumbers = lapNumbers.map(lapNumber => {
            const existingSectors = sectorNumbersByCarNumberAndLapNumber[carNumber][lapNumber] || []
            const missingSectors = sectorNumbers.filter(sector => !existingSectors.includes(sector))
            const missingSectorsLapNumber = Number(lapNumber)
            return missingSectors.length > 0 ? { carNumber, missingSectorsLapNumber, missingSectorNumbers: missingSectors } : null
        }).filter(Boolean)

        if (missingLapNumbers.length > 0) {
            missingLapNumbersAndSectorNumbers.push({
                carNumber,
                missingLapNumbers,
                missingSectorNumbers: []
            })
        }

        return missingLapNumbersAndSectorNumbers
    })
}

const getSurroundingSectorNumbers = (sectorNumbers, currentSectorNumber) => { 
    const length =sectorNumbers.length
    const adjustedIndex = (currentSectorNumber - 1 + length) % length
    
    const precedingSectorNumber = sectorNumbers[( adjustedIndex - 1 + length) % length]
    const followingSectorNumber = sectorNumbers[( adjustedIndex + 1) % length]
    
    return [precedingSectorNumber, currentSectorNumber, followingSectorNumber]  
}


const DatabaseUpdateDatasOrganism = ( props ) => {
    const { t } = useTranslation(['strategy', 'general'])
    
    const maxLapNumber = Math.max(...props.tableDatas.map(tableData => tableData.LAP_NUMBER))
    const lapNumbers = Array.from({ length: maxLapNumber }, (_, i) => i + 1)
    const tableCarNumbers = [...new Set(props.tableDatas.map(tableData => tableData.CAR_NUMBER))]
                            .sort((a, b) => a - b)

    const sectorNumbers = props.selectedDatabaseTableName === 'SECTOR_DATA' 
    ? [...new Set(props.tableDatas.map(tableData => tableData.SECTOR_NUMBER))]
    : []

    const [cells, setCells] = useState(Array(props.tableDatas.length).fill(''))
    const [initialValueObject, setInitialValueObject] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [keyColumns, setKeyColumns] = useState(['CAR_NUMBER'])
    const [threshold, setThreshold] = useState(null)
    const [missingDatas, setMissingDatas] = useState([])
    const [modalTableDatas, setModalTableDatas] = useState([])
    const [modalTableHeaders, setModalTableHeaders] = useState([])
    const [selectedCarNumber, setSelectedCarNumber] = useState(null)
    const [selectedLapNumber, setSelectedLapNumber] = useState(null)
    const [selectedSectorNumber, setSelectedSectorNumber] = useState(null)
    const [selectedCarNumberDrivers, setSelectedCarNumberDrivers] = useState([])
    const [tableDataObject, setTableDataObject] = useState({})

    
    const handleModalOpen = ({ carNumber, lapNumber, sectorNumber}) => {
        const keys = Object.keys(props.tableDatas[0])
        const lapNumbersToFind = [lapNumber - 1, lapNumber, lapNumber + 1]

        let modalTableDatas = []
        if (sectorNumber === null) {
            modalTableDatas = findModalLapDataTableData(lapNumbersToFind, carNumber, tableDataObject, keys)
        } else {
            const modalSectorNumbers = getSurroundingSectorNumbers(sectorNumbers, sectorNumber)
            modalTableDatas = findModalSectorDataTableData(lapNumbersToFind, modalSectorNumbers, carNumber, tableDataObject, keys)
        }
    
        setSelectedCarNumber(carNumber)
        setSelectedLapNumber(lapNumber)
        setSelectedSectorNumber(sectorNumber)
        setModalTableHeaders(keys)
        setModalTableDatas(modalTableDatas)
        setIsModalOpen(true)
        
    }

    useEffect(()=>{
        if(props.selectedDatabaseTableName === 'LAP_DATA'){
            setMissingDatas(findNonSequentialCarNumbers(props.tableDatas))
            const tableDatasObj = props.tableDatas.reduce((acc, data) => {
                const key = `${data.CAR_NUMBER}-${data.LAP_NUMBER}`
                acc[key] = data
                return acc
            }, {})
            setTableDataObject(tableDatasObj)
        } else {
            setMissingDatas(findNonSequentialLapsAndSectors(props.tableDatas, sectorNumbers))
            const tableDatasObj = props.tableDatas.reduce((acc, data) => {
                const key = `${data.CAR_NUMBER}-${data.LAP_NUMBER}-${data.SECTOR_NUMBER}`
                acc[key] = data
                return acc
            }, {})
            setTableDataObject(tableDatasObj)
        }
    },[props.tableDatas])


    useEffect(()=>{
        if(props.tableDatas.length !==0 && selectedCarNumber !== null){
        const driverNames = props.tableDatas
            .filter(item => item.CAR_NUMBER === selectedCarNumber)
            .map(item => item.DRIVER_NAME)
            .filter((value, index, self) => self.indexOf(value) === index)
            setSelectedCarNumberDrivers(driverNames)
        } 
    },[selectedCarNumber])

    

    useEffect(() => {
        if(props.selectedDatabaseTableName === 'LAP_DATA'){
            setKeyColumns(['LAP_NUMBER'])
        } else if (props.selectedDatabaseTableName === 'SECTOR_DATA') {
            setKeyColumns(['LAP_NUMBER', 'SECTOR_NUMBER'])  
    }}, [props.selectedDatabaseTableName])


    useEffect(() => {
        const initialCells = props.tableDatas.map(tableData => {
            let value = tableData[props.selectedDatabaseColumnName]
            if (value !== undefined && value !== null) {
                value = (isNaN(value) || Number.isInteger(value)) ? value : Number(value).toFixed(3)
            } else {
                value = ''
            }
            return { 
                value,
                id: tableData.ID,
                carNumber: tableData.CAR_NUMBER,
                lapNumber: tableData.LAP_NUMBER,
                sectorNumber: tableData.SECTOR_NUMBER
            }
        })
        const initialValueObject = initialCells ? initialCells.reduce((acc, cell, index) => {
            if(cell){
                acc[index] = cell.value === 0 || isNaN(cell.value) || Number.isInteger(cell.value) || cell.value === '' ? cell.value : Number(cell.value).toFixed(3)
            }
            return acc
        }, {}) : {}
        
        const { mean, standardDeviation } = calculateMeanAndStandardDeviation(Object.values(initialValueObject).map(Number))
        const threshold = [mean - 3 * standardDeviation, mean + 3 * standardDeviation]

        setCells(initialCells)
        setInitialValueObject(initialValueObject) 
        setThreshold(threshold)
        setIsUpdating(false)
    }, [props.selectedDatabaseColumnName, props.tableDatas])

    return (
        <div
            style={{margin: "20px 0 0 0"}}
        >
            {isUpdating ? 
                <div style={overlayStyle}>
                    <h1>{t('general.updatingData')}</h1>
                    <CircularProgress />
                </div>
            :null}
           
            <EditDatabaseTable
                tableCarNumbers={tableCarNumbers}
                cells={cells}
                determineBackgroundColor={determineBackgroundColor}
                handleModalOpen={handleModalOpen}
                initialValueObject={initialValueObject} 
                keyColumns={keyColumns}
                lapNumbers={lapNumbers}
                missingDatas={missingDatas}
                sectorNumbers={sectorNumbers}
                selectedDatabaseTableName={props.selectedDatabaseTableName}
                setIsModalOpen={setIsModalOpen}
                setIsUpdating = {setIsUpdating}
                threshold={threshold}
            />

            <EditDatabaseModal
                isModalOpen = {isModalOpen}
                isUpdating = {isUpdating}
                modalTableDatas = {modalTableDatas}
                modalTableHeaders = {modalTableHeaders}
                selectedCarNumber = {selectedCarNumber}
                selectedCarNumberDrivers = {selectedCarNumberDrivers}               
                selectedLapNumber = {selectedLapNumber}
                selectedRaceDetailId = {props.selectedRaceDetailId}
                selectedSectorNumber = {selectedSectorNumber}
                setIsModalOpen = {setIsModalOpen}
                setIsUpdating = {setIsUpdating}
                setUpdateData = {props.setUpdateData}
            />
        </div>
       
    )
}

export default DatabaseUpdateDatasOrganism


const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    color: '#FFFFFF',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000
}