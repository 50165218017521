import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'

import { formatTime } from '../services/Util'
import upImage from '../image/scale-bar-arrow.png'

import { Line } from 'react-chartjs-2'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'

import { useTranslation } from 'react-i18next'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const plotColor = [
  '#7289DA', '#2E8B57', '#E9967A', '#F08080', '#FFC0CB',
  '#FF69B4', '#FF1493','#FF6347', '#FFD700', '#FFFACD',
  '#FFE4B5','#BDB76B', '#EE82EE', '#BA55D3', '#4B0082', 
  '#6A5ACD','#7FFF00', '#2E8B57', '#00FFFF', '#4682B4',
  '#800000','#008080', '#191970', '#D2691E'
]

const gridColor = '#5A5A5A'
const labelColor = 'white'


const SameTeamLapGraph = (props) => {
  const { t } = useTranslation(['strategy', 'general'])

  const { raceDetailId } = useParams();
  
  const [sortedTableData, setSortedTableData] = useState()
  const [graphDatas, setGraphDatas] = useState()
  const [teamLapTimeData, setTeamLapTimeData] = useState()
  
  const [ xMin, setXMin ] = useState(0)
  const [ xMax, setXMax ] = useState(100)
  const [xSlideMax, setXSlideMax] = useState(100)
  const [xSlideMin, setXSlideMin] = useState(0)
  const [ySlideMax, setYSlideMax] = useState(300)
  const [ySlideMin, setYSlideMin] = useState(0)

  const [yMin, setYMin] = useState(0)
  const [yMax, setYMax] = useState(240)
  const [options, setOptions] = useState({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {display: true,
        position: 'bottom',
        title: {
          display: true,
          text: 'LAP',
          font: {size: 18},
          color: labelColor,
        },
        ticks: {
          color: labelColor,
          font: {size:18}
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          font: {size: 18},
          color: gridColor
        }
      },
      y: {
        type: 'linear',
        display: true,
        title: {
          display: true,
          text: props.useDataRows === 'timeRows' ? 'TIME': 'SPEED',
          font: {size: 18},
          color: labelColor,
        },
        ticks: {
          color: labelColor,
          font: {size:18}
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          color: gridColor
        }
      }
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          padding: 18,
          font: {size: 18},
          usePointStyle: true,
          color: 'white'
        }
      }
    }
  })

  const [chartData, setChartData] = useState({
    labels: [],
    datasets:  []
  })

  const handleXChange = (event, newValue) => {
    setXMin(newValue[0])
    setXMax(newValue[1])
  }

  const handleYChange = (event, newValue) => {
    setYMin(newValue[0])
    setYMax(newValue[1])
  }

  const onClickMaxUp = (e) => {
    const isX = e.target.className.charAt(0) === 'x'

    if (isX) {
      setXSlideMax(xSlideMax+10)
    } else {
      setYSlideMax(ySlideMax+10)
    }
  }
    
  const onClickMaxDown = (e) => {
    const isX = e.target.className.charAt(0) === 'x'

    if (isX) {
      setXSlideMax(xSlideMax-10)
      if (xMax > xSlideMax-10) {
        setXMax(xSlideMax-10)
      }
    } else {
      setYSlideMax(ySlideMax-10)
      if (yMax > ySlideMax-10) {
        setYMax(ySlideMax-10)
      }
    }   
  }
    
  const onClickMinUp = (e) => {
    const isX = e.target.className.charAt(0) === 'x'

    if (isX) {
      setXSlideMin(xSlideMin+10)
      if (xMin < xSlideMin+10) {
        setXMin(xSlideMin+10)
      }
    } else {
      setYSlideMin(ySlideMin+10)
      if (yMin < ySlideMin+10) {
        setYMin(ySlideMin+10)
      }
    }
  }
    
  const onClickMinDown = (e) => {
    const isX = e.target.className.charAt(0) === 'x'

    if (isX) {
      if (xSlideMin === 0) return
      setXSlideMin(xSlideMin-10)
    } else {
      setYSlideMin(ySlideMin-10)
    }
  }
  
  const  controlTableData = (inputData) => {
    const tableData = {};
    tableData['header'] = Object.keys(inputData)
    tableData[props.useDataRows] = [];
    const addLength = 1
    const maxRowLength = Math.max(...Object.values(inputData).map(lapData => lapData.length))+addLength
    
    for (let i = 0; i < maxRowLength; i++) {
      const lapNumber = (i+1).toString()
      const dataRow = [lapNumber]
      for (const header of tableData['header']) {         
        if (props.sortType === 'time') {
          inputData[header] = inputData[header].sort((a, b) => a.TIME - b.TIME);
        } else if (props.sortType === 'speed') {
          inputData[header] = inputData[header].sort((a, b) => b.SPEED - a.SPEED);
        }

        if(props.useDataRows === 'timeRows'){
          const cell = inputData[header][i] ? inputData[header][i].TIME : null
          dataRow.push(cell)
        } else if (props.useDataRows === 'speedRows'){
          const cell = inputData[header][i] ? inputData[header][i].SPEED: null
          dataRow.push(cell)
        }          
      }
      tableData[props.useDataRows].push(dataRow)
    }
    setSortedTableData(tableData)
  }

  const findMinValueFromSortedDeta = (data) => {
    let minValue = Infinity;
    for (const row of data) {
      for (const value of row.slice(1)) {
        if (value !== null) {
          minValue = Math.min(minValue, value);
        }
      }
    }
    return minValue
  }

  const findMaxValueFromSortedDeta = (data) => {
    let maxValue = -Infinity
    for (const row of data) {
      for (const value of row.slice(1)) {
        if (value !== null) {
          maxValue = Math.max(maxValue, value)
        }
      }
    }
    return maxValue
  }

  const generateTimeGraphData = () =>{
    if (!sortedTableData) return null;
    let lapNumber = 0
    let ydata = 0
    const lapDatas = sortedTableData.header.map((header, index) => {
      const lapData = sortedTableData[props.useDataRows].map((row) => (
        lapNumber = row[0],
        ydata = row[index + 1],
        {x: lapNumber, y: ydata}
      ))
      
      return {
        label: header,
        data: lapData,
        borderColor: plotColor[index],
        backgroundColor: plotColor[index],
        borderWidth: 4   
      };
    });
    const labels = sortedTableData[props.useDataRows].map((row) => row[0]);

    setChartData({
      labels: labels,
      datasets: lapDatas,
    });
    setGraphDatas({ datasets: lapDatas });
  }

  
  useEffect(() => {
    if(props.carNumber){
      const getTeamLapTime = () => {
        fetch(`/api/lap/getTeamLapTimeAndSpeedByRaceDetailIdAndCarNumber/${raceDetailId}/${props.carNumber}`)
        .then(response => response.json())
        .then(json => {
            if(json.status === 200){
              setTeamLapTimeData(json.data);
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
      }
      getTeamLapTime()
    }
  }, [props])

  useEffect(() =>{
    if(teamLapTimeData){
      controlTableData(teamLapTimeData)
    }
  }, [teamLapTimeData])

  useEffect(() =>{
    if(sortedTableData){
      generateTimeGraphData(sortedTableData)
      setXMax(sortedTableData[props.useDataRows].length)
      setXSlideMax(sortedTableData[props.useDataRows].length)
      setYMin(findMinValueFromSortedDeta(sortedTableData[props.useDataRows]))
      setYMax(findMaxValueFromSortedDeta(sortedTableData[props.useDataRows]))
    }
  }, [sortedTableData])

 

  useEffect(() => {
    setOptions(prevOptions => ({
      ...prevOptions,
      scales: {
        ...prevOptions.scales,
        x: {
          ...(prevOptions.scales?.x || {}),
          min: Math.trunc(xMin),
          max: Math.trunc(xMax)
        }
      }})
    )
  }, [xMin, xMax])

  useEffect(() => {
    setOptions(prevOptions => ({
      ...prevOptions,
      scales: {
        ...prevOptions.scales,
        y: {
          ...(prevOptions.scales?.y || {}),
          min: Math.trunc(yMin), 
          max: Math.trunc(yMax),
          ...(props.useDataRows === 'timeRows' && {
            ticks: {
              ...prevOptions.scales?.y?.ticks,
              callback: (value) => formatTime(value, 2),
            }, 
          })
        }
      }
    }))
  }, [yMin, yMax])

  

  return (
    <>
      {graphDatas ?
        <div className='lapTime-container'>
          <div className='lapTime-flex-container'>
            <Stack sx={{ height: '90%' }} spacing={1}>
              <div className='flex updown'>
                  <img alt='' className='y-max-up' src={upImage} onClick={onClickMaxUp}/>
                  <img alt='' className='y-max-down' src={upImage} onClick={onClickMaxDown} style={{transform: 'rotate(180deg)'}}/>
              </div>
              <Slider
                  max={ySlideMax}
                  min={ySlideMin}
                  orientation='vertical'
                  value={[yMin, yMax]}
                  onChange={handleYChange}
                  valueLabelDisplay='auto'
                  className='chart-slider'
              />
              <div className='flex updown'>
                  <img alt='' className='y-min-up' src={upImage} onClick={onClickMinUp}/>
                  <img alt='' className='y-min-down' src={upImage} onClick={onClickMinDown} style={{transform: 'rotate(180deg)'}}/>
              </div>
            </Stack>
            <div class= "canvas_container">
              <Line options={options} data={chartData} />
              <Stack className='stack-slider-flex' spacing={1}>
              <div className='flex updown landscape'>
                  <img alt='' className='x-min-up' src={upImage} onClick={onClickMinUp} style={{transform: 'rotate(90deg)'}}/>
                  <img alt='' className='x-min-down' src={upImage} onClick={onClickMinDown} style={{transform: 'rotate(270deg)'}}/>
              </div>
              <Slider
                  max={xSlideMax}
                  min={xSlideMin}
                  value={[xMin, xMax]}
                  onChange={handleXChange}
                  orientation='horizontal'
                  valueLabelDisplay='auto'
                  className='chart-slider chart-slider-bottm'
              />
              <div className='flex updown landscape'>
                  <img alt='' className='x-max-up' src={upImage} onClick={onClickMaxUp} style={{transform: 'rotate(90deg)'}}/>
                  <img alt='' className='x-max-down' src={upImage} onClick={onClickMaxDown} style={{transform: 'rotate(270deg)'}}/>
              </div>
            </Stack> 
            </div>
          </div>    
        </div>
      : null}
    </>
  );
};

export default SameTeamLapGraph;