const toFixedIfNumber = (value, numOfDecimals) => {
    return typeof value === 'number' ? value.toFixed(numOfDecimals) : value
}

const groupLapDataByCar = (lapDataArray) => {
    const lapDataByCar = {}

    for (const lapData of lapDataArray) {
        const carNumber = lapData.CAR_NUMBER
        if (!lapDataByCar[carNumber]) {
            lapDataByCar[carNumber] = []
        }
        lapDataByCar[carNumber].push(lapData)
    }

    return lapDataByCar
}

const groupSectorDataByCar = (sectorDataArray) => {
    const sectorDataByCar = {}

    for (const sectorData of sectorDataArray) {
        const carNumber = sectorData.CAR_NUMBER
        if (!sectorDataByCar[carNumber]) {
            sectorDataByCar[carNumber] = []
        }
        sectorDataByCar[carNumber].push(sectorData)
    }

    return sectorDataByCar
}

// For heatmap
const lerpColor = (a, b, amount) => {
    const ar = a >> 16,
          ag = (a >> 8) & 0xff,
          ab = a & 0xff,
          br = b >> 16,
          bg = (b >> 8) & 0xff,
          bb = b & 0xff,
          rr = ar + amount * (br - ar),
          rg = ag + amount * (bg - ag),
          rb = ab + amount * (bb - ab)

    return (rr << 16) + (rg << 8) + (rb | 0)
}

const median = (values) => {
    if(values.length === 0) return 0

    const sortedValues = [...values].sort(function(a,b){
        return a-b
    })

    var half = Math.floor(sortedValues.length / 2)

    if (sortedValues.length % 2)
        return sortedValues[half]

    return (sortedValues[half - 1] + sortedValues[half]) / 2.0
}

const colorScale = (values, color1, color2, type, inputMedian = null) => {
    if (type === 'normal') {
        const min = Math.min(...values.filter(val => val !== null))
        const max = Math.max(...values.filter(val => val !== null))
        let medianVal = inputMedian !== null && inputMedian !== undefined && inputMedian !== '-' ? inputMedian : median(values.filter(val => val !== null))
        return values.map(val => {
            if ((val === null) ) {
                return 0xffffff
            }
            return (val === medianVal) ? lerpColor(0xffffff, color2, 0)  :val <= medianVal ? lerpColor(color1, 0xffffff, (val - min) / (medianVal - min)) : lerpColor(0xffffff, color2, (val - medianVal) / (max - medianVal))
        })

    } else if (type === 'personalDriverHistory') {
        const filteredValues = values.filter(val => typeof val === 'number' && val !== null && val !== 0)
        const min = Math.min(...filteredValues)
        const max = Math.max(...filteredValues)
        const medianVal = median(filteredValues)

        return values.map(val => {
            if (typeof val !== 'number' || val === '-' || val === null || val === 0) {
                return 0xffffff
            }
            if (val < medianVal) {
                return lerpColor(color1, 0xffffff, (val - min) / (medianVal - min))
            } else {
                return lerpColor(0xffffff, color2, (val - medianVal) / (max - medianVal))
            }
        })
    }
}

function convertHMStoDecimal(timeString) {
    const parts = timeString.split(':')

    const minutes = parseInt(parts[0])
    const secondsAndMilliseconds = parts[1] ? parts[1].split('.') : [0]
    const seconds = parseInt(secondsAndMilliseconds[0])
    const milliseconds = parseInt(secondsAndMilliseconds[1]) || 0

    const secondsInADay = 86400

    const minutesFraction = (minutes * 60) / secondsInADay
    const secondsFraction = (seconds + milliseconds / 1000) / secondsInADay

    return minutesFraction + secondsFraction
}

function convertDecimalToHMS(timeDecimal) {
    const totalSeconds = timeDecimal * 86400
  
    const remainingSeconds = totalSeconds % 3600
  
    let hour
    let minutes
    if (timeDecimal >= 0) {
        hour = Math.floor(totalSeconds / 3600)
        minutes = Math.floor(remainingSeconds / 60) % 60
    } else {
        hour = Math.ceil(totalSeconds / 3600)
        minutes = Math.ceil(remainingSeconds / 60) % 60
    }
    const seconds = remainingSeconds % 60

    const formattedTime = Math.abs(hour) > 0 ?
    `${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${seconds.toFixed(3)}` :
    `${String(minutes).padStart(2, '0')}:${seconds.toFixed(3)}`
    return formattedTime
}

function convertHMSToMS(timeStr) {
    if (timeStr.split(':').length === 2) {
        timeStr = '00:' + timeStr
    }
    
    const [hours, minutes, seconds] = timeStr.split(':')

    return parseFloat(hours) * 60 * 60 + parseFloat(minutes) * 60 + parseFloat(seconds)
}

function formatTime(seconds, decimalPlaces = 3) {
    if (seconds === undefined || seconds === null || isNaN(seconds)) {
      return ''
    }
  
    const hours = seconds >= 0 ? Math.floor(seconds / 3600) : Math.ceil(seconds / 3600)
    seconds = seconds - 3600 * hours
    const mins = seconds >= 0 ? Math.floor(seconds / 60) : Math.ceil(seconds / 60)
    const secs = decimalPlaces === 0 ? (seconds >= 0 ? Math.floor(seconds % 60) : Math.ceil(seconds % 60)) : seconds % 60
  
    const formattedMins = mins.toFixed(0).padStart(2, '0')
    const formattedSecs = decimalPlaces
                          ? secs.toFixed(decimalPlaces).padStart(decimalPlaces + 3, '0')
                          : secs.toFixed(0).padStart(2, '0')
  
    return hours
           ? `${hours}:${formattedMins}:${formattedSecs}`
           : mins
           ? `${mins}:${formattedSecs}`
           : `${formattedSecs}`
}


export {
    toFixedIfNumber,
    groupLapDataByCar,
    groupSectorDataByCar,
    colorScale,
    median,
    convertHMStoDecimal,
    convertDecimalToHMS,
    convertHMSToMS,
    formatTime
}