import SimulationRaceTotalTimeComparisonTable from '../components/SimulationRaceTotalTimeComparisonTable'
import SimulationEstimatedTyreDegradation from '../components/SimulationEstimatedTyreDegradation'
import SimulationOptionGraph from '../components/SimulationOptionGraph'

import '../css/Simulation.css'

const SimulationResultGraph = ({ resultGraphData, estimatedTyreDegradation }) => {
    return (
        <>
            <div className='graph-container'>
                <SimulationRaceTotalTimeComparisonTable
                    resultGraphData={resultGraphData}
                />
                <SimulationEstimatedTyreDegradation
                    estimatedTyreDegradation={estimatedTyreDegradation}
                />
            </div>
            <br></br>
            <SimulationOptionGraph
                lapDatas={resultGraphData}
            />
        </>
    )
}

export default SimulationResultGraph