import Header from '../components/Header'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import { useNavigate, useParams } from 'react-router-dom'

import { useEffect, useState, useContext } from 'react'
import AuthContext from '../../authContext'

import FuelManagement from '../components/FuelManagement'
import MileageManagement from '../components/MileageManagement'

import { useTranslation } from 'react-i18next'

const FuelAndTyreManagement = () => {
    const { t } = useTranslation(['strategy', 'general'])

    const { eventId } = useParams()
    const ctx = useContext(AuthContext)

    const [ isFuelManagementMode, setIsFuelManagementMode] = useState(true)

    const [ raceDetails, setRaceDetails] = useState([])
    const [ selectedRaceDetailId, setSelectedRaceDetailId] = useState(null)
    const [ carNumbers, setCarNumbers] = useState(null)
    const [ selectedCarNumber, setSelectedCarNumber] = useState('')

    const [ totalSummaryViewMode, setTotalSummaryViewMode ] = useState(false)


    function getCarNumbers(defaultRaceDetailId = null) {
        const searchRaceDetailId = selectedRaceDetailId ? selectedRaceDetailId : defaultRaceDetailId
        fetch(`/api/car/carNumbers/${parseInt(searchRaceDetailId)}`)
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                setCarNumbers(json.data)
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    useEffect(() => {
        const getRaceDetails = () => {           
            fetch(`/api/raceDetail/getAllRacesByEventId/${parseInt(eventId)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    let data = json.data
                    data = data.sort((a, b) => a.ID - b.ID)
                    setRaceDetails(data)
                    getCarNumbers(data[0].ID)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        getRaceDetails()
    }, [])

    useEffect(() => {
        selectedRaceDetailId && getCarNumbers()
    }, [selectedRaceDetailId])

    const handleCarNumberChange = (event) => {
        setSelectedCarNumber(event.target.value);
    }

    const navigate = useNavigate()
    const handleBackToSessionClick = () => {
        navigate(`/event/${eventId}`)
    }

    return (
        <>
            <Header />
            <div className='display-race-container'>
                <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                }}>
                    <div>
                        <button 
                            onClick={() => {
                                setIsFuelManagementMode(true)
                            }}
                            style={{
                                backgroundColor: isFuelManagementMode ? 'blue' : '#696969',
                                color: 'white',
                                margin: '0 5px 1px 0',
                                width: '200px',
                                height: '50px'
                            }}
                        >
                            {t('fuelAndTyreManagement.fuelManagement')}
                        </button>

                        <button 
                            onClick={() => {
                                setIsFuelManagementMode(false)
                            }}
                            style={{
                                backgroundColor: !isFuelManagementMode ? 'blue' : '#696969',
                                color: 'white',
                                margin: '0 5px 1px 0',
                                width: '200px',
                                height: '50px'
                            }}
                        >
                            {t('fuelAndTyreManagement.tyreMileageManagement')}
                        </button>
                    </div>
                    <button className='event-button' onClick={handleBackToSessionClick}>{t('general.sessionList')}</button>
                </div>
                

                <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                }}>
                    <div>
                        <FormControl sx={{ m: 1, minWidth: 180, color: 'white', borderBottom: '1px solid white' }}>
                            <InputLabel id="demo-simple-select-autowidth-label" style={{ color: 'white' }}>carNumber</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={selectedCarNumber}
                                onChange={handleCarNumberChange}
                                autoWidth
                                label="carNumber"
                                style={{ color: 'white', height: '50px', backgroundColor: selectedCarNumber ? 'blue' : '#696969'}}
                            >
                                {
                                    carNumbers && carNumbers.map((carNumber, index) => (
                                        <MenuItem 
                                            key={index}
                                            value={carNumber} 
                                            style={{ color: 'black', minWidth: 160}}
                                        >
                                            {carNumber}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        {raceDetails.map((raceDetail) => (
                            <button 
                                key={raceDetail.ID}
                                onClick={() => {
                                    setTotalSummaryViewMode(false)
                                    setSelectedRaceDetailId(raceDetail.ID)
                                }}
                                style={{
                                    backgroundColor: selectedRaceDetailId === raceDetail.ID ? 'blue' : '#696969',
                                    color: 'white',
                                    margin: '0 5px 1px 0',
                                    width: '200px',
                                    height: '50px'
                                }}
                            >
                                {raceDetail.RACE_DETAIL_NAME}
                            </button>
                        ))}

                        {isFuelManagementMode ?
                        <button 
                            onClick={() => {
                                setSelectedRaceDetailId(null)
                                setTotalSummaryViewMode(!totalSummaryViewMode)
                            }}
                            style={{
                                backgroundColor: totalSummaryViewMode ? 'blue' : '#696969',
                                color: 'white',
                                margin: '0 5px 1px 0',
                                width: '200px',
                                height: '50px'
                            }}
                        >
                            summary
                        </button> :
                        <></>
                        }

                    </div>
                </div>
                
                {
                    isFuelManagementMode ?
                    <FuelManagement eventId = {eventId}
                                selectedRaceDetailId = {selectedRaceDetailId}
                                selectedCarNumber = {selectedCarNumber}
                                userId = {ctx.userId}
                                totalSummaryViewMode = {totalSummaryViewMode}
                    />:
                    <MileageManagement eventId = {eventId}
                                selectedRaceDetailId = {selectedRaceDetailId}
                                selectedCarNumber = {selectedCarNumber}
                                userId = {ctx.userId}
                                raceDetails = {raceDetails}
                    />
                }
            </div>
        </>
    )
}

export default FuelAndTyreManagement