import React, { useState, useEffect } from 'react'

const SetupUserInputTable = (props) => {
    const [ tableData, setTableData ] = useState([])

    const title = props.title
    let data = props.data
    const type = props.type
    const isEditMode = props.isEditMode || false

    let editedData = {
        type: type,
        title: title,
        newData: data
    }

    const setEditedData = props.setEditedData

    const setIsEditMode = props.setIsEditMode
    const setDataToEdit = props.setDataToEdit
    const setSetup = props.setSetup

    const truncateValue = (value) => {
        if (!value) return ''
        if (isNaN(value)) return value

        const decimalPlaces = value.toString().split('.')[1]
        return decimalPlaces?.length > 3 ? Number(value).toFixed(3) : Number(value)
    }

    useEffect(() => {
        const tempTableData = []
        
        tempTableData.push({
            key: 0,
            elementName: '',
            base: 'Base',
            selected: 'Selected',
            recommend: 'Recommend'
        })

        data.forEach((element, index) => {
            const tempRow = {
                key: index + 1,
                elementName: element.elementName.toUpperCase(),
                base: truncateValue(element.values.base),
                selected: truncateValue(element.values.selected),
                recommend: truncateValue(element.values.recommend),
                options: element.options,
                constrain: element.constrain
            }

            tempTableData.push(tempRow)
        })

        setTableData(tempTableData)
    }, [data])

    const editModeHandler = () => {
        setIsEditMode(true)
        setDataToEdit({ title, data, type })
    }

    const saveModeHandler = () => {
        setIsEditMode(false)
        props.onClose(editedData)
    }

    const inputChangeHandler = (e) => {
        const elementName = e.target.name
        const newValue = e.target.value

        let indexName

        setTableData((prev) => {
            return prev.map(row => {
                if (row.elementName === elementName) {
                    return { ...row, selected: newValue }
                }

                return row
            })
        })

        const tempNewData = editedData?.newData.map(row => {
            if (row.elementName === elementName.toLowerCase()) {
                indexName = row.indexName
                return { ...row, values: { ...row.values, selected: newValue } }
            }

            return row
        })

        setEditedData({ type, title, indexName, newValue, newData: tempNewData })
        setSetup((prev) => {
            return prev.map(setup => {
                if (setup.Parameter === indexName) {
                    return { ...setup, Base: newValue }
                }

                return setup
            })
        })
    }

    const Select = ({ options, selectedValue, name }) => {
        return (
            <select name={name} value={ selectedValue } onChange={ inputChangeHandler }>
                {
                    options.map(option => {
                        return (
                            <option key={ option } value={ option }>{ option }</option>
                        )
                    })
                }
            </select>
        )
    }

    return (
        <>
            <div>
                <table className={`base-selected-recommend-table ${type} ${isEditMode ? 'edit-mode' : ''}`}>
                    <thead>
                        <tr>
                            <th colSpan='4'>
                                { title }
                                {isEditMode ?
                                        <span className='table-save' onClick={ saveModeHandler }></span>
                                    :
                                        (title !== 'FL' && title !== 'RL') && <span className='table-edit' onClick={ editModeHandler }></span>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.map(row => {
                                return (
                                    <tr key={ row.key }>
                                        <td className={ row.elementName === '' ? 'td-transparent' : 'td-dark' }>{ row.elementName }</td>
                                        <td className={ `center ${row.base === 'Base' ? 'td-dark': ''}` }>{ row.base }</td>
                                        <td className={
                                            `center
                                            ${row.selected !== 'Selected' && row.elementName === 'DAMPER' ? 'td-disabled' : 'td-selected'}
                                            ${(title === 'FL' || title === 'RL' || row.constrain === 'ON' || row.elementName === 'DAMPER') && row.selected !== 'Selected' ? 'td-input-disabled' : row.selected !== 'Selected' ? 'td-input-enabled' : ''}
                                            ${row.selected === 'Selected' ? 'td-dark': ''}`
                                        }>
                                            {
                                                title === 'FL' || title === 'RL' || row.constrain === 'ON' || row.selected === 'Selected' ?
                                                    truncateValue(row.selected)
                                                : row.options ? 
                                                    <Select
                                                        options= { row.options }
                                                        selectedValue={ row.selected }
                                                        name={ row.elementName }
                                                        type={ type}
                                                        title={ title }
                                                        data={ data }
                                                    />
                                                : <input
                                                        type='number'
                                                        name={ row.elementName }
                                                        value={ truncateValue(row.selected) }
                                                        onChange={ (e) => inputChangeHandler(e, type, title, data) }
                                                        disabled={row.elementName === 'DAMPER'}
                                                    />
                                            }
                                        </td>
                                        <td className={ `center ${row.recommend === 'Recommend' ? 'td-dark': ''}` }>{ row.recommend }</td>
                                    </tr>
                                )
                            })
                        } 
                    </tbody>
                </table>
            </div>
        </>
    )
}


export default SetupUserInputTable