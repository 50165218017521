import {useState, useEffect} from 'react'

import { TableContainer, Paper, Table, TableHead, TableRow, TableBody } from '@mui/material'
import Slider from '@mui/material/Slider'
import { StyledHeaderTableCell, StyledTableCell } from './TableStyle'

import { Chart } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js'

import ClassColorDisplay from './CarClass'
import notFollowImage from '../image/follow-analysis.png'
import followImage from '../image/follow.png'
import upImage from '../image/scale-bar-arrow.png'
import analysisbg from '../image/grap-analysis.png'


import CircularProgress from '@mui/material/CircularProgress'

import { useTranslation } from 'react-i18next'

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
)

const BattleAnalysis = (props) => {
    const { t } = useTranslation(['strategy', 'general'])
  
    const raceDetailId = props.raceDetailId

    const [attackChart, setAttackChart] = useState([])
    const [battleDatas, setBattleDatas] = useState([])
    const [carClassDatas, setCarClassDatas] = useState([])
    const [carNumberColorData, setCarNumberColorData] = useState({})
    const [chartData, setChartData] = useState({})
    const [circuitDatas, setCircuitDatas] = useState([])
    const [currentDriver, setCurrentDriver] = useState()
    const [defendChart, setDefendChart] = useState([])
    const [driverList, setDriverList] = useState([])
    const [finalPositions, setFinalPositions] = useState([])
    const [fixedSectorData, setFixedSectorData] = useState([])
    const [graphTime, setGraphTime] = useState([0, 0])
    const [haslackSectorDataAndTotalData,
            setHaslackSectorDataAndTotalData] = useState(false)
    const [lapCount, setLapCount] = useState([0, 0])
    const [lapTimeDatasByLaps, setLapTimeDatasByLaps] = useState([])
    const [loading, setLoading] = useState(true)
    const [maxLap, setMaxLap] = useState(0)
    const [raceData, setRaceData] = useState([])
    const [recordedCalculatedTotalTimeDatasByLapAndSector, 
        setRecordedCalculatedTotalTimeDatasByLapAndSector] = useState([])
    const [sectorDatas, setSectorDatas] = useState([])
    const [sectorLength, setSectorLength] = useState(0)
    const [showAttack, setShowAttack] = useState(true)
    const [showDefense, setShowDefense] = useState(true)
    const [showLapTime, setShowLapTime] = useState(true)
    const [tableDatas, setTableDatas] = useState([])
    const [totalTimeDatasFromLapDatas, setTotalTimeDatasFromLapDatas] = useState([])
    const [minSliderX, setMinSliderX] = useState(0)
    const [maxSliderX, setMaxSliderX] = useState(0)
    
    const isAllClassNull = (carClassDatas) => {
        if (carClassDatas !== null)
        return carClassDatas.every(carClassData => carClassData.CLASS === null)
    }

    const isSelectedClass = (carNumber, carClassDatas) => {
        return (props.selectedClasses !== null && props.selectedClasses.includes(findCarClassByCarNumber(carNumber, carClassDatas))
                || props.selectedClasses === null)
    }

    const findCarClassByCarNumber = (carNumber, carClassDatas) =>{
        if (carClassDatas){
            const findClassObject = carClassDatas.find(carClassData => carClassData.CAR_NUMBER === carNumber)
            const findClassString = findClassObject.CLASS
            return findClassString
        }
    }
    
    const findTotalTimeByCarNumberAndLapNumber = (lapDatas, carNumber, lapNumber) =>{
        if(lapDatas){
            let totalTime =  lapDatas.find(lapdata => (lapdata.CAR_NUMBER === carNumber && lapdata.LAP_NUMBER === lapNumber)).TOTAL_TIME
            totalTime = totalTime.toFixed(3)
            return totalTime
        }
    }

    

    const removeDuplicateCarNumbersFrom2DArray = (OuterArray) => {
        for (let i = 0; i < OuterArray.length; i++) {
            const innerArray = OuterArray[i]
            const carNumbers = new Set()
            const uniqueData = innerArray.filter(object => {
                if (carNumbers.has(object.carNumber)) {
                    return false
                } else {
                    carNumbers.add(object.carNumber)
                    return true
                }
            })
            OuterArray[i] = uniqueData
        }
        return OuterArray
    }

    const verifyLapDatasHasContinuousLapNumberAndTotalTime = (lapDatas) => {
        try {
          const carNumbers = [...new Set(lapDatas.map(lapData => lapData.CAR_NUMBER))]
        
          for (const carNumber of carNumbers) {
            const filteredData = lapDatas.filter(lapData => lapData.CAR_NUMBER === carNumber)
            for (let i = 0; i < filteredData.length; i++) {
              if (filteredData[i].LAP_NUMBER !== i + 1) {
                return false
              }
            }
        
            let totalTime = 0
            for (let i = 0; i < filteredData.length; i++) {
              totalTime += filteredData[i].TIME
              if (parseFloat(totalTime.toFixed(3)) !== parseFloat(filteredData[i].TOTAL_TIME.toFixed(3))) {
                return false
              }
            }
          }
        
          return true
        } catch (error) {
          return false
        }
      }

    useEffect(() => {
        const getSectorData = () => {
            fetch(`/api/race/getAllSectorData/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    let data = json.data
                    setSectorDatas(data)
                    const driverList = []
                    data.forEach((sector) => {
                        const driver = sector.DRIVER_NAME
                        const carNumber = sector.CAR_NUMBER

                        const isDriverUnique = !driverList.some((item) => item.driver === driver)

                        if (isDriverUnique) {
                            driverList.push({ driver: driver, carNumber: carNumber })
                        }
                    })
                    driverList.sort((a, b) => a.carNumber - b.carNumber)
                    setDriverList(driverList)
                    setLoading(false) 
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getCircuit = () => {
            fetch(`/api/circuit/getCircuitInfo/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200) {
                    let data = json.data
                    setSectorLength(data[0].SECTOR_LENGTH)
                    setCircuitDatas(data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getRace = () => {
            fetch(`/api/raceDetail/getRaceDetail/${raceDetailId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    let race = json.data
                    const formatDateTime = (dateTime) => {
                        const date = new Date(dateTime)
                        const hours = String(date.getHours()).padStart(2, '0')
                        const minutes = String(date.getMinutes()).padStart(2, '0')
                        const year = date.getFullYear()
                        const month = (date.getMonth() + 1).toString().padStart(2, '0')
                        const day = date.getDate().toString().padStart(2, '0')
                    
                        return {
                            time: `${hours}:${minutes}`,
                            date: `${year}-${month}-${day}`
                        }
                    }
                    
                    if (race.RACE_START_TIME !== undefined) {
                        const formattedStart = formatDateTime(race.RACE_START_TIME)
                        race.START_DATE = formattedStart.date
                        race.START_TIME = formattedStart.time
                    }
                    
                    if (race.RACE_END_TIME !== undefined) {
                        const formattedEnd = formatDateTime(race.RACE_END_TIME)
                        race.END_DATE = formattedEnd.date
                        race.END_TIME = formattedEnd.time
                    }

                    setRaceData(race)
                    setMaxLap(race.NUM_OF_LAPS ? race.NUM_OF_LAPS : 0)
                    setLapCount([0, race.NUM_OF_LAPS ? race.NUM_OF_LAPS : 0])
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            }) 
        }

        const getCarNumberColor = () => {
            fetch(`/api/car/getCarNumberColor/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    setCarNumberColorData(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getCarClasses = () =>{
            fetch(`/api/car/getCarClasses/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                    setCarClassDatas(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getLapNumbersCarNumbersTimeAndTotalTimesByRaceDetailId = () =>{
            fetch(`/api/lap/getLapNumbersCarNumbersTimeAndTotalTimesByRaceDetailId/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                    setTotalTimeDatasFromLapDatas(json.data)
                }
            })
            .catch((error) => {
                console.log(error)
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }


        if (raceDetailId) {
            getSectorData()
            getCircuit()
            getRace()
            getCarNumberColor()
            getCarClasses()
            getLapNumbersCarNumbersTimeAndTotalTimesByRaceDetailId()
        }
    }, [raceDetailId])

    useEffect(() => {
        if(sectorDatas.length === 0) return
        const fixedSectors = [...sectorDatas]
        if (raceData[0]?.RACE_ID !== 1) {
            const lastLap = fixedSectorData.reduce((accumulator, currentValue) => {
                if (currentValue.LAP_NUMBER > accumulator) {
                    accumulator = currentValue.LAP_NUMBER
                }
                return accumulator
            }, 0)

            setMaxLap(lastLap)
            setLapCount([0, lastLap])
        }

        for(let i = 1; i <= maxLap; i++) {
            for(let j = 1; j <= sectorLength; j++) {
                const currentSectorDatas = sectorDatas.filter((sector) => sector.LAP_NUMBER === i && sector.SECTOR_NUMBER === j)
                const previousSectorDatas = sectorDatas.filter((sector) => sector.LAP_NUMBER === i - 1 && sector.SECTOR_NUMBER === j)

                currentSectorDatas.forEach((currentSectorData) => {
                    const carNumber = currentSectorData.CAR_NUMBER
                    if (previousSectorDatas.length > 0 && previousSectorDatas !== undefined) {
                        const driverPreviousSectorData = previousSectorDatas.find((previousSectorData) => previousSectorData.CAR_NUMBER === carNumber)
                        if (driverPreviousSectorData === undefined) {
                            let initialMutiplier = 2

                            const findPreviousSectorData = (mutiplier, i, j) => {
                                let missingLapNumber
                                let missingSectorNumber
                                if (j === 1) {
                                    missingLapNumber = i - mutiplier
                                    missingSectorNumber = sectorLength
                                }
                                else {
                                    missingLapNumber = i - mutiplier
                                    missingSectorNumber = j
                                }

                                const replacementSectorData = sectorDatas.find((sector) => sector.CAR_NUMBER === carNumber && sector.LAP_NUMBER === missingLapNumber && sector.SECTOR_NUMBER === missingSectorNumber)

                                if (replacementSectorData === undefined && missingLapNumber > 0) {
                                    mutiplier++
                                    findPreviousSectorData(initialMutiplier)
                                } else {
                                    return replacementSectorData
                                }
                            }
                            const addToSectorData = findPreviousSectorData(initialMutiplier, i, j)
                            if (addToSectorData !== undefined) {
                                const data = {
                                    CAR_NUMBER: addToSectorData.CAR_NUMBER,
                                    DRIVER: addToSectorData.DRIVER_NAME,
                                    TIME: addToSectorData.TIME,
                                    LAP_NUMBER: Number(addToSectorData.LAP_NUMBER) + 1,
                                    SECTOR_NUMBER: addToSectorData.SECTOR_NUMBER,
                                }
                                fixedSectors.push(data)
                            }
                        }
                    }
                })
            }
        }
        setFixedSectorData(fixedSectors)
    }, [sectorDatas, maxLap])

    useEffect(() => {
        if(fixedSectorData.length === 0) return

        const hasLackTotalDatas = !(verifyLapDatasHasContinuousLapNumberAndTotalTime(totalTimeDatasFromLapDatas))
        
        const calculateTotalTime = () => {
            const calculateTotalTimeForSector = (currentSectorData, previousSectorDatas) => {
                const carNumber = currentSectorData.CAR_NUMBER
                const driver = currentSectorData.DRIVER_NAME
                const time = currentSectorData.TIME
                const lapNumber = currentSectorData.LAP_NUMBER
                const sectorNumber = currentSectorData.SECTOR_NUMBER
                let totalTime = 0
        
                if (lapNumber === 1 && sectorNumber === 1 ) {
                    totalTime = Number(currentSectorData.TIME)
                } else if (previousSectorDatas.length === 0){
                    totalTime = Number(currentSectorData.TIME)
                    lackSectorDataCarNumbers.push(carNumber)
                } else if(lackSectorDataCarNumbers.includes(carNumber) && hasLackTotalDatas){
                    totalTime = 0
                    setHaslackSectorDataAndTotalData(true)
                } else {                            
                    const driverPreviousSectorData = previousSectorDatas.find((previousSectorData) => previousSectorData.carNumber === carNumber)
                    if (driverPreviousSectorData !== undefined) {
                        if (sectorNumber === sectorLength && lackSectorDataCarNumbers.includes(carNumber) && !hasLackTotalDatas){
                            totalTime = findTotalTimeByCarNumberAndLapNumber(totalTimeDatasFromLapDatas, carNumber, lapNumber)
                        } else {
                            totalTime = Number(driverPreviousSectorData?.totalTime) + Number(currentSectorData.TIME)
                        }                         
                    }
                }
        
                return { carNumber: carNumber, driver: driver, totalTime: totalTime, time: time, lap: currentSectorData.LAP_NUMBER, sector: currentSectorData.SECTOR_NUMBER }
            }
        
            const calculatedTotalTimeDatasByLapAndSector = []
            const lackSectorDataCarNumbers = []
            for(let lapNumber = 1; lapNumber <= maxLap; lapNumber++) {
                for(let sectorNumber = 1; sectorNumber <= sectorLength; sectorNumber++) {
                    const currentSectorDatas = fixedSectorData.filter((sector) => sector.LAP_NUMBER === lapNumber && sector.SECTOR_NUMBER === sectorNumber)
                    const previousSectorDatas = calculatedTotalTimeDatasByLapAndSector[calculatedTotalTimeDatasByLapAndSector.length - 1]
                    const calculatedSectorData = currentSectorDatas.map(currentSectorData => calculateTotalTimeForSector(currentSectorData, previousSectorDatas))
                    calculatedSectorData.sort((a, b) => a.totalTime - b.totalTime)
                    calculatedTotalTimeDatasByLapAndSector.push(calculatedSectorData)          
                }
            }

            const recordedCalculatedTotalTimeDatasByLapAndSector = calculatedTotalTimeDatasByLapAndSector.map(lapData => 
                lapData.filter(driver => driver.totalTime !== 0)
            )

            const recordedCalculatedTotalTimeDatas = recordedCalculatedTotalTimeDatasByLapAndSector.flat()
            const lastSectorDatas = []
            driverList.forEach((driver) => {
                const driverSectorDatas = recordedCalculatedTotalTimeDatas.filter((recordedCalculatedTotalTimeData) => recordedCalculatedTotalTimeData.carNumber === driver.carNumber)
                const lastSectorData = driverSectorDatas[driverSectorDatas.length - 1]
                lastSectorDatas.push(lastSectorData)
            })
            lastSectorDatas.sort((a, b) => {
                if (a.lap === b.lap) {
                    if (a.sector === b.sector) {
                        return a.totalTime - b.totalTime
                    }
                    return b.sector - a.sector
                }
                return b.lap- a.lap
            })
            setFinalPositions(lastSectorDatas)
            setRecordedCalculatedTotalTimeDatasByLapAndSector(recordedCalculatedTotalTimeDatasByLapAndSector)
        }

        const calculateLapTimeData = () => {
            const lapTimeDatasByLaps = []
            for (let lapNumber = 1; lapNumber <= maxLap; lapNumber++) {
                const lap = fixedSectorData.filter(lap => lap.LAP_NUMBER === lapNumber)
                const lapData = lap.map(lap => ({
                    driver: lap.DRIVER_NAME,
                    carNumber: lap.CAR_NUMBER,
                    time: lap.TIME,
                    lap: lap.LAP_NUMBER,
                    sector: lap.SECTOR_NUMBER,
                }))
          
                const lapTimeDatas = driverList.map(driver => {
                    const driverLapData = lapData.filter(lap => lap.carNumber === driver.carNumber)
                    if (driverLapData.length === 0) return null
            
                    let lapTime = 0
            
                    if (driverLapData.length >= sectorLength) {
                        lapTime = driverLapData.reduce((accumulator, currentValue) => accumulator + currentValue.time, 0)
                    }
            
                    return {
                    driver: driver.driver,
                    carNumber: driver.carNumber,
                    lapTime: lapTime,
                    lap: lapNumber,
                    }
                })
                
                lapTimeDatasByLaps.push(lapTimeDatas.filter(lapTimeData => lapTimeData !== null))
            }
          
            const allLapTimes = lapTimeDatasByLaps.flat().map(lapTimeData => lapTimeData.lapTime).filter(lapTime => lapTime !== undefined && lapTime !== 0 && lapTime < 200)
            const firstAverageLapTime = Math.floor(allLapTimes.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / allLapTimes.length)
            const filteredLapDatas = lapTimeDatasByLaps.flat().filter(lapTimeData => lapTimeData && lapTimeData.lapTime !== undefined && lapTimeData.lapTime !== 0 && lapTimeData.lapTime < firstAverageLapTime + 30 && lapTimeData.lapTime > firstAverageLapTime - 30)
          
            const averageLapTime = Math.floor(filteredLapDatas.reduce((accumulator, currentValue) => accumulator + currentValue.lapTime, 0) / filteredLapDatas.length)

            setGraphTime([averageLapTime - 3, averageLapTime + 3])
            setLapTimeDatasByLaps(lapTimeDatasByLaps)
        }
          
        calculateTotalTime()
        calculateLapTimeData()
    }, [fixedSectorData, raceData])
    
    useEffect(() => {
        if (recordedCalculatedTotalTimeDatasByLapAndSector.length === 0) return
        
        const driversSectorDatasByLapsAndSectors = removeDuplicateCarNumbersFrom2DArray(recordedCalculatedTotalTimeDatasByLapAndSector.map(lap => lap.filter(driver => driver.lap >= lapCount[0] && driver.lap <= lapCount[1])).filter(lap => lap.length !== 0))

        const battleResultsBySectors = driversSectorDatasByLapsAndSectors.map(driversSectorDatas => {
            const battleDatas = []
            for (let i = 0; i < driversSectorDatas.length - 1; i++) {
                const driver = driversSectorDatas[i]
                const chasingDriver = driversSectorDatas[i + 1]
                const difference = chasingDriver.totalTime - driver.totalTime
                if (difference <= 1) {
                    const data = {
                        leadingCarNumber: driver.carNumber,
                        chasingCarNumber: chasingDriver.carNumber,
                        lap: driver.lap,
                        sector: driver.sector,
                        leadingDriverSectorTime: driver.time,
                        chasingDriverSectorTime: chasingDriver.time,
                        leadingDriverTotalTime: driver.totalTime,
                        chasingDriverTotalTime: chasingDriver.totalTime,
                        difference: difference,
                    }
                    battleDatas.push(data)
                }
            }
            return battleDatas
        })

        const maxLapData = driversSectorDatasByLapsAndSectors.reduce((accumulator, currentValue, index) => {
            if (currentValue.length > accumulator.length) {
                accumulator = { index: index, length: currentValue.length }
            }
            return accumulator
        }, { index: 0, length: 0 })

        const tableDatas = driversSectorDatasByLapsAndSectors[maxLapData.index].map(driverSectorDatas => ({
            carNumber: driverSectorDatas.carNumber,
            driverName: driverSectorDatas.driver,
            battleTime: 0,
            battlePercentage: 0,
            cleanPercentage: 0,
            lostTime: 0,
            gainedTime: 0,
            overtaking: 0,
            defending: 0,
            finalPosition: 0,
            lastLap: 0,
            lastSector: 0,
            lastCompletedLap: 0,
        }))

        battleResultsBySectors.forEach(battleResults => {
            battleResults.forEach(battleResult => {
                const leadingDriver = tableDatas.find(tableData => tableData.carNumber === battleResult.leadingCarNumber)
                const chasingDriver = tableDatas.find(tableData => tableData.carNumber === battleResult.chasingCarNumber)
        
                const updateDriverData = (driver, sectorTime, gainedTime, lostTime) => {
                    if (driver.lastLap !== battleResult.lap || driver.lastSector !== battleResult.sector) {
                        driver.battleTime += sectorTime
                        driver.lastSector = battleResult.sector
                        driver.lastLap = battleResult.lap
                        driver.lostTime += lostTime
                    } else {
                        driver.lostTime += lostTime
                        driver.gainedTime += gainedTime
                    }
                }
        
                updateDriverData(leadingDriver, battleResult.leadingDriverSectorTime, battleResult.chasingDriverSectorTime, battleResult.leadingDriverSectorTime)
                updateDriverData(chasingDriver, battleResult.chasingDriverSectorTime, battleResult.leadingDriverSectorTime, battleResult.chasingDriverSectorTime)
            })
        })

        const driverPerformanceDatas = tableDatas.map(tableData => ({
            carNumber: tableData.carNumber,
            driverName: tableData.driverName,
            defenseAttempts: 0,
            defenseSuccess: 0,
            overtakeAttempts: 0,
            overtakes: 0,
        }))

        battleResultsBySectors.forEach(battleResults => {
            battleResults.forEach(battleResult => {
                const leadingDriver = driverPerformanceDatas.find(driverPerformanceData => driverPerformanceData.carNumber === battleResult.leadingCarNumber)
                const chasingDriver = driverPerformanceDatas.find(driverPerformanceData => driverPerformanceData.carNumber === battleResult.chasingCarNumber)

                if (leadingDriver.carNumber !== chasingDriver.carNumber) {
                    leadingDriver.defenseAttempts++
                    chasingDriver.overtakeAttempts++
                }

                const recordedCalculatedTotalTimeDatas = recordedCalculatedTotalTimeDatasByLapAndSector.flat()
                const nextLap = battleResult.lap + (battleResult.sector === sectorLength ? 1 : 0)
                const nextSectorNumber = (battleResult.sector % sectorLength) + 1

                const leadingDriverNextSector = recordedCalculatedTotalTimeDatas.find(recordedCalculatedTotalTimeData => (
                    recordedCalculatedTotalTimeData.lap === nextLap &&
                    recordedCalculatedTotalTimeData.sector === nextSectorNumber &&
                    recordedCalculatedTotalTimeData.carNumber === battleResult.leadingCarNumber
                ))

                const chasingDriverNextSector = recordedCalculatedTotalTimeDatas.find(recordedCalculatedTotalTimeData => (
                    recordedCalculatedTotalTimeData.lap === nextLap &&
                    recordedCalculatedTotalTimeData.sector === nextSectorNumber &&
                    recordedCalculatedTotalTimeData.carNumber === battleResult.chasingCarNumber
                ))

                if (leadingDriverNextSector && chasingDriverNextSector) {
                    if (leadingDriverNextSector.totalTime <= chasingDriverNextSector.totalTime) {
                        leadingDriver.defenseSuccess++
                        battleResult.leadingDriverresult = t('battleAnalysis.defended')
                        battleResult.chasingDriverresult = t('battleAnalysis.failure')
                    } else {
                        chasingDriver.overtakes++
                        battleResult.leadingDriverresult = t('battleAnalysis.overtaken')
                        battleResult.chasingDriverresult = t('battleAnalysis.success')
                    }
                } else if (leadingDriverNextSector) {
                    leadingDriver.defenseSuccess++
                    battleResult.leadingDriverresult = t('battleAnalysis.defended')
                } else if (chasingDriverNextSector) {
                    chasingDriver.overtakes++
                    battleResult.chasingDriverresult = t('battleAnalysis.success')
                }
            })
        })

        tableDatas.forEach((tableData) => {
            let lastLap = null

            for (let i = driversSectorDatasByLapsAndSectors.length - 1; i >= 0; i--) {
                const lap = driversSectorDatasByLapsAndSectors[i].find((driverSectorData) => driverSectorData.carNumber === tableData.carNumber)
                if (lap) {
                    lastLap = lap
                    break
                }
            }            
            const driverBattleData = driverPerformanceDatas.find((driverPerformanceData) => driverPerformanceData.carNumber === tableData.carNumber)
        
            const calculateStatistics = (lastLap) => {
                tableData.finalPosition = finalPositions.findIndex((position) => position.carNumber === tableData.carNumber) + 1
                if (lastLap.totalTime === 0 || lastLap.totalTime === lastLap.time) {
                    tableData.battlePercentage = ((driverBattleData.overtakes + driverBattleData.defenseSuccess) / (driverBattleData.overtakeAttempts + driverBattleData.defenseAttempts) * 100).toFixed(2)
                } else {
                    tableData.battlePercentage = ((tableData.battleTime / lastLap.totalTime) * 100).toFixed(2)
                }
                tableData.cleanPercentage = (100 - tableData.battlePercentage).toFixed(2)
                tableData.overtakeRatio = `${driverBattleData.overtakes} / ${driverBattleData.overtakeAttempts}`
                tableData.defenseRatio = `${driverBattleData.defenseSuccess} / ${driverBattleData.defenseAttempts}`

            }
        
            if (lastLap) {
                calculateStatistics(lastLap)
            } else {
                let num = 1
                while (!lastLap) {
                    lastLap = driversSectorDatasByLapsAndSectors[driversSectorDatasByLapsAndSectors.length - num].find((lap) => lap.carNumber === tableData.carNumber)
                    num++
                }
                calculateStatistics(lastLap)
            }
        })        

        tableDatas.sort((a, b) => {
            return a.finalPosition - b.finalPosition
        })

        setBattleDatas(battleResultsBySectors)
        setTableDatas(tableDatas)
    }, [recordedCalculatedTotalTimeDatasByLapAndSector, lapCount])

    useEffect(() => {
        const attackData = []
        const defendData = []
        const stackedBarHeight = graphTime[1] ? (graphTime[1] - graphTime[0]) / sectorLength : 0
        
        const currentDriverBattles = battleDatas.flatMap((lap) => lap.filter((battle) => battle.leadingCarNumber === currentDriver || battle.chasingCarNumber === currentDriver))
        
        for (let i = 1; i <= maxLap; i++) {
            const attackLapData = []
            const defendLapData = []
            
            for (let j = 1; j <= sectorLength; j++) {
                const sectorDatas = currentDriverBattles.filter((battle) => battle.lap === i && battle.sector === j)
            
                const defendSectorData = sectorDatas.filter((battle) => battle.leadingCarNumber === currentDriver)
                const attackSectorData = sectorDatas.filter((battle) => battle.chasingCarNumber === currentDriver)
                const leadingDriverResult = defendSectorData[0]?.leadingDriverresult
                const chasingDriverResult = attackSectorData[0]?.chasingDriverresult
            
                if (sectorDatas.length === 0) {
                    const noBattleData = { value: stackedBarHeight, additionalInfo: 'No Battle', lap: i, sector: j }
                    attackLapData.push(noBattleData)
                    defendLapData.push(noBattleData)
                } else {
                    defendLapData.push({ value: stackedBarHeight, additionalInfo: defendSectorData.length !== 0 ? leadingDriverResult : 'No Battle', lap: i, sector: j })
                    attackLapData.push({ value: stackedBarHeight, additionalInfo: attackSectorData.length !== 0 ? chasingDriverResult : 'No Battle', lap: i, sector: j })
                }
            }
            
            attackData.push(attackLapData)
            defendData.push(defendLapData)
        }
        
        attackData.forEach((lap) => {
            lap.forEach((sector) => {
                if (sector.additionalInfo === undefined) {
                    sector.additionalInfo = t('battleAnalysis.notEnoughData')
                }
            })
        })
        defendData.forEach((lap) => {
            lap.forEach((sector) => {
                if (sector.additionalInfo === undefined) {
                    sector.additionalInfo = t('battleAnalysis.notEnoughData')
                }
            })
        })
        setDefendChart(defendData)
        setAttackChart(attackData)
    }, [currentDriver, battleDatas, graphTime])

    useEffect(() => {
        setMinSliderX(0)
        setMaxSliderX(maxLap)
    }, [currentDriver])

    useEffect(() =>{
        if(haslackSectorDataAndTotalData){
            setLoading(false)
        }
    }, [haslackSectorDataAndTotalData])
      

    useEffect(() => {
        const labels = []
        for (let i = 1; i <= maxLap; i++) {
            labels.push(i)
        }
        const transparent = []
        for (let i = 1; i <= maxLap; i++) {
            transparent.push({value: graphTime[0], additionalInfo: 'ignore'})
        }

        const getAdditionalInfo = (chart, sector, context) => {
            const battleInfo = chart.flat(2).filter((lap) => lap.sector === sector)
            const additionalInfo = battleInfo[context.dataIndex]?.additionalInfo
            return additionalInfo === 'No Battle' ? 'No Battle' : `${additionalInfo}`
        }

        const lapTimeDataByLap = lapTimeDatasByLaps.map((lap) => lap.find((driver) => driver && driver.carNumber === currentDriver)).filter(lap => lap !== undefined && lap.lapTime !== 0)
        const data = {
            labels,
            datasets: [
                {
                    type: 'line',
                    label: 'Lap Time',
                    data: lapTimeDataByLap.map((lap) => lap.lapTime),
                    fill: false,
                    backgroundColor: 'white',
                    borderColor: 'white',
                    borderWidth: 2,
                    hidden: !showLapTime,
                },
                {
                    type: 'bar',
                    label: 'transparentAttack',
                    data: transparent.map((lap) => lap.value),
                    backgroundColor: 'transparent',
                    stack: 'Attack',
                    hidden: !showAttack,

                },
                {
                    type: 'bar',
                    label: 'Attack1',
                    data: attackChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo !== 'No Battle' && sector.sector === 1).map((lap) => lap.value)}),
                    backgroundColor: 'rgba(100, 149, 237, 0.8)',
                    borderColor: 'transparent',
                    borderWidth: {
                        top: 2,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    stack: 'Attack',
                    hidden: !showAttack,
                },
                {
                    type: 'bar',
                    label: 'AttackTransparent',
                    data: attackChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo === 'No Battle' && sector.sector === 1).map((lap) => lap.value)}),
                    backgroundColor: 'transparent',
                    stack: 'Attack',
                    hidden: !showAttack
                },
                {
                    type: 'bar',
                    label: 'Attack2',
                    data: attackChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo !== 'No Battle' && sector.sector === 2).map((lap) => lap.value)}),
                    backgroundColor: 'rgba(100, 149, 237, 0.8)',
                    borderColor: 'transparent',
                    borderWidth: {
                        top: 2,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    stack: 'Attack',
                    hidden: !showAttack
                },
                {
                    type: 'bar',
                    label: 'AttackTransparent',
                    data: attackChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo === 'No Battle' && sector.sector === 2).map((lap) => lap.value)}),
                    backgroundColor: 'transparent',
                    stack: 'Attack',
                    hidden: !showAttack
                },
                {
                    type: 'bar',
                    label: 'Attack3',
                    data: attackChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo !== 'No Battle' && sector.sector === 3).map((lap) => lap.value)}),
                    backgroundColor: 'rgba(100, 149, 237, 0.8)',
                    borderColor: 'transparent',
                    borderWidth: {
                        top: 2,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    stack: 'Attack',
                    hidden: !showAttack
                },
                {
                    type: 'bar',
                    label: 'AttackTransparent',
                    data: attackChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo === 'No Battle' && sector.sector === 3).map((lap) => lap.value)}),
                    backgroundColor: 'transparent',
                    stack: 'Attack',
                    hidden: !showAttack
                },
                {
                    type: 'bar',
                    label: 'Attack4',
                    data: attackChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo !== 'No Battle' && sector.sector === 4).map((lap) => lap.value)}),
                    backgroundColor: 'rgba(100, 149, 237, 0.8)',
                    borderColor: 'transparent',
                    borderWidth: {
                        top: 2,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    stack: 'Attack',
                    hidden: !showAttack
                },
                {
                    type: 'bar',
                    label: 'AttackTransparent',
                    data: attackChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo === 'No Battle' && sector.sector === 4).map((lap) => lap.value)}),
                    backgroundColor: 'transparent',
                    stack: 'Attack',
                    hidden: !showAttack
                },
                {
                    type: 'bar',
                    label: 'transparentDefense',
                    data: transparent.map((lap) => lap.value),
                    backgroundColor: 'transparent',
                    stack: 'Defense',
                    hidden: !showDefense, 
                },
                {
                    type: 'bar',
                    label: 'Defense1',
                    data: defendChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo !== 'No Battle' && sector.sector === 1).map((lap) => lap.value)}),
                    backgroundColor: 'rgba( 244	, 164, 96, 0.8)',
                    borderColor: 'transparent',
                    borderWidth: {
                        top: 2,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    stack: 'Defense',
                    hidden: !showDefense, 
                },
                {
                    type: 'bar',
                    label: 'DefenseTransparent',
                    data: defendChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo === 'No Battle' && sector.sector === 1).map((lap) => lap.value)}),
                    backgroundColor: 'transparent',
                    stack: 'Defense',
                    hidden: !showDefense, 
                },
                {
                    type: 'bar',
                    label: 'Defense2',
                    data: defendChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo !== 'No Battle' && sector.sector === 2).map((lap) => lap.value)}),
                    backgroundColor: 'rgba( 244	, 164, 96, 0.8)',
                    borderColor: 'transparent',
                    borderWidth: {
                        top: 2,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    stack: 'Defense',
                    hidden: !showDefense, 
                },
                {
                    type: 'bar',
                    label: 'DefenseTransparent',
                    data: defendChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo === 'No Battle' && sector.sector === 2).map((lap) => lap.value)}),
                    backgroundColor: 'transparent',
                    stack: 'Defense',
                    hidden: !showDefense, 
                },
                {
                    type: 'bar',
                    label: 'Defense3',
                    data: defendChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo !== 'No Battle' && sector.sector === 3).map((lap) => lap.value)}),
                    backgroundColor: 'rgba( 244	, 164, 96, 0.8)',
                    borderColor: 'transparent',
                    borderWidth: {
                        top: 2,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },

                    stack: 'Defense',
                    hidden: !showDefense, 
                },
                {
                    type: 'bar',
                    label: 'DefenseTransparent',
                    data: defendChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo === 'No Battle' && sector.sector === 3).map((lap) => lap.value)}),
                    backgroundColor: 'transparent',
                    stack: 'Defense',
                    hidden: !showDefense, 
                },
                {
                    type: 'bar',
                    label: 'Defense4',
                    data: defendChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo !== 'No Battle' && sector.sector === 4).map((lap) => lap.value)}),
                    backgroundColor: 'rgba( 244	, 164, 96, 0.8)',
                    borderColor: 'transparent',
                    borderWidth: {
                        top: 2,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    stack: 'Defense',
                    hidden: !showDefense, 
                },
                {
                    type: 'bar',
                    label: 'DefenseTransparent',
                    data: defendChart.map((lap) => {return lap.filter((sector) => sector.additionalInfo === 'No Battle' && sector.sector === 4).map((lap) => lap.value)}),
                    backgroundColor: 'transparent',
                    stack: 'Defense',
                    hidden: !showDefense, 
                },
            ],
        }
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                intersect: false,
            },
            scales: {
                x: {
                    stacked: true,
                    title: {
                        display: true,
                        // text: 'Lap',
                        color: '#fff',
                        font: {
                            size: 18,
                        }
                    },
                    ticks: {
                        callback: function(value) {
                            return value % 2 === 0 ? value + 1 : ''
                        },
                        color: '#fff',
                        font: {
                            size: 14,
                        },
                    },
                    grid: {
                        display: false,
                    },
                    border: {
                        display: true,
                        color: 'white',
                    },
                    max: lapCount[1],
                    min: lapCount[0],
                },
                y: {
                    stacked: true,
                    title: {
                        display: true,
                        // text: 'Lap Time',
                        color: '#fff',
                        font: {
                            size: 18,
                        }
                    },
                    ticks: {
                        color: '#fff',
                        font: {
                            size: 16,
                        }
                    },
                    grid: {
                        display: false,
                    },
                    border: {
                        display: true,
                        color: 'white',
                    },
                    max: graphTime[1],
                    min: graphTime[0],
                },
                yRight: {
                    position: 'right',
                    title: {
                        display: true,
                        // text: 'Sector',
                        color: '#fff',
                        font: {
                            size: 18,
                        },
                    },
                    ticks: {
                        color: '#fff',
                        font: {
                            size: 16,
                        },
                        stepSize: 1,
                    },
                    max: sectorLength,
                    min: 1,
                    offset: 10,
                    border: {  
                        display: true,
                        color: 'white',
                    },
                },
            },
            plugins: {
                legend: {
                    position: 'top',
                    labels: {
                        color: 'white',
                        padding: 10,
                        font: {
                            size: 14,
                        },
                        filter: function(item) {
                            return null
                        },
                        usePointStyle: true,
                    },
                },
                tooltip: {
                    callbacks: {
                        mode: 'index',
                        title: function(context) {
                            return null
                        },
                        label: function(context) {
                            const label = context.dataset.label || ''
                            if (context.parsed.y !== undefined) {
                                switch (label) {
                                    case 'Defense1':
                                    case 'Defense2':
                                    case 'Defense3':
                                    case 'Defense4':
                                        return getAdditionalInfo(defendChart, Number(label.slice(-1)), context)
                            
                                    case 'Attack1':
                                    case 'Attack2':
                                    case 'Attack3':
                                    case 'Attack4':
                                        return getAdditionalInfo(attackChart, Number(label.slice(-1)), context)
                            
                                    case 'DefenseTransparent':
                                    case 'AttackTransparent':
                                        return 'No Battle'
                                }
                            }
                        }
                    },
                    intersect: false,
                }
            }
        }
        setChartData({ data, options })
    }, [attackChart, defendChart, graphTime, showAttack, showDefense, showLapTime])

    const handleLapCount = (event, newValue) => {
        if (newValue[1] === 0) return
        setLapCount(newValue)
    }

    const handleGraphTime = (event, newValue) => {
        setGraphTime(newValue)
    }

    const handleToggleLapTime= () => {
        setShowLapTime(!showLapTime)
    }
    const handleToggleAttack = () => {
        setShowAttack(!showAttack)
    }
    const handleToggleDefense = () => {
        setShowDefense(!showDefense)
    }

    const onClickMaxUp = (e) => {
        setMaxSliderX(maxSliderX + 1)
    }
    
    const onClickMaxDown = (e) => {
        setMaxSliderX(maxSliderX - 1) 
    }
    
    const onClickMinUp = (e) => {
        setMinSliderX(minSliderX + 1)
    }
    
    const onClickMinDown = (e) => {
        setMinSliderX(minSliderX - 1)
    }

    const TableheadStyle ={
        padding:'5px',
        fontWeight:'bold',
        color:'#fff',
    }

    const TablecellStyle ={
        padding:'5px',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        width:'auto',
        color:'#000 !important',
    }

    const TableCarNoStyle ={
        fontSize: '20px',
        fontWeight: 'bold',
        padding:'5px',
        whiteSpace: 'nowrap',
        width:'auto',
    }

    return (
        <>
            {!haslackSectorDataAndTotalData && tableDatas.length !== 0 ? (
                <TableContainer component={Paper} sx={{backgroundColor: 'transparent'}}>
                    <Table className='live-table'>
                        <TableHead>
                            <TableRow>
                                <StyledHeaderTableCell sx={{...TableheadStyle}}>Follow</StyledHeaderTableCell>
                                <StyledHeaderTableCell sx={{...TableheadStyle}}>No.</StyledHeaderTableCell>
                                { isAllClassNull(carClassDatas) ? null : <StyledHeaderTableCell sx={{...TableheadStyle}}>Class</StyledHeaderTableCell>}
                                {raceData.RACE_ID === 1 ? <StyledHeaderTableCell sx={{...TableheadStyle}}>{t('battleAnalysis.driver')}</StyledHeaderTableCell> : ''}
                                <StyledHeaderTableCell sx={{...TableheadStyle}}>{t('battleAnalysis.battlePercentage')}</StyledHeaderTableCell>
                                <StyledHeaderTableCell sx={{...TableheadStyle}}>{t('battleAnalysis.cleanPercentage')}</StyledHeaderTableCell>
                                <StyledHeaderTableCell sx={{...TableheadStyle}}>{t('battleAnalysis.overtakeSuccessCount')}</StyledHeaderTableCell>
                                <StyledHeaderTableCell sx={{...TableheadStyle}}>{t('battleAnalysis.defenseSucccessCount')}</StyledHeaderTableCell>
                                <StyledHeaderTableCell sx={{...TableheadStyle}}>{t('battleAnalysis.finalPosition')}</StyledHeaderTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableDatas.map((tableData, index) => (
                                isSelectedClass(tableData.carNumber, carClassDatas) ? (
                                <>
                                    <TableRow  key={tableData.carNumber}>    
                                        <StyledTableCell className='follow-cell'>
                                            {currentDriver === tableData.carNumber ? (
                                                <img src={followImage} alt='Follow中' onClick={() => setCurrentDriver('')} />
                                            ) : (
                                                <img src={notFollowImage} alt='notFollow' onClick={() => setCurrentDriver(Number(tableData.carNumber))} />
                                            )}
                                        </StyledTableCell>
                                        { carNumberColorData && carNumberColorData[tableData.carNumber] ? 
                                            <td className='carno-cell' sx={{...TableCarNoStyle, backgroundColor: carNumberColorData[tableData.carNumber]['COLOR_BG'], color: (carNumberColorData[tableData.carNumber]['COLOR_TEXT'] === null) ? `#242424 !important`: `${carNumberColorData[tableData.carNumber]['COLOR_TEXT']} !important`}}>{tableData.carNumber}</td>
                                            : <StyledTableCell sx={{...TableCarNoStyle}}>{tableData.carNumber}</StyledTableCell>
                                        }
                                        { isAllClassNull(carClassDatas) ?  null : <StyledTableCell sx={{...TablecellStyle}}> {ClassColorDisplay(findCarClassByCarNumber(tableData.carNumber, carClassDatas), 'table') }</StyledTableCell>} 
                                        {raceData.RACE_ID === 1 ? <StyledTableCell sx={{...TablecellStyle}}><div className='display-black-text'>{tableData.driverName}</div></StyledTableCell> : ''}
                                        <StyledTableCell sx={{...TablecellStyle}}><div className='display-black-text'>{tableData.battlePercentage}%</div></StyledTableCell>
                                        <StyledTableCell sx={{...TablecellStyle}}><div className='display-black-text'>{tableData.cleanPercentage}%</div></StyledTableCell>
                                        <StyledTableCell sx={{...TablecellStyle}}><div className='display-black-text'>{tableData.overtakeRatio}</div></StyledTableCell>
                                        <StyledTableCell sx={{...TablecellStyle}}><div className='display-black-text'>{tableData.defenseRatio}</div></StyledTableCell>
                                        <StyledTableCell sx={{...TablecellStyle}}><div className='display-black-text'>{tableData.finalPosition !== 0 ? index + 1 : index + 1}</div></StyledTableCell>                                        
                                    </TableRow>
                                    {currentDriver === tableData.carNumber && (
                                    <TableRow
                                    className='race-chart-tr'
                                    key={tableData.carNumber}
                                    >
                                    <td className='race-chart-td' colSpan={9}>
                                        <img className='race-chart-bg' src={analysisbg} />
                                        <div className='display-race-chart-container'>
                                            <div className='display-race-chart'>
                                                <div className='display-race-chart-container-left'>
                                                    <span className='slider-text slider-text-laptime'>
                                                        LAPTIME
                                                        <ul className='slider-text-dec'>
                                                            <li></li>
                                                            <li></li>
                                                            <li></li>
                                                            <li></li>
                                                            <li></li>
                                                            <li></li>
                                                            <li></li>
                                                            <li></li>
                                                        </ul>
                                                    </span>
                                                    <div className='updown'>
                                                        <img src={upImage} className='y-max-up'/>
                                                        <img src={upImage} className='y-max-down' />
                                                    </div>
                                                    <Slider
                                                        max={150}
                                                        min={60}
                                                        value={graphTime}
                                                        onChange={handleGraphTime}
                                                        getAriaLabel={() => 'Graph Time Range'}
                                                        orientation='vertical'
                                                        valueLabelDisplay='auto'
                                                    />
                                                    <div className='updown'>
                                                        <img src={upImage} className='y-max-up'/>
                                                        <img src={upImage} className='y-max-down' />
                                                    </div>
                                                    <div className='flex updown'>
                                                        <img className='y-max-up'/>
                                                        <img className='y-max-down' />
                                                    </div>
                                                </div>
                                                <div className='display-race-chart-sort'>
                                                    <label>
                                                        <input
                                                            id='display-race-chart-laptime'
                                                            type='checkbox'
                                                            checked={showLapTime}
                                                            onChange={handleToggleLapTime}
                                                        />
                                                        <span className='lap-time' />
                                                        LAP TIME
                                                    </label>
                                                    <label>
                                                        <input
                                                            id='display-race-chart-showattack'
                                                            type='checkbox'
                                                            checked={showAttack}
                                                            onChange={handleToggleAttack}
                                                        />
                                                        <span className='attack' />
                                                        ATTACK
                                                    </label>
                                                    <label>
                                                        <input
                                                            id='display-race-chart-showdefense'
                                                            type='checkbox'
                                                            checked={showDefense}
                                                            onChange={handleToggleDefense}
                                                        />
                                                        <span className='defense' />
                                                        DEFENSE
                                                    </label>
                                                </div>
                                                <span className='slider-text slider-text-sector'>
                                                    SECTOR
                                                    <ul className='slider-text-dec'>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </span>

                                                <Chart
                                                    options={chartData.options}
                                                    data={chartData.data}
                                                />
                                                <div className='display-race-chart-container-bottom'>
                                                    <span className='slider-text slider-text-lap'>
                                                    LAP
                                                        <ul className='slider-text-dec'>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        </ul>
                                                    </span>
                                                    <div className='updown'>
                                                        <img src={upImage} className='y-max-up' onClick={onClickMinDown}/>
                                                        <img src={upImage} className='y-max-down' onClick={onClickMinUp}/>
                                                    </div>
                                                    <Slider
                                                        max={maxSliderX}
                                                        min={minSliderX}
                                                        value={lapCount}
                                                        onChange={handleLapCount}
                                                        getAriaLabel={() => 'Lap Range'}
                                                        orientation='horizontal'
                                                        valueLabelDisplay='auto'
                                                        />
                                                    <div className='updown'>
                                                        <img src={upImage} className='y-max-up' onClick={onClickMaxDown}/>
                                                        <img src={upImage} className='y-max-down' onClick={onClickMaxUp}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className='race-chart-line-dec'></span>
                                    </td>
                                    </TableRow>
                                    )}
                                </>
                                ) : null
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                )  : loading ? (
                    <div className='display-race-none'>
                        <h1>{t('general.readingData')}</h1>
                        <CircularProgress />
                    </div>
                ) : driverList.length === 0 ? (
                    <div className='display-race-none'>
                        <h1>{t('battleAnalysis.noData')}</h1>
                    </div>
                ) : haslackSectorDataAndTotalData ? (
                    <div className='display-race-none'>
                        <h1>{t('battleAnalysis.missingData')}</h1>
                        <h1>{t('pleaseContactAdmin', {ns: 'general'})}</h1>
                    </div>
                ) : null}      
        </>
    )
}

export default BattleAnalysis