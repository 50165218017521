import React, { useEffect, useState } from 'react'

import { Line } from 'react-chartjs-2'
import Slider from '@mui/material/Slider'

import findKeyByAxis from '../services/findKeyByAxis'

import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const TimeSeriesChartTimeDistance = (props) =>  {
    const { t } = useTranslation(['core'])

    const initialChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                ticks: {
                    font: {
                        size: 10,
                    },
                    color: '#fff',
                    textStrokeColor: '#fff',
                    padding: 10
                },
                grid: {
                    color: '#fff',
                    borderColor: '#fff',
                },  
            },
            y: {
                type: 'linear',
                position: 'left',
                ticks: {
                    font: {
                        size: 10,
                    },
                    color: '#fff',
                    textStrokeColor: '#fff',
                    padding: 10
                },
                grid: {
                    // color: '#fff',
                    color: (context) => {
                    },
                    borderColor: '#fff',
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: '#4AFFFF'
                }
            }
        }
    }

    const [ xMin, setXMin ] = useState(0)
    const [ xMax, setXMax ] = useState(100)
    const [ xAsTime, setXAsTime ] = useState(false)
    const [ xAxisData, setXAxisData ] = useState()

    const [ chartData, setChartData ] = useState({
        labels: [],
        datasets: [{
            label: '',
            data: [],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0)',
        }]
    })
    const [chartOptions, setChartOptions] = useState(initialChartOptions)

    const timeSeriesList = props.timeSeriesList
    const graphData = props.graphData
    const plotData = graphData.plotData

    const isSetup = props.isSetup

    const xWithDistance = findKeyByAxis(plotData, 'x')
    const xWithTime = findKeyByAxis(plotData, 'xTime')

    const graphDataToPlot = []

    ChartJS.register({
        id: 'custom_canvas_background_color',
        beforeDraw: (chart) => {
            const { ctx, chartArea } = chart
            ctx.save()
            ctx.globalCompositeOperation = 'destination-over'
            ctx.fillStyle = 'rgba(175, 231, 255, 0.2)'
            ctx.fillRect(chartArea.left, chartArea.top, chartArea.width, chartArea.height)
            ctx.restore()
        }
    })

    useEffect(() => {
        const channelName = xAsTime ? xWithTime : xWithDistance
        setXAxisData(plotData[channelName])
    }, [plotData])

    useEffect(() => {
        if (!xAxisData) return

        let channelCounter = 1
        for (const [channel, timeSeriesData] of Object.entries(plotData)) {
            if (channel === xWithDistance || channel === xWithTime) continue
            timeSeriesData.forEach((timeSeries, iTimeSeries) => {
                const { fileName, data } = timeSeries

                if (!data) return

                const sameTimeSeries = timeSeriesList.find(ts => ts.fileName === fileName)

                if (!sameTimeSeries || !sameTimeSeries.visible) return

                const color = sameTimeSeries.color

                const singleTimeSeriesPlotData = []
                data.forEach((d, iData) => {
                    singleTimeSeriesPlotData.push({
                        x: xAxisData[iTimeSeries].data[iData],
                        y: d
                    })
                })

                const singleTimeSeriesData = {
                    plotData: singleTimeSeriesPlotData,
                    label: `${fileName}${isSetup ? ':' : `_${channel}` }`,
                    color,
                    channel,
                    max: Math.max(...singleTimeSeriesPlotData.map(d => d.y)),
                    min: Math.min(...singleTimeSeriesPlotData.map(d => d.y))
                }

                if (channelCounter > 1) {
                    if (channelCounter !== graphData.channels.length) {
                        singleTimeSeriesData.yAxisID = `y${(graphData.channels.length + 1) - channelCounter}`
                    }

                    if (channelCounter === 2) {
                        graphDataToPlot[0].yAxisID = `y${graphData.channels.length}`
                    }
                }

                graphDataToPlot.push(singleTimeSeriesData)
            })

            channelCounter++
        }

        const xMaxTemp = Math.max(...xAxisData.map(d => d.data[d.data.length - 1]))
        setXMax(xMaxTemp)

        const datasets = graphDataToPlot.map((data, i) => {
            const dataset = {
                label: data.label,
                data: data.plotData,
                borderColor: data.color,
                borderWidth: 3,
                pointRadius: 0,
                fill: false,
                yAxisID: data.yAxisID || 'y'
            }

            if (data.yAxisID) {
                dataset.yAxisID = data.yAxisID
                setChartOptions(prevOptions => ({
                    ...prevOptions,
                    scales: {
                        ...prevOptions.scales,
                        y: {
                            ...(prevOptions.scales?.y || {}),
                            stacked: true,
                            stack: `${graphData.id}`
                        },
                        [data.yAxisID]: {
                            ...(prevOptions.scales?.y || {}),
                            stack: `${graphData.id}`,
                            offset: true
                        }
                    }
                }))
            }

            return dataset
        })

        setChartData({
            labels: [],
            datasets: datasets.length > 0 ? datasets : [{
                label: '',
                data: [],
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(0, 0, 0, 0)',
            }]
        })

        if (datasets.length < Object.keys(chartOptions.scales).length - 1) {
            const acceptedScales = datasets.map(d => d.yAxisID ? d.yAxisID : 'y')
            acceptedScales.push('x')

            const filteredScale = Object.keys(chartOptions.scales)
                .filter(key => acceptedScales.includes(key))
                .reduce((result, key) => {
                    result[key] = chartOptions.scales[key]
                    return result
                }, {})

            setChartOptions(prevOptions => ({
                ...prevOptions,
                scales: filteredScale
            }))
        }

        if (!chartOptions || !datasets.length) setChartOptions(initialChartOptions)
    }, [xAxisData, timeSeriesList, graphData])

    useEffect(() => {
        const channelName = xAsTime ? xWithTime : xWithDistance
        setXAxisData(plotData[channelName])
    }, [xAsTime])

    useEffect(() => {
        if (!chartOptions || !chartData.datasets.length) return

        setChartOptions(prevOptions => ({
            ...prevOptions,
            scales: {
                ...prevOptions.scales,
                x: {
                    ...(prevOptions.scales?.x || {}),
                    min: Math.trunc(xMin),
                    max: Math.trunc(xMax)
                }
            }
        }))
    }, [xMin, xMax])

    const xSliderHandler = (_, newValue) => {
        setXMin(newValue[0])
        setXMax(newValue[1])
    }

    const changeXAxisHandler = (e) => {
        setXAsTime(e.target.value === 'time')
    }

    return (
        chartData.datasets.length && (
            <>
                <div className='time-checker-div'>
                    <input className='time-checker' type='radio' name={`time-distance-checker-${graphData.id}`} value='time' id={`time-checker-${graphData.id}`} onChange={ changeXAxisHandler }/>
                    <label className='time-checker-label' htmlFor={`time-checker-${graphData.id}`}>{t('general.xAxis')}＝Time</label> 
                    <input className='time-checker' type='radio' name={`time-distance-checker-${graphData.id}`} value='distance' id={`distance-checker-${graphData.id}`} onChange={ changeXAxisHandler } defaultChecked/>
                    <label className='time-checker-label' htmlFor={`distance-checker-${graphData.id}`}>{t('general.xAxis')}=Distance</label>
                </div>
                <div className='canvas-container'>
                    <Line data={ chartData } options={ chartOptions } />
                </div>
                <Slider
                    orientation='horizontal'
                    value={ [xMin, xMax] }
                    onChange={ xSliderHandler }
                    valueLabelDisplay='auto'
                    size='small'
                    sx={{
                        color: '#4AFFFF',
                        '& .MuiSlider-thumb': {
                            backgroundColor: '#4AFFFF',
                            width: '8px',
                            height: '8px'
                        },
                        '& .MuiSlider-thumb:hover': {
                            boxShadow: '0px 0px 0px 8px rgba(74, 255, 255, 0.16)'
                        }
                    }}
                />
            </>
        )
    )
}

export default TimeSeriesChartTimeDistance