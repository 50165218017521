import React, { useEffect, useState } from 'react'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import SameTeamLapTable from './SameTeamLapTable'
import SameTeamLapGraph from './SameTeamLapGraph'

import { useTranslation } from 'react-i18next'

const SameTeamAnalysis = ({ raceDetailId }) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [ activeSort, setActiveSort] = useState(null)
    const [ carNumbers, setCarNumbers] = useState([])
    const [ selectedCarNumber, setSelectedCarNumber] = useState('')
    
    
    const handleCarNumberChange = (event) => {
        setSelectedCarNumber(event.target.value);
    }
    
    const handleSortChange = (event) => {
        const sortType = event.target.name

        if (activeSort === sortType) {
            // Clicking on the active switch, toggle it off
            setActiveSort(null)
        } else {
            // Clicking on an inactive switch, toggle it on
            setActiveSort(sortType)
        }
    }

    useEffect(() => {
        const getCarNumbers = () => {
            fetch(`/api/car/carNumbers/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    setCarNumbers(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        getCarNumbers()
    }, [])

    return (
        <>
            <div style={{margin: '50px 0 0 0',}}>
                <span className='carNo-info'>{t('general.pleaseSelectCarNumber')}</span>
                <FormControl className='car-num-selecter-box' >

                    <span className='car-num-selecter-label'>Car Number</span>
                    <Select
                        labelId='demo-simple-select-autowidth-label'
                        id='demo-simple-select-autowidth'
                        value={selectedCarNumber}
                        onChange={handleCarNumberChange}
                        autoWidth
                        label='carNumber'
                        className='car-num-selecter'
                    >
                        {
                            carNumbers && carNumbers.map((carNumber, index) => (
                                <MenuItem 
                                    key={`select-${index}`}
                                    value={carNumber} 
                                    style={{ color: 'black', minWidth: 160}}
                                >
                                    {carNumber}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <label className={`switch square`}>
                    <input
                        type='checkbox'
                        className='switch-input'
                        name='time'
                        checked={activeSort === 'time'}
                        onChange={handleSortChange}
                    />
                    <span className='slider square'></span>
                    </label>
                    <div>{t('sameTeamAnalysis.lapOrder')}</div>

                    <div style={{ marginLeft: '20px' }}></div>
                    <label className={`switch square`}>
                        <input
                        type='checkbox'
                        className='switch-input'
                        name='speed'
                        checked={activeSort === 'speed'}
                        onChange={handleSortChange}
                        />
                        <span className='slider square'></span>
                    </label>
                    <div>{t('sameTeamAnalysis.speedOrder')}</div>
                </div>
                <SameTeamLapTable carNumber={selectedCarNumber} sortType = {activeSort}/>
                <SameTeamLapGraph carNumber={selectedCarNumber} useDataRows = {'timeRows'} sortType = {activeSort}/>
                <SameTeamLapGraph carNumber={selectedCarNumber} useDataRows = {'speedRows'} sortType = {activeSort}/>
            </div>
        </>
    )
}

export default SameTeamAnalysis