import React from 'react'
import { Bar } from 'react-chartjs-2'

import { useTranslation } from 'react-i18next'

const options = {
    plugins: {
        legend: {
        labels: {
            color: 'white', 
        },
        },
    },
    scales: {
        x: {
            grid: {
                color: '#FFFFFF',
            },
            ticks: {
                color: '#FFFFFF',
            },
        },
        y: {
            min: 0,
            max: 100,
            grid: {
                color: '#FFFFFF',
            },
            ticks: {
                stepSize: 25,
                color: '#FFFFFF',
            }
        }
    }
}

const TrackTempAndAttackLapNumber = () => {
    const { t } = useTranslation(['strategy'])

    const data = {
        labels: ['OutLap', `${t('trackTempAndAttackLapNumber.lap')} 2`, `${t('trackTempAndAttackLapNumber.lap')} 3`, `${t('trackTempAndAttackLapNumber.lap')} 4`, `${t('trackTempAndAttackLapNumber.lap')} 5`, `${t('trackTempAndAttackLapNumber.lap')} 6`, `${t('trackTempAndAttackLapNumber.lap')} 7`, `${t('trackTempAndAttackLapNumber.lap')} 8`],
        datasets: [
            {
                label: `${t('trackTempAndAttackLapNumber.trackTemp')} 15-20`,
                data: [0, 0, 0, 0, 0, 0, 0, 0],
                backgroundColor: 'rgba(54, 162, 235, 1)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 2,
                borderRadius: 4,
                borderSkipped: false,
            },
            {
                label: `${t('trackTempAndAttackLapNumber.trackTemp')} 20-25`,
                data: [0, 0, 0, 0, 0, 0, 0, 0],
                backgroundColor: 'rgba(75, 192, 192, 1)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                borderRadius: 4,
                borderSkipped: false,
            },
            {
                label: `${t('trackTempAndAttackLapNumber.trackTemp')} 25-30`,
                data: [0, 9.0, 22.4, 23.9, 25.4, 11.9, 4.5, 3.0],
                backgroundColor: 'rgba(255, 206, 86, 1)',
                borderColor: 'rgba(255, 206, 86, 1)',
                borderWidth: 2,
                borderRadius: 4,
                borderSkipped: false,
            },
            {
                label: `${t('trackTempAndAttackLapNumber.trackTemp')} 30-35`,
                data: [0, 34.7, 27.8, 13.9, 8.3, 9.7, 5.6, 0],
                backgroundColor: 'rgba(255, 99, 132, 1)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 2,
                borderRadius: 4,
                borderSkipped: false,
            }
        ]
    }

    return <Bar data={data} options={options} />
}

export default TrackTempAndAttackLapNumber
