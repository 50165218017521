import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

const EditEvent = (props) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [eventList, setEventList] = useState()
    const [allRaceList, setAllRaceList] = useState()
    const [currentRaceList, setCurrentRaceList] = useState()
    const [eventListData, setEventListData] = useState()

    const [currentEvent, setCurrentEvent] = useState()
    const [selectedRace, setSelectedRace] = useState()
    const [currentRaceEdit, setCurrentRaceEdit] = useState()

    const [currentlyEditing, setCurrentlyEditing] = useState(false)
    const [raceEditor, setRaceEditor] = useState(false)

    const eventNameRef = useRef()
    const eventSeriesRef = useRef()
    const eventCircuitRef = useRef()
    const raceNameRef = useRef()
    const raceTypeRef = useRef()
    const raceStartTimeRef = useRef()
    const raceEndTimeRef = useRef()
    const lapRecordingStartTimeRef = useRef()
    const lapRecordingEndTimeRef = useRef()
    const raceStartDateRef = useRef()
    const raceEndDateRef = useRef()

    useEffect(() => {
        setEventList(props.eventList.map(event => event.props))
        getAllRaces()
    }, [props.eventList])

    useEffect(() => {
        setEventListData(props.eventListData)
    }, [props.eventListData])

    const getAllRacesByEventId = (eventId) => {
        fetch(`/api/raceDetail/getAllRacesByEventId/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                let data = json.data

                function formatDateTime(dateTimeString) {
                    const dateTime = new Date(dateTimeString);
                    const hours = dateTime.getUTCHours();
                    const minutes = dateTime.getMinutes();
                    const formattedHours = String(hours).padStart(2, '0');
                    const formattedMinutes = String(minutes).padStart(2, '0');
                    const year = dateTime.getUTCFullYear();
                    const month = (dateTime.getUTCMonth() + 1).toString().padStart(2, '0');
                    const day = dateTime.getUTCDate().toString().padStart(2, '0');
                
                    return {
                        time: `${formattedHours}:${formattedMinutes}`,
                        date: `${year}-${month}-${day}`
                    };
                }

                data = data.sort((a, b) => a.ID - b.ID)
                data.forEach(race => {
                    if (race.RACE_START_TIME !== undefined) {
                        race.RACE_START_TIME = formatDateTime(race.RACE_START_TIME).time;
                    }
                    if (race.RACE_END_TIME !== undefined) {
                        const formatted = formatDateTime(race.RACE_END_TIME);
                        race.RACE_END_TIME = formatted.time;
                        race.END_DATE = formatted.date;
                    }
                    if (race.LAP_RECORDING_START_TIME !== undefined) {
                        race.LAP_RECORDING_START_TIME = formatDateTime(race.LAP_RECORDING_START_TIME).time;
                    }
                    if (race.LAP_RECORDING_END_TIME !== undefined) {
                        const formatted = formatDateTime(race.LAP_RECORDING_END_TIME);
                        race.LAP_RECORDING_END_TIME = formatted.time;
                        race.END_DATE = formatted.date;
                    }
                });
                setCurrentRaceList(data)
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const getAllRaces = () => {
        fetch('/api/racedetail/getAll')
        .then(response => response.json())
        .then(json => {
            console.log('json', json)
            if (json.status === 200) {
                setAllRaceList(json.data)
            }
        }
        )
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }        

    const updateRaceDetail = (raceData) => {
        fetch('/api/raceDetail/updateRaceDetailValues', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                raceData: raceData
            })
        })
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                alert(t('editEvent.successfulUpdate'))
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const deleteEvent = (eventId) => {
        fetch('/api/event/deleteEvent', {   
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                eventId: eventId
            })
        })
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                alert(t('editEvent.successfulDeletion'))
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const updateEventValues = (eventId,  eventName, circuitId, raceId) => {
        fetch('/api/event/updateEventValues', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: eventId,
                eventName: eventName,
                circuitId: circuitId,
                raceId: raceId
            })
        })
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                alert(t('editEvent.successfulUpdate'))
            } else {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const getSeriesId = (eventId) => {
        const event = eventListData.find((event) => event.ID === eventId)
        if(event) {
            const raceId = event.RACE_ID
            const raceOption = props.raceSeries.find(race => race.props.value === raceId)
            if (raceOption) {
                return raceOption.props.value
            }
        }
    }

    const getCircuitId = (eventId) => {
        const event = eventListData.find((event) => event.ID === eventId)
        if(event) {
            const circuitId = event.CIRCUIT_ID
            const circuitOption = props.circuitList.find(circuit => circuit.props.value === circuitId)
            if (circuitOption) {
                return circuitOption.props.value
            }
        }
    }

    const editRaceHandler = (raceId) => {
        if(raceEditor === false) {
            setRaceEditor(true)
            setCurrentRaceEdit(raceId)
        } else if (raceEditor === true && raceId !== 'cancel') {
            if(raceStartDateRef.current.value < raceEndDateRef.current.value || (raceStartDateRef.current.value === raceEndDateRef.current.value && raceStartTimeRef.current.value < raceEndTimeRef.current.value)) {
                const raceData = {
                    ID: raceId,
                    RACE_DETAIL_NAME: raceNameRef.current.value,
                    RACE_TYPE: raceTypeRef.current.value,
                    RACE_START_DATE: raceStartDateRef.current.value,
                    RACE_START_TIME: raceStartTimeRef.current.value,
                    LAP_RECORDING_START_TIME: lapRecordingStartTimeRef.current.value,
                    RACE_END_DATE: raceEndDateRef.current.value,
                    RACE_END_TIME: raceEndTimeRef.current.value,
                    LAP_RECORDING_END_TIME: lapRecordingEndTimeRef.current.value
                }
                updateRaceDetail(raceData)
                setRaceEditor(false)
                setCurrentRaceEdit(null)
                getAllRacesByEventId(currentEvent)
            } else {
                alert(t('editEvent.setCorrectDateAndTime'))
            }
        } else {
            setRaceEditor(false)
            setCurrentRaceEdit(null)
        }
    }

    const editEventHandler = (eventId) => {
        setCurrentlyEditing(true)
        setCurrentEvent(eventId)
        getAllRacesByEventId(eventId)
    }

    const deleteEventHandler = (eventId) => {
        if(window.confirm(t('editEvent.deletionConfirmation'))) {
            // updateSelectedEventIdBeforeDelete(eventId, null)
            deleteEvent(eventId)
            props.updateEventList(eventList.filter(event => event.value !== eventId))
        }
    }

    const updateEventValuesHandler = () => {
        updateEventValues(currentEvent, eventNameRef.current.value, eventCircuitRef.current.value, eventSeriesRef.current.value)
        const updatedList = eventList.map(event => {
            if (event.value === currentEvent) {
                props.setEditEventStatus(eventNameRef.current.value, eventCircuitRef.current.value, eventSeriesRef.current.value)
                return { ...event, children: eventNameRef.current.value }
            } else {
                return event
            }
        })
        props.updateEventList(updatedList)
    }

    const raceSelectHandler = (raceId) => {
        setSelectedRace(raceId.target.value)
    }
    
    const removeRaceFromEventHandler = (currentRaceId) => {
        updateRaceDetail({ ID: currentRaceId, EVENT_ID: null })
        getAllRacesByEventId(currentEvent)
        getAllRaces()
    }

    const addRaceToEventHandler = () => {
        if (selectedRace === undefined || selectedRace === '') {
            alert(t('editEvent.pleaseSelectRace'))
            return
        } else {
            const selectedRaceData = JSON.parse(selectedRace)
            if (selectedRaceData.eventID === null) {
                updateRaceDetail({ ID: selectedRaceData.raceID, EVENT_ID: currentEvent })
                getAllRacesByEventId(currentEvent)
                getAllRaces()
            } else {
                const differentEvent = eventList.find((event) => event.value === selectedRaceData.eventID).children
                if (currentEvent === selectedRaceData.eventID) {
                    alert(t('editEvent.sameEvent'))
                }
                else if(window.confirm(t('editEvent.alreadyRegisteredConfirmation', { differentEvent: differentEvent }))) {
                    updateRaceDetail({ ID: selectedRaceData.raceID, EVENT_ID: currentEvent })
                    getAllRacesByEventId(currentEvent)
                    getAllRaces()
                }
            }
        }
    }

    return (
        <>
        {eventList !== undefined
          ? (!currentlyEditing ? 
                eventList.map((event, index) => ( 
                    <div className='event-edit' key={index}>
                        <div className='event-edit-inner'>{event.children}</div>
                        <div className='event-edit-buttons'>
                            <button onClick={() => editEventHandler(event.value) }>{t('editEvent.edit')}</button>
                            <button onClick={() => deleteEventHandler(event.value) }>{t('editEvent.delete')}</button>
                        </div>
                        <span className='dli-plus'></span>
                        <span className='dli-plus'></span>
                    </div>
                ))
            :   <div>
                <div className='event-edit-menu-buttons'>
                 <button onClick={ updateEventValuesHandler } className='event-edit-inner-button'>{t('editEvent.updateEventInformation')}
                    <span className='top-page-dec-top-left'></span>
                    <span className='top-page-dec-top-right'></span>
                    <span className='top-page-dec-bottom-left'></span>
                    <span className='top-page-dec-bottom-right'></span>
                 </button>
                <button onClick={() => {
                            setRaceEditor(false)
                            setCurrentlyEditing(false)
                            setCurrentRaceList(null)
                        }}>{t('editEvent.back')}

                    <span className='top-page-dec-top-left'></span>
                    <span className='top-page-dec-top-right'></span>
                    <span className='top-page-dec-bottom-left'></span>
                    <span className='top-page-dec-bottom-right'></span>
                    </button>
                    </div>
                    <div className='event-edit-single'>
                        <div className='event-edit-container'>
                            <span class='dli-plus'></span>
                            <span class='dli-plus'></span>
                            <span class='dli-plus'></span>
                            <span class='dli-plus'></span>
                            <div className='event-edit-inner'>
                                <div className='event-edit-input'>
                                    <div className='event-edit-inner-name'>{t('general.eventName')}: </div>
                                    <input type='text' defaultValue={ eventList.find((event) => event.value === currentEvent ).children } ref={eventNameRef} />
                                </div>
                            </div>
                            <div className='event-edit-inner'>
                                <div className='event-edit-input'>
                                    <div className='event-edit-inner-name'>{t('general.series')}: </div>
                                    <select defaultValue={getSeriesId(currentEvent)} ref={eventSeriesRef}>
                                        {props.raceSeries}
                                    </select>
                                </div>  
                            </div>
                            <div className='event-edit-inner'>
                                <div className='event-edit-input'>
                                    <div className='event-edit-inner-name'>{t('general.circuit')}: </div>
                                    <select defaultValue={getCircuitId(currentEvent)} ref={eventCircuitRef}>
                                        {props.circuitList}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='event-edit-seperator'>

                        </div>
                        <div className='event-edit-race-menu'>
                        <div className='event-edit-race-menu-tit'>
                            <div className=''>DATE</div>
                            <div className=''>RACE</div>
                            <div className=''>TYPE</div>
                            <div className='dummy-text'></div>
                        </div>
                            { currentRaceList && currentRaceList.length >= 1 ? (
                                currentRaceList.map((r, index) => (
                                    <div className='event-edit-race' key={index}>
                                        <span class='dli-plus'></span>
                                        <span class='dli-plus'></span>
                                        <div className='event-edit-race-date'>
                                            {raceEditor && currentRaceEdit === r.ID ? 
                                                <>
                                                    <div className = 'event-edit-race-date-input'>
                                                        <label>{t('general.startDate')}:*</label>
                                                        <input name ='test' type = 'date' defaultValue={r.RACE_DATE.split('T')[0]} ref={raceStartDateRef} className='event-edit-time'></input>
                                                    </div>
                                                    <div className = 'event-edit-race-date-input'>
                                                        <label>{t('general.raceStartTime')}:*</label>
                                                        <input type = 'time' defaultValue={r.RACE_START_TIME} ref={raceStartTimeRef} className='event-edit-time'></input>
                                                    </div>
                                                    <div className = 'event-edit-race-date-input'>
                                                        <label>{t('general.lapRecordingStartTime')}:*</label>
                                                        <input type = 'time' defaultValue={r.LAP_RECORDING_START_TIME} ref={lapRecordingStartTimeRef} className='event-edit-time'></input>
                                                    </div>
                                                    <div className = 'event-edit-race-date-input'>
                                                        <label>{t('general.endDate')}:*</label>
                                                        <input type = 'date' defaultValue={r.END_DATE} ref={raceEndDateRef} className='event-edit-time'></input>
                                                    </div>
                                                    <div className = 'event-edit-race-date-input'>
                                                        <label>{t('general.raceEndTime')}:*</label>
                                                        <input type = 'time' defaultValue={r.RACE_END_TIME} ref={raceEndTimeRef} className='event-edit-time'></input>
                                                    </div>
                                                    <div className = 'event-edit-race-date-input'>
                                                        <label>{t('general.lapRecordingEndTime')}:*</label>
                                                        <input type = 'time' defaultValue={r.LAP_RECORDING_END_TIME} ref={lapRecordingEndTimeRef} className='event-edit-time'></input>
                                                    </div>

                                                    <div className='edit-notes'>{t('editEvent.ociEnvironment')}</div>
                                                    <div className='edit-notes'>{t('editEvent.updateInput')}</div>
                                                </>

                                                : 
                                                `${r.RACE_DATE.split('T')[0]}: ${r.RACE_START_TIME} - ${r.RACE_END_TIME}${r.RACE_DATE !== r.END_DATE ? '*' : ''}`                                            }
                                        </div>
                                        <div>
                                            {raceEditor && currentRaceEdit === r.ID ? 
                                                <input type='text' defaultValue={ r.RACE_DETAIL_NAME} ref={raceNameRef}/>

                                                : 
                                                r.RACE_DETAIL_NAME
                                            }
                                        </div>
                                        <div>
                                            {raceEditor && currentRaceEdit === r.ID ? 
                                                <select 
                                                    defaultValue={r.RACE_TYPE}
                                                    ref={raceTypeRef}
                                                >
                                                    <option value='fp'>FP</option>
                                                    <option value='qf'>QF</option>
                                                    <option value='race'>Race</option>
                                                </select>
                                                : 
                                                r.RACE_TYPE 
                                            }
                                        </div>
                                        <div className='event-edit-race-buttons'>
                                            {raceEditor && currentRaceEdit === r.ID ?
                                                <>
                                                    <button onClick={() => editRaceHandler('cancel')}className='event-edit-race-button'>{t('editEvent.cancel')}</button>
                                                    <button onClick={() => editRaceHandler(r.ID)}   className='event-edit-race-button'>{t('editEvent.doUpdate')}</button>

                                                </>
                                                :
                                                !raceEditor ?
                                                    <>
                                                        <button onClick={() => editRaceHandler(r.ID)}   className='event-edit-race-button'>{t('editEvent.edit')}</button>
                                                        <button onClick={() => removeRaceFromEventHandler(r.ID)}   className='event-edit-race-button'>{t('editEvent.delete')}</button>
                                                    </>
                                                    :
                                                    <>
                                                        <div className='event-edit-race-button' style={{display: 'none'}}></div>
                                                        <div className='event-edit-race-button' style={{display: 'none'}}></div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                )))
                                :
                                <div className='event-race'>{t('general.raceNotRegistered')}</div>
                            }
                        </div>
                        <div>
                            <div className='event-edit-add-menu'>
                                <select onChange={raceSelectHandler} value={selectedRace}>
                                    <option key='0x' value=''>{t('pleaseSelect', {ns: 'general'})}</option>
                                    {allRaceList.map((race) => (
                                        <option key={race.ID} value={JSON.stringify({ raceID: race.ID, eventID: race.EVENT_ID })}>{race.RACE_DETAIL_NAME}</option>
                                    ))}
                                </select>
                                <button onClick={addRaceToEventHandler} className='event-edit-race-button'>{t('general.raceNotRegistered')}</button>
                            </div>
                        </div>
                    </div>    
                </div>
            )
          : <div>{t('editEvent.eventNotMade')}</div>}
      </>
    )
}

export default EditEvent