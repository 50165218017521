import { useEffect, useState} from 'react'
import { convertDecimalToHMS } from '../services/Util'

import '../css/Simulation.css'

const SimulationRaceTotalTimeComparisonTable = ({ resultGraphData }) => {
    const [ optionList, setOptionList ] = useState([])

    useEffect(() => {
        let tr = []
        let option1RceTime = resultGraphData[1]?.race_time

        Object.entries(resultGraphData).forEach(data => {
            const optionNo = data[0]
            const result = data[1]

            if (result.display) {
                const lapData = Object.values(result.optionData)
                const findPit1 = lapData.findIndex(el => el.pit_sc === 'Pit_1')
                const findPit2 = lapData.findIndex(el => el.pit_sc === 'Pit_2')
                const deltaTime = Math.abs(option1RceTime - result.race_time)

                tr.push(
                    <tr key={optionNo}>
                        <td>Option {optionNo}</td>
                        <td>{result.strategy}</td>
                        <td>{findPit1 > 0 ? findPit1+1 : ''}</td>
                        <td>{findPit2 > 0 ? findPit2+1 : ''}</td>
                        <td>{convertDecimalToHMS(result.race_time)}</td>
                        <td>{option1RceTime <= result.race_time ? convertDecimalToHMS(deltaTime) : ('-' + convertDecimalToHMS(deltaTime))}</td>
                        <td>{result.lap_time_average}</td>
                    </tr>
                )
            }
        })

        setOptionList(tr)
    }, [resultGraphData])

    return (
        <>
            <table border={1}>
                <thead>
                    <tr>
                        <td className='textAlignCenter' colSpan={7}>Race Total Time Comparison</td>
                    </tr>
                    <tr>
                        <td className='textAlignCenter'></td>
                        <td className='textAlignCenter'>Strategy</td>
                        <td className='textAlignCenter'>Pitstop 1</td>
                        <td className='textAlignCenter'>Pitstop 2</td>
                        <td className='textAlignCenter'>Race Time</td>
                        <td className='textAlignCenter'>Delta time</td>
                        <td className='textAlignCenter'>Lap Time Average</td>
                    </tr>
                </thead>
                <tbody>
                    {optionList}
                </tbody>
            </table>
        </>
    )
}

export default SimulationRaceTotalTimeComparisonTable