import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Header from '../components/Header'
import TrackTempAndAttackLapNumber from '../components/TrackTempAndAttackLapNumber'

import { useTranslation } from 'react-i18next'

const QFPlan = () => {
    const { t } = useTranslation(['strategy'])

    const { eventId } = useParams()

    const navigate = useNavigate()
    const handleButtonClick = () => {
        navigate(`/event/${eventId}`)
    }

    return (
        <>
            <Header />
            <div className='display-race-container analysis-page'>
                <div
                    style={{
                        margin: '10px 0 0 0',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <button className='event-button' onClick={handleButtonClick}>
                        {t('general.sessionList')}
                    </button>
                </div>
                <h2
                    style={{
                        margin: '50px auto',
                        textAlign: 'center',
                    }}
                >
                    過去の同サーキットにおける路面温度と周回数の傾向（S耐FSW）
                </h2>
                <TrackTempAndAttackLapNumber />
            </div>
        </>
    )
}

export default QFPlan
