const updateProgress = async (val) => {
    // プログレスバーの進捗値を更新し、プログレスバーに反映させる
    let progressElement = document.getElementById('myProgress')

    if (progressElement === null) return

    if (val === 5) {
        progressElement.parentElement.style.display = 'block'
    } else if (val === 100) {
        progressElement.parentElement.style.display = 'none'
    }

    progressElement.value = val
    progressElement.innerText = `${val}}%`
}

export default updateProgress