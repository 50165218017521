import React, { useState, useEffect } from 'react'

import { Line } from 'react-chartjs-2'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'

import search_columns from '../services/search_columns'

import { baseColors, recommendColor, recommendColors } from '../services/plotColors'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const LineChart = ({ list, df_scatter, df_base, title, plotLine }) => {
    const [ chartData, setChartData ] = useState({})
    const [ chartOptions, setChartOptions ] = useState({})

    let plot_label = []
    let Plot_ans_name
    let selectStatus = plotLine

    useEffect(() => {    
        if (selectStatus === 'All') {
            Plot_ans_name = search_columns(df_scatter, title, 1);
            for (let i = 0; i < Plot_ans_name.length; i++) {
                plot_label[i] = Plot_ans_name[i].slice(0, 6);
            }
        } else {
            Plot_ans_name = search_columns(df_scatter, selectStatus, 1);
            for (let i = 0; i < Plot_ans_name.length; i++) {
                plot_label[i] = Plot_ans_name[i].slice(0, 6);
            }
        }

        let myArray = []
    
        if (list !== 0) {
            myArray[0] = {
                label: 'Answer_Data',
                data: df_scatter.loc({ columns: Plot_ans_name }).iloc({ rows: list }).values[0],
                borderWidth: 1.5,
                backgroundColor: recommendColor,
                borderColor: recommendColor,
                pointRadius: 1.3,
            }
        }
    
        let baseCount = 0
        let recommendCount = 0
        for (let i = 0; i < df_base.index.length; i++) {
            let label = df_base.index[i]
            label = label[label.length - 1] === '✖' ? label.slice(0, -1) : label

            let color
            if (label.toString().includes('Recommend')) {
                color = recommendColors[(recommendCount) % recommendColors.length]
                recommendCount++
            } else {
                color = baseColors[(baseCount) % baseColors.length]
                baseCount++
            }

            myArray[myArray.length] = {
                label: label,
                data: df_base.loc({ columns: Plot_ans_name }).values[i],
                backgroundColor: color,
                borderColor: color,
                borderWidth: i === 0 ? 1.5 : 0.8,
                pointRadius: i === 0 ? 1.3 : 1,
            }
        }

        const chartData = {
            labels: plot_label,
            datasets: myArray,
        }
    
        const chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: false,
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: 'white',
                        font: {
                            size: 8,
                        },
                    },
                },
                y: {
                    ticks: {
                        color: 'white',
                        font: {
                            size: 8,
                        },
                        autoSkip: true,
                        maxTicksLimit: 3,
                    },
                },
            },
        }

        setChartData(chartData)
        setChartOptions(chartOptions)
    }, [list, df_scatter, df_base, title, plotLine])
    
    return ( chartData.labels && <Line data={chartData} options={chartOptions} />)
}

export default LineChart