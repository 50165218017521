import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { TableContainer, Paper, Table, TableHead, TableRow } from '@mui/material'

import { StyledHeaderTableCell } from './TableStyle'
import ClassColorDisplay from './CarClass'
import TimeFormatter from './TimeFormatter'

import { colorScale } from '../services/Util'

import { useTranslation } from 'react-i18next'

const OutingTopTable = (props) => {
    const { t } = useTranslation(['strategy', 'general'])
 
    const [backgroundColors, setBackgroundColors] = useState([])
    const [initialSortedCarClassArray, setInitialSortedCarClassArray] = useState(null)
    const [sortedCarClassArray, setSortedCarClassArray] = useState([])
    const [tableCarNumberArray, setTableCarNumberArray] = useState([])
    const [tableData, setTableData] = useState({})
    
    const TableheadStyle ={
        fontWeight:'bold',
        color:'#fff',
        textAlign:'center',
    }
    
    const isAllClassNull = (carClassDatas) => {
        if (carClassDatas !== null){
        return carClassDatas.every(carClassData => carClassData.CLASS === null)
        }
    }

    const handleButtonClick = (header) => {
        props.setSelectedCarNumber(header)
        return
    }

    const transposeArray = (array) => {
        return  array[0].map((_, columnIndex) => array.map(row => row[columnIndex]))
    }

    const sortInitialCarClassArray = (inputData, carClassDatas) =>{
        const carNumberSortedArray = Object.keys(inputData)
        // TODO: ask hatasa-san
        if (carClassDatas, carNumberSortedArray){
            const initialSortedCarClassArray = carNumberSortedArray.map(carNumber => {
                const carData = carClassDatas.find(carClassData => carClassData.CAR_NUMBER === parseInt(carNumber))
                return carData ? carData.CLASS : null
            })
            
            setInitialSortedCarClassArray(initialSortedCarClassArray)
        }
        return
    }

    const sortCarNumberArray = (inputData, sort, selectedClasses, initialSortedCarClassArray) => {
        const carNumberSortedArray = Object.keys(inputData)
        let selectedClassCarNumberSortedArray = carNumberSortedArray
        
        selectedClassCarNumberSortedArray = initialSortedCarClassArray.reduce((resultArray, carClass, index) => {
        if (selectedClasses.includes(carClass)) {
            resultArray.push(carNumberSortedArray[index])
        }
        return resultArray
        }, [])   
        
       
        const selectedClassCarDatas = Object.fromEntries(
            Object.entries(inputData).filter(([key]) => selectedClassCarNumberSortedArray.includes(key))
        )

        if (sort === 'lapTime') {
            const eachCarBestDatas = Object.entries(selectedClassCarDatas).map(([carNumber, lapDatas]) => {
                const eachCarBestLapTime = Math.min(...lapDatas.map(lapData => lapData.TIME))
                return { CAR_NUMBER: carNumber, TIME: eachCarBestLapTime }
            })
                    
            const lapTimeSortedCarBestDatas = eachCarBestDatas.slice().sort((a, b) => a.TIME - b.TIME)
            const lapTimeSortedCarNumberArray = lapTimeSortedCarBestDatas.map(item => item.CAR_NUMBER)
            setTableCarNumberArray(lapTimeSortedCarNumberArray)
            return
        }
        setTableCarNumberArray(selectedClassCarNumberSortedArray)
        return
    }

    const sortCarClassArray = (tableCarNumberArray, carClassDatas) => {
        // TODO: ask hatasa-san
        if (carClassDatas, tableCarNumberArray) {
            const sortedCarClassArray = tableCarNumberArray.map(carNumber => {
                const carData = carClassDatas.find(carClassData => carClassData.CAR_NUMBER === parseInt(carNumber))
                return carData ? carData.CLASS : null
            })     
            setSortedCarClassArray(sortedCarClassArray)
            return
        }
    }

    const setTableDataObject = (props) => {
        const inputData = props.inputData
        const headerName = "CarNumber"
        const headerArray = tableCarNumberArray
        const tableData = {
            headerName: headerName,
            header: headerArray,
            rows: []
        }
    
        if (sortedCarClassArray !== null) {
            tableData['classHeader'] = sortedCarClassArray
        }
    
        const maxRowLength = Math.max(...Object.values(inputData).map(carData => carData.length))
    
        const pitStopTime = ['outing', ...headerArray.map(header => inputData[header]?.[0]?.PIT_STOP_TIME?.toString() ?? 'N/A')];

        tableData.rows.push(pitStopTime)
    
       
        const lapRows = Array.from({ length: maxRowLength }, (_, rowIndex) => {
            const lapNumber = (rowIndex + 1).toString()
            const times = headerArray.map(header => inputData[header][rowIndex] ? inputData[header][rowIndex].TIME : null)
            return [lapNumber, ...times]
        })
    
        tableData.rows = tableData.rows.concat(lapRows)
    
        setTableData(tableData)
    }
  
    const setHeatmapArray = (sortedTableData, bestLap) => { 
        const timeArray = sortedTableData.map(array => array.slice(1)).slice(1)
        const timeArrysTransposed = timeArray.length ? transposeArray(timeArray) : undefined
        
        const allHeatMapArray = timeArrysTransposed ? timeArrysTransposed.map(timeArray => {
            const colorArray = colorScale(timeArray, 0xff523c, 0x3a4eff, 'normal')
            if (timeArray.includes(bestLap.TIME)) {
                colorArray[timeArray.indexOf(bestLap.TIME)] = 'b8a1e6'
            }
            return colorArray
        }) : []

        const backgroundColorTranspose = allHeatMapArray.length ? transposeArray(allHeatMapArray) : null
        const backgroundColorTransposeAddLapColor = backgroundColorTranspose ? backgroundColorTranspose.map(row => ['f2f2f2', ...row]): null
        const OutingcolorArray = backgroundColorTransposeAddLapColor ? Array(backgroundColorTransposeAddLapColor[0].length).fill('f2f2f2') : null

        if (OutingcolorArray){
            backgroundColorTransposeAddLapColor.unshift(OutingcolorArray)     
            const backgroundColors = backgroundColorTransposeAddLapColor.flat() 
            setBackgroundColors(backgroundColors)           
        }  
    }

    useEffect(()=>{
        if (props.carClassDatas) {
            sortInitialCarClassArray(props.inputData, props.carClassDatas)
        } 
    }, [props.inputData, props.carClassDatas])

    useEffect(()=>{
        if (initialSortedCarClassArray !== null) {
            sortCarNumberArray(props.inputData, props.sort, props.selectedClasses, initialSortedCarClassArray)
        } 
    }, [props.inputData, props.sort, props.selectedClasses, initialSortedCarClassArray])

    useEffect(()=>{
        if (initialSortedCarClassArray !== null) {
            sortCarClassArray(tableCarNumberArray, props.carClassDatas)
        } 
    }, [tableCarNumberArray])
    

    useEffect(() =>{
        if(sortedCarClassArray){
            setTableDataObject(props)
        }
    }, [sortedCarClassArray])

    useEffect(() =>{
        if(tableData && props.bestLap){
            setHeatmapArray(tableData.rows, props.bestLap)
        }
    }, [tableData, props.bestLap])
    
    return (
        backgroundColors.length !== 0 && props.selectedCarNumber && props.carNumberColor?
        <TableContainer component={Paper}  sx={{height: '100%' ,margin: '20px 0 0 0', backgroundColor: 'transparent'}}>
        <Table className= 'fp-table-container'>  
            <TableHead>
                <TableRow>
                    <StyledHeaderTableCell sx={{...TableheadStyle}}>{tableData.headerName}</StyledHeaderTableCell>
                    {tableData.header.map((header, index) =>
                        <th className={`carno-th ${String(props.selectedCarNumber) === header ? 'active' : ''}`} key={index} onClick={() => 
                            {handleButtonClick(header)
                        }}>
                            <button
                                style={{
                                    backgroundColor: (props.carNumberColor[header] && props.carNumberColor[header]['COLOR_BG']) ? props.carNumberColor[header]['COLOR_BG'] : '#f2f2f2',
                                    color: (props.carNumberColor[header] && props.carNumberColor[header]['COLOR_TEXT']) ? props.carNumberColor[header]['COLOR_TEXT'] : '#000',
                                    fontWeight: 'bold'        
                                }}
                                className={`fp-table-button`}
                            >   
                                {header}
                            </button>                        
                        </th>
                    )}
                </TableRow>
                {isAllClassNull(props.carClassDatas) ?
                null
                :<tr sx={{...TableheadStyle}}>
                    <th style={{backgroundColor:'#f2f2f2', fontWeight: 'normal'}}>{'Class'}</th>
                    {sortedCarClassArray.map((carClass, index) => 
                        <th key={index}>
                            { ClassColorDisplay(carClass, 'table') }
                        </th>
                    )}
                </tr>}
            </TableHead>
            <tbody>     
                {tableData.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex} > 
                            {backgroundColors[cellIndex + (row.length * rowIndex)]
                            ?  (<div style={{textAlign:'center', color: 'black', backgroundColor: `#${backgroundColors[cellIndex + (row.length * rowIndex)].toString(16).padStart(6, '0')}` }}>
                                {typeof cell === 'string' ? cell : <TimeFormatter seconds={cell} />}
                                </div>)
                            : null }
                            </td>
                        ))}
                        </tr>
                    ))}
            </tbody>
        </Table>
        </TableContainer>
         :
        null
    )
}

export default OutingTopTable