import React, { useState, useEffect } from 'react'

import Header from '../components/Header'
import SetupHistoryTable from '../components/SetupHistoryTable'

import '../css/setuphistory.css'

const SetupHistory = () => {
    const [setupData, setSetupData] = useState([])
    const [vehicleMappingItems, setVehicleMappingItems] = useState([])

    const [defaultSetup, setDefaultSetup] = useState(null)
    const [setupFile, setSetupFile] = useState()

    useEffect(() => {
        getSetupData()
        getVehicleItemMappings()
    }, [])

    useEffect(() => {
        if (defaultSetup) {
            setSetupFile(createSetupFile(defaultSetup))
            // window.location.href = '/setup
        }
    }, [defaultSetup])

    const handleCollection = (value) => {
        setSetupFile(createSetupFile(value))
        // window.location.href = '/setup
        }

    const handleLapChart= (value) => {
        setSetupFile(createSetupFile(value))
        // window.location.href = '/setup
        }

    const createSetupFile = (value) => {
        const currentSetupData = setupData.setups.find(setup => setup.id === value)
        currentSetupData.setup_items.forEach(item => {
            const position = vehicleMappingItems.vehicleItemMappings.find(mapping => mapping.id === item.vehicle_item_mapping_id).position
            item.position = position
            delete item.vehicle_item_mapping_id
        })
        return currentSetupData
    }
    

    // function getSetupData() {
    //     fetch('http://localhost:8080/api/v1/setups', {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //     })
    //     .then(response => response.json())
    //     .then(response => {
    //         setSetupData(response)
    //     })
    // }
    // 現在GR86Yarisだけです
    // function getVehicleItemMappings() {
    //     fetch('http://localhost:8080/api/v1/vehicleItemMappings', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify({
    //             vehicleId: 1,
    //         })
    //     })
    //     .then(response => response.json())
    //     .then(response => {
    //         setVehicleMappingItems(response)
    //     })
    // }

    const getSetupData = () => {
        setSetupData(
            {
                "setups": [
                    {
                        "id": 1,
                        "name": "Setup1",
                        "vehicle_id": 1,
                        "setup_items": [
                            {
                                "id": 1,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 1,
                                "value": "160.5"
                            },
                            {
                                "id": 2,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 2,
                                "value": "160"
                            },
                            {
                                "id": 3,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 3,
                                "value": "Fr_16kg"
                            },
                            {
                                "id": 4,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 4,
                                "value": "Rr_16kg"
                            },
                            {
                                "id": 5,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 5,
                                "value": "Fr_Base-50per"
                            },
                            {
                                "id": 6,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 6,
                                "value": "Rr_8.1/mm"
                            },
                            {
                                "id": 7,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 7,
                                "value": "34"
                            },
                            {
                                "id": 8,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 8,
                                "value": "32.5"
                            },
                            {
                                "id": 9,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 9,
                                "value": "RR28_861B_231110tuning_newRr_7deg"
                            },
                            {
                                "id": 10,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 10,
                                "value": "Fr_Out10"
                            },
                            {
                                "id": 11,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 11,
                                "value": "Rr_In20"
                            },
                            {
                                "id": 12,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 12,
                                "value": "-4.1"
                            },
                            {
                                "id": 13,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 13,
                                "value": "-2.8"
                            },
                            {
                                "id": 14,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 14,
                                "value": "Final4.3"
                            },
                            {
                                "id": 15,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 15,
                                "value": "RR28_SE23_R08BURIRUM_w350Nm"
                            },
                            {
                                "id": 16,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 16,
                                "value": "N"
                            },
                            {
                                "id": 17,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 17,
                                "value": "0"
                            },
                            {
                                "id": 18,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 18,
                                "value": "100"
                            },
                            {
                                "id": 19,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 19,
                                "value": "20"
                            },
                            {
                                "id": 20,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 20,
                                "value": "1.0148"
                            },
                            {
                                "id": 21,
                                "setup_id": 1,
                                "vehicle_item_mapping_id": 21,
                                "value": "61"
                            }
                        ]
                    },
                    {
                        "id": 2,
                        "name": "Setup2",
                        "vehicle_id": 1,
                        "setup_items": [
                            {
                                "id": 22,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 1,
                                "value": "16"
                            },
                            {
                                "id": 23,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 2,
                                "value": "16"
                            },
                            {
                                "id": 24,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 3,
                                "value": "Fr_16kg"
                            },
                            {
                                "id": 25,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 4,
                                "value": "Rr_16kg"
                            },
                            {
                                "id": 26,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 5,
                                "value": "Fr_Base-50per"
                            },
                            {
                                "id": 27,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 6,
                                "value": "Rr_8.1/mm"
                            },
                            {
                                "id": 28,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 7,
                                "value": "34"
                            },
                            {
                                "id": 29,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 8,
                                "value": "32.5"
                            },
                            {
                                "id": 30,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 9,
                                "value": "RR28_861B_231110tuning_newRr_7deg"
                            },
                            {
                                "id": 31,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 10,
                                "value": "Fr_Out10"
                            },
                            {
                                "id": 32,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 11,
                                "value": "Rr_In20"
                            },
                            {
                                "id": 33,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 12,
                                "value": "-4.1"
                            },
                            {
                                "id": 34,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 13,
                                "value": "-2.8"
                            },
                            {
                                "id": 35,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 14,
                                "value": "Final4.3"
                            },
                            {
                                "id": 36,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 15,
                                "value": "RR28_SE23_R08BURIRUM_w350Nm"
                            },
                            {
                                "id": 37,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 16,
                                "value": "N"
                            },
                            {
                                "id": 38,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 17,
                                "value": "0"
                            },
                            {
                                "id": 39,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 18,
                                "value": "100"
                            },
                            {
                                "id": 40,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 19,
                                "value": "20"
                            },
                            {
                                "id": 41,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 20,
                                "value": "1.0148"
                            },
                            {
                                "id": 42,
                                "setup_id": 2,
                                "vehicle_item_mapping_id": 21,
                                "value": "61"
                            }
                        ]
                    }
                ]
            }
        )
    }

    const getVehicleItemMappings = () => {
        setVehicleMappingItems(
            {
                "vehicleItemMappings": [
                    {
                        "id": 1,
                        "vehicle_id": 1,
                        "default_value": "160.5",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "Fr",
                        "item": {
                            "id": 1,
                            "name": "RideHeight_Fr [mm]"
                        },
                        "category_values": [
                            {
                                "id": 1,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_10kg",
                                "value": 98.067
                            },
                            {
                                "id": 2,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_11kg",
                                "value": 107.873
                            },
                            {
                                "id": 3,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_12kg",
                                "value": 117.68
                            },
                            {
                                "id": 4,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_13kg",
                                "value": 127.487
                            },
                            {
                                "id": 5,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_14kg",
                                "value": 137.293
                            },
                            {
                                "id": 6,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_15kg",
                                "value": 147.01
                            },
                            {
                                "id": 7,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_16kg",
                                "value": 156.906
                            },
                            {
                                "id": 8,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_17kg",
                                "value": 166.713
                            },
                            {
                                "id": 9,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_18kg",
                                "value": 176.52
                            },
                            {
                                "id": 10,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_19kg",
                                "value": 186.326
                            },
                            {
                                "id": 11,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_20kg",
                                "value": 196.133
                            },
                            {
                                "id": 12,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_10kg",
                                "value": 98.067
                            },
                            {
                                "id": 13,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_11kg",
                                "value": 107.873
                            },
                            {
                                "id": 14,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_12kg",
                                "value": 117.68
                            },
                            {
                                "id": 15,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_13kg",
                                "value": 127.487
                            },
                            {
                                "id": 16,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_14kg",
                                "value": 137.293
                            },
                            {
                                "id": 17,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_15kg",
                                "value": 147.01
                            },
                            {
                                "id": 18,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_16kg",
                                "value": 156.906
                            },
                            {
                                "id": 19,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_17kg",
                                "value": 166.713
                            },
                            {
                                "id": 20,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_18kg",
                                "value": 176.52
                            },
                            {
                                "id": 21,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_19kg",
                                "value": 186.326
                            },
                            {
                                "id": 22,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_20kg",
                                "value": 196.133
                            },
                            {
                                "id": 23,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Base-80per",
                                "value": 23
                            },
                            {
                                "id": 24,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Base-70per",
                                "value": 33.6
                            },
                            {
                                "id": 25,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Base-60per",
                                "value": 47.6
                            },
                            {
                                "id": 26,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Base-50per",
                                "value": 53.2
                            },
                            {
                                "id": 27,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Base-40per",
                                "value": 69.8
                            },
                            {
                                "id": 28,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Base-30per",
                                "value": 80.2
                            },
                            {
                                "id": 29,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Base-20per",
                                "value": 88.2
                            },
                            {
                                "id": 30,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Base-10per",
                                "value": 97
                            },
                            {
                                "id": 31,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Base",
                                "value": 116.2
                            },
                            {
                                "id": 32,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Base+10per",
                                "value": 127.4
                            },
                            {
                                "id": 33,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Less",
                                "value": 0.001
                            },
                            {
                                "id": 34,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_8.1N/mm",
                                "value": 16.2
                            },
                            {
                                "id": 35,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_9.3N/mm",
                                "value": 18.6
                            },
                            {
                                "id": 36,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_10.7N/mm",
                                "value": 21.4
                            },
                            {
                                "id": 37,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_12.2N/mm",
                                "value": 24.4
                            },
                            {
                                "id": 38,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_14.1N/mm",
                                "value": 28.2
                            },
                            {
                                "id": 39,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_16.2N/mm",
                                "value": 32.4
                            },
                            {
                                "id": 40,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_13N/mm",
                                "value": 26
                            },
                            {
                                "id": 41,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_15N/mm",
                                "value": 30
                            },
                            {
                                "id": 42,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_17.3N/mm",
                                "value": 34.6
                            },
                            {
                                "id": 43,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_22.7N/mm",
                                "value": 45.4
                            },
                            {
                                "id": 44,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_26.2N/mm",
                                "value": 52.4
                            },
                            {
                                "id": 45,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_30.2N/mm",
                                "value": 60.4
                            },
                            {
                                "id": 46,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Out20'",
                                "value": -0.167
                            },
                            {
                                "id": 47,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Out18'",
                                "value": -0.15
                            },
                            {
                                "id": 48,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Out16'",
                                "value": -0.133
                            },
                            {
                                "id": 49,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Out14'",
                                "value": -0.117
                            },
                            {
                                "id": 50,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Out12'",
                                "value": -0.1
                            },
                            {
                                "id": 51,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Out10'",
                                "value": -0.083
                            },
                            {
                                "id": 52,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Out8'",
                                "value": -0.067
                            },
                            {
                                "id": 53,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Out6'",
                                "value": -0.05
                            },
                            {
                                "id": 54,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Out4'",
                                "value": -0.033
                            },
                            {
                                "id": 55,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_Out2'",
                                "value": -0.017
                            },
                            {
                                "id": 56,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In0'",
                                "value": 0
                            },
                            {
                                "id": 57,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In2'",
                                "value": 0.017
                            },
                            {
                                "id": 58,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In4'",
                                "value": 0.033
                            },
                            {
                                "id": 59,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In6'",
                                "value": 0.05
                            },
                            {
                                "id": 60,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In8'",
                                "value": 0.067
                            },
                            {
                                "id": 61,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In10'",
                                "value": 0.083
                            },
                            {
                                "id": 62,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In12'",
                                "value": 0.1
                            },
                            {
                                "id": 63,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In14'",
                                "value": 0.117
                            },
                            {
                                "id": 64,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In16'",
                                "value": 0.133
                            },
                            {
                                "id": 65,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In18'",
                                "value": 0.15
                            },
                            {
                                "id": 66,
                                "vehicle_item_mapping_id": 1,
                                "name": "Fr_In20'",
                                "value": 0.167
                            },
                            {
                                "id": 67,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Out20'",
                                "value": -0.167
                            },
                            {
                                "id": 68,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Out18'",
                                "value": -0.15
                            },
                            {
                                "id": 69,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Out16'",
                                "value": -0.133
                            },
                            {
                                "id": 70,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Out14'",
                                "value": -0.117
                            },
                            {
                                "id": 71,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Out12'",
                                "value": -0.1
                            },
                            {
                                "id": 72,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Out10'",
                                "value": -0.083
                            },
                            {
                                "id": 73,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Out8'",
                                "value": -0.067
                            },
                            {
                                "id": 74,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Out6'",
                                "value": -0.05
                            },
                            {
                                "id": 75,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Out4'",
                                "value": -0.033
                            },
                            {
                                "id": 76,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_Out2'",
                                "value": -0.017
                            },
                            {
                                "id": 77,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In0'",
                                "value": 0
                            },
                            {
                                "id": 78,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In2'",
                                "value": 0.017
                            },
                            {
                                "id": 79,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In4'",
                                "value": 0.033
                            },
                            {
                                "id": 80,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In6'",
                                "value": 0.05
                            },
                            {
                                "id": 81,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In8'",
                                "value": 0.067
                            },
                            {
                                "id": 82,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In10'",
                                "value": 0.083
                            },
                            {
                                "id": 83,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In12'",
                                "value": 0.1
                            },
                            {
                                "id": 84,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In14'",
                                "value": 0.117
                            },
                            {
                                "id": 85,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In16'",
                                "value": 0.133
                            },
                            {
                                "id": 86,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In18'",
                                "value": 0.15
                            },
                            {
                                "id": 87,
                                "vehicle_item_mapping_id": 1,
                                "name": "Rr_In20'",
                                "value": 0.167
                            },
                            {
                                "id": 88,
                                "vehicle_item_mapping_id": 1,
                                "name": "Final4.3",
                                "value": 4.3
                            },
                            {
                                "id": 89,
                                "vehicle_item_mapping_id": 1,
                                "name": "Final4.556",
                                "value": 4.556
                            },
                            {
                                "id": 90,
                                "vehicle_item_mapping_id": 1,
                                "name": "N",
                                "value": 22.5
                            },
                            {
                                "id": 91,
                                "vehicle_item_mapping_id": 1,
                                "name": "NNE",
                                "value": 45
                            },
                            {
                                "id": 92,
                                "vehicle_item_mapping_id": 1,
                                "name": "NE",
                                "value": 67.5
                            },
                            {
                                "id": 93,
                                "vehicle_item_mapping_id": 1,
                                "name": "ENE",
                                "value": 90
                            },
                            {
                                "id": 94,
                                "vehicle_item_mapping_id": 1,
                                "name": "E",
                                "value": 112.5
                            },
                            {
                                "id": 95,
                                "vehicle_item_mapping_id": 1,
                                "name": "ESE",
                                "value": 135
                            },
                            {
                                "id": 96,
                                "vehicle_item_mapping_id": 1,
                                "name": "SE",
                                "value": 157.5
                            },
                            {
                                "id": 97,
                                "vehicle_item_mapping_id": 1,
                                "name": "SSE",
                                "value": 180
                            },
                            {
                                "id": 98,
                                "vehicle_item_mapping_id": 1,
                                "name": "S",
                                "value": 202.5
                            },
                            {
                                "id": 99,
                                "vehicle_item_mapping_id": 1,
                                "name": "SSW",
                                "value": 225
                            },
                            {
                                "id": 100,
                                "vehicle_item_mapping_id": 1,
                                "name": "SW",
                                "value": 247.5
                            },
                            {
                                "id": 101,
                                "vehicle_item_mapping_id": 1,
                                "name": "WSW",
                                "value": 270
                            },
                            {
                                "id": 102,
                                "vehicle_item_mapping_id": 1,
                                "name": "W",
                                "value": 292.5
                            },
                            {
                                "id": 103,
                                "vehicle_item_mapping_id": 1,
                                "name": "WNW",
                                "value": 315
                            },
                            {
                                "id": 104,
                                "vehicle_item_mapping_id": 1,
                                "name": "NW",
                                "value": 337.5
                            },
                            {
                                "id": 105,
                                "vehicle_item_mapping_id": 1,
                                "name": "NNW",
                                "value": 0
                            }
                        ]
                    },
                    {
                        "id": 2,
                        "vehicle_id": 1,
                        "default_value": "160",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "Rr",
                        "item": {
                            "id": 2,
                            "name": "RideHeight_Rr [mm]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 3,
                        "vehicle_id": 1,
                        "default_value": "Fr_16kg",
                        "is_category": true,
                        "min": -100,
                        "max": 200,
                        "position": "FR",
                        "item": {
                            "id": 3,
                            "name": "Spring_Fr [kgf/mm]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 4,
                        "vehicle_id": 1,
                        "default_value": "Rr_16kg",
                        "is_category": true,
                        "min": -100,
                        "max": 200,
                        "position": "RR",
                        "item": {
                            "id": 4,
                            "name": "Spring_Rr [kgf/mm]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 5,
                        "vehicle_id": 1,
                        "default_value": "Fr_Base-50per",
                        "is_category": true,
                        "min": -100,
                        "max": 200,
                        "position": "FR",
                        "item": {
                            "id": 5,
                            "name": "ARB_Fr [N/mm]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 6,
                        "vehicle_id": 1,
                        "default_value": "Rr_8.1/mm",
                        "is_category": true,
                        "min": -100,
                        "max": 200,
                        "position": "RR",
                        "item": {
                            "id": 6,
                            "name": "ARB_Rr [N/mm]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 7,
                        "vehicle_id": 1,
                        "default_value": "34",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "FR",
                        "item": {
                            "id": 7,
                            "name": "BumpstopGap_Fr [mm]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 8,
                        "vehicle_id": 1,
                        "default_value": "32.5",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "RR",
                        "item": {
                            "id": 8,
                            "name": "BumpstopGap_Rr [mm]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 9,
                        "vehicle_id": 1,
                        "default_value": "RR28_861B_231110tuning_newRr_7deg",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "",
                        "item": {
                            "id": 9,
                            "name": "Wing_Rr [deg]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 10,
                        "vehicle_id": 1,
                        "default_value": "Fr_Out10",
                        "is_category": true,
                        "min": -100,
                        "max": 200,
                        "position": "Fr",
                        "item": {
                            "id": 10,
                            "name": "Toe_Fr [']"
                        },
                        "category_values": []
                    },
                    {
                        "id": 11,
                        "vehicle_id": 1,
                        "default_value": "Rr_In20",
                        "is_category": true,
                        "min": -100,
                        "max": 200,
                        "position": "Rr",
                        "item": {
                            "id": 11,
                            "name": "Toe_Rr [']"
                        },
                        "category_values": []
                    },
                    {
                        "id": 12,
                        "vehicle_id": 1,
                        "default_value": "-4.1",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "Fr",
                        "item": {
                            "id": 12,
                            "name": "Camber_Fr [deg]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 13,
                        "vehicle_id": 1,
                        "default_value": "-2.8",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "Rr",
                        "item": {
                            "id": 13,
                            "name": "Camber_Rr [deg]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 14,
                        "vehicle_id": 1,
                        "default_value": "Final4.3",
                        "is_category": true,
                        "min": -100,
                        "max": 200,
                        "position": "",
                        "item": {
                            "id": 14,
                            "name": "FinalGear_ratio [-]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 15,
                        "vehicle_id": 1,
                        "default_value": "RR28_SE23_R08BURIRUM_w350Nm",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "",
                        "item": {
                            "id": 15,
                            "name": "Engine_power [-]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 16,
                        "vehicle_id": 1,
                        "default_value": "N",
                        "is_category": true,
                        "min": -100,
                        "max": 200,
                        "position": "",
                        "item": {
                            "id": 16,
                            "name": "Wind_direction [deg]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 17,
                        "vehicle_id": 1,
                        "default_value": "0",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "",
                        "item": {
                            "id": 17,
                            "name": "Wind_speed [m/s]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 18,
                        "vehicle_id": 1,
                        "default_value": "100",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "",
                        "item": {
                            "id": 18,
                            "name": "Load_grip [%]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 19,
                        "vehicle_id": 1,
                        "default_value": "20",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "",
                        "item": {
                            "id": 19,
                            "name": "Track_temperature [°C]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 20,
                        "vehicle_id": 1,
                        "default_value": "1.0148",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "",
                        "item": {
                            "id": 20,
                            "name": "Track_pressure [kPa]"
                        },
                        "category_values": []
                    },
                    {
                        "id": 21,
                        "vehicle_id": 1,
                        "default_value": "61",
                        "is_category": false,
                        "min": -100,
                        "max": 200,
                        "position": "",
                        "item": {
                            "id": 21,
                            "name": "Track_humidity [%]"
                        },
                        "category_values": []
                    }
                ]
            }
        )
    }

    return (
        <>
            <div className='setup-history-main'>
                <div className='setup-header'>
                    <Header isSmallHeader={true} />
                    <div className='header-right'>SETUP HISTORY</div>
                </div>
                <SetupHistoryTable setupData={setupData.setups} vehicleMappingItems={vehicleMappingItems.vehicleItemMappings} setDefaultSetup={setDefaultSetup} handleCollection={handleCollection} handleLapChart={handleLapChart}/>
            </div>

        </>
    )
}

export default SetupHistory