import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

const MileagePressureTargetsTable = ({ setPressureTargets, tyreSheetInfo, emptyTableValue }) => {
    const { t } = useTranslation(['strategy'])

    useEffect(() => {
        emptyField()
        setPressureTargets([])
        if (tyreSheetInfo.length > 0) {
            const allElementsByClassName = document.querySelectorAll('.disableInputTB')

            allElementsByClassName.forEach(element => {
                const elementId = element.id

                document.getElementById(elementId).value = tyreSheetInfo[0][elementId]

                setPressureTargets(prev => ({
                    ...prev,
                    [elementId]: typeof tyreSheetInfo[0][elementId] === 'number' ? parseFloat(tyreSheetInfo[0][elementId]) : tyreSheetInfo[0][elementId]
                }))
            })
        }
    }, [tyreSheetInfo])

    useEffect(() => {
        if (emptyTableValue) {
            emptyField()
        }
    }, [emptyTableValue])

    const emptyField = () => {
        const allElementsByClassName = document.querySelectorAll('.disableInputTB')

        allElementsByClassName.forEach(element => {
            const id = element.id

            document.getElementById(id).value = ''
        })
    }

    const onChangeHandlerTextBox = (val) => (e) => {
        const valArr = val.split('-')
        const objectKey = valArr[0]
        let inputText = e.target.value

        if (e.target.type === 'number') {
            inputText = parseFloat(e.target.value)
        }
        
        setPressureTargets(prev => ({
            ...prev,
            [objectKey]: inputText
        }))
    }
    
    const onBlurHandler = (key) => async (e) => {
        if (tyreSheetInfo.length > 0) {
            const updatedObj = {
                [key]: e.target.value
            }
            const conditionObj = {
                CAR_NUMBER: tyreSheetInfo[0].CAR_NUMBER,
                CIRCUIT_ID: tyreSheetInfo[0].CIRCUIT_ID,
                EVENT_ID: tyreSheetInfo[0].EVENT_ID,
                RACE_DETAIL_ID: tyreSheetInfo[0].RACE_DETAIL_ID,
                DRIVER_NAME: tyreSheetInfo[0].DRIVER_NAME
            }
            const obj = {
                UPDATEDVALUE: updatedObj,
                CONDITIONS: conditionObj
            }
    
            try {
                const result = await updateCarTireHistory(obj)
                if (result.status === 200) {
                    alert(`Cold pressure targets ${t('general.wasChanged')}`)
                }
            } catch {
                alert('Unable to perform update')
            }
        }
    }

    return (
        <>
            <table border='1'>
                <tbody>
                    <tr>
                        <td colSpan={2}><strong>Cold pressure targets</strong></td>
                        <td>Dry</td>
                        <td colSpan={2}>@ reference T</td>
                        <td colSpan={2}>After fitting</td>
                        <td>Wet</td>
                        <td colSpan={2}>@ reference T</td>
                        <td colSpan={2}>After fitting</td>
                    </tr>
                    <tr>
                        <td>Ref. Temp. [°C]</td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='REF_TEMP' type='number' onChange={onChangeHandlerTextBox('REF_TEMP')} onBlur={onBlurHandler('REF_TEMP')}/></td>
                        <td><input className='disableInputTB' style={{ width: '70px' }} id='DRY_REF_TEMP' type='text' onChange={onChangeHandlerTextBox('DRY_REF_TEMP')} onBlur={onBlurHandler('DRY_REF_TEMP')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='DRY_REF_T_FL' type='number' onChange={onChangeHandlerTextBox('DRY_REF_T_FL')} onBlur={onBlurHandler('DRY_REF_T_FL')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='DRY_REF_T_FR' type='number' onChange={onChangeHandlerTextBox('DRY_REF_T_FR')} onBlur={onBlurHandler('DRY_REF_T_FR')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='DRY_AFTER_FITTING_FL' type='number' onChange={onChangeHandlerTextBox('DRY_AFTER_FITTING_FL')} onBlur={onBlurHandler('DRY_AFTER_FITTING_FL')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='DRY_AFTER_FITTING_FR' type='number' onChange={onChangeHandlerTextBox('DRY_AFTER_FITTING_FR')} onBlur={onBlurHandler('DRY_AFTER_FITTING_FR')}/></td>
                        <td><input className='disableInputTB' style={{ width: '70px' }} id='WET_REF_TEMP' type='text' onChange={onChangeHandlerTextBox('WET_REF_TEMP')} onBlur={onBlurHandler('WET_REF_TEMP')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='WET_REF_T_FL' type='number' onChange={onChangeHandlerTextBox('WET_REF_T_FL')} onBlur={onBlurHandler('WET_REF_T_FL')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='WET_REF_T_FR' type='number' onChange={onChangeHandlerTextBox('WET_REF_T_FR')} onBlur={onBlurHandler('WET_REF_T_FR')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='WET_AFTER_FITTING_FL' type='number' onChange={onChangeHandlerTextBox('WET_AFTER_FITTING_FL')} onBlur={onBlurHandler('WET_AFTER_FITTING_FL')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='WET_AFTER_FITTING_FR' type='number' onChange={onChangeHandlerTextBox('WET_AFTER_FITTING_FR')} onBlur={onBlurHandler('WET_AFTER_FITTING_FR')}/></td>
                    </tr>
                    <tr>
                        <td>atm. Press. [bar]</td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='ATM_PRESS' type='number' onChange={onChangeHandlerTextBox('ATM_PRESS')} onBlur={onBlurHandler('ATM_PRESS')}/></td>
                        <td><input className='disableInputTB' style={{ width: '70px' }} id='DRY_ATM_PRESS' type='text' onChange={onChangeHandlerTextBox('DRY_ATM_PRESS')} onBlur={onBlurHandler('DRY_ATM_PRESS')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='DRY_REF_T_RL' type='number' onChange={onChangeHandlerTextBox('DRY_REF_T_RL')} onBlur={onBlurHandler('DRY_REF_T_RL')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='DRY_REF_T_RR' type='number' onChange={onChangeHandlerTextBox('DRY_REF_T_RR')} onBlur={onBlurHandler('DRY_REF_T_RR')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='DRY_AFTER_FITTING_RL' type='number' onChange={onChangeHandlerTextBox('DRY_AFTER_FITTING_RL')} onBlur={onBlurHandler('DRY_AFTER_FITTING_RL')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='DRY_AFTER_FITTING_RR' type='number' onChange={onChangeHandlerTextBox('DRY_AFTER_FITTING_RR')} onBlur={onBlurHandler('DRY_AFTER_FITTING_RR')}/></td>
                        <td><input className='disableInputTB' style={{ width: '70px' }} id='WET_ATM_PRESS' type='text' onChange={onChangeHandlerTextBox('WET_ATM_PRESS')} onBlur={onBlurHandler('WET_ATM_PRESS')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='WET_REF_T_RL' type='number' onChange={onChangeHandlerTextBox('WET_REF_T_RL')} onBlur={onBlurHandler('WET_REF_T_RL')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='WET_REF_T_RR' type='number' onChange={onChangeHandlerTextBox('WET_REF_T_RR')} onBlur={onBlurHandler('WET_REF_T_RR')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='WET_AFTER_FITTING_RL' type='number' onChange={onChangeHandlerTextBox('WET_AFTER_FITTING_RL')} onBlur={onBlurHandler('WET_AFTER_FITTING_RL')}/></td>
                        <td><input className='disableInputTB' style={{ width: '40px' }} id='WET_AFTER_FITTING_RR' type='number' onChange={onChangeHandlerTextBox('WET_AFTER_FITTING_RR')} onBlur={onBlurHandler('WET_AFTER_FITTING_RR')}/></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default MileagePressureTargetsTable

function updateCarTireHistory(obj) {
    return new Promise ((resolve, reject) => {
        fetch('/api/carTireHistory/updateCarTireHistoryValues', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json)
            })
            .catch((error) => {
                reject(error)
            })
    })
}