import React, { useState, useEffect } from 'react'

import GetFile from '../services/getFile'

import '../css/Track.css'

const Track = ({ trackId }) => {
    const [ imageData, setImageData ] = useState()

    useEffect(() => {
        const fetchImage = async () => {
            const data = await GetFile(`/data/img/track/${trackId}.png`, 'buffer')
            setImageData(data)
        }

        fetchImage()
    }, [trackId])

    return (
        <div className='track-container'>
            <div className='track-content'>
                <img src={imageData ? URL.createObjectURL(new Blob([imageData])) : ''} alt={`Track ${trackId}`} />
            </div>
        </div>
    )
}

export default Track