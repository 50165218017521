import { DataFrame } from 'danfojs'

// ObjectよりInputのDataframe作成
const makeInputdf = (Obj_Setup, df_DOEcondition) => {
    console.log("makeInputdf Obj_Setup", Obj_Setup)
    let newArray = []
    for(let j = 0; j < Obj_Setup[Object.keys(Obj_Setup)[0]].data.length; j++) {
        let Obj = {}
        for(let i = 0; i < Object.keys(Obj_Setup).length; i++) {
            Obj[df_DOEcondition['Parameter'].values[i]] = Obj_Setup[Object.keys(Obj_Setup)[i]].data[j]
        }
        newArray.push(Obj)
    }
    const df = new DataFrame(newArray)
    return df
}

export default makeInputdf