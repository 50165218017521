import React, { useState, useEffect } from 'react'

import { useLiveData } from '../../DataContext'

import TimeFormatter from './TimeFormatter'

import followImage from '../image/follow.png'

import { useTranslation } from 'react-i18next'

const StintHistory = () => {
    const { t } = useTranslation(['strategy', 'general'])

    const [stintHistory, setStintHistory] = useState([])
    const [carNumbers, setCarNumbers] = useState([])
    const [carNumberStintDataObj, setCarNumberStintDataObj] = useState([])
    const [maxLength, setMaxLength] = useState(null)

    const {raceDetailId, followCarNumber, rivalCarNumbers} = useLiveData()

    useEffect(() => {
        const getStintHistory = () => {
            fetch(`/api/lap/buildStintSummary/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    let data = json.data
                    setStintHistory(data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        if (raceDetailId) {
            getStintHistory()
        }
    }, [raceDetailId])

    useEffect(() => {
        const carNumbers = Array.from(new Set(stintHistory.map(stintData => stintData.CAR_NUMBER)))
        setCarNumbers(carNumbers)
        const carNumberStintDataObjTmp = {}
        carNumbers.forEach(carNumber => {
            carNumberStintDataObjTmp[carNumber] = stintHistory.filter(stintData => stintData.CAR_NUMBER === carNumber)   
        })
        setCarNumberStintDataObj(carNumberStintDataObjTmp)
        const maxLength = Math.max(...Object.values(carNumberStintDataObjTmp).map(arr => arr.length))
        setMaxLength(maxLength)
    }, [stintHistory])

    return (
        <div>
            {(followCarNumber || rivalCarNumbers.length) && Object.keys(carNumberStintDataObj).length &&
            <>
                <div className='stint-history-tit'>
                    <h2 className='mark-tit'>{t('stintHistory.markedCar')}</h2>
                </div>
                <table className='stint-history-table stint-history-markertable'>
                    <span className='side-contens contens-left'>◯◯◯</span>
                    <span className='side-contens contens-right'>◯◯◯</span>
                    <thead>
                        <tr>
                            <th>Car Number</th>
                            {Array.from({ length: maxLength }, (_, i) => (
                                <th key = {i}>{i+1}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {[followCarNumber, ...rivalCarNumbers].map((str, index) => {
                        const carNumber = parseInt(str, 10);
                        const isFirstcontents = index === 0;

                    return (
                        <tr className={isFirstcontents ? 'follow-column follow-tr' : 'follow-column rival-tr'} key={index}>
                                {/* <span className={isFirstcontents ? 'follow-column follow-tr' : 'follow-column rival-tr'}></span> */}
                            <td className={isFirstcontents ? 'first-td' : 'rivel-td'}>
                                {isFirstcontents ? (
                                <span className='follow'>
                                    <img src={followImage} alt='Follow Image'/>
                                </span>
                                ) : (
                                <span className='rivval'>VS</span>
                                )}
                                {carNumber}
                            </td>
                            {carNumberStintDataObj[carNumber].map((stintData, stintIndex) => (
        
                            <td className='detailed-time-td' key={stintIndex}>
                                {stintData.DRIVER_TYPE} <span className='stintdata-lasps'>{stintData.LAPS}</span><TimeFormatter seconds={stintData.TOTAL_TIME} decimalPlaces={0}/>
                            </td>
                        
                        ))}
                    </tr>
                );
            })}

                    </tbody>
                </table>
            </>
            }
            <div className='stint-history-tit'>
                <h2 className='all-tit'>{t('general.all')}</h2>
            </div>
            <table className='stint-history-table stint-history-alltable'>
                <span className='side-contens contens-left'>◯◯◯</span>
                <span className='side-contens contens-right'>◯◯◯</span>
                <thead>
                    <tr>
                    <span className='tr-before-content'></span>
                        <th>Car Number</th>
                        {Array.from({ length: maxLength }, (_, i) => (
                            <th key = {i}>{i+1}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {carNumbers.map((carNumber, index) => (
                            <tr key = {index}>
                            <span className='tr-before-content'></span>
                            <td>{carNumber}</td>
                            {carNumberStintDataObj[carNumber].map((stintData, stintIndex) => (
                                <td className='detailed-time-td' key = {stintIndex} >
                                    {stintData.DRIVER_TYPE} <span className='stintdata-lasps'>{stintData.LAPS}</span>  <TimeFormatter seconds={stintData.TOTAL_TIME} decimalPlaces={0}/>                                   
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default StintHistory
