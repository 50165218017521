import { useEffect, useState } from 'react'
import * as DOMPurify from 'dompurify'

import '../css/Mileage.css'

import { useTranslation } from 'react-i18next'

const MileageEachRowInput = ({ rowId, userId, tyreSheetHeaderTable, pressureTargets, setMileageInfo, mileageInfo, value, setIfNewRowInsert, prevTyreSheetInfo, prevValue }) => {
    const { t } = useTranslation(['strategy', 'general'])
    
    const [toggleButton, setToggleButton] = useState('create')
    const [isPreviousReference, setIsPreviousReference] = useState(false)
    console.log(userId)

    useEffect(() => {
        if (value) {
            if (value.length) {
                const allElementsByClassName = document.querySelectorAll('.inputTextBox')
                const allPos = ['FL', 'FR', 'RL', 'RR']

                allElementsByClassName.forEach(element => {
                    const key = element.id.split('-')[0]
                    const pos = element.id.split('-')[1]

                    if (allPos.includes(pos)) {
                        const findSide = value.find(el => el.POSITION === pos)
                        if (findSide) {
                            document.getElementById(`${key}-${pos}-${rowId}`).value = findSide[key] ? findSide[key] : ''
                        }
                    } else if (pos === 'A') {
                        document.getElementById(`${key}-A-${rowId}`).value = value[0][key]
                    } else if (pos === 'F') {
                        const findSide = value.find(el => el.POSITION === 'FR')
                        if (key === 'TOTAL_MILEAGE') {
                            document.getElementById(`${key}-F-${rowId}`).innerText = findSide ? parseFloat(findSide[key]).toFixed(3) : ''
                        } else {
                            document.getElementById(`${key}-F-${rowId}`).value = findSide ? findSide[key] : ''
                        }
                    } else if (pos === 'R') {
                        const findSide = value.find(el => el.POSITION === 'RR')
                        if (key === 'TOTAL_MILEAGE') {
                            document.getElementById(`${key}-R-${rowId}`).innerText = findSide ? parseFloat(findSide[key]).toFixed(3) : ''
                        } else {
                            document.getElementById(`${key}-R-${rowId}`).value = findSide ? findSide[key] : ''
                        }
                    }
                })
            }
            setToggleButton('update')
        } else if (prevValue) {
            const allElementsByClassName = document.querySelectorAll('.inputTextBox')
            const allPos = ['FL', 'FR', 'RL', 'RR']

            allElementsByClassName.forEach(element => {
                const key = element.id.split('-')[0]
                const pos = element.id.split('-')[1]

                if (allPos.includes(pos)) {
                    const findSide = prevValue.find(el => el.POSITION === pos)
                    if (findSide) {
                        const inputValue = findSide[key] ? findSide[key] : ''
                        if (key === 'WEIGHT_START') {
                            document.getElementById(`${key}-${pos}-${rowId}`).value = ''
                        } else if (key === 'WEIGHT_USED') {
                            document.getElementById(`WEIGHT_START-${pos}-${rowId}`).value = inputValue
                            onChangeTextBox2(`WEIGHT_START-${pos}`, inputValue, element.type)
                        } else {
                            document.getElementById(`${key}-${pos}-${rowId}`).value = inputValue
                            onChangeTextBox2(`${key}-${pos}`, inputValue, element.type)
                        }
                    }
                } else if (pos === 'A') {
                    const inputValue = prevValue[0][key]
                    document.getElementById(`${key}-A-${rowId}`).value = inputValue
                    onChangeTextBox2(`${key}-A`, inputValue, element.type)
                } else if (pos === 'F') {
                    const findSide = prevValue.find(el => el.POSITION === 'FR')
                    if (key === 'TOTAL_MILEAGE') {
                        const inputValue = findSide[key] ? parseFloat(findSide[key]).toFixed(3) : null
                        document.getElementById(`PREVIOUS_MILEAGE-F-${rowId}`).value = inputValue
                        onChangeTextBox2(`PREVIOUS_MILEAGE-F`, inputValue, element.type)
                    } else if (key === 'LAPS_DONE' || key === 'PREVIOUS_MILEAGE') {
                        document.getElementById(`${key}-F-${rowId}`).value = ''
                    } else {
                        const inputValue = findSide[key] ? findSide[key] : ''
                        document.getElementById(`${key}-F-${rowId}`).value = inputValue
                        onChangeTextBox2(`${key}-F`, inputValue, element.type)
                    }
                } else if (pos === 'R') {
                    const findSide = prevValue.find(el => el.POSITION === 'RR')
                    if (key === 'TOTAL_MILEAGE') {
                        const inputValue = findSide[key] ? parseFloat(findSide[key]).toFixed(3) : null
                        document.getElementById(`PREVIOUS_MILEAGE-R-${rowId}`).value = inputValue
                        onChangeTextBox2(`PREVIOUS_MILEAGE-R`, inputValue, element.type)
                    } else if (key === 'LAPS_DONE' || key === 'PREVIOUS_MILEAGE') {
                        document.getElementById(`${key}-R-${rowId}`).value = ''
                    } else {
                        const inputValue = findSide[key] ? findSide[key] : ''
                        document.getElementById(`${key}-R-${rowId}`).value = inputValue
                        onChangeTextBox2(`${key}-R`, inputValue, element.type)
                    }
                }
            })

            setToggleButton('create')
            setIsPreviousReference(true)
        } else {
            const allElementsByClassName = document.querySelectorAll('.inputTextBox')
            const allPos = ['FL', 'FR', 'RL', 'RR']

            allElementsByClassName.forEach(element => {
                const key = element.id.split('-')[0]
                const pos = element.id.split('-')[1]

                if (allPos.includes(pos)) {
                    document.getElementById(`${key}-${pos}-${rowId}`).value = ''
                } else if (pos === 'A') {
                    document.getElementById(`${key}-A-${rowId}`).value = ''
                } else if (pos === 'F') {
                    if (key === 'TOTAL_MILEAGE') {
                        document.getElementById(`${key}-F-${rowId}`).innerText = ''
                    } else {
                        document.getElementById(`${key}-F-${rowId}`).value = ''
                    }
                } else if (pos === 'R') {
                    if (key === 'TOTAL_MILEAGE') {
                        document.getElementById(`${key}-R-${rowId}`).innerText = ''
                    } else {
                        document.getElementById(`${key}-R-${rowId}`).value = ''
                    }
                }
            })
            setToggleButton('create')
        }
    }, [value, prevValue])

    useEffect(() => {
        const allPos = ['FR', 'RR']

        allPos.forEach(pos => {
            const findPrevMileage = prevTyreSheetInfo.find(el => el.POSITION === pos && el.ROW_ID === parseInt(rowId))
            const frontOrRear = pos[0]
            document.getElementById(`PREVIOUS_MILEAGE-${frontOrRear}-${rowId}`).value = findPrevMileage?.TOTAL_MILEAGE ? findPrevMileage.TOTAL_MILEAGE.toFixed(3) : ''
        })
    }, [rowId, prevTyreSheetInfo])

    const updateMileageInfo = (prev, rowId, positions, objectKey, inputText) => {
        let currentRow = prev[rowId] || {};
        let updatedRow = { ...currentRow };

        inputText = DOMPurify.sanitize(inputText)
    
        positions.forEach(pos => {
            let currentPosition = currentRow[pos] || {};
            let updatedPosition = { ...currentPosition, [objectKey]: inputText };
            updatedRow[pos] = updatedPosition;
        });
    
        return { ...prev, [rowId]: updatedRow };
    };
    
    const handleTotalMileageUpdate = (getPos, objectKey, inputText, rowId) => {
        inputText = DOMPurify.sanitize(inputText)
        const lapDone = objectKey === 'LAPS_DONE' ? inputText : mileageInfo[getPos]?.LAPS_DONE;
        const getPrevMileage = mileageInfo[getPos]?.PREVIOUS_MILEAGE || document.getElementById(`PREVIOUS_MILEAGE-${getPos}-${rowId}`).value;
        const prevMileage = objectKey === 'PREVIOUS_MILEAGE' ? inputText : getPrevMileage;
    
        const newTotalMileage = calculateTotalMileage(lapDone, parseFloat(prevMileage));
        document.getElementById(`TOTAL_MILEAGE-${getPos}-${rowId}`).innerText = newTotalMileage;
    
        const positions = (getPos === 'F') ? ['FR', 'FL'] : (getPos === 'R') ? ['RR', 'RL'] : [getPos];
        setMileageInfo(prev => updateMileageInfo(prev, rowId, positions, 'TOTAL_MILEAGE', newTotalMileage));
    };
    
    const onChangeTextBoxCommon = (elementId, inputText) => {
        inputText = DOMPurify.sanitize(inputText)
        const valArr = elementId.split('-');
        const objectKey = valArr[0];
        const getPos = valArr[1];
    
        const positionMapping = {
            'A': ['FL', 'FR', 'RL', 'RR'],
            'F': ['FL', 'FR'],
            'R': ['RL', 'RR']
        };
    
        const positions = positionMapping[getPos] || [getPos];
    
        setMileageInfo(prev => updateMileageInfo(prev, rowId, positions, objectKey, inputText));
    
        if (objectKey === 'LAPS_DONE' || objectKey === 'PREVIOUS_MILEAGE') {
            handleTotalMileageUpdate(getPos, objectKey, inputText, rowId)
        }
    }
    
    // 以下、元の関数をこの新しい共通関数を使用するように更新
    const onChangeTextBox = (elementId) => (e) => {
        let inputText = e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value
        inputText = DOMPurify.sanitize(inputText)
        onChangeTextBoxCommon(elementId, inputText)
    }

    const onChangeTextBox2 = (elementId, inputValue, inputType) => {
        let inputText = inputType === 'number' ? parseFloat(inputValue) : inputValue
        inputText = DOMPurify.sanitize(inputText)
        onChangeTextBoxCommon(elementId, inputText)
    }

    const calculateTotalMileage = (lapDone, prevMileage) => {
        if (!lapDone) {
            lapDone = 0
        }

        prevMileage = prevMileage ? prevMileage : 0
        const circuitLength = tyreSheetHeaderTable.LENGTH
        const totalMileage = lapDone * circuitLength + prevMileage

        return totalMileage.toFixed(3)
    }

    const onClickCreate = async () => {
        let insertVal = []
        Object.entries(mileageInfo[rowId]).forEach((data) => {
            const pos = data[0]
            const eachInfo = data[1]
            let totalMileage
            if (pos === 'FR' || pos === 'FL') {
                const htmlValue = document.getElementById(`TOTAL_MILEAGE-F-${rowId}`).innerText
                totalMileage = parseFloat(DOMPurify.sanitize(htmlValue))
            } else {
                const htmlValue = document.getElementById(`TOTAL_MILEAGE-R-${rowId}`).innerText
                totalMileage = parseFloat(DOMPurify.sanitize(htmlValue)) 
            }

            const tyreSheetHeaderTableObj = {
                ...tyreSheetHeaderTable,
                ENGINEER: DOMPurify.sanitize(document.getElementById('engineerTB').value),
                NO1_MECHANIC: DOMPurify.sanitize(document.getElementById('no1MechanicTB').value),
                CHASSIS: DOMPurify.sanitize(document.getElementById('chassisTB').value)
            }

            insertVal.push({
                ...tyreSheetHeaderTableObj,
                ...pressureTargets,
                ...eachInfo,
                'POSITION': pos,
                'TOTAL_MILEAGE': totalMileage,
                'ROW_ID': parseInt(rowId),
                'USER_ID': parseInt(userId)
            })
        })

        const bulkInsert = await bulkInsertIntoCarTireHistory(insertVal)
        if (bulkInsert.status === 200) {
            const { [rowId]: _, ...newObj } = mileageInfo
            setMileageInfo(newObj)
            setToggleButton('update')
            setIfNewRowInsert(true)
        }
    }

    const onClickUpdate = () => {
        let updateObj = {
            ROW_ID: value[0].ROW_ID,
            CAR_NUMBER: tyreSheetHeaderTable.CAR_NUMBER,
            RACE_DETAIL_ID: tyreSheetHeaderTable.RACE_DETAIL_ID,
            EVENT_ID: tyreSheetHeaderTable.EVENT_ID,
            CIRCUIT_ID: tyreSheetHeaderTable.CIRCUIT_ID,
            DRIVER_NAME: tyreSheetHeaderTable.DRIVER_NAME
        }

        let promiseAll = []
        Object.entries(mileageInfo[rowId]).forEach((data) => {
            const pos = data[0]
            const eachInfo = data[1]
            updateObj['POSITION'] = pos

            const obj = {
                UPDATEDVALUE: eachInfo,
                CONDITIONS: updateObj
            }
            promiseAll.push(updateEachData(obj))
        })

        Promise.all(promiseAll)
            .then(() => {
                const { [rowId]: _, ...newObj } = mileageInfo
                setMileageInfo(newObj)
                alert(t('mileageEachRowInput.editSaved'))
            })
            .catch(error => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
    }

    return (
        <>
            <tr style={{backgroundColor: isPreviousReference ? '#fffacd' : 'none'}}>
                <td rowSpan={2}>
                    <input id={'SET_N-A-' + rowId} className='inputTextBox inputTB70px' type='text' onChange={onChangeTextBox('SET_N-A')}></input>
                </td>
                <td className='dottedBottom'>
                    <input id={'LAPS_DONE-F-' + rowId} className='inputTextBox inputTB70px' type='number' onChange={onChangeTextBox('LAPS_DONE-F')}></input>
                </td>
                <td className='dottedBottom'>
                    <input id={'SPEC_TYPE-F-' + rowId} className='inputTextBox inputTB70px' type='text' onChange={onChangeTextBox('SPEC_TYPE-F')}></input>
                </td>
                <td rowSpan={2}>
                    <input id={'TYPE-A-' + rowId} className='inputTextBox inputTB70px' type='text' onChange={onChangeTextBox('TYPE-A')}></input>
                </td>
                <td className='dottedBottom dottedRight'>
                    <input id={'WEIGHT_START-FL-' + rowId} className='inputTextBox inputTB40px' type='number' onChange={onChangeTextBox('WEIGHT_START-FL')}></input>
                </td>
                <td className='dottedBottom'>
                    <input id={'WEIGHT_START-FR-' + rowId} className='inputTextBox inputTB40px' type='number' onChange={onChangeTextBox('WEIGHT_START-FR')}></input>
                </td>
                <td className='dottedBottom dottedRight'>
                    <input id={'WEIGHT_USED-FL-' + rowId} className='inputTextBox inputTB40px' type='number' onChange={onChangeTextBox('WEIGHT_USED-FL')}></input>
                </td>
                <td className='dottedBottom'>
                    <input id={'WEIGHT_USED-FR-' + rowId} className='inputTextBox inputTB40px' type='number' onChange={onChangeTextBox('WEIGHT_USED-FR')}></input>
                </td>
                <td className='dottedBottom dottedRight'>
                    <input id={'WHEEL_NUM-FL-' + rowId} className='inputTextBox inputTB40px' type='text' onChange={onChangeTextBox('WHEEL_NUM-FL')}></input>
                </td>
                <td className='dottedBottom'>
                    <input id={'WHEEL_NUM-FR-' + rowId} className='inputTextBox inputTB40px' type='text' onChange={onChangeTextBox('WHEEL_NUM-FR')}></input>
                </td>
                <td className='dottedBottom dottedRight'>
                    <input id={'TPMS_NUM-FL-' + rowId} className='inputTextBox inputTB40px' type='text' onChange={onChangeTextBox('TPMS_NUM-FL')}></input>
                </td>
                <td className='dottedBottom'>
                    <input id={'TPMS_NUM-FR-' + rowId} className='inputTextBox inputTB40px' type='text' onChange={onChangeTextBox('TPMS_NUM-FR')}></input>
                </td>
                <td className='dottedBottom'>
                    <input id={'PREVIOUS_MILEAGE-F-' + rowId} className='inputTextBox inputTB70px' type='number' onChange={onChangeTextBox('PREVIOUS_MILEAGE-F')}></input>
                </td>
                <td className='dottedBottom'>
                    <div style={{color: isPreviousReference ? 'black' : 'white'}} className='inputTextBox inputTB60px' id={'TOTAL_MILEAGE-F-' + rowId}></div>
                </td>
                <td rowSpan={2}>
                    <textarea id={'COMMENTS-A-' + rowId} className='inputTextBox textareaWidth' rows='2' onChange={onChangeTextBox('COMMENTS-A')}></textarea>
                </td>
                <td rowSpan={2}>
                    {
                        toggleButton === 'create' ?
                        <button id={'createButton-create-' + rowId} className='inputTextBox createButton' onClick={onClickCreate} style={{backgroundColor: mileageInfo[rowId] ? 'yellow' : 'white'}}>create</button>
                        :
                        <button id={'createButton-update-' + rowId} className='inputTextBox createButton' onClick={onClickUpdate} style={{backgroundColor: mileageInfo[rowId] ? 'yellow' : 'white'}}>update</button>
                    }
                </td>
            </tr>
            <tr className='trBottom' style={{backgroundColor: isPreviousReference ? '#fffacd' : 'none'}}>
                <td>
                    <input id={'LAPS_DONE-R-' + rowId} className='inputTextBox inputTB70px' type='number' onChange={onChangeTextBox('LAPS_DONE-R')}></input>
                </td>
                <td>
                    <input id={'SPEC_TYPE-R-' + rowId} className='inputTextBox inputTB70px' type='text' onChange={onChangeTextBox('SPEC_TYPE-R')}></input>
                </td>
                <td className='dottedRight'>
                    <input id={'WEIGHT_START-RL-' + rowId} className='inputTextBox inputTB40px' type='number' onChange={onChangeTextBox('WEIGHT_START-RL')}></input>
                </td>
                <td>
                    <input id={'WEIGHT_START-RR-' + rowId} className='inputTextBox inputTB40px' type='number' onChange={onChangeTextBox('WEIGHT_START-RR')}></input>
                </td>
                <td className='dottedRight'>
                    <input id={'WEIGHT_USED-RL-' + rowId} className='inputTextBox inputTB40px' type='number' onChange={onChangeTextBox('WEIGHT_USED-RL')}></input>
                </td>
                <td>
                    <input id={'WEIGHT_USED-RR-' + rowId} className='inputTextBox inputTB40px' type='number' onChange={onChangeTextBox('WEIGHT_USED-RR')}></input>
                </td>
                <td className='dottedRight'>
                    <input id={'WHEEL_NUM-RL-' + rowId} className='inputTextBox inputTB40px' type='text' onChange={onChangeTextBox('WHEEL_NUM-RL')}></input>
                </td>
                <td>
                    <input id={'WHEEL_NUM-RR-' + rowId} className='inputTextBox inputTB40px' type='text' onChange={onChangeTextBox('WHEEL_NUM-RR')}></input>
                </td>
                <td className='dottedRight'>
                    <input id={'TPMS_NUM-RL-' + rowId} className='inputTextBox inputTB40px' type='text' onChange={onChangeTextBox('TPMS_NUM-RL')}></input>
                </td>
                <td>
                    <input id={'TPMS_NUM-RR-' + rowId} className='inputTextBox inputTB40px' type='text' onChange={onChangeTextBox('TPMS_NUM-RR')}></input>
                </td>
                <td>
                    <input id={'PREVIOUS_MILEAGE-R-' + rowId} className='inputTextBox inputTB70px' type='number' onChange={onChangeTextBox('PREVIOUS_MILEAGE-R')}></input>
                </td>
                <td>
                    <div style={{color: isPreviousReference ? 'black' : 'white'}} className='inputTextBox inputTB60px' id={'TOTAL_MILEAGE-R-' + rowId}></div>
                </td>
            </tr>
        </>
    )
}

export default MileageEachRowInput

function bulkInsertIntoCarTireHistory(obj) {
    return new Promise ((resolve, reject) => {
        fetch('/api/carTireHistory/bulkInsertIntoCarTireHistory', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .then(json => {
            resolve(json)
        })
        .catch((error) => {
            reject(error)
        })
    })
}

function updateEachData(obj) {
    fetch('/api/carTireHistory/updateCarTireHistoryValues', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .then(json => {
            return json
        })
        .catch((error) => {
            return error
        })
}