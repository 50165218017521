//Scatter用セレクトボックスのためにカテゴリ値を除外した配列を作成
const extractContinuous = (df) => {
    const  Array_conti = []
    for (let i = 0; i < df.index.length; i++) {
        const valueDOE = df.iloc({'rows':[i], 'columns':[1]}).values[0][0]

        if (typeof(valueDOE) === 'string') {
            // TODO: do something
        } else {
            Array_conti.push(df.index[i])
        }

    }

    return Array_conti
}

export default extractContinuous