import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, TableContainer, Table, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

import '../css/headerMenu.css';

import { colorScale } from '../services/Util';

import TimeFormatter from './TimeFormatter';
import { StyledHeaderTableCell } from './TableStyle';

const SameTeamLapTable = (props) => {
  const { t } = useTranslation(['strategy', 'general'])

  const { raceDetailId } = useParams()

  const [backgroundColor, setBackgroundColor] = useState(null)
  const [bestLap, setBestLap] = useState(null)
  const [bestTimes, setBestTimes] = useState(null)
  const [speedBackgroundColor, setSpeedBackgroundColor] = useState(null)
  const [tableData, setTableData] = useState()
  const [teamLapTimeData, setTeamLapTimeData] = useState()

  const TableheadStyle ={
    fontWeight:'bold',
    color:'white',
    textAlign:'center',
    width:'40px'
  }


  const transposeArray = (array) => {
    return  array[0].map((_, columnIndex) => array.map(row => row[columnIndex]))
  }
  
  const controlTableData = (inputData) => {
    const tableData = {};
    tableData['header'] = Object.keys(inputData)
    tableData['timeRows'] = [];
    tableData['speedRows'] = [];
    const addLength = 1
    const maxRowLength = Math.max(...Object.values(inputData).map(lapData => lapData.length))+addLength
    
    
    for (let i = 0; i < maxRowLength; i++) {
        const lapNumber = (i+1).toString()
        const row = [lapNumber];

        for (const header of tableData['header']) {   
          if (props.sortType === 'time') {
            inputData[header] = inputData[header].sort((a, b) => a.TIME - b.TIME);
          }
          if (props.sortType === 'speed') {
            inputData[header] = inputData[header].sort((a, b) => b.SPEED - a.SPEED);
          }
          const cell = inputData[header][i] ? inputData[header][i].TIME : null;
          row.push(cell);
        }
        tableData['timeRows'].push(row);
    }

    for (let i = 0; i < maxRowLength; i++) {
      const lapNumber = (i+1).toString()
      const row = [lapNumber];
      for (const header of tableData['header']) {
        const cell = inputData[header][i] ? inputData[header][i].SPEED : null;
        row.push(cell);
      }
      tableData['speedRows'].push(row);
    }
    return tableData
  }

  const getBestData = (tableDataRows) => {
    let bestDataArray = ['best'];
    for (let i = 1; i < tableDataRows[0].length; i++) {
        // 各列の値を取得して数値のみの配列を作成
        let columnValues = tableDataRows.map(function(row) {
            return row[i];
        });

        bestDataArray.push(Math.min(...columnValues.filter(Number)));
    }
    return bestDataArray;
  }

  const setHeatmapArray = (tableData) => { 
    const timeArray = tableData.map(array => array.slice(1))
    const timeArrysTransposed = timeArray.length ? transposeArray(timeArray) : undefined
    
    const allHeatMapArray = timeArrysTransposed ? timeArrysTransposed.map(timeArray => {
        const colorArray = colorScale(timeArray, 0xff523c, 0x3a4eff, 'normal')
        if (timeArray.includes(bestLap.TIME)) {
            colorArray[timeArray.indexOf(bestLap.TIME)] = 'b8a1e6'
        }
        return colorArray
    }) : []

    const backgroundColorTranspose = allHeatMapArray.length ? transposeArray(allHeatMapArray) : null
    const backgroundColorTransposeAddLapColor = backgroundColorTranspose ? backgroundColorTranspose.map(row => ['f2f2f2', ...row]): null
    
    if (backgroundColorTransposeAddLapColor){     
        const backgroundColor = backgroundColorTransposeAddLapColor.flat() 
        setBackgroundColor(backgroundColor)           
    }
  }

  const setSpeedHeatmapArray = (tableData) => { 
    const timeArray = tableData.map(array => array.slice(1))
    const timeArrysTransposed = timeArray.length ? transposeArray(timeArray) : undefined
    
    const allHeatMapArray = timeArrysTransposed ? timeArrysTransposed.map(timeArray => {
        const colorArray = colorScale(timeArray, 0x3a4eff, 0xff523c, 'normal')
        return colorArray
    }) : []

    const backgroundColorTranspose = allHeatMapArray.length ? transposeArray(allHeatMapArray) : null
    const backgroundColorTransposeAddLapColor = backgroundColorTranspose ? backgroundColorTranspose.map(row => ['f2f2f2', ...row]): null
    
    if (backgroundColorTransposeAddLapColor){     
        const backgroundColor = backgroundColorTransposeAddLapColor.flat() 
        setSpeedBackgroundColor(backgroundColor)           
    }
  }

  useEffect(() => {
    const getBestLap = () => {
      fetch(`/api/lap/getBestLapByRaceDetailedId/${raceDetailId}`)
      .then(response => response.json())
      .then(json => {
          if(json.status === 200){
              setBestLap(json.data);
          }
      })
      .catch((error) => {
          alert(t('pleaseContactAdmin', {ns: 'general'}))
      })
    }
    if (raceDetailId){
      getBestLap()
    }   
  }, [raceDetailId]) 
    
  useEffect(() => {
    if(props.carNumber){
      const getTeamLapTime = () => {
        fetch(`/api/lap/getTeamLapTimeAndSpeedByRaceDetailIdAndCarNumber/${raceDetailId}/${props.carNumber}`)
        .then(response => response.json())
        .then(json => {
            if(json.status === 200){
              setTeamLapTimeData(json.data);
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
      }
      getTeamLapTime()
    }
  }, [props])

  useEffect(() =>{
    if(teamLapTimeData){
      setTableData(controlTableData(teamLapTimeData))    
    }
  }, [teamLapTimeData])

  useEffect(() =>{
    if(tableData && bestLap){
      setBestTimes(getBestData(tableData.timeRows))
      setHeatmapArray(tableData.timeRows)
      setSpeedHeatmapArray(tableData.speedRows)
    }
    }, [tableData])

  

  return (
    <>
      {speedBackgroundColor ?
      <TableContainer className= 'same-team-table' component={Paper}>
        <div className='lapTime-box'>
          <h3>Lap Time
            <ul className='same-team-tit-dec'>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </h3>
          <Table className= 'time-table'>  
            <TableHead>
                <TableRow>
                  <StyledHeaderTableCell className='lap-time-th' sx={{...TableheadStyle}}>{ 'lapTime' }</StyledHeaderTableCell>
                  {(tableData.header).map((head, index) => <th className='name-th' key={index}>{head}</th>)}
                </TableRow>
            </TableHead>
            <tbody>
              {tableData.timeRows.map((timeRow, rowIndex) => (
                <tr key={rowIndex}>
                  {timeRow.map((time, cellIndex) => (
                      <td key={cellIndex} className={cellIndex === 0 ? 'first-td' : ''}> 
                        <div style={{
                            textAlign: 'center', 
                            backgroundColor: cellIndex === 0
                                ? 'transparent'
                                : `#${(backgroundColor[cellIndex + (timeRow.length * rowIndex)] || 'ffffff').toString(16).padStart(6, '0')}`
                        }}>
                            {typeof time === 'string' ? time : <TimeFormatter seconds={time} />}
                        </div>
                      </td>
                  ))}
                </tr>
              ))}

                <tr>
                  {bestTimes.map((time, index) => (
                    <td className='best-td' key = {index}>
                      <div>
                        {index !== 0 ? <TimeFormatter seconds={time}/> : time}
                      </div>
                    </td>
                  ))}
                </tr>
                    
            </tbody>
          </Table>
        </div>
        <div className='Speed-box'>
          <h3>Speed
            <ul className='same-team-tit-dec'>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </h3>
        <Table className= 'speed-table'> 
          <TableHead>
              <TableRow>
                  <StyledHeaderTableCell className='lap-time-th' sx={{...TableheadStyle}}>{ 'speed' }</StyledHeaderTableCell>
                    {(tableData.header.map((head, index) => <th key={index}>{head}</th>))}
              </TableRow>
          </TableHead>
          <tbody>
                {tableData.speedRows.map((speedRow, rowIndex) => (
                  <tr key={rowIndex}>
                    {speedRow.map((speed, cellIndex) => (
                      <td className={cellIndex === 0 ? 'first-td' : ''} key={cellIndex}>  
                        <div style={{
                          textAlign: 'center',
                          backgroundColor: cellIndex === 0
                              ? 'transparent'
                              : `#${(speedBackgroundColor[cellIndex + (speedRow.length * rowIndex)] || 'ffffff').toString(16).padStart(6, '0')}`
                          }}>
                          {speed}
                        </div>
                      </td>
                    ))}
                  </tr>
                    ))}
                  <tr>
                    {bestTimes.map((time, index) => (
                      <td className='best-td' key = {index}>
                        <div>
                          {index !== 0 ? <TimeFormatter seconds={time}/> : time}
                        </div>
                      </td>
                    ))}
                  </tr>
          </tbody>
        </Table>
        </div>
      </TableContainer>
      :
      null}
    </>
  );
};

export default SameTeamLapTable;