import React from 'react'

import SetupUserInputTable from './SetupUserInput_Table'

const SetupUserInputChassisTab = (props) => {
    const chassisData = props.chassisData
    const setIsEditMode = props.setIsEditMode
    const setDataToEdit = props.setDataToEdit
    const setEditedData = props.setEditedData
    const setSetup = props.setSetup

    return (
        <>
            <div className='chassis-car car-side'></div>
            <div className='chassis-container flex'>
                <div className='chassis-left'>
                    <SetupUserInputTable
                        title='FRONT'
                        data={ chassisData.front }
                        setIsEditMode={ setIsEditMode }
                        type='chassis'
                        setDataToEdit={ setDataToEdit }
                        setEditedData={ setEditedData }
                        setSetup={ setSetup }
                    />
                </div>
                <div className='chassis-right'>
                    <SetupUserInputTable
                        title='REAR'
                        data={ chassisData.rear }
                        setIsEditMode={ setIsEditMode }
                        type='chassis'
                        setDataToEdit={ setDataToEdit }
                        setEditedData={ setEditedData }
                        setSetup={ setSetup }
                    />
                </div>
            </div>
        </>
    )
}

export default SetupUserInputChassisTab