import { useContext } from 'react'

import AuthContext from './authContext'

import './TopPage.css'

const TopPage = () => {
    const ctx = useContext(AuthContext)

    const adminHandler = () => {
        window.location.href = '/admin'
    }

    const strategyHandler = () => {
        // TODO: change URL?
        window.location.href = '/event'
    }

    const coreHandler = () => {
        window.location.href = '/core/optimize'
    }

    const coreAdminHandler = () => {
        window.location.href = '/core/admin'
    }

    const dashboardHandler = () => {
        window.location.href = '/event'
    }

    const setupHandler = () => {
        window.location.href = '/setup-history'
    }

    const trackTemperatureHandler = () => {
        window.location.href = '/trackTemperature'
    }

    const buttons = {
        admin: 
            <div className='button admin-button' onClick={ adminHandler }>
                ADMIN
                <span className='top-page-dec-top-left'></span>
                <span className='top-page-dec-top-right'></span>
                <span className='top-page-dec-bottom-left'></span>
                <span className='top-page-dec-bottom-right'></span>
            </div>,
        strategy:
            <div className='button strategy-button' onClick={ strategyHandler }>
                STRATEGY
                <span className='top-page-dec-top-left'></span>
                <span className='top-page-dec-top-right'></span>
                <span className='top-page-dec-bottom-left'></span>
                <span className='top-page-dec-bottom-right'></span>
            </div>,
        core:
            <div className='button core-button' onClick={ coreHandler }>
                CORE
                <span className='top-page-dec-top-left'></span>
                <span className='top-page-dec-top-right'></span>
                <span className='top-page-dec-bottom-left'></span>
                <span className='top-page-dec-bottom-right'></span>
            </div>,
        coreAdmin:
            <div className='button core-button' onClick={ coreAdminHandler }>
                CORE ADMIN
                <span className='top-page-dec-top-left'></span>
                <span className='top-page-dec-top-right'></span>
                <span className='top-page-dec-bottom-left'></span>
                <span className='top-page-dec-bottom-right'></span>
            </div>,
        dashboard:
            <div className='button dashboard-button' onClick={ dashboardHandler }>
                DASHBOARD
                <span className='top-page-dec-top-left'></span>
                <span className='top-page-dec-top-right'></span>
                <span className='top-page-dec-bottom-left'></span>
                <span className='top-page-dec-bottom-right'></span>
            </div>,
        setup: <div className='button dashboard-button' onClick={ setupHandler }>
                    SETUP
                    <span className='top-page-dec-top-left'></span>
                    <span className='top-page-dec-top-right'></span>
                    <span className='top-page-dec-bottom-left'></span>
                    <span className='top-page-dec-bottom-right'></span>
                </div>,
        trackTemperature: <div className='button dashboard-button' onClick={ trackTemperatureHandler }>
                    TRACK TEMP
                    <span className='top-page-dec-top-left'></span>
                    <span className='top-page-dec-top-right'></span>
                    <span className='top-page-dec-bottom-left'></span>
                    <span className='top-page-dec-bottom-right'></span>
                </div>
    }

    return (
        <>
        <span className='top-page-dec-top'></span>
            <div className='top-page-container'>
                <div className='buttons-container'>
                        <span className='dli-plus'></span>
                        <span className='dli-plus'></span>
                        <span className='dli-plus'></span>
                        <span className='dli-plus'></span>
                    <img src='../Logo_GR_small.png'/>
                    { ctx.role === 'admin' &&
                        <>
                            <div className='button-boxs'>
                                { buttons.admin }
                                { buttons.trackTemperature } 
                                { buttons.coreAdmin }
                            </div>
                            <div className='button-boxs'>
                                { buttons.core }
                                { buttons.setup }
                                { buttons.dashboard } 
                            </div>
                        </>
                    }
                    { ctx.role === 'user' &&
                        <>
                            <div className='button-boxs'>
                                { buttons.core }
                                { buttons.setup }
                                { buttons.dashboard }
                            </div>
                        </>
                    }
                    { ctx.role === 'coreAdmin' &&
                        <>
                            <div className='button-boxs'>
                                { buttons.core }
                                { buttons.coreAdmin }
                            </div>
                        </>
                    }
                    <div className='button-boxs'>
                        { ctx.role === 'strategy' && buttons.strategy }
                        { ctx.role === 'core' && buttons.core }
                        { (ctx.role === 'strategy' || ctx.role === 'dashboard') && buttons.dashboard }
                    </div>
                </div>
            </div>
        <span className='top-page-dec-bottom'></span>
        </>
    )
}

export default TopPage