import React, { useRef, useState, useEffect } from 'react'

import { TableRow } from '@mui/material'
import { StyledTableCell } from './TableStyle'

import { useLiveData } from '../../DataContext'

import setting from '../image/setting-icon.png'
import download from '../image/download-icon.png'

import '../css/headerSpMenu.css'
import '../css/headerSpMenu.css'

import TimeFormatter from './TimeFormatter'
import WeatherApp from './WeatherApp'

import { useTranslation } from 'react-i18next'

const HeaderWithMapMenu = (props) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [ columnOrder, setColumnOrder ] = useState([])
    const [ columns, setColumns ] = useState([])
    const [ currentUrl, setCurrentUrl ] = useState('')
    const [ remainingTimeAndLaps, setRemainingTimeAndLaps ] = useState()

    const sideMenuHintElement = useRef()

    const { tableData, isRace, raceSeries } = useLiveData()
    
    const columnIdAndName = props.columnIdAndName
    const raceTimeAndLaps = props.raceTimeAndLaps
    const totalTimeForRemainingTime = props.totalTimeForRemainingTime
    const flagInfo = props.flagInfo
    const displayRivalHeaders = props.displayRivalHeaders
    const setDisplayRivalHeaders = props.setDisplayRivalHeaders
    const mapDisplayLeaders = props.mapDisplayLeaders
    const setDisplayMapLeaders = props.setDisplayMapLeaders

    const closeSideMenuHint = (event) => {
        event.stopPropagation()
        if (sideMenuHintElement.current) {
            sideMenuHintElement.current.classList.remove('show')
        }
    }

    document.addEventListener('click', closeSideMenuHint)

    useEffect(() => {
        const getRaceDetailList = () => {
            fetch('/api/raceDetail/getAll')
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    let data = json.data
                    props.originalRaceDetailListUpdate(data)
                    const list = data.map((race, index) => (
                        <option key = { index + 1 } value = { race.ID } data-name = { race.RACE_DETAIL_NAME }>{ race.RACE_DETAIL_NAME }</option>
                    ))

                    list.unshift(<option key = { 0 } value = { 0 } data-name = { t('pleaseSelect', {ns: 'general'}) }>{ t('pleaseSelect', {ns: 'general'}) }</option>)

                    props.raceDetailListUpdate(list)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }
            getRaceDetailList()
    }, [])

    useEffect(() => {
        setRemainingTimeAndLaps(props.remainingTimeAndLaps)
    }, [props.remainingTimeAndLaps])

    useEffect(() => {
        if (!props.columnOrder) return

        const url = window.location.href
        if (url !== currentUrl) {
            setCurrentUrl(url)
            if(raceSeries === 'SE') {
                setColumns(Object.keys(columnIdAndName).filter((el) => el !== 'ots'))
            } else if(raceSeries === 'SF') {
                setColumns(  Object.keys(columnIdAndName).filter((el) => el !== 'class' && el !== 'drType' && el !== 'drivingTime'))
            }
            setColumnOrder(props.columnOrder)
            return
        }

        const hiddenColumns = columns.filter((el) => !props.columnOrder.includes(el))
        const updatedColumns = [...props.columnOrder]

        hiddenColumns.forEach((el) => {
            const index = columns.indexOf(el)
            updatedColumns.splice(index, 0, el)
        })

        setColumns(updatedColumns)
        setColumnOrder(props.columnOrder)
    }, [props.columnOrder, window.location.href])

    useEffect(() => {
        let intervalId
      
        const decreaseRemainingTimeAndLaps = () => {
            setRemainingTimeAndLaps((prevRemainingTimeAndLaps) => prevRemainingTimeAndLaps - 1)
        }
      
        if (raceSeries === 'SE' && remainingTimeAndLaps !== '' && raceTimeAndLaps) {
            if ((raceTimeAndLaps - totalTimeForRemainingTime) <= 0) {
                setRemainingTimeAndLaps('')
            } else {
                setRemainingTimeAndLaps(raceTimeAndLaps - totalTimeForRemainingTime)
            }
      
            intervalId = setInterval(() => {
                if (raceTimeAndLaps - totalTimeForRemainingTime <= 1) {
                    setRemainingTimeAndLaps('')
                } else {
                    decreaseRemainingTimeAndLaps()
                }
            }, 1000)
        }
      
        return () => {
          clearInterval(intervalId)
        }
    }, [totalTimeForRemainingTime])
    



    const handleRivalHeaders = (e) => {
        const isChecked = e.target.checked
        setDisplayRivalHeaders(isChecked)
    }

    const handleDisplayMap = (e) => {
        const isChecked = e.target.checked
        props.setDisplayMap(isChecked)
    }

    const handleMapLeaders = (e) => {
        const isChecked = e.target.checked
        setDisplayMapLeaders(isChecked)
    }

    const handleDisplayFollowAndRivals = (e) => {
        const isChecked = e.target.checked
        props.setDisplayFollowAndRivals(isChecked)
    } 

    const settingViewHandler = () => {
        props.setModalType('settingView')
        props.setOpenModal(true)
    } 

    const reportViewHandler = () => {
        props.setModalType('reportDownloadView')
        props.setOpenModal(true)
    } 


    const renderViewTable = (column, provided = {}, snapshot = {}) => {
        // SFのみ
        if (column === 'ots' && raceSeries !== 'SF') return null

        // SEのみ
        if ((column === 'class' || column === 'drType') && raceSeries !== 'SE') return null

        let displayName
        let checked
        let onChangeHandler

        if (column === 'all') {
            displayName = 'All'
            checked = columnOrder.length === columns.length
            onChangeHandler = (e) => {
                const isChecked = e.target.checked

                if (isChecked) {
                    props.setColumnOrder(columns)
                } else {
                    props.setColumnOrder([])
                }
            }
        } else if(column === 'rivalHeaders') {
            displayName = 'Follow/Rivals'
            checked = displayRivalHeaders
            onChangeHandler = handleRivalHeaders
        }else if (column === 'map') {
            displayName = 'Map'
            checked = props.displayMap
            onChangeHandler = handleDisplayMap
        } else if (column === 'mapLeaders') {
            displayName = 'Map Leaders'
            checked = mapDisplayLeaders
            onChangeHandler = handleMapLeaders
        } else if(column === 'markedCars') {
            displayName = 'Marked Cars'
            checked = props.displayFollowAndRivals
            onChangeHandler = handleDisplayFollowAndRivals
        } else {
            displayName = columnIdAndName[column]
            checked = columnOrder.includes(column)
            onChangeHandler = (e) => {
                const isChecked = e.target.checked
                if (isChecked) {
                    const newColumnOrder = [...columnOrder, column].sort((a, b) => columns.indexOf(a) - columns.indexOf(b))
                    props.setColumnOrder(newColumnOrder)
                } else {
                    props.setColumnOrder(columnOrder.filter((el) => el !== column))
                }
            }
        }

        if (!displayName) return null

        return (
            <TableRow
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                style = {{
                    height: '30px',
                    backgroundColor: snapshot.isDragging ? '#535353' : '#242424',
                    ...provided.draggableProps?.style,
                }}
            >
                <StyledTableCell style={{ color: '#fff', fontWeight: '400', textAlign: 'left', paddingLeft: '12%' }}>
                    {displayName}
                </StyledTableCell>
                <StyledTableCell>
                    <label className='switch'>
                        <input
                            type='checkbox'
                            className='switch-input'
                            checked={checked}
                            onChange={onChangeHandler}
                        />
                        <span className='slider round'></span>
                    </label>
                </StyledTableCell>
            </TableRow>
        )
    }

    const isMobileView = window.innerWidth < 1024

    return (
        <header className='sp-bottom-Mapheader'>
                <div className='first-header-content'>
                    <div className='header-left-area'>
                        <div className='display-div'>
                            <div className='display-title'>Pit In</div>
                            <div className='display-value double-border'>{ tableData.length !== 0 ? tableData.filter(el => el.isPitIn == true).length : 0 }</div>
                        </div>
                        <div className='display-div'>
                            <div className='display-title'>{ isRace && raceSeries === 'SF' ? 'Laps to go' : 'RemainingTime'}</div>
                            <div className='display-value double-border'>{ remainingTimeAndLaps ? isRace && raceSeries === 'SF' ? remainingTimeAndLaps : <TimeFormatter seconds={remainingTimeAndLaps} decimalPlaces={0} /> :  '--' }</div>
                        </div>
                        <div className='menu-display'>
                            <div className='display-div'>
                                <div className='display-title'>Flag</div>
                                <div className='display-value flag'>
                                    <div className={`flag-color`}></div>
                                    <div className='flag-text'></div>
                                </div>
                            </div>
                        </div>
                        {(!(props.tableScroll || props.isScroll) || isMobileView) && (
                        <WeatherApp weatherData={props.weatherData} precipitationForecast={props.precipitationForecast}/>
                        )}
                    </div>
                    <div className='header-right-area'>
                        <div className='second-header-content'>
                            <div className='latest-update-time'>
                                <span className='time-label'>{t('headerWithMenu.lastUpdate')}</span> <span className='time-value'>{ props.latestReceivedTime }</span>
                            </div>
                            <div className='opciton-menu'>
                                <button className='circle-button' title={t('headerWithMenu.downloadReport')} onClick={ reportViewHandler }><img src= { download } className='download-icon' alt={t('headerWithMenu.downloadReport')}/></button>
                                <button className='circle-button' title={t('general.variousSettings')} onClick={ settingViewHandler }><img src= { setting } alt={t('headerWithMenu.settings')}/></button>
                                <button className='ellipse-button' title={t('general.sessionList')} onClick={() => window.location.href = `/event/${props.eventId}`}>← Back</button>
                            </div>
                        </div>
                    </div>
                </div>
        </header>
    )
}

export default HeaderWithMapMenu