import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import TimeFormatter from "./TimeFormatter";

import { useLiveData } from "../../DataContext";

import { Chart } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js'

import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'
import upImageDark from '../image/scale-bar-arrow.png'
import upImageLight from '../image/scale-bar-arrow_light.png'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
)

const BeforeAfterPitAvg = (props) => {
  const { t } = useTranslation(['strategy', 'general'])

  const [hasPit, setHasPit] = useState(false);
  const [driverLapData, setDriverLapData] = useState();
  const [currentLap, setCurrentLap] = useState(0);
  const [pitArrays, setPitArrays] = useState();
  const [beforePitLaps, setBeforePitLaps] = useState([]);
  const [afterPitLaps, setAfterPitLaps] = useState([]);
  const [specialAfterPitLaps, setSpecialAfterPitLaps] = useState([]);
  const [averageBeforePit, setAverageBeforePit] = useState([]);
  const [averageAfterPit, setAverageAfterPit] = useState([]);
  const [specialAverageAfterPit, setSpecialAverageAfterPit] = useState([]);
  const [amountOfPitStops, setAmountOfPitStops] = useState(0);
  const [pitStopNumber, setPitStopNumber] = useState(0);

  const [yMin, setYMin] = useState(0);
  const [yMax, setYMax] = useState(30);
  const [slideMax, setSlideMax] = useState(100);
  const [slideMin, setSlideMin] = useState(-100);

  const [lineGraphOptions, setLineGraphOptions] = useState({});

  const handleChange = (event, newValue) => {
    setYMin(newValue[0])
    setYMax(newValue[1])
  }

  const carNumber = Number(props.driverInfo.car);
  const colors = [
    '#7289DA', '#FFD700', '#E9967A', '#F08080', '#FFC0CB',
    '#FF69B4', '#FF1493','#FF6347', '#FFFFFF', '#FFFACD',
    '#FFE4B5','#BDB76B', '#EE82EE', '#BA55D3','#D2691E', 
    '#6A5ACD','#7FFF00', '#2E8B57', '#00FFFF', '#4682B4',
    '#D2691E','#008080', '#A0522D', '#32CD32'
  ]
  const { raceDetailId, lapData, isLightMode } = useLiveData();

  useEffect(() => {
    getAllLapData(carNumber, raceDetailId)
      .then((data) => {
        if (!data) return;
        if (data.lapData.length === 0) return;
        const currentDriverLapData = data.lapData.sort(
          (a, b) => a.lapNumber - b.lapNumber
        );
        currentDriverLapData.pop();
        setDriverLapData(currentDriverLapData);
        setCurrentLap(
          currentDriverLapData[currentDriverLapData.length - 1].lapNumber
        );
        setAmountOfPitStops(
          currentDriverLapData.reduce((acc, cur) => {
            return cur.pitStopTime > acc ? cur.pitStopTime : acc;
          }, 0)
        );

        const specialLaps = currentDriverLapData.slice(2, 7)
        setSpecialAfterPitLaps([specialLaps])
        if(specialLaps.length > 0) {
          const specialAverage = calculateAverageLapTime(specialLaps)
          setSpecialAverageAfterPit([specialAverage])
        }
      })
      .catch((error) => {
        alert(t('pleaseContactAdmin', {ns: 'general'}))
      });
  }, [lapData]);

  useEffect(() => {
    if (!driverLapData || driverLapData.length === 0) return;

    const pitData = driverLapData.filter((data) => data.pitStopTime);

    if (pitData.length === 0) return;
    const pitArrays = [];

    driverLapData.forEach((lap) => {
      const pitValue = lap.pitStopTime;
      const pitArrayIndex = pitArrays.findIndex(
        (arr) => arr[0].pitStopTime === pitValue
      );

      if (pitArrayIndex === -1) {
        pitArrays.push([lap]);
      } else {
        pitArrays[pitArrayIndex].push(lap);
      }
    });
    setPitArrays(pitArrays);
    setHasPit(true);
    setPitStopNumber(1);
    processPitData(pitArrays);
  }, [driverLapData]);

  useEffect(() => {
    if (!afterPitLaps || afterPitLaps.length === 0) return
    const allLaps = afterPitLaps.flat().flat()
    const lapTimes = allLaps.map((lap) => lap.lapTime)
    const maxLapTime = Math.max(...lapTimes)
    const minLapTime = Math.min(...lapTimes)
    setYMax(maxLapTime + 5)
    setYMin(minLapTime - 5)
    setSlideMax(maxLapTime + 10)
    setSlideMin(minLapTime - 10)
  }, [afterPitLaps]);

  useEffect(() => {
    setLineGraphOptions({
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              return formatValue(context.parsed.y);
            }
          }
        }
      },
      scales: {
        y: {
          ticks: {
            color: isLightMode ? '#000' : '#fff',
            stepSize: 1,
            callback: function(value) {
              const sign = Math.sign(value);
              const absValue = Math.abs(value); 
              const minutes = Math.floor(absValue / 60);
              const seconds = absValue % 60;
              const formattedMinutes = minutes.toString().padStart(2, '0'); 
              const formattedSeconds = seconds.toString().padStart(2, '0'); 
              return `${sign === -1 ? '-' : ''}${formattedMinutes}:${formattedSeconds}`;
            },
            font: {
              size: 14,
              weight: 'bold',
            },
          },
          grid: {
            display: true, 
            color: 'rgba(255, 255, 255, 0.4)',
          },
          min: Math.trunc(yMin), 
          max: Math.trunc(yMax) 
        },
        x: {
          ticks: {
            color: isLightMode ? '#000' : '#fff',
            font: {
              size: 14,
              weight: 'bold',
            },
          },
          offset: -5,
        },
      }
    })
  }, [yMin, yMax]);

  const formatValue = (value) => {
    const sign = Math.sign(value);
    const absValue = Math.abs(value);
    const minutes = Math.floor(absValue / 60);
    const seconds = (absValue % 60).toFixed(3);
    const paddedSeconds = seconds.padStart(6, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${sign === -1 ? '-' : ''}${formattedMinutes}:${paddedSeconds}`;
  };

  const calculateAverageLapTime = (lapData) => {
    const totalLapTime = lapData.reduce((acc, cur) => acc + cur.lapTime, 0);
    return totalLapTime / lapData.length;
  };

  const handlePitStopNumber = (e) => {
    return () => {
      if (pitStopNumber + e < 1 || pitStopNumber + e > amountOfPitStops) return;
      setPitStopNumber(pitStopNumber + e);
    };
  };

  const processPitData = (beforeAfterPit) => {
    if (!beforeAfterPit || beforeAfterPit.length < 1) {
      return;
    }

    const beforePitData = [];
    const afterPitData = [];
    const averagesBeforePit = [];
    const averagesAfterPit = [];

    const extractLaps = (pitArray, startIndex, numLaps) => {
      const extractedLaps = [];
      for (let i = startIndex; i > startIndex - numLaps; i--) {
        if (pitArray[i] === undefined) {
          break;
        }
        extractedLaps.push(pitArray[i]);
      }
      return extractedLaps;
    };

    const processSinglePitStop = (beforePit, afterPit, numLaps) => {
      beforePitData.push(extractLaps(beforePit, beforePit.length - 1, numLaps));
      afterPitData.push(afterPit.slice(2, 2 + numLaps));
      averagesBeforePit.push(
        calculateAverageLapTime(beforePitData[beforePitData.length - 1])
      );
      averagesAfterPit.push(
        calculateAverageLapTime(afterPitData[afterPitData.length - 1])
      );
    };

    const processMultiplePitStops = () => {
      for (let i = 1; i < beforeAfterPit.length; i++) {
        const beforePit = beforeAfterPit[i - 1];
        const afterPit = beforeAfterPit[i];
        const tempBeforePitData = [];
        const tempAfterPitData = [];
        const tempAveragesBeforePit = [];
        const tempAveragesAfterPit = [];

        const processPitStop = (numLaps) => {
          if (beforePit.length >= numLaps || afterPit.length >= numLaps) {
            tempBeforePitData.push(
              extractLaps(beforePit, beforePit.length - 1, numLaps)
            );
            tempAfterPitData.push(afterPit.slice(2, 2 + numLaps));
            tempAveragesBeforePit.push(
              calculateAverageLapTime(
                tempBeforePitData[tempBeforePitData.length - 1]
              )
            );
            tempAveragesAfterPit.push(
              calculateAverageLapTime(
                tempAfterPitData[tempAfterPitData.length - 1]
              )
            );
          }
        };

        processPitStop(5);

        beforePitData.push(tempBeforePitData);
        afterPitData.push(tempAfterPitData);
        averagesBeforePit.push(tempAveragesBeforePit);
        averagesAfterPit.push(tempAveragesAfterPit);
      }
    };

    if (beforeAfterPit[beforeAfterPit.length - 1][0].pitStopTime === 1) {
      if (beforeAfterPit.length !== 2) {
        return;
      }

      const beforePit = beforeAfterPit[0];
      const afterPit = beforeAfterPit[1];

      if (beforePit.length >= 5 || afterPit.length >= 5) {
        processSinglePitStop(beforePit, afterPit, 5);
      }

      setBeforePitLaps([beforePitData]);
      setAfterPitLaps([afterPitData]);
      setAverageBeforePit([averagesBeforePit]);
      setAverageAfterPit([averagesAfterPit]);
    } else if (beforeAfterPit[beforeAfterPit.length - 1][0].pitStopTime > 1) {
      processMultiplePitStops();
      setBeforePitLaps(beforePitData);
      setAfterPitLaps(afterPitData);
      setAverageBeforePit(averagesBeforePit);
      setAverageAfterPit(averagesAfterPit);
    }
  }

  const upImage = isLightMode ? upImageLight : upImageDark;

  const makeLapTable = (pitData, bigIndex, lapType, specialCases, isBefore) => {
    if (
      pitData[bigIndex + specialCases] &&
      pitData[bigIndex + specialCases][lapType]
    ) {
      return (
        <table
          className={`scroll-content pit-table`}
        >
          <thead>
            <tr>
              {isBefore
                ? pitData[bigIndex + specialCases][lapType]
                    .slice()
                    .reverse()
                    .map((lap, index) => (
                      <th key={index}>Lap {lap.lapNumber}</th>
                    ))
                : pitData[bigIndex + specialCases][lapType]
                    .slice()
                    .map((lap, index) => (
                      <th key={index}>Lap {lap.lapNumber}</th>
                    ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {isBefore
                ? pitData[bigIndex + specialCases][lapType]
                    .slice()
                    .reverse()
                    .map((lap, index) => (
                      <td key={index}>
                        <TimeFormatter
                          seconds={lap.lapTime}
                          decimalPlaces={3}
                        />
                      </td>
                    ))
                : pitData[bigIndex + specialCases][lapType]
                    .slice()
                    .map((lap, index) => (
                      <td key={index}>
                        <TimeFormatter
                          seconds={lap.lapTime}
                          decimalPlaces={3}
                        />
                      </td>
                    ))}
            </tr>
          </tbody>
        </table>
      );
    }
  };

  const makePitLossBarGraph = () => {
    if (!pitArrays || pitArrays.length === 0) return

    const pitLaps = []
    const pitLossData = []

    pitArrays.forEach((pitArray, index) => {
      if (index === 0 && pitArray[0].pitStopTime === 1) {
        pitLaps.push(pitArray[0])
      } else if (index !== 0) {
        pitLaps.push(pitArray[0])
      }
    }) 

    pitLaps.forEach((pitLap) => {
      const beforeInLapData = driverLapData?.find((data) => data.lapNumber === pitLap.lapNumber - 1)
      const inLapData = driverLapData?.find((data) => data.lapNumber === pitLap.lapNumber)
      const outLapData = driverLapData?.find((data) => data.lapNumber === pitLap.lapNumber + 1)
      const afterOutLapData = driverLapData?.find((data) => data.lapNumber === pitLap.lapNumber + 2)

      let pitLossTime
      if (beforeInLapData && inLapData && outLapData && afterOutLapData) {
        pitLossTime = (inLapData.lapTime + outLapData.lapTime) - (beforeInLapData.lapTime + afterOutLapData.lapTime)
      } else {
        pitLossTime = null
      }
      pitLossData.push(pitLossTime !== null ? Number(pitLossTime.toFixed(3)) : null)
    })

    const data = {
      labels: pitLaps.map((lap) => lap.lapNumber),
      datasets: [
        {
          label: 'Pit Loss',
          data: pitLossData,
          backgroundColor: colors.slice(0, pitLaps.length),
          borderColor: colors.slice(0, pitLaps.length),
          borderWidth: 1,
        }
      ]
    }


    const options = {
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              return formatValue(context.parsed.y);
            }
          }            
        }
      },
      scales: {
        y: {
          ticks: {
            color: isLightMode ? '#000' : '#fff',
            stepSize: 1,
            callback: function(value) {
              const sign = Math.sign(value);
              const absValue = Math.abs(value); 
              const minutes = Math.floor(absValue / 60);
              const seconds = absValue % 60;
              const formattedMinutes = minutes.toString().padStart(2, '0'); 
              const formattedSeconds = seconds.toString().padStart(2, '0'); 
              return `${sign === -1 ? '-' : ''}${formattedMinutes}:${formattedSeconds}`;
            },
            font: {
              size: 14,
              weight: 'bold',
            },
          },
          grid: {
            display: true, 
            color: isLightMode ? '#000' : '#fff',
          },
        },
        x: {
          ticks: {
            color: isLightMode ? '#000' : '#fff',
            font: {
              size: 14,
              weight: 'bold',
            },
          },
          offset: -5
        },
      }
    }

    return (
      <div className='pit-bar-graph'>
        <h3>Pit Loss</h3>
        <Chart type='bar' data={data} options={options} />
      </div>
    )
  }

  const makeAfterPitLineGraph = (afterPitLaps) => {
    if (!pitArrays || pitArrays.length === 0) return
    const data = {
      labels: ['1', '2', '3', '4', '5'],
      datasets: afterPitLaps.map((lapTimes, index) => ({
        label: `Pit ${index + 1}`,
        data: lapTimes.map((lap) => lap.lapTime),
        fill: false,
        backgroundColor: colors[index],
        borderColor: colors[index],
        borderWidth: 5,
      })),
    }

    return (
      <div className='after-line-graph'>
        <div className='after-line-inner'>
          <h3>Time After Pit</h3>
          <Chart type='line' data={data} options={lineGraphOptions}/>
        </div>
        <div className='timeComparison-flex-container'>
          <Stack sx={{height: '40%', alignItems: 'center'}} spacing={1}>
            <div className='flex updown'>
              <img className='y-max-up' src={upImage} onClick={onClickMaxUp} alt=''/>
              <img className='y-max-down' src={upImage} onClick={onClickMaxDown} style={{transform: 'rotate(180deg)'}} alt=''/>
            </div>
            <Slider
              sx={{ padding: '0'}}
              max={slideMax}
              min={slideMin}
              orientation='vertical'
              value={[yMin, yMax]}
              onChange={handleChange}
              valueLabelDisplay='auto'
              valueLabelFormat={(value) => {
                return formatValue(value)
              }}
            />
            <div className='flex updown'>
              <img className='y-min-up' src={upImage} onClick={onClickMinUp} alt=''/>
              <img className='y-min-down' src={upImage} onClick={onClickMinDown} style={{transform: 'rotate(180deg)'}} alt=''/>
            </div>
          </Stack>
        </div>
      </div>
    )
  }

  const onClickMaxUp = (e) => {
    setSlideMax(slideMax+10)
  }

  const onClickMaxDown = (e) => {
    setSlideMax(slideMax-10)
    if (yMax > slideMax-10) {
        setYMax(slideMax-10)
    }
  }

  const onClickMinUp = (e) => {
    setSlideMin(slideMin+10)
    if (yMin < slideMin+10) {
        setYMin(slideMin+10)
    }
  }

  const onClickMinDown = (e) => {
    setSlideMin(slideMin-10)
  }

  return (
    <>
      <div className="car-driver-top-info">
        <div className="driver-info">
          <div className="driver-name-info">
            <h4> {props.driverInfo.name}</h4>
          </div>
          <div className="driver-CarNo-info">
            <h4> {props.driverInfo.car}</h4>
          </div>
        </div>

        <div className="currentLap-info">
          <h4> {currentLap + 1}</h4>
          <span>Current Lap</span>
        </div>

        <div className="pit-stop-info">
          <div className="pit-stop-times-tit-bg">
            <h4 className="pit-stop-times-tit">
              {t('beforeAfterPitAvg.pitStopCount')}<span> {amountOfPitStops}</span>
            </h4>
          </div>
          <div className="pit-select-area">
            <div className="pit-stop-times">
              <button
                className="add-btn triangle"
                onClick={handlePitStopNumber(1)}
              ></button>
              <button
                className="remove-btn triangle"
                onClick={handlePitStopNumber(-1)}
              ></button>
            </div>
            <p>
              {pitStopNumber}
              <span>{t('beforeAfterPitAvg.count')}</span>
            </p>

            <div className="pit-action-area">
              <span>
                {t('beforeAfterPitAvg.pitInLap')}:
                {pitStopNumber === 0
                  ? 0
                  : pitArrays[0][0].pitStopTime === 1
                  ? pitStopNumber === 1
                    ? 1
                    : pitArrays[pitStopNumber - 1][0].lapNumber
                  : pitArrays[pitStopNumber][0]?.lapNumber}
              </span>
              <span>
                {t('beforeAfterPitAvg.pitOutLap')}:
                {pitStopNumber === 0
                  ? 0
                  : pitArrays[0][0].pitStopTime === 1
                  ? pitStopNumber === 1
                    ? 2
                    : pitArrays[pitStopNumber - 1][1].lapNumber
                  : pitArrays[pitStopNumber][1]?.lapNumber}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='car-driver-middle-info'>
            {makePitLossBarGraph()}
            {makeAfterPitLineGraph(afterPitLaps.flat())}
      </div>

      {hasPit ? (
        <>
          {/* Special case 1st lap pit */}
          {pitArrays[0][0].pitStopTime === 1 ? (
            <div key={`${pitStopNumber}pitLap`} className='pit-data'>
              <br />
              {pitStopNumber === 1 ?  
                (
                  <div className='fivelap-after-average'>
                    <ul className='separator-line'>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                    {/* Create a new array similar to afterPitLaps with laps 3-7 */}
                    {specialAfterPitLaps[0]?.[0]
                      ? makeLapTable([specialAfterPitLaps], 0, 0, 0, false)
                      : ''
                    }
                    {specialAverageAfterPit[0] ? (
                      <p className = 'pit-faster'>
                        {t('beforeAfterPitAvg.avgTime5LapsBefore')}:{' '}
                        <span className='average-time'>
                          <TimeFormatter
                            seconds={specialAverageAfterPit[0]}
                            decimalPlaces={3}
                          />
                        </span>
                      </p>
                    ) : (
                      ''
                    )}
                    
                  </div>
                )  :
                (
                  <>
                    <div className='fivelap-before-average'>
                      <ul className='separator-line'>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                      {beforePitLaps[pitStopNumber - 2]?.[0]
                        ? makeLapTable(beforePitLaps, pitStopNumber - 2, 0, 0, true)
                        : ''}
                      {averageBeforePit[pitStopNumber - 2] &&
                      averageBeforePit[pitStopNumber - 2][0] ? (
                        <p
                          className={
                            averageBeforePit[pitStopNumber - 2][0] <
                            averageAfterPit[pitStopNumber - 2][0]
                              ? 'pit-faster'
                              : 'pit-slower'
                          }
                        >
                          {t('beforeAfterPitAvg.avgTime5LapsBefore')}:{' '}
                          <br />
                          <span className='average-time'>
                            <TimeFormatter
                              seconds={averageBeforePit[pitStopNumber - 2][0]}
                              decimalPlaces={3}
                            />
                          </span>
                        </p>
                      ) : (
                        <p>{t('beforeAfterPitAvg.dataShownAfter5Laps')}</p>
                      )}
                    </div>
                    <div className='fivelap-after-average'>
                      <ul className='separator-line'>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                      {afterPitLaps[pitStopNumber - 2]?.[0]
                        ? makeLapTable(afterPitLaps, pitStopNumber - 2, 0, 0, false)
                        : ''}
                      {averageAfterPit[pitStopNumber - 2] &&
                      averageAfterPit[pitStopNumber - 2][0] ? (
                        <p
                          className={
                            averageBeforePit[pitStopNumber - 2][0] >
                            averageAfterPit[pitStopNumber - 2][0]
                              ? 'pit-faster'
                              : 'pit-slower'
                          }
                        >
                          {t('beforeAfterPitAvg.avgTime5LapsAfter')}:{' '}
                          <span className='average-time'>
                            <TimeFormatter
                              seconds={averageAfterPit[pitStopNumber - 2][0]}
                              decimalPlaces={3}
                            />
                          </span>
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                )
              }
            </div>
          ) : (
            // Normal case
            <div key={`${pitStopNumber}pitLap`} className="pit-data">
              <br />

              {/* 5 Lap Average before pit */}
              <div className="fivelap-before-average">
                <ul className="separator-line">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
                {beforePitLaps[pitStopNumber - 1]?.[0]
                  ? makeLapTable(beforePitLaps, pitStopNumber - 1, 0, 0, true)
                  : ""}
                {averageBeforePit[pitStopNumber - 1] &&
                averageBeforePit[pitStopNumber - 1][0] ? (
                  <p
                    className={
                      averageBeforePit[pitStopNumber - 1][0] <
                      averageAfterPit[pitStopNumber - 1][0]
                        ? "pit-faster"
                        : "pit-slower"
                    }
                  >
                    {t('beforeAfterPitAvg.avgTime5LapsBefore')}:{' '}
                    <br className='sp-none' />
                    <span className="average-time">
                      <TimeFormatter
                        seconds={averageBeforePit[pitStopNumber - 1][0]}
                        decimalPlaces={3}
                      />
                    </span>
                  </p>
                ) : (
                  <p>{t('beforeAfterPitAvg.dataShownAfter5Laps')}</p>
                )}
              </div>

              {/* 5 Lap Average after pit */}
              <div className="fivelap-after-average">
                <ul className="separator-line">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
                {afterPitLaps[pitStopNumber - 1]?.[0]
                  ? makeLapTable(afterPitLaps, pitStopNumber - 1, 0, 0, false)
                  : ""}
                {averageAfterPit[pitStopNumber - 1] &&
                averageAfterPit[pitStopNumber - 1][0] ? (
                  <p
                    className={
                      averageBeforePit[pitStopNumber - 1][0] >
                      averageAfterPit[pitStopNumber - 1][0]
                        ? "pit-faster"
                        : "pit-slower"
                    }
                  >
                    {t('beforeAfterPitAvg.avgTime5LapsAfter')}:{' '}
                    <span className="average-time">
                      <TimeFormatter
                        seconds={averageAfterPit[pitStopNumber - 1][0]}
                        decimalPlaces={3}
                      />
                    </span>
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <p>{t('beforeAfterPitAvg.notPittedYet')}</p>
      )}
    </>
  );
};

export default BeforeAfterPitAvg;

function getAllLapData(carNumber, raceDetailId) {
  return new Promise((resolve, reject) => {
    if (!raceDetailId) return resolve([]);

    fetch(
      `/api/race/getAllRaceDataByCarNumberRaceDetailId/${carNumber}/${raceDetailId}/`
    )
      .then((res) => res.json())
      .then((json) => resolve(json.data))
      .catch((error) => {
        reject(error);
      });
  });
}
