import React, { useState, useEffect } from 'react'

import TimeSeriesModalUpload from './TimeSeriesModalUpload'
import TimeSeriesModalCorrelation from './TimeSeriesModalCorrelation'
import TimeSeriesChannels from './TimeSeriesChannels'

import { useTranslation } from 'react-i18next'

const TimeSeriesFile = (props) => {
    const { t } = useTranslation(['core'])

    const [ openModal, setOpenModal ] = useState(false)
    const [ openCorrelationModal, setOpenCorrelationModal ] = useState(false)

    const [ displayChannelTab, setDisplayChannelTab ] = useState(false)
    const [ selectedTimeSeries, setSelectedTimeSeries ] = useState()

    const [ addDataInfo, setAddDataInfo ] = useState()

    const vehicleId = props.vehicleId
    const trackId = props.trackId

    const timeSeriesList = props.timeSeriesList
    const setTimeSeriesList = props.setTimeSeriesList

    const selectedGraph = props.selectedGraph
    const setSelectedGraph = props.setSelectedGraph

    const setGraphData = props.setGraphData

    useEffect(() => {
        if (!addDataInfo) return
        setTimeSeriesList((prevData) => [...prevData, addDataInfo])
        setAddDataInfo()
    }, [addDataInfo])

    const addDataHandler = () => {
        setOpenModal(true)
    }

    const handleCloseModal = (addedDataInfo) => {
        setAddDataInfo(addedDataInfo)
        setOpenModal(false)
    }

    const FileListDisplayHandler = () => {
        setDisplayChannelTab(false)
    }

    const channelDisplayHandler = () => {
        setDisplayChannelTab(true)
    }

    const autoCorrelationHandler = () => {
        setOpenCorrelationModal(true)
    }

    const handleCloseCorrelationModal = () => {
        setOpenCorrelationModal(false)
        console.log('close', vehicleId, trackId)
    }

    const colorHandler = (e) => {
        const targetId = selectedTimeSeries.id
        const newColor = e.target.value

        const targetData = timeSeriesList.find(data => data.id === Number(targetId))
        targetData.color = newColor

        setTimeSeriesList(prevData => 
            prevData.map(data => 
                data.id === targetId ? targetData : data
            )
        )
    }

    const chartDisplayHandler = (e) => {
        const targetId = e.target.dataset.id
        const visible = e.target.checked

        const targetData = timeSeriesList.find(data => data.id === Number(targetId))
        targetData.visible = visible

        setTimeSeriesList(prevData => 
            prevData.map(data => 
                data.id === targetId ? targetData : data
            )
        )
    }

    return (
        <>
            <div className='time-series-file-container'>
                <div className='button-container'>
                <div className='time-series-file-button' onClick={ addDataHandler }>{t('timeSeriesFile.addData')}</div>
                {/* <div className='time-series-file-button' onClick={ channelDisplayHandler }>{ displayChannelTab ? t('timeSeriesFile.fileList') : t('timeSeriesFile.displayChannel') }</div> */}
                <div className='time-series-file-button' onClick={ autoCorrelationHandler }>Auto-Correlation</div>
                </div>
                { displayChannelTab ? (
                        <>
                        <div className='time-series-file-tab-continer'>
                            <div className={`time-series-file-tab ${displayChannelTab ? '' : 'active-tab'}`} onClick={ FileListDisplayHandler }>{  t('timeSeriesFile.fileList') }</div>
                            <div className={`time-series-file-tab ${displayChannelTab ? 'active-tab' : ''}`} onClick={ channelDisplayHandler }>{  t('timeSeriesFile.displayChannel')}</div>
                        </div>
                            <TimeSeriesChannels
                                selectedTimeSeries={ selectedTimeSeries}
                                timeSeriesList={ timeSeriesList }
                                selectedGraph={ selectedGraph }
                                setSelectedGraph={ setSelectedGraph }
                                setGraphData={ setGraphData }
                            />
                        </>
                    ) : 
                        <>
                        <div className='time-series-file-tab-continer'>
                            <div className={`time-series-file-tab ${displayChannelTab ? '' : 'active-tab'}`} onClick={ FileListDisplayHandler }>{  t('timeSeriesFile.fileList') }</div>
                            <div className={`time-series-file-tab ${displayChannelTab ? 'active-tab' : ''}`} onClick={ channelDisplayHandler }>{  t('timeSeriesFile.displayChannel')}</div>
                        </div>
                            <div className='data-file-container'>
                                <div className='data-file-channel-header-container'>
                                    <div>Data List</div>
                                </div>
                                { timeSeriesList.map((data, i) => {
                                    return ( data.filePath && (
                                        <div className='data-file-content' key={ data.id }>
                                            <div className={`data-file-checkbox-div ${selectedTimeSeries && data.id === selectedTimeSeries.id ? 'selected-time-series' : '' }`} /*style={{ color: data.color }}*/  onClick={ () => setSelectedTimeSeries(data) }>
                                                <input type='checkbox' className='data-file-checkbox' id={ `cb-${data.id}` } data-id={ data.id } checked={ data.visible } onChange={ chartDisplayHandler } />
                                                <label className='data-file-checkbox-label'>{ data.fileName }</label>
                                            </div>
                                        </div>
                                    ))
                                })}
                            </div>

                            <div className='file-info-container'>
                                { selectedTimeSeries && (
                                    <>
                                        {/* <div className='data-info'>
                                            <span className='data-info-title'>Data Location</span>
                                            <span className='data-info-content'>{ timeSeriesList.dataLocation }</span>
                                        </div> */}
                                        <div className='data-info'>
                                            <span className='data-info-title'>Date</span>
                                            <span className='data-info-content'>{ selectedTimeSeries.date }</span>
                                        </div>
                                        <div className='data-info'>
                                            <span className='data-info-title'>Course</span>
                                            <span className='data-info-content'>{ selectedTimeSeries.course }</span>
                                        </div>
                                        <div className='data-info'>
                                            <span className='data-info-title'>Driver</span>
                                            <span className='data-info-content'>{ selectedTimeSeries.driver }</span>
                                        </div>
                                        <div className='data-info'>
                                            <span className='data-info-title'>Fastest</span>
                                            <span className='data-info-content'>{ selectedTimeSeries.fastest }</span>
                                        </div>
                                        <div className='data-info'>
                                            <span className='data-info-title'>Color</span>
                                            <input className='data-info-content' type='color' value={ selectedTimeSeries.color } onChange={ colorHandler } list='presetColors'></input>
                                            <datalist id='presetColors'>
                                                <option>#FABED4</option>
                                                <option>#FFFAC8</option>
                                                <option>#AAFFC3</option>
                                                <option>#42D4F4</option>
                                                <option>#CECECE</option>

                                                <option>#E6194B</option>
                                                <option>#FFE119</option>
                                                <option>#BFEF45</option>
                                                <option>#7FB8D7</option>
                                                <option>#B3B3B3</option>

                                                <option>#E619B9</option>
                                                <option>#F58231</option>
                                                <option>#808000</option>
                                                <option>#4363D8</option>
                                                <option>#807B7B</option>

                                                <option>#CA32F0</option>
                                                <option>#9E1414</option>
                                                <option>#318000</option>
                                                <option>#585882</option>
                                                <option>#434040</option>

                                                <option>#841EB4</option>
                                                <option>#5A0A0A</option>
                                                <option>#5A0A0A</option>
                                                <option>#000075</option>
                                                <option>#000000</option>
                                            </datalist>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    }
            </div>
            
            <TimeSeriesModalUpload open={ openModal } onClose={ handleCloseModal } setOpenModal={ setOpenModal } timeSeriesList={ timeSeriesList } />
            <TimeSeriesModalCorrelation open={ openCorrelationModal } onClose={ handleCloseCorrelationModal } setOpenModal={ setOpenCorrelationModal } timeSeriesList={ timeSeriesList } />
        </>
        
    )
}

export default TimeSeriesFile