import * as ort from 'onnxruntime-web'
import * as dfd from 'danfojs'

import transRange from './transRange'
import transArray from './transArray'
import GetFile from './getFile'
// import uploadJsonToServer from './uploadJsonToServer'
import groupByModel from './groupByModel'

const addCompareTimes = async (modelId, VEHICLE_ID, TRACK_ID, SECTOR_NUM, df_compareSetup, Obj_compareSetup, onnxModels) => {
    let arrayCompare = {'id': transRange(df_compareSetup.columns.length)}
    let df_compareTime = new dfd.DataFrame(arrayCompare)

    // if (VEHICLE_ID === 'SuperFormula' && TRACK_ID === 'Suzuka' || VEHICLE_ID === 'Gr86' && TRACK_ID === 'Burirum') {
        await areneCompareTime()
        // await localCompareTime()
    // } else {
    //     await localCompareTime()
    // }
    console.log('modelId', modelId)

    df_compareTime = df_compareTime.sub(df_compareTime.iloc({rows: [0]}), { axis: 1 })
    df_compareTime.addColumn('LapTime', df_compareTime.iloc({columns: ["1:"]}).sum().values, {inplace: true})
    let df_ans = df_compareTime.iloc({rows:[0]})
    for(let i=1; i<df_compareTime.index.length; i++){
        const df_tmp = df_compareTime.iloc({rows:[i]}).sub(df_compareTime.iloc({rows: [i-1]}), { axis: 1 })
        df_ans = dfd.concat({ dfList: [df_ans, df_tmp], axis: 0 })
    }

    return df_compareTime

    function localCompareTime() {
        return new Promise (async (resolve, reject) => {
            for (let j = 0; j < SECTOR_NUM; j++) {
                const modelname = `Sector0${j + 1}Duration of Sector`
                const onnxName = `${modelname}.onnx`
    
                const arrayBuffer = await GetFile(`/data/ONNX/ONNX_${VEHICLE_ID}_${TRACK_ID}/${onnxName}`, 'buffer')
                let myOnnxSession = await ort.InferenceSession.create(arrayBuffer)
                
                let ansCompare = await myOnnxSession.run(Obj_compareSetup)
                ansCompare = transArray(ansCompare.variable.data)
    
                df_compareTime.addColumn(modelname, ansCompare, {inplace:true})
            }

            resolve()
        })
    }

    async function areneCompareTime() {
        return new Promise (async (resolve, reject) => {
            const tempFolderName = Date.now()
            const counter = 100
    
            const xLength = df_compareSetup.index.length
            const labelBase = df_compareSetup.columns.length
    
            const compareInputs = []
    
            for (let i = 0; i < xLength; i++) {
                const xName = `x${i + 1}`
                const compareInput = {
                    name: xName,
                    shape: [labelBase, 1],
                    datatype: Obj_compareSetup[xName].type === 'string' ? 'BYTES' : 'FP32',
                    data: Object.values(Obj_compareSetup[xName].data).map(v => { return [v] })
                }
    
                compareInputs.push(compareInput)
            }
    
            // await uploadJsonToServer({inputs: compareInputs}, tempFolderName, counter)
            const responseJson = await getCompareResult({inputs: compareInputs})
            await processResult(responseJson.data)
            resolve()
        })

        function getCompareResult(compareInputs) {
            return new Promise (async (resolve, reject) => {
                const POST_OPTIONS = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                       inputs: compareInputs,
                       modelId
                    })
                }
                fetch(`/core/api/optimizeBase/`, POST_OPTIONS)
                    .then(response => response.json())
                    .then(responseJson => {
                        resolve(responseJson)
                    })
                    .catch(error => { console.error('Error:', error) })
            })
        }

        function processResult(responseJson) {
            return new Promise (async (resolve, reject) => {
                const outputs = responseJson.outputs[0]
                const data = outputs.data
                const shape = outputs.shape[0]

                const onnxObjectResult = groupByModel(shape, data, onnxModels)
                const sectorResult = onnxObjectResult.filter(v => v.name.includes('Sector'))

                sectorResult.forEach(sector => {
                    const modelname = sector.name
                    const ansCompare = sector.value
                    df_compareTime.addColumn(modelname, ansCompare, {inplace:true})
                })

                resolve()
            })
        }
    }
}

export default addCompareTimes