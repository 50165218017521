import { useEffect, useState } from 'react'
import { TableContainer, Paper, Table, TableRow, TableBody } from '@mui/material'
import { useTranslation } from 'react-i18next'

import ClassColorDisplay from './CarClass'
import { StyledTableCell } from './TableStyle'

const ClassSelectElement = ({ selectedClasses: initialSelectedClasses, carClassDatas, handleSelectedClassesChange }) => {
    const { t } = useTranslation(['strategy'])
    const [uniqueClassesArray, setUniqueClassesArray] = useState([])
    const [selectedClasses, setSelectedClasses] = useState(initialSelectedClasses || [])

    const handleSelectAll = () => {
        setSelectedClasses(selectedClasses.length === uniqueClassesArray.length ? [] : [...uniqueClassesArray])
    }

    const handleSelectClass = (classItem) => {
        setSelectedClasses(selectedClasses.includes(classItem)
        ? selectedClasses.filter(selectedClass => selectedClass !== classItem)
        : [...selectedClasses, classItem])
    }

    useEffect(() => {
        const uniqueClasses = [...new Set(carClassDatas.map(carClassData => carClassData.CLASS))].sort()
        setUniqueClassesArray(uniqueClasses)
        if (!initialSelectedClasses) {
            setSelectedClasses(uniqueClasses)
        }
    }, [carClassDatas, initialSelectedClasses])

    useEffect(() => {
        handleSelectedClassesChange(selectedClasses)
    }, [selectedClasses, handleSelectedClassesChange])

    

    return (
        <>
        <TableContainer component={Paper} size='small' sx={{ height: 300, width: 300, backgroundColor: '#242424' }}>
            <Table sx={{ minWidth: 250 }} aria-label='customized table'>
                <TableBody>
                    <TableRow style={{ color: '#fff', textAlign: 'center', width: '100%' }}>{t('general.classSelection')}</TableRow>
                    <TableRow key='class-all-option' style={{ backgroundColor: '#242424', height: '30px' }}>
                        <StyledTableCell className='allclass-display-header'>All</StyledTableCell>
                        <StyledTableCell>
                        <label className='switch'>
                            <input
                                type='checkbox'
                                className='switch-input'
                                value='all'
                                checked={selectedClasses.length === uniqueClassesArray.length}
                                onChange={handleSelectAll}
                            />
                            <span className='slider round'></span>
                        </label>
                        </StyledTableCell>
                    </TableRow>
                    {uniqueClassesArray.map((classItem, index) => (
                        <TableRow key={index} style={{ backgroundColor: '#242424', height: '30px' }}>
                            <StyledTableCell style={{ color: '#fff', fontWeight: '400', textAlign: 'left', paddingLeft: '12%' }}>
                                {ClassColorDisplay(classItem, 'header')}
                            </StyledTableCell>
                            <StyledTableCell>
                                <label className='switch'>
                                    <input
                                        type='checkbox'
                                        className='switch-input'
                                        value={classItem}
                                        checked={selectedClasses.includes(classItem)}
                                        onChange={() => handleSelectClass(classItem)}
                                    />
                                    <span className='slider round'></span>
                                </label>
                            </StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </>
)}

export default ClassSelectElement