import { useState, useEffect } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';

import ClassColorDisplay from './CarClass';
import { StyledHeaderTableCell, StyledTableCell } from './TableStyle';
import TimeFormatter from './TimeFormatter';
import { colorScale } from '../services/Util';
import notFollowImage from '../image/follow-analysis.png'
import followImage from '../image/follow.png';

import { useTranslation } from 'react-i18next';

const BestLapComparison = (props) => {
  const { t } = useTranslation(['strategy', 'general']);

  const raceDetailId = props.raceDetailId;

  const [bestLapTimeWithSectorTimes, setBestLapTimeWithSectorTimes] = useState(
    []
  );
  const [carClassDatas, setCarClassDatas] = useState([null]);
  const [carNumberColor, setCarNumberColor] = useState({});
  const [currentCarNumber, setCurrentCarNumber] = useState(null);
  const [heatMapsObj, setHeatMapObj] = useState({});
  const [sectorLength, setSectorLength] = useState(0);
  const [tableDatas, setTableDatas] = useState([]);

  const TableheadStyle = {
    padding: '5px',
    fontWeight: 'bold',
    color: '#fff',
  };

  const TablecellStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '5px',
    whiteSpace: 'nowrap',
    width: 'auto',
    color: '#000 !important',
  };

  const TableCarNoStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '5px',
    whiteSpace: 'nowrap',
    width: 'auto',
  };

    const isAllClassNull = (carClassDatas) => {
        if (carClassDatas[0] !== null)
            return carClassDatas.every(carClassData => carClassData.CLASS === null)
    }

  const findCarClassByCarNumber = (carNumber, carClassDatas) => {
    if (carClassDatas[0]) {
      const findClassObject = carClassDatas.find(
        (carClassData) => carClassData.CAR_NUMBER === carNumber
      );
      const findClassString = findClassObject.CLASS;
      return findClassString;
    }
  };

  const isSelectedClass = (carNumber, carClassDatas) => {
    return (
      (props.selectedClasses !== null &&
        props.selectedClasses.includes(
          findCarClassByCarNumber(carNumber, carClassDatas)
        )) ||
      props.selectedClasses === null
    );
  };

  useEffect(() => {
    const getCarLapData = () => {
      fetch(`/api/race/bestLapTimeWithSectorTimes/${raceDetailId}`)
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 200) {
            let data = json.data;
            setBestLapTimeWithSectorTimes(data);
            setTableDatas(data);
          }
        })
        .catch((error) => {
          alert(t('pleaseContactAdmin', {ns: 'general'}));
        });
    };

    const getCircuit = () => {
      fetch(`/api/circuit/getCircuitInfo/${raceDetailId}`)
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 200) {
            let data = json.data;
            setSectorLength(data[0].SECTOR_LENGTH);
          }
        })
        .catch((error) => {
          alert(t('pleaseContactAdmin', {ns: 'general'}));
        });
    };

    const getCarNumberColor = () => {
      fetch(`/api/car/getCarNumberColor/${raceDetailId}`)
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 200) {
            setCarNumberColor(json.data);
          }
        })
        .catch((error) => {
          alert(t('pleaseContactAdmin', {ns: 'general'}));
        });
    };

    const getCarClasses = () => {
      fetch(`/api/car/getCarClasses/${raceDetailId}`)
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 200) {
            setCarClassDatas(json.data);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(t('pleaseContactAdmin', {ns: 'general'}));
        });
    };

    if (raceDetailId) {
      getCarLapData();
      getCircuit();
      getCarNumberColor();
      getCarClasses();
    }
  }, [raceDetailId]);

  useEffect(() => {
    if (currentCarNumber) {
      const currentCarData = bestLapTimeWithSectorTimes.find(
        (tableData) => tableData.CAR_NUMBER === currentCarNumber
      );
      const diffData = bestLapTimeWithSectorTimes.map((tableData) => {
        const diff = {
          CAR_NUMBER: tableData.CAR_NUMBER,
          DRIVER_NAME: tableData.DRIVER_NAME,
          ABBREVIATION: tableData.ABBREVIATION,
          LAP_NUMBER: tableData.LAP_NUMBER,
          LAP_TIME: tableData.LAP_TIME - currentCarData.LAP_TIME,
          SEC1_TIME: tableData.SEC1_TIME - currentCarData.SEC1_TIME,
          SEC2_TIME: tableData.SEC2_TIME - currentCarData.SEC2_TIME,
          SEC3_TIME: tableData.SEC3_TIME - currentCarData.SEC3_TIME,
          SEC4_TIME: tableData.SEC4_TIME
            ? tableData.SEC4_TIME - currentCarData.SEC4_TIME
            : null,
        };
        return diff;
      });
      setTableDatas(diffData);
    } else {
      setTableDatas(bestLapTimeWithSectorTimes);
    }
  }, [currentCarNumber]);

  useEffect(() => {
    const buildHeatMapsObj = (median) => {
      return {
        LAP_TIME: colorScale(
          tableDatas.map((tableData) => tableData.LAP_TIME),
          0xff523c, 
          0x3a4eff,
          'normal',
          median
        ),
        SEC1_TIME: colorScale(
          tableDatas.map((tableData) => tableData.SEC1_TIME),
          0xff523c, 
          0x3a4eff,
          'normal',
          median
        ),
        SEC2_TIME: colorScale(
          tableDatas.map((tableData) => tableData.SEC2_TIME),
          0xff523c, 
          0x3a4eff,
          'normal',
          median
        ),
        SEC3_TIME: colorScale(
          tableDatas.map((tableData) => tableData.SEC3_TIME),
          0xff523c, 
          0x3a4eff,
          'normal',
          median
        ),
        SEC4_TIME: colorScale(
          tableDatas.map((tableData) => tableData.SEC4_TIME),
          0xff523c, 
          0x3a4eff,
          'normal',
          median
        ),
      };
    };

    if (currentCarNumber) {
      setHeatMapObj(buildHeatMapsObj(0));
    } else {
      setHeatMapObj(buildHeatMapsObj(null));
    }
  }, [tableDatas]);

  return carNumberColor ? (
    <TableContainer className='live-table-container' component={Paper} sx={{ height: '100%', width: '1200px', margin: '20px 0 0 0' }}>
        <Table className='live-table' sx={{ color: 'black' }}>
          <TableHead sx={{ height: '50px' }}>
            <TableRow sx={{ backgroundColor: '#bb1414' }}>
              <StyledHeaderTableCell className='follow' sx={{ ...TableheadStyle }}>Follow</StyledHeaderTableCell>
              <StyledHeaderTableCell sx={{ ...TableheadStyle }}>Car No</StyledHeaderTableCell>
              {isAllClassNull(carClassDatas) ? null : <StyledHeaderTableCell sx={{...TableheadStyle}}>Class</StyledHeaderTableCell>}
              <StyledHeaderTableCell sx={{ ...TableheadStyle }}>Name</StyledHeaderTableCell>
              <StyledHeaderTableCell sx={{ ...TableheadStyle }}>LapNo</StyledHeaderTableCell>
              <StyledHeaderTableCell sx={{ ...TableheadStyle }}>Lap</StyledHeaderTableCell>
              <StyledHeaderTableCell sx={{ ...TableheadStyle }}>Sec1</StyledHeaderTableCell>
              <StyledHeaderTableCell sx={{ ...TableheadStyle }}>Sec2</StyledHeaderTableCell>
              <StyledHeaderTableCell sx={{ ...TableheadStyle }}>Sec3</StyledHeaderTableCell>
              {sectorLength === 4 && <StyledHeaderTableCell sx={{...TableheadStyle}}>Sec4</StyledHeaderTableCell>}
            </TableRow>
          </TableHead>
        <TableBody>
        {tableDatas.map((tableData, index) => 
          isSelectedClass(tableData.CAR_NUMBER, carClassDatas) && carNumberColor[tableData.CAR_NUMBER] ? (
            <TableRow key={`${index}row`} sx={{ border: '#e0e0e0' }}>
              <StyledTableCell sx={{...TableheadStyle, border: currentCarNumber === tableData.CAR_NUMBER ? '2px solid red' : 'solid 1px #fff' }}>
                {currentCarNumber === tableData.CAR_NUMBER ? (
                  <img src={followImage} alt='Follow中'
                    onClick={() => setCurrentCarNumber(null)}
                  />
                ) : (
                  <img
                    src={notFollowImage}
                    alt='notFollow'
                    onClick={() => setCurrentCarNumber(tableData.CAR_NUMBER)}
                  />
                )}
              </StyledTableCell>
              <div className={currentCarNumber === tableData.CAR_NUMBER ? 'table-carno-boxs active' : 'table-carno-boxs'}>
                <StyledTableCell className='car-no' sx={{ ...TableCarNoStyle,  backgroundColor: carNumberColor[tableData.CAR_NUMBER] ? carNumberColor[tableData.CAR_NUMBER]['COLOR_BG'] : '', color: carNumberColor[tableData.CAR_NUMBER] ? `${carNumberColor[tableData.CAR_NUMBER]['COLOR_TEXT']} !important` :  `#242424 !important` }}>{tableData.CAR_NUMBER}</StyledTableCell> 
              </div>
              {isAllClassNull(carClassDatas) ? null : (
                <StyledTableCell sx={{...TableheadStyle, border: currentCarNumber === tableData.CAR_NUMBER ? '2px solid red' : 'solid 1px #fff' }}>
                  {' '}
                  {ClassColorDisplay(
                    findCarClassByCarNumber(tableData.CAR_NUMBER, carClassDatas),
                    'table'
                  )}{' '}
                </StyledTableCell>
              )}
              <StyledTableCell className='name' sx={{...TableheadStyle, border: currentCarNumber === tableData.CAR_NUMBER ? '2px solid red' : 'solid 1px #fff' }}>
                {tableData.ABBREVIATION ? tableData.ABBREVIATION : tableData.DRIVER_NAME}
              </StyledTableCell>
              <StyledTableCell className='lap-no' sx={{...TableheadStyle, border: currentCarNumber === tableData.CAR_NUMBER ? '2px solid red' : 'none' }}>
                {tableData.LAP_NUMBER}
              </StyledTableCell>
              <StyledTableCell
                className='lap-times'
                sx={{
                  ...TablecellStyle,
                  backgroundColor: `#${heatMapsObj['LAP_TIME'][index]
                    ?.toString(16)
                    .padStart(6, '0')}`,
                  border: currentCarNumber === tableData.CAR_NUMBER ? '2px solid red' : 'solid 1px #fff'
                }}
              >
              <TimeFormatter seconds={tableData.LAP_TIME} />
            </StyledTableCell>
            <StyledTableCell
              className='sec-data'
              sx={{
                ...TablecellStyle,
                backgroundColor: `#${heatMapsObj['SEC1_TIME'][index]
                  ?.toString(16)
                  .padStart(6, '0')}`,
                  border: currentCarNumber === tableData.CAR_NUMBER ? '2px solid red' : 'solid 1px #fff'
              }}
            >
              <TimeFormatter seconds={tableData.SEC1_TIME} />
            </StyledTableCell>
            <StyledTableCell
              className='sec-data'
              sx={{
                ...TablecellStyle,
                backgroundColor: `#${heatMapsObj['SEC2_TIME'][index]
                  ?.toString(16)
                  .padStart(6, '0')}`,
                  border: currentCarNumber === tableData.CAR_NUMBER ? '2px solid red' : 'solid 1px #fff'
              }}
            >
              <TimeFormatter seconds={tableData.SEC2_TIME} />
            </StyledTableCell>
            <StyledTableCell
              className='sec-data'
              sx={{
                ...TablecellStyle,
                backgroundColor: `#${heatMapsObj['SEC3_TIME'][index]
                  ?.toString(16)
                  .padStart(6, '0')}`,
                  border: currentCarNumber === tableData.CAR_NUMBER ? '2px solid red' : 'solid 1px #fff'
              }}
            >
              <TimeFormatter seconds={tableData.SEC3_TIME} />
            </StyledTableCell>
            {sectorLength === 4 && (
              <StyledTableCell
                className='sec-data'
                sx={{
                  ...TablecellStyle,
                  backgroundColor: `#${heatMapsObj['SEC4_TIME'][index]
                    ?.toString(16)
                    .padStart(6, '0')}`,
                    border: currentCarNumber === tableData.CAR_NUMBER ? '2px solid red' : 'solid 1px #fff'
                }}
              >
                <TimeFormatter seconds={tableData.SEC4_TIME} />
              </StyledTableCell>
              )}
            </TableRow>
          ) : null
        )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default BestLapComparison;
