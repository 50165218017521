import React from 'react'

const SelectButtons = (props) => {     
    return(
        <>
            <div style={{ fontWeight: 'bold', fontSize: '20px', paddingBottom: '8px' }}>{props.titleText}</div>
            <div style={{
                        display: 'flex',
                        fontSize: '20px',
                        flexDirection: 'row',
                        alignItems: 'center'
                }}>
                <div>
                    {props.buttons.map((button, index) => (
                        <button 
                            key={index}
                            onClick={() => {
                                props.handleButtonSelect(button.id)
                            }}
                            style={{
                                backgroundColor: props.selectedButtonId === button.id ? 'blue' : '#696969',
                                color: 'white',
                                fontSize: '20px',
                                margin: '0 5px 1px 0',
                                width: '200px',
                                height: '50px'
                            }}
                        >
                            {button.label}
                        </button>
                    ))}
                </div>    
            </div>
        </>
    )
}

export default SelectButtons