import React, { useEffect, useState } from 'react'

import Header from '../components/Header'
import MainButtonDropdown from '../components/MainButtonDropdown'
import Optimization from '../components/Optimization'
import TimeSeries from '../components/TimeSeries'
import PwBop from '../components/PwBop'

import ReportModal from '../components/ReportModal'

import '../css/Optimize.css'

import { useTranslation } from 'react-i18next'

const Optimize = () => {
    const { t } = useTranslation(['core'])

    const [ selectedType, setSelectedType ] = useState('')
    const [ vehicle, setVehicle ] = useState('')
    const [ track, setTrack ] = useState('')
    const [ raceSeries, setRaceSeries ] = useState('')
    const [ raceYear, setRaceYear ] = useState('')

    const [ vehicleData, setVehicleData ] = useState()
    const [ trackData, setTrackData ] = useState()

    const [ openModal, setOpenModal ] = useState(false)


    const [ setupList, setSetupList] = useState()
    const [ selectedSetupId, setSelectedSetupId ] = useState()
    const [ selectedSetup, setSelectedSetup ] = useState()
    const [ vehicleItems, setVehicleItems ] = useState()

    useEffect(() => {
        if (!vehicleData) return

        const vehicleId = vehicleData.id

        const fetchSetupList = async () => {
            try {
                const response = await fetch(`/api/v1/setups?vehicleId=${vehicleId}`)
                const data = await response.json()
                setSetupList(data.setups)
            } catch (error) {
                console.error('Error fetching setup list:', error)
            }
        }

        const fetchVehicleItems = async () => {
            try {
                const response = await fetch(`/api/v1/vehicleItemMappings/${vehicleId}`)
                const data = await response.json()
                setVehicleItems(data.vehicleItemMappings)
            } catch (error) {
                console.error('Error fetching vehicle items:', error)
            }
        }
    
        fetchSetupList()
        fetchVehicleItems()
    }, [vehicleData])

    const reportHandler = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const selectSetupHandler = (e) => {
        const setupId = Number(e.target.value)
        const setup = setupList.find(setup => setup.id === setupId)
        setSelectedSetupId(setupId)
        setSelectedSetup(setup)
    }

    return (
        <>
            <div className='display-flex core-header-container'>
                    <Header/>
                <div className='main-buttons'>
                    <MainButtonDropdown id='optimize' title={t('optimize.optimization')} setSelectedType={ setSelectedType } setVehicle={ setVehicle } setTrack={ setTrack } setVehicleData={ setVehicleData } setTrackData={ setTrackData } />
                    <MainButtonDropdown id='time' title={t('optimize.timeSeriesComparison')}  setSelectedType={ setSelectedType } setVehicle={ setVehicle } setTrack={ setTrack } setVehicleData={ setVehicleData } setTrackData={ setTrackData } />
                    <MainButtonDropdown id='pwBop' title={t('optimize.pwBopInformation')}  setSelectedType={ setSelectedType } setRaceSeries={ setRaceSeries } setRaceYear={ setRaceYear } />
                    <div className='main-button dropdown-button' onClick={ reportHandler }>{t('optimize.reportCreation')} </div>
                    <MainButtonDropdown id='option' title={t('optimize.option')}  setSelectedType={ setSelectedType } />
                    <div className={`loading-container`}>
                        <progress id='myProgress' value='' max='100'></progress>
                    </div>
                </div>
            </div>

            { selectedType === 'optimize' && vehicle && track && (
                <select
                    value={ selectedSetupId }
                    onChange={ selectSetupHandler }
                >
                    <option value=''>Select a setup</option>
                    { setupList && setupList.map(setup => {
                        return (
                            <option key={ setup.id } value={ setup.id }>{ setup.name }</option>
                        )
                    })}
                </select>
            )}

            <section className='main-work-container'>
                { selectedType === 'optimize' && vehicle && track && selectedSetup && (
                    <Optimization
                        vehicleId={ vehicle }
                        trackId={ track }
                        vehicleData={ vehicleData }
                        trackData={ trackData }
                        setup={ selectedSetup }
                        vehicleItems={ vehicleItems }
                    />
                )}
                { selectedType === 'time' && vehicle && track && (
                    <>
                        <TimeSeries vehicleId={ vehicle } trackId={ track} />
                    </>
                )}
                { selectedType === 'pwBop' && raceSeries && raceYear && <PwBop raceSeries={ raceSeries } raceYear={ raceYear } /> }
            </section>
            <ReportModal open={ openModal } onClose={ handleCloseModal } setOpenModal={ setOpenModal }/>
        </>
    )
}

export default Optimize