import { useState } from 'react'

const useFetch = (url, method, body) => {
    const [data, setData] = useState([]);
    const fetchData = () => {
        fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => response.json())
        .then(json => {
            setData(json.data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }
    return [data, fetchData];
}

const ComplementTimingData = () => {
    const [sourceRaceDetailId, setSourceRaceDetailId] = useState("");
    const [destinationRaceDetailId, setDestinationRaceDetailId] = useState("");
    const [missingLapData, fetchMissingLapData] = useFetch('api/lap/findMissingLapDatasInSource', 'POST', {sourceRaceDetailId, destinationRaceDetailId});
    const [missingSectorData, fetchMissingSectorData] = useFetch('api/sector/findMissingSectorDatasInSource', 'POST', {sourceRaceDetailId, destinationRaceDetailId});
    
    const onClickFetchMissingData = () => {
        fetchMissingLapData()
        fetchMissingSectorData()
    }

    const onClickComplementMissingData = (url) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sourceRaceDetailId,
                destinationRaceDetailId
            })
        })
        .then(response => response.json())
        .then(json => {
            alert(json.data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    return ( 
        <div
            style={{margin: "20px 0 0 0"}}
        >
            <div>
                <label>公開セッション</label>
                <input type="text" value={sourceRaceDetailId} onChange={e => setSourceRaceDetailId(e.target.value)} placeholder="Race Detail ID" />
                <label>バックアップ</label>
                <input type="text" value={destinationRaceDetailId} onChange={e => setDestinationRaceDetailId(e.target.value)} placeholder="Race Detail ID" />
            </div>
            <div>
                <button style={{backgroundColor: 'dimgray', color: 'white'}} onClick={onClickFetchMissingData}>データ差分を表示</button>
            </div>
            <div>
                <button style={{backgroundColor: 'dimgray', color: 'white'}} onClick={() => onClickComplementMissingData('api/lap/complementMissingLapDatasFromOtherRaceDetail')}>公開セッションlap_dataをバックアップから補完</button>
                <button style={{backgroundColor: 'dimgray', color: 'white'}} onClick={() => onClickComplementMissingData('api/sector/complementMissingSectorDatasFromOtherRaceDetail')}>公開セッションsector_dataをバックアップから補完</button>
            </div>
            {missingLapData.length ? <MissingLapDataTable lapData={missingLapData}/> : null}
            {missingSectorData.length ? <MissingSectorDataTable sectorData={missingSectorData}/> : null}
        </div>
    )
}

const MissingLapDataTable = ({lapData}) => {
    const carNumbers = [...new Set(lapData.map(data => data.CAR_NUMBER))];
    const maxLapNumber = Math.max(...lapData.map(data => data.LAP_NUMBER));
    const lapNumbers = Array.from({length: maxLapNumber}, (_, i) => i + 1);

    return ( 
        <>
            <div>Lap data</div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        {carNumbers.map((carNumber, index) => (
                            <th style={{backgroundColor: 'dimgray'}} key={index}>{carNumber}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {lapNumbers.map((lapNumber, index) => {
                        const isOddRow = index % 2 !== 0;
                        const cellStyle = isOddRow ? {backgroundColor: 'dimgray', color: 'white'} : {backgroundColor: 'white', color: 'black'};
                        return (
                            <tr key={index}>
                                <td style={cellStyle}>{lapNumber}</td>
                                {carNumbers.map((carNumber, index) => {
                                    const lap = lapData.find(lap => lap.CAR_NUMBER === carNumber && lap.LAP_NUMBER === lapNumber);
                                    return <td key={index} style={cellStyle}>{lap ? lap.TIME : ''}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

const MissingSectorDataTable = ({sectorData}) => {
    const carNumbers = [...new Set(sectorData.map(data => data.CAR_NUMBER))];
    const maxLapNumber = Math.max(...sectorData.map(data => data.LAP_NUMBER));
    const lapNumbers = Array.from({length: maxLapNumber}, (_, i) => i + 1);
    const maxSectorNumber = Math.max(...sectorData.map(data => data.SECTOR_NUMBER));
    const sectorNumbers = Array.from({length: maxSectorNumber}, (_, i) => i + 1);

    return ( 
        <>
            <div>Sector data</div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        {carNumbers.map((carNumber, index) => (
                            <th style={{backgroundColor: 'dimgray'}} key={index}>{carNumber}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {lapNumbers.map((lapNumber, lapIndex) => (
                        sectorNumbers.map((sectorNumber, sectorIndex) => {
                            const isOddRow = (lapIndex) % 2 !== 0;
                            const cellStyle = isOddRow ? {backgroundColor: 'dimgray', color: 'white'} : {backgroundColor: 'white', color: 'black'};
                            return (
                                <tr key={`${lapIndex}-${sectorIndex}`}>
                                    {sectorIndex === 0 && <td style={cellStyle} rowSpan={sectorNumbers.length}>{lapNumber}</td>}
                                    <td style={cellStyle}>{sectorNumber}</td>
                                    {carNumbers.map((carNumber, index) => {
                                        const sector = sectorData.find(sectorData => sectorData.CAR_NUMBER === carNumber && sectorData.LAP_NUMBER === lapNumber && sectorData.SECTOR_NUMBER === sectorNumber);
                                        return <td key={index} style={cellStyle}>{sector ? sector.TIME : ''}</td>
                                    })}
                                </tr>
                            )
                        })
                    ))}
                </tbody>
            </table>
        </>
    )
}

export default ComplementTimingData