import { Dialog, DialogContent, DialogActions } from '@mui/material'

import '../css/common.css'

import { useTranslation } from 'react-i18next'

const ReportModal = (props) => {
    const { t } = useTranslation(['core'])

    const handleCloseModal = () => {
        props.onClose()
        props.setOpenModal(false)
    }

    const reportHandler = () => {
        const checkboxes = document.querySelectorAll('.report-checkbox input[type="checkbox"]')
        const checkedCheckboxes = Array.from(checkboxes).filter(checkbox => checkbox.checked)

        const reportContainer = document.querySelector('.report-container')
        reportContainer.innerText = ''

        checkedCheckboxes.forEach(checkbox => {
            const labelElement = document.querySelector(`label[for="${checkbox.id}"]`)

            const div = document.createElement('div')
            div.classList.add('report-content')
            div.innerText = `${labelElement.innerText}: ${checkbox.value}`
            reportContainer.appendChild(div)
        })
    }

    const dialogContentStyle={
        width: 'auto'
    }

    return (
        <Dialog open={ props.open } onClose={ handleCloseModal } maxWidth='md' fullWidth={ true } >
            <div className='top-dialog core'>
                <div className='dialog-title long'>{t('reportModal.createReport')}</div>
                <DialogActions>
                    <div onClick={ handleCloseModal } className='modal-exit'>✕</div>
                </DialogActions>
            </div>
            <DialogContent className='custom-modal core' style={dialogContentStyle}>
                <div className='container-border'>
                    <div className='checkbox-container'>
                        <div className='report-checkbox'>
                            <input type='checkbox' id='optimize-report-cb' name='otimize-report-cb' value='optimize-report' />
                            <label htmlFor='optimize-report-cb'>{t('reportModal.optimization')}</label>
                        </div>
                        <div className='report-checkbox'>
                            <input type='checkbox' id='time-series-report-cb' name='time-series-report-cb' value='time-series-report' />
                            <label htmlFor='time-series-report-cb'>{t('reportModal.timeSeriesComparison')}</label>
                        </div>
                        <div className='report-checkbox'>
                            <input type='checkbox' id='pw-bop-report-cb' name='pw-bop-report-cb' value='pw-bop-report' />
                            <label htmlFor='pw-bop-report-cb'>{t('reportModal.bwBopInformation')}</label>
                        </div>
                    </div>
                </div>
                <div className='modal-button-container'>
                    <div className='modal-button' onClick={ reportHandler }>{t('reportModal.createReport')}</div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ReportModal