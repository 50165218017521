import { useEffect, useState } from 'react'

import { SetTable } from './MakeTable'
import TimeComparisonChart from './TimeComparisonChart'
import SetDiffGapPos from '../services/SetDiffGapPos'
import ChartSetGapByLapTime from '../services/ChartSetGapByLapTime'
import Map from '../components/Map'
import RaceManagement from './RaceManagement'
import RivalsHeader from './RivalsHeader'

import { useTranslation } from 'react-i18next'

const TableChartToggleHandler = ({ tableChartToggle, displayMap, mapDisplayLeaders, setMapLeaders, setFollowCarNumber, setRivalCarNumbers, sectorTraffic, setPredictPitInMode, highlightUpdatedRows, setTableData, setLapData, pitRoadTime, pitStopTimeByRefueling, pitStopTimeByWork, pitStopTimeSettingStatus, tireWarmUpLossTime, raceManagementCar, setRaceManagementCar, setupChange, setSetupChange, driverComment, setDriverComment, notes, setNotes, latestDataByCar, setHistoryGap, carClasses, raceDetailId, currentView, displayRivalHeaders, columnOrder, setColumnOrder, columnIdAndName, tableScroll, setTableScroll, sectorLength, mapSize, setMapSize, setModalType, setOpenModal, mobileTableContent, setMobileTableContent, tabletMode, mobileMode, styleSheet}) => {
    const { t } = useTranslation(['strategy'])

    const [displayTableSection, setDisplayTableSection] = useState('result_container live')
    const [displayChartSection, setDisplayChartSection] = useState('result_container_displaynone live')
    const [displayMapSection, setDisplayMapSection] = useState('result_container_displaynone live')

    const styles =  styleSheet ?  'switchStyle' : ''

    useEffect(() => {
        if (tableChartToggle === 'table') {
            setDisplayTableSection('result_container live')
            setDisplayChartSection('result_container_displaynone live')
        } else {
            setDisplayTableSection('result_container_displaynone live')
            setDisplayChartSection('result_container live')
        }
    })

    useEffect(() => {
        displayMap ? setDisplayMapSection('result_container live') : setDisplayMapSection('result_container_displaynone live') 
    }, [displayMap])

    return (
        <div className={`${styles} container_dashboard`}>
            {tableChartToggle === 'table' && (
                <>
                    <section className='result_container live'>
                        {displayRivalHeaders && <RivalsHeader/>}
                        <SetTable
                            setFollowCarNumber={ setFollowCarNumber }
                            setRivalCarNumbers= { setRivalCarNumbers }
                            sectorTraffic={ sectorTraffic }
                            setPredictPitInMode={ setPredictPitInMode }
                            highlightUpdatedRows={ highlightUpdatedRows }
                            tableChartToggle={ tableChartToggle }
                            setTableData={ setTableData }
                            setLapData={ setLapData }
                            raceDetailId={ raceDetailId }
                            currentView={ currentView }
                            columnOrder={ columnOrder }
                            setColumnOrder={ setColumnOrder }
                            columnIdAndName = { columnIdAndName }
                            tableScroll = { tableScroll }
                            setTableScroll = { setTableScroll }
                            setMapLeaders = { setMapLeaders }
                            mobileTableContent ={mobileTableContent}
                            setMobileTableContent={setMobileTableContent}
                            tabletMode={tabletMode}
                            mobileMode={mobileMode}
                        />
                        <SetDiffGapPos
                            tableChartToggle={ tableChartToggle }
                            pitRoadTime={ pitRoadTime }
                            pitStopTimeByRefueling={ pitStopTimeByRefueling }
                            pitStopTimeByWork={ pitStopTimeByWork }
                            tireWarmUpLossTime={ tireWarmUpLossTime }
                            setTableData={ setTableData }
                            latestDataByCar={ latestDataByCar }
                            carClasses={ carClasses }
                            sectorLength = { sectorLength }
                        />
                    </section>
                
                    { displayMap && (
                        <section className='result_container live'>
                        <div className={`map-container map-container-${mapSize}`}>
                                <div className='map-container-size'>
                                    <div className={mapSize === 'small' ? 'map-container-size-clicked' : 'map-container-size-not-clicked'} onClick={() => setMapSize('small')}>{t('tableChartToggleHandler.small')}</div>
                                    <div className={mapSize === 'medium' ? 'map-container-size-clicked' : 'map-container-size-not-clicked'} onClick={() => setMapSize('medium')}>{t('tableChartToggleHandler.medium')}</div>
                                    <div className={mapSize === 'large' ? 'map-container-size-clicked' : 'map-container-size-not-clicked'} onClick={() => setMapSize('large')}>{t('tableChartToggleHandler.large')}</div>
                                </div>
                                <Map
                                    pitRoadTime={ pitRoadTime }
                                    pitStopTimeByRefueling={ pitStopTimeByRefueling }
                                    pitStopTimeByWork={ pitStopTimeByWork }
                                    pitStopTimeSettingStatus={ pitStopTimeSettingStatus }
                                    mapSize={ mapSize }
                                    setModalType={ setModalType }
                                    setOpenModal={ setOpenModal }
                                    mapDisplayLeaders={ mapDisplayLeaders }
                                    tableChartToggle={ tableChartToggle }
                                />
                                {/* コメントアウトしている状態だとリロード時にmapに車両が表示されるのが遅いらしいがDB負荷低減優先でそのままにする */}
                                {/* <SetDiffGapPos
                                    tableChartToggle={ tableChartToggle }
                                    pitRoadTime={ pitRoadTime }
                                    pitStopTimeByRefueling={ pitStopTimeByRefueling }
                                    pitStopTimeByWork={ pitStopTimeByWork }
                                    tireWarmUpLossTime={ tireWarmUpLossTime }
                                    setTableData={ setTableData }
                                    latestDataByCar={ latestDataByCar }
                                    carClasses={ carClasses }
                                    sectorLength = { sectorLength }
                                /> */}
                            </div>
                        </section>
                        
                    )}
                </>
            )}

            {tableChartToggle === 'chart' && (
                <div>
                    <section className='result_container live'>
                        <TimeComparisonChart
                            displayRivalHeaders = {displayRivalHeaders}
                            setFollowCarNumber={ setFollowCarNumber }
                            setRivalCarNumbers= { setRivalCarNumbers }
                        />
                        <ChartSetGapByLapTime
                            setHistoryGap={ setHistoryGap }
                        />
                        {tabletMode && (
                            <>
                                {displayRivalHeaders && <RivalsHeader/>}
                                <SetTable
                                    setFollowCarNumber={ setFollowCarNumber }
                                    setRivalCarNumbers= { setRivalCarNumbers }
                                    sectorTraffic={ sectorTraffic }
                                    setPredictPitInMode={ setPredictPitInMode }
                                    highlightUpdatedRows={ highlightUpdatedRows }
                                    tableChartToggle={ tableChartToggle }
                                    setTableData={ setTableData }
                                    setLapData={ setLapData }
                                    raceDetailId={ raceDetailId }
                                    currentView={ currentView }
                                    columnOrder={ columnOrder }
                                    setColumnOrder={ setColumnOrder }
                                    columnIdAndName = { columnIdAndName }
                                    tableScroll = { tableScroll }
                                    setTableScroll = { setTableScroll }
                                    setMapLeaders = { setMapLeaders }
                                    mobileTableContent ={mobileTableContent}
                                    setMobileTableContent={setMobileTableContent}
                                />
                                <SetDiffGapPos
                                    tableChartToggle={ tableChartToggle }
                                    pitRoadTime={ pitRoadTime }
                                    pitStopTimeByRefueling={ pitStopTimeByRefueling }
                                    pitStopTimeByWork={ pitStopTimeByWork }
                                    tireWarmUpLossTime={ tireWarmUpLossTime }
                                    setTableData={ setTableData }
                                    latestDataByCar={ latestDataByCar }
                                    carClasses={ carClasses }
                                    sectorLength = { sectorLength }
                                />
                            </>
                        )}
                        {mobileMode && (
                            <>
                            </>
                        )}

                        
                    </section>
                </div>
            )}
            {tableChartToggle === 'map' && (
                <div>
                    <section className='result_container result_container_map'>
                        <div className={`one-screen-map map-container map-container-full-screen`}>
                            <Map
                                pitRoadTime={ pitRoadTime }
                                pitStopTimeByRefueling={ pitStopTimeByRefueling }
                                pitStopTimeByWork={ pitStopTimeByWork }
                                pitStopTimeSettingStatus={ pitStopTimeSettingStatus }
                                mapSize={ 'fullScreen' }
                                setModalType={ setModalType }
                                setOpenModal={ setOpenModal }
                                mapDisplayLeaders={ mapDisplayLeaders }
                                tableChartToggle={ tableChartToggle }
                            />
                            <SetDiffGapPos
                                tableChartToggle={ tableChartToggle }
                                pitRoadTime={ pitRoadTime }
                                pitStopTimeByRefueling={ pitStopTimeByRefueling }
                                pitStopTimeByWork={ pitStopTimeByWork }
                                tireWarmUpLossTime={ tireWarmUpLossTime }
                                setTableData={ setTableData }
                                latestDataByCar={ latestDataByCar }
                                carClasses={ carClasses }
                                sectorLength = { sectorLength }
                            />
                        </div>
                        {tabletMode && (
                            <>
                                {displayRivalHeaders && <RivalsHeader/>}
                                <SetTable
                                    setFollowCarNumber={ setFollowCarNumber }
                                    setRivalCarNumbers= { setRivalCarNumbers }
                                    sectorTraffic={ sectorTraffic }
                                    setPredictPitInMode={ setPredictPitInMode }
                                    highlightUpdatedRows={ highlightUpdatedRows }
                                    tableChartToggle={ tableChartToggle }
                                    setTableData={ setTableData }
                                    setLapData={ setLapData }
                                    raceDetailId={ raceDetailId }
                                    currentView={ currentView }
                                    columnOrder={ columnOrder }
                                    setColumnOrder={ setColumnOrder }
                                    columnIdAndName = { columnIdAndName }
                                    tableScroll = { tableScroll }
                                    setTableScroll = { setTableScroll }
                                    setMapLeaders = { setMapLeaders }
                                    mobileTableContent ={mobileTableContent}
                                    setMobileTableContent={setMobileTableContent}
                                />
                                <SetDiffGapPos
                                    tableChartToggle={ tableChartToggle }
                                    pitRoadTime={ pitRoadTime }
                                    pitStopTimeByRefueling={ pitStopTimeByRefueling }
                                    pitStopTimeByWork={ pitStopTimeByWork }
                                    tireWarmUpLossTime={ tireWarmUpLossTime }
                                    setTableData={ setTableData }
                                    latestDataByCar={ latestDataByCar }
                                    carClasses={ carClasses }
                                    sectorLength = { sectorLength }
                                />
                            </>
                        )}
                    </section>
                </div>
            )}
            {tableChartToggle === 'management' && (
                <RaceManagement 
                    raceManagementCar={raceManagementCar} 
                    setRaceManagementCar={setRaceManagementCar}
                    setupChange={setupChange}
                    setSetupChange={setSetupChange}
                    driverComment={driverComment}
                    setDriverComment={setDriverComment}
                    notes={notes}
                    setNotes={setNotes}
                />
            )}
        </div>
    )
}

export default TableChartToggleHandler
