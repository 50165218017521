import { useState, useEffect } from 'react'
import { TableContainer, Paper, Table, TableHead, TableRow, TableBody } from '@mui/material'

import { StyledHeaderTableCell, StyledTableCell } from '../components/TableStyle'
import { toFixedIfNumber } from '../services/Util'

import { useLiveData } from '../../DataContext'

import TimeFormatter from './TimeFormatter'

import followImage from '../image/follow.png'
import rivalImage from '../image/rival.png'

const RivalsHeader = () => {
    const { tableData, lapData, followCarNumber, rivalCarNumbers, isRace, sectorLength, overallBest, remainingTimeAndLaps, isLightMode } = useLiveData()

    const [followCar, setFollowCar] = useState({})
    const [headerCars, setHeaderCars] = useState([])
    const [finalPitLap, setFinalPitLap] = useState([])
    const [pitLoss, setPitLoss] = useState([])
    const [rivalInfos, setRivalInfos] = useState({})
    const [buttonSelector, setButtonSelector] = useState('Time')

    useEffect(() => {
        if (tableData.length === 0) return
        const newHeaderCars = []
        if (followCarNumber) {
            newHeaderCars.push(tableData?.filter((data) => (data.carno)?.split('.')[1] === followCarNumber))
            setFollowCar(newHeaderCars[0][0])
        }
        
        for (let i = 0; i < rivalCarNumbers.length; i++) {
            newHeaderCars.push(tableData?.filter((data) => (data.carno)?.split('.')[1] === rivalCarNumbers[i]))
        }

        setHeaderCars(newHeaderCars.flat().sort((a, b) => a.pos - b.pos))

        const tempData = []
        tableData.forEach((row) => {
            if (rivalCarNumbers.includes((row.carno).split('.')[1])) {
                const driverData = { 
                    carNumber: Number((row.carno).split('.')[1]),
                    targetLapTime: Number(row.laptime) + Number(row.gap) / (remainingTimeAndLaps/Number(row.laptime))
                }
                if (driverData.targetLapTime && isFinite(driverData.targetLapTime)) {
                    // console.log(driverData.targetLapTime)
                    tempData.push(driverData)
                }
            }
        }) 

        setRivalInfos(tempData)
    }, [tableData, followCarNumber, rivalCarNumbers])

    useEffect(() => {
        const finalPitLap = headerCars.map((car) => {
            const carNumber = Number(car.carno.split('.')[1])
            const carLapData = lapData?.filter((data) => data.CAR_NUMBER === carNumber)
    
            if (carLapData.length === 0) {
                return { lastPitLap: null, carNumber }
            }
    
            carLapData.sort((a, b) => b.LAP_NUMBER - a.LAP_NUMBER)
            const firstInstance = carLapData.reduce((prev, current) => (
                prev.PIT_STOP_TIME > current.PIT_STOP_TIME
            ) ? prev : current)
    
            return {
                lastPitLap: firstInstance?.LAP_NUMBER ?? null,
                carNumber
            }
        })
    
        const currentPitLoss = finalPitLap.map((pitLap) => {
            const currentCarData = lapData?.filter((data) => data.CAR_NUMBER === pitLap.carNumber)
            const beforeInLapData = currentCarData?.find((data) => data.LAP_NUMBER === pitLap.lastPitLap - 1)
            const inLapData = currentCarData?.find((data) => data.LAP_NUMBER === pitLap.lastPitLap)
            const outLapData = currentCarData?.find((data) => data.LAP_NUMBER === pitLap.lastPitLap + 1)
            const afterOutLapData = currentCarData?.find((data) => data.LAP_NUMBER === pitLap.lastPitLap + 2)
            
            let pitLossTime
            if (beforeInLapData && inLapData && outLapData && afterOutLapData) {
                pitLossTime = (inLapData.TIME + outLapData.TIME) - (beforeInLapData.TIME + afterOutLapData.TIME)
            } else {
                pitLossTime = null
            }
            return pitLossTime !== null ? pitLossTime.toFixed(3) : null
        }).filter(Boolean)
    
        setPitLoss(currentPitLoss)
        setFinalPitLap(finalPitLap)
    }, [lapData, headerCars])

    const handleButtonSelector = () => {
        if (buttonSelector === 'Time') {
            setButtonSelector('Diff')
        } else if (buttonSelector === 'Diff') {
            setButtonSelector('Best')
        } else if (buttonSelector === 'Best') {
            setButtonSelector('Time')
        }
    }

    const DynamicNumberCell = ({ car, followCar, valueKey, buttonSelector }) => {
        const value = car[valueKey]
        const followValue = followCar[valueKey]
        const diffValue = followCarNumber && followValue !== undefined && value !== undefined ? `${value - followValue}` : ''
        const bestValue = car.bestLap ? car.bestLap : '-'
    
        return (
            <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'>
                {
                    buttonSelector === 'Time' ? value :
                    buttonSelector === 'Diff' && followCarNumber ? diffValue :
                    buttonSelector === 'Best' ? bestValue : '-'
                }
            </StyledTableCell>
        )
    }

    const TimeCell = ({ condition, trueComponent, falseComponent }) => (
        <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'>
            {condition ? trueComponent : falseComponent}
        </StyledTableCell>
    )
      
    const SectorTimeCell = ({ car, sector, followCar, buttonSelector, overallBest, }) => {
        const sectorTime = car[`sec${sector}`]
        const sectorBest = car[`sec${sector}Best`]
        const followCarTime = followCar[`sec${sector}`]
        const isBest = buttonSelector === 'Best'
        const isDiff = buttonSelector === 'Diff' && followCarNumber
        const isTime = buttonSelector === 'Time'
      
        const timeFormatterComponent = (time) => <TimeFormatter seconds={time} decimalPlaces={3}/>
        const diffComponent = followCarTime !== '' && sectorTime !== '' ? timeFormatterComponent(followCarTime - sectorTime) : '-'
        const bestComponent = (
          <div style={{color: sectorBest <= overallBest[`sec${sector}`] ? '#b8a1e6' : '#a1e6c3'}}>
                {sectorBest !== '' ? timeFormatterComponent(sectorBest) : '-'}
          </div>
        )
      
        return (
            <TimeCell
                isLightMode={isLightMode}
                condition={isTime}
                trueComponent={sectorTime !== '' ? timeFormatterComponent(sectorTime) : '-'}
                falseComponent={isDiff ? diffComponent : (isBest ? bestComponent : '-')}
            />
        )
    }
      
    const LapTimeCell = ({ car, followCar, buttonSelector, overallBest }) => {
        const laptimeComponent = <TimeFormatter seconds={car.laptime} decimalPlaces={3}/>
        const diffComponent = followCarNumber && followCarNumber !== car.carno.split('.')[1] ? `${toFixedIfNumber(followCar.laptime - car.laptime, 3)}` : ''
        const bestComponent = (
            <div style={{color: car.bestLaptime <= overallBest.lap ? '#b8a1e6' : '#a1e6c3'}}>
                {car.bestLaptime ? laptimeComponent : ''}
            </div>
        )
      
        return (
            <TimeCell
                isLightMode={isLightMode}
                condition={buttonSelector === 'Time'}
                trueComponent={laptimeComponent}
                falseComponent={buttonSelector === 'Diff' && followCarNumber ? diffComponent : (buttonSelector === 'Best' ? bestComponent : '-')}
            />
        )
    }
    
    return (
        <>
            {(rivalCarNumbers.length > 0 || followCarNumber) &&
                <TableContainer className='rival-tavle-container' component={Paper}>
                    <Table sx={{ minWidth: 650 }} className='live-table rival-header-table' style={{height:'100%'}}>
                        <TableHead className='live-table-header rival-table-header' >
                            <TableRow>
                                <StyledHeaderTableCell align='center'></StyledHeaderTableCell>
                                <StyledHeaderTableCell isLightMode={isLightMode} align='center'>Pos</StyledHeaderTableCell>
                                <StyledHeaderTableCell isLightMode={isLightMode} align='center'>Name (Number)</StyledHeaderTableCell>
                                <StyledHeaderTableCell isLightMode={isLightMode} align='center'>Gap</StyledHeaderTableCell>
                                <StyledHeaderTableCell isLightMode={isLightMode} align='center'>Lap</StyledHeaderTableCell>
                                <StyledHeaderTableCell isLightMode={isLightMode} align='center'>
                                    Sector
                                </StyledHeaderTableCell>
                                <StyledHeaderTableCell isLightMode={isLightMode} align='center' colSpan={sectorLength}>
                                    Sector Times
                                    <button className='rivals-button' onClick={handleButtonSelector}>{buttonSelector}</button>
                                </StyledHeaderTableCell>
                                <StyledHeaderTableCell isLightMode={isLightMode} align='center'>
                                    Laptime
                                    <button className='rivals-button' onClick={handleButtonSelector}>{buttonSelector}</button>
                                </StyledHeaderTableCell>
                                { followCarNumber ? <StyledHeaderTableCell isLightMode={isLightMode} align='center'>Catchup Laptime</StyledHeaderTableCell> : '' }
                                { isRace === 1 ? <StyledHeaderTableCell  isLightMode={isLightMode}align='center'>OTS</StyledHeaderTableCell> : <StyledHeaderTableCell align='center'>Driving Time</StyledHeaderTableCell> }
                                <StyledHeaderTableCell isLightMode={isLightMode} align='center'>Pit</StyledHeaderTableCell>
                                <StyledHeaderTableCell isLightMode={isLightMode} align='center'>Last Pit</StyledHeaderTableCell>
                                <StyledHeaderTableCell isLightMode={isLightMode} align='center'>Last Pit Loss</StyledHeaderTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {headerCars.map((car, index) => (
                                <TableRow key={`index_${car.carno}`} style={{ boxShadow: (car.carno.split('.')[1] === followCarNumber) ? 'rgba(255, 17, 26, .6) 0px 9px 19px 0px inset' : 'rgba(255, 241, 119, 0.8) 0px 9px 19px 0px inset' }}>
                                    <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'>{(car.carno.split('.')[1] === followCarNumber) ?<img className='follow-header-mark' src={followImage} alt='Followマーク' />:<img className='reval-header-mark' src={rivalImage} alt='ライバルマーク' />}</StyledTableCell>
                                    <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'>{car.pos} {followCarNumber ? `(${followCar.pos - car.pos})`: `` }</StyledTableCell>
                                    <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'>{car.driverName} ({(car.carno).split('.')[1]})</StyledTableCell>
                                    <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'>{toFixedIfNumber(car.gap, 3)}</StyledTableCell>
                                    <DynamicNumberCell car={car} followCar={followCar} valueKey='lap' buttonSelector={buttonSelector} />
                                    <DynamicNumberCell car={car} followCar={followCar} valueKey='sec' buttonSelector={buttonSelector} />
                                    <SectorTimeCell car={car} sector={1} followCar={followCar} buttonSelector={buttonSelector} overallBest={overallBest} />
                                    <SectorTimeCell car={car} sector={2} followCar={followCar} buttonSelector={buttonSelector} overallBest={overallBest} />
                                    <SectorTimeCell car={car} sector={3} followCar={followCar} buttonSelector={buttonSelector} overallBest={overallBest} />
                                    {sectorLength === 4 && <SectorTimeCell isLightMode={isLightMode} car={car} sector={4} followCar={followCar} buttonSelector={buttonSelector} overallBest={overallBest} />}
                                    <LapTimeCell isLightMode={isLightMode} car={car} followCar={followCar} buttonSelector={buttonSelector} overallBest={overallBest} />
                                    { followCarNumber ? <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'>{
                                        followCarNumber === (car.carno).split('.')[1] ? '-' :
                                        rivalInfos.map((rivalInfo, index) => (
                                            (rivalInfo.carNumber === Number((car.carno).split('.')[1])) ? <TimeFormatter key={`rivals${rivalInfo.carNumber}`} seconds={rivalInfo.targetLapTime} decimalPlaces={3}/> : ''
                                        ))
                                    }</StyledTableCell> : ''}
                                    { isRace === 1 ? <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'>{car.otsLeft} {followCarNumber ? `(${car.otsLeft - followCar.otsLeft})`: `` }</StyledTableCell> : 
                                        <StyledTableCell isLightMode={isLightMode}  align='center' className='cell-items'><TimeFormatter seconds={car.driverTotalTime} decimalPlaces={0}/> </StyledTableCell> 
                                    }
                                    <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'>{car.pit}</StyledTableCell>
                                    <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'>{finalPitLap[index]?.lastPitLap ? finalPitLap[index].lastPitLap : '-'}</StyledTableCell>  
                                    <StyledTableCell isLightMode={isLightMode} align='center' className='cell-items'><TimeFormatter seconds={pitLoss[index]} decimalPlaces={3}/></StyledTableCell>                      
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>   
            }
        </>
    )
}

export default RivalsHeader