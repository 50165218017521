import React, { useState, useEffect } from 'react'

import '../css/setup.css'

import Track from './Track'
import ScatterPlot from './ScatterPlot'
import SelectBoxLabels from './SelectBoxLabels'
import CornerChart from './CornerChart'

import extractContinuous from '../services/extractContinuous'

import { useTranslation } from 'react-i18next'

const SetupLeftResult = (props) => {
    const { t } = useTranslation(['core'])

    const [ selectedTopGraph, setSelectedTopGraph ] = useState('rollStiff')
    const [ selectedBottomGraph, setSelectedBottomGraph ] = useState('handling')

    const [ scatLabels, setScatLabels ] = useState([])
    const [ selectedX, setSelectedX ] = useState('')
    const [ selectedY, setSelectedY ] = useState('LapTime')
    const [ selectedColorChart, setSelectedColorChart ] = useState('LapTime')

    const [ rollStiffLabels, setRollStiffLabels ] = useState([])
    const [ selectedRollStiffX, setSelectedRollStiffX ] = useState('')
    const [ selectedRollStiffY, setSelectedRollStiffY ] = useState('LapTime')

    const [ heaveLabels, setHeaveLabels ] = useState([])
    const [ selectedHeaveX, setSelectedHeaveX ] = useState('')
    const [ selectedHeaveY, setSelectedHeaveY ] = useState('LapTime')

    const [df_baseHandling, setDf_baseHandling] = useState()
    const [df_baseAero, setDf_baseAero] = useState()
    const [df_baseMech, setDf_baseMech] = useState()

    const [ df_scatHandling, setDf_scatHandling ] = useState()
    const [ df_scatAero, setDf_scatAero ] = useState()
    const [ df_scatMech, setDf_scatMech ] = useState()

    const df_baseSetup = props.df_baseSetup
    const df_mapDriver = props.df_mapDriver

    const df_scatRollStiff = props.df_scatKPI
    const df_scatHeave = props.df_scatKPI

    const listAns = props.listAns
    const df_DOEcondition = props.df_DOEcondition
    const selectedIndex = props.selectedIndex

    const Obj_chart_combination = props.Obj_chart_combination
    const Obj_KPI_base = props.Obj_KPI_base
    const Obj_KPI_recommend = props.Obj_KPI_recommend
    const Obj_circuit_sens = props.Obj_circuit_sens

    const df_baseKPI = props.df_baseKPI
    const df_scatKPI = props.df_scatKPI

    const track = props.track

    useEffect(() => {
        const getScatLabels = () => {
            if (!df_baseSetup) return []

            let plotLabels = Array.from(extractContinuous(df_baseSetup))
            let KPIlabels = df_baseKPI.columns

            const categoriesToAdd = []
            if (df_DOEcondition) {
                const categoryIndex = df_DOEcondition.columns.indexOf('Category_name1')
                df_DOEcondition.values.forEach((d) => {
                    if (d[categoryIndex] !== '-') {
                        categoriesToAdd.push(d[0])
                    }
                })
            }

            const arraySectors = ['LapTime']

            const labels = [...plotLabels, ...categoriesToAdd, ...KPIlabels, ...arraySectors, ...df_mapDriver.index]
            setSelectedX(labels[0])
            return labels
        }

        const getKPILabels = (type) => {
            if (!df_scatKPI) return []
      
            let labels = df_scatKPI.columns
            if (type === 'rollStiff') {
                labels = labels.filter((l) =>
                    l.includes('Roll_Stiff') ||
                    l.includes('Wheelrate') ||
                    l.includes('LapTime')
                )
                setSelectedRollStiffX(labels[0])
            } else {
                labels = labels.filter((l) =>
                    l.includes('Heave') ||
                    l.includes('Spring_Wheelrate') ||
                    l.includes('Sprung_Resonance') ||
                    l.includes('LapTime')
                )
                setSelectedHeaveX(labels[1])
            }
            return labels
        }

        setScatLabels(getScatLabels())
        setRollStiffLabels(getKPILabels('rollStiff'))
        setHeaveLabels(getKPILabels('heave'))
    }, [df_baseSetup, df_DOEcondition, df_scatKPI])

    useEffect(() => {
        setDf_baseAero(props.df_baseAero)
        setDf_baseMech(props.df_baseMech)
        setDf_baseHandling(props.df_baseHandling)

        setDf_scatHandling(props.df_scatHandling)
        setDf_scatAero(props.df_scatAero)
        setDf_scatMech(props.df_scatMech)
    }, [props.df_baseAero, props.df_baseMech, props.df_baseHandling, props.df_scatHandling, props.df_scatAero, props.df_scatMech])

    return (
        <div className='result-container left'>
            <div className='top-left top left'>
                <div className='select-container'>
                    <select onChange={ (e) => setSelectedTopGraph(e.target.value) } value={ selectedTopGraph }>
                        <option value='rollStiff'>{t('setup.rollStiffness')}</option>
                        <option value='heave'>Heave</option>
                    </select>
                </div>
                {
                    selectedTopGraph === 'rollStiff' && df_scatRollStiff
                    ?
                        <div className='chart-container'>
                            <div className='canvas-container scat-container'>
                                <ScatterPlot
                                    list={ listAns }
                                    df_scatter={ df_scatRollStiff }
                                    df_base={ df_baseKPI }
                                    selectedX={ selectedRollStiffX }
                                    selectedY={ selectedRollStiffY }
                                    selectedColorChart={ selectedColorChart }
                                    isSPM={ true }
                                    selectedIndex={ selectedIndex }
                                />
                                {/* <div className='select-container x-select-container'>
                                    <SelectBoxLabels title='X軸' onChangeType='rollStiff' setState={setSelectedRollStiffX} options={rollStiffLabels} value={selectedRollStiffX}/>
                                </div>
                                <div className='select-container y-select-container'>
                                    <SelectBoxLabels title='Y軸' onChangeType='rollStiff' setState={setSelectedRollStiffY} options={rollStiffLabels} value={selectedRollStiffY}/>
                                </div> */}
                            </div>
                            <div className='select-container x-select-container'>
                                <SelectBoxLabels title={t('general.xAxis')} onChangeType='rollStiff' setState={setSelectedRollStiffX} options={rollStiffLabels} value={selectedRollStiffX}/>
                            </div>
                            <div className='select-container y-select-container'>
                                <SelectBoxLabels title={t('general.yAxis')} onChangeType='rollStiff' setState={setSelectedRollStiffY} options={rollStiffLabels} value={selectedRollStiffY}/>
                            </div>
                        </div>
                    :
                        df_scatHeave &&
                            <div className='chart-container'>
                                <div className='canvas-container scat-container'>
                                    <ScatterPlot
                                        list={ listAns }
                                        df_scatter={ df_scatHeave }
                                        df_base={ df_baseKPI }
                                        selectedX={ selectedHeaveX }
                                        selectedY={ selectedHeaveY }
                                        selectedColorChart={ selectedColorChart }
                                        isSPM={ true }
                                        selectedIndex={ selectedIndex }
                                    />
                                    {/* <div className='select-container x-select-container'>
                                        <SelectBoxLabels title='X軸' onChangeType='heave' setState={setSelectedHeaveX} options={heaveLabels} value={selectedHeaveX}/>
                                    </div>
                                    <div className='select-container y-select-container'>
                                        <SelectBoxLabels title='Y軸' onChangeType='heave' setState={setSelectedHeaveY} options={heaveLabels} value={selectedHeaveY}/>
                                    </div> */}
                                </div>
                                <div className='select-container x-select-container'>
                                    <SelectBoxLabels title={t('general.xAxis')} onChangeType='heave' setState={setSelectedHeaveX} options={heaveLabels} value={selectedHeaveX}/>
                                </div>
                                <div className='select-container y-select-container'>
                                    <SelectBoxLabels title={t('general.yAxis')} onChangeType='heave' setState={setSelectedHeaveY} options={heaveLabels} value={selectedHeaveY}/>
                                </div>
                            </div>
                }
            </div>
            <div className='top-right top right'>
                <div className='select-container'>
                    <select onChange={ (e) => setSelectedBottomGraph(e.target.value) } value={ selectedBottomGraph }>
                        <option value='handling'>{t('setup.handling')}</option>
                        <option value='aero'>Aero Balance</option>
                        <option value='mechanical'>Mechanical Balance</option>
                    </select>
                </div>
                <div className='chart-container'>
                    <div className='canvas-container scat-container'>
                        {
                            selectedBottomGraph === 'handling' && df_scatHandling
                            ?
                                <ScatterPlot
                                    list={ listAns }
                                    df_scatter={ df_scatHandling }
                                    df_base={ df_baseHandling }
                                    selectedX={ selectedX }
                                    selectedY={ selectedY }
                                    selectedColorChart={ selectedColorChart }
                                    isSPM={ true }
                                    selectedIndex={ selectedIndex }
                                />
                            :
                                selectedBottomGraph === 'aero' && df_scatAero
                            ?
                                <ScatterPlot
                                    list={ listAns }
                                    df_scatter={ df_scatAero }
                                    df_base={ df_baseAero }
                                    selectedX={ selectedX }
                                    selectedY={ selectedY }
                                    selectedColorChart={ selectedColorChart }
                                    isSPM={ true }
                                    selectedIndex={ selectedIndex }
                                />
                            :
                                df_scatMech &&
                                    <ScatterPlot
                                        list={ listAns }
                                        df_scatter={ df_scatMech }
                                        df_base={ df_baseMech }
                                        selectedX={ selectedX }
                                        selectedY={ selectedY }
                                        selectedColorChart={ selectedColorChart }
                                        isSPM={ true }
                                        selectedIndex={ selectedIndex }
                                    />
                            }

                        {/* <div className='select-container x-select-container'>
                            <SelectBoxLabels title='X軸' onChangeType='scatterPlot' setState={ setSelectedX } options={ scatLabels } value={ selectedX } />
                        </div>
                        <div className='select-container y-select-container'>
                            <SelectBoxLabels title='Y軸' onChangeType='scatterPlot' setState={ setSelectedY } options={ scatLabels } value={ selectedY } />
                        </div> */}
                    </div>
                    <div className='select-container x-select-container'>
                        <SelectBoxLabels title={t('general.xAxis')} onChangeType='scatterPlot' setState={ setSelectedX } options={ scatLabels } value={ selectedX } />
                    </div>
                    <div className='select-container y-select-container'>
                        <SelectBoxLabels title={t('general.yAxis')} onChangeType='scatterPlot' setState={ setSelectedY } options={ scatLabels } value={ selectedY } />
                    </div>
                </div>
            </div>
            <div className='bottom-left bottom left'>
                <Track trackId= { track } />
            </div>
            <div className='bottom-right bottom right'>
                { Object.keys(Obj_KPI_base).length > 0 && (
                    <>
                        <div className='graph-title'>
                            <p>{t('setup.cornerSensitivity')}</p>
                        </div>
                        <div className='chart-container'>
                            <CornerChart
                                Obj_chart_combination={ Obj_chart_combination }
                                Obj_KPI_base={ Obj_KPI_base }
                                Obj_KPI_recommend={ Obj_KPI_recommend }
                                Obj_circuit_sens={ Obj_circuit_sens }
                                isSetup={ true }
                                isCornerSensitivity={ true }
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default SetupLeftResult