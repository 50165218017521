// 各手法で最適解探索
function compare_setupRequest(list, df_base, df_scatter, df_setupRequest) {
    let df_ans = df_scatter.iloc({'rows':list}).resetIndex()

    let keep_range = 10 //Keepの時の±のレンジ
    let KPI_index = [9, 10, 11, 12, 15] //RollTotal, RollBalance, HeaveTotal, HeaveBalance, Resonance
    let KPI_method = ['Total', 'Balance', 'Total', 'Balance', 'Balance']

    let listAns = list

    for (let i = 0; i < df_setupRequest.index.length; i++) {
        let row_name = df_base.columns[KPI_index[i]]
        let base_data = df_base.iloc({'rows':[0], 'columns':[KPI_index[i]]}).values[0][0]
        let scat_range = df_ans[row_name].max() - df_ans[row_name].min()
        let judge_value = df_setupRequest.iloc({'rows':[i], 'columns':[0]}).values[0][0]
        df_ans = df_scatter.iloc({'rows':listAns}).resetIndex()

        if (listAns === undefined){
            return listAns
        } else {
            if (KPI_method[i] === 'Total') {
                if (judge_value === 'Increase') {
                    console.log('increase ' + row_name)
                    console.log('Answer num = ' + df_ans.index.length)
                    df_ans = df_ans.query(df_ans[row_name].gt(base_data))
                    console.log('Answer num = ' + df_ans.index.length)
                } else if (judge_value === 'Keep') {
                    console.log('Keep ' + row_name)
                    console.log('Answer num = ' + df_ans.index.length)
                    df_ans = df_ans.query(df_ans[row_name].gt(base_data - scat_range*keep_range/100)).resetIndex()
                    df_ans = df_ans.query(df_ans[row_name].lt(base_data + scat_range*keep_range/100))
                    console.log('Answer num = ' + df_ans.index.length)
                } else if (judge_value === 'Decrease') {
                    console.log('decrease ' + row_name)
                    console.log('Answer num = ' + df_ans.index.length)
                    df_ans = df_ans.query(df_ans[row_name].lt(base_data))
                    console.log('Answer num = ' + df_ans.index.length)
                }
            } else if (KPI_method[i] === 'Balance') {
                if (judge_value === 'More Front') {
                    console.log('more front ' + row_name)
                    console.log('Answer num = ' + df_ans.index.length)
                    df_ans = df_ans.query(df_ans[row_name].gt(base_data))
                    console.log('Answer num = ' + df_ans.index.length)
                } else if (judge_value === 'Keep') {
                    console.log('Keep ' + row_name)
                    console.log('Answer num = ' + df_ans.index.length)
                    df_ans = df_ans.query(df_ans[row_name].gt(base_data - scat_range*keep_range/100)).resetIndex()
                    df_ans = df_ans.query(df_ans[row_name].lt(base_data + scat_range*keep_range/100))
                    console.log('Answer num = ' + df_ans.index.length)
                } else if (judge_value === 'More Rear') {
                    console.log('more rear ' + row_name)
                    console.log('Answer num = ' + df_ans.index.length)
                    console.log(df_ans)
                    df_ans = df_ans.query(df_ans[row_name].lt(base_data))
                    console.log('Answer num = ' + df_ans.index.length)
                }

            }
            df_ans = df_ans.resetIndex()
            listAns = df_ans['id'].values
        }
    }

    return listAns
}

export default compare_setupRequest