import React from 'react'

import DatabaseUpdateKeysOrganism from './DatabaseUpdateKeysOrganism'
import DatabaseUpdateDatasOrganism from './DatabaseUpdateDatasOrganism'


const EditDatabaseTemplate = ( props ) => {

    return (
        <div
            style={{margin: "20px 0 0 0"}}
        >
            <DatabaseUpdateKeysOrganism 
                databaseColumns={props.databaseColumns}
                databaseLabel = {props.databaseLabel}
                eventLabel = {props.eventLabel}
                eventList={props.eventList}
                handleDatabaseColumnSelectChange={props.handleDatabaseColumnSelectChange}
                handleEventSelect={props.handleEventSelect}
                handleEventLabelChange = {props.handleEventLabelChange}
                handleDatabaseLabelChange = {props.handleDatabaseLabelChange}
                handleRaceDetailIdChange={props.handleRaceDetailIdChange}
                handleSelectedDatabaseTableChange={props.handleSelectedDatabaseTableChange}
                isLoading = {props.isLoading}
                raceDetails={props.raceDetails}
                selectedDatabaseColumnName={props.selectedDatabaseColumnName}
                selectedDatabaseTableName={props.selectedDatabaseTableName}
                selectedRaceDetailId={props.selectedRaceDetailId}
                selectedEventId={props.selectedEventId}
            />
            {props.selectedDatabaseColumnName !== '' && !props.isLoading ?
                <DatabaseUpdateDatasOrganism
                    selectedDatabaseColumnName = {props.selectedDatabaseColumnName}
                    selectedDatabaseTableName = {props.selectedDatabaseTableName}
                    selectedRaceDetailId = {props.selectedRaceDetailId}
                    setUpdateData = {props.setUpdateData}
                    tableDatas = {props.tableDatas}
                />
            : null}
        </div>
    )
}

export default EditDatabaseTemplate