import React from 'react'
import { useEffect, useState} from 'react'
import { useNavigate} from 'react-router-dom'

import ComplementTimingData from '../components/ComplementTimingData'
import CreatingTeamTimingDetail from '../components/CreatingTeamTimingDetail'
import CustomTabPanel from '../components/CustomTabPanel'
import EditDatabaseTemplate from '../components/EditDatabaseTemplate'
import Header from '../components/Header'

import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { toJSONBrowser } from 'danfojs/dist/danfojs-base/io/browser'

import { useTranslation } from 'react-i18next'

const EditDatabase = ( props ) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [databaseColumns, setDatabaseColumns] = useState([])
    const [databaseLabel, setDatabaseLabel] = useState(t('databaseUpdateKeysOrganism.databaseSelection'))
    const [eventLabel, setEventLabel] = useState(t('databaseUpdateKeysOrganism.eventSelection'))
    const [eventList, setEventList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [lapDatas, setLapDatas] = useState([])
    const [raceDetails, setRaceDetails] = useState([])
    const [sectorDatas, setSectorDatas] = useState([])
    const [selectedDatabaseColumnName, setSelectedDatabaseColumnName] = useState('')
    const [selectedDatabaseTableName, setSelectedDatabaseTable] = useState('')
    const [selectedEventId, setSelectedEventId] = useState('')
    const [selectedRaceDetailId, setSelectedRaceDetailId] = useState(null)
    const [tableDatas, setTableDatas] = useState([])
    const [updateData, setUpdateData] = useState({})

    const [tabValue, setTabValue] = useState(0)
        
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    }

    const navigate = useNavigate()

    const handleEventLabelChange = (newLabel) => {
        setEventLabel(newLabel)
    }

    const handleDatabaseLabelChange = (newLabel) => {
        setDatabaseLabel(newLabel)
    }
    
    const handleDatabaseColumnSelectChange = (newDatabaseColumn) => {
        setSelectedDatabaseColumnName(newDatabaseColumn)
    }
    
    const handleEventSelect = (newEvent) => {
        setSelectedEventId(newEvent)
        setSelectedDatabaseColumnName('')
        setDatabaseColumns([])
        setSelectedRaceDetailId(null)
    }
    
    const handleRaceDetailIdChange = (newRaceId) => {
        setSelectedRaceDetailId(newRaceId)
    }
    
    const handleSelectedDatabaseTableChange = (newDatabaseTable) => {
        setSelectedDatabaseTable(newDatabaseTable)
        setSelectedDatabaseColumnName('')
        setDatabaseColumns([])
    }
    
    
    const updateLapData = (lapData) => {
        return fetch('/api/lap/update', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(lapData)
        })
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                setLapDatas(currentLapDatas => currentLapDatas.map(data => 
                    data.ID === lapData.ID ? lapData : data
                ))
            } else {
                throw new Error(json.message || 'Unknown error')
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })     
    }

    const insertLapData = (lapData) => {
        return fetch('/api/lap/insert', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(lapData)
        })
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                setLapDatas(currentLapDatas => [...currentLapDatas, lapData])
            } else {
                throw new Error(json.message || 'Unknown error')
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })     
    }

    const updateSectorData = (sectorData) => {
        return fetch('/api/sector/update', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sectorData)
        })
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                setSectorDatas(currentSectorDatas => currentSectorDatas.map(data => 
                    data.ID === sectorData.ID ? sectorData : data
                ))
            } else {
                throw new Error(json.message || 'Unknown error')
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const insertSectorData = (sectorData) => {
        return fetch('/api/sector/insert', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sectorData)
        })
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                setSectorDatas(currentSectorDatas => [...currentSectorDatas, sectorData])
            } else {
                throw new Error(json.message || 'Unknown error')
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const getEvents = () => {
        fetch('/api/event/getAll')
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                let data = json.data
                const list = data.map(d => (
                    { id: d.ID, name: d.EVENT_NAME, circuit: d.CIRCUIT_ID, series: d.RACE_ID }
                ))

                list.sort((a, b) => b.id - a.id)
                setEventList(list)
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const getLapDataByRaceDetailId = (selectedRaceDetailId) => {
        fetch(`/api/lap/getLapDataByRaceDetailId/${selectedRaceDetailId}`)
        .then(response => response.json())
        .then(json => {
            if(json.status === 200){
                setLapDatas(json.data)
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    const getSectorDataByRaceDetailId = (selectedRaceDetailId) => {
        fetch(`/api/race/getAllSectorData/${selectedRaceDetailId}`)
        .then(response => response.json())
        .then(json => {
            if(json.status === 200){
                setSectorDatas(json.data)
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    

    const getRaceDetails = (selectedEventId) => {           
        fetch(`/api/raceDetail/getAllRacesByEventId/${parseInt(selectedEventId)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                let datas = json.data
                datas = datas.sort((a, b) => a.ID - b.ID)
                setRaceDetails(datas)
            }
        })
        .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        })
    }

    useEffect(() => {     
        getEvents()
    }, [])

    useEffect(() => {
        if (selectedEventId !== ''){
            getRaceDetails(selectedEventId)
        }
    },[selectedEventId])

    useEffect(() => {
        if (selectedRaceDetailId !== null && selectedDatabaseTableName === 'LAP_DATA'){
            setIsLoading(true)
            getLapDataByRaceDetailId(selectedRaceDetailId)
        } else if (selectedRaceDetailId !== null && selectedDatabaseTableName === 'SECTOR_DATA'){
            setIsLoading(true)
            getSectorDataByRaceDetailId(selectedRaceDetailId)
        }
    }, [selectedRaceDetailId, selectedDatabaseTableName])

    useEffect(() => {
        setIsLoading(false)
        if (selectedDatabaseTableName === 'LAP_DATA') {
            setDatabaseColumns(lapDatas.length !== 0 ? Object.keys(lapDatas[0]) : [])
            setTableDatas(lapDatas)
        } else if (selectedDatabaseTableName === 'SECTOR_DATA') {
            setDatabaseColumns(sectorDatas.length !== 0 ? Object.keys(sectorDatas[0]) : [])
            setTableDatas(sectorDatas)
        }
    }, [lapDatas, sectorDatas, selectedDatabaseTableName])


    useEffect(() => {
        if(selectedDatabaseTableName === 'LAP_DATA' && updateData.ID === undefined){
            insertLapData(updateData)
        } else if (selectedDatabaseTableName === 'LAP_DATA' && updateData.ID !== undefined){
            updateLapData(updateData)
        } else if(selectedDatabaseTableName === 'SECTOR_DATA' && updateData.ID === undefined){
            insertSectorData(updateData)
        } else if (selectedDatabaseTableName === 'SECTOR_DATA' && updateData.ID !== undefined){
            updateSectorData(updateData)
        }
    }, [updateData])
   
    return(
        <div style={{ margin: '0 20px', paddingBottom: '10px' }}>
            <div className='event-selected-top-text'>
                <Header />
                <button
                    style={{
                        backgroundColor:'#696969',
                        color: 'white',
                        margin: '0 5px 1px 0',
                        width: '200px',
                        height: '50px'
                    }}
                    onClick={() => {
                    navigate('/admin')
                    }}
                >
                    {t('general.managementPage')}
                </button>
            </div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab 
                            label="手動で編集" 
                            style={{
                                backgroundColor: tabValue === 0 ? '#4AFFFF' : 'lightgray', 
                                color: 'black'
                            }} 
                        />
                        <Tab 
                            label="バックアップから補完" 
                            style={{
                                backgroundColor: tabValue === 1 ? '#4AFFFF' : 'lightgray', 
                                color: 'black'
                            }} 
                        />
                        <Tab 
                            label="team timing detailを作成" 
                            style={{
                                backgroundColor: tabValue === 2 ? '#4AFFFF' : 'lightgray', 
                                color: 'black'
                            }} 
                        />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <EditDatabaseTemplate
                        databaseColumns={databaseColumns}
                        databaseLabel={databaseLabel}
                        eventLabel={eventLabel}
                        eventList={eventList}
                        handleDatabaseColumnSelectChange={handleDatabaseColumnSelectChange}
                        handleDatabaseLabelChange={handleDatabaseLabelChange}
                        handleEventLabelChange={handleEventLabelChange}
                        handleEventSelect={handleEventSelect}
                        handleRaceDetailIdChange={handleRaceDetailIdChange}
                        handleSelectedDatabaseTableChange={handleSelectedDatabaseTableChange}
                        isLoading={isLoading}
                        raceDetails={raceDetails}
                        selectedDatabaseColumnName={selectedDatabaseColumnName}
                        selectedDatabaseTableName={selectedDatabaseTableName}
                        selectedRaceDetailId={selectedRaceDetailId}

                        setUpdateData = {setUpdateData}
                        tableDatas={tableDatas}
                        updateData = {updateData}
                        
                    />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1}>
                    <ComplementTimingData/>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <CreatingTeamTimingDetail/>
                </CustomTabPanel>
            </Box>
        </div>
    )
}

export default EditDatabase
