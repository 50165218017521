import React, { useState, useEffect } from 'react'

import { useLiveData } from '../../DataContext'

import followImage from '../image/follow.png'

import { useTranslation } from 'react-i18next'

const PaceCatcher = (props) => {
    const { t } = useTranslation(['strategy'])

    const [futureLapData, setFutureLapData] = useState([])
    const [driverCount, setDriverCount] = useState()
    const [markDisplay, setMarkdisplay] = useState(false)

    const {tableData, raceSeries, followCarNumber, rivalCarNumbers} = useLiveData()

    const handleMarkClick = () => {
        setMarkdisplay(!markDisplay)
    }

    useEffect(() => {
        setDriverCount(tableData.length)
    }, [driverCount])

    useEffect(() => {
        if (tableData.length === 0) return

        let futureData = []
        let tempData = []
        tableData.forEach((driver) => {
            const colorObject = props.paceColors.find((color) => color.carno === driver.carno)
            const color = colorObject?.color || '#FFFFFF'

            const driverData = { 
                carno: driver.carno,
                pos: driver.pos,
                total: Number(driver.laptime + driver.gap),
                color: color,
                textColor: colorObject?.textColor,
                teamBgColor: driver.teamBgColor,
                teamTextColor: driver.teamTextColor,
                pit: driver.pit,
            }
            tempData.push(driverData)
        })
        futureData.push(tempData)

        // Calculate the position for each lap
        for (let lap = 1; lap <= 7; lap++) {
            let newTempData = []
            const lapData = tempData

            // Update the futureData array with the positions for the current lap
            lapData.forEach((driver) => {
                tableData.forEach((driverData) => {
                    const laptime = Number(driverData.laptime)
                    if (driver.carno === driverData.carno) {
                        const driverData = { 
                            carno: driver.carno,
                            pos: driver.pos,
                            total: driver.total + laptime,
                            color: driver.color,
                            textColor: driver.textColor,
                            teamBgColor: driver.teamBgColor,
                            teamTextColor: driver.teamTextColor,
                            pit: driver.pit,
                        }
                        newTempData.push(driverData)
                    }
                }) 
            })

            // Sort the data based on the difference between laptime and the laptime of the car in front
            newTempData.sort((a, b) => a.total - b.total)
            tempData = newTempData
            futureData.push(tempData)
        }
        setFutureLapData(futureData)
    }, [tableData])

    return (
        <div className='pace-catcher-area'>
            <div className='remarks-area'>
                <div className='switch-area'>
                    <div className='switch-upper'>
                    <p className='switch-area-text'>{t('paceCatcher.checkMarkedCar')}</p>
                    <label className='switch'>
                        <input className='switch-input' type='checkbox' onChange={handleMarkClick} checked={markDisplay} />
                        <span className='slider round'></span>
                    </label>
                    </div>
                    <ul className='switch-area-base'>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
                <div>
                    {markDisplay && <div className='pace-catcher-follow'>※<span className='example-box' ></span>{t('paceCatcher.markedCar')}</div>}
                    <div className='pace-catcher-pit'>※<span className='pit-example-box'></span>{t('paceCatcher.pitStopCount')}</div>
                </div>
            </div>

            <table className='pace-catcher-table'>
                <span className='side-contens contens-left'>◯◯◯</span>
                <span className='side-contens contens-right'>◯◯◯</span>
                
                <thead>
                    <tr className='pace-catcher-tr'>
                        <th>Position</th>
                        {Array.from({ length: 8 }, (_, i) => (
                            <th key = {i}>{i === 0 ? 'N' : `N + ${i}`}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {futureLapData[0] && futureLapData[0].map((driverData, index) => (
                            <tr key = {index}>
                                <td>
                                    {markDisplay && driverData.carno.replace('No.', '') === followCarNumber ? <span className='follow-mark'><img src={followImage}/></span> : null}
                                    {markDisplay && rivalCarNumbers.includes(driverData.carno.replace('No.', '')) ? <span className='rival-mark'>vs</span> : null}
                                    {driverData.pos}
                                </td>
                            {futureLapData.map((lapData, lapIndex) => ( 
                                <td key = {lapIndex} 
                                    style={{
                                        color: raceSeries === 'SF' ? lapData[index].teamTextColor || lapData[index].textColor : lapData[index].textColor,
                                        fontWeight: markDisplay ? (lapData[index].carno.replace('No.', '') === followCarNumber || rivalCarNumbers.includes(lapData[index].carno.replace('No.', ''))) ? '900' : '700' : '700', 
                                        backgroundColor: raceSeries === 'SF' ? lapData[index].teamBgColor|| lapData[index].color : lapData[index].color,
                                        border: markDisplay? lapData[index].carno.replace('No.', '') === followCarNumber ? '3px solid #bc1414' : '1px solid #ccc': '1px solid #ccc',  
                                        boxShadow: markDisplay? lapData[index].carno.replace('No.', '') === followCarNumber ? '0px 0px 15px 1px red': '0px 0px 0px 0px #000': '0px 0px 0px 0px #000', 
                                        opacity: markDisplay?  followCarNumber === lapData[index].carno.replace('No.', '') ||
                                        rivalCarNumbers.includes(lapData[index].carno.replace('No.', ''))? '1': '0.4': '1', 
                                    }}
                                >
                                    {lapData[index].carno.split('.')[1]} <span className='pit-info'>{lapData[index].pit}</span>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default PaceCatcher
