import * as ort from 'onnxruntime-web'

//BaseのObjectデータ作成
const makeCompareONNXobject = (df_DOEcondition, df_compareSetup) => {
    let length = df_compareSetup.columns.length
    let df = df_compareSetup.T
    let label_input = df.columns
    const Obj_compareSetup = {}

    for (let i = 0; i < df_DOEcondition.index.length; i++) {
        let judgeType = df_DOEcondition.iloc({'rows':[i], 'columns':[1]}).values[0][0]
        let tensor_input

        if (judgeType === '-') {
            tensor_input = new ort.Tensor('string', df[label_input[i]].values, [length, 1])
            Obj_compareSetup['x' + (i + 1)] = tensor_input
        } else {
            tensor_input = new ort.Tensor('float32', df[label_input[i]].values, [length, 1])
            Obj_compareSetup['x' + (i + 1)] = tensor_input
        }
    }
    return Obj_compareSetup
}

export default makeCompareONNXobject