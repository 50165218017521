import React, { useEffect, useState } from 'react'
import Plotly from 'react-plotly.js'

import search_columns from '../services/search_columns'

const PlotlyGraph = ({ df_scatHandling, df_scatAero, df_scatMech, df_scatRHFL, df_scatRHFR, df_scatRHRL, selectedX, selectedY, selectedColorChart }) => {
    const [ chartData, setChartData ] = useState([])
    const [layout, setLayout] = useState({})

    let Obj_plotly_data = {}
    let list_plotly_data = []

    function set_plotly_df(Obj, df_scat) {
        let Plot_x = search_columns(df_scat, selectedX)
        let Plot_y = search_columns(df_scat, selectedY)

        if (!(list_plotly_data.includes(Plot_x))) {
            Obj[Object.keys(Obj).length] = df_scat[Plot_x]?.values.filter(x => !isNaN(x))
            list_plotly_data[list_plotly_data.length] = Plot_x
        }
        if (!(list_plotly_data.includes(Plot_y))) {
            Obj[Object.keys(Obj).length] = df_scat[Plot_y]?.values.filter(x => !isNaN(x))
            list_plotly_data[list_plotly_data.length] = Plot_y
        }

        return Obj
    }

    useEffect(() => {
        let ColorScale_name = search_columns(df_scatHandling, selectedColorChart)

        if (!ColorScale_name) return

        let scat_color = df_scatHandling[ColorScale_name].values.filter(x => !isNaN(x))
    
        Obj_plotly_data = set_plotly_df(Obj_plotly_data, df_scatHandling)
        Obj_plotly_data = set_plotly_df(Obj_plotly_data, df_scatAero)
        Obj_plotly_data = set_plotly_df(Obj_plotly_data, df_scatMech)

        let df_to_dimensions = []

        for (let i = 0; i < Object.keys(Obj_plotly_data).length; i++) {
            let dimension_label=Object.keys(Obj_plotly_data)[i]
    
            if (typeof(Obj_plotly_data[dimension_label][0]) === 'number') {
                df_to_dimensions.push({
                    range: [Math.min(...Obj_plotly_data[dimension_label]), Math.max(...Obj_plotly_data[dimension_label])],
                    label: list_plotly_data[i],
                    tickvals: [Math.min(...Obj_plotly_data[dimension_label]).toFixed(2), Math.max(...Obj_plotly_data[dimension_label]).toFixed(2)],
                    values: Obj_plotly_data[dimension_label]
                })
            } else {
                let value_list = Obj_plotly_data[dimension_label]
                let ticktext_list = []
                let ticktext_value = []
                let dimension_values = []
    
                for (let j=0; j<value_list.length; j++) {
                    if (!(ticktext_list.includes(value_list[j]))) {
                        ticktext_list.push(value_list[j])
                        ticktext_value.push(ticktext_value.length)
                    }
                    for (let k=0; k<ticktext_list.length; k++) {
                        if (value_list[j]===ticktext_list[k]) {
                            dimension_values.push(ticktext_value[k])
                        }
                    }
                }
    
                df_to_dimensions.push({
                    label: dimension_label,
                    tickvals: ticktext_value,
                    ticktext: ticktext_list,
                    values: dimension_values
                })            
            }
        }

        setLayout({
            autosize: false,
            width: '100%',
            height: '100%',
            paper_bgcolor: 'transparent',
            plot_bgcolor: 'transparent',
            font: {
                color: '#fff'
            }
        })

        setChartData([{
            type: 'parcoords',
            line: {
                showscale: true,
                reversescale: true,
                colorscale: 'Jet',
                cmin: Math.min(...scat_color),
                cmax: Math.max(...scat_color),
                color: scat_color
            },
            dimensions: df_to_dimensions
        }])
    }, [selectedX, selectedY, selectedColorChart])

    return <Plotly data={chartData} layout={layout} config={{ displayModeBar: false, displayLogo: false }} />
}

export default PlotlyGraph