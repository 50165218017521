import { useEffect, useState } from 'react'

import TableDefinedByBodyRows from './TableDefinedByBodyRows'
import { useTranslation } from 'react-i18next'


const EditDatabaseModal = (props) => {
    const { t } = useTranslation(['strategy', 'general'])
    const headers = props.modalTableHeaders
    const middleRowIndex = Math.floor(props.modalTableDatas.length / 2)
    const [inputData, setInputData] = useState({})

    
    const handleInputChange = (event, rowIndex, header) => {
        const value = event.target.value
        if (rowIndex === middleRowIndex) {
            if (header === 'DRIVER_NAME' && value === 'other') {
                setInputData({ ...inputData, [header]: value, DRIVER_NAME_selected: true })
            } else {
                setInputData({ ...inputData, [header]: value })
            }
        }
    }

    const handleInputFocus = (header) => {
        if (header === 'DRIVER_NAME' && inputData[header] === 'other') {
            setInputData({ ...inputData, [header]: '' })
        }
    }

    const isAllRequiredFieldsFilled = () => {
        const allRequiredFields = ['LAP_NUMBER', 'TIME', 'RACE_DETAIL_ID', 'CAR_NUMBER', 'SECTOR_NUMBER', 'PIT_STOP_TIME']
        const requiredFields = allRequiredFields.filter(field => props.modalTableHeaders.includes(field))
        return requiredFields.every(field => 
            inputData[field] !== undefined &&
            inputData[field] !== '' )
    }

    const logInputData = () => {
        props.setIsUpdating(true)
        props.setUpdateData(inputData)
        props.setIsModalOpen(false)
    }

    const defineInputData = (modalTableDatas, modalTableHeaders, selectedValues) => {
        const newInputValues = {};
        if (modalTableDatas.length > 0) {
            const middleRowData = modalTableDatas[Math.floor(modalTableDatas.length / 2)];
            modalTableHeaders.forEach(header => {
                let value = middleRowData[header];
                if (!value) {
                    switch (header) {
                        case 'CAR_NUMBER':
                            value = selectedValues.selectedCarNumber;
                            break;
                        case 'LAP_NUMBER':
                            value = selectedValues.selectedLapNumber;
                            break;
                        case 'RACE_DETAIL_ID':
                            value = selectedValues.selectedRaceDetailId;
                            break;
                        case 'SECTOR_NUMBER':
                            value = selectedValues.selectedSectorNumber;
                            break;
                        case 'DRIVER_NAME':
                            if (selectedValues.selectedCarNumberDrivers.length > 0) {
                                value = selectedValues.selectedCarNumberDrivers[0];
                            }
                            break;
                        default:
                            break;
                    }
                }
                if (header === 'PIT_STOP_TIME' && value) {
                    value = parseFloat(value);
                }
                newInputValues[header] = value;
            });
        }
        return newInputValues;
    };

    const bodyRows = props.modalTableDatas.map((modalTableData, rowIndex) => {
        const cells = props.modalTableHeaders.map((header, headerIndex) => {
            const isSpecialCell = ['LAP_NUMBER', 'TIME', 'RACE_DETAIL_ID', 'CAR_NUMBER', 'SECTOR_NUMBER', 'DRIVER_NAME', 'PIT_STOP_TIME'].includes(header)
            const inputStyle = isSpecialCell ? { backgroundColor: '#fa6a6a', width: '100%', boxSizing: 'border-box' } : { width: '100%', boxSizing: 'border-box' }
            
            let initialValue = ''
            if (rowIndex > 0) {
                const previousRowData = props.modalTableDatas[rowIndex - 1]
                if (previousRowData !== undefined) {
                    initialValue = previousRowData[header] || ''
                }
            }
           
            if (header === 'DRIVER_NAME' && rowIndex === middleRowIndex) {
                const inputElement = inputData.DRIVER_NAME_selected ? (
                    <input
                        type='text'
                        value={inputData[header] || ''}
                        onChange={(event) => handleInputChange(event, rowIndex, header)}
                        onFocus={() => handleInputFocus(header)}
                        style={{...inputStyle, color: 'black'}}
                    />
                ) : (
                    <select
                        value={initialValue}
                        onChange={(event) => handleInputChange(event, rowIndex, header)}
                        style={{...inputStyle, color: 'black'}}
                    >
                        {props.selectedCarNumberDrivers.map((driver) => (
                            <option key={driver} value={driver}>{driver}</option>
                        ))}
                        <option value='other'>other</option>
                    </select>
                )
                return <td key={headerIndex}>{inputElement}</td>
            } else {
                const content = rowIndex === middleRowIndex ? (
                    <input
                        type='text'
                        value={inputData[header] !== undefined ? inputData[header] : ''}
                        onChange={(event) => handleInputChange(event, rowIndex, header)}
                        style={inputStyle}
                        disabled={header === 'ID'}
                    />
                ) : modalTableData[header]
                return <td key={headerIndex}>{content}</td>
            }
        })
    
        return <tr key={rowIndex}>{cells}</tr>
    })

    useEffect(() => {
        const selectedValues = {
            selectedCarNumber: props.selectedCarNumber,
            selectedLapNumber: props.selectedLapNumber,
            selectedRaceDetailId: props.selectedRaceDetailId,
            selectedSectorNumber: props.selectedSectorNumber,
            selectedCarNumberDrivers: props.selectedCarNumberDrivers
        };
        const newInputValues = defineInputData(props.modalTableDatas, props.modalTableHeaders, selectedValues)
        setInputData(newInputValues)
    }, [props.modalTableDatas, props.modalTableHeaders, props.selectedCarNumber, props.selectedLapNumber, props.selectedRaceDetailId, props.selectedCarNumberDrivers])

    if (!props.isModalOpen) return null
    return (
        <div style={modalStyle}>
            <div style={{textAlign: 'right', paddingRight: '20px', marginBottom: '10px'}}>
                <span style={{backgroundColor: '#fa6a6a', color: 'black', padding: '5px', borderRadius: '5px', fontSize: '12px'}}>
                {t('general.RedBackgroundRequiredFields')}
                </span>
            </div>
            <TableDefinedByBodyRows 
                headers={headers} 
                bodyRows={bodyRows} 
            />
            <div style={{textAlign: 'center', marginTop: '20px'}}>
                <button onClick={logInputData} disabled={!isAllRequiredFieldsFilled()} style={buttonStyle}>Update</button>
                <button onClick={() => props.setIsModalOpen(false)} style={buttonStyle}>Close</button>
            </div>
        </div>
        
    )
}

export default EditDatabaseModal

const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    color: '#242424',
    padding: '20px',
    zIndex: 1000,
    overflowX: 'hidden', 
    maxHeight: '90vh',
    maxWidth: '90vw',
    boxSizing: 'border-box'
}

const buttonStyle = {
    margin: '10px', 
    padding: '10px 15px', 
    border: '1px solid #ddd', 
    borderRadius: '4px', 
    cursor: 'pointer',
    backgroundColor: '#f5f5f5', 
    outline: 'none',
}