const TrigerButton = (props) => {
    const handleTrigerButtonClick = () => {
        props.handleTrigerButtonClick()
    }

    return (
        <div>
            <button
                style={{
                    backgroundColor: '#696969',
                    color: 'white',
                    margin: '5px 5px 1px 0',
                    width: '200px',
                    height: '50px',
            }}
                onClick={handleTrigerButtonClick}
            >
                {props.buttonLabel}
            </button>
        </div>
    )
}

export default TrigerButton