import { useState } from 'react'

const CreatingTeamTimingDetail = () => {
    const [raceDetailId, setRaceDetailId] = useState("");
    const [raceSeasonId, setRaceSeasonId] = useState("");
    const [targetCarNumber, setTargetCarNumber] = useState("");
    
    const onClickCreatOrUpdateButton = () => {
        fetch('api/teamTimingDetail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                raceDetailId,
                raceSeasonId,
                targetCarNumber
            })
        })
        .then(response => response.json())
        .then(json => {
            alert(json.data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    return ( 
        <div
            style={{margin: "20px 0 0 0"}}
        >
            <div>
                <label>raceDetailId</label>
                <input type="text" value={raceDetailId} onChange={e => setRaceDetailId(e.target.value)} placeholder="Race Detail ID" />
                <label>raceSeasonId</label>
                <input type="text" value={raceSeasonId} onChange={e => setRaceSeasonId(e.target.value)} placeholder="Race Season ID" />
                <label>targetCarNumber</label>
                <input type="text" value={targetCarNumber} onChange={e => setTargetCarNumber(e.target.value)} placeholder="Target Car Number" />
                <button style={{backgroundColor: 'dimgray', color: 'white'}} onClick={() => onClickCreatOrUpdateButton()}>実行</button>
            </div>
        </div>
    )
}

export default CreatingTeamTimingDetail