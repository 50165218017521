import * as dfd from 'danfojs'

import { DataFrame } from 'danfojs'

//BaseのDataframeデータ作成
const makeBaseDataframe = (df) => {
    let label_input = df.index
    let judge_input = df.iloc({columns:[0]}).values

    const Obj_base = {}

    for (let i = 0; i < label_input.length; i++) {   
        if (judge_input[i][0] === '-' || judge_input[i][0] === 'env' || judge_input[i][0] === 'ON') {      
            Obj_base[label_input[i]] = df.iloc({rows:[i]}).values[0]
        } else {
            Obj_base[label_input[i]] = Obj_base[judge_input[i][0]]
        }
    }

    let df_ans = new DataFrame(Obj_base)
    df_ans.setIndex({index: df.columns, inplace: true})
    df_ans = df_ans.T

    return df_ans
}

export default makeBaseDataframe