import { useEffect, useState } from 'react'

import MileageTyreSheetHeader from '../components/MileageTyreSheetHeader'
import MileagePressureTargetsTable from '../components/MileagePressureTargetsTable'
import MileageInfoTable from '../components/MileageInfoTable'

import CircularProgress from '@mui/material/CircularProgress'

import { useTranslation } from 'react-i18next'

const MileageManagement = ({eventId, selectedRaceDetailId, selectedCarNumber, userId, raceDetails}) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [tyreSheetHeaderTable, setTyreSheetHeaderTable] = useState({})
    const [pressureTargets, setPressureTargets] = useState({})
    const [displayMileagePressureTargetsTable, setDisplayMileagePressureTargetsTable] = useState(false)
    const [displayMileageInfoTable, setDisplayMileageInfoTable] = useState(false)
    const [resetTable, setResetTable] = useState(false)
    const [emptyTableValue, setEmptyTableValue] = useState(false)

    const [loading, setLoading] = useState(false)

    const [tyreSheetInfo, setTyreSheetInfo] = useState([])
    const [prevTyreSheetInfo, setPrevTyreSheetInfo] = useState([])

    useEffect(() => {
        setDisplayMileagePressureTargetsTable(false)
        setDisplayMileageInfoTable(false)

        const fetchData = async () => {
            if (!eventId || !selectedRaceDetailId || !selectedCarNumber) {
                setResetTable(true)
                return
            }
    
            if (eventId && selectedRaceDetailId && selectedCarNumber) {
                setLoading(true)
                setDisplayMileagePressureTargetsTable(true)
                setDisplayMileageInfoTable(true)
                setEmptyTableValue(true)
                emptyField()
    
                try {
                    const result = await getExistingResult(eventId, selectedRaceDetailId, selectedCarNumber, userId)
    
                    if (result.length > 0) {
                        setTyreSheetInfo(result)
                    }

                    let raceDetailId = selectedRaceDetailId
                    while (true) {
                        raceDetailId = getPreviousRaceDetailId(raceDetails, raceDetailId)
                        if (!raceDetailId) break

                        const prevResult = await getExistingResult(eventId, raceDetailId, selectedCarNumber, userId)
                        
                        if (prevResult.length > 0) {
                            setPrevTyreSheetInfo(prevResult)
                            break
                        }
                    }
    
                } catch (error) {
                    alert(t('pleaseContactAdmin', {ns: 'general'}))
                } finally {
                    setLoading(false)
                }
            }
        }
    
        fetchData()
    }, [eventId, selectedRaceDetailId, selectedCarNumber])

    const emptyField = () => {
        setPrevTyreSheetInfo([])
        setTyreSheetInfo([])

        document.getElementById('engineerTB').value = ''
        document.getElementById('no1MechanicTB').value = ''
        document.getElementById('chassisTB').value = ''
    }
    
   
    useEffect(() => {
        if (Object.keys(pressureTargets).length === 22) {
            setDisplayMileageInfoTable(true)
        }
    }, [pressureTargets])


    useEffect(() => {
        if (resetTable) {
            setResetTable(false)
            setDisplayMileagePressureTargetsTable(false)
            setDisplayMileageInfoTable(false)
        }
    }, [resetTable])

    return (
        <>
            <h2 style={{color: 'red'}}>{t('mileageManagement.creationBug')}</h2>
            <h3 style={{color: 'red'}}>{t('mileageManagement.inputPossible')}</h3>
            <div style={{ position: 'relative'}}>
                <MileageTyreSheetHeader
                    setTyreSheetHeaderTable={setTyreSheetHeaderTable}
                    tyreSheetInfo={tyreSheetInfo}
                    eventId = {eventId}
                    selectedRaceDetailId = {selectedRaceDetailId}
                    selectedCarNumber = {selectedCarNumber}
                />
                <br></br>
                {
                    displayMileagePressureTargetsTable ?
                    <MileagePressureTargetsTable
                        setPressureTargets={setPressureTargets}
                        tyreSheetInfo={tyreSheetInfo}
                        emptyTableValue={emptyTableValue}
                    />
                    : <></>
                }
                <br></br>
                {
                    displayMileageInfoTable ?
                    <MileageInfoTable
                        userId={userId}
                        tyreSheetHeaderTable={tyreSheetHeaderTable}
                        pressureTargets={pressureTargets}
                        tyreSheetInfo={tyreSheetInfo}
                        prevTyreSheetInfo={prevTyreSheetInfo}
                    />
                    : <></>
                }
                {
                    loading &&
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        zIndex: 1000}}
                    >
                        <CircularProgress />
                    </div>
                }
            </div>
        </>
    )
}

export default MileageManagement

function getExistingResult(eventId, raceDetailId, carNumber, userId) {
    return new Promise ((resolve, reject) => {
        fetch(`/api/carTireHistory/getExistingResult/${eventId}/${raceDetailId}/${carNumber}/${userId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function getPreviousRaceDetailId(raceDetails, currentId) {
    const currentIndex = raceDetails.findIndex(detail => detail.ID === currentId);
    if (currentIndex > 0) {
        const previousObject = raceDetails[currentIndex - 1]
        return previousObject.ID
    } else if (currentIndex === 0) {
        return null
    } else {
        return null
    }
}