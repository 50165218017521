import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Header from '../components/Header'
import { useTranslation } from 'react-i18next'

import '../css/eventPage.css'
import '../css/lightMode.css'
import light from '../image/light.svg'
import dark from '../image/dark.svg'


import backBtn from '../image/allow-right-sp.png'

const Event = () => {
  const { t } = useTranslation(['strategy', 'general'])

  const [originalEventList, setOriginalEventList] = useState([])
  const [eventList, setEventList] = useState([])
  const [event, setEvent] = useState('')

  const [raceList, setRaceList] = useState([])

  const [circuitList, setCircuitList] = useState([])
  const [raceSeries, setRaceSeries] = useState([])

  const [circuitFilter, setCircuitFilter] = useState('all')
  const [raceSeriesFilter, setRaceSeriesFilter] = useState('all')

  const [openMenu, setOpenMenu] = useState(true)

  const [isLightMode, setIsLightMode] = useState(false)


  const navigate = useNavigate()

  const { eventId } = useParams()

  // sleep 関数の定義
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    const getSeries = () => {
      fetch('/api/race/getAll')
        .then(response => response.json())
        .then(json => {
          if (json.status === 200) {
            let data = json.data
            const list = data.map((d) => (
              { id: d.ID, name: d.NAME, displayName: d.DISPLAY_NAME }
            ))

            setRaceSeries(list)
          }
        })
        .catch((error) => {
          alert(t('pleaseContactAdmin', { ns: 'general' }))
        })
    }

    const getCircuits = () => {
      fetch('/api/circuit/getAll')
        .then(response => response.json())
        .then(json => {
          if (json.status === 200) {
            const list = json.data.map(d => (
              { id: d.ID, name: d.NAME, sectorLength: d.SECTOR_LENGTH }
            ))

            setCircuitList(list)
          }
        })
        .catch((error) => {
          alert(t('pleaseContactAdmin', { ns: 'general' }))
        })
    }

    const getEvents = () => {
      fetch('/api/event/getAll')
        .then(response => response.json())
        .then(json => {
          if (json.status === 200) {
            let data = json.data
            const list = data.map(d => (
              { id: d.ID, name: d.EVENT_NAME, circuit: d.CIRCUIT_ID, series: d.RACE_ID }
            ))

            list.sort((a, b) => b.id - a.id)
            setOriginalEventList(list)
            setEventList(list)
          }
        })
        .catch((error) => {
          alert(t('pleaseContactAdmin', { ns: 'general' }))
        })
    }

    getSeries()
    getCircuits()
    getEvents()
  }, [])

  const toggle = document.getElementById('toggle');
  const label = document.getElementById('toggle-label');

  const handleToggleChange = () => {
      document.body.classList.toggle('light-mode');
      /*if (toggle.checked) {
          label.textContent = 'Dark Mode';
      } else {
          label.textContent = 'Light Mode';
      }*/

      setIsLightMode(prevState => !prevState);
  }

  useEffect(() => {
    const fetchEventDetails = async () => {
      // 遅延を設けるために sleep 関数を使用
      await sleep(200); // 0.5秒間の遅延
      if (eventId) {
        const selectedEvent = eventList.find(e => e.id === parseInt(eventId));
        if (selectedEvent) {
          setEvent(selectedEvent);
          setOpenMenu(false);
        }
      }
    };
    fetchEventDetails();
  }, [eventId, eventList]);

  useEffect(() => {
    const getRaces = () => {
      fetch(`/api/raceDetail/getAllRacesByEventId/${event.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(json => {
          if (json.status === 200) {
            let data = json.data
            data = data.sort((a, b) => a.ID - b.ID)
            data.forEach(race => {
              if(race.RACE_START_TIME !== undefined) {
                const raceStartTime = new Date(race.RACE_START_TIME)
                const hours = raceStartTime.getUTCHours()
                const minutes = raceStartTime.getMinutes()
                const formattedHours = String(hours).padStart(2, '0')
                const formattedMinutes = String(minutes).padStart(2, '0')
                
                race.START_TIME = `${formattedHours}:${formattedMinutes}`
              }
              if(race.RACE_END_TIME !== undefined) {
                const raceEndTime = new Date(race.RACE_END_TIME)
                const hours = raceEndTime.getUTCHours()
                const minutes = raceEndTime.getMinutes()
                const formattedHours = String(hours).padStart(2, '0')
                const formattedMinutes = String(minutes).padStart(2, '0')

                const year = raceEndTime.getUTCFullYear()
                const month = (raceEndTime.getUTCMonth() + 1).toString().padStart(2, '0')
                const day = raceEndTime.getUTCDate().toString().padStart(2, '0')
                
                race.END_DATE = `${year}-${month}-${day}`
                race.END_TIME = `${formattedHours}:${formattedMinutes}`
              }
            })
            setRaceList(data)
          }
        })
        .catch((error) => {
          alert(t('pleaseContactAdmin', { ns: 'general' }))
        })
    }

    if (event !== '') {
      getRaces()
    }

  }, [event])

  const raceSelectorHandler = (index) => {
    const selectedEvent = eventList[index]
    setEvent(selectedEvent)
    setOpenMenu(false)
    setEventList(originalEventList)
    navigate(`/event/${selectedEvent.id}`)
  }

  const circuitFilterHandler = (e) => {
    const filter = e.target.value
    setCircuitFilter(filter)

    const circuitFilteredList = filter === 'all' ? originalEventList : originalEventList.filter(event => event.circuit === parseInt(filter))
    const filteredList = raceSeriesFilter === 'all' ? circuitFilteredList : circuitFilteredList.filter(event => event.series === parseInt(raceSeriesFilter))

    setEventList(filteredList)
  }

  const raceSeriesFilterHandler = (e) => {
    const filter = e.target.value
    setRaceSeriesFilter(filter)

    const raceSeriesFilteredList = filter === 'all' ? originalEventList : originalEventList.filter(event => event.series === parseInt(filter))
    const filteredList = circuitFilter === 'all' ? raceSeriesFilteredList : raceSeriesFilteredList.filter(event => event.circuit === parseInt(circuitFilter))

    setEventList(filteredList)
  }

  const raceButtonViewHandler = (e) => {
    const raceId = e.target.value
    const raceType = raceList.find(r => r.ID === parseInt(raceId)).RACE_TYPE
    navigate(`/live?${raceType}/${raceId}`)
  }

  const formatRaceDateTime = (raceDate, startTime, endTime, raceName) => {
    const raceDateTime = new Date(`${raceDate.split('T')[0]}T${startTime}Z`); // UTCを指定
    const startTimeDate = new Date(`${raceDate.split('T')[0]}T${startTime}Z`); // UTCを指定
    const endTimeDate = new Date(`${raceDate.split('T')[0]}T${endTime}Z`); // UTCを指定
  
    const formattedRaceDate = raceDateTime.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC' // UTCを指定
    });
    const formattedStartTime = startTimeDate.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC' // UTCを指定
    });
    const formattedEndTime = endTimeDate.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC' // UTCを指定
    });
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return (
      <>
        <div className='event-time'>
            <span>{formattedRaceDate}{window.innerWidth <= 743 ? '' : ': '} </span> 
            <span>{formattedStartTime} - {formattedEndTime}</span>
        </div>
        <div>
          {userTimeZone}
        </div>
        <div>
          {raceName}
        </div>
      </>
    )
  }

  const getDisplayNameIndex = (displayName) => {
    switch (displayName) {
      case 'スーパーフォーミュラ':
        return t('event.displayName1')
      case 'スーパー耐久':
        return t('event.displayName2')
      case 'super endurance':
        return t('event.displayName3')
      default:
        return -1
    }
  }

  return (
    <>
      <Header className='header-custom' />
      <div className='event-choice-container'>
        {openMenu &&
          <>
            <div className='event-top-text'>
              {/* <h1>イベント</h1> */}
            </div>
            <div className='event-filter'>
              <div>
                <select name='circuitFilter' onChange={circuitFilterHandler}>
                  <option value='all'>{t('event.allCircuits')}</option>
                  {circuitList.map((c, index) => (
                    <option key={index} value={c.id}>{c.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <select name='raceSeriesFilter' onChange={raceSeriesFilterHandler}>
                  <option value='all'>{t('event.allSeries')}</option>
                  {raceSeries.map((r, index) => (
                    <option key={index} value={r.id}>{getDisplayNameIndex(r.displayName)}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className='event-choice event-choice-tit'>
              <div className='event-choice-name event-choice-tit-name'>
                {t('general.eventName')}
              </div>
              <div className='event-choice-circuit-name event-choice-tit-name'>
                {t('general.circuit')}
              </div>
              <div className='event-choice-series-name event-choice-tit-name'>
                {t('general.series')}
              </div>
              <div className='dummy-text'>
              </div>
            </div>
            {eventList.map((e, index) => (
              <div key={index} className='event-choice event-after-item-choice'>
                <div className='sp-raceinfo-block'>
                <span className='sp-word event-choice-tit-name'> {t('general.eventName')}</span>
                  <div className='event-choice-name'>
                    {e.name}
                  </div>
                  <span className='sp-word event-choice-tit-name'> {t('general.circuit')}</span>
                  <div className='event-choice-circuit-name'>
                    {circuitList.find(c => c.id === e.circuit)?.name}
                  </div>
                  <span className='sp-word event-choice-tit-name'> {t('general.series')}</span>
                  <div className='event-choice-series-name'>
                    {raceSeries.find(r => r.id === e.series) && (
                      <>
                        <div>{getDisplayNameIndex(raceSeries.find(r => r.id === e.series)?.displayName)}</div>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <button className='event-button' onClick={() => raceSelectorHandler(index)}>{t('event.select')}</button>
                </div>
                <span className='top-page-dec-top-right'></span>
                <span className='top-page-dec-bottom-right'></span>
              </div>
            ))}
          </>
        }
        {!openMenu &&
          <>
            <div className='event-selected-top-text'>
              <div className='event-tit-container'>
                <h1>{event.name}</h1>
              </div>
              <div className='header-menu'>
                {/* <div className='eventPage-btn-container'>
                                <button  onClick={() => navigate(`/event/${eventId}/fuel-and-tyre-management`)}>燃料・タイヤマイレージ管理</button>
                                <button  onClick={() => navigate(`/event/${eventId}/simulation`)}>ピットイン戦略シミュレーション</button>
                            </div> */}

                  <div className='light-mode-switch-Box'>
                        <div className='dark'><img src= { dark }></img></div>
                        <label className='switch light-mode-switch'>
                            <input 
                            type='checkbox' 
                            id='toggle' 
                            class='toggle-input'  
                            onChange={handleToggleChange} />
                            <span className='slider round'></span>
                        </label>
                        <div className='light'><img src= { light }></img></div>
                </div>

                <button className='event-list-button' onClick={() => {
                  window.location.href = "/event"
                }}
                >
                  <span>{t('event.eventList')}</span>
                  <span className='border-line'></span>
                  <span>all events</span>
                  <img className='backBtn-img' src={backBtn} alt='イベント一覧に戻る' />
                </button>
              </div>
            </div>
            <div className='eventPage-btn-container'>
              <button onClick={() => navigate(`/event/${eventId}/fuel-and-tyre-management`)}>{t('event.fuelAndTyreMileageManagement')}</button>
              <button onClick={() => navigate(`/event/${eventId}/simulation`)}>{t('event.pitStopStrategySimulation')}</button>
              <button onClick={() => navigate(`/event/${eventId}/tire-pressure-prediction`)}>{t('event.tyreinnerpressureprediction')}</button>
            </div>
            <div className=''>
              <div className='event-race-menu'>
                {raceList.length >= 1 ? (
                  raceList.map((race, index) => (
                    <div className='event-race' key={index}>
                      <div className='race-date'>
                        {formatRaceDateTime(race.RACE_DATE, race.START_TIME, race.END_TIME, race.RACE_DETAIL_NAME)}
                      </div>
                      <div className='event-race-buttons'>
                        {
                          race.RACE_TYPE === 'fp' ?
                            <button className='event-race-button plan-button'
                              disabled={true}
                              onClick={() => console.log()}
                            >
                              Plan
                            </button>
                            : race.RACE_TYPE === 'qf' ?
                              <button className='event-race-button plan-button'
                                disabled={true}
                                onClick={() => navigate(`/event/${eventId}/qf-plan/${race.ID}`)}
                              >
                                Plan
                              </button>
                              : race.RACE_TYPE === 'race' ?
                                <button className='event-race-button plan-button'
                                  disabled={true}
                                  onClick={() => console.log()}
                                >
                                  Plan
                                </button>
                                :
                                <button className='event-race-button plan-button'
                                  value={race.ID}
                                  disabled={true}
                                  onClick={() => console.log()}
                                >
                                  Plan
                                </button>
                        }
                        <button className='event-race-button view-button' value={race.ID} onClick={raceButtonViewHandler}>View</button>
                        {
                          race.RACE_TYPE === 'fp' ?
                            <button className='event-race-button analysis-button'
                              value={race.ID}
                              onClick={() => navigate(`/event/${eventId}/fp-analysis/${race.ID}`)}
                            >
                              Analysis
                            </button>
                            : race.RACE_TYPE === 'qf' ?
                              <button className='event-race-button analysis-button'
                                value={race.ID}
                                onClick={() => navigate(`/event/${eventId}/qf-analysis/${race.ID}`)}
                              >
                                Analysis
                              </button>
                              : race.RACE_TYPE === 'race' ?
                                <button className='event-race-button analysis-button'
                                  value={race.ID}
                                  onClick={() => navigate(`/event/${eventId}/race-analysis/${race.ID}`)}
                                >
                                  Analysis
                                </button>
                                :
                                <button className='event-race-button analysis-button'
                                  value={race.ID}
                                  disabled={true}
                                  onClick={() => console.log()}
                                >
                                  Analysis
                                </button>
                        }
                      </div>
                    </div>
                  )))
                  :
                  <div className='event-race'>{t('general.raceNotRegistered')}</div>
                }
              </div>
            </div>
          </>
        }
      </div>

    </>

  )
}
export default Event