import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { TableContainer, Paper, Table, TableRow, TableBody } from '@mui/material'
import { StyledTableCell } from './TableStyle'
import { useState } from 'react'

import PaceCatcher from './PaceCatcher'
import LapHistory from './LapHistory'
import PersonalDriverHistory from './PersonalDriverHistory'
import BeforeAfterPitAvg from './BeforeAfterPitAvg'
import AddNewEvent from './AddNewEvent'
import EditEvent from './EditEvent'
import LapTimeChart from './LapTimeChart'
import StintHistory from './StintHistory'
import SettingView from './SettingView'
import ReportDownloadView from './ReportDownloadView'
import ClassColorDisplay from './CarClass'

import tableOff  from  '../image/table-off.png'
import tableOn  from  '../image/table-on.png'

import chartOff  from  '../image/chart-off.png'
import chartOn  from  '../image/chart-on.png'

import { useTranslation } from 'react-i18next'


const ModalComponent = (props) => {
    const { t } = useTranslation(['strategy'])

    const modalType = props.modalType
    const carClasses = props.carClasses
    const [radioOption, setRadioOption] = useState('none')
    const [view, setView] = useState('position')
    const [order, setOrder] = useState('descending')
    const [moreLapData, setMoreLapData] = useState(false)
    const [classView, setClassView] = useState(false)

    const handleCloseModal = () => {
        props.onClose()
        props.setOpenModal(false)
    }

    const handleRadioChange = (e) => {
        setRadioOption(e.target.value)
    }

    const handleViewChange = (e) => {
        setView(e.target.value)
    }

    const handleOrderChange = (e) => {
        setOrder(e.target.value)
    }

    const handleMoreLapData = () => {
        setMoreLapData(!moreLapData)
    }

    const getModalTitle = () => {
        let title = 'Not Found'

        if (modalType === 'alldriverlaps') {
            title = t('modalComponent.allLapsTable')
        } else if (modalType === 'paceCatcher') {
            title = t('modalComponent.paceCatcher')
        } else if (modalType === 'personalDriverHistory' || modalType === 'beforeAfterPitAvg') {
            title = t('modalComponent.pitStopDetail')
        } else if(modalType === 'addNewEvent') {
            title = t('modalComponent.addEvent')
        } else if (modalType === 'editEvent') {
            title = t('modalComponent.editEvent')
        } else if (modalType === 'stintHistory') {
            title = t('modalComponent.stintHistory')
        } else if (modalType === 'settingView') {
            title = t('general.variousSettings')
        } else if (modalType === 'reportDownloadView') {
            title = t('modalComponent.downloadReport')
        }
        return title
    }

    const getModalContent = () => {
        if (modalType === 'alldriverlaps'){
            if (radioOption === 'graph') {
                return <LapTimeChart moreLapData={moreLapData}/>
            } else {
                return <LapHistory carClasses= { carClasses } radioOption={ radioOption } view={ view } order={ order } moreLapData={moreLapData}/>
            }
        } else if (modalType === 'paceCatcher'){
            return <PaceCatcher paceColors = { props.paceColors }/>
        } else if (modalType === 'personalDriverHistory') {
            return <PersonalDriverHistory driverInfo = { props.driverInfo }/>
        } else if (modalType === 'beforeAfterPitAvg') {
            return <BeforeAfterPitAvg driverInfo = { props.driverInfo } groupedData = { props.groupedData }/>
        } else if (modalType === 'addNewEvent') {
            return <AddNewEvent 
                        onEventAdded = { props.onEventAdded } raceSeries = { props.raceSeries } 
                        circuitList = { props.circuitList } raceId = { props.raceId } circuit = { props.circuit }
                    />
        } else if (modalType === 'editEvent') {
            return <EditEvent 
                        eventList = { props.eventList } eventListData = { props.eventListData } setEditEventStatus = { props.setEditEventStatus }
                        updateEventList = { props.updateEventList } raceSeries = { props.raceSeries } circuitList = { props.circuitList }
                    />
        } else if (modalType === 'stintHistory'){
            return <StintHistory/>
        } else if (modalType === 'settingView'){
            return <SettingView 
                        pitStopTimeByRefueling = { props.pitStopTimeByRefueling } 
                        setPitStopTimeByRefueling ={ props.setPitStopTimeByRefueling}
                        pitStopTimeByWork = { props.pitStopTimeByWork }
                        setPitStopTimeByWork = { props.setPitStopTimeByWork}
                        setPitStopTimeSettingStatus = { props.setPitStopTimeSettingStatus}
                    />
        } else if (modalType === 'reportDownloadView') {
            return <ReportDownloadView numOfLaps = { props.numOfLaps} />
        }
    }

    const paperProps={
        sx: {
            width: '100%',
            maxWidth: window.innerWidth < 1024 ? 'none' : '1200px',
        },
    }

    const dialogContentStyle={
        width: 'auto'
    }

    if (modalType === 'alldriverlaps') {
        paperProps.sx={
            width: '100%',
            maxWidth: '1800px',
        }
        // dialogContentStyle.width = '100%'
    }

    const tableImage =
    radioOption === 'allLaps' || radioOption === 'lap' || radioOption === 'driver' || radioOption === 'none' ? (
        <div className='icon-underbar'>
            <img src={tableOff} alt={t('general.table')} />
        </div>
    ) : (
            <img src={tableOn} alt={t('general.table')} />
    );

    const graphImage =
    radioOption === 'graph' ? (
        <div className='icon-underbar'>
        <img src={chartOn} alt={t('general.chart')} />
        </div>
    ) : (
        <img src={chartOff} alt={t('general.chart')} />
    );
    
    return (
        <Dialog open={ props.open } onClose={ handleCloseModal } PaperProps={ paperProps }>
            <div className='top-dialog'>
            <div className={`top-dialog-background ${modalType === 'editEvent' ? 'edit-event-tit' : ''}`}>
                <span className='dli-plus' style={{ display: modalType === 'editEvent' ? 'inline-block' : 'none' }}></span>
                <span className='dli-plus' style={{ display: modalType === 'editEvent' ? 'inline-block' : 'none' }}></span>
                <span className='dli-plus' style={{ display: modalType === 'editEvent' ? 'inline-block' : 'none' }}></span>
                <span className='dli-plus' style={{ display: modalType === 'editEvent' ? 'inline-block' : 'none' }}></span>
                    <DialogTitle>{ getModalTitle() }</DialogTitle>
                    <div onClick={ handleCloseModal } className='modal-exit'>✕</div>
            </div>
            </div>

            <DialogContent className='custom-modal' style={dialogContentStyle}>
            { modalType === 'alldriverlaps' ? 
                        <div className='alldriverlaps-main-container'>
                            <div className='container'>
                                <div className='header-options'>
                                    <div className='title inlineFlex'>{t('modalComponent.heatMap')}:　</div>
                                    <div className='inlineFlex all-laps-all-options'>
                                        <div className='all-laps-options'>
                                            <input type='radio' id='byAllLaps'className='visually-hidden' name='allLaps' value='allLaps' onChange={ handleRadioChange } checked={radioOption === 'allLaps'}/>
                                            <label htmlFor = 'byAllLaps'>{t('general.all')}</label>
                                        </div>
                                        <div className='all-laps-options'>
                                            <input type='radio' id='byLap' className='visually-hidden' name='allLaps' value='lap' onChange={ handleRadioChange } checked={radioOption === 'lap'}/>
                                            <label htmlFor='byLap'>{t('modalComponent.byLap')}</label>
                                        </div>
                                        <div className='all-laps-options'>
                                            <input type='radio' id='byCar' className='visually-hidden' name='allLaps' value='driver' onChange={ handleRadioChange } checked={radioOption === 'driver'}/>
                                            <label htmlFor='byCar'>{t('modalComponent.byCar')}</label>    
                                        </div>
                                        <div className='all-laps-options'>
                                            <input type='radio' id='none' className='visually-hidden' name='allLaps' value='none' onChange={ handleRadioChange } checked={radioOption === 'none'}/>
                                            <label htmlFor='none'>{t('modalComponent.none')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='lap-history-container'>
                                    <div className='lap-history-upper'>
                                        <div className='inlineFlex'>{t('modalComponent.column')}:　</div>
                                        <div className='inlineFlex all-laps-all-options lap-history-select-boxs'>
                                            <select className='lap-history-select' onChange={ handleViewChange } defaultValue={ view }>
                                                <option value='followRival'>{t('modalComponent.leftFromMarkedCar')}</option>
                                                <option value='car'>{t('modalComponent.sortByCarNumber')}</option>
                                                <option value='position'>{t('modalComponent.sortByPosition')}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='lap-history-upper '>
                                        <div className='inlineFlex'>{t('modalComponent.row')}:　</div>
                                        <div className='inlineFlex all-laps-all-options lap-history-select-boxs'>
                                            <select className='lap-history-select' onChange={ handleOrderChange } defaultValue={ order }>
                                                <option value='descending'>{t('modalComponent.descending')}</option>
                                                <option value='ascending'>{t('modalComponent.ascending')}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            { props.raceSeries === 'SE'  &&
                                <div className='all-driver-header-container'>
                                    { classView && 
                                            <div className='lap-history-change-view-container'>
                                                    <div className='change-view-middle'>
                                                        <TableContainer size='small'>
                                                            <Table sx={{ minWidth: 250, color: '#fff' }} aria-label='customized table'>
                                                                <TableBody >
                                                                <TableRow key='class-all-option' style={{ backgroundColor: '#535353'}}>
                                                                    <StyledTableCell style={{ color: '#fff', fontWeight: '400', textAlign: 'left', paddingLeft: '12%' }}>
                                                                        All
                                                                    </StyledTableCell>
                                                                    <StyledTableCell>
                                                                        <label className='switch'>
                                                                            <input
                                                                                type='checkbox'
                                                                                className='switch-input'
                                                                                value='all'
                                                                                checked={carClasses?.every(column => column.isVisible === true)}
                                                                                onChange={props.handleCarClassCheckboxChange}
                                                                            />
                                                                            <span className='slider round'></span>
                                                                        </label>
                                                                    </StyledTableCell>
                                                                </TableRow>
                                                                    {carClasses?.map(column => {
                                                                        return (
                                                                            <TableRow key={column.class} style={{ backgroundColor: '#535353'}}>
                                                                                <StyledTableCell style={{ color: '#fff', fontWeight: '400', textAlign: 'left', paddingLeft: '12%' }}>
                                                                                    { ClassColorDisplay(column.class, 'header') }
                                                                                </StyledTableCell>
                                                                                <StyledTableCell>
                                                                                    <label className='switch'>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            className='switch-input'
                                                                                            value={column.class}
                                                                                            checked={column.isVisible}
                                                                                            onChange={props.handleCarClassCheckboxChange}
                                                                                        />
                                                                                        <span className='slider round'></span>
                                                                                    </label>
                                                                                </StyledTableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                <div className='lap-history-change-view-bottom'>
                                                    <div onClick={() => setClassView(false)} className='lap-history-change-view-close-button'>{t('general.close')}</div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            }
                            <div className='alldriverlaps-main-container-right'>
                                <div className='all-driver-header-container'>
                                    <button className='all-driver-header-button' disabled={ props.raceSeries !== 'SE'} onClick={() => setClassView(prevClassView => !prevClassView)}>{t('modalComponent.class')}</button>
                                    <button className='all-driver-header-button' onClick={ handleMoreLapData }>{moreLapData ? t('modalComponent.displayOnlyLast50Laps') : t('modalComponent.displayAllLaps')}</button>
                                </div>

                                <div className='container-switching-btns'>
                                    <p>{t('general.displaySwitch')}</p>
                                    <div className='table-icon-area'>
                                        <input type='radio'className='visually-hidden' name='none' value='none' onChange={ handleRadioChange } checked={radioOption === 'none'}/>
                                        <label htmlFor='none'>{tableImage}</label>
                                    </div>

                                    <div className='graph-icon-area'>
                                        <input type='radio' id='byGraph' className='visually-hidden' name='allLaps' value='graph' onChange={ handleRadioChange } checked={radioOption === 'graph'}/>
                                        <label htmlFor='byGraph'>{graphImage}</label>    
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                { getModalContent() }
            </DialogContent>
        </Dialog>
    )
}

export default ModalComponent