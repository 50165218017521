import { useEffect, useState} from 'react'
import * as DOMPurify from 'dompurify'

import '../css/Simulation.css'

import { useTranslation } from 'react-i18next'

const SimulationCircuitTable = ({ strategyCircuitList, onInputChange, changedCounter, setEstimatedTyreDegradation, raceStrategyList }) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [ qfTime, setQfTime ] = useState('01:36.500')
    const [ fastesInMediumMMSS, setFastestInMediumMMSS ] = useState('01:38.150')
    const [ fcEstimateAve, setFcEstimateAve ] = useState(2.1)
    const [ refuelingSpeed, setRefuelingSpeed ] = useState(1.816)
    const [ refuelingSpeedCalculation, setRefuelingSpeedCalculation ] = useState(0)
    const [ reactionTime, setReactionTime ] = useState(0.0)
    const [ pitWorkTimefourwheel, setPitWorkTimefourwheel ] = useState(6)
    const [ tyreDegSoft, setTyreDegSoft] = useState(0.178)
    const [ pitWorkTime, setPitWorkTime ] = useState(6)
    const [ tyreWUTime, setTyreWUTime ] = useState(2.0)
    const [ tyreDegMedium, setTyreDegMedium ] = useState(0.017)
    const [ maxFuelLoad, setMaxFuelLoad ] = useState(70)
    const [ fuelWeightInAttackLapOfQF, setFuelWeightInAttackLapOfQF ] = useState(6)

    useEffect(() => {
        if (raceStrategyList) {
            if(raceStrategyList.length > 0) {
                onChangeSelect(raceStrategyList[0].CIRCUIT)
                document.getElementById(`tyre_deg_in_soft`).value = raceStrategyList[0].TYREDEGSOFT
                document.getElementById(`tyreDegInMedium`).value = raceStrategyList[0].TYREDEGMEDIUM
                document.getElementById(`estimateAve`).value = raceStrategyList[0].FCESTIMATEAVE
                document.getElementById(`pitWorkTimefourwheel`).value = raceStrategyList[0].PITWORKTIMEFOURWHEEL
                document.getElementById(`pitworkTimeTb`).value = raceStrategyList[0].PITWORKTIME
                document.getElementById(`refuelingSpeed`).value = raceStrategyList[0].REFUELINGSPEED
                document.getElementById(`fastInMediumMMSS`).value = raceStrategyList[0].FASTESINMEDIUMMMSS
                document.getElementById(`qfTimeTB`).value = raceStrategyList[0].QFTIME
                document.getElementById(`fuelWeightInAttackLapOfQF`).value = raceStrategyList[0].FUELWEIGHTINATTACKLAPOFQF
                document.getElementById(`reactionTime`).value = raceStrategyList[0].REACTIONTIME
                document.getElementById(`tyreWUTime`).value = raceStrategyList[0].TYREWUTIME
                document.getElementById(`maxFuelLoadTb`).value = raceStrategyList[0].MAXFUELLOAD
            } else {
                let obj = []
                const eachRow = {
                    TYREDEGSOFT: tyreDegSoft,
                    TYREDEGMEDIUM: tyreDegMedium,
                    FCESTIMATEAVE: fcEstimateAve,
                    PITWORKTIMEFOURWHEEL: pitWorkTimefourwheel,
                    PITWORKTIME: pitWorkTime,
                    REFUELINGSPEED: refuelingSpeed,
                    FASTESINMEDIUMMMSS: fastesInMediumMMSS,
                    QFTIME: qfTime,
                    FUELWEIGHTINATTACKLAPOFQF: fuelWeightInAttackLapOfQF,
                    REACTIONTIME: reactionTime,
                    TYREWUTIME: tyreWUTime,
                    MAXFUELLOAD: maxFuelLoad,
                    USERID: parseInt(localStorage.getItem('userId')),
                    CIRCUIT: 1
                }

                const arr = [1,2,3,4]
                arr.forEach((index) => {
                    const tempRow = {...eachRow}
                    tempRow.OPTIONNO = index
                    tempRow.PITSTOPLAP = parseInt(DOMPurify.sanitize(document.getElementById(`pitstopLapTb_${index}`).value))
                    tempRow.PITSTOPLAP2 = parseInt(DOMPurify.sanitize(document.getElementById(`pitstopLap2Tb2_${index}`).value))
                    tempRow.STRATEGY = parseInt(-1)

                    obj.push(tempRow)
                })

                insert(obj)
                    .then(() => {})
                    .catch(() => {
                        alert(t('pleaseContactAdmin', {ns: 'general'}))
                    })
            }
        }
    }, [raceStrategyList])

    useEffect(() => {
        setRefuelingSpeedCalculation(fcEstimateAve/refuelingSpeed)
    }, [fcEstimateAve, refuelingSpeed])

    useEffect(() => {
        onInputChange(Number(changedCounter) + 1)
        setEstimatedTyreDegradation(() => ({
            tyreDegSoft,
            tyreDegMedium
        }))
    }, [qfTime, fastesInMediumMMSS, fcEstimateAve, refuelingSpeed, refuelingSpeedCalculation, reactionTime, pitWorkTime, tyreDegSoft, pitWorkTimefourwheel, tyreWUTime, tyreDegMedium, maxFuelLoad])

    const onBlurHandlerUpdate = (key) => async (e) => {
        const obj = {
            UPDATEDVALUE: {
                [key]: parseFloat(e.target.value)
            },
            CONDITIONS: {
                USERID: parseInt(localStorage.getItem('userId'))
            }
        }

        try {
            await update(obj)
        } catch (error) {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        }
    }

    const onChangeHandlerCircuit = async (e) => {
        const circuitId = parseInt(e.target.value)
        displayOnChangeSelectCircuit(circuitId)
        onInputChange(Number(changedCounter) + 1)
        
        const obj = {
            UPDATEDVALUE: {
                CIRCUIT: circuitId
            },
            CONDITIONS: {
                USERID: parseInt(localStorage.getItem('userId'))
            }
        }

        try {
            await update(obj)
        } catch (error) {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        }
    }

    const onChangeSelect = (val) => {
        const id = parseInt(val)
        document.getElementById('selectCircuit').selectedIndex = id
        displayOnChangeSelectCircuit(id)
    }

    const displayOnChangeSelectCircuit = (id) => {
        const trackLength = document.getElementById(`TRACK_LENGTH_${id}`).innerText
        const raceDistance = document.getElementById(`race_distance_${id}`).value
        const pitRoadLossTime = document.getElementById(`pit_road_loss_time_${id}`).innerText
        const fuelEffect = document.getElementById(`fuel_effect_${id}`).innerText
        document.getElementById('circuitDistance').innerText = DOMPurify.sanitize(trackLength)
        document.getElementById('raceDistance').innerText = DOMPurify.sanitize(raceDistance)
        document.getElementById('pitRoadLossTime').innerText = DOMPurify.sanitize(pitRoadLossTime)
        document.getElementById('fuelEffect').innerText = DOMPurify.sanitize(fuelEffect)

        setEstimatedTyreDegradation(prev => ({
            ...prev,
            fuelEffect: DOMPurify.sanitize(document.getElementById('fuelEffect').innerText)
        }))
    }

    const onChangeHandlerFCSoft = (e) => {
        setQfTime(e.target.value)
    }

    return (
        <>
            <table border='1'>
                <tbody>
                    <tr>
                        {/* column 1 set */}
                        <td className='label'>Circuit</td>
                        <td>
                            <select id='selectCircuit' className='selectOptionWidth' onChange={onChangeHandlerCircuit}>
                                <option>{t('pleaseSelect', {ns: 'general'})}</option>
                                {
                                    strategyCircuitList.map(data => {
                                        const setVal = `${data.ID}`
                                        return <option key={data.ID} value={setVal}>{data.NAME}</option>
                                    })
                                }
                            </select>
                        </td>
                        <td className='unit'></td>
                        {/* column 2 set */}
                        <td className='label'>FC(Fasted in Soft)</td>
                        <td>2.1</td>
                        <td className='unit'>[kg/Lap] @</td>
                        <td id='fc_soft_mmss'>{qfTime}</td>
                        <td className='unit'>[mm:ss.000]</td>
                        <td className='emptyCol'></td>
                        {/* column 3 set */}
                        <td>QF Time</td>
                        <td>
                            <input id='qfTimeTB' className='inputText' type='text' defaultValue={qfTime} onChange={onChangeHandlerFCSoft} onBlur={onBlurHandlerUpdate('QFTIME')}></input>
                        </td>
                        <td>[mm:ss.000]</td>
                    </tr>
                    <tr>
                        {/* column 1 set */}
                        <td className='label'>Circuit Distance</td>
                        <td id='circuitDistance'></td>
                        <td className='unit'>[km]</td>
                        {/* column 2 set */}
                        <td className='label'>FC(Fasted in Medium)</td>
                        <td>2.1</td>
                        <td className='unit'>[kg/Lap] @</td>
                        <td>
                            <input id='fastInMediumMMSS' className='inputText' type='text' defaultValue={fastesInMediumMMSS} onChange={(e) => setFastestInMediumMMSS(e.target.value)} onBlur={onBlurHandlerUpdate('FASTESINMEDIUMMMSS')}></input>
                        </td>
                        <td className='unit'>[mm:ss.000]</td>
                        <td className='emptyCol'></td>
                        {/* column 3 set */}
                        <td>Fuel Weight in attack lap of QF</td>
                        <td>
                            <input id='fuelWeightInAttackLapOfQF' className='inputNumber' type='number' defaultValue='6' onChange={(e) => setFuelWeightInAttackLapOfQF(e.target.value)} onBlur={onBlurHandlerUpdate('FUELWEIGHTINATTACKLAPOFQF')}></input>
                        </td>
                        <td>[kg]</td>
                    </tr>
                    <tr>
                        {/* column 1 set */}
                        <td className='label'>Race Distance</td>
                        <td id='raceDistance'></td>
                        <td className='unit'>[Laps]</td>
                        {/* column 2 set */}
                        <td className='label'>FC (Estimate Ave.)</td>
                        <td>
                            <input id='estimateAve' className='inputNumber' type='number' defaultValue={fcEstimateAve} onChange={(e) => setFcEstimateAve(e.target.value)} onBlur={onBlurHandlerUpdate('FCESTIMATEAVE')}></input>
                        </td>
                        <td className='unit'>[kg/Lap] @</td>
                        <td></td>
                        <td className='unit'>[mm:ss.000]</td>
                        <td className='emptyCol'></td>
                        {/* column 3 set */}
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        {/* column 1 set */}
                        <td className='label'>Pit Road Loss Time</td>
                        <td id='pitRoadLossTime'></td>
                        <td className='unit'>[sec]</td>
                        {/* column 2 set */}
                        <td className='label'></td>
                        <td></td>
                        <td className='unit'></td>
                        <td></td>
                        <td></td>
                        <td className='emptyCol'></td>
                        {/* column 3 set */}
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        {/* column 1 set */}
                        <td className='label'>Fuel Effect</td>
                        <td id='fuelEffect'></td>
                        <td className='unit'>[sec/10kg]</td>
                        {/* column 2 set */}
                        <td className='label'>Pitwork Time (Only 4Wheel)</td>
                        <td>
                            <input id='pitWorkTimefourwheel' className='inputNumber' type='number' defaultValue={pitWorkTimefourwheel} onChange={(e) => setPitWorkTimefourwheel(e.target.value)} onBlur={onBlurHandlerUpdate('PITWORKTIMEFOURWHEEL')}></input>
                        </td>
                        <td className='unit'>[sec]</td>
                        <td></td>
                        <td></td>
                        <td className='emptyCol'></td>
                        {/* column 3 set */}
                        <td>Reaction Time</td>
                        <td>
                            <input id='reactionTime' className='inputNumber' type='number' defaultValue={reactionTime} onChange={(e) => setReactionTime(e.target.value)} onBlur={onBlurHandlerUpdate('REACTIONTIME')}></input>
                        </td>
                        <td>[sec]</td>
                    </tr>
                    <tr>
                        {/* column 1 set */}
                        <td className='label'>Tyre Deg in Soft</td>
                        <td>
                            <input id='tyre_deg_in_soft' className='inputNumber' type='number' defaultValue={tyreDegSoft} onChange={(e) => setTyreDegSoft(e.target.value)} onBlur={onBlurHandlerUpdate('TYREDEGSOFT')}></input>
                        </td>
                        <td className='unit'>[sec/Lap]</td>
                        {/* column 2 set */}
                        <td className='label'>Pitwork Time</td>
                        <td>
                            <input id='pitworkTimeTb' className='inputNumber' type='number' defaultValue={pitWorkTime} onChange={(e) => setPitWorkTime(e.target.value)} onBlur={onBlurHandlerUpdate('PITWORKTIME')}></input>
                        </td>
                        <td className='unit'>[sec]</td>
                        <td></td>
                        <td></td>
                        <td className='emptyCol'></td>
                        {/* column 3 set */}
                        <td>Tyre W/U Time</td>
                        <td>
                            <input id='tyreWUTime' className='inputNumber' type='number' defaultValue={tyreWUTime} onChange={(e) => setTyreWUTime(e.target.value)} onBlur={onBlurHandlerUpdate('TYREWUTIME')}></input>
                        </td>
                        <td>[sec]</td>
                    </tr>
                    <tr>
                        {/* column 1 set */}
                        <td className='label'>Tyre Deg in Medium</td>
                        <td>
                            <input id='tyreDegInMedium' className='inputNumber' type='number' defaultValue={tyreDegMedium} onChange={(e) => setTyreDegMedium(e.target.value)} onBlur={onBlurHandlerUpdate('TYREDEGMEDIUM')}></input>
                        </td>
                        <td className='unit'>[sec/Lap]</td>
                        {/* column 2 set */}
                        <td className='label'>Refueling Speed</td>
                        <td>
                            <input id='refuelingSpeed' className='inputNumber' type='number' defaultValue={refuelingSpeed} onChange={(e) => setRefuelingSpeed(e.target.value)} onBlur={onBlurHandlerUpdate('REFUELINGSPEED')}></input>
                        </td>
                        <td className='unit'>[kg/sec]</td>
                        <td id='refuelingSpeedCalculation' data-value={refuelingSpeedCalculation}>
                            {refuelingSpeedCalculation.toFixed(3)}
                            <div id='refuelingSpeedCalculationHidden' style={{display:'none'}}>{refuelingSpeedCalculation}</div>
                        </td>
                        <td>[sec/Lap]</td>
                        <td className='emptyCol'></td>
                        {/* column 3 set */}
                        <td>Maximum Fuel Load</td>
                        <td>
                            <input id='maxFuelLoadTb' className='inputNumber' type='number' defaultValue={maxFuelLoad} onChange={(e) => setMaxFuelLoad(e.target.value)} onBlur={onBlurHandlerUpdate('MAXFUELLOAD')}></input>
                        </td>
                        <td>[kg]</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default SimulationCircuitTable

function insert(obj) {
    return new Promise ((resolve, reject) => {
        fetch('/api/raceStrategy/bulkCreateRow', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .then(json => {
            resolve(json)
        })
        .catch((error) => {
            reject(error)
        })
    })
}

function update(obj) {
    return new Promise ((resolve, reject) => {
        fetch('/api/raceStrategy/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .then(json => {
            resolve(json)
        })
        .catch((error) => {
            reject(error)
        })
    })
}