import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const AddVehicleModal = (props) => {
    const [newVehicleName, setNewVehicleName] = useState('')
    const handleCloseModal = () => {
        props.onClose()
        props.setOpenModal(false)
    }
    const formHandler = (event) => {
        event.preventDefault()
        props.onVehicleAdded(newVehicleName)
    }
        return (
            <Dialog open={props.open} onClose={handleCloseModal}>
                <DialogTitle style={{textAlign:'center'}}>車両を追加</DialogTitle>
                <DialogContent>
                    <div>
                        <form className = 'vehicle-form' onSubmit = { formHandler } >
                            <div className = 'vehicle-form-input'>
                                <label className = 'vehicle-label'>車両名:</label>
                                <input
                                    type = 'text'
                                    id = 'newVehicleName'
                                    value = {newVehicleName}
                                    onChange={(e) => setNewVehicleName(e.target.value)}
                                    style={{borderColor: '#000000'}}
                                />
                            </div>
                            <div style={{color: 'red', textAlign: 'center'}}>{props.modalMessage}</div>
                            <button type='submit' style={{
                                color: '#000000',
                                borderColor: '#000000',
                                backgroundColor: '#cccccc',
                                display: 'block',
                                margin: '0 auto'
                            }}
                            >追加</button>
                        </form>
                    </div>
                </DialogContent>
       
        </Dialog>        
    )
}

export default AddVehicleModal