import React, { useState, useRef } from 'react'

import { useTranslation } from 'react-i18next'

const AddNewEvent = (props) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [newEventName, setNewEventName] = useState('')

    const raceIdRef = useRef()
    const circuitRef = useRef()

    const formHandler = (e) => {
        e.preventDefault()
        props.onEventAdded(newEventName, circuitRef.current.value, raceIdRef.current.value)
    }

    return (
        <div>
            <form className = 'event-form' onSubmit = { formHandler } >
                <div className = 'event-form-input'>
                    <label className = 'event-label'>{t('general.eventName')}:</label>
                    <input
                        type = 'text'
                        id = 'newEventName'
                        value = {newEventName}
                        onChange = { (e) => setNewEventName(e.target.value) }
                    />
                </div>
                <div className = 'event-form-input'>
                    <label className = 'event-label'>{t('general.series')}:</label>
                    <select defaultValue={props.raceId} ref={raceIdRef}>
                        <option value = ''>{t('pleaseSelect', {ns: 'general'})}</option>
                        {props.raceSeries}
                    </select>
                </div>
                <div className = 'event-form-input'>
                    <label className = 'event-label'>{t('general.circuit')}:</label>
                    <select defaultValue={props.circuit} ref={circuitRef}>
                        <option value = ''>{t('pleaseSelect', {ns: 'general'})}</option>
                        {props.circuitList}
                    </select>
                </div>
                <button type = 'submit'>{t('addNewEvent.add')}</button>
            </form>
        </div>
    )
}

export default AddNewEvent