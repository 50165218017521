import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const AdminDeployModel = () => {
    const { t } = useTranslation(['strategy', 'general'])
    // move 更新しました、削除しました、and 削除しますか？ to general.json

    const [ modelList, setModelList ] = useState([])
    const [ updated, setUpdated ] = useState(true)

    useEffect(() => {
        if (!updated) return

        const getList = async () => {
            fetch('/core/api/trainingModel/getModels', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(data => setModelList(data.data))
            .catch(error => alert('システム管理者に連絡してください'))
        }

        getList()
        setUpdated(false)
    }, [updated])

    const deployHandler = (e, model) => {
        fetch('/core/api/trainingModel/updateModel', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: model.ID,
                isDeployed: e.target.checked,
                vehicle: model.VEHICLE,
                track: model.TRACK,
                driver: model.DRIVER
            })
        })
        .then(res => res.json())
        .then(data => {
            if (data.status === 200) {
                alert(t('editEvent.successfulUpdate'))
                setUpdated(true)
            } else {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            }
        })
        .catch(error => alert(t('pleaseContactAdmin', {ns: 'general'})))
    }

    const deleteHandler = (modelId) => {
        const confirm = window.confirm(t('editEvent.deletionConfirmation'))
        if (!confirm) return

        fetch('/core/api/trainingModel/deleteModel', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: modelId
            })
        })
        .then(res => res.json())
        .then(data => {
            if (data.status === 200) {
                alert(t('editEvent.successfulDeletion'))
                setUpdated(true)
            } else {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            }
        })
        .catch(error => alert(t('pleaseContactAdmin', {ns: 'general'})))
    }

    return (
        <div className='core-admin input-container deploy-model'>
            <div className='deploy-model-settings header'>
                <div className='model-remove header'>削除</div>
                <div className='model-id header'>モデルID</div>
                <div className='model-vehicle header'>車種</div>
                <div className='model-track header'>サーキット</div>
                <div className='model-driver header'>ドライバ</div>
                <div className='model-deploy header'>デプロイ</div>
            </div>
            { modelList.map(model => {
                return (
                    <div className='deploy-model-settings' key={ model.ID } >
                        <div className='model-remove' onClick={(e) => { deleteHandler(model.ID) }}>✕</div>
                        <div className='model-id'>{ model.ID }</div>
                        <div className='model-vehicle'>{ model.VEHICLE }</div>
                        <div className='model-track'>{ model.TRACK }</div>
                        <div className='model-driver'>{ model.DRIVER || '-' }</div>
                        <div className='model-deploy'>
                            <input type='checkbox' checked={ model.IS_DEPLOYED === 1 } onChange={(e) => deployHandler(e, model) }/>
                        </div>
                    </div>
                )
            }) }
        </div>
    )
}

export default AdminDeployModel