import React, { useState, useEffect } from 'react'
import { useLiveData } from '../../DataContext'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

import { useTranslation } from 'react-i18next'

const ReportDownloadView = (props) => {
    const { t } = useTranslation(['strategy', 'general'])
    
    const { raceDetailId, isRace, raceSeries } = useLiveData()

    const [ carNumbers, setCarNumbers] = useState([])
    const [ selectedCarNumber, setSelectedCarNumber] = useState(null)

    useEffect(() => {
        const getCarNumbers = () => {
            fetch(`/api/car/carNumbers/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    setCarNumbers(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        getCarNumbers()
    }, [])

    const handleCarNumberChange = (event) => {
        setSelectedCarNumber(event.target.value);
    }

    return(
        <>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <FormControl sx={{ m: 1, minWidth: 180, color: 'white', borderBottom: '1px solid white' }}>
                    <InputLabel id='demo-simple-select-autowidth-label' style={{ color: 'black' }}>carNumber</InputLabel>
                    <Select
                        labelId='demo-simple-select-autowidth-label'
                        id='demo-simple-select-autowidth'
                        value={selectedCarNumber}
                        onChange={handleCarNumberChange}
                        autoWidth
                        label='carNumber'
                        style={{ color: 'black', height: '50px', backgroundColor: 'white'}}
                    >
                        {
                            carNumbers && carNumbers.map((carNumber, index) => (
                                <MenuItem 
                                    key={`select-${index}`}
                                    value={carNumber} 
                                    style={{ color: 'black', minWidth: 160}}
                                >
                                    {carNumber}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <a className='report-download' href={`/api/report/download/${isRace}/${raceSeries}/${raceDetailId}/${props.numOfLaps}/${Number(selectedCarNumber)}`} download>Download</a>
            </div>
        </>
    )
}

export default ReportDownloadView
