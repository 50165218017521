import { useTranslation } from 'react-i18next'

const SettingView = (props) => {
    const { t } = useTranslation(['strategy'])
    
    return(
        <>
            <div className='pitwork-setting-area'>
                <h3 className=''>{t('general.pitStopPrediction')}</h3>
                <div className='pitworktime-area'>
                    <label>pit work time(s):</label>
                    <input
                        name='pitStopTimeByWork'
                        type='number'
                        defaultValue={props.pitStopTimeByWork}
                        onChange = { (e) => {
                            props.setPitStopTimeByWork(Number(e.target.value))
                            props.setPitStopTimeSettingStatus(true)
                        }}>
                    </input>
                </div>
                <div className='refuelingtime-area'>
                    <label>refueling time(s):</label>
                    <input
                        name='pitStopTimeByRefueling'
                        type='number'
                        defaultValue={props.pitStopTimeByRefueling}
                        onChange = { (e) => {
                            props.setPitStopTimeByRefueling(Number(e.target.value))
                            props.setPitStopTimeSettingStatus(true)
                        }}>
                    </input>
                </div>
            </div>
        </>
    )
}

export default SettingView
