import { write, utils } from 'xlsx'
import * as dfd from 'danfojs'
import { saveAs } from 'file-saver'

const downloadResult = async (VEHICLE_ID, TRACK_ID, listAns, df_showSetup, df_baseSetup,
    df_baseTime, df_baseHandling, df_baseAero, df_baseMech,
    // df_baseRHFL, df_baseRHFR, df_baseRHRL,
    df_scatTime, df_scatHandling, df_scatAero, df_scatMech,
    // df_scatRHFL, df_scatRHFR, df_scatRHRL,
    df_scatInput,
    scat_chart_1, scat_chart_2, scat_chart_3,
    // scat_chart_4, scat_chart_5, scat_chart_6,
    Obj_base_result, Obj_scat_result,
    df_baseKPI, df_scatKPI, vehicle) => {
    
    const OutputName = `${VEHICLE_ID}_${TRACK_ID}_setup_result.xlsx`

    function makeArray(df, indexname) {

        df = df.T
        df.setIndex({index: indexname, inplace: true})
        df = df.T

        let array = []
        for(let i=0; i<df.index.length+1; i++){
            if (i===0){
                array.push([''].concat(df.columns))
            }else{
                array.push([df.index[i-1]].concat(df.iloc({rows:[i-1]}).values[0]))
            }
        }
        return array
    }

    function makeArrayForKPI(df, indexname) {

        df = df.T
        df.setIndex({index: indexname, inplace: true})
        df = df.T

        let array = []
        for(let i=0; i<df.index.length+1; i++){
            if (i===0){
                array.push([''].concat(df.columns))
            }else{
                array.push([df.index[i-1]].concat(df.iloc({rows:[i-1]}).values[0]))
            }
        }
        return array
    }

    // ArrayをwbのSheetに追加する
    function aoa_to_workbook(array){
        let wb = utils.book_new();
        for(let i=0; i<array.length; i++){
            let ws = utils.aoa_to_sheet(array[i].data)
            utils.book_append_sheet(wb, ws, array[i].name);
        }
        return wb
    }
  
    // stringをArrayBufferに変換する
    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
    }

    function func1() {
        var write_opts = {
            type: 'binary'
        };

        let inputAddInput = 0
        let InputLength = df_scatInput.columns.length + df_scatTime.columns.length
        // let KPISummary = [8, 4, 5, 9, 11, 12, 13, 14]
        let KPISummary = [9, 5, 6, 10, 12, 13, 14, 15]
        let inputSummary
        if(vehicle.parameter_names.length !== 4){
            inputAddInput = vehicle.parameter_names.slice(4)
        }
        if(inputAddInput !== undefined){
            inputSummary = vehicle.parameter_names
        }else{
            inputSummary = vehicle.parameter_names.slice(0, 4)
        }
        console.log(vehicle.parameter_names.slice(0, 4))
        console.log(inputSummary)

        // df_outputInput = dfd.concat({ dfList: [df_scatInput.iloc({"rows":[listAns[0]]}), df_baseInput], axis: 0 }).T
        const df_outputInput = df_showSetup
        const df_outputTime = dfd.concat({ dfList: [df_scatTime.iloc({"rows":[listAns[0]]}), df_baseTime], axis: 0 }).T
        const df_outputHandling = dfd.concat({ dfList: [df_scatHandling.iloc({'columns':[`${InputLength }:`], "rows":[listAns[0]]}), df_baseHandling.iloc({'columns':[`${InputLength }:`]})], axis: 0 }).T
        const df_outputAero = dfd.concat({ dfList: [df_scatAero.iloc({'columns':[`${InputLength }:`], "rows":[listAns[0]]}), df_baseAero.iloc({'columns':[`${InputLength }:`]})], axis: 0 }).T
        const df_outputMech = dfd.concat({ dfList: [df_scatMech.iloc({'columns':[`${InputLength }:`], "rows":[listAns[0]]}), df_baseMech.iloc({'columns':[`${InputLength }:`]})], axis: 0 }).T
        // const df_outputRHFL = dfd.concat({ dfList: [df_scatRHFL.iloc({'columns':[`${InputLength }:`], "rows":[listAns[0]]}), df_baseRHFL.iloc({'columns':[`${InputLength }:`]})], axis: 0 }).T
        // const df_outputRHFR = dfd.concat({ dfList: [df_scatRHFR.iloc({'columns':[`${InputLength }:`], "rows":[listAns[0]]}), df_baseRHFR.iloc({'columns':[`${InputLength }:`]})], axis: 0 }).T
        // const df_outputRHRL = dfd.concat({ dfList: [df_scatRHRL.iloc({'columns':[`${InputLength }:`], "rows":[listAns[0]]}), df_baseRHRL.iloc({'columns':[`${InputLength }:`]})], axis: 0 }).T

        df_baseSetup.iloc({"columns":["1:"]}).loc({"rows":inputSummary}).print()
        let df_baseSummary = dfd.concat({ dfList: [df_baseSetup.iloc({"columns":["1:"]}).loc({"rows":inputSummary}), df_baseKPI.iloc({'columns':KPISummary}).T], axis: 0})
        let df_scatSummary = df_scatKPI.iloc({'columns':KPISummary, "rows":[listAns[0]]})
        df_scatSummary.setIndex({index: ['Recommend'], inplace: true})
        let df_scatSummary_2 = df_scatInput.iloc({"rows":[listAns[0]]}).loc({"columns":inputSummary})
        df_scatSummary_2.setIndex({index: ['Recommend'], inplace: true})
        df_scatSummary = dfd.concat({ dfList: [df_scatSummary_2.T, df_scatSummary.T], axis: 0})

        // if(inputAddInput !== undefined){
        //     let tmpDf_baseSetup = df_baseSetup.iloc({"columns":[1]})
        //     df_baseSummary = dfd.concat({ dfList: [df_baseSummary, tmpDf_baseSetup.T], axis: 0})
        //     let tmpDf_scatSummary = df_scatInput[inputAddInput].iloc({"rows":[listAns[0]]})
        //     tmpDf_scatSummary.setIndex({index: ['Recommend'], inplace: true})
        //     df_scatSummary = dfd.concat({ dfList: [df_scatSummary, tmpDf_scatSummary.T], axis: 0})
        // }

        let df_outputSummary = dfd.concat({ dfList: [df_scatSummary, df_baseSummary], axis: 1 })
        let indexSummary = inputSummary.concat(df_scatKPI.iloc({'columns':KPISummary}).columns)
        // console.log(indexSummary)
        df_outputSummary.setIndex({index: indexSummary, inplace: true})


        let indexname = ['Recommend']
        indexname = indexname.concat(df_baseSetup.iloc({"columns":["1:"]}).columns)

        let wbArray = []
        wbArray.push({name: 'Summary', data: makeArrayForKPI(df_outputSummary, indexname)})
        wbArray.push({name: 'Setup', data: makeArray(df_outputInput, indexname)})
        wbArray.push({name: 'Time', data: makeArray(df_outputTime, indexname)})
        wbArray.push({name: scat_chart_1, data: makeArray(df_outputHandling, indexname)})
        wbArray.push({name: scat_chart_2, data: makeArray(df_outputAero, indexname)})
        wbArray.push({name: scat_chart_3, data: makeArray(df_outputMech, indexname)})
        // wbArray.push({name: scat_chart_4, data: makeArray(df_outputRHFL, indexname)})
        // wbArray.push({name: scat_chart_5, data: makeArray(df_outputRHFR, indexname)})
        // wbArray.push({name: scat_chart_6, data: makeArray(df_outputRHRL, indexname)})

        // ArrayをWorkbookに変換する
        var wb = aoa_to_workbook(wbArray);
        var wb_out = write(wb, write_opts);

        // WorkbookからBlobオブジェクトを生成
        var blob = new Blob([s2ab(wb_out)], { type: 'application/octet-stream' });
        // FileSaverのsaveAs関数で、xlsxファイルとしてダウンロード
        saveAs(blob, OutputName);
    }

    function downloadScatResult() {
        const scatOutputName = `${VEHICLE_ID}_${TRACK_ID}_result.json`

        const resultObject = {
            base: {},
            scat: {},
        }

        for (const [key, value] of Object.entries(Obj_base_result)) {
            const json = dfd.toJSON(value, { download: false })
            resultObject.base[key] = json
        }

        for (const [key, value] of Object.entries(Obj_scat_result)) {
            const json = dfd.toJSON(value, { download: false })
            resultObject.scat[key] = json
        }

        resultObject['baseTime'] = dfd.toJSON(df_baseTime, { download: false })
        resultObject['scatTime'] = dfd.toJSON(df_scatTime, { download: false })

        const jsonString = JSON.stringify(resultObject, null, 2)
        const blob = new Blob([jsonString], { type: 'application/json' })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = scatOutputName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    func1()
    // downloadScatResult()
}

export default downloadResult