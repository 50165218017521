import { useEffect, useState } from 'react'
import '../css/DriverData.css'

const ProgressBarCell = (props) => {
    const [progress, setProgress] = useState(0)
    const isInPit = props.isInPit
    const totalTime = (props.interval || 20) * 1000
    const incrementValue = 100 / (totalTime / 100)

    useEffect(() => {
        let interval

        if (!isInPit) {
            interval = setInterval(() => {
                setProgress((prevProgress) => {
                    const newProgress = prevProgress + incrementValue
                    return newProgress <= 95 ? newProgress : 95
                })
            }, 100)
        }

        return () => clearInterval(interval)
    }, [isInPit])
  
    return <div className='progress-bar' style={{ width: `${progress}%` }}></div>
}

export default ProgressBarCell