// strを含む列を抽出
const search_columns = (df, str, multi = 0) => {
    let flag_column
    if (multi === 0) {
        for (let i = 0; i < df.columns.length; i++) {
            flag_column = df.columns[i].includes(str)
            if (flag_column === true) {
                return df.columns[i]
            }
        }
    } else if (multi === 1) {
        let columns = []
        for (let i = 0; i < df.columns.length; i++) {
            flag_column = df.columns[i].includes(str)
            if (flag_column === true) {
                columns[columns.length] = df.columns[i]
            }
        }
        return columns
    }
}

export default search_columns