import React from 'react'

import SetupUserInputTable from './SetupUserInput_Table'

const SetupUserInputTableEdit = (props) => {
    const dataToEdit = props.dataToEdit

    const setIsEditMode = props.setIsEditMode
    const onClose = props.onClose

    const setEditedData = props.setEditedData
    const setSetup = props.setSetup

    const handleCloseModal = () => {
        setIsEditMode(false)
    }

    return (
        <>
            { props.open && (
                <>
                    <div className='modal-container'>
                        <div className='modal-content'>
                            {/* <div className='modal-exit' onClick={ handleCloseModal }>✕</div> */}
                            <div>
                                <SetupUserInputTable
                                    title={ dataToEdit.title }
                                    data={ dataToEdit.data }
                                    setIsEditMode={ setIsEditMode }
                                    type={ dataToEdit.type }
                                    isEditMode={ true }
                                    onClose={ onClose }
                                    setEditedData={ setEditedData }
                                    setSetup={ setSetup }
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}


export default SetupUserInputTableEdit