import * as dfd from 'danfojs'

// 各手法で最適解探索
const compare_setupLapTime = (df) => {
    let before_name = []
    let diff_name = []
    let index_name = ['base']
    let df_ans = df.iloc({'columns':[1]})

    for (let i = 0; i < df.index.length; i++) {
        let base_value = df.iloc({'columns':[1], 'rows':[i]}).values[0][0]
        let recommend_value = df.iloc({'columns':[0], 'rows':[i]}).values[0][0]
        if (!(isNaN(recommend_value))){
            base_value = Number(base_value).toFixed(2)
            recommend_value = Number(recommend_value).toFixed(2)
        }

        if (!(base_value === recommend_value)) {
            before_name.push(base_value)
            diff_name.push(recommend_value)
            index_name.push('→ ' + df.index[i] + ' change')
        }
    }

    for (let i = 0; i < before_name.length; i++) {
        let df_tmp = df_ans.iloc({'columns':[df_ans.columns.length-1]})
        df_tmp = df_tmp.replace(before_name[i], diff_name[i], {columns: [df_ans.columns[i]]})
        df_ans = dfd.concat({ dfList: [df_ans, df_tmp], axis: 1 })
    }

    df_ans = df_ans.T
    df_ans.setIndex({index: index_name, inplace: true})
    df_ans = df_ans.T

    console.log(df_ans)
    return df_ans
}

export default compare_setupLapTime