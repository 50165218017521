import React, { useState, useMemo } from 'react'

import RGL, { WidthProvider } from 'react-grid-layout'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import TimeSeriesChartTimeDistance from './TimeSeriesChart_TimeDistance'
import TimeSeriesChartXY from './TimeSeriesChart_XY'
import TimeSeriesChartBar from './TimeSeriesChart_Bar'
import TimeSeriesChartRadar from './TimeSeriesChart_Radar'

const TimeSeriesDragResize = (props) => {
    const [ layouts, setLayouts ] = useState([])

    const timeSeriesList = props.timeSeriesList
    const graphData = props.graphData
    const setGraphData = props.setGraphData

    const selectedGraph = props.selectedGraph
    const setSelectedGraph = props.setSelectedGraph

    const vehicleId = props.vehicleId
    const trackId = props.trackId

    const ReactGridLayout = useMemo(() => WidthProvider(RGL), [])

    const onLayoutChange = (newLayout) => {
        setLayouts(newLayout)
    }

    const removeGraphHandler = (graphId) => {
        const newGraphData = graphData.filter(data => data.id != graphId)

        if (selectedGraph.id === graphId) {
            setSelectedGraph({})
        }

        setGraphData(newGraphData)
    }

    const selectGraphHandler = (e, graphData) => {
        if (e.target.classList.contains('remove-graph-button')) return
        setSelectedGraph(graphData)
    }

    return (
        <ReactGridLayout
            className='layout'
            layouts={ layouts }
            onLayoutChange={ onLayoutChange }
            compactType={null}
            cols={ 3 }
            rowHeight={ 300 }
            preventCollision={ true }
            draggableCancel='.MuiSlider-thumb, .time-checker-div, .remove-graph-button'
        >
            { graphData.length > 0 && (
                graphData.map((data, i) => {
                    return (
                        <div key={ data.id }>
                            <div
                                className={ `chart-container ${data.id == selectedGraph.id ? 'selected-graph' : ''}` }
                                onMouseDown={(e) => { selectGraphHandler(e, data) }}
                            >
                                <span className='remove-graph-button' onMouseDown={() => { removeGraphHandler(data.id) }}></span>
                                { data.type === 'timeDistance' && (
                                    <TimeSeriesChartTimeDistance graphData={ data } timeSeriesList={ timeSeriesList } />
                                )}

                                { data.type === 'xY' && (
                                    <TimeSeriesChartXY graphData={ data } timeSeriesList={ timeSeriesList } />
                                )}

                                { data.type === 'bar' && (
                                    <TimeSeriesChartBar graphData={ data } timeSeriesList={ timeSeriesList } vehicleId={ vehicleId } trackId={ trackId } />
                                )}

                                { data.type === 'radar' && (
                                    <TimeSeriesChartRadar graphData={ data } timeSeriesList={ timeSeriesList } />
                                )}
                            </div>
                        </div>
                    )
                })
            )}
        </ReactGridLayout>
    )
}

export default TimeSeriesDragResize