import { groupLapDataByCar, groupSectorDataByCar } from './Util'

const GetLatestDataForEachCar = async (lapDataArray, sectorDataArray, raceDetailId, sectorLength) => {

    // ラップデータとセクターデータを車両番号ごとに分類
    const lapDataByCar = groupLapDataByCar(lapDataArray)
    const sectorDataByCar = groupSectorDataByCar(sectorDataArray)

    const lapTimeInfoByCar = await getLapTimeInfoByCar(raceDetailId)

    const latestDataByCar = {}

    for (let carNumber in lapDataByCar) {
        // 各車両のラップデータとセクターデータをソート
        sortDataByKey(lapDataByCar, carNumber, 'LAP_NUMBER')
        sortDataByKey(sectorDataByCar, carNumber, 'SECTOR_NUMBER')

        latestDataByCar[carNumber] = {
            carNumber: parseInt(carNumber),
            diff: 0,
            totalLapTime: 0,
            totalSectorTime: 0,
            latestLap: {LAP_NUMBER: 0},
            latestSector: {LAP_NUMBER: 0},
        }

        for (let sectorData of sectorDataByCar[carNumber]) {
            if (sectorData.LAP_NUMBER > latestDataByCar[carNumber].latestSector.LAP_NUMBER) {
                latestDataByCar[carNumber].totalSectorTime = 0
            }

            latestDataByCar[carNumber].latestSector = sectorData
            latestDataByCar[carNumber].totalSectorTime += sectorData.TIME
        }
        latestDataByCar[carNumber].isSectorDataConsistent = isSectorDataConsistent(carNumber, sectorDataByCar[carNumber], latestDataByCar[carNumber].latestSector.LAP_NUMBER, latestDataByCar[carNumber].latestSector.SECTOR_NUMBER)

        const carLapTimeInfo = lapTimeInfoByCar.find(lapTimeInfo => parseInt(lapTimeInfo.CAR_NUMBER) === parseInt(carNumber))
        latestDataByCar[carNumber].totalLapTime = carLapTimeInfo.TOTAL_TIME || 0

        for (let lapData of lapDataByCar[carNumber]) {

            if (lapData.LAP_NUMBER < latestDataByCar[carNumber].latestSector.LAP_NUMBER) {
                latestDataByCar[carNumber].latestLap = lapData
            }

            if (lapData.LAP_NUMBER === latestDataByCar[carNumber].latestSector.LAP_NUMBER) {
                latestDataByCar[carNumber].totalLapTime -= lapData.TIME
            }

        }

        const lapRecordCount = carLapTimeInfo.RECORD_COUNT || 0
        latestDataByCar[carNumber].isLapDataConsistent = isLapDataConsistent(lapRecordCount, latestDataByCar[carNumber].latestLap.LAP_NUMBER, latestDataByCar[carNumber].latestSector.SECTOR_NUMBER === sectorLength)
        if (!latestDataByCar[carNumber].isLapDataConsistent) {
            latestDataByCar[carNumber].latestLap = lapDataByCar[carNumber][lapDataByCar[carNumber].length - 1]
        }
    }

    return latestDataByCar
}

export default GetLatestDataForEachCar

function sortDataByKey(dataByCar, carNumber, key) {
    if (dataByCar[carNumber]) {
        dataByCar[carNumber].sort((a, b) => a[key] - b[key])
    } else {
        dataByCar[carNumber] = []
    }
}

function getLapTimeInfoByCar(raceDetailId) {
    return new Promise((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/race/getTotalLapTimeByRaceDetailId/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function isSectorDataConsistent(carNumber, sectorData, lapNumber, sectorNumber) {
    const sectorRecordCount = sectorData.filter(sector => sector.CAR_NUMBER === parseInt(carNumber) && sector.LAP_NUMBER === parseInt(lapNumber)).length
    return sectorRecordCount === sectorNumber
}

function isLapDataConsistent(lapRecordCount, lapNumber, isLastSector) {
    if (isLastSector) {
        lapRecordCount -= 1
    }

    return lapRecordCount >= lapNumber
}