import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'

import { useTranslation } from 'react-i18next'


const SetupTableConstrain = (props) => {
    const { t } = useTranslation(['core'])

    const df_baseSetup = props.df_baseSetup

    const [ constrainSelectedOption, setConstrainSelectedOption ] = useState()

    useEffect(() => {
        if (!df_baseSetup) return

        const dfConstrain = df_baseSetup.loc({ columns: ['Constrain'] })

        const tempConstrainSelectedOption = []
        dfConstrain.index.forEach((parameter, index) => {
            tempConstrainSelectedOption.push({
                parameter: parameter,
                value: dfConstrain.values[index][0]
            })
        })

        setConstrainSelectedOption(tempConstrainSelectedOption)
    }, [df_baseSetup])

    const handleCloseModal = () => {
        props.setConstrainOptionOpen(false)
    }

    const startClickHandler = () => {
        props.onClose(constrainSelectedOption)
        handleCloseModal()
    }

    const paperProps = {
        sx: {
            width: '100%',
            maxWidth: '1200px',
        }
    }

    const ConstrainSelect = (parameter, value) => {
        const constrainOptions = ['-', 'ON']

        const selectHandler = (e) => {
            setConstrainSelectedOption((prev) => {
                return prev.map((option) => {
                    if (option.parameter === parameter) {
                        return {
                            parameter: option.parameter,
                            value: e.target.value
                        }
                    } else {
                        return option
                    }
                })
            })
        }

        return (
            <select key={ `${parameter}_select` } name={ parameter } id={ `${parameter}_select` } value={ value } onChange={ selectHandler }>
                {constrainOptions.map((option) => {
                    return (
                        <option key={`${parameter}_${option}`} value={option}>
                            {option}
                        </option>
                    )
                })}
            </select>
        )
    }

    return (
        <Dialog className='setup-page constrain' open={ props.constrainOptionOpen } onClose={ handleCloseModal } PaperProps={ paperProps }>
            <DialogTitle>{t('setup.fixedParameterSettings')}</DialogTitle>
            <DialogContent>
                <table>
                    <thead>
                        <tr>
                            <th>Parameter</th>
                            <th>Constrain</th>
                        </tr>
                    </thead>
                    <tbody>
                        { constrainSelectedOption && constrainSelectedOption.map((option, index) => {
                            return (
                                <tr key={ `${option.parameter}_${index}` }>
                                    <td>{ option.parameter }</td>
                                    <td>{ ConstrainSelect(option.parameter, option.value) }</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions>
                <button onClick={ startClickHandler }>Start</button>
                <button onClick={ handleCloseModal }>Cancel</button>
            </DialogActions>
        </Dialog>
    )

    // return (
    //     <>
    //         { props.open && (
    //             <>
    //                 <div className='modal-container'>
    //                     <div className='modal-content'>
    //                         <div className='title'>固定パラメータ設定</div>
    //                         <div className='table-container'>

    //                         </div>

    //                         <div className='button-container'>
    //                             <button onClick={ onClose }>Start</button>
    //                             <button onClick={ onClose }>Cancel</button>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </>
    //         )}
    //     </>
    // )
}


export default SetupTableConstrain