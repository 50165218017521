const GetFile = async (fileName, type = 'json') => {
    return new Promise (async (resolve, reject) => {
        const filePath = `/getFile/${fileName}`

        let data
        if (type === 'buffer') {
            data = await (await fetch(filePath)).arrayBuffer()
        } else if (type === 'txt') {
            data = (await fetch(filePath)).text()
        } else if (type === 'json') {
            data = (await fetch(filePath)).json()
        }

        resolve(data)
    })
}

export default GetFile