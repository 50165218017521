// //Tableデータ作成
const SetupTable = (df, type, selectedTable, setSelectedTable) => {

    const TableSelect = () => {
        return (
            <div className='select-container'>
                <select onChange={ (e) => setSelectedTable(e.target.value) } value={ selectedTable }>
                    <option value='Time'>Time</option>
                    <option value='KPI'>ロール剛性</option>
                </select>
            </div>
        )
    }

    const TableTd = (key, className, content) => {
        return (
            <td key={key} className={className} >
                { content }
            </td>
        )
    }

    const headers = ['parameter', 'Target', 'Recommend', 'Selected', 'Base']
    df.columns.forEach(c => {
        if (!headers.includes(c)) headers.push(c)
    })

    const KPIrows = ['Roll_Stiff_Total', 'Roll_Stiff_Balance', 'Heave_Total', 'Heave_Ratio', 'Sprung_Resonance_Ratio']
    const rows = []

    const cells = []
    headers.forEach((h, i) => {
        const td = TableTd(
            `header-${i}`,
            'center td-dark',
            h === 'parameter' ? <TableSelect /> : h
        )

        cells.push(td)
    })

    rows.push(<tr key={`row-0`}>{cells}</tr>)

    df.values.forEach((row, i) => {
        const cells = []

        let parameterName = df.index[i]
        if (type === 'kpi' && !KPIrows.includes(df.index[i])) return

        if (type === 'laptime') {
            parameterName = parameterName.split('Duration')[0]
        }
        const parameterTd = TableTd(
            `index-${i}-0`,
            'center td-dark',
            parameterName
        )

        cells.push(parameterTd)

        headers.forEach((h, hI) => {
            if (h === 'parameter') return

            let text = ''

            if (df.columns.includes(h)) {
                const headerIndex = df.columns.indexOf(h)
                text = row[headerIndex]
            }

            const td = TableTd(
                `data-${i}-${hI}`,
                'center',
                text === '' || isNaN(text) ? text : parseFloat(text).toFixed(3)
            )

            cells.push(td)
        })

        rows.push(<tr key={`row-${i + 1}`}>{cells}</tr>)
    })

    return (
        <table>
            <thead>{rows[0]}</thead>
            <tbody>{rows.slice(1)}</tbody>
        </table>
    )
}

export default SetupTable
