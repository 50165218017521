import { useState, useEffect } from 'react'

import { useLiveData } from '../../DataContext'

import { colorScale } from '../services/Util'

import TimeFormatter from './TimeFormatter'

import { useTranslation } from 'react-i18next'

const LapHistory = (props) => {
    const { t } = useTranslation(['strategy'])

    const [lapTimes, setLapTimes] = useState({})
    const [allLapTimes, setAllLapTimes] = useState([])
    const [positions, setPositions] = useState([])
    const [heatMap, setHeatMap] = useState([])
    const [minMax, setMinMax] = useState([0, 0])
    const [updatedTableData, setUpdatedTableData] = useState()

    const { raceDetailId, tableData, lapData, followCarNumber, rivalCarNumbers} = useLiveData()

    useEffect(() => {
        const getAllLapData = (raceDetailId) => {
            return new Promise ((resolve, reject) => {
                if (!raceDetailId) return resolve([])
        
                fetch(`/api/race/getAllLapData/${raceDetailId}`)
                    .then(response => response.json())
                    .then(json => {
                        setAllLapTimes(json.data)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }

        getAllLapData(raceDetailId)
    }, [])

    useEffect(() => {
        if (props.moreLapData === false) {
            processLapData(lapData)
        } else {
            if (lapData.length > 0) {
                const newLapData = lapData.filter((data) => {
                    return !allLapTimes.some((allLapData) => {
                        return allLapData.CAR_NUMBER === data.CAR_NUMBER && allLapData.LAP_NUMBER === data.LAP_NUMBER
                    })
                })
                const newAllLapTimes = allLapTimes.concat(newLapData)
                setAllLapTimes(newAllLapTimes)
                processLapData(newAllLapTimes)
            }
        }
    }, [props.moreLapData, props.radioOption, props.carClasses, lapData])

    const processLapData = (lapData) => {
        if (!lapData) return

        let carTableData = [...tableData]
        const currentCarClasses = props.carClasses.filter((data) => data.isVisible === true).map((data) => data.class)
        setUpdatedTableData(carTableData.filter((data) => currentCarClasses.includes(data.carClass)))
        carTableData = carTableData.filter((data) => currentCarClasses.includes(data.carClass)).map((data) => Number(data.carno.split('.')[1]))

        const filteredLapData = lapData.filter((data) => carTableData.includes(data.CAR_NUMBER))

        const sortedData = filteredLapData.sort((a, b) => {
            if (a.LAP_NUMBER === b.LAP_NUMBER) {
                return a.POS - b.POS
            }
            return a.LAP_NUMBER - b.LAP_NUMBER
        })

        let lapTimesByCarNumber = {}
        let allLaps = []

        sortedData.forEach((driverData, index) => {
            const carNumber = driverData.CAR_NUMBER
            const lap = driverData.LAP_NUMBER
            const time = driverData.TIME ? driverData.TIME.toFixed(3) : '-'

            lapTimesByCarNumber = addToGroup(lapTimesByCarNumber, carNumber, lap, time)
            allLaps = addToGroup(allLaps, lap, carNumber, time)
        })

        const min = Math.min(...Object.keys(allLaps))
        const max = Math.max(...Object.keys(allLaps))
        setMinMax([min, max])
        setLapTimes(lapTimesByCarNumber)
        setHeatMap(getHeatMap(allLaps))

        function addToGroup(group, groupBy, addKey, time) {
            if (!group[groupBy]) {
                group[groupBy] = {}
            }
            group[groupBy][addKey] = Number(time)
            return group
        }

        function getHeatMap(allLaps) {
            const heatMaps = []

            if (allLaps.length < 1) return heatMaps
            // If there is an empty array from minMax[0] to minMax[1], fill it with empty objects
            for (let i = min; i <= max; i++) {
                if (!allLaps[i]) {
                    allLaps[i] = {}
                }
            }
            if (props.radioOption === 'allLaps') {
                // First puts all the lap times into one array
                const lapTimes = []
                allLaps.forEach((lapData) => {
                    Object.values(lapData).forEach((lapTime) => {
                        lapTimes.push(lapTime)
                    })
                })
                // Then gets the color scale for the lap times
                const colors = colorScale(lapTimes, 0xff523c, 0x3a4eff, 'normal')
                const lapTimesAndColors = {}
                lapTimes.forEach((lapTime, index) => {
                    lapTimesAndColors[ lapTime ] = colors[ index ]
                })
                // Then put the lap times and colors into the heat map
                allLaps.forEach((lapData) => {
                    const heatMap = {}
                    if(Object.keys(lapData).length === 0) {
                        heatMaps.push(heatMap)
                    } else {
                        Object.keys(lapData).forEach((carNumber) => {
                            heatMap[ carNumber ] = lapTimesAndColors[ lapData[ carNumber ] ]
                        })
                        heatMaps.push(heatMap)
                    }
                })
            } else if (props.radioOption === 'driver') {
                // First create an array of all drivers using lapData and using their car number as an array index in allDriverLapTimes
                const allDriverLapTimes = []
                    
                filteredLapData.forEach((driverData) => {
                    const carNumber = driverData.CAR_NUMBER
                    if(!allDriverLapTimes[carNumber]) {
                        allDriverLapTimes[carNumber] = []
                    }
                })
                //  Second using allDriverLapTimes and allLaps, create an array of all lap times for each driver. If there is no lap time for a driver put '-' in the array
                allDriverLapTimes.forEach((driverLapTimes, index) => {
                    for (let i = min; i <= max; i++) {
                        if (allLaps[i][index]) {
                            driverLapTimes.push(allLaps[i][index])
                        } else {
                            driverLapTimes.push(null)
                        }
                    }
                })
                // Then get the color scale for each driver
                const colors = []
                allDriverLapTimes.forEach((driverLapTimes, index) => {
                    colors[index] = (colorScale(driverLapTimes, 0xff523c, 0x3a4eff, 'normal'))
                })
                // Create an array of objects. Array index is the lap number. Each object contains the car number and the color for that lap
                allDriverLapTimes.forEach((driverLapTimes, index) => {
                    driverLapTimes.forEach((lapTime, lapNumber) => {
                        if (!heatMaps[lapNumber]) {
                            heatMaps[lapNumber] = {}
                        }
                        heatMaps[lapNumber][index] = colors[index][lapNumber]
                    })
                })
            } else if (props.radioOption === 'lap') {
                allLaps.forEach((lapData) => {
                    const lapTimes = Object.values(lapData)
                    const carNumbers = Object.keys(lapData)
                    const colors = colorScale(lapTimes, 0xff523c, 0x3a4eff, 'normal')
                    const heatMap = {}
                    carNumbers.forEach((carNumber, index) => {
                        heatMap[ carNumber ] = colors[ index ]
                    })
                    heatMaps.push(heatMap)
                })
            } 
            return heatMaps
        }
    }

    useEffect(() => {
        if (updatedTableData === undefined) return
        if (props.view === 'followRival') {
            let sortedTableData = updatedTableData.sort((a, b) => {
                return a.pos - b.pos
            })

            if(rivalCarNumbers && rivalCarNumbers.length > 0) {
                for (let i = rivalCarNumbers.length - 1; i >= 0; i--) {
                    const currentRivalCarNumber = rivalCarNumbers[i]
                    const rivalCarIndex = sortedTableData.findIndex((data) => data.carno.split('.')[1] === currentRivalCarNumber)
                
                    if (rivalCarIndex !== -1) {
                        const rivalCarData = sortedTableData.splice(rivalCarIndex, 1)[0]
                        sortedTableData.unshift(rivalCarData)
                    }
                }
            }
            if (followCarNumber !== '') {
                const followCarIndex = sortedTableData.findIndex((data) => data.carno.split('.')[1] === followCarNumber)
                const followCarData = sortedTableData.splice(followCarIndex, 1)[0]
                sortedTableData.unshift(followCarData)
            }

            setPositions(sortedTableData.map((data) => data.carno.split('.')[1]))
        } else if (props.view === 'car') {
            const sortedTableData = updatedTableData.sort((a, b) => {
                return Number(a.carno.split('.')[1]) - Number(b.carno.split('.')[1])
            })

            setPositions(sortedTableData.map((data) => data.carno.split('.')[1]))
        } else if (props.view === 'position') {
            const sortedTableData = updatedTableData.sort((a, b) => {
                return a.pos - b.pos
            })

            setPositions(sortedTableData.map((data) => data.carno.split('.')[1]))
        }
    }, [props.view, lapTimes, updatedTableData])

    return (
        <>
            <div className='lap-times-table-container'>

                <table className='lap-times-table'>
                    <thead>
                    { Object.keys(lapTimes[ Object.keys(lapTimes)[0] ] || {}).length > 0 ? (
                        <tr>
                            <th>LAP</th>
                            { positions.map((carNumber) => (
                                <th key = { carNumber }>{ carNumber }</th>
                            )) }
                        </tr>
                    ) : (
                        <tr>
                            <th>{t('general.lapTimeNotRecorded')}</th>
                        </tr>
                    )}
                    </thead>
                    <tbody>
                        {Object.keys(lapTimes).length > 0 ? (
                            props.order === 'descending' ? 
                                Array.from({ length: minMax[1] - minMax[0] + 1 }, (_, index) => {
                                const lapNumber = minMax[0] + index

                                return (
                                    <tr className='lap-times-Table-tr' key={lapNumber}>
                                    <td>{lapNumber}</td>
                                    {positions.map((carNumber) => (
                                        <td
                                        className='lap-times-Table-cell'
                                        style={{
                                            backgroundColor:
                                            heatMap[lapNumber - minMax[0]] &&
                                            heatMap[lapNumber - minMax[0]][carNumber] &&
                                            typeof heatMap[lapNumber - minMax[0]][carNumber] === 'number'
                                                ? `#${heatMap[lapNumber - minMax[0]][carNumber].toString(16).padStart(6, '0')}`
                                                : 'transparent'
                                        }}
                                        key={carNumber}
                                        >
                                        {lapTimes[carNumber] && lapTimes[carNumber][lapNumber] !== undefined ? (
                                            <TimeFormatter seconds={lapTimes[carNumber][lapNumber]} decimalPlaces={3} />
                                        ) : (
                                            '-'
                                        )}
                                        </td>
                                    ))}
                                    </tr>
                                )
                                })
                            : 
                                Array.from({ length: minMax[1] - minMax[0] + 1 }, (_, index) => {
                                const lapNumber = minMax[1] - index

                                return (
                                    <tr key={lapNumber}>
                                    <td>{lapNumber}</td>
                                    {positions.map((carNumber) => (
                                        <td
                                        className='lap-times-Table-cell'
                                        style={{
                                            backgroundColor:
                                            heatMap[lapNumber - minMax[0]] &&
                                            heatMap[lapNumber - minMax[0]][carNumber] &&
                                            typeof heatMap[lapNumber - minMax[0]][carNumber] === 'number'
                                                ? `#${heatMap[lapNumber - minMax[0]][carNumber].toString(16).padStart(6, '0')}`
                                                : 'transparent'
                                        }}
                                        key={carNumber}
                                        >
                                        {lapTimes[carNumber] && lapTimes[carNumber][lapNumber] !== undefined ? (
                                            <TimeFormatter seconds={lapTimes[carNumber][lapNumber]} decimalPlaces={3} />
                                        ) : (
                                            '-'
                                        )}
                                        </td>
                                    ))}
                                    </tr>
                                )
                                })
                        ) : null}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default LapHistory
