import React from 'react'

import SetupUserInputTable from './SetupUserInput_Table'

const SetupUserInputAlignmentTab = (props) => {
    const alignmentData = props.alignmentData
    const setIsEditMode = props.setIsEditMode
    const setDataToEdit = props.setDataToEdit
    const setEditedData = props.setEditedData
    const setSetup = props.setSetup

    return (
        <>
            <div className='alignment-container flex'>
                <div className='alignment-left'>
                    <SetupUserInputTable
                        title='FL'
                        data={ alignmentData.fl }
                        setIsEditMode={ setIsEditMode }
                        type='alignment'
                        setDataToEdit={ setDataToEdit }
                        setSetup={ setSetup }
                    />
                    <SetupUserInputTable
                        title='RL'
                        data={ alignmentData.rl }
                        setIsEditMode={ setIsEditMode }
                        type='alignment'
                        setDataToEdit={ setDataToEdit }
                        setSetup={ setSetup }
                    />
                </div>
                <div className='alignment-middle car-top'></div>
                <div className='alignment-right'>
                    <SetupUserInputTable
                        title='FR'
                        data={ alignmentData.fr }
                        setIsEditMode={ setIsEditMode }
                        type='alignment'
                        setDataToEdit={ setDataToEdit }
                        setEditedData={ setEditedData }
                        setSetup={ setSetup }
                    />
                    <SetupUserInputTable
                        title='RR'
                        data={ alignmentData.rr }
                        setIsEditMode={ setIsEditMode }
                        type='alignment'
                        setDataToEdit={ setDataToEdit }
                        setEditedData={ setEditedData }
                        setSetup={ setSetup }
                    />
                </div>
            </div>
        </>
    )
}

export default SetupUserInputAlignmentTab