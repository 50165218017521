import React from 'react'

import SetupUserInputTable from './SetupUserInput_Table'

const SetupUserInputAeroTab = (props) => {
    const aeroData = props.aeroData
    const setIsEditMode = props.setIsEditMode
    const setDataToEdit = props.setDataToEdit
    const setEditedData = props.setEditedData
    const setSetup = props.setSetup

    return (
        <>
            <div className='aero-car car-side'></div>
            <div>
                <SetupUserInputTable
                    title='REAR'
                    data={ aeroData.rear }
                    setIsEditMode={ setIsEditMode }
                    type='aero'
                    setDataToEdit={ setDataToEdit }
                    setEditedData={ setEditedData }
                    setSetup={ setSetup }
                />
            </div>
        </>
    )
}


export default SetupUserInputAeroTab