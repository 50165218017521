import { useEffect, useState} from 'react'
import * as DOMPurify from 'dompurify'

import '../css/Simulation.css'

import { useTranslation } from 'react-i18next'

const SimulationCircuitInformation = ({ setStrategyCircuitList, setCircuitRaceDistance, circuitRaceDistance }) => {
    const { t } = useTranslation(['strategy', 'general'])
    
    const [displayTable, setDisplayTable] = useState(false)
    const [circuitList, setCircuitList] = useState({})
    useEffect(() => {
        getAllStrategyCircuit()
            .then(json => {
                json.sort((a,b) => a.ID - b.ID)
                setStrategyCircuitList(json)
                setCircuitList(json)

                console.log(json)
            })
            .catch(error => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
    }, [])

    useEffect(() => {
        if (displayTable) {
            document.getElementById('circuitTable').style.display = 'block'
        } else {
            document.getElementById('circuitTable').style.display = 'none'
        }
    }, [])

    const onClickHandlerToggleButton = (e) => {
        if (!displayTable) {
            document.getElementById('circuitTable').style.display = 'block'
            setDisplayTable(true)
        } else {
            document.getElementById('circuitTable').style.display = 'none'
            setDisplayTable(false)
        }
    }

    const onBlurHandlerRaceDistance = (id) => (e) => {
        setCircuitRaceDistance(prev => ({
            ...prev,
            [id]: parseInt(DOMPurify.sanitize(e.target.value))
        }))
    }

    return (
        <>
            <button onClick={onClickHandlerToggleButton}>Toggle Circuit Table</button>
            <table border={1} id='circuitTable'>
                <thead>
                    <tr>
                        <th className='textAlign infoColumn1'>Circuit</th>
                        <th className='textAlign infoColumn'>Distance</th>
                        <th className='textAlign infoColumn'>Race Distance [Laps]</th>
                        <th className='textAlign infoColumn'>Fuel Effect [10kg/Lap]</th>
                        <th className='textAlign infoColumn'>Pit Road Loss Time [sec]</th>
                        {/* <th className='textAlign infoColumn'>Lap Count</th> */}
                        <th className='textAlign infoColumn'>Tyre Deg in Soft [sec]</th>
                        <th className='textAlign infoColumn'>Tyre Deg in Medium [sec]</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.values(circuitList).map((circuit, index) => {
                            const circuitInfo = circuit

                            if (!circuitInfo) return

                            const circuitId = circuit.ID
                            const raceDistance = circuitRaceDistance[circuitId]

                            return (
                                <tr key={index}>
                                    <td id={`NAME_${circuitId}`}>{circuitInfo.NAME}</td>
                                    <td id={`TRACK_LENGTH_${circuitId}`}>{circuitInfo.TRACK_LENGTH}</td>
                                    <td>
                                        <input className='inputNumber' id={`race_distance_${circuitId}`} type='number' defaultValue={raceDistance} onBlur={onBlurHandlerRaceDistance(circuitId)}/>
                                    </td>
                                    <td id={`fuel_effect_${circuitId}`}>{circuitInfo.FUEL_EFFECT}</td>
                                    <td id={`pit_road_loss_time_${circuitId}`}>{circuitInfo.PIT_ROAD_TIME}</td>
                                    {/* <td id={`lap_count_${circuitId}`}>{circuitInfo?.LAP_COUNT}</td> */}
                                    <td id={`tyre_deg_in_soft_${circuitId}`}>{circuitInfo.TYRE_DEG_IN_SOFT}</td>
                                    <td id={`tyre_deg_in_medium_${circuitId}`}>{circuitInfo.TYRE_DEG_IN_MEDIUM}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <br></br>
        </>
    )
}

export default SimulationCircuitInformation

function getAllStrategyCircuit() {
    return new Promise ((resolve, reject) => {
        fetch(`/api/circuit/getAll`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}