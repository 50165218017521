const processTimeSeriesData = (timeSeriesList, selectedGraph, keysToAddRemove, toRemove = false) => {    
    const existingPlotData = selectedGraph.plotData

    if (toRemove) {
        for ( let [axis, channelName] of Object.entries(keysToAddRemove)) {
            if (axis === 'id') continue

            if (existingPlotData[channelName][0].axis === axis) delete existingPlotData[channelName]
        }

        return existingPlotData
    } else {
        const toAddInPlotData = {}
        for ( let [axis, channelName] of Object.entries(keysToAddRemove)) {
            if (axis === 'id' || existingPlotData.hasOwnProperty(channelName) || !channelName) continue

            existingPlotData[channelName] = []

            timeSeriesList.forEach(timeSeries => {
                const { fileName, timeSeriesData } = timeSeries
                existingPlotData[channelName].push({
                    fileName,
                    data: timeSeriesData[channelName],
                    axis: axis,
                    id: keysToAddRemove.id
                })
            })
        }

        return { ...existingPlotData, ...toAddInPlotData }
    }
}

export default processTimeSeriesData