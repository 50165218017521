import * as ort from 'onnxruntime-web'


//BaseのObjectデータ作成
const  makeBaseONNXobject = (df_DOEcondition, df_baseSetup) => {
    let length = df_baseSetup.iloc({'columns':['1:']}).columns.length
    let df = df_baseSetup.iloc({'columns':['1:']}).T
    let label_input = df.columns
    let judge_input = df_baseSetup.T.iloc({'rows':[0]}).values[0]
    const Obj_baseSetup = {}

    for (let i = 0; i < df_DOEcondition.index.length; i++) {
        let judgeType = df_DOEcondition.iloc({'rows':[i], 'columns':[1]}).values[0][0]
        let tensor_input

        if (judge_input[i] === '-' || judge_input[i] === 'env' || judge_input[i] === 'ON') {     
            if (judgeType === '-') {
                    tensor_input = new ort.Tensor('string', df[label_input[i]].values, [length, 1])
                    Obj_baseSetup['x' + (i + 1)] = tensor_input
            } else {
                tensor_input = new ort.Tensor('float32', df[label_input[i]].values, [length, 1])
                Obj_baseSetup['x' + (i + 1)] = tensor_input
            }
        } else {
            let readParam = df_baseSetup.at(judge_input[i], label_input[i])
            if (typeof(readParam) === 'string') {
                tensor_input = new ort.Tensor('string', df[judge_input[i]].values, [length, 1])
            } else if(typeof(readParam) === 'number') {
                tensor_input = new ort.Tensor('float32', df[judge_input[i]].values, [length, 1])
            }
            Obj_baseSetup['x' + (i + 1)] = tensor_input
        }
    }
    return Obj_baseSetup
}

export default makeBaseONNXobject