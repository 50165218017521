// Setupファイルのプルダウンにしていた部分を連続値に変換(7/16)
const covertNameContinuous = (df, obj, reverse, start_num=0) => {
    if (reverse === 0){
        for(let i=start_num; i<df.columns.length; i++){
            for(let j = 0; j<df.index.length; j++){
                for(const key in obj){
                    if(df.index[j] === key){
                        let beforeValue = df.iloc({'columns':[i], 'rows':[j]}).values[0][0]
                        let changeValue = search_name(obj[key], beforeValue)

                        if(changeValue !== undefined && beforeValue !== undefined){
                            df = df.replace(beforeValue, changeValue === 0 ? changeValue.toString() : changeValue, { columns: [df.columns[i]] })
                        }
                    }
                }
            }
        }
    } else if(reverse === 1){
        for(let i = 0; i<df.index.length; i++){
            for(const key in obj){
                if(df.index[i] === key){
                    let beforeValue = df.iloc({'columns':[0], 'rows':[i]}).values[0][0]
                    if (beforeValue !== undefined) {
                        if (typeof beforeValue === 'number') {
                            let changeValue
    
                            for (let j = 0; j < obj[key].length; j++) {
                                if (beforeValue.toFixed(2) === obj[key][j].value.toFixed(2)) {
                                    changeValue = obj[key][j].name
                                }
                            }
    
                            if (changeValue !== undefined) {
                                df = df.replace(beforeValue, changeValue, { columns: [df.columns[0]] })
                            }
                        }
                    }
                }
            }
        }
    }

    return df
}

export default covertNameContinuous

function search_name(Obj, Val){
    let ans
    for(let i = 0; i<Obj.length; i++) {
        if (Obj[i].name === Val) {
            ans = Obj[i].value
        }
    }
    return ans
}