import React, { useEffect, useState } from 'react'

import { Scatter } from 'react-chartjs-2'
import Slider from '@mui/material/Slider'

import { Chart as ChartJS } from 'chart.js'

const TimeSeriesChartXY = (props) => {
    const initialChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            }
        },
        scales: {
            x: {
                type: 'linear',
                display: true,
                position: 'bottom',
                title: {
                    display: true,
                    text: '',
                },
                ticks: {
                    font: {
                        size: 10,
                    },
                    color: '#fff',
                    textStrokeColor: '#fff',
                    padding: 10
                },
                grid: {
                    color: '#fff',
                    borderColor: '#fff',
                }
            },
            y: {
                type: 'linear',
                display: true,
                title: {
                    display: true,
                },
                ticks: {
                    font: {
                        size: 10,
                    },
                    color: '#fff',
                    textStrokeColor: '#fff',
                    padding: 10
                },
                grid: {
                    color: '#fff',
                    borderColor: '#fff',
                }
            }
        }
    }

    const [ xMin, setXMin ] = useState(0)
    const [ xMax, setXMax ] = useState(50)

    const [ yMin, setYMin ] = useState(0)
    const [ yMax, setYMax ] = useState(50)

    const [ chartData, setChartData ] = useState({
        labels: [],
        datasets: [{
            label: '',
            data: [],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0)',
        }]
    })
    const [ chartOptions, setChartOptions ] = useState(initialChartOptions)

    const timeSeriesList = props.timeSeriesList || []
    const graphData = props.graphData
    const plotData = graphData.plotData
    const channels = graphData.channels

    const graphDataToPlot = []

    ChartJS.register({
        id: 'custom_canvas_background_color',
        beforeDraw: (chart) => {
            const { ctx, chartArea } = chart
            ctx.save()
            ctx.globalCompositeOperation = 'destination-over'
            ctx.fillStyle = 'rgba(175, 231, 255, 0.2)'
            ctx.fillRect(chartArea.left, chartArea.top, chartArea.width, chartArea.height)
            ctx.restore()
        }
    })

    useEffect(() => {
        channels.forEach(channel => {
            const xKey = channel.x
            const yKey = channel.y

            const timeSeriesData = plotData[xKey]

            if (!plotData[yKey]) return

            timeSeriesData.forEach((timeSeries, iTimeSeries) => {
                const { fileName, data } = timeSeries

                if (!data) return

                const sameTimeSeries = timeSeriesList.find(ts => ts.fileName === fileName)

                if (!sameTimeSeries.visible) return

                const color = sameTimeSeries.color
 
                const singleTimeSeriesPlotData = []
                data.forEach((d, iData) => {
                    singleTimeSeriesPlotData.push({
                        x: d,
                        y: plotData[yKey][iTimeSeries].data[iData]
                    })
                })

                const singleTimeSeriesData = {
                    plotData: singleTimeSeriesPlotData,
                    label: `${fileName}_${channel.x}_${channel.y}`,
                    color,
                    channel
                }

                graphDataToPlot.push(singleTimeSeriesData)
            })
        })

        let minX = Infinity
        let minY = Infinity
        let maxX = -Infinity
        let maxY = -Infinity

        const datasets = graphDataToPlot.map((data, i) => {
            const dataset = {
                label: data.label,
                data: data.plotData,
                backgroundColor: data.color,
                pointRadius: 2,
                showLine: false,
            }

            data.plotData.forEach(d => {
                minX = Math.min(minX, d.x)
                maxX = Math.max(maxX, d.x)
                minY = Math.min(minY, d.y)
                maxY = Math.max(maxY, d.y)
            })

            return dataset
        })

        if (minX !== Infinity) {
            setXMin(minX)
            setXMax(maxX)
            setYMin(minY)
            setYMax(maxY)
        }

        setChartData({
            labels: [],
            datasets: datasets.length > 0 ? datasets : [{
                label: '',
                data: [],
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(0, 0, 0, 0)',
            }]
        })

    }, [graphData, timeSeriesList])

    useEffect(() => {
        if (!chartOptions || !chartData.datasets.length) return

        setChartOptions(prevOptions => ({
            ...prevOptions,
            scales: {
                ...prevOptions.scales,
                x: {
                    ...(prevOptions.scales?.x || {}),
                    min: Math.trunc(xMin),
                    max: Math.trunc(xMax)
                }
            }
        }))
    }, [xMin, xMax])

    useEffect(() => {
        if (!chartOptions || !chartData.datasets.length) return

        setChartOptions(prevOptions => ({
            ...prevOptions,
            scales: {
                ...prevOptions.scales,
                y: {
                    ...(prevOptions.scales?.y || {}),
                    min: Math.trunc(yMin),
                    max: Math.trunc(yMax)
                }
            }
        }))
    }, [yMin, yMax])

    // useEffect(() => {
    //     console.log('hi', chartData)
    // }, [chartData])

    const xSliderHandler = (_, newValue) => {
        setXMin(newValue[0])
        setXMax(newValue[1])
    }

    const ySliderHandler = (_, newValue) => {
        setYMin(newValue[0])
        setYMax(newValue[1])
    }

    return (
        chartData.datasets.length && (
            <>
                <div className='display-flex'>
                    <Slider
                        max={ yMax }
                        min={ yMin }
                        orientation='vertical'
                        value={ [yMin, yMax] }
                        onChange={ ySliderHandler }
                        valueLabelDisplay='auto'
                        size='small'
                        sx={{
                            color: '#4AFFFF',
                            '& .MuiSlider-thumb': {
                                backgroundColor: '#4AFFFF',
                                width: '8px',
                                height: '8px'
                            },
                            '& .MuiSlider-thumb:hover': {
                                boxShadow: '0px 0px 0px 8px rgba(74, 255, 255, 0.16)'
                            }
                        }}
                    />
                    <div className='canvas-container'>
                        <Scatter data={ chartData } options={ chartOptions } />
                    </div>
                </div>
                <Slider
                    max={ xMax }
                    min={ xMin }
                    orientation='horizontal'
                    value={ [xMin, xMax] }
                    onChange={ xSliderHandler }
                    valueLabelDisplay='auto'
                    size='small'
                    sx={{
                        color: '#4AFFFF',
                        '& .MuiSlider-thumb': {
                            backgroundColor: '#4AFFFF',
                            width: '8px',
                            height: '8px'
                        },
                        '& .MuiSlider-thumb:hover': {
                            boxShadow: '0px 0px 0px 8px rgba(74, 255, 255, 0.16)'
                        }
                    }}
                />
            </>
        )
    )
}

export default TimeSeriesChartXY