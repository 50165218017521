import { useState, useEffect, useRef } from 'react'
import { TableContainer, Paper, Table, TableHead, TableRow, TableBody } from '@mui/material'
import { StyledHeaderTableCell, StyledTableCell } from '../components/TableStyle'


import { colorScale } from '../services/Util'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

import notRivalImage from '../image/no-rival.png'
import rivalImage from '../image/rival.png'


import { useTranslation } from 'react-i18next'



ChartJS.register(ArcElement, Tooltip, Legend)

const RivalSectorComparison = (props) => {
    const { t } = useTranslation(['strategy', 'general'])

    const byParameter = {
        lap: {cx: 1, cz: 1, weight: 1},
        sec1: {cx: 0.007, cz: 0.758, weight: 0.155},
        sec2: {cx: 0.115, cz: 0.014, weight: 0.720},
        sec3: {cx: 0.003, cz: 0.003, weight: 0.038},
        sec4: {cx: 0.875, cz: 0.225, weight: 0.088},
    }

    const bySector = {
        lap: {cx: .744, cz: .122, weight: .134},
        sec1: {cx: 0.357, cz: 0.328, weight: 0.315},
        sec2: {cx: 0.411, cz: 0.307, weight: 0.282},
        sec3: {cx: 0.910, cz: 0.038, weight: 0.052},
        sec4: {cx: 0.938, cz: 0.012, weight: 0.050},
    }

    const [sectorChartData, setSectorChartData] = useState()
    const [parameterChartData, setParameterChartData] = useState()

    const [ally, setAlly] = useState('')
    const [rival, setRival] = useState('')

    const [bestLapTable, setBestLapTable] = useState()
    const [bestLapTableCalculated, setBestLapTableCalculated] = useState()

    const [bestSectorTable, setBestSectorTable] = useState()
    const [bestSectorTableCalculated, setBestSectorTableCalculated] = useState()

    const [sectorLength, setSectorLength] = useState()
    const [sectorData, setSectorData] = useState([])
    const [fixedSectorData, setFixedSectorData] = useState([])
    const [lapTimeData, setLapTimeData] = useState([])
    const [driverList, setDriverList] = useState([])
    const [lapCount, setLapCount] = useState()

    const chartColors = [
        'rgba(130,255,128, 1)',
        'rgba(74, 255, 255, 1)',
        'rgba(255,82,61, 1)',
        'rgba(255,168,0, 1)',
    ]
    
    const secterChartColors = [
        'rgba(255,82,61, 1)',
        'rgba(74, 255, 255, 1)',
        'rgba(255,168,0, 1)',
    ]

    

    const borderColors = [
        'rgba(130,255,128, 1)',
        'rgba(74, 255, 255, 1)',
        'rgba(255,82,61, 1)',
        'rgba(255,168,0, 1)',
    ]

    const secterBorderColors = [
        'rgba(255,82,61, 1)',
        'rgba(74, 255, 255, 1)',
        'rgba(255,168,0, 1)',
    ]

    useEffect(() => {
        const getSectorData = () => {
            fetch(`/api/race/getAllSectorData/${props.raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    let data = json.data
                    setSectorData(data)

                    const lapCount = Math.max(...data.map((sector) => sector.LAP_NUMBER))
                    setLapCount(lapCount)

                    const driverList = []
                    data.forEach((sector) => {
                        const driver = sector.DRIVER_NAME
                        const carNumber = sector.CAR_NUMBER

                        const isDriverUnique = !driverList.some((item) => item.driver === driver)

                        if (isDriverUnique) {
                            driverList.push({ driver: driver, carNumber: carNumber })
                        }
                    })
                    driverList.sort((a, b) => a.carNumber - b.carNumber)
                    setDriverList(driverList)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getCircuit = () => {
            fetch(`/api/circuit/getCircuitInfo/${props.raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200) {
                    let data = json.data
                    setSectorLength(data[0].SECTOR_LENGTH)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        if (props.raceDetailId) {
            getSectorData()
            getCircuit()
        }
    }, [])

    useEffect(() => {
        if(sectorData.length === 0 || lapCount === 0) return
        const fixedSectors = [...sectorData]
    
        for(let i = 1; i <= lapCount; i++) {
            for(let j = 1; j <= sectorLength; j++) {
                const currentSectorData = sectorData.filter((sector) => sector.LAP_NUMBER === i && sector.SECTOR_NUMBER === j)
                const previousSector = sectorData.filter((sector) => sector.LAP_NUMBER === i - 1 && sector.SECTOR_NUMBER === j)

                currentSectorData.forEach((currentSector) => {
                    const carNumber = currentSector.CAR_NUMBER
                    if (previousSector.length > 0 && previousSector !== undefined) {
                        const driverPreviousSector = previousSector.find((previousSector) => previousSector.CAR_NUMBER === carNumber)
                        if (driverPreviousSector === undefined) {
                            let initialMutiplier = 2

                            const findPreviousSector = (mutiplier, i, j) => {
                                let missingLapNumber
                                let missingSectorNumber
                                if (j === 1) {
                                    missingLapNumber = i - mutiplier
                                    missingSectorNumber = sectorLength
                                }
                                else {
                                    missingLapNumber = i - mutiplier
                                    missingSectorNumber = j
                                }

                                const replacementSector = sectorData.find((sector) => sector.CAR_NUMBER === carNumber && sector.LAP_NUMBER === missingLapNumber && sector.SECTOR_NUMBER === missingSectorNumber)

                                if (replacementSector === undefined && missingLapNumber > 0) {
                                    mutiplier++
                                    findPreviousSector(initialMutiplier)
                                } else {
                                    return replacementSector
                                }
                            }
                            const addToSectorData = findPreviousSector(initialMutiplier, i, j)
                            if (addToSectorData !== undefined) {
                                const data = {
                                    CAR_NUMBER: addToSectorData.CAR_NUMBER,
                                    DRIVER: addToSectorData.DRIVER_NAME,
                                    TIME: addToSectorData.TIME,
                                    LAP_NUMBER: Number(addToSectorData.LAP_NUMBER) + 1,
                                    SECTOR_NUMBER: addToSectorData.SECTOR_NUMBER,
                                }
                                fixedSectors.push(data)
                            }
                        }
                    }
                })
            }
        }
        setFixedSectorData(fixedSectors)
    }, [sectorData])

    useEffect(() => {
        if(fixedSectorData.length === 0) return

        const calculateLapTimeData = () => {
            const lapTimeData = []
            for (let lapNumber = 1; lapNumber <= lapCount; lapNumber++) {
                const lap = fixedSectorData.filter(lap => lap.LAP_NUMBER === lapNumber)
                const lapData = lap.map(lap => ({
                    driver: lap.DRIVER_NAME,
                    carNumber: lap.CAR_NUMBER,
                    time: lap.TIME,
                    lap: lap.LAP_NUMBER,
                    sector: lap.SECTOR_NUMBER,
                }))
                const lapTimeDataByLap = driverList.map(driver => {
                    const driverLapData = lapData.filter(lap => lap.carNumber === driver.carNumber)
                    if (driverLapData.length === 0) return null
                    let lapTime = 0
                    
                    if (driverLapData.length >= sectorLength) {
                        lapTime = driverLapData.reduce((acc, cur) => acc + cur.time, 0)
                    }

                    return {
                    driver: driver.driver,
                    carNumber: driver.carNumber,
                    lapTime: lapTime,
                    lap: lapNumber,
                    }
                })
          
              lapTimeData.push(lapTimeDataByLap.filter(driver => driver !== null))
            }
            setLapTimeData(lapTimeData)
        }
          
        calculateLapTimeData()
    }, [fixedSectorData, sectorLength])

    const byParameterOuterLabels = ['Cx', 'Cz', 'Weight']
    const byParameterInnerLabels = Array.from({ length: sectorLength }, (_, i) => `Sector ${i + 1}`)

    useEffect(() => {
        const sectorDataArray = []
        const parameterDataArray = []
          
        Object.keys(byParameter.sec1).forEach(parameterKey => {
            const parameterData = {
                labels: byParameterInnerLabels,
                datasets: [
                    {
                        data: [byParameter.sec1[parameterKey], byParameter.sec2[parameterKey], byParameter.sec3[parameterKey], byParameter.sec4[parameterKey]],
                        backgroundColor: chartColors,
                        borderColor: borderColors,
                        borderWidth: 1,
                    },
                ],
            }
            parameterDataArray.push(parameterData)
        })

        Object.keys(bySector).forEach(sectorKey => {
            const parameterData = {
                labels: ['Cx', 'Cz', 'Weight'],
                datasets: [
                    {
                        data: Object.values(bySector[sectorKey]),
                        backgroundColor: secterChartColors,
                        borderColor: secterBorderColors,
                        borderWidth: 1,
                    },
                ],
            }
            sectorDataArray.push(parameterData)
        })

        setParameterChartData(parameterDataArray)
        setSectorChartData(sectorDataArray)
    }, [sectorLength])

    useEffect(() => {
        if (ally === '' || rival === '' || (ally === rival) || lapTimeData.length === 0) return
        const flatLapData = lapTimeData.flat()

        const filterAndFindMinLap = (carNumber) => {
            const filteredLapData = flatLapData.filter(lap => lap.carNumber === carNumber && lap.lapTime > 0)
            if (filteredLapData.length === 0) {
                return null
            }
            const minLapTime = Math.min(...filteredLapData.map(lap => lap.lapTime))
            return filteredLapData.find(lap => lap.lapTime === minLapTime)
        }

        const filterAndFindMinSector = (carNumber, sector) => {
            const filteredSectorData = fixedSectorData.filter(sectorData => sectorData.CAR_NUMBER === carNumber && sectorData.SECTOR_NUMBER === sector)
            if (filteredSectorData.length === 0) {
                return null
            }
            const minSectorTime = Math.min(...filteredSectorData.map(sectorData => sectorData.TIME))
            return filteredSectorData.find(sectorData => sectorData.TIME === minSectorTime)
        }
          
        const allyBestLap = filterAndFindMinLap(Number(ally))
        const rivalBestLap = filterAndFindMinLap(Number(rival))

        const allyBestLapSectors = fixedSectorData.filter(sectorData => sectorData.CAR_NUMBER === Number(ally) && sectorData.LAP_NUMBER === allyBestLap.lap)
        const rivalBestLapSectors = fixedSectorData.filter(sectorData => sectorData.CAR_NUMBER === Number(rival) && sectorData.LAP_NUMBER === rivalBestLap.lap)
        const allySectorTimes = []
        const rivalSectorTimes = []

        for (let i = 1; i <= sectorLength; i++) {
            const allySector = filterAndFindMinSector(Number(ally), i)
            const rivalSector = filterAndFindMinSector(Number(rival), i)
            allySectorTimes.push(allySector)
            rivalSectorTimes.push(rivalSector)
        }


        const bestLapTablePreCalculated = [
            {
                type: 'Ally',
                lap: allyBestLap.lapTime,
                sector1: allyBestLapSectors[0].TIME,
                sector2: allyBestLapSectors[1].TIME,
                sector3: allyBestLapSectors[2].TIME,
                sector4: sectorLength === 4 ?  allyBestLapSectors[3].TIME : 0
            },
            {
                type: 'Rival',
                lap: rivalBestLap.lapTime,
                sector1: rivalBestLapSectors[0].TIME,
                sector2: rivalBestLapSectors[1].TIME,
                sector3: rivalBestLapSectors[2].TIME,
                sector4: sectorLength === 4 ?  rivalBestLapSectors[3].TIME : 0
            },
            { 
                type: 'Diff', 
                lap: rivalBestLap.lapTime - allyBestLap.lapTime, 
                sector1: rivalBestLapSectors[0].TIME - allyBestLapSectors[0].TIME, 
                sector2: rivalBestLapSectors[1].TIME - allyBestLapSectors[1].TIME,
                sector3: rivalBestLapSectors[2].TIME - allyBestLapSectors[2].TIME,
                sector4: sectorLength === 4 ? rivalBestLapSectors[3].TIME - allyBestLapSectors[3].TIME : 0
            },
            {
                type: 'Diff Ratio',
                lap: (rivalBestLap.lapTime - allyBestLap.lapTime) / allyBestLap.lapTime, 
                sector1: (rivalBestLapSectors[0].TIME - allyBestLapSectors[0].TIME) / allyBestLapSectors[0].TIME,
                sector2: (rivalBestLapSectors[1].TIME - allyBestLapSectors[1].TIME) / allyBestLapSectors[1].TIME,
                sector3: (rivalBestLapSectors[2].TIME - allyBestLapSectors[2].TIME) / allyBestLapSectors[2].TIME,
                sector4: sectorLength === 4 ? (rivalBestLapSectors[3].TIME - allyBestLapSectors[3].TIME) / allyBestLapSectors[3].TIME : 0
            }
        ]

        const bestLapTableAfterCalculatedValues = []
        for (let i = 0; i <= sectorLength; i++) {
            let sector
            let parameter
            let preCalculated
        
            if (i === 0) {
                sector = bySector[`lap`]
                parameter = byParameter[`lap`]
                preCalculated = bestLapTablePreCalculated[3][`lap`] * 1000

            } else {
                sector = bySector[`sec${i}`]
                parameter = byParameter[`sec${i}`]
                preCalculated = bestLapTablePreCalculated[3][`sector${i}`] * 1000

            }

            const heatMapPreCalc = [
                sector.cx * parameter.cx * preCalculated,
                sector.cz * parameter.cz * preCalculated,
                sector.weight * parameter.weight * preCalculated
            ]

            const heatMapAfterCalc = colorScale(heatMapPreCalc, 0x3a4eff, 0xff523c, 'normal')

            const lapValues = {
                cx: { value: heatMapPreCalc[0], color: heatMapAfterCalc[0] },
                cz: { value: heatMapPreCalc[1], color: heatMapAfterCalc[1] },
                weight: { value: heatMapPreCalc[2], color: heatMapAfterCalc[2] }
            }
            bestLapTableAfterCalculatedValues.push(lapValues)
        }

        const bestLapTableAfterCalculated = [
            {
                type: 'Cx',
                lap: {value: bestLapTableAfterCalculatedValues[0].cx.value, color: bestLapTableAfterCalculatedValues[0].cx.color},
                sector1: {value: bestLapTableAfterCalculatedValues[1].cx.value, color: bestLapTableAfterCalculatedValues[1].cx.color},
                sector2: {value: bestLapTableAfterCalculatedValues[2].cx.value, color: bestLapTableAfterCalculatedValues[2].cx.color},
                sector3: {value: bestLapTableAfterCalculatedValues[3].cx.value, color: bestLapTableAfterCalculatedValues[3].cx.color},
                sector4: sectorLength === 4 ? {value: bestLapTableAfterCalculatedValues[4].cx.value, color: bestLapTableAfterCalculatedValues[4].cx.color} : 0
            },
            {
                type: 'Cz',
                lap: {value: bestLapTableAfterCalculatedValues[0].cz.value, color: bestLapTableAfterCalculatedValues[0].cz.color},
                sector1: {value: bestLapTableAfterCalculatedValues[1].cz.value, color: bestLapTableAfterCalculatedValues[1].cz.color},
                sector2: {value: bestLapTableAfterCalculatedValues[2].cz.value, color: bestLapTableAfterCalculatedValues[2].cz.color},
                sector3: {value: bestLapTableAfterCalculatedValues[3].cz.value, color: bestLapTableAfterCalculatedValues[3].cz.color},
                sector4: sectorLength === 4 ? {value: bestLapTableAfterCalculatedValues[4].cz.value, color: bestLapTableAfterCalculatedValues[4].cz.color} : 0
            },
            {
                type: 'Weight',
                lap: {value: bestLapTableAfterCalculatedValues[0].weight.value, color: bestLapTableAfterCalculatedValues[0].weight.color},
                sector1: {value: bestLapTableAfterCalculatedValues[1].weight.value, color: bestLapTableAfterCalculatedValues[1].weight.color},
                sector2: {value: bestLapTableAfterCalculatedValues[2].weight.value, color: bestLapTableAfterCalculatedValues[2].weight.color},
                sector3: {value: bestLapTableAfterCalculatedValues[3].weight.value, color: bestLapTableAfterCalculatedValues[3].weight.color},
                sector4: sectorLength === 4 ? {value: bestLapTableAfterCalculatedValues[4].weight.value, color: bestLapTableAfterCalculatedValues[4].weight.color} : 0
            },
        ]

        setBestLapTable(bestLapTablePreCalculated)
        setBestLapTableCalculated(bestLapTableAfterCalculated)
    
        const bestSectorPreCalculated = [
            {
                type: 'Ally',
                lap: allySectorTimes[0].TIME + allySectorTimes[1].TIME + allySectorTimes[2].TIME + (sectorLength === 4 ? allySectorTimes[3].TIME : 0),
                sector1: allySectorTimes[0].TIME,
                sector2: allySectorTimes[1].TIME,
                sector3: allySectorTimes[2].TIME,
                sector4: sectorLength === 4 ? allySectorTimes[3].TIME : 0
            },
            {
                type: 'Rival',
                lap: rivalSectorTimes[0].TIME + rivalSectorTimes[1].TIME + rivalSectorTimes[2].TIME + (sectorLength === 4 ? rivalSectorTimes[3].TIME : 0),
                sector1: rivalSectorTimes[0].TIME,
                sector2: rivalSectorTimes[1].TIME,
                sector3: rivalSectorTimes[2].TIME,
                sector4: sectorLength === 4 ? rivalSectorTimes[3].TIME : 0

            },
            { 
                type: 'Diff', 
                lap: (rivalSectorTimes[0].TIME + rivalSectorTimes[1].TIME + rivalSectorTimes[2].TIME + (sectorLength === 4 ? rivalSectorTimes[3].TIME : 0)) - (allySectorTimes[0].TIME + allySectorTimes[1].TIME + allySectorTimes[2].TIME + (sectorLength === 4 ? allySectorTimes[3].TIME : 0)),
                sector1: rivalSectorTimes[0].TIME - allySectorTimes[0].TIME, 
                sector2: rivalSectorTimes[1].TIME - allySectorTimes[1].TIME,
                sector3: rivalSectorTimes[2].TIME - allySectorTimes[2].TIME,
                sector4: sectorLength === 4 ? rivalSectorTimes[3].TIME - allySectorTimes[3].TIME : 0
            },
            {
                type: 'Diff Ratio',
                lap: ((rivalSectorTimes[0].TIME + rivalSectorTimes[1].TIME + rivalSectorTimes[2].TIME + (sectorLength === 4 ? rivalSectorTimes[3].TIME : 0)) - (allySectorTimes[0].TIME + allySectorTimes[1].TIME + allySectorTimes[2].TIME + (sectorLength === 4 ? allySectorTimes[3].TIME : 0))) / (allySectorTimes[0].TIME + allySectorTimes[1].TIME + allySectorTimes[2].TIME + (sectorLength === 4 ? allySectorTimes[3].TIME : 0)), 
                sector1: (rivalSectorTimes[0].TIME - allySectorTimes[0].TIME) / allySectorTimes[0].TIME,
                sector2: (rivalSectorTimes[1].TIME - allySectorTimes[1].TIME) / allySectorTimes[1].TIME,
                sector3: (rivalSectorTimes[2].TIME - allySectorTimes[2].TIME) / allySectorTimes[2].TIME,
                sector4: sectorLength === 4 ? (rivalSectorTimes[3].TIME - allySectorTimes[3].TIME) / allySectorTimes[3].TIME : 0
            }
        ]

        const bestSectorTableAfterCalculatedValues = []
        for (let i = 0; i <= sectorLength; i++) {
            let sector
            let parameter
            let preCalculated
        
            if (i === 0) {
                sector = bySector[`lap`]
                parameter = byParameter[`lap`]
                preCalculated = bestSectorPreCalculated[3][`lap`] * 1000

            } else {
                sector = bySector[`sec${i}`]
                parameter = byParameter[`sec${i}`]
                preCalculated = bestSectorPreCalculated[3][`sector${i}`] * 1000

            }

            const heatMapPreCalc = [
                sector.cx * parameter.cx * preCalculated,
                sector.cz * parameter.cz * preCalculated,
                sector.weight * parameter.weight * preCalculated
            ]

            const heatMapAfterCalc = colorScale(heatMapPreCalc, 0x3a4eff, 0xff523c, 'normal')

            const lapValues = {
                cx: { value: heatMapPreCalc[0], color: heatMapAfterCalc[0] },
                cz: { value: heatMapPreCalc[1], color: heatMapAfterCalc[1] },
                weight: { value: heatMapPreCalc[2], color: heatMapAfterCalc[2] }
            }
            bestSectorTableAfterCalculatedValues.push(lapValues)
        }

        const bestSectorTableAfterCalculated = [
            {
                type: 'Cx',
                lap: {value: bestSectorTableAfterCalculatedValues[0].cx.value, color: bestSectorTableAfterCalculatedValues[0].cx.color},
                sector1: {value: bestSectorTableAfterCalculatedValues[1].cx.value, color: bestSectorTableAfterCalculatedValues[1].cx.color},
                sector2: {value: bestSectorTableAfterCalculatedValues[2].cx.value, color: bestSectorTableAfterCalculatedValues[2].cx.color},
                sector3: {value: bestSectorTableAfterCalculatedValues[3].cx.value, color: bestSectorTableAfterCalculatedValues[3].cx.color},
                sector4: sectorLength === 4 ? {value: bestSectorTableAfterCalculatedValues[4].cx.value, color: bestSectorTableAfterCalculatedValues[4].cx.color} : 0
            },
            {
                type: 'Cz',
                lap: {value: bestSectorTableAfterCalculatedValues[0].cz.value, color: bestSectorTableAfterCalculatedValues[0].cz.color},
                sector1: {value: bestSectorTableAfterCalculatedValues[1].cz.value, color: bestSectorTableAfterCalculatedValues[1].cz.color},
                sector2: {value: bestSectorTableAfterCalculatedValues[2].cz.value, color: bestSectorTableAfterCalculatedValues[2].cz.color},
                sector3: {value: bestSectorTableAfterCalculatedValues[3].cz.value, color: bestSectorTableAfterCalculatedValues[3].cz.color},
                sector4: sectorLength === 4 ? {value: bestSectorTableAfterCalculatedValues[4].cz.value, color: bestSectorTableAfterCalculatedValues[4].cz.color} : 0
            },
            {
                type: 'Weight',
                lap: {value: bestSectorTableAfterCalculatedValues[0].weight.value, color: bestSectorTableAfterCalculatedValues[0].weight.color},
                sector1: {value: bestSectorTableAfterCalculatedValues[1].weight.value, color: bestSectorTableAfterCalculatedValues[1].weight.color},
                sector2: {value: bestSectorTableAfterCalculatedValues[2].weight.value, color: bestSectorTableAfterCalculatedValues[2].weight.color},
                sector3: {value: bestSectorTableAfterCalculatedValues[3].weight.value, color: bestSectorTableAfterCalculatedValues[3].weight.color},
                sector4: sectorLength === 4 ? {value: bestSectorTableAfterCalculatedValues[4].weight.value, color: bestSectorTableAfterCalculatedValues[4].weight.color} : 0
            },
        ]

        setBestSectorTable(bestSectorPreCalculated)
        setBestSectorTableCalculated(bestSectorTableAfterCalculated)
    }, [ally, rival])


    // useEffect(() => {
    //     const drawChart = () => {
    //     const ctx = chartRef.getContext('2d')
    
    //     const myChart = new ChartJS(ctx, {
    //         options: {
    //         plugins: [ShadowPlugin], 
    //         },
    //     })
    //     return myChart;
    //     }
    //     const chartInstance = drawChart()
    //     return () => {
    //         chartInstance.destroy()
    //     }
    // }, [])



    
    const TableheadStyle ={
        padding:'5px',
        fontWeight:'bold',
        color:'#fff',
        width: 'auto',
    }

    const TablecellStyle ={
        padding:'5px',
        whiteSpace: 'nowrap',
        width:'auto',
        color:'#000 !important',
    }


    return (
        sectorData.length > 0 && (
            <>
                <div className='rival-table-container'>
                    <h3 className='contribution-tit'>{t('rivalSectorComparison.sectorContribution')}</h3>
                    <div className='rival-chart-container factor-sec-table'>
                        {parameterChartData.map((data, index) => {
                            return (
                                <div key={`${index}sector`} className='rival-chart-inner-container'>
                                    <h3>{byParameterOuterLabels[index]}</h3>
                                    <div className='rival-chart-top'>
                                        <div className='chart-shadow'></div>
                                        <Pie data={data}  />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <h3 className='contribution-tit'>{t('rivalSectorComparison.factorContribution')}</h3>
                    <div className='rival-chart-container pc-rival-chart-container sec-table'>
                        {sectorChartData.map((data, index) => {
                            return (
                                <div key={`${index}para`} className='rival-chart-inner-container'>
                                    <h3>{index === 0 ? 'Lap' : `Sector ${index}`}</h3>
                                    <div className='rival-chart-bottom'>
                                        <div className='chart-shadow'></div>
                                        <Pie data={data} />
                                    </div>
                                    
                                </div>
                                
                            )
                        })}
                    </div>
                    </div>
                <div className='rival-table-container'>
                <h3 className='contribution-tit'>{t('rivalSectorComparison.analyzedCarSelection')}</h3>
                    <div className='rival-table-header-container'>
                    <img className='rival-img' src={rivalImage} alt='バトルマーク'/>
                        <div className='rival-table-header'>
                        <h3>{t('rivalSectorComparison.ownCar')}</h3>
                        <div class='select-box'>
                            <select className='rival-table-select' onChange={(e) => setAlly(e.target.value)}>
                                <option value={''}>{t('pleaseSelect', {ns: 'general'})}</option>
                                {driverList.map((driver, index) => {
                                    return (
                                        <option key={`${index}driver`} value={driver.carNumber}>{`${driver.carNumber}: ${driver.driver}`}</option>
                                    )
                                })}
                            </select>
                        </div>
                        </div>
                        <div className='rival-table-header'>
                        <h3>{t('rivalSectorComparison.rival')}</h3>
                        <div class='select-box'>
                            <select className='rival-table-select ' onChange={(e) => setRival(e.target.value)}>
                                <option value={''}>{t('pleaseSelect', {ns: 'general'})}</option>
                                {driverList.map((driver, index) => {
                                    return (
                                        <option key={`${index}driver`} value={driver.carNumber}>{`${driver.carNumber}: ${driver.driver}`}</option>
                                    )
                                })}
                            </select>
                        </div>
                        </div>
                    </div>
                    {bestLapTable && bestSectorTable && (
                        <div className='rival-table-main'>
                            <h3 className='rival-table-main-tit'>Best Lap</h3>
                            <div className='rival-table-display'>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <h4 className='table-sab-tit'>{t('rivalSectorComparison.timeComparison')}</h4>
                                    <div className='table-boxs'>
                                        <TableContainer sx={{height: '250px', width: '15rem'}}>
                                            <Table className='live-table lap-time-table' sx={{color: 'black'}}>
                                                <TableHead sx={{height: '50px'}}>
                                                    <TableRow>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}></StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Lap Time</StyledHeaderTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bestLapTable.map((row, index) => {
                                                        return (
                                                            <TableRow key={`${index}row`} sx={{backgroundColor: index % 2 === 0 ? '#e0e0e0' : 'white'}}>
                                                                <StyledTableCell classes={{ label: 'my-class-name' }} sx={{...TablecellStyle}}>{row.type}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle}}>{row.lap.toFixed(3)}</StyledTableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TableContainer component={Paper} sx={{height: '250px', width: '23rem'}}>
                                            <Table className='live-table secter-time-table'>
                                                <TableHead sx={{height: '50px'}}>
                                                    <TableRow>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 1</StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 2</StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 3</StyledHeaderTableCell>
                                                        {sectorLength === 4 && (
                                                            <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 4</StyledHeaderTableCell>
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bestLapTable.map((row, index) => {
                                                        return (
                                                            <TableRow key={`${index}row`} sx={{backgroundColor: index % 2 === 0 ? '#e0e0e0' : 'white'}}>
                                                                <StyledTableCell sx={{...TablecellStyle}}>{row.sector1.toFixed(3)}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle}}>{row.sector2.toFixed(3)}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle}}>{row.sector3.toFixed(3)}</StyledTableCell>
                                                                {sectorLength === 4 && (
                                                                    <StyledTableCell sx={{...TablecellStyle}}>{row.sector4.toFixed(3)}</StyledTableCell>
                                                                )}
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                <div className='rival-table-divider'/>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <h4 className='table-sab-tit'>{t('rivalSectorComparison.winLossEstimation')}</h4>
                                    <div className='table-boxs'>
                                        <TableContainer component={Paper} sx={{height: '250px', width: '10rem'}}>
                                            <Table className='live-table lap-table' sx={{color: 'black'}}>
                                                <TableHead sx={{height: '50px'}}>
                                                    <TableRow>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}></StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Lap</StyledHeaderTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bestLapTableCalculated.map((row, index) => {
                                                        return (
                                                            <TableRow key={`${index}row`}>
                                                                <StyledTableCell sx={{...TablecellStyle}}>{row.type}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle, backgroundColor: row.lap?.color !== undefined ? `#${row.lap.color?.toString(16).padStart(6, '0')}` : 'white'}}>{row.lap.value.toFixed(3)}</StyledTableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TableContainer component={Paper} sx={{height: '250px', width: '23rem'}}>
                                            <Table className='live-table' sx={{color: 'black'}}>
                                                <TableHead sx={{height: '50px'}}>
                                                    <TableRow>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 1</StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 2</StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 3</StyledHeaderTableCell>
                                                        {sectorLength === 4 && (
                                                            <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 4</StyledHeaderTableCell>
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bestLapTableCalculated.map((row, index) => {
                                                        return (
                                                            <TableRow key={`${index}row`}>
                                                                <StyledTableCell sx={{...TablecellStyle, backgroundColor: row.sector1?.color !== undefined ? `#${row.sector1.color?.toString(16).padStart(6, '0')}` : 'white'}}>{row.sector1.value.toFixed(3)}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle, backgroundColor: row.sector2?.color !== undefined ? `#${row.sector2.color?.toString(16).padStart(6, '0')}` : 'white'}}>{row.sector2.value.toFixed(3)}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle, backgroundColor: row.sector3?.color !== undefined ? `#${row.sector3.color?.toString(16).padStart(6, '0')}` : 'white'}}>{row.sector3.value.toFixed(3)}</StyledTableCell>
                                                                {sectorLength === 4 && (
                                                                    <StyledTableCell sx={{...TablecellStyle, backgroundColor: row.sector4?.color !== undefined ? `#${row.sector4.color?.toString(16).padStart(6, '0')}` : 'white'}}>{row.sector4.value.toFixed(3)}</StyledTableCell>
                                                                )}
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                            <h3 className='rival-table-main-tit'>Ideal Time</h3>
                            <div className='rival-table-display'>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <h4 className='table-sab-tit'>{t('rivalSectorComparison.timeComparison')}</h4>
                                    <div className='table-boxs'>
                                        <TableContainer component={Paper} sx={{height: '250px', width: '10rem'}}>
                                            <Table className='live-table lap-time-table' sx={{color: 'black'}}>
                                                <TableHead sx={{height: '50px'}}>
                                                    <TableRow>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}></StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Lap Time</StyledHeaderTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bestSectorTable.map((row, index) => {
                                                        return (
                                                            <TableRow key={`${index}row`} sx={{backgroundColor: index % 2 === 0 ? '#e0e0e0' : 'white'}}>
                                                                <StyledTableCell sx={{...TablecellStyle}}>{row.type}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle}}>{row.lap.toFixed(3)}</StyledTableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TableContainer component={Paper} sx={{height: '250px', width: '23rem'}}>
                                            <Table className='live-table secter-time-table ' sx={{color: 'black'}}>
                                                <TableHead sx={{height: '50px'}}>
                                                    <TableRow>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 1</StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 2</StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 3</StyledHeaderTableCell>
                                                        {sectorLength === 4 && (
                                                            <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 4</StyledHeaderTableCell>
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bestSectorTable.map((row, index) => {
                                                        return (
                                                            <TableRow key={`${index}row`} sx={{backgroundColor: index % 2 === 0 ? '#e0e0e0' : 'white'}}>
                                                                <StyledTableCell sx={{...TablecellStyle}}>{row.sector1.toFixed(3)}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle}}>{row.sector2.toFixed(3)}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle}}>{row.sector3.toFixed(3)}</StyledTableCell>
                                                                {sectorLength === 4 && (
                                                                    <StyledTableCell sx={{...TablecellStyle}}>{row.sector4.toFixed(3)}</StyledTableCell>
                                                                )}
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                <div className='rival-table-divider'/>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <h4 className='table-sab-tit'>{t('rivalSectorComparison.winLossEstimation')}</h4>
                                    <div className='table-boxs'>
                                        <TableContainer component={Paper} sx={{height: '250px', width: '10rem'}}>
                                                <Table className='live-table lap-table' sx={{color: 'black'}}>
                                                    <TableHead sx={{height: '50px'}}>
                                                        <TableRow>
                                                            <StyledHeaderTableCell sx={{...TableheadStyle}}></StyledHeaderTableCell>
                                                            <StyledHeaderTableCell sx={{...TableheadStyle}}>Lap</StyledHeaderTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {bestSectorTableCalculated.map((row, index) => {
                                                            return (
                                                                <TableRow key={`${index}row`}>
                                                                    <StyledTableCell sx={{...TablecellStyle}}>{row.type}</StyledTableCell>
                                                                    <StyledTableCell sx={{...TablecellStyle, backgroundColor: row.lap?.color !== undefined ? `#${row.lap.color?.toString(16).padStart(6, '0')}` : 'white'}}>{row.lap.value.toFixed(3)}</StyledTableCell>
                                                                </TableRow>
                                                            )
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        <TableContainer component={Paper} sx={{height: '250px', width: '23rem'}}>
                                            <Table className='live-table' sx={{color: 'black'}}>
                                                <TableHead sx={{height: '50px'}}>
                                                    <TableRow>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 1</StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 2</StyledHeaderTableCell>
                                                        <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 3</StyledHeaderTableCell>
                                                        {sectorLength === 4 && (
                                                            <StyledHeaderTableCell sx={{...TableheadStyle}}>Sector 4</StyledHeaderTableCell>
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {bestSectorTableCalculated.map((row, index) => {
                                                        return (
                                                            <TableRow key={`${index}row`}>
                                                                <StyledTableCell sx={{...TablecellStyle, backgroundColor: row.sector1?.color !== undefined ? `#${row.sector1.color?.toString(16).padStart(6, '0')}` : 'white'}}>{row.sector1.value.toFixed(3)}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle, backgroundColor: row.sector2?.color !== undefined ? `#${row.sector2.color?.toString(16).padStart(6, '0')}` : 'white'}}>{row.sector2.value.toFixed(3)}</StyledTableCell>
                                                                <StyledTableCell sx={{...TablecellStyle, backgroundColor: row.sector3?.color !== undefined ? `#${row.sector3.color?.toString(16).padStart(6, '0')}` : 'white'}}>{row.sector3.value.toFixed(3)}</StyledTableCell>
                                                                {sectorLength === 4 && (
                                                                    <StyledTableCell sx={{...TablecellStyle, backgroundColor: row.sector4?.color !== undefined ? `#${row.sector4.color?.toString(16).padStart(6, '0')}` : 'white'}}>{row.sector4.value.toFixed(3)}</StyledTableCell>
                                                                )}
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        )
    )
}

export default RivalSectorComparison