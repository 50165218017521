import React, { useState, useEffect } from 'react'
import { Scatter } from 'react-chartjs-2'
import * as d3 from 'd3'

import search_columns from '../services/search_columns'
import { baseColors, recommendColor, recommendColors } from '../services/plotColors'

const ScatterPlot = ({ id, list, df_scatter, df_base, selectedX, selectedY, selectedColorChart, isSPM, selectedIndex }) => {
    const [ chartData, setChartData ] = useState({})
    const [ chartOptions, setChartOptions ] = useState({})

    const chartId = id

    let stringNumber = { x: [], y: [] }

    useEffect(() => {
        let Plot_x_name = search_columns(df_scatter, selectedX)
        let Plot_y_name = search_columns(df_scatter, selectedY)
        let ColorScale_name = search_columns(df_scatter, selectedColorChart)

        let myArray = []

        if (list !== 0) {
            let x_ans_handling = df_scatter.iloc({ rows: list })[Plot_x_name].values[0]
            let y_ans_handling = df_scatter.iloc({ rows: list })[Plot_y_name].values[0]
            let Data_ans_handling = [{ x: x_ans_handling, y: y_ans_handling }];
            myArray[0] = {
                label: 'Answer_Data',
                data: Data_ans_handling,
                backgroundColor: recommendColor,
                pointRadius: 6,
                pointStyle: 'rectRot',
                showLine: false,
            }
        }

        if (selectedIndex) {
            let x_selected_handling, y_selected_handling
            if (selectedIndex[0] === -1) {
                x_selected_handling = df_base[Plot_x_name].values[0]
                y_selected_handling = df_base[Plot_y_name].values[0]
            } else {
                x_selected_handling = df_scatter.iloc({ rows: selectedIndex })[Plot_x_name].values[0]
                y_selected_handling = df_scatter.iloc({ rows: selectedIndex })[Plot_y_name].values[0]
            }
            let Data_selected_handling = [{ x: x_selected_handling, y: y_selected_handling }]
            myArray[1] = {
                label: 'Selected',
                data: Data_selected_handling,
                backgroundColor: '#33CCFF',
                pointRadius: 6,
                pointStyle: 'rectRot',
                showLine: false,
            }
        }

        let baseCount = 0
        let recommendCount = 0
        for (let i = 0; i < df_base.index.length; i++) {
            let x_base_handling = df_base[Plot_x_name].values[i];
            let y_base_handling = df_base[Plot_y_name].values[i];
            let Data_base_handling = [{ x: x_base_handling, y: y_base_handling }];
            let label = df_base.index[i]
            label = label[label.length - 1] === '✖' ? label.slice(0, -1) : label

            let color
            if (label.toString().includes('Recommend')) {
                color = recommendColors[(recommendCount) % recommendColors.length]
                recommendCount++
            } else {
                color = baseColors[(baseCount) % baseColors.length]
                baseCount++
            }

            myArray[myArray.length] = {
                label: label,
                data: Data_base_handling,
                backgroundColor: color,
                pointRadius: 4,
                showLine: false,
            }
        }

        let x_scatter_handling = df_scatter[Plot_x_name].values
        let y_scatter_handling = df_scatter[Plot_y_name].values

        let scat_color_scaling = []
        if (ColorScale_name) {
            let scat_color = df_scatter[ColorScale_name].values
            const colscale = d3.scaleLinear().domain([Math.min(...scat_color), Math.max(...scat_color)]).range([255, 0])
            scat_color_scaling = scat_color.map((value) => getGammaRGB(colscale(value)))
        }

        let Data_scatter_handling = x_scatter_handling.map( (v,i) => ({ x: v, y: y_scatter_handling[i] }) )

        if (isNaN(Data_scatter_handling[0].x)) convertToNumber('x')
        if (isNaN(Data_scatter_handling[0].y)) convertToNumber('y')

        function convertToNumber(type) {
            stringNumber[type] = stringNumber[type].filter(s => s.chartId !== chartId)
            
            Data_scatter_handling.forEach(d => {
                let number = stringNumber[type].findIndex(s => s.name === d[type] && s.chartId === chartId) + 1

                if (!number) {
                    number = stringNumber[type].length + 1
                    stringNumber[type].push({ name: d[type], number, chartId })
                }

                d[type]= number
            })
        }

        myArray[myArray.length] = {
            label: 'Scatter_Data',
            data: Data_scatter_handling,
            backgroundColor: scat_color_scaling,
            pointBorderColor: scat_color_scaling,
            pointRadius: 2,
            showLine: false,
        }

        const chartData = {
            datasets: myArray,
        }

        setChartData(chartData)

        const chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
                tooltip: {
                    filter: function (tooltipItem) {
                        return tooltipItem.dataset.label !== 'Scatter_Data';
                    }
                }
            },
            scales: {
                x: {
                    type: 'linear',
                    display: true,
                    position: 'bottom',
                    title: {
                        display: isSPM ? false : true,
                        text: Plot_x_name,
                        color: 'white'
                    },
                    ticks: {
                        color: 'white',
                        font: {
                            size: 8,
                        },
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    title: {
                        display: isSPM ? false : true,
                        text: Plot_y_name,
                        color: 'white'
                    },
                    ticks: {
                        color: 'white',
                        font: {
                            size: 8,
                        },
                    }
                }
            }
        }

        if (stringNumber.x.length) {
            chartOptions.scales.x.ticks = {
                font: {
                    size: 7
                },
                callback: (value) => {
                    const findIndex = stringNumber.x.findIndex(s => s.number === value && s.chartId === chartId)
    
                    if (findIndex !== -1) {
                        const label = stringNumber.x[findIndex].name
                        return label
                    }
                }
            }
        }

        if (stringNumber.y.length) {
            chartOptions.scales.y.ticks = {
                font: {
                    size: 7
                },
                callback: (value) => {
                    const findIndex = stringNumber.y.findIndex(s => s.number === value && s.chartId === chartId)
    
                    if (findIndex !== -1) {
                        const label = stringNumber.y[findIndex].name
                        return label
                    }
                }
            }
        }

        setChartOptions(chartOptions)
    }, [list, df_scatter, df_base, selectedX, selectedY, selectedColorChart, selectedIndex])

    return (chartData.datasets && <Scatter data={chartData} options={chartOptions} /> )
}

export default ScatterPlot

function getGammaRGB(g) {
    if (!g) return 0

    const gamma = 0.5

    let val = ~~(255 * Math.pow((g / 255), 1 / gamma))

    return `rgb(${val}, ${val}, ${val})`
}