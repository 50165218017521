import { useTranslation } from "react-i18next"

const SelectBoxLabels = ({ title, onChangeType, setState, options, value }) => {
    const { t } = useTranslation(['general'])

    const onChangeHandler = (e) => {
        setState(e.target.value)
    }

    const arrayOptions = typeof options[0] === 'string' ? options : options.map(option => option.id)

    return (
        <div className='select-child'>
            <span className='select-title'>{ title }</span>
            <select
                value={ value }
                data-type={ onChangeType }
                onChange={ onChangeHandler }
            >
                <option value='' disabled>{t('pleaseSelect')}</option>
                {arrayOptions.map(option => (
                    <option key={`${option}_${Math.floor(Math.random() * 999)}`} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        </div>
    )
}

export  default SelectBoxLabels