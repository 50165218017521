const classAndColorObject = {
    'ST-1': { backgroundColor: '#f39800', textColor: '#ffffff' },
    'ST-3': { backgroundColor: '#0081cc', textColor: '#ffffff' },
    'ST-4': { backgroundColor: '#e60012', textColor: '#ffffff' },
    'ST-5': { backgroundColor: '#008b4f', textColor: '#ffffff' },
    'ST-Q': { backgroundColor: '#808080', textColor: '#ffffff' },
    'ST-TCR': { backgroundColor: '#fadce9', textColor: '#000000' },
    'ST-X': { backgroundColor: '#ffea00', textColor: '#000000' },
    'ST-Z': { backgroundColor: '#d3edfb', textColor: '#000000' },
    '4WD': { backgroundColor: '#ffffff', textColor: '#000000' },
    'D2': { backgroundColor: '#004097', textColor: '#ffffff' },
    'D3': { backgroundColor: '#019946', textColor: '#ffffff' },
    'D4': { backgroundColor: '#e90b1a', textColor: '#ffffff' },
    'D5': { backgroundColor: '#f19021', textColor: '#ffffff' },
    'NZ': { backgroundColor: '#00a1e9', textColor: '#ffffff' },
    'Lady OMR': { backgroundColor: '#e5007f', textColor: '#ffffff' },
    'Yaris OMR': { backgroundColor: '#faf105', textColor: '#000000' },
}

const ClassColorDisplay = (carClass, type) => {
    const carClassObject = classAndColorObject[carClass]
    let bgColor = '#ffffff'
    let textColor = '#000000'

    if (carClassObject) {
        bgColor = carClassObject.backgroundColor
        textColor = carClassObject.textColor
    }

    return <div className={`class-display-${type}`} style={{ backgroundColor: bgColor, color: textColor }}>{carClass}</div>
}

export default ClassColorDisplay
