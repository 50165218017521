import React, { useState, useEffect } from 'react'

const AuthContext = React.createContext({
    isLoggedIn: false,
    isAdmin: false,
    userId: null,
    role: null,
    isLoginChecked: false,
    onLogin: () => {}
})

export const AuthContextProvider = (props) => {
    const [ isLoggedIn, setIsLoggedIn ] = useState(false)
    const [ isAdmin, setIsAdmin ] = useState(false)
    const [ userId, setUserId ] = useState(null)
    const [ userRole, setUserRole ] = useState(null)
    const [ isLoginChecked, setIsLoginChecked ] = useState(false)

    useEffect(() => {
        const storedUserLoggedInIn = localStorage.getItem('isLoggedIn')
        const storedUserId = localStorage.getItem('userId')
        const storedUserRole = localStorage.getItem('userRole')

        if (storedUserLoggedInIn === 'true') {
            setIsLoggedIn(true)
            setIsAdmin(storedUserRole === 'admin')
            setUserId(storedUserId)
            setUserRole(storedUserRole)
        } else {
            localStorage.removeItem('isLoggedIn')
            localStorage.removeItem('userId')
            localStorage.removeItem('userRole')
        }
        setIsLoginChecked(true)
    }, [])

    const loginHandler = (userId, role) => {
        localStorage.setItem('userId', userId)
        localStorage.setItem('userRole', role)
        localStorage.setItem('isLoggedIn', 'true')

        setIsLoggedIn(true)
        setIsAdmin(role === 'admin')
        setUserId(userId)
        setUserRole(role)
    }

    return (
        <AuthContext.Provider value = {{
            isLoggedIn: isLoggedIn,
            isAdmin: isAdmin,
            userId: userId,
            role: userRole,
            isLoginChecked: isLoginChecked,
            onLogin: loginHandler
        }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext
