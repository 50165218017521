import React from 'react';

import { useTranslation } from 'react-i18next';

import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import grapbg from '../image/best-lap-container.png'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OutingBarGraph = ({ lapDatas }) => {
  const { t } = useTranslation(['strategy']);

  const timeValues2D = Object.values(lapDatas).map((carNumberLapdatas) =>
    carNumberLapdatas.map((lapData) => lapData.TIME)
  );
  const allCarsAttackLap = timeValues2D.map((carNumberTimeArray) =>
    carNumberTimeArray.indexOf(Math.min(...carNumberTimeArray))
  );

  const attackCount = {};
  allCarsAttackLap.forEach((lap) => {
    attackCount[lap + 1] = (attackCount[lap + 1] || 0) + 1;
  });

  const graphData = Object.entries(attackCount).map(([lap, count]) => ({
    lap: lap,
    count,
    percentage:
      (count /
        Object.values(attackCount).reduce((total, val) => total + val, 0)) *
      100,
  }));

  //--------------レース上の注意---------------//
  //ラップ数１のデータはアタックとして成立しない
  //-----------------------------------------//
  const hasLap1 = graphData.some((data) => data.lap === '1');
  if (!hasLap1) {
    graphData.unshift({
      lap: '1',
      count: 0,
      percentage: 0,
    });
  }

  const chartData = {
    labels: graphData.map((data) => data.lap),
    datasets: [
      {
        label: t('outinBarGraph.lapCountToBest'),
        fontColor: 'black', // テキストの色を黒に設定
        data: graphData.map((data) => data.percentage),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };

  const addOptions = {
    responsive: true,
    plugins: {
      legend: { labels: { font: { family: 'Roboto', size: 18} } },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: { family: 'Helvetica Neue', size: 16 },
        bodyFont: { family: 'Helvetica Neue', size: 14 },
        padding: 10,
        cornerRadius: 5,
      },
    },
    scales: {
      x: {
        ticks: { font: { size: 20 }, color: 'white'  },
      },
      y: {
        ticks: {
          font: { size: 20 },
          callback: function (value) {
            return value + '%';
          },
        color: 'white'},
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className='best-lap-container'>
      <img className='best-lap-container-bg' src={grapbg} alt=''/>
      <div className='best-lap-grah'>
        <Bar data={chartData} options={addOptions} width={1600} height={587}  />
        <span>{t('general.lapCount')}
          <ul className='lap-grah-base'>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </span>
      </div>
    </div>
  );
};

export default OutingBarGraph;
