import React from 'react'

const FPLive = ({ children }) => {
    return (
        <>
            {children}
        </>
    )
}

export default FPLive