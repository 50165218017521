import { useEffect, useState } from 'react'

import { useLiveData } from '../../DataContext'

import ProgressBarCell from './ProgressBarCell'
import { toFixedIfNumber } from '../services/Util'

import { SecTableCell } from './TableStyle'

const SectorTableCell = (props) => {
    const [classColor, setClassColor] = useState('white')
    const [innerVal, setInnerVal] = useState('')
    const [ifComponent, setIfComponent] = useState('')
    const [row, setRow] = useState([])
    const sectorNumber = props.sectorNo
    const prevTime = props.prevTime
    const isInPit = props.row.isPitIn

    const { sectorLength, overallBest } = useLiveData()

    const setWhiteEmpty = (val) => {
        setIfComponent('WhiteEmptyCell')
        setInnerVal(val)
        if (val === '-'){
            setClassColor('no-data-black')
        } else{
            setClassColor('no-data')
        }
    }

    const setProgressBarCell = (val) => {
        setIfComponent('ProgressBarCell')
        setInnerVal(val)
    }

    const setDisplayTime = (currentTime, bestTime) => {
        setIfComponent('DisplayTimeCell')

        if (currentTime <= overallBest[`sec${sectorNumber}`] ) {
            setClassColor('overall-best')
        } else if (!bestTime || currentTime <= bestTime) {
            setClassColor('personal-best')
        } else {
            setClassColor('current-sector')
        }

        setInnerVal(toFixedIfNumber(currentTime, 3))
    }

    const set3Sector = () => {
        if (sectorNumber === '1') {
            if (!row.sec1 && !row.sec2 && !row.sec3) {
                setProgressBarCell(prevTime || 20)
            } else if (row.sec2 && row.sec3) {
                setProgressBarCell(prevTime || 20)
            } else if (row.sec2 && !row.sec1) {
                setWhiteEmpty('-')
            } else if (row.sec1) {
                setDisplayTime(row.sec1, row.sec1Best)
            }
        } else if (sectorNumber === '2') {
            if (row.sec2) {
                setDisplayTime(row.sec2, row.sec2Best)
            } else if (row.sec3 && !row.sec2) {
                setWhiteEmpty('-')
            } else if (row.sec1) {
                setProgressBarCell(prevTime || 20)
            }
        } else if (sectorNumber === '3') {
            if (row.sec3) {
                setDisplayTime(row.sec3, row.sec3Best)
            } else if (row.sec2) {
                setProgressBarCell(prevTime || 20)
            } else if (!row.sec2) {
                setWhiteEmpty()
            }
        }
    }

    const set4Sector = () => {
        if (sectorNumber === '1') {
            if (!row.sec1 && !row.sec2 && !row.sec3 && !row.sec4) {
                setProgressBarCell(prevTime || 20)
            } else if (row.sec2 && row.sec3 && row.sec4) {
                setProgressBarCell(prevTime || 20)
            } else if (row.sec2 && !row.sec1) {
                setWhiteEmpty('-')
            } else if (row.sec1) {
                setDisplayTime(row.sec1, row.sec1Best)
            }
        } else if (sectorNumber === '2') {
            if (row.sec2) {
                setDisplayTime(row.sec2, row.sec2Best)
            } else if (row.sec3 && !row.sec2) {
                setWhiteEmpty('-')
            } else if (row.sec1) {
                setProgressBarCell(prevTime || 20)
            }
        } else if (sectorNumber === '3') {
            if (row.sec3) {
                setDisplayTime(row.sec3, row.sec3Best)
            } else if (row.sec4 && !row.sec3) {
                setWhiteEmpty('-')
            } else if (row.sec2) {
                setProgressBarCell(prevTime || 20)
            } else if (!row.sec2) {
                setWhiteEmpty()
            }
        } else if (sectorNumber === '4') {
            if (row.sec4) {
                setDisplayTime(row.sec4, row.sec4Best)
            } else if (row.sec3) {
                setProgressBarCell(prevTime || 20)
            } else if (!row.sec3) {
                setWhiteEmpty()
            }
        }
    }

    useEffect(() => {
        setRow(props.row)

        if (sectorLength === 3) {
            set3Sector()
        } else {
            set4Sector()
        }
    }, [props])

    if (ifComponent === 'ProgressBarCell') {
        return <SecTableCell align = 'right' className='no-data sector-cell cell-items' style={{ border: '3px solid #242424', boxSizing: 'border-box' }}><ProgressBarCell interval={ innerVal } isInPit = { isInPit }/></SecTableCell>
    } else if (ifComponent === 'DisplayTimeCell') {
        return <SecTableCell align = 'right' className={`${classColor} sector-cell cell-items`} style={{ border: '3px solid #242424', boxSizing: 'border-box'}}>{ innerVal }</SecTableCell>
    } else {
        return <SecTableCell align='right' className={`no-data ${classColor}`} style={{ border: '3px solid #242424', boxSizing: 'border-box'}}>{innerVal}</SecTableCell>

    }
}

export default SectorTableCell