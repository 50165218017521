import React, { useEffect, useState } from 'react'

import * as dfd from 'danfojs'

import SelectBoxLabels from './SelectBoxLabels'
import SetupTable from './SetupTable'
import LineChart from './LineChart'
import CornerChart from './CornerChart'
import CompareTimeChart from './CompareTimeChart'

import { useTranslation } from 'react-i18next'

const ResultPerformance = (props) => {
    const { t } = useTranslation(['core'])

    const [ chartOptions, setChartOptions ] = useState([])
    const [ scatGroupLabels, setScatGroupLabels ] = useState([])
    const [ lineLabels, setLineLabels ] = useState([])
    
    const [ selectedTable, setSelectedTable ] = useState('Time')
    const [ selectedChart, setSelectedChart ] = useState('')
    const [ selectedGraphGroup, setSelectedGraphGroup ] = useState('')
    const [ selectedCorner, setSelectedCorner ] = useState('All')

    const [ parameterNames, setParameterNames ] = useState([])
    const [ df_showUserInput, setDf_showUserInput ] = useState()

    const [ scat_chart_1, setScat_chart_1 ] = useState(props.scat_chart_1)
    const [ scat_chart_2, setScat_chart_2 ] = useState(props.scat_chart_2)
    const [ scat_chart_3, setScat_chart_3 ] = useState(props.scat_chart_3)

    const [ df_baseHandling, setDf_baseHandling ] = useState()
    const [ df_baseAero, setDf_baseAero ] = useState()
    const [ df_baseMech, setDf_baseMech ] = useState()

    const [ df_scatHandling, setDf_scatHandling ] = useState(props.df_scatHandling)
    const [ df_scatAero, setDf_scatAero ] = useState(props.df_scatAero)
    const [ df_scatMech, setDf_scatMech ] = useState(props.df_scatMech)

    const [ showAllHandling, setShowAllHandling ] = useState(false)

    const [ displayTimeResult, setDisplayTimeResult ] = useState(true)
    const [ displayUserInputResult, setDisplayUserInputResult ] = useState(true)
    const [ displayCompareTimeResult, setDisplayCompareTimeResult ] = useState(true)
    const [ displayScatResult, setDisplayScatResult ] = useState(true)
    const [ displayCornerKPIResult, setDisplayCornerKPIResult ] = useState(true)

    const df_showTime = props.df_showTime
    const df_showKPI = props.df_showKPI

    const df_baseSetup = props.df_baseSetup
    const df_mapDriver = props.df_mapDriver

    const df_baseInput = props.df_baseInput
    const df_baseTime = props.df_baseTime
    const Obj_base_result = props.Obj_base_result

    const df_scatInput = props.df_scatInput
    const df_scatTime = props.df_scatTime
    const Obj_scat_result = props.Obj_scat_result

    const df_compareTime = props.df_compareTime

    const listAns = props.listAns

    const Obj_parameter_combination = props.Obj_parameter_combination

    const Obj_chart_combination = props.Obj_chart_combination
    const Obj_circuit_sens = props.Obj_circuit_sens
    const Obj_KPI_base = props.Obj_KPI_base
    const Obj_KPI_recommend = props.Obj_KPI_recommend

    let handlingIndex, handlingNotEmpty = []
    let aeroIndex = []
    let mechanicalIndex = []

    useEffect(() => {
        setDf_baseHandling(props.df_baseHandling)
        setDf_baseAero(props.df_baseAero)
        setDf_baseMech(props.df_baseMech)
    }, [props])

    useEffect(() => {
        if (df_mapDriver) {
            handlingIndex = df_mapDriver.columns.indexOf('Handling')
            aeroIndex = df_mapDriver.columns.indexOf('Aero Balance')
            mechanicalIndex = df_mapDriver.columns.indexOf('mechanical Balance')
            df_mapDriver.values.forEach((v, i) => {
                if (v[handlingIndex] !== '-' || v[aeroIndex] !== '-' || v[mechanicalIndex] !== '-') {
                    handlingNotEmpty.push(i)
                }
            })
            const tempParameterNames = handlingNotEmpty.map(i => df_mapDriver.index[i])
            setParameterNames(tempParameterNames)
        }

        if (Object.keys(Obj_chart_combination).length === 0) return

        const temChartOptions = Obj_chart_combination['Balance'].map(c => c.name)
        setChartOptions(temChartOptions)
        setSelectedChart(temChartOptions[0])
    }, [df_mapDriver])

    useEffect(() => {
        const getScatGroupLabels = () => {
            if (!Obj_chart_combination) return []

            const labels = Object.keys(Obj_chart_combination)
            labels.pop()

            setSelectedGraphGroup(labels[0])
            return labels
        }

        const getLineLabels = () => {
            if (Object.keys(Obj_circuit_sens).length === 0) return []
            
            const labels = ['All', ...Obj_circuit_sens.map(item => item.name)]
            setSelectedCorner(labels[0])
            return labels
        }

        setScatGroupLabels(getScatGroupLabels())
        setLineLabels(getLineLabels())
    }, [Obj_circuit_sens])

    useEffect(() => {
        if (parameterNames.length === 0 || !listAns) return
        if (!df_baseSetup) return

        const df_base = Obj_base_result[selectedChart]
        const df_scat = Obj_scat_result[selectedChart]

        const labelBase = df_baseSetup.iloc({'columns':['1:']}).columns
        const recommendCount = labelBase.filter(l => l.includes('Recommend'))
        let counter = 0
        if (recommendCount.length > 0) {
            labelBase.forEach((l, i) => {
                if (l.includes('Recommend')) {
                    labelBase[i] = `Recommend_${counter}`
                    counter++
                }
            })
        }
        df_base.setIndex({index: labelBase, inplace: true})

        const round_num = 3
        let tempDf_showUserInput = new dfd.DataFrame({'Recommend': df_scat.iloc({'rows':[listAns[0]], 'columns':['1:']}).round(round_num).T.values}, {index: df_scat.iloc({'columns':['1:']}).columns})
        // let tempDf_showUserInputMax = new dfd.DataFrame({'Recommend_max': df_scat.iloc({'rows':[listAnsMinMax.max], 'columns':['1:']}).round(round_num).T.values}, {index: df_scat.iloc({'columns':['1:']}).columns})
        // let tempDf_showUserInputMin = new dfd.DataFrame({'Recommend_min': df_scat.iloc({'rows':[listAnsMinMax.min], 'columns':['1:']}).round(round_num).T.values}, {index: df_scat.iloc({'columns':['1:']}).columns})
        const df_showUserInputRecommend = tempDf_showUserInput['Recommend']
        tempDf_showUserInput.drop({ columns: ['Recommend'], inplace: true })
        tempDf_showUserInput = dfd.concat({ dfList: [tempDf_showUserInput, df_base.iloc({'columns':['1:']}).round(round_num).T], axis: 1 })
        tempDf_showUserInput = dfd.concat({ dfList: [tempDf_showUserInput, df_showUserInputRecommend], axis: 1 })
        // tempDf_showUserInput = dfd.concat({ dfList: [tempDf_showUserInput, df_showUserInputRecommend, tempDf_showUserInputMax, tempDf_showUserInputMin], axis: 1 })
        tempDf_showUserInput.setIndex({index: df_base.iloc({'columns':['1:']}).columns, inplace: true})

        tempDf_showUserInput.index.forEach((index, i) => {
            const indexName = index.split(`_${selectedChart}`)[0]

            if (showAllHandling) {
                tempDf_showUserInput.rename({ index: indexName }, { inplace: true })
            } else {
                if (!parameterNames.includes(indexName)) {
                    tempDf_showUserInput.drop({ index: [index], inplace: true })
                } else {
                    tempDf_showUserInput.rename({ index: indexName }, { inplace: true })
                }
            }
        })

        setDf_showUserInput(tempDf_showUserInput)
    }, [selectedChart, listAns, df_baseSetup, showAllHandling])

    useEffect(() => {
        if (Object.keys(Obj_chart_combination).length === 0 || !selectedGraphGroup) return

        const graphgroup = selectedGraphGroup

        const tempScat_chart_1 = Obj_chart_combination[graphgroup][0].name
        const tempScat_chart_2 = Obj_chart_combination[graphgroup][1].name
        const tempScat_chart_3 = Obj_chart_combination[graphgroup][2].name

        setScat_chart_1(tempScat_chart_1)
        setScat_chart_2(tempScat_chart_2)
        setScat_chart_3(tempScat_chart_3)

        setDf_baseHandling(dfd.concat({ dfList: [df_baseInput, df_baseTime, Obj_base_result[tempScat_chart_1]], axis: 1 }))
        setDf_baseAero(dfd.concat({ dfList: [df_baseInput, df_baseTime, Obj_base_result[tempScat_chart_2]], axis: 1 }))
        setDf_baseMech(dfd.concat({ dfList: [df_baseInput, df_baseTime, Obj_base_result[tempScat_chart_3]], axis: 1 }))

        setDf_scatHandling(dfd.concat({ dfList: [df_scatInput, df_scatTime, Obj_scat_result[tempScat_chart_1]], axis: 1 }))
        setDf_scatAero(dfd.concat({ dfList: [df_scatInput, df_scatTime, Obj_scat_result[tempScat_chart_2]], axis: 1 }))
        setDf_scatMech(dfd.concat({ dfList: [df_scatInput, df_scatTime, Obj_scat_result[tempScat_chart_3]], axis: 1 }))
    }, [selectedGraphGroup])

  
    const showAllHandlingHandler = () => {
        setShowAllHandling(!showAllHandling)
    }

    const displayResizeHandler = (e) => {
        const type = e.currentTarget.dataset.type;
        const resultPlace = e.currentTarget.closest('.result-place')
    
        if (!resultPlace) return
    
        if (type === 'time') {
            setDisplayTimeResult(prevDisplayTimeResult => !prevDisplayTimeResult)
        }

        if (type === 'userInput') {
            setDisplayTimeResult(prevDisplayTimeResult => !prevDisplayTimeResult)
        }

        if (type === 'compareTime') {
            setDisplayCompareTimeResult(displayCompareTimeResult => !displayCompareTimeResult)
        }
        
        if (type === 'scat') {
            setDisplayScatResult(displayScatResult => !displayScatResult)
        }

        if (type === 'cornerKPI') {
            setDisplayCornerKPIResult(displayCornerKPIResult => !displayCornerKPIResult)
        }
    
    
        if (resultPlace.classList.contains('flex')) {
            resultPlace.style.height = '150%'
        } else {
            resultPlace.style.height = '25%'
        }
    
        const scaleValue = resultPlace.dataset.scaleValue ? parseFloat(resultPlace.dataset.scaleValue) : 1
    
        if (scaleValue === 3) {
            resultPlace.style.height = '25%'
            resultPlace.dataset.scaleValue = null
        } else {
            resultPlace.style.height = '150%'
            resultPlace.dataset.scaleValue = 3
        }
    }

    return (
        <>
            <div className='result-containers'>
            <div className='result-place flex'>
                    { df_showTime && (
                        <div className='result-graph-container w-50 resize-container'>
                            <div className='graph-title'>
                                <p>Time, StaticKPI</p>
                                <button data-type='time' onClick={displayResizeHandler}>
                                    {displayTimeResult ? (
                                        <>
                                            <span className="Resize-mark-top-left"></span>
                                            <span className="Resize-mark-top-right"></span>
                                            <span className="Resize-mark-bottom-left"></span>
                                            <span className="Resize-mark-bottom-right"></span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="reduction-mark-top-left"></span>
                                            <span className="reduction-mark-top-right"></span>
                                            <span className="reduction-mark-bottom-left"></span>
                                            <span className="reduction-mark-bottom-right"></span>
                                        </>
                                    )}
                                </button>
                            </div>
                                <div className='graph-result-container setup-table-container time-staticKPI-container'>
                                    <div className='table-container'>
                                        <div className={`setup-table setup-table-laptime ${selectedTable === 'KPI' ? 'display-none' : ''}`}>
                                            { selectedTable === 'Time' && df_showTime && SetupTable(df_showTime, 'laptime', Obj_parameter_combination) }
                                        </div>

                                        <div className={`setup-table setup-table-laptime ${selectedTable === 'Time' ? 'display-none' : ''}`}>
                                            { selectedTable === 'KPI' && df_showKPI && SetupTable(df_showKPI, 'kpi', Obj_parameter_combination) }
                                        </div>

                                        <div className='select-container'>
                                            { df_showTime && (<SelectBoxLabels title='' onChangeType='table' setState={ setSelectedTable } options={ ['Time', 'KPI'] } value={ selectedTable } />)}
                                        </div>
                                    </div>
                                </div>
                        </div>
                    )}

                    { df_showUserInput && (
                        <div className='result-graph-container w-50 resize-container'>
                            <div className='graph-title'>
                                <p>Handling Balance</p>
                                <button data-type='userInput' onClick={ displayResizeHandler }>
                                {displayTimeResult ? (
                                        <>
                                            <span className="Resize-mark-top-left"></span>
                                            <span className="Resize-mark-top-right"></span>
                                            <span className="Resize-mark-bottom-left"></span>
                                            <span className="Resize-mark-bottom-right"></span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="reduction-mark-top-left"></span>
                                            <span className="reduction-mark-top-right"></span>
                                            <span className="reduction-mark-bottom-left"></span>
                                            <span className="reduction-mark-bottom-right"></span>
                                        </>
                                    )}
                                </button>
                            </div>

                                <div className='graph-result-container setup-table-container handling-balance-container'>
                                    <div className='table-container'>
                                        <div className='setup-table setup-table-handling'>
                                            { SetupTable(df_showUserInput, 'setup', Obj_parameter_combination) }
                                        </div>
                                        <div className='handling-balance-action-btn'>
                                            <div className='select-container'>
                                                <SelectBoxLabels title='' onChangeType='setup' setState={ setSelectedChart } options={ chartOptions } value={ selectedChart } />
                                            </div>
                                            <div className='input-checkbox-container'>
                                                <input type='checkbox' onChange={ showAllHandlingHandler } /> {t('resultPerformance.displayOther')}
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                        </div>
                    )}
                </div>

                <div className='result-place'>
                    { df_compareTime && (
                        <div className='result-graph-container resize-container'>
                            <div className='graph-title'>
                                <p>Base to recommend time</p>
                                <button data-type='compareTime' onClick={ displayResizeHandler }>
                                {displayCompareTimeResult ? (
                                        <>
                                            <span className="Resize-mark-top-left"></span>
                                            <span className="Resize-mark-top-right"></span>
                                            <span className="Resize-mark-bottom-left"></span>
                                            <span className="Resize-mark-bottom-right"></span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="reduction-mark-top-left"></span>
                                            <span className="reduction-mark-top-right"></span>
                                            <span className="reduction-mark-bottom-left"></span>
                                            <span className="reduction-mark-bottom-right"></span>
                                        </>
                                    )}
                                    </button>
                            </div>
                                <div className='graph-result-container compare-time-chart-container'>
                                    <CompareTimeChart df_compareTime={ df_compareTime } />
                                </div>
                            
                        </div>
                    )}
                </div>
                <div className='result-place'>
                    { df_scatHandling && df_scatAero && df_scatMech && (
                        <div className='result-graph-container resize-container'>
                            <div className='graph-title'>
                                <div className='graph-title-boxs'>
                                    <p>Handling Mean</p> 
                                    <p>Aero Balance</p> 
                                    <p>Mechanical Balance</p>
                                </div>
                                <button data-type='scat' onClick={ displayResizeHandler }>
                                {displayScatResult ? (
                                        <>
                                            <span className="Resize-mark-top-left"></span>
                                            <span className="Resize-mark-top-right"></span>
                                            <span className="Resize-mark-bottom-left"></span>
                                            <span className="Resize-mark-bottom-right"></span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="reduction-mark-top-left"></span>
                                            <span className="reduction-mark-top-right"></span>
                                            <span className="reduction-mark-bottom-left"></span>
                                            <span className="reduction-mark-bottom-right"></span>
                                        </>
                                    )}
                                    </button>
                            </div>
                                <div className='display-flex'>
                                    <div className='graph-result-container graph-container graph-line-container'>
                                        <div className='line-container canvas-container'>
                                            {/* <div className='graph-title'>{scat_chart_1}</div> */}
                                            <div className='graph-item'>
                                                <LineChart list={ listAns } df_scatter={ df_scatHandling } df_base={ df_baseHandling } title={ scat_chart_1 } plotLine={ selectedCorner } />
                                            </div>
                                        </div>
                                        <div className='line-container canvas-container'>
                                            {/* <div className='graph-title'>{scat_chart_2}</div> */}
                                            <div className='graph-item'>
                                                <LineChart list={ listAns } df_scatter={ df_scatAero } df_base={ df_baseAero } title={ scat_chart_2 } plotLine={ selectedCorner } />
                                            </div>
                                        </div>
                                        <div className='line-container canvas-container'>
                                            {/* <div className='graph-title'>{scat_chart_3}</div> */}
                                            <div className='graph-item'>
                                                <LineChart list={ listAns } df_scatter={ df_scatMech} df_base={ df_baseMech } title={ scat_chart_3 } plotLine={ selectedCorner } />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='select-container select-container-flex-boxs'>
                                        <SelectBoxLabels title={t('general.scatterPlot')} onChangeType='scatGroup' setState={ setSelectedGraphGroup } options={ scatGroupLabels } value={ selectedGraphGroup } />
                                        <SelectBoxLabels ttitle={t('resultPerformance.cornerSelection')} onChangeType='lineChart' setState={ setSelectedCorner } options={ lineLabels } value={ selectedCorner } />
                                    </div>
                                </div>
                        </div>
                    )}
                </div>


                <div className='result-place'>
                    { Object.keys(Obj_KPI_base).length > 0 && (
                        <div className='result-graph-container resize-container'>
                            <div className='graph-title'>
                                <p>Corner KPI</p>
                                <button data-type='cornerKPI' onClick={ displayResizeHandler }>
                                {displayCornerKPIResult ? (
                                        <>
                                            <span className="Resize-mark-top-left"></span>
                                            <span className="Resize-mark-top-right"></span>
                                            <span className="Resize-mark-bottom-left"></span>
                                            <span className="Resize-mark-bottom-right"></span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="reduction-mark-top-left"></span>
                                            <span className="reduction-mark-top-right"></span>
                                            <span className="reduction-mark-bottom-left"></span>
                                            <span className="reduction-mark-bottom-right"></span>
                                        </>
                                    )}
                                    </button>
                            </div>
                                <div className='graph-result-container corner-chart-container'>
                                    <CornerChart
                                        Obj_chart_combination={ Obj_chart_combination }
                                        Obj_KPI_base={ Obj_KPI_base }
                                        Obj_KPI_recommend={ Obj_KPI_recommend }
                                        Obj_circuit_sens={ Obj_circuit_sens }
                                    />
                                </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default ResultPerformance