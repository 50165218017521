import Header from '../components/Header'

import { useTranslation } from 'react-i18next'

const NoPage = () => {
    const { t } = useTranslation(['general'])

    return (
        <>
            <Header/>
            <h1 className='no-page-found'>{t('pageNotFound')}</h1>
        </>
    )
}

export default NoPage