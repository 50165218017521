import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TableContainer, Paper, Table, TableHead, TableRow } from '@mui/material'

import { colorScale } from '../services/Util'
import { StyledHeaderTableCell } from './TableStyle'
import TimeFormatter from './TimeFormatter'
import { useTranslation } from 'react-i18next'


const TimingDatasTable = ( props ) => {
    const { t } = useTranslation(['strategy', 'general'])

    const { raceDetailId } = useParams()

    const [backgroundColors, setBackgroundColors] = useState([])
    const [tableData, setTableData] = useState({})

    const TableheadStyle ={
        fontWeight:'bold',
        color:'#fff',
        textAlign:'center',
        width:'40px'
    }

    const transposeArray = (array) => {
        if (array.length && array[0].length) {
            return array[0].map((_, colIndex) => array.map(row => row[colIndex]))
        }
        return []
    }  

    const setSortedTableData = (sectorData, sectorLength) => {
        const tableData = {
            headerName: 'TimingData',
            header: sectorLength === 3 ? ['SEC1', 'SEC2', 'SEC3', 'LAP_TIME', 'SPEED'] : ['SEC1', 'SEC2', 'SEC3', 'SEC4', 'LAP_TIME', 'SPEED'],
            rows: []
        }
    
        tableData.rows = Object.keys(sectorData).map((key, index) => {
            const row = [(index + 1)?.toString(), ...tableData.header.map(header => {
                return header === 'SPEED' ? sectorData[key][header]?.toString() : sectorData[key][header]
            })]
            return row
        })
    
        setTableData(tableData)
    }

    const setHeatmapArray = (arrays, bestLap, individualBestLap) => {         
        const arraysSliced = arrays ? arrays.map(array => array.slice(1)) : undefined
        const arrysTransposed = transposeArray(arraysSliced)
        const allHeatMapArray = []
        
        if(arrysTransposed){
            const lapNumberbackColor =  Array(arrysTransposed[0].length).fill('f2f2f2')
            allHeatMapArray.push(lapNumberbackColor)
        }
        
        arrysTransposed.forEach((timeArray, index) => {
            let colorArray
            ((props.timingDatas[Object.keys(props.timingDatas)[0]]['SPEED']!==0) && (index ===  arrysTransposed.length-1))
                ? colorArray = colorScale(timeArray.map(Number), 0x3a4eff, 0xff523c, 'normal')
                : colorArray = colorScale(timeArray, 0xff523c, 0x3a4eff, 'normal')            

            if(individualBestLap){            
                colorArray[timeArray.indexOf(individualBestLap)] = 'a1e6c3'
            }

            if(bestLap){            
                colorArray[timeArray.indexOf(bestLap['TIME'])] = 'b8a1e6'
            }
            
            allHeatMapArray.push(colorArray)
        })       
        const backgroundColors = transposeArray(allHeatMapArray).flat()    
        setBackgroundColors(backgroundColors) 
    }

    useEffect(() =>{
        if(props.timingDatas !== null && props.sectorLength !== null){
            setSortedTableData(props.timingDatas, props.sectorLength)
        }
    }, [props.timingDatas, props.sectorLength])

    useEffect(() =>{
        if(Object.keys(tableData).length !== 0){
            if (tableData.rows.length > 0){
                setHeatmapArray(tableData.rows, props.bestLap, props.individualBestLap)
            }    
        }
    }, [tableData, props.bestLap, props.individualBestLap])

    return (
        props.individualBestLap && tableData.rows ?
        <TableContainer className='secter-time-table' component={Paper}>
        <Table className= 'fp-table-container' sx={{color: 'white'}}>  
            <TableHead sx={{height: '50px'}}>
                <TableRow>
                    <StyledHeaderTableCell sx={{...TableheadStyle}}>{tableData.headerName}</StyledHeaderTableCell>
                        { tableData.header
                        ? (tableData.header.map((head, index) => <th key={index}>{head}</th>))
                        : null}
                </TableRow>
            </TableHead>
                <tbody>
                    {tableData.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex}> 
                                {(backgroundColors[(cellIndex) + (row.length) * rowIndex]) !== undefined ?(
                                <div style={{textAlign:'center', color: 'black', backgroundColor: `#${backgroundColors[(cellIndex) + (row.length) * rowIndex]?.toString(16).padStart(6, '0')}` }}>
                                    {typeof cell === 'string' ? cell : <TimeFormatter seconds={cell} />}
                                </div>
                                ): null}        
                            </td>
                        ))}
                        </tr>
                    ))}
                </tbody>
        </Table>
        </TableContainer> 
        :
        null
    )
}

export default TimingDatasTable