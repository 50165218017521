import { DataFrame } from 'danfojs'

import covertNameContinuous from './covertNameContinuous'

// excelファイルからSetup情報、DriverMap取得
const readSetupFile = async (teamOutingDetailId, vehicleId, circuitId, updatedSetupFile, constrainSelectedOption) => {
    return new Promise (async (resolve, reject) => {        
        fetch('/api/v1/setups', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                vehicleId: vehicleId,
                circuitId: circuitId,
                teamOutingDetailId: teamOutingDetailId,
                updatedSetupFile: updatedSetupFile
            })
        })
        .then(response => response.json())
        .then(response => {
            console.log("response", response)
            if (response.status !== 200) {
                return alert("Failed getting setup files")
            }

            const setupFiles = response.data

            const setup = setupFiles.setup
            setup.forEach(setup => {
                setup.Base = Number(setup.Base) ? Number(setup.Base) : setup.Base
            })
            const df_baseSetup_raw = new DataFrame(setup)
            df_baseSetup_raw.setIndex({ column: 'Parameter', drop: true, inplace: true })
            df_baseSetup_raw.drop({ columns: ["SetupItemId"], inplace: true })
            df_baseSetup_raw.drop({ columns: ["VehicleItemMappingId"], inplace: true })
            const df_baseSetup = covertNameContinuous(df_baseSetup_raw, setupFiles.combination_parameter, 0, 1)

            const df_DOEcondition = new DataFrame(setupFiles.doe_condition)

            const df_mapDriver = new DataFrame(setupFiles.SetupSheet.driverMap)
            const df_setupRequest = new DataFrame(setupFiles.SetupSheet.setupRequest)
            df_mapDriver.setIndex({ column: 'Parameter', drop: true, inplace: true })
            df_setupRequest.setIndex({ column: 'Parameter', drop: true, inplace: true })

            const changedCornerNameOnnxModels = setupFiles.model_list.map(model => {
                setupFiles.circuit_map.forEach(corner => {
                    if (model.indexOf(corner.Sim_Parameter) !== -1) {
                        model = model.replace(corner.Sim_Parameter, `${corner.UI_Parameter}_`)
                    }
                })
                return model
            })

            resolve({
                Obj_parameter_combination: setupFiles.combination_parameter,
                Obj_chart_combination: setupFiles.combination_chart,
                Obj_circuit_map: setupFiles.circuit_map,
                Obj_circuit_sens: setupFiles.circuit_sensitivity,
                onnxModels: changedCornerNameOnnxModels,
                df_DOEcondition,
                df_baseSetup_raw,
                df_baseSetup,
                df_mapDriver,
                df_setupRequest,
                vehicleData: setupFiles.vehicle,
                trackData: setupFiles.circuit,
                model: setupFiles.model,
                setupOptions: setupFiles.setup_options,
                setup
            })
        })
        .catch(error => {
            console.error('Error getting setup files', error)
            console.error('Error getting setup files', error)
        })
    })
}

export default readSetupFile