import { useState, useEffect } from 'react'
import { useDebounce } from '../lib/hooks/useDebounce'

import WebSocketService from '../../WebSocketService'
import ModalComponent from '../components/ModalComponent'
import HeaderWithMenu from '../components/HeaderWithMenu'
import HeaderWithSpMenu from '../components/HeaderWithSpMenu'
import HeaderWithMapMenu from '../components/HeaderWithMapMenu'
import FooterMenu from '../components/FooterMenu'
import TableChartToggleHandler from '../components/TableChartToggleHandler'
import GetLatestDataForEachCar from '../services/GetLatestDataForEachCar'
import SetBattle from '../services/SetBattle'

import FPLive from '../components/FPLive'
import QFLive from '../components/QFLive'
import RaceLive from '../components/RaceLive'

import '../css/dashboard.css'
import '../css/lightMode.css'
import '../css/timeComparisonChart.css'

import DataContext from '../../DataContext'

import { useTranslation } from 'react-i18next'

const Live = () => {
    const { t } = useTranslation(['strategy', 'general'])

    const [raceDetailId, setRaceDetailId] = useState('')
    const [originalRaceDetailList, setOriginalRaceDetailList] = useState([])
    const [raceDetailList, setRaceDetailList] = useState([])
    const [tableData, setTableData] = useState([])
    const [tableDataWithBattle, setTableDataWithBattle] = useState([])
    const [sectorData, setSectorData] = useState([])
    const [singleWsSectorData, setSingleWsSectorData] = useState([])
    const [singleWsLapData, setSingleWsLapData] = useState([])
    const [lapData, setLapData] = useState([])
    const [otsData, setOtsData] = useState([])
    const [latestDataByCar, setLatestDataByCar] = useState([])
    const [weatherData, setWeatherData] = useState([])
    const [precipitationForecast, setPrecipitationForecast] = useState({type: 'noRain', minute: 0, precipitation: 0})
    const [columnOrder, setColumnOrder] = useState()
    const [columnOriginalLength, setColumnOriginalLength] = useState()

    const [followCarNumber, setFollowCarNumber] = useState('')
    const [rivalCarNumbers, setRivalCarNumbers] = useState([])

    const [tableChartToggle, setTableChartToggle] = useState('table')
    const [displayRivalHeaders, setDisplayRivalHeaders] = useState(true)
    const [displayMap, setDisplayMap] = useState(true)
    const [mapDisplayLeaders, setDisplayMapLeaders] = useState(false)
    const [mapLeaders, setMapLeaders] = useState([])
    const [mapSize, setMapSize] = useState('small')
    const [openModal, setOpenModal] = useState(false)
    const [modalType, setModalType] = useState()
    const [paceColors, setPaceColors] = useState([])

    const [sectorLength, setSectorLength] = useState('')
    const [pitRoadTime, setPitRoadTime] = useState(0)
    const [pitStopTimeByRefueling, setPitStopTimeByRefueling] = useState(0)
    const [pitStopTimeByWork, setPitStopTimeByWork] = useState(0)
    const [pitStopTimeSettingStatus, setPitStopTimeSettingStatus] = useState(false)
    const [tireWarmUpLossTime, setTireWarmUpLossTime] = useState(0)
    const [pitRoadExitTime, setPitRoadExitTime] = useState(0)
    const [isRace, setIsRace] = useState(false)
    const [raceType, setRaceType] = useState('')
    const [raceSeries, setRaceSeries] = useState()
    const [eventId, setEventId] = useState()
    const [numOfLaps, setnumOfLaps] = useState(0)
    const [highlightUpdatedRows, setHighlightUpdatedRows] = useState([])

    const [predictPitInMode, setPredictPitInMode] = useState(false)
    const [projectPositionMode, setProjectPositionMode] = useState(false)
    const [changeViewMode, setChangeViewMode] = useState(false)
    const [currentView, setCurrentView] = useState()
    const [displayFollowAndRivals, setDisplayFollowAndRivals] = useState(false)
    const [displayMapGroups, setDisplayMapGroups] = useState(false)
    const [sectorTraffic, setSectorTraffic] = useState([])
    const [overallBest, setOverallBest] = useState({})
    const [carClasses, setCarClasses] = useState([])
    const [carClassesOnLoad, setCarClassesOnLoad ] = useState(false)
    const [latestReceivedTime, setLatestReceivedTime] = useState()

    const [historyGap, setHistoryGap] = useState({})
    const [circuitId, setCircuitId] = useState(0)
    const [pitRoadExitProgressPos, setPitRoadExitProgressPos] = useState(0)

    const [remainingTimeAndLaps, setRemainingTimeAndLaps] = useState()
    const [raceTimeAndLaps, setRaceTimeAndLaps] = useState()
    const [totalTimeForRemainingTime, setTotalTimeForRemainingTime] = useState(0)
    const [flagInfo, setFlagInfo] = useState({color: null, text: ''})

    const [raceManagementCar, setRaceManagementCar] = useState('')
    const [setupChange, setSetupChange] = useState({})
    const [driverComment, setDriverComment] = useState({})
    const [notes, setNotes] = useState({})

    const [updateQueue, setUpdateQueue] = useState([])
    const [firstLoad, setFirstLoad] = useState(false)

    const [isScroll, setIsScrolled] = useState(false)
    const [tableScroll, setTableScroll] = useState(false)

    const [mobileTableContent, setMobileTableContent] = useState('LAPS');
    const [tabletMode, setTabletMode] = useState(false)
    const [mobileMode, setMobileMode] = useState(false)
    const [styleSheet, setStyleSheet] = useState(false);

    const carClassesDebounced = useDebounce(carClassesOnLoad, 5000)

    const [isLightMode, setIsLightMode] = useState(false);


    const columnIdAndName = {
        follow: 'Follow',
        rival:'Rival',
        pos: 'Pos',
        name: 'Name',
        team: 'Team',
        drType: 'Dr',
        ots: 'OTS',
        class: 'Class',
        e: 'E',
        tire: 'Tire',
        lap: 'Lap',
        gap: 'Gap',
        diff: 'Diff',
        laptime: 'Laptime',
        drivingTime: 'Driving Time',
        battle: 'Battle',
        sector: 'Sector',
        bestLap: 'Best Time',
        pit: 'Pit',
        deg1: 'Deg1',
        deg2: 'Deg2',
        deg3: 'Deg3',
        deg4: 'Deg4',
        avg1: 'Avg1',
        avg2: 'Avg2',
        avg3: 'Avg3',
        avg4: 'Avg4',
    }

    function updateLapData(carNumber, newLapData) {
        const lowestLapNumberIndex = lapData.findIndex((data) => data.CAR_NUMBER === carNumber && data.LAP_NUMBER === getLowestLapNumber(carNumber))

        if (lowestLapNumberIndex !== -1) {
            setLapData((prevLapData) => [
                ...prevLapData.slice(0, lowestLapNumberIndex),
                ...prevLapData.slice(lowestLapNumberIndex + 1),
            ])
        }

        setLapData((prev) => [...prev, {
            CAR_NUMBER: carNumber,
            DRIVER_NAME: newLapData.DRIVER_NAME,
            LAP_NUMBER: newLapData.LAP_NUMBER,
            TIME: newLapData.TIME,
            GAP: newLapData.GAP,
            SPEED: newLapData.SPEED,
            DIFF: newLapData.DIFF,
            POS: newLapData.POS,
            PIT_STOP_TIME: newLapData.PIT_STOP_TIME,
        }])

        function getLowestLapNumber(carNumber) {
            const carLaps = lapData.filter((lap) => lap.CAR_NUMBER === carNumber)
            if (carLaps.length <= 50) return -1

            const lowestLap = Math.min(...carLaps.map((lap) => lap.LAP_NUMBER))
            return lowestLap
        }
    }

    async function getLatestLapDataAndUpdateTable() {
        const latestLapDataByCarNumberObj = await getLatestLapDataByCarNumberObj(raceDetailId);
        const carNumbers = Object.keys(latestLapDataByCarNumberObj);
    
        const updates = await Promise.all(carNumbers.map(async (carNumber) => {
            const latestLap = latestLapDataByCarNumberObj[carNumber];
            // SFでしか使わないためコメントアウト
            // const driverInfo = latestLap.DRIVER_NAME ? await getDriverData(latestLap.DRIVER_NAME) : undefined;
    
            return {
                carNumber,
                latestLap,
                //driverInfo // SFでしか使わないためコメントアウト
            };
        }));
    
        setTableData(prevTableData => {
            const newTableData = [...prevTableData];
    
            // updates.forEach(({ carNumber, latestLap, driverInfo }) => { // SFでしか使わないためコメントアウト
            updates.forEach(({ carNumber, latestLap }) => {
                let carIndex = newTableData.findIndex((carData) => carData.carno === `No.${carNumber}`);
                let carData;
    
                if (carIndex === -1) {
                    carData = {
                        carno: `No.${carNumber}`,
                        isPitIn: false,
                        otsLeft: 200,
                        otsUsing: '0',
                        otsLimit: '0',
                        isVisible: true,
                        pos: "",
                        driverTotalTime: 0,
                        carClass: ''
                    };
                    newTableData.push(carData);
                    carIndex = newTableData.length - 1;
                } else {
                    carData = newTableData[carIndex];
                }
    
                // SFでしか使わないためコメントアウト
                // if (driverInfo) {
                //     carData.driverNameShort = driverInfo.ABBREVIATION || driverInfo.DRIVER_NAME;
                //     carData.teamTextColor = driverInfo.COLOR_TEXT || '#000000';
                //     carData.teamBgColor = driverInfo.COLOR_BG || '#FFFFFF';
                // }

                const prevLapNumber = carData?.lap
    
                //carData.driverName = latestLap.DRIVER_NAME || carData.driverName;
                carData.lap = latestLap.LAP_NUMBER || carData.lap;
                carData.laptime = latestLap.TIME || carData.laptime;
                carData.isOutLap = carData.isInLap ? true : false;
                carData.isInLap = latestLap.PIT_STOP_TIME ? carData.pit ? latestLap.PIT_STOP_TIME > carData.pit : latestLap.PIT_STOP_TIME > 0 : false;
                carData.pit = !latestLap.PIT_STOP_TIME ? 0 : latestLap.PIT_STOP_TIME || carData.pit;
                carData.drType = latestLap.DRIVER_TYPE || carData.drType;

                setTotalTimeForRemainingTime(latestLap.TOTAL_TIME)
    
                if ((carIndex === -1 && latestLap) || (carData && latestLap.LAP_NUMBER !== prevLapNumber)) {
                    setHighlightUpdatedRows(prevRow => [...prevRow, carData.carno])
                    setTimeout(() => {
                        setHighlightUpdatedRows([])
                    }, 1000)
                    
                    carData.driverTotalTime += latestLap.TIME;

                    updateLapData(Number(carNumber), latestLap)
                    setSingleWsLapData(latestLap)
                }
    
                const isNewBest = updateBestLapSector('lap', carData.laptime, carData.bestLaptime, carData.isInLap, carData.isOutLap);
                carData.bestLaptime = isNewBest ? carData.laptime : carData.bestLaptime;
                carData.bestLap = isNewBest ? carData.lap : carData.bestLap;
    
                newTableData[carIndex] = carData;
            });
    
            organizeBattleTableData(newTableData);
            return newTableData;
        });
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            getLatestLapDataAndUpdateTable();
        }, 2000);
    
        return () => clearInterval(intervalId);
    }, [raceDetailId]);

    useEffect(() => {
        setUrl()
    }, [raceDetailList])

    useEffect(() => {
        WebSocketService.onMessage((message) => {
            webSocketResponse(message)
        })

        handleSectorTrafficChange()
        setTime()
    }, [tableData])

    useEffect(() => {
        const generateSimilarColor = (driverColors) => {
            const randomColor = driverColors[Math.floor(Math.random() * driverColors.length)]
        
            const r = parseInt(randomColor.slice(1, 3), 16)
            const g = parseInt(randomColor.slice(3, 5), 16)
            const b = parseInt(randomColor.slice(5, 7), 16)
        
            const rVariation = Math.floor(Math.random() * 50) - 25
            const gVariation = Math.floor(Math.random() * 50) - 25
            const bVariation = Math.floor(Math.random() * 50) - 25
        
            const newR = Math.min(255, Math.max(0, r + rVariation))
            const newG = Math.min(255, Math.max(0, g + gVariation))
            const newB = Math.min(255, Math.max(0, b + bVariation))

            const brightness = (0.299 * newR + 0.587 * newG + 0.114 * newB) / 255
            const textColor = brightness < 0.4 ? '#FFFFFF' : '#000000'

            const newColor = `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`

            return { color: newColor, textColor }
        }

        const driverColors = [
            '#7289DA', '#FFFFFF', '#E9967A', '#F08080', '#FFC0CB',
            '#FF69B4', '#FF1493','#FF6347', '#FFD700', '#FFFACD',
            '#FFE4B5','#BDB76B', '#EE82EE', '#BA55D3','#D2691E', 
            '#6A5ACD','#7FFF00', '#2E8B57', '#00FFFF', '#4682B4',
            '#D2691E','#008080', '#A0522D', '#32CD32'
        ]

        const colors = []

        if (paceColors.length === 0 || paceColors.length !== tableData.length) {
            tableData.forEach((data, index) => {
                if (paceColors.length > 0) {
                    const foundDriverColor = paceColors.find((color) => color.carno === data.carno)
                    if (foundDriverColor) {
                        colors.push(foundDriverColor)
                    } else {
                        colors.push({carno: tableData[index].carno, color: driverColors[index] ? driverColors[index] : generateSimilarColor(driverColors).color, textColor: driverColors[index] ? 'black' : generateSimilarColor(driverColors).textColor})
                    }
                } else {
                    colors.push({carno: tableData[index].carno, color: driverColors[index] ? driverColors[index] : generateSimilarColor(driverColors).color, textColor: driverColors[index] ? 'black' : generateSimilarColor(driverColors).textColor})
                }
            })
            setPaceColors(colors)
        }
    }, [tableData.length])

    useEffect(() => {
        const url = new URL(window.location.href)
        // const tabletMode = window.innerWidth <= 1366 && window.innerWidth >= 768
        // const mobileMode = window.innerWidth < 768
        // setTabletMode(tabletMode)
        // setMobileMode(mobileMode)


        if(raceSeries === 'SF') {
            if(tabletMode){
                if(mobileTableContent === 'LAPS'){
                    setColumnOrder(['follow', 'pos', 'name', 'ots', 'e', 'lap', 'gap', 'diff'])  
                }else if(mobileTableContent === 'SECTORS'){
                    setColumnOrder(['follow', 'pos', 'name', 'ots', 'e', 'laptime', 'sector']) 
                }else if(mobileTableContent === 'PITINS'){
                    setColumnOrder(['follow', 'pos', 'name', 'ots', 'e', 'laptime','bestLap', 'pit']) 
                }else if(mobileTableContent === 'TYREDEG'){
                    setColumnOrder(['follow', 'pos', 'name', 'ots', 'e', 'laptime', 'deg1', 'deg2']) 
                }else if(mobileTableContent === 'TYREAVG'){
                    setColumnOrder(['follow', 'pos', 'name', 'ots', 'e', 'laptime', 'avg1', 'avg2']) 
                }    
            }else if(mobileMode){
                if(mobileTableContent === 'LAPS'){
                    setColumnOrder(['follow', 'pos', 'name', 'laptime', 'lap', 'gap', 'diff']) 
                }else if(mobileTableContent === 'SECTORS'){
                    setColumnOrder(['follow', 'pos', 'name', 'laptime', 'sector']) 
                }else if(mobileTableContent === 'PITINS'){
                    setColumnOrder(['follow', 'pos', 'name', 'laptime','bestLap','pit']) 
                }else if(mobileTableContent === 'TYREDEG'){
                    setColumnOrder(['follow', 'pos', 'name', 'laptime', 'deg1', 'deg2']) 
                }else if(mobileTableContent === 'TYREAVG'){
                    setColumnOrder(['follow', 'pos', 'name', 'e', 'laptime', 'avg1', 'avg2']) 
                }      
            }else{
                if (url.href.includes('race')) {
                    setColumnOrder(['follow', 'rival', 'pos', 'name', 'ots', 'e', 'lap', 'gap', 'diff', 'laptime', 'battle', 'sector', 'bestLap', 'pit', 'deg1',  'avg1']) 
                } else if (url.href.includes('fp')) {
                    setColumnOrder(['follow', 'rival', 'pos', 'name', 'ots', 'e', 'lap', 'bestLap', 'gap', 'diff', 'laptime', 'sector', 'pit', 'avg1'])
                } else if (url.href.includes('qf')) {
                    setColumnOrder(['follow', 'rival', 'pos', 'name', 'ots', 'e', 'lap', 'bestLap', 'gap', 'diff', 'laptime', 'sector', 'avg1'])
                }
            }
        } else if(raceSeries === 'SE') {
            if(tabletMode){
                // deg, avg, drivingTimeの挙動がおかしいため削除
                if(mobileTableContent === 'LAPS'){
                    setColumnOrder(['follow', 'pos', 'name', 'drType', 'class', 'laptime', 'lap', 'gap', 'diff'])  
                }else if(mobileTableContent === 'SECTORS'){
                    setColumnOrder(['follow', 'pos', 'name', 'drType', 'class', 'laptime', 'sector'])  
                }else if(mobileTableContent === 'PITINS'){
                    setColumnOrder(['follow', 'pos', 'name', 'drType', 'class', 'laptime', 'bestLap', 'pit']) 
                }else if(mobileTableContent === 'TYREDEG'){
                    setColumnOrder(['follow', 'pos', 'name', 'drType', 'class', 'laptime', 'deg1', 'deg2']) 
                } 
            }else if(mobileMode){
                if(mobileTableContent === 'LAPS'){
                    setColumnOrder(['follow', 'pos', 'name', 'drType', 'class', 'laptime', 'lap', 'gap', 'diff']) 
                }else if(mobileTableContent === 'SECTORS'){
                    setColumnOrder(['follow', 'pos', 'name', 'drType', 'class', 'laptime', 'sector']) 
                }else if(mobileTableContent === 'PITINS'){
                    setColumnOrder(['follow', 'pos', 'name', 'drType', 'class', 'laptime','bestLap','pit']) 
                }else if(mobileTableContent === 'TYREDEG'){
                    setColumnOrder(['follow', 'pos', 'name', 'drType', 'class', 'laptime', 'deg1', 'deg2']) 
                }else if(mobileTableContent === 'TYREAVG'){
                    setColumnOrder(['follow', 'pos', 'name','drType', 'class', 'laptime', 'avg1', 'avg2']) 
                }      
            }else{
                if (url.href.includes('race')) {
                    // deg, avg, drivingTimeの挙動がおかしいためコメントアウト
                    // setColumnOrder(['follow', 'rival', 'pos', 'name', 'drType', 'class', 'lap', 'gap', 'diff', 'laptime', 'drivingTime', 'battle', 'sector', 'bestLap', 'pit', 'deg1', 'avg1', ])
                    setColumnOrder(['follow', 'rival', 'pos', 'name', 'drType', 'class', 'lap', 'gap', 'diff', 'laptime', 'battle', 'sector', 'bestLap', 'pit', ])
                } else if (url.href.includes('fp')) {
                    // deg, avgの挙動がおかしいためコメントアウト
                    //setColumnOrder(['follow', 'rival', 'pos', 'name', 'drType', 'class', 'lap', 'bestLap', 'gap', 'diff', 'laptime', 'sector', 'pit', 'avg1'])
                    setColumnOrder(['follow', 'rival', 'pos', 'name', 'drType', 'class', 'lap', 'bestLap', 'gap', 'diff', 'laptime', 'sector', 'pit', ])
                } else if (url.href.includes('qf')) {
                    // deg, avgの挙動がおかしいためコメントアウト
                    // setColumnOrder(['follow', 'rival', 'pos', 'name', 'drType', 'class', 'lap', 'bestLap', 'gap', 'diff', 'laptime', 'sector', 'avg1'])
                    setColumnOrder(['follow', 'rival', 'pos', 'name', 'drType', 'class', 'lap', 'bestLap', 'gap', 'diff', 'laptime', 'sector', ])
                }
            }
        }
        setColumnOriginalLength(26)
    
    
    }, [raceDetailId, raceSeries, mobileTableContent, tabletMode, mobileMode])

    useEffect(() => {
        if (updateQueue.length > 0) {
            const nextUpdate = updateQueue[0]
            setSingleWsSectorData(nextUpdate)
            setUpdateQueue((prevQueue) => prevQueue.slice(1))
        }
    }, [updateQueue])

    useEffect(() => {
        if (followCarNumber === '') {
            setDisplayFollowAndRivals(false)
        }
    
        setTableData((prev) => {
            return prev.map((car) => {
                const carNumber = car.carno.split('.')[1]
                const carClassVisibility = carClasses.find((carClass) => carClass.class === car.carClass)?.isVisible
    
                if (carNumber === followCarNumber || rivalCarNumbers.includes(carNumber)) {
                    return { ...car, isVisible: displayFollowAndRivals ? true : carClassVisibility }
                } else {
                    return { ...car, isVisible: !displayFollowAndRivals && carClassVisibility }
                }
            })
        })
    }, [displayFollowAndRivals, followCarNumber, rivalCarNumbers])
    
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY
            if (scrollPosition > 1) {
                setIsScrolled(true)
            } else {
                setIsScrolled(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
    
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const carClasses = await getCarClasses(raceDetailId);
            const uniqueClass = [...new Set(carClasses.map(car => car.CLASS))]
                .filter(className => className !== null)
                .sort()
            const carClassesWithList = uniqueClass.map(className => {
                const carNumbersInClass = carClasses
                    .filter(car => car.CLASS === className)
                    .map(car => car.CAR_NUMBER)
                return {
                    class: className,
                    isVisible: true,
                    cars: carNumbersInClass
                }
            })
    
            if (uniqueClass.length === 0) {
                carClassesWithList.push({
                    class: '',
                    isVisible: true,
                    cars: carClasses.map(car => car.CAR_NUMBER)
                })
    
                carClasses.forEach(carClass => {
                    carClass.CLASS = ''
                })
            }
    
            setCarClasses(carClassesWithList)
        }
    
        fetchData()
    }, [carClassesDebounced])

    useEffect(() => {
        if (tableData.length > 0 && !firstLoad) {
            organizeBattleTableData(tableData)
        }
    }, [tableData])
    
    
    // Function to add updates to the queue
    const addToQueue = (update) => {
        setUpdateQueue((prevQueue) => [...prevQueue, update])
    }

    const webSocketSendMessage = (message) => {
        WebSocketService.send(JSON.stringify(message))
    }

    const webSocketResponse = async (message) => {
        let data = JSON.parse(message)

        if (data.error) {
            return
        }

        if (data.type === 'live_changedRaceDetailId') {
            const latestSectorData = await getLatestSectorData(data.raceDetailId)
            setSectorData(latestSectorData)

            const latest50LapData = await getLatest50LapData(data.raceDetailId)
            setLapData(latest50LapData)

            const allLapData = await getAllLapData(data.raceDetailId)

            const circuitInfo = await getCircuitInfo(data.raceDetailId)
            const sectorLength = circuitInfo ? circuitInfo.SECTOR_LENGTH : 3
            setSectorLength(sectorLength)
            setTireWarmUpLossTime(circuitInfo ? circuitInfo.TIRE_WARM_UP_LOSS_TIME : 0)
            setPitRoadExitTime(circuitInfo.PIT_ROAD_EXIT_TIME)
            setCircuitId(circuitInfo.ID)
            setPitRoadExitProgressPos(circuitInfo.P / circuitInfo.SECTOR1)

            const raceDetailInfo = await getRaceDetailInfo(data.raceDetailId)
            const raceSeriesType = raceDetailInfo.EVENT.RACE_ID === 1 ? 'SF' : 'SE'
            setPitRoadTime(circuitInfo ? raceSeriesType === 'SF' ? circuitInfo.PIT_ROAD_TIME : circuitInfo.PIT_ROAD_TIME * 6/5 : 30)
            setPitStopTimeByWork(raceSeriesType === 'SF' ? 6 : 0)
            setEventId(raceDetailInfo.EVENT_ID)
            setIsRace(!raceDetailInfo.RACE_TYPE || raceDetailInfo.RACE_TYPE === 'race')
            setnumOfLaps(raceDetailInfo.NUM_OF_LAPS)
            setRaceSeries(raceSeriesType)
            setRemainingTimeAndLaps(raceDetailInfo.RACE_TIME)
            setRaceTimeAndLaps(raceDetailInfo.RACE_TIME)

            const url = `/live?${raceDetailInfo.RACE_TYPE}/${data.raceDetailId}`.trim()
            window.history.replaceState(null, null, url)
            setRaceType(raceDetailInfo.RACE_TYPE)

            setLatestDataByCar({})
            setOverallBest({})

            const newOverallBest = {
                lap: Infinity,
                sec1: Infinity,
                sec2: Infinity,
                sec3: Infinity
            }

            if (sectorLength === 4) newOverallBest.sec4 = Infinity

            data.tableData.forEach((data) => {
                newOverallBest.lap = data.bestLaptime ? Math.min(newOverallBest.lap, data.bestLaptime) : newOverallBest.lap                
                newOverallBest.sec1 = data.sec1Best ? Math.min(newOverallBest.sec1, data.sec1Best) : newOverallBest.sec1
                newOverallBest.sec2 = data.sec2Best ? Math.min(newOverallBest.sec2, data.sec2Best) : newOverallBest.sec2
                newOverallBest.sec3 = data.sec3Best ? Math.min(newOverallBest.sec3, data.sec3Best) : newOverallBest.sec3
                
                if (sectorLength === 4) {
                    newOverallBest.sec4 = data.sec4Best ? Math.min(newOverallBest.sec4, data.sec4Best) : newOverallBest.sec4
                }

                if (raceSeriesType === 'SE') {
                    data.teamTextColor = '#000000'
                    data.teamBgColor = '#FFFFFF'
                }
            })
                
            setOverallBest(newOverallBest)
            setLatestDataByCar(await GetLatestDataForEachCar(latest50LapData, latestSectorData, data.raceDetailId, sectorLength))

            const carClasses = await getCarClasses(data.raceDetailId)
            const uniqueClass = [...new Set(carClasses.map(car => car.CLASS))]
                .filter(carClasses => carClasses !== null)
                .sort()
            const carClassesWithList = uniqueClass.map((className) => {
                const carNumbersInClass = carClasses
                    .filter(car => car.CLASS === className)
                    .map(car => car.CAR_NUMBER)
                return {
                    class: className, isVisible: true, cars: carNumbersInClass
                } 
            })

            if (uniqueClass.length === 0) {
                carClassesWithList.push({
                    class: '', isVisible: true,
                    cars: carClasses.map(car => car.CAR_NUMBER)
                })

                carClasses.forEach((carClass) => {
                    carClass.CLASS = ''
                })
            }

            setCarClasses(carClassesWithList)

            const driverTotalTime = []
            allLapData.forEach((lapData) => {
                const driverIndex = driverTotalTime.findIndex((driver) => driver.DRIVER_NAME === lapData.DRIVER_NAME)
                if (driverIndex === -1) {
                    driverTotalTime .push({
                        DRIVER_NAME: lapData.DRIVER_NAME,
                        TOTAL_TIME: lapData.TIME
                    })
                } else {
                    driverTotalTime[driverIndex].TOTAL_TIME += lapData.TIME
                }
            })

            if(raceSeriesType !== 'SF' && data.tableData.length > 0) {
                const firstCarTotalTime = data.tableData.find((car) => car.pos === 1)?.totalLapTime || 0
                setTotalTimeForRemainingTime(firstCarTotalTime)
            }

            data.tableData.forEach((data) => {
                data.driverTotalTime = driverTotalTime.find((driver) => driver.DRIVER_NAME === data.driverName)?.TOTAL_TIME
                data.carClass = carClasses.find(car => car.CAR_NUMBER === parseInt(data.carno.split('.')[1])).CLASS
                data.isVisible = true
            })

            setTableData(data.tableData)
        } else if (data.type === 'live_update') {
            handleTableDataUpdate(data.updateType, data.update)
        } else if (data.type === 'live_headerUpdate') {
            handleHeaderUpdate(data.updateType, data.update)
        } else if (data.type === 'live_allOtsData') {
            setOtsData(data.otsData)
        } else if (data.type === 'live_weatherData') {
            setWeatherData(data.weatherData)
        } else if (data.type === 'live_precipitationForecast') {
            setPrecipitationForecast(data.precipitationForecast)
        }
    }

    const setUrl = () => {
        const url = new URL(window.location.href)
        const raceType = url.search.split('/')[0].slice(1)
        const raceId = url.search.split('/')[1]
        
        const foundRaceId = originalRaceDetailList.find(race => race.ID === parseInt(raceId))
        const foundRaceType = raceType === foundRaceId?.RACE_TYPE
    
        if (foundRaceType) {
            handleRaceDetailIdChange({ target: { value: raceId } })
        } 

        setRaceType(raceType)
    }


    const originalRaceDetailListUpdate = (currentList) => {
        setOriginalRaceDetailList(currentList)
    }

    const raceDetailListUpdate = (currentList) => {
        setRaceDetailList(currentList)
    }

    const updateBestLapSector = (key, newTime, bestTime, isInLap, isOutLap) => {
        if ((key === 'lap' && (isInLap || isOutLap)) ||
            (key === 'sec1' && isOutLap) ||
            (key === `sec${sectorLength}` && isInLap)) {
            return false
        }

        let isNewBest = false

        if (!bestTime || bestTime === undefined || newTime <= bestTime) {
            isNewBest = true
            setOverallBest(prevOverallBest => ({
                ...prevOverallBest,
                [key]: Math.min(prevOverallBest[key], newTime) || Infinity
            }))
        }

        return isNewBest
    }

    const setTime = () => {
        const addZero = num => String(num).padStart(2, '0')

        const now = new Date()
        let hour = addZero(now.getHours())
        let min = addZero(now.getMinutes())
        let sec  = addZero(now.getSeconds())
        setLatestReceivedTime(`${hour}:${min}:${sec}`)
    }

    const handleTableDataUpdate = async (updateType, update) => {
        let carData
        let carIndex
        setTableData(prevTableData => {
            if (update.carNo) {
                carIndex = prevTableData.findIndex((carData) => carData.carno === `No.${update.carNo}`)
                if (carIndex === -1) {
                    carData = {
                        carno: `No.${update.carNo}`,
                        isPitIn: false,
                        otsLeft: 200,
                        otsUsing: '0',
                        otsLimit: '0',
                        isVisible: true,
                        pos: "",
                        driverTotalTime: 0
                    }

                    getCarClass(raceDetailId, parseInt(update.carNo)).then(carClass => {
                        carData.carClass = carClass ? carClass.CLASS : ''
                        setCarClassesOnLoad(true)
                    })
                } else {
                    carData = prevTableData[carIndex]
                }
            }

            const driverName = update[updateType].DRIVER_NAME ? update[updateType].DRIVER_NAME : undefined
            const carNumber = update[updateType].CAR_NUMBER ? update[updateType].CAR_NUMBER : undefined
            if (driverName && (!carData.hasOwnProperty('driverNameShort') || !carData.hasOwnProperty('teamTextColor') || !carData.hasOwnProperty('teamBgColor'))) {
                getDriverData(driverName).then(driverInfo => {
                    if (!driverInfo) return
                    if (!carData.hasOwnProperty('driverNameShort')) carData.driverNameShort = driverInfo?.ABBREVIATION ? driverInfo.ABBREVIATION : driverInfo.DRIVER_NAME
                    if (!carData.hasOwnProperty('teamTextColor')) carData.teamTextColor = driverInfo?.COLOR_TEXT ? driverInfo.COLOR_TEXT : '#000000'
                    if (!carData.hasOwnProperty('teamBgColor')) carData.teamBgColor = driverInfo?.COLOR_BG ? driverInfo.COLOR_BG : '#FFFFFF'
                })
            }

            let isNewBest

            switch (updateType) {
                case 'lapData':
                    // carData.driverName = update.lapData.DRIVER_NAME || carData.driverName
                    // carData.lap = update.lapData.LAP_NUMBER || carData.lap
                    // carData.laptime = update.lapData.TIME || carData.laptime
                    // carData.diff = update.lapData.DIFF || carData.diff
                    // carData.gap = update.lapData.GAP || carData.gap
                    // carData.pos = update.lapData.POS || carData.pos
                    // carData.driverTotalTime += update.lapData.TIME
                    // carData.isOutLap = carData.isInLap ? true : false
                    // carData.isInLap = update.lapData.PIT_STOP_TIME ? carData.pit ? update.lapData.PIT_STOP_TIME > carData.pit : update.lapData.PIT_STOP_TIME > 0 : false  
                    // carData.pit = !update.lapData.PIT_STOP_TIME ? 0 : update.lapData.PIT_STOP_TIME || carData.pit
                    // carData.drType = update.lapData.DRIVER_TYPE || carData.drType 

                    // setTotalTimeForRemainingTime(update.lapData.TOTAL_TIME)

                    // setHighlightUpdatedRows(prevRow => [...prevRow, carData.carno])
                    // setTimeout(() => {
                    //     setHighlightUpdatedRows([])
                    // }, 1000)

                    // isNewBest = updateBestLapSector('lap', carData.laptime, carData.bestLaptime, carData.isInLap, carData.isOutLap)
                    // carData.bestLaptime = isNewBest ? carData.laptime : carData.bestLaptime
                    // carData.bestLap = isNewBest ? carData.lap : carData.bestLap

                    // updateLatestDataByCar(Number(update.carNo), update.lapData, 'lapData')
                    // updateLapData(Number(update.carNo), update.lapData)

                    // setSingleWsLapData(update.lapData)
                    // break
                case 'sectorData':
                    carData.driverTotalTime = carData.driverName === update.sectorData.DRIVER_NAME ? carData.driverTotalTime : 0
                    carData.driverName = update.sectorData.DRIVER_NAME || carData.driverName

                    const latestData = latestDataByCar[carNumber];
                    const isNewSector = !latestData || latestData.latestSector.SECTOR_NUMBER !== update.sectorData.SECTOR_NUMBER;
                    const isFirstSectorOfLap = !latestData && update.sectorData.LAP_NUMBER === 1 && update.sectorData.SECTOR_NUMBER === 1;

                    if (isNewSector || isFirstSectorOfLap) {
                        if (update.sectorData.SECTOR_NUMBER === 1) {
                            carData[`prev_sec${update.sectorData.SECTOR_NUMBER}`] = carData[`sec${update.sectorData.SECTOR_NUMBER}`]
                            setSectorData((prevData) => prevData.filter(data => data.CAR_NUMBER !== parseInt(update.carNo)))
                        }

                        carData[`sec${update.sectorData.SECTOR_NUMBER}`] = update.sectorData.TIME || carData[`sec${update.sectorData.SECTOR_NUMBER}`]

                        setSectorData((prev) => [...prev, {
                            CAR_NUMBER: Number(update.carNo),
                            LAP_NUMBER: update.sectorData.LAP_NUMBER,
                            SECTOR_NUMBER: update.sectorData.SECTOR_NUMBER,
                            TIME: update.sectorData.TIME,
                            CREATED_AT: update.sectorData.CREATED_AT,
                        }])

                        for (let i = update.sectorData.SECTOR_NUMBER + 1; i <= sectorLength; i++) {
                            if (update.sectorData.SECTOR_NUMBER === 1 && i !== 1) carData[`prev_sec${i}`] = carData[`sec${i}`]
                            carData[`sec${i}`] = ''
                        }

                        isNewBest = updateBestLapSector(`sec${update.sectorData.SECTOR_NUMBER}`, carData[`sec${update.sectorData.SECTOR_NUMBER}`], carData[`sec${update.sectorData.SECTOR_NUMBER}Best`], carData.isInLap, carData.isOutLap)
                        carData[`sec${update.sectorData.SECTOR_NUMBER}Best`] = isNewBest ? carData[`sec${update.sectorData.SECTOR_NUMBER}`] : carData[`sec${update.sectorData.SECTOR_NUMBER}Best`]

                        
                        updateLatestDataByCar(Number(update.carNo), update.sectorData, 'sectorData')
                        update.sectorData.carClass = carData.carClass
                        addToQueue(update.sectorData)
                    }
                    break
                case 'otsUsage':
                    // 0という値が入るためnullとundefinedだけ判定
                    if (carIndex !== -1) {
                        carData.otsLeft = (update.otsUsage.otsLeft !== null && update.otsUsage.otsLeft !== undefined) ? update.otsUsage.otsLeft : carData.otsLeft
                        carData.otsUsing = (update.otsUsage.otsUsing !== null && update.otsUsage.otsUsing !== undefined) ? update.otsUsage.otsUsing : carData.otsUsing
                        carData.otsLimit = (update.otsUsage.otsLimit !== null && update.otsUsage.otsLimit !== undefined) ? update.otsUsage.otsLimit : carData.otsLimit
                    }
                    break
                case 'pitInfo':
                    if (carIndex !== -1) {
                        carData.driverName = update.pitInfo.DRIVER_NAME || carData.driverName
                        carData.isPitIn = update.pitInfo.isPitIn
                    }
                break

            }
            const updatedTable = [...prevTableData]
            if (carIndex === -1) {
                updatedTable.push(carData)
            }
            organizeBattleTableData(updatedTable)
            return updatedTable
        })

        function updateLatestDataByCar(carNumber, newData, type) {
            let latestData = latestDataByCar[carNumber]
            if (!latestData) {
                latestData = {
                    carNumber: parseInt(carNumber),
                    diff: 0,
                    totalLapTime: 0,
                    totalSectorTime: 0,
                    latestLap: {LAP_NUMBER: 0},
                    latestSector: {LAP_NUMBER: 0},
                    isLapDataConsistent: true,
                    isSectorDataConsistent: true
                }
            }

            if (type === 'sectorData') {
                if (newData.SECTOR_NUMBER === 1) {
                    latestData.totalSectorTime = newData.TIME
                    latestData.isSectorDataConsistent = true
                } else {
                    if (latestData.isSectorDataConsistent) {
                        latestData.isSectorDataConsistent = (
                            (latestData.latestSector.SECTOR_NUMBER + 1) === newData.SECTOR_NUMBER
                                || latestData.latestSector.SECTOR_NUMBER + 1 === sectorLength)
                            && latestData.latestSector.LAP_NUMBER === newData.LAP_NUMBER
                    }
                    latestData.totalSectorTime += newData.TIME
                }

                if (newData.SECTOR_NUMBER === 1 || newData.SECTOR_NUMBER === sectorLength) {
                    const latestLap = lapData.find(lap => lap.CAR_NUMBER === carNumber && lap.LAP_NUMBER === newData.LAP_NUMBER - 1)
                    if (latestLap) {
                        if (latestLap.LAP_NUMBER > latestData.latestLap.LAP_NUMBER) {
                            latestData.latestLap = latestLap
                            latestData.totalLapTime += latestLap.TIME

                            if (latestData.isLapDataConsistent) {
                                latestData.isLapDataConsistent = (latestData.latestLap.LAP_NUMBER + 1) === newData.LAP_NUMBER
                            }
                        }
                    } else {
                        latestData.isLapDataConsistent = false
                    }
                }

                latestData.latestSector = newData
            }

            setLatestDataByCar((prev) => {
                return {
                    ...prev,
                    [carNumber]: latestData
                }
            })
        }
    }

    const handleHeaderUpdate = async (updateType, update) => {
        switch (updateType) {
            case 'raceRemainingTimeAndLaps':
                setRemainingTimeAndLaps(update.raceRemainingTimeAndLaps)
                break
            case 'flagInfo':
                let color
                let flagInfo = ''
                if (update.flagInfo) {
                    flagInfo = update.flagInfo.toLowerCase()
                }

                if (flagInfo.includes('safety car in this lap')) {
                    color = 'safety-car-in-this-lap'
                } else if (flagInfo.includes('safety car')) {
                    color = 'safety-car'
                } else if (flagInfo.includes('再スタート')) {
                    color = 'restart'
                } else if (flagInfo.includes('sc')) {
                    color = 'sc'
                } else if (flagInfo.includes('black and white')) {
                    color = 'black-and-white'
                } else if (flagInfo.includes('black flag with orange disc')) {
                    color = 'black-flag-with-orange-disc'
                } else if (flagInfo.includes('black')) {
                    color = 'black'
                } else if (flagInfo.includes('white')) {
                    color = 'blue'
                } else if (flagInfo.includes('checkered')) {
                    color = 'checkered'
                } else if (flagInfo.includes('code 60')) {
                    color = 'code-60'
                } else if (flagInfo.includes('green')) {
                    color = 'green'
                } else if (flagInfo.includes('national flag')) {
                    color = 'national-flag'
                } else if (flagInfo.includes('red and yellow')) {
                    color = 'red-and-yellow-stripes'
                } else if (flagInfo.includes('red')) {
                    color = 'red'
                } else if (flagInfo.includes('white')) {
                    color = 'white'
                } else if (flagInfo.includes('yellow')) {
                    color = 'yellow'
                } else if (flagInfo.includes('fcy')) {
                    color = 'fcy'
                }  else if (flagInfo.includes('drive through penalty')) {
                    color = 'drive-through-penalty'
                } else {
                    color = 'default'
                }

            setFlagInfo({text: update.flagInfo, color})
            break
        }
    }

    const handleRaceDetailIdChange = async (e) => {
        setFollowCarNumber('')
        setHistoryGap({})
        
        localStorage.removeItem('hiddenValue')
        let element = e.target, value = element.value
        if (value !== '0') {
            setRaceDetailId(value)
            webSocketSendMessage({ type: 'live_changedRaceDetailId', data: { raceDetailId: value } } )
            
            webSocketSendMessage({ type: 'live_allOtsData', data: { raceDetailId: value }} )
            webSocketSendMessage({ type: 'live_weatherData', data: { raceDetailId: value }} )
            setInterval(() => {
                webSocketSendMessage({ type: 'live_weatherData', data: { raceDetailId: value }} )
            }, 300000)
            webSocketSendMessage({ type: 'live_precipitationForecast', data: { raceDetailId: value }} )
            setInterval(() => {
                webSocketSendMessage({ type: 'live_precipitationForecast', data: { raceDetailId: value }} )
            }, 60000)
        }

        return () => {
            WebSocketService.close()
        }
    }

    const handleSectorTrafficChange = () => {
        let sectorTrafficCount = {
            sec1: 0,
            sec2: 0,
            sec3: 0,
            sec4: 0,
            pit: 0
        }

        tableData.forEach(data => {
            if (data.isPitIn) {
                sectorTrafficCount.pit++
                return
            }

            if (sectorLength === 4) {
                const { sec1, sec2, sec3, sec4 } = data

                if (sec1 && sec2 && sec3 && sec4) {
                    sectorTrafficCount.sec1++
                } else if (sec1 && sec2 === '' && sec3 === '' && sec4 === '') {
                    sectorTrafficCount.sec2++
                } else if (sec1 && sec2 && sec3 === '' && sec4 === '') {
                    sectorTrafficCount.sec3++
                } else if (sec1 && sec2 && sec3 && sec4 === '') {
                    sectorTrafficCount.sec4++
                }
            } else if (sectorLength === 3) {
                const { sec1, sec2, sec3 } = data

                if (sec1 && sec2 && sec3) {
                    sectorTrafficCount.sec1++
                } else if (sec1 && sec2 === '' && sec3 === '') {
                    sectorTrafficCount.sec2++
                } else if (sec1 && sec2 && sec3 === '') {
                    sectorTrafficCount.sec3++
                }
            }
        })
        setSectorTraffic(sectorTrafficCount)
    }

    const handleCarClassCheckboxChange = (e) => {
        const carClass = e.target.value
        const isChecked = e.target.checked
        const isAll = carClass === 'all'

        setCarClasses((prev) => {
            return prev.map((car) => {
                if (isAll || car.class === carClass) {
                    car.isVisible = isChecked
                }
                return car
            })
        })

        const updatedTableData = tableData.map((car) => {
            if (isAll || car.carClass === carClass) {
                car.isVisible = isChecked
            }
            return car
        })

        setTableData(updatedTableData)
        organizeBattleTableData(updatedTableData)
    }

    const organizeBattleTableData = async (tableData) => {
        const sortedTableData = tableData.sort((a, b) => {
            if (!a.pos && !b.pos) {
                return 0
            } else if (!a.pos) {
                return 1
            } else if (!b.pos) {
                return -1
            }

            return a.pos - b.pos
        })

        const newTableDataWithBattle = await SetBattle(sortedTableData, isRace, followCarNumber, displayFollowAndRivals, carClasses)
        setTableDataWithBattle(newTableDataWithBattle)
    }
        
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    return (
        <DataContext.Provider value={{
            raceDetailId,
            tableData,
            tableDataWithBattle,
            lapData,
            sectorData,
            otsData,
            sectorLength,
            followCarNumber,
            rivalCarNumbers,
            displayFollowAndRivals,
            displayMapGroups,
            isRace,
            raceSeries,
            overallBest,
            predictPitInMode,
            projectPositionMode,
            historyGap,
            circuitId,
            singleWsSectorData,
            singleWsLapData,
            pitRoadExitTime,
            pitRoadExitProgressPos,
            carClasses,
            mapLeaders,
            remainingTimeAndLaps,
            isLightMode,
            styleSheet
        }}>
        {(<HeaderWithMenu 
            setOpenModal = {setOpenModal}
            setModalType = {setModalType}
            tableChartToggle = {tableChartToggle}
            setTableChartToggle = {setTableChartToggle}
            displayMap = {displayMap}
            setDisplayMap = {setDisplayMap}
            mapDisplayLeaders = {mapDisplayLeaders}
            setDisplayMapLeaders = { setDisplayMapLeaders }
            handleRaceDetailIdChange = { handleRaceDetailIdChange }
            setPredictPitInMode = { setPredictPitInMode }
            setProjectPositionMode = { setProjectPositionMode }
            setChangeViewMode = { setChangeViewMode}
            setCurrentView = { setCurrentView }
            changeViewMode = { changeViewMode }
            latestReceivedTime = { latestReceivedTime }
            precipitationForecast = { precipitationForecast }
            weatherData = { weatherData }
            raceDetailId = { raceDetailId }
            numOfLaps = { numOfLaps }
            sectorLength = { sectorLength }
            carClasses = { carClasses }
            handleCarClassCheckboxChange = { handleCarClassCheckboxChange }
            raceDetailListUpdate = { raceDetailListUpdate }
            originalRaceDetailListUpdate = { originalRaceDetailListUpdate}
            columnOrder = { columnOrder }
            setColumnOrder = { setColumnOrder }
            columnOriginalLength = { columnOriginalLength }
            columnIdAndName = { columnIdAndName }
            remainingTimeAndLaps = { remainingTimeAndLaps }
            raceTimeAndLaps = { raceTimeAndLaps }
            flagInfo = { flagInfo }
            totalTimeForRemainingTime = { totalTimeForRemainingTime }
            eventId = { eventId }
            displayRivalHeaders = { displayRivalHeaders }
            setDisplayRivalHeaders = { setDisplayRivalHeaders }
            pitStopTimeSettingStatus = { pitStopTimeSettingStatus }
            displayFollowAndRivals = { displayFollowAndRivals }
            setDisplayFollowAndRivals = { setDisplayFollowAndRivals }
            displayMapGroups = { displayMapGroups }
            setDisplayMapGroups = { setDisplayMapGroups }
            isScroll = { isScroll }
            tableScroll = { tableScroll }
            setIsLightMode= {setIsLightMode}
            tabletMode = {tabletMode}
            setTabletMode = {setTabletMode}
            mobileMode = {mobileMode}
            setMobileMode = {setMobileMode}
            setStyleSheet = {setStyleSheet}
        />)}
            {raceType === 'fp' && 
                <FPLive 
                children = {
                    <>  
                        <TableChartToggleHandler
                            tableChartToggle={ tableChartToggle }
                            displayMap = { displayMap }
                            mapDisplayLeaders = { mapDisplayLeaders }
                            setMapLeaders = { setMapLeaders }
                            setFollowCarNumber={ setFollowCarNumber }
                            setRivalCarNumbers={ setRivalCarNumbers }
                            sectorTraffic={ sectorTraffic }
                            setPredictPitInMode={ setPredictPitInMode }
                            highlightUpdatedRows={ highlightUpdatedRows }
                            setTableData={ setTableData }
                            setLapData={ setLapData }
                            pitRoadTime={ pitRoadTime }
                            pitStopTimeByRefueling={ pitStopTimeByRefueling }
                            pitStopTimeByWork={ pitStopTimeByWork }
                            pitStopTimeSettingStatus = { pitStopTimeSettingStatus }
                            tireWarmUpLossTime = { tireWarmUpLossTime }
                            raceManagementCar = { raceManagementCar }
                            setRaceManagementCar = { setRaceManagementCar }
                            setupChange = { setupChange }
                            setSetupChange = { setSetupChange }
                            driverComment = { driverComment }
                            setDriverComment = { setDriverComment }
                            notes = { notes }
                            setNotes = { setNotes }
                            latestDataByCar={ latestDataByCar }
                            setHistoryGap={ setHistoryGap }
                            carClasses={ carClasses }
                            raceDetailId={ raceDetailId }
                            currentView={ currentView }
                            columnOrder = { columnOrder }
                            setColumnOrder = { setColumnOrder }
                            columnIdAndName = { columnIdAndName }
                            displayRivalHeaders = { displayRivalHeaders }
                            tableScroll = { tableScroll }
                            setTableScroll = { setTableScroll }
                            sectorLength = { sectorLength }
                            mapSize = { mapSize }
                            setMapSize = { setMapSize }
                            setModalType={ setModalType }
                            setOpenModal={ setOpenModal }
                            mobileTableContent ={mobileTableContent}
                            setMobileTableContent={setMobileTableContent}
                            tabletMode = {tabletMode}
                            mobileMode = {mobileMode}
                            styleSheet={styleSheet}
                        />
                        <ModalComponent
                            open={ openModal }
                            onClose={ handleCloseModal }
                            setOpenModal = { setOpenModal }
                            modalType={ modalType }
                            paceColors={ modalType === 'alldriverlaps' ? '' : paceColors }
                            pitStopTimeByRefueling = { pitStopTimeByRefueling }
                            setPitStopTimeByRefueling = {setPitStopTimeByRefueling}
                            pitStopTimeByWork = { pitStopTimeByWork }
                            setPitStopTimeByWork = {setPitStopTimeByWork}
                            setPitStopTimeSettingStatus= {setPitStopTimeSettingStatus}
                            raceSeries = { raceSeries }
                            carClasses = { carClasses }
                            handleCarClassCheckboxChange = { handleCarClassCheckboxChange }
                            numOfLaps = { numOfLaps }
                        />
                    </>
                }
                />
            }
            {raceType === 'qf' &&
                <QFLive 
                children = {
                    <>  
                        <TableChartToggleHandler
                            tableChartToggle={ tableChartToggle }
                            displayMap = { displayMap }
                            mapDisplayLeaders = { mapDisplayLeaders }
                            setMapLeaders = { setMapLeaders }
                            setFollowCarNumber={ setFollowCarNumber }
                            setRivalCarNumbers={ setRivalCarNumbers }
                            sectorTraffic={ sectorTraffic }
                            setPredictPitInMode={ setPredictPitInMode }
                            highlightUpdatedRows={ highlightUpdatedRows }
                            setTableData={ setTableData }
                            setLapData={ setLapData }
                            pitRoadTime={ pitRoadTime }
                            pitStopTimeByRefueling={ pitStopTimeByRefueling }
                            pitStopTimeByWork={ pitStopTimeByWork }
                            pitStopTimeSettingStatus = { pitStopTimeSettingStatus }
                            tireWarmUpLossTime = { tireWarmUpLossTime }
                            raceManagementCar = { raceManagementCar }
                            setRaceManagementCar = { setRaceManagementCar }
                            setupChange = { setupChange }
                            setSetupChange = { setSetupChange }
                            driverComment = { driverComment }
                            setDriverComment = { setDriverComment }
                            notes = { notes }
                            setNotes = { setNotes }
                            latestDataByCar={ latestDataByCar }
                            setHistoryGap={ setHistoryGap }
                            carClasses={ carClasses }
                            raceDetailId={ raceDetailId }
                            currentView={ currentView }
                            columnOrder = { columnOrder }
                            setColumnOrder = { setColumnOrder }
                            columnIdAndName = { columnIdAndName }
                            displayRivalHeaders = { displayRivalHeaders }
                            tableScroll = { tableScroll }
                            setTableScroll = { setTableScroll }
                            sectorLength = { sectorLength }
                            mapSize = { mapSize }
                            setMapSize = { setMapSize }
                            setModalType={ setModalType }
                            setOpenModal={ setOpenModal }
                            mobileTableContent ={mobileTableContent}
                            setMobileTableContent={setMobileTableContent}
                            tabletMode = {tabletMode}
                            mobileMode = {mobileMode}
                            styleSheet={styleSheet}
                        />
                        <ModalComponent
                            open={ openModal }
                            onClose={ handleCloseModal }
                            setOpenModal = { setOpenModal }
                            modalType={ modalType }
                            paceColors={ modalType === 'alldriverlaps' ? '' : paceColors }
                            pitStopTimeByRefueling = { pitStopTimeByRefueling }
                            setPitStopTimeByRefueling = {setPitStopTimeByRefueling}
                            pitStopTimeByWork = { pitStopTimeByWork }
                            setPitStopTimeByWork = {setPitStopTimeByWork}
                            setPitStopTimeSettingStatus= {setPitStopTimeSettingStatus}
                            raceSeries = { raceSeries }
                            carClasses = { carClasses }
                            handleCarClassCheckboxChange = { handleCarClassCheckboxChange }
                            numOfLaps = { numOfLaps }
                        />
                    </>
                }
                />
            }
            {raceType === 'race' &&
                <RaceLive 
                    children = {
                        <>  
                            <TableChartToggleHandler
                                tableChartToggle={ tableChartToggle }
                                displayMap = { displayMap }
                                mapDisplayLeaders = { mapDisplayLeaders }
                                setMapLeaders = { setMapLeaders }
                                setFollowCarNumber={ setFollowCarNumber }
                                setRivalCarNumbers={ setRivalCarNumbers }
                                sectorTraffic={ sectorTraffic }
                                setPredictPitInMode={ setPredictPitInMode }
                                highlightUpdatedRows={ highlightUpdatedRows }
                                setTableData={ setTableData }
                                setLapData={ setLapData }
                                pitRoadTime={ pitRoadTime }
                                pitStopTimeByRefueling={ pitStopTimeByRefueling }
                                pitStopTimeByWork={ pitStopTimeByWork }
                                pitStopTimeSettingStatus = { pitStopTimeSettingStatus }
                                tireWarmUpLossTime = { tireWarmUpLossTime }
                                raceManagementCar = { raceManagementCar }
                                setRaceManagementCar = { setRaceManagementCar }
                                setupChange = { setupChange }
                                setSetupChange = { setSetupChange }
                                driverComment = { driverComment }
                                setDriverComment = { setDriverComment }
                                notes = { notes }
                                setNotes = { setNotes }
                                latestDataByCar={ latestDataByCar }
                                setHistoryGap={ setHistoryGap }
                                carClasses={ carClasses }
                                raceDetailId={ raceDetailId }
                                currentView={ currentView }
                                columnOrder = { columnOrder }
                                setColumnOrder = { setColumnOrder }
                                columnIdAndName = { columnIdAndName }
                                displayRivalHeaders = { displayRivalHeaders }
                                tableScroll = { tableScroll }
                                setTableScroll = { setTableScroll }
                                sectorLength = { sectorLength }
                                mapSize = { mapSize }
                                setMapSize = { setMapSize }
                                setModalType={ setModalType }
                                setOpenModal={ setOpenModal }
                                mobileTableContent ={mobileTableContent}
                                setMobileTableContent={setMobileTableContent}
                                tabletMode = {tabletMode}
                                mobileMode = {mobileMode}
                                styleSheet={styleSheet}
                            />
                            <ModalComponent
                                open={ openModal }
                                onClose={ handleCloseModal }
                                setOpenModal = { setOpenModal }
                                modalType={ modalType }
                                paceColors={ modalType === 'alldriverlaps' ? '' : paceColors }
                                pitStopTimeByRefueling = { pitStopTimeByRefueling }
                                setPitStopTimeByRefueling = {setPitStopTimeByRefueling}
                                pitStopTimeByWork = { pitStopTimeByWork }
                                setPitStopTimeByWork = {setPitStopTimeByWork}
                                setPitStopTimeSettingStatus= {setPitStopTimeSettingStatus}
                                raceSeries = { raceSeries }
                                carClasses = { carClasses }
                                handleCarClassCheckboxChange = { handleCarClassCheckboxChange }
                                numOfLaps = { numOfLaps }
                            />
                        </>
                    }
                />
            }

            {tableChartToggle === 'chart' ? (
                <HeaderWithMapMenu
                    setOpenModal={setOpenModal}
                    setModalType={setModalType}
                    tableChartToggle={tableChartToggle}
                    setTableChartToggle={setTableChartToggle}
                    displayMap={displayMap}
                    setDisplayMap={setDisplayMap}
                    mapDisplayLeaders={mapDisplayLeaders}
                    setDisplayMapLeaders={setDisplayMapLeaders}
                    handleRaceDetailIdChange={handleRaceDetailIdChange}
                    setPredictPitInMode={setPredictPitInMode}
                    setProjectPositionMode={setProjectPositionMode}
                    setChangeViewMode={setChangeViewMode}
                    setCurrentView={setCurrentView}
                    changeViewMode={changeViewMode}
                    latestReceivedTime={latestReceivedTime}
                    precipitationForecast={precipitationForecast}
                    weatherData={weatherData}
                    raceDetailId={raceDetailId}
                    numOfLaps={numOfLaps}
                    sectorLength={sectorLength}
                    carClasses={carClasses}
                    handleCarClassCheckboxChange={handleCarClassCheckboxChange}
                    raceDetailListUpdate={raceDetailListUpdate}
                    originalRaceDetailListUpdate={originalRaceDetailListUpdate}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                    columnOriginalLength={columnOriginalLength}
                    columnIdAndName={columnIdAndName}
                    remainingTimeAndLaps={remainingTimeAndLaps}
                    raceTimeAndLaps={raceTimeAndLaps}
                    flagInfo={flagInfo}
                    totalTimeForRemainingTime={totalTimeForRemainingTime}
                    eventId={eventId}
                    displayRivalHeaders={displayRivalHeaders}
                    setDisplayRivalHeaders={setDisplayRivalHeaders}
                    pitStopTimeSettingStatus={pitStopTimeSettingStatus}
                    displayFollowAndRivals={displayFollowAndRivals}
                    setDisplayFollowAndRivals={setDisplayFollowAndRivals}
                    isScroll={isScroll}
                    tableScroll={tableScroll}
                />
            ) : (
                <HeaderWithSpMenu
                    setOpenModal={setOpenModal}
                    setModalType={setModalType}
                    tableChartToggle={tableChartToggle}
                    setTableChartToggle={setTableChartToggle}
                    displayMap={displayMap}
                    setDisplayMap={setDisplayMap}
                    mapDisplayLeaders={mapDisplayLeaders}
                    setDisplayMapLeaders={setDisplayMapLeaders}
                    handleRaceDetailIdChange={handleRaceDetailIdChange}
                    setPredictPitInMode={setPredictPitInMode}
                    setProjectPositionMode={setProjectPositionMode}
                    setChangeViewMode={setChangeViewMode}
                    setCurrentView={setCurrentView}
                    changeViewMode={changeViewMode}
                    latestReceivedTime={latestReceivedTime}
                    precipitationForecast={precipitationForecast}
                    weatherData={weatherData}
                    raceDetailId={raceDetailId}
                    numOfLaps={numOfLaps}
                    sectorLength={sectorLength}
                    carClasses={carClasses}
                    handleCarClassCheckboxChange={handleCarClassCheckboxChange}
                    raceDetailListUpdate={raceDetailListUpdate}
                    originalRaceDetailListUpdate={originalRaceDetailListUpdate}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                    columnOriginalLength={columnOriginalLength}
                    columnIdAndName={columnIdAndName}
                    remainingTimeAndLaps={remainingTimeAndLaps}
                    raceTimeAndLaps={raceTimeAndLaps}
                    flagInfo={flagInfo}
                    totalTimeForRemainingTime={totalTimeForRemainingTime}
                    eventId={eventId}
                    displayRivalHeaders={displayRivalHeaders}
                    setDisplayRivalHeaders={setDisplayRivalHeaders}
                    pitStopTimeSettingStatus={pitStopTimeSettingStatus}
                    displayFollowAndRivals={displayFollowAndRivals}
                    setDisplayFollowAndRivals={setDisplayFollowAndRivals}
                    isScroll={isScroll}
                    tableScroll={tableScroll}
                />
            )}

            
            <FooterMenu
                raceDetailId = { raceDetailId }
                numOfLaps = { numOfLaps }
                eventId = { eventId }
            />
        </DataContext.Provider>
    )
}

export default Live

function getCircuitInfo(raceDetailId) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve(0)

        fetch(`/api/circuit/getCircuitInfo/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data[0]))
            .catch(error => {
                reject(error)
            })
    })
}

function getRaceDetailInfo(raceDetailId) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve(0)

        fetch(`/api/raceDetail/getRaceDetail/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function getLatest50LapData(raceDetailId) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/race/getLatest50LapData/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function getLatestLapDataByCarNumberObj(raceDetailId) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/lap/getLatestLapDataByCarNumberObj/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function getLatestSectorData(raceDetailId) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/race/getLatestSectorData/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function getCarClasses(raceDetailId) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/car/getCarClasses/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function getCarClass(raceDetailId, carNumber) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId || !carNumber) return resolve(null)

        fetch(`/api/car/getCarClass/${raceDetailId}/${carNumber}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function getDriverData(driverName) {
    return new Promise ((resolve, reject) => {
        if (!driverName) return resolve([])

        fetch(`/api/driver/getDriverData/${driverName}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function getAllLapData(raceDetailId) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/race/getAllLapData/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}