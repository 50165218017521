import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Header from '../components/Header'
import RivalSectorComparison from '../components/RivalSectorComparison'
import OutingAnalysis from '../components/OutingAnalysis'

import '../css/analysis.css'

import { useTranslation } from 'react-i18next'

const FPAnalysis = () => {
    const { t } = useTranslation(['strategy'])

    const { eventId, raceDetailId } = useParams()

    const [ viewMode, setViewMode ] = useState('outing')

    const [circuitData, setCircuitData] = useState([])
    const [raceData, setRaceData] = useState([])

    const navigate = useNavigate()
    const handleButtonClick = () => {
        navigate(`/event/${eventId}`)
    }

    return (
        <>
            <Header className='header-custom'/>
            <div className='display-race-container fp-race-container analysis-page'>
                <div className='qf-race-tit-section'>
                <button className='event-button' onClick={handleButtonClick}>{t('general.sessionList')}</button>
                <div className='event-tit-container'>
                <h1>RACE</h1>
                <span className='tit-decoration'><span className='small-text'>Event</span> {raceData?.EVENT_NAME}</span>
                <span className='tit-decoration'><span className='small-text'>Track</span> {circuitData[0]?.NAME}</span>
            </div>
                </div>

                <div className='fp-race-buttn-contents select-container'>
                <span className='box-title'>{t('general.displaySwitch')}</span>
                    <select
                        value={viewMode}
                        onChange={(e) => setViewMode(e.target.value)}
                        className='select-box'
                    >
                        <option value='outing'>{t('fpAnalysis.outingAnalysis')}</option>
                        <option value='rival'>{t('general.rivalComparison')}</option>
                    </select>
                </div>
                {
                    viewMode === 'rival' ?
                    <RivalSectorComparison raceDetailId = {raceDetailId}/> 
                    : <OutingAnalysis raceDetailId = {raceDetailId} />
                } 
            </div>
        </>
    )
}

export default FPAnalysis