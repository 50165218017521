import { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import BestLapComparison from '../components/BestLapComparison'
import ClassSelectElement from '../components/ClassSelectElement'
import Header from '../components/Header'
import RivalSectorComparison from '../components/RivalSectorComparison'

import '../css/analysis.css'

import { useTranslation } from 'react-i18next'

const QFAnalysis = () => {
  const { t } = useTranslation(['strategy', 'general'])

  const { eventId, raceDetailId } = useParams()

  const viewHandlerButtonElement = useRef()
  const classHandlerButtonElement = useRef()
  const changeViewElement = useRef()

  const [carClassDatas, setCarClassDatas] = useState(null)
  const [showClassSelectElement, setShowClassSelectElement] = useState(false)
  const [selectedClasses, setSelectedClasses] = useState(null)
  const [viewMode, setViewMode] = useState('best')

  const navigate = useNavigate()
  const handleButtonClick = () => {
    navigate(`/event/${eventId}`)
  }

  const handleClassSelection = () => {
    showClassSelectElement !== true
      ? setShowClassSelectElement(true)
      : setShowClassSelectElement(false)
  }


  const handleSelectedClassesChange = (newSelectedClasses) => {
    setSelectedClasses(newSelectedClasses)
  }

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        viewHandlerButtonElement.current &&
        viewHandlerButtonElement.current.contains(event.target)
      )
        return
      if (
        classHandlerButtonElement.current &&
        classHandlerButtonElement.current.contains(event.target)
      )
        return
      if (
        changeViewElement.current &&
        changeViewElement.current.contains(event.target)
      )
        return
      setShowClassSelectElement(false)
    }
  
    document.addEventListener('click', handleOutsideClick)
  
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    const getCarClasses = () => {
      fetch(`/api/car/getCarClasses/${raceDetailId}`)
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 200) {
            setCarClassDatas(json.data)
          }
        })
        .catch((error) => {
          console.log(error);
          alert(t('pleaseContactAdmin', {ns: 'general'}));
        });
    };

    getCarClasses()
  }, [raceDetailId])

  return (
    <>
    <Header className='header-custom' />
      <div className='display-race-container qf-race-container analysis-page'>
        <div className='qf-race-tit-section'>
            <button className='event-button' onClick={handleButtonClick}>{t('general.sessionList')}</button>
            <div className='event-tit-container'>
                <h1>QF1</h1>
            </div>
        </div>

        <div class="selecters">
          <div className='select-container'>
            <span className='box-title'>{t('general.displaySwitch')}</span>
            <select
              value={viewMode}
              onChange={(e) => setViewMode(e.target.value)}
              className='select-box'
            >
              <option value='best'>{t('qfAnalysis.bestLapList')}</option>
              <option value='rival'>{t('general.rivalComparison')}</option>
              {/* <option value='team'>{t('general.sameTeamComparison')}</option> */}
            </select>
          </div>

          <div className='class-select-molecule'>
            <button
              name='selectedClass'
              style={{ width: '150px', color: '#FFFF' }}
              onClick={handleClassSelection}
              ref={viewHandlerButtonElement}
            >
              {t('general.classSelection')}
            </button>
            <div className='class-select-element' ref={changeViewElement}>
              {showClassSelectElement && (
                <ClassSelectElement
                  state={showClassSelectElement}
                  carClassDatas={carClassDatas}
                  selectedClasses={selectedClasses}
                  handleSelectedClassesChange={handleSelectedClassesChange}
                  style={{ position: 'absolute' }}
                />
              )}
            </div>
          </div>
        </div>

        

        {viewMode === 'best' ? (
          <BestLapComparison
            raceDetailId={raceDetailId}
            selectedClasses={selectedClasses}
          />
        ) : viewMode === 'rival' ? (
          <RivalSectorComparison raceDetailId={raceDetailId} />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default QFAnalysis
