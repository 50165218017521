import {useState, useEffect} from 'react'

import Track from '../services/Track'
import {useLiveData} from '../../DataContext'

import PitinMap from './PitinMap'

import {gsap} from 'gsap'

import { useTranslation } from 'react-i18next'

const Map = ({pitRoadTime, pitStopTimeByRefueling, pitStopTimeByWork, pitStopTimeSettingStatus, mapSize, setModalType, setOpenModal, mapDisplayLeaders, tableChartToggle}) => {
    const { t } = useTranslation(['strategy', 'general'])

    const {tableData, raceDetailId, circuitId, singleWsSectorData, singleWsLapData, sectorLength, followCarNumber, rivalCarNumbers, sectorData, raceSeries, mapLeaders, displayMapGroups, carClasses} = useLiveData()
    const [latestSectorLap, setLatestSectorLap] = useState({})
    const [movingData, setMovingData] = useState({})
    const [startAnimationDataObject, setStartAnimationDataObject] = useState({})
    const [carMotion, setCarMotion] = useState({})
    const [currentProgressBySector, setCurrentProgressBySector] = useState({})
    const [currentSectorLocation, setCurrentSectorLocation] = useState({})
    const [trackPath, setTrackPath] = useState({})
    const [reload, setReload] = useState(false)

    const [beforePitinSectorData, setBeforePitinSectorData] = useState({})

    const [trackId, setTrackId] = useState('trackSvg')
    const [carNumberTeamColorObj, setCarNumberTeamColorObj] = useState({})
    const [mapBattleData, setMapBattleData] = useState([])

    let predictContainerPositionTop = {}
    let pitInMapTop = {}
    let pitInTop = {}

    const lapSpeed = 1

    const settingViewHandler = () => {{
        setModalType('settingView')
        setOpenModal(true)
    }}

    useEffect(() => {
        if (raceDetailId) {
            killGsapByClassName(`.car_${trackId}`)
    
            getLatestSectorLap(raceDetailId)
                .then(json => {
                    const laterSectorLapObj = {}
    
                    json.forEach(data => {
                        if (typeof laterSectorLapObj[data.CAR_NUMBER] === 'undefined') {
                            laterSectorLapObj[data.CAR_NUMBER] = []
                        }
                        laterSectorLapObj[data.CAR_NUMBER].push({
                            LAP_NUMBER: data.LAP_NUMBER,
                            SECTOR_NUMBER: data.SECTOR_NUMBER,
                            TIME: data.TIME,
                            PREV_PIT_STOP_TIME: data.PREV_PIT_STOP_TIME,
                            PIT: data.PIT
                        })
                    })

                    setLatestSectorLap(prev => ({
                        ...prev,
                        ...laterSectorLapObj
                    }))
    
                    json.forEach(data => {
                        if (data.PIT) {
                            pitinDisplay(data.CAR_NUMBER, data.PIT)
                        }
                    })
                })
                .catch(error => {
                    alert(t('pleaseContactAdmin', {ns: 'general'}))
                })
        }
    }, [raceDetailId, tableChartToggle])
    

    useEffect(() => {
        if (!singleWsLapData) return
    
        const incomingLapCarNo = singleWsLapData.CAR_NUMBER
        const incomingCurrentPitStop = singleWsLapData.PIT_STOP_TIME || 0
        const sectorData = latestSectorLap[incomingLapCarNo]
        const prevPitStop = sectorData ? sectorData[0].PREV_PIT_STOP_TIME : 0
        const checkIfIsPitin = incomingCurrentPitStop !== prevPitStop
    
        setBeforePitinSectorData(prev => {
            if (prev[incomingLapCarNo]?.isPitIn !== checkIfIsPitin) {
                return {
                    ...prev,
                    [incomingLapCarNo]: {
                        isPitIn: checkIfIsPitin,
                        SECTOR_DATA: sectorData
                    }
                }
            }
            return prev
        })
    }, [singleWsLapData, latestSectorLap])

    useEffect(() => {
        for (let i=1; i<=sectorLength; i++) {
            setTrackPath(prev => ({
                ...prev,
                [i]: document.getElementById(`sec${i}${trackId}`)?.getAttribute('d')
            }))
        }
        if (!reload && sectorData.length >= 20) {
            const driverNumbers = sectorData.map(el => el.CAR_NUMBER)
            const uniqueDriverNumbers = [...new Set(driverNumbers)]
            const lastSectorData = []
            uniqueDriverNumbers.forEach(driverNumber => {
                lastSectorData.push(sectorData.filter(el => el.CAR_NUMBER === driverNumber).slice(-1)[0])
            })
    
            const newMovingObject = {}
            const newCarMotionObject = {}
            const newStartAnimationDataObject = {}
    
            lastSectorData.forEach(carSectorData => {
                const { CAR_NUMBER, LAP_NUMBER, SECTOR_NUMBER, TIME } = carSectorData
                newMovingObject[CAR_NUMBER] = {
                    ...newMovingObject[CAR_NUMBER],
                    [`${LAP_NUMBER}_${SECTOR_NUMBER}`]: TIME
                }
                newCarMotionObject[CAR_NUMBER] = new gsap.timeline()
                newStartAnimationDataObject[CAR_NUMBER] = carSectorData
            })
    
            setMovingData(newMovingObject)
            setCarMotion(newCarMotionObject)
            setStartAnimationDataObject(newStartAnimationDataObject)
            setReload(true)
        }
    }, [raceDetailId, sectorData])

    useEffect(() => {
        for(let [carNumber, startAnimationData] of Object.entries(startAnimationDataObject)) {
            setLatestSectorLap(prev => {
                let updatedData = { ...prev }

                const latestSectorData = updateLatestSectorData(startAnimationData, updatedData, startAnimationData)
                updatedData = latestSectorData.updatedData
                startAnimationData = latestSectorData.startAnimationData

                return updatedData
            })
            startAnimation(startAnimationData, carMotion, 'reload')
        }
    }, [startAnimationDataObject])

    useEffect(() => {
        if (reload === false) return
        for (let i=1; i<=sectorLength; i++) {
            setTrackPath(prev => ({
                ...prev,
                [i]: document.getElementById(`sec${i}${trackId}`).getAttribute('d')
            }))
        }
        if (Object.keys(singleWsSectorData).length > 0 && trackId === 'trackSvg') {
            const newMovingData = buildMovingData(singleWsSectorData)
            setMovingData(newMovingData)
            const newCarMotion = buildCarMotion(singleWsSectorData)
            setCarMotion(newCarMotion)

            let startAnimationData = singleWsSectorData
            let pitinSectorData = beforePitinSectorData[singleWsSectorData.CAR_NUMBER]
            setLatestSectorLap(prev => {
                let updatedData = { ...prev }
                if (pitinSectorData?.isPitIn) {
                    const beforePitinSectorData = updateBeforePitinSectorData(singleWsSectorData, pitinSectorData, updatedData, startAnimationData)
                    updatedData = beforePitinSectorData.updatedData
                    startAnimationData = beforePitinSectorData.startAnimationData
                } else {
                    const latestSectorData = updateLatestSectorData(singleWsSectorData, updatedData, startAnimationData)
                    updatedData = latestSectorData.updatedData
                    startAnimationData = latestSectorData.startAnimationData
                }
                return updatedData
            })
            startAnimation(startAnimationData, newCarMotion, 'live')
        }
    }, [singleWsSectorData, reload])

    useEffect(() => {
        const followedCarAtTop = document.getElementById(`use_${trackId}`)

        if (followedCarAtTop) {
            followedCarAtTop.setAttribute('href', `#car${followCarNumber}_${trackId}`)
        }

        if (document.getElementById('togglePreditMap')) {
            document.getElementById('togglePreditMap').checked = false
        }
        setTrackId('trackSvg')
    }, [followCarNumber])

    useEffect(() => {
        Object.entries(carMotion).forEach(([carNo, timeline]) => {
            const timelineProgress = timeline.progress()
            const sectorNumber = timeline.labels.sectorNumber
            if (sectorNumber !== undefined){
                setCurrentSectorLocation(prev => ({
                    ...prev,
                    [carNo]: {
                        SECTOR: sectorNumber,
                        PROGRESS: timelineProgress
                    }
                }))
            }
        })
    }, [carMotion, sectorData])

    const buildMovingData = (singleWsSectorData, type, gap) => {
        const { CAR_NUMBER, LAP_NUMBER, SECTOR_NUMBER, TIME } = singleWsSectorData
        let updatedMovingData = { ...movingData }
    
        if (type !== 'delay') {
            updatedMovingData = {
                ...updatedMovingData,
                [CAR_NUMBER]: {
                    ...updatedMovingData[CAR_NUMBER],
                    [`${LAP_NUMBER}_${SECTOR_NUMBER}`]: TIME
                }
            }
        } else {
            updatedMovingData = {
                ...updatedMovingData,
                [`${CAR_NUMBER}G${gap}`]: {
                    ...updatedMovingData[`${CAR_NUMBER}G${gap}`],
                    [`${LAP_NUMBER}_${SECTOR_NUMBER}`]: TIME
                }
            }
        }
    
        return updatedMovingData
    }

    useEffect(() => {
        const updatedCarColors = Object.values(tableData).reduce((acc, data) => {
            const carNo = parseInt(data.carno.split('.')[1])
            const isPitIn = data.isPitIn
            
    
            acc[carNo] = {
                teamBgColor: raceSeries === 'SF' ? data.teamBgColor : '#FFFFFF',
                teamTextColor: raceSeries === 'SF' ? data.teamTextColor : '#000000'
            }
    
            pitinDisplay(carNo, isPitIn)
    
            return acc
        }, {})
    
        setCarNumberTeamColorObj(prevCarColors => ({
            ...prevCarColors,
            ...updatedCarColors
        }))
    }, [tableData, tableChartToggle])

    useEffect(() => {
        if (reload === false) return

        let carMovingWithGap = {...movingData}
        let carsBySectors = [[], [], [], []]
        let carGroups = []
        let groupProgressDifference = []

        const averageTimeBySector = calculateAverageTimePerSector(latestSectorLap)
        const cumsumAverageTimeBySector = {}
        let cumsumTime = 0
        Object.keys(averageTimeBySector).forEach(sectorNumber => {
            cumsumTime += averageTimeBySector[sectorNumber]
            cumsumAverageTimeBySector[sectorNumber] = cumsumTime
        })
        const averageLapTime = cumsumAverageTimeBySector[sectorLength]

        let carProgresssConvertedToTimeObjectArray = []
        Object.entries(carMotion).forEach(([carNo, timeline]) => {
            const sectorNumber = timeline.labels.sectorNumber
            if (sectorNumber !== undefined && Number(carNo)){
                const progresssConvertedToTime = 
                    sectorNumber === 1
                    ? averageTimeBySector[sectorNumber] * timeline.progress()
                    : cumsumAverageTimeBySector[sectorNumber - 1] + averageTimeBySector[sectorNumber] * timeline.progress()
                carProgresssConvertedToTimeObjectArray.push({
                    carNumber: Number(carNo),
                    progresssConvertedToTime
                })
            }
        })

        if (carProgresssConvertedToTimeObjectArray.length) {
            carProgresssConvertedToTimeObjectArray = carProgresssConvertedToTimeObjectArray.sort((a,b) => b?.progresssConvertedToTime - a?.progresssConvertedToTime)

            let carGroup = []
            let prevProgress = 0
            const battleRangeTime = 2
            carProgresssConvertedToTimeObjectArray.forEach((car, index) => {
                if (index === 0) {
                    carGroup.push(car.carNumber)
                    prevProgress = car.progresssConvertedToTime
                } else {
                    if ( prevProgress - car.progresssConvertedToTime < battleRangeTime) {
                        carGroup.push(car.carNumber)
                    }                    
                    else {
                        carGroups.push(carGroup)
                        carGroup = [car.carNumber]
                    }
                    prevProgress = car.progresssConvertedToTime
                }
            })
            carGroups.push(carGroup)

            const firstCar = carProgresssConvertedToTimeObjectArray[0]
            const lastCar = carProgresssConvertedToTimeObjectArray[carProgresssConvertedToTimeObjectArray.length - 1]
            if (lastCar?.progresssConvertedToTime - firstCar?.progresssConvertedToTime + averageLapTime < battleRangeTime) {
                const newCarGroup = [...carGroups[carGroups.length - 1], ...carGroups[0]]
                carGroups.shift()
                carGroups.pop()
                carGroups.push(newCarGroup)
            }
        }

        carGroups = carGroups.map((group) => group.filter((carNumber) => !isNaN(carNumber)))
        carGroups = carGroups.filter((group) => group.length >= 2)
        setMapBattleData(carGroups)

        const groupMiddleMakerGapTime = 2
        carGroups.forEach((group) => {
            const firstCar = group[0]
            const lastCar = group[group.length - 1]
            const firstCarProgressConvertedToTime = carProgresssConvertedToTimeObjectArray.find(car => car.carNumber === firstCar).progresssConvertedToTime
            const lastCarProgressConvertedToTime = carProgresssConvertedToTimeObjectArray.find(car => car.carNumber === lastCar).progresssConvertedToTime
            const gap = firstCarProgressConvertedToTime - lastCarProgressConvertedToTime >= 0
                ? firstCarProgressConvertedToTime - lastCarProgressConvertedToTime
                : firstCarProgressConvertedToTime - lastCarProgressConvertedToTime + averageLapTime
            groupProgressDifference.push({
                gap: Math.floor(gap / groupMiddleMakerGapTime),
            })
        })

        carGroups.forEach((battleGroup, index) => {
            if (battleGroup.length < 2) return
            const battleLeaderNumber = battleGroup[battleGroup.length - 1]
            
            const leaderGap = {}
            const carSeparation = groupProgressDifference[index].gap

            leaderGap.gap = carSeparation
            leaderGap.driverNumber = battleLeaderNumber
            leaderGap.driverData = {...sectorData.filter(el => el.CAR_NUMBER === battleLeaderNumber).slice(-1)[0]}

            if (carSeparation && carSeparation > 0) {
                for (let i = 1; i <= carSeparation; i++) {
                    const newCarGapMoving = buildMovingData(leaderGap.driverData, 'delay', i + 2)
                    carMovingWithGap = {...carMovingWithGap, ...newCarGapMoving}
                }
            }
        })
        setMovingData(carMovingWithGap)
    }, [raceDetailId, carMotion])

    const buildCarMotion = (singleWsSectorData) => {
        const carNumber = singleWsSectorData.CAR_NUMBER
        const newTimeline = new gsap.timeline()
    
        return {
            ...carMotion,
            [carNumber]: newTimeline
        }
    }

    const updateBeforePitinSectorData = (singleWsSectorData, pitinSectorData, updatedData, startAnimationData) => {
        const sectorNumber = singleWsSectorData.SECTOR_NUMBER
        const pitinSectorDataObj = pitinSectorData.SECTOR_DATA?.find(el => el.SECTOR_NUMBER === sectorNumber)
        const newUpdatedData = updatedData[singleWsSectorData.CAR_NUMBER]?.find(el => el.SECTOR_NUMBER === sectorNumber)

        if (newUpdatedData) {
            newUpdatedData.LAP_NUMBER = pitinSectorDataObj?.LAP_NUMBER
            newUpdatedData.TIME = pitinSectorDataObj?.TIME
            newUpdatedData.PREV_PIT_STOP_TIME = typeof parseInt(newUpdatedData.PREV_PIT_STOP_TIME) === 'number' ? newUpdatedData.PREV_PIT_STOP_TIME + 1 : 1
        }

        startAnimationData.TIME = pitinSectorDataObj ? pitinSectorDataObj.TIME : startAnimationData.TIME
        startAnimationData.LAP_NUMBER = pitinSectorDataObj ? pitinSectorDataObj.LAP_NUMBER : startAnimationData.LAP_NUMBER

        return {updatedData, startAnimationData}
    }

    const updateLatestSectorData = (singleWsSectorData, updatedData, startAnimationData) => {
        if (!updatedData[singleWsSectorData.CAR_NUMBER]) {
            updatedData[singleWsSectorData.CAR_NUMBER] = []
        }

        const sectorIndex = updatedData[singleWsSectorData.CAR_NUMBER].findIndex(el => el.SECTOR_NUMBER === singleWsSectorData.SECTOR_NUMBER)
        if (sectorIndex > -1) {
            updatedData[singleWsSectorData.CAR_NUMBER][sectorIndex].TIME = singleWsSectorData.TIME
            updatedData[singleWsSectorData.CAR_NUMBER][sectorIndex].LAP_NUMBER = singleWsSectorData.LAP_NUMBER
        } else {
            updatedData[singleWsSectorData.CAR_NUMBER].push({
                LAP_NUMBER: singleWsSectorData.LAP_NUMBER,
                SECTOR_NUMBER: singleWsSectorData.SECTOR_NUMBER,
                TIME: singleWsSectorData.TIME
            })
        }
        return {updatedData, startAnimationData}
    }

    const startAnimation = (wsSectorData, carMotionObj, type) => {
        const nextSectorNumber = wsSectorData.SECTOR_NUMBER === sectorLength ? 1 : wsSectorData.SECTOR_NUMBER + 1
        
        const animationIds = []

        const commonPrefixes = ["car", "carpulse", "background"]
        const maxSuffix = 20
        const suffix = `_${wsSectorData.CAR_NUMBER}_${trackId}`
        
        commonPrefixes.forEach(prefix => {
            animationIds.push(`${prefix}${suffix}`)
        })
        
        for (let i = 2; i <= maxSuffix; i++) {
            animationIds.push(`carpulse${wsSectorData.CAR_NUMBER}G${i}_${trackId}`)
        }
        
        animationIds.forEach(id => killGsapById(id))
        
        for (let i = 2; i <= maxSuffix; i++) {
            const key = `${wsSectorData.CAR_NUMBER}G${i}`
            if (movingData[key]) {
                const gMotion = { ...wsSectorData }
                gMotion.CAR_NUMBER = key
                carMotionObj[key] = new gsap.timeline().delay(parseInt(i) * 2 - 2)
                animation(gMotion, nextSectorNumber, carMotionObj, type)
            }
        }        
        animation(wsSectorData, nextSectorNumber, carMotionObj, type)
        setCarMotion(carMotionObj)
    }

    const animation = (wsSectorData, nextSectorNumber, carMotionObj, type) => {
        const carNumber = wsSectorData.CAR_NUMBER
        const sectorNumber = nextSectorNumber
        let prevTime = wsSectorData.TIME

        if (type === 'reload') {
            const currentTimeStamp = new Date()
            const sectorTimeStamp = new Date(wsSectorData.CREATED_AT ? wsSectorData.CREATED_AT : currentTimeStamp)
    
            if (document.getElementById(`car${carNumber}_${trackId}`)) {
                carMotionObj[carNumber].to(`#car${carNumber}_${trackId}`, {
                    duration: prevTime / lapSpeed,
                    repeat: 0,
                    ease: 'power0.in',
                    motionPath: {
                        path: trackPath[sectorNumber],
                        autoRotate: false,
                        alignOrigin: [0.5, 0.5]
                    }
                })
                carMotionObj[`${carNumber}`].addLabel("sectorNumber", sectorNumber)
                carMotionObj[`${carNumber}`].seek((currentTimeStamp - sectorTimeStamp) / 1000)
            }
            else if (document.getElementById(`carpulse${carNumber}_${trackId}`)) {
                carMotionObj[carNumber].to(`#carpulse${carNumber}_${trackId}`, {
                    duration: prevTime / lapSpeed,
                    repeat: 0,
                    ease: 'power0.in',
                    motionPath: {
                        path: trackPath[sectorNumber],
                        autoRotate: false,
                        alignOrigin: [0.5, 0.5]
                    }
                })
                carMotionObj[`${carNumber}`].addLabel('sectorNumber', sectorNumber)
                carMotionObj[`${carNumber}`].seek((currentTimeStamp - sectorTimeStamp) / 1000)
            }
        }
        else if (type === 'live') {
            let currentCarNumber = carNumber.toString()
            if (currentCarNumber.includes('G')) {
                currentCarNumber = currentCarNumber.split('G')[0]
            }

            let findPrevTime = latestSectorLap && latestSectorLap[Number(currentCarNumber)]
                ? latestSectorLap[Number(currentCarNumber)].find(el => el.SECTOR_NUMBER === sectorNumber)
                : null

            if (findPrevTime) {
                prevTime = findPrevTime.TIME
            } else {
                prevTime = wsSectorData.TIME
            }
            if (document.getElementById(`car${carNumber}_${trackId}`)) {
                carMotionObj[carNumber].to(`#car${carNumber}_${trackId}`, {
                    duration: prevTime / lapSpeed,
                    repeat: 0,
                    ease: 'power0.in',
                    motionPath: {
                        path: trackPath[sectorNumber],
                        autoRotate: false,
                        alignOrigin: [0.5, 0.5]
                    }
                })
                carMotionObj[`${carNumber}`].addLabel("sectorNumber", sectorNumber)
            } else if (document.getElementById(`carpulse${carNumber}_${trackId}`)) {
                carMotionObj[carNumber].to(`#carpulse${carNumber}_${trackId}`, {
                    duration: prevTime / lapSpeed,
                    repeat: 0,
                    ease: 'power0.in',
                    motionPath: {
                        path: trackPath[sectorNumber],
                        autoRotate: false,
                        alignOrigin: [0.5, 0.5]
                    }
                })
                carMotionObj[`${carNumber}`].addLabel('sectorNumber', sectorNumber)
            } else if (document.getElementById(`gap${carNumber}_${trackId}`)) {
                carMotionObj[carNumber].to(`#gap${carNumber}_${trackId}`, {
                    duration: prevTime / lapSpeed,
                    repeat: 0,
                    ease: 'power0.in',
                    motionPath: {
                        path: trackPath[sectorNumber],
                        autoRotate: false,
                        alignOrigin: [0.5, 0.5],
                    }
                })
                carMotionObj[`${carNumber}`].addLabel('sectorNumber', sectorNumber)
            }
        }
    }

    const killGsapByClassName = (className) => {
        const elementsToKill = document.querySelectorAll(className)

        elementsToKill.forEach((element) => {
            gsap.killTweensOf(element)
        })
    }
    
    const killGsapById = (id) => {
        const elementsToKill = document.getElementById(id)

        if (elementsToKill) {
            gsap.killTweensOf(elementsToKill)
        }
    }

    const preditMapToggle = (e) => {
        const isChecked = e.target.checked
        if (isChecked) {
            const elementsToKill = document.querySelectorAll('.car_preditMap')

            elementsToKill.forEach((element) => {
                gsap.killTweensOf(element)
            })

            const newProgressBySector = {}
    
            Object.entries(carMotion).forEach(([carNo, timeline]) => {
                const timelineProgress = timeline.progress()
                const sectorNumber = timeline.labels.sectorNumber
                if (sectorNumber !== undefined){
                    newProgressBySector[carNo] = {
                        POSITION: sectorNumber,
                        PROGRESS: timelineProgress
                    }
                }
            })
        
            setCurrentProgressBySector(prev => ({
                ...prev,
                ...newProgressBySector
            }))

            setTrackId('preditMap')
            document.getElementById('trackSvg').style.display = 'none'
        } else {
            setTrackId('trackSvg')
            document.getElementById('trackSvg').style.display = 'block'
        }
    }

    const pitinDisplay = (carNo, pit) => {
        const pitinCarObject = document.getElementById(`car${carNo}_${trackId}`)

        if (pitinCarObject) {
            if (pit) {
                pitinCarObject.style.opacity = 0
            } else {
                pitinCarObject.style.opacity = 1
            }
        }
    }

    return (
        <>
            { 
                circuitId !== 0 && followCarNumber ?
                    <div 
                        className='predict-container'
                        style={predictContainerPositionTop}
                        onClick={() => {!pitStopTimeSettingStatus && settingViewHandler()}}
                    >
                        <label className='switch square'  htmlFor='togglePreditMap'>
                            <input
                                id='togglePreditMap'
                                type='checkbox'
                                className='switch-input'
                                onChange={preditMapToggle}
                                disabled={followCarNumber && pitStopTimeSettingStatus ? false : true}
                            />
                            <span className='slider square'></span>
                        </label>
                        <div>{t('general.pitStopPrediction')}</div>
                    </div>
                    :
                    <></>
            }
            <Track
                id={ 'trackSvg' }
                circuitId={circuitId}
                drivers={movingData}
                carNumberTeamColorObj={carNumberTeamColorObj}
                mapSize={mapSize}
                followCarNumber={followCarNumber}
                rivalCarNumbers={rivalCarNumbers}
                mapLeaders={mapLeaders}
                mapDisplayLeaders={mapDisplayLeaders}
                displayMapGroups={displayMapGroups}
                battleData={mapBattleData}
                carClasses={carClasses}
                currentSectorLocation={currentSectorLocation}
            />
            {
                trackId === 'trackSvg' ?
                    <></>
                    :
                    <>
                        <PitinMap
                            pitLossTime={(pitRoadTime + pitStopTimeByRefueling + pitStopTimeByWork)}
                            circuitId={circuitId}
                            drivers={movingData}
                            currentProgressBySector={currentProgressBySector}
                            carNumberTeamColorObj={carNumberTeamColorObj}
                            pitInMapTop={pitInMapTop}
                            mapSize={mapSize}
                            battleData={mapBattleData}
                            carClasses={carClasses}
                            currentSectorLocation={currentSectorLocation}
                        />
                    </>
            }
            <div 
                className='pit-info-container'
                style={pitInTop}
            >
                <span>{t('map.pitStop')}：</span>
                <div className='map-in-pit-container'>
                    {
                        tableData.map((data, index) => {
                            if (data.isPitIn) {
                                return <div key={index} style={{ backgroundColor: data.teamBgColor, color: data.teamTextColor }}>{data.carno.split('.')[1]}</div>
                            }
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Map

function getLatestSectorLap(raceDetailId) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/race/getLatestSectorLap/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function calculateAverageTimePerSector(latestSectorLap) {
    const sectorTimes = {}
    const sectorCounts = {}

    Object.values(latestSectorLap).forEach(carLaps => {
        carLaps.forEach(({SECTOR_NUMBER, TIME, PIT}) => {
            if (PIT === "false") {
                if (!sectorTimes[SECTOR_NUMBER]) {
                    sectorTimes[SECTOR_NUMBER] = 0
                    sectorCounts[SECTOR_NUMBER] = 0
                }
                sectorTimes[SECTOR_NUMBER] += parseFloat(TIME)
                sectorCounts[SECTOR_NUMBER] += 1
            }
        })
    })

    const averageTimes = {}
    Object.keys(sectorTimes).forEach(sector => {
        averageTimes[sector] = sectorTimes[sector] / sectorCounts[sector]
    })

    return averageTimes
}