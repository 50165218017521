import  { useTranslation } from 'react-i18next'

const ResultSensitivity = ({ result }) => {
    const { t } = useTranslation(['core'])

    return (
        <h1>{t('resultSensitivity.sensitivityCheck')}</h1>
    )
}

export default ResultSensitivity