import React, { useEffect, useState } from 'react'
import Header from '../components/Header'

const TrackTemperature = () => {
    
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [temperature1, setTemperature1] = useState('')
    const [temperature2, setTemperature2] = useState('')
    const [temperature3, setTemperature3] = useState('')
    const [temperature4, setTemperature4] = useState('')
    const [showHelp, setShowHelp] = useState(false)
    const [allMeasuredTrackTemperatures, setAllMeasuredTrackTemperatures] = useState([])
    const [showAllRecords, setShowAllRecords] = useState(false)
    const [reverseOrder, setReverseOrder] = useState(false)

    const getLatestMesuredTrackTemperature = () => {
        fetch('/api/measuredTrackTemperature')
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    setLatitude(json.data.LATITUDE)
                    setLongitude(json.data.LONGITUDE)
                }
            })            
            .catch((error) => {
                console.error(error)
            })
    }

    const getAllMesuredTrackTemperatures = () => {
        fetch('/api/measuredTrackTemperature/all')
            .then(response => response.json())
            .then(json => {
                if (json.data) {
                    setAllMeasuredTrackTemperatures(json.data)
                }
            })            
            .catch((error) => {
                console.error(error)
            })
    }
    
    const createMesuredTrackTemperature = () => {
        const measuredTrackTemperatureData = {
            LATITUDE: latitude || null,
            LONGITUDE: longitude || null,
            TEMPERATURE_1: temperature1 || null,
            TEMPERATURE_2: temperature2 || null,
            TEMPERATURE_3: temperature3 || null,
            TEMPERATURE_4: temperature4 || null,
        }

        fetch('/api/measuredTrackTemperature', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(measuredTrackTemperatureData)
        })
            .then(response => response.json())
            .then(json => {
                if (json.status !== 200) {
                    alert('システム管理者に連絡してください')
                    return
                }
                alert('追加しました')

                if (showAllRecords) getAllMesuredTrackTemperatures()

                setTemperature1('')
                setTemperature2('')
                setTemperature3('')
                setTemperature4('')
            })
            .catch((error) => {
                console.error(error)                    
                alert('システム管理者に連絡してください')
            })
    }

    useEffect(() => {
        getLatestMesuredTrackTemperature()
    }, [])

    useEffect(() => {
        if (reverseOrder) {
            setAllMeasuredTrackTemperatures(prevTemperatures => [...prevTemperatures].reverse());
        }
    }, [reverseOrder])

    return (
        <div style={{ margin: '0 20px', paddingBottom: '10px' }}>
            <Header/>
            <div style={{ color: 'white', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    <button onClick={() => setShowHelp(!showHelp)} style={{ width: "200px", height: "30px", borderRadius: '4px', border: 'none', backgroundColor: '#A0A0A0', color: 'black', cursor: 'pointer'}}>
                        { showHelp ? '非表示' : '入力上の注意点' }
                    </button>
                </div>
                {showHelp && (
                    <div>
                        <li style={{fontSize: '16px'}}>数字は表示優先度です</li>
                        <li style={{fontSize: '16px'}}>温度1が未入力で記録した場合は温度2が路面温度として採用されます</li>
                        <li style={{fontSize: '16px'}}>データの混同を避けるために測定方法とカラム名は統一してください</li>
                        <li style={{fontSize: '16px'}}>例:鉢植え×アスファルトは常に温度2に入れる</li>
                        <li style={{fontSize: '16px'}}>補正や表示の順番の入れ替えは可能なのでご相談ください</li>
                    </div>
                )}
                <form onSubmit={(e) => { e.preventDefault(); createMesuredTrackTemperature(); }} style={{ display: 'flex', flexDirection: 'column', gap: '10px', maxWidth: '400px', margin: 'auto' }}>
                    <div>
                        <label style={{fontSize: '20px'}}>緯度（前回の値がデフォルト）:</label>
                        <input type="text" value={latitude} onChange={(e) => setLatitude(e.target.value)} style={{ color: 'white', width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                    </div>
                    <div>
                        <label style={{fontSize: '20px'}}>経度（前回の値がデフォルト）:</label>
                        <input type="text" value={longitude} onChange={(e) => setLongitude(e.target.value)} style={{ color: 'white', width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                    </div>
                    <div>
                        <label style={{fontSize: '20px'}}>温度1:</label>
                        <input type="text" value={temperature1} onChange={(e) => setTemperature1(e.target.value)} style={{ color: 'white', width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                    </div>
                    <div>
                        <label style={{fontSize: '20px'}}>温度2:</label>
                        <input type="text" value={temperature2} onChange={(e) => setTemperature2(e.target.value)} style={{ color: 'white', width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                    </div>
                    <div>
                        <label style={{fontSize: '20px'}}>温度3:</label>
                        <input type="text" value={temperature3} onChange={(e) => setTemperature3(e.target.value)} style={{ color: 'white', width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                    </div>
                    <div>
                        <label style={{fontSize: '20px'}}>温度4:</label>
                        <input type="text" value={temperature4} onChange={(e) => setTemperature4(e.target.value)} style={{ color: 'white', width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                    </div>
                    <button type="submit" style={{ padding: '10px', borderRadius: '4px', border: 'none', backgroundColor: '#4AFFFF', color: 'black', cursor: 'pointer', alignSelf: 'center' }}>
                        送信
                    </button>
                </form>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button onClick={() => {
                    if (showAllRecords) {
                        setShowAllRecords(false);
                    } else {
                        getAllMesuredTrackTemperatures();
                        setShowAllRecords(true);
                    }
                }} style={{ width: "200px", height: "30px", borderRadius: '4px', border: 'none', backgroundColor: '#A0A0A0', color: 'black', cursor: 'pointer', margin: '20px 10px 0 0' }}>{showAllRecords ? '全レコードを非表示' : '全レコードを表示'}</button>
                    <button onClick={() => setReverseOrder(!reverseOrder)} style={{ width: "200px", height: "30px", borderRadius: '4px', border: 'none', backgroundColor: '#A0A0A0', color: 'black', cursor: 'pointer', margin: '20px 0 0 10px' }}>逆順表示</button>
                </div>
                {showAllRecords && (
                    <div style={{ overflowX: 'auto' }}>
                        <table style={{ fontSize:'16px', marginTop: '20px', width: '100%', borderCollapse: 'collapse', backgroundColor: '#f0f0f0', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
                            <thead>
                                <tr style={{ backgroundColor: '#4AFFFF', color: 'black' }}>
                                    <th style={{ padding: '10px', border: '1px solid #ccc' }}>ID</th>
                                    <th style={{ padding: '10px', border: '1px solid #ccc' }}>測定日時</th>
                                    <th style={{ padding: '10px', border: '1px solid #ccc' }}>緯度</th>
                                    <th style={{ padding: '10px', border: '1px solid #ccc' }}>経度</th>
                                    <th style={{ padding: '10px', border: '1px solid #ccc' }}>温度1</th>
                                    <th style={{ padding: '10px', border: '1px solid #ccc' }}>温度2</th>
                                    <th style={{ padding: '10px', border: '1px solid #ccc' }}>温度3</th>
                                    <th style={{ padding: '10px', border: '1px solid #ccc' }}>温度4</th>
                                </tr>
                            </thead>
                            <tbody>
                            {allMeasuredTrackTemperatures.slice().reverse().map((record, index) => (
                                <tr key={record.ID} style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff', color: 'black' }}>
                                    <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>{record.ID}</td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>{record.MEASURED_AT ? new Date(record.MEASURED_AT).toLocaleString() : "now"}</td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>{record.LATITUDE}</td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>{record.LONGITUDE}</td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>{record.TEMPERATURE_1}</td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>{record.TEMPERATURE_2}</td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>{record.TEMPERATURE_3}</td>
                                    <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>{record.TEMPERATURE_4}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TrackTemperature