import { useState, useEffect } from 'react'

import { Line } from 'react-chartjs-2'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'

import { useLiveData } from '../../DataContext'

import upImage from '../image/scale-bar-arrow.png'
import mapImage from '../image/map-container.png'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import DropdownSelectBox from './DropdownSelectBox'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

// Set chart color
const plotColor = [
    '#7289DA', '#FFFFFF', '#E9967A', '#F08080', '#FFC0CB',
    '#FF69B4', '#FF1493','#FF6347', '#FFD700', '#FFFACD',
    '#FFE4B5','#BDB76B', '#EE82EE', '#BA55D3', '#4B0082', 
    '#6A5ACD','#7FFF00', '#2E8B57', '#00FFFF', '#4682B4',
    '#800000','#008080', '#191970', '#D2691E'
]

const gridColor = '#5A5A5A'
const labelColor = 'white'

const TimeComparisonChart = (props) => {
    const [options, setOptions] = useState({})
    const [chartData, setChartData] = useState({
        labels: [],
        datasets:  []
    })

    const [ xMin, setXMin ] = useState(0)
    const [ xMax, setXMax ] = useState(30)

    const [xSlideMax, setXSlideMax] = useState(100)
    const [xSlideMin, setXSlideMin] = useState(0)

    const [yMin, setYMin] = useState(-30)
    const [yMax, setYMax] = useState(30)

    const [slideMax, setSlideMax] = useState(100)
    const [slideMin, setSlideMin] = useState(-100)
    const [carNumberColor, setCarNumberColor] = useState(null);
    const [visibleLines, setVisibleLines] = useState({})
    
    const [followCar, setFollowCar] = useState('')

    const { lapData,raceDetailId, historyGap } = useLiveData()
    const [carNumbers, setCarNumbers] = useState([])
    const lapDatas = lapData

    const handleXChange = (event, newValue) => {
        setXMin(newValue[0])
        setXMax(newValue[1])
    }

    const handleChange = (event, newValue) => {
        setYMin(newValue[0])
        setYMax(newValue[1])
    }

    const handleSelectValueChange = (value) => {
        props.setFollowCarNumber(String(value))
        setFollowCar(value)
    }

    const handleLegendItemClick = (label) => {
        setVisibleLines(prevState => ({
            ...prevState,
            [label]: !prevState[label]
        }))
    }


    useEffect(() =>{
        getCarNumberColor()
    },[])

    
    useEffect(() => {
        if (chartData && chartData.datasets) {
            const initialVisibleLines = {}
            chartData.datasets.forEach(data => {
                initialVisibleLines[data.label] = true
            })
            setVisibleLines(initialVisibleLines)
        }
    }, [chartData])

    useEffect(() =>{
        if(lapDatas&&lapDatas.length!==0){
            setCarNumbers(Array.from(new Set(lapDatas.map(lapData => lapData.CAR_NUMBER))).sort((a, b) => a - b))
        }
    }, [lapDatas])
    
    useEffect(() => {
        createChart()
    }, [historyGap])

    useEffect(() => {
        setOptions(prevOptions => ({
            ...prevOptions,
            scales: {
                ...prevOptions.scales,
                x: {
                    ...(prevOptions.scales?.x || {}),
                    min: Math.trunc(xMin),
                    max: Math.trunc(xMax)
                }
            }
        }))
    }, [xMin, xMax])

    useEffect(() => {
        setOptions(prevOptions => ({
            ...prevOptions,
            scales: {
                ...prevOptions.scales,
                y: {
                    ...(prevOptions.scales?.y || {}),
                    min: Math.trunc(yMin), 
                    max: Math.trunc(yMax) 
                }
            }
        }))
    }, [yMin, yMax])


    var hiddenValue = localStorage.getItem('hiddenValue') ? JSON.parse(localStorage.getItem('hiddenValue')) : []

    const getCarNumberColor = () => {
        fetch(`/api/car/getCarNumberColor/${raceDetailId}`)
            .then((response) => response.json())
            .then((json) => {
            if (json.status === 200) {
                setCarNumberColor(json.data);
            }
        })
        .catch((error) => {
            alert('システム管理者に連絡してください');
        })
    }
    
    
    const createChart = () => {

        const maxLap = Object.keys(historyGap).length ? Math.max(...Object.keys(historyGap).map(carNumber => historyGap[carNumber].length)) : 0
        const labels = Array.from(Array(maxLap + 2), (_, index) => index + 1)
        let storeDatasets = []
        
        let colorCnt = 0

        lapDatas.sort((a, b) => a.CAR_NUMBER - b.CAR_NUMBER || a.LAP_NUMBER - b.LAP_NUMBER)
        for (const [carNumber, data] of Object.entries(historyGap)) {
            const drivers = [...new Set(data.map(car => car.driverName))]
            const legendLabel = `${carNumber} ${drivers.join('\n')}`
            const findCar = storeDatasets.find(el => el.label === legendLabel)
            if(!findCar){
                // 線の色の設定、#DC143C crimson red.

                // TODO: ゼッケンカラーの設定、S耐だと全部白になっていて、見にくいかも。。。
                // const carData = tableData.find(car => car.carno.split('.')[1] === carNumber)
                // const bgColor = carData ? carData.teamBgColor : plotColor[colorCnt]

                // const bgColor = carNumber.split(' ')[0] === '14' ? '#DC143C' : plotColor[colorCnt]
                const bgColor = plotColor[colorCnt]
                // 線の太さ
                const lineThickness =  1

                const setHiddenLine = hiddenValue.includes(carNumber.split(' '))

                storeDatasets.push({
                    label: legendLabel,
                    data: data,
                    borderColor: bgColor,
                    backgroundColor: bgColor,
                    hidden: setHiddenLine,
                    borderWidth: lineThickness,
                    // color: carData ? carData.teamTextColor : plotColor[colorCnt],　// TODO: ここもゼッケンカラー
                })

                colorCnt = colorCnt === plotColor.length -1 ? 0 : colorCnt + 1
            }
        }

        let maxX = -Infinity
        let maxY = -Infinity
        let minY = Infinity

        storeDatasets.forEach((dataset) => {
            dataset.data.forEach((data) => {
                if (data.x > maxX) maxX = data.x
                if (data.y > maxY) maxY = data.y
                if (data.y < minY) minY = data.y
            })
        })

        setYMax(Math.ceil(maxY))
        setYMin(Math.floor(minY))
        setXMax(Math.ceil(maxX))

        setSlideMax(Math.ceil(maxY) + 10)
        setSlideMin(Math.floor(minY) - 10)
        setXSlideMax(Math.ceil(maxX))

        setOptions({
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    display: true,
                    position: 'bottom',
                    title: {
                        display: true,
                        color: labelColor,
                    },
                    ticks: {
                        color: labelColor
                    },
                    grid: {
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: true,
                        color: gridColor
                    },
                    min: xMin,
                    max: xMax
                },
                y: {
                    type: 'linear',
                    display: true,
                    title: {
                        display: true,
                        color: labelColor,
                    },
                    ticks: {
                        color: labelColor,
                    },
                    grid: {
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: true,
                        color: gridColor
                    },
                    min: minY,
                    max: maxY
                }
            },
            plugins: {
                legend: {
                    // TODO: ゼッケンカラー用
                    display: false,
                    position: 'left',
                    onClick: handleLegendClick,
                    backgroundColor: 'rgba(255, 255, 255, 0.8)', // 背景色を設定
                    
                    labels: {
                        color: labelColor,
                        padding: 10,
                        
                        font: {
                            size: 16
                        },
                        usePointStyle: true,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)' // 背景色を設定
                    },
                }
            },
        })

        setChartData({
            labels,
            datasets: storeDatasets
        })
    }

    const handleLegendClick = (event, legendItem, legend) => {
        // TODO: ゼッケンカラー用　ー　legend.chartをchartにするだけ
        const ci = legend.chart
        legend.chart.data.datasets.forEach((d, i) => {
            if (d.label === legendItem.text) {
                if (hiddenValue.includes(legendItem.text)) {
                    hiddenValue = hiddenValue.filter(el => el !== legendItem.text)
                    ci.show(i)
                    d.hidden = false
                } else {
                    hiddenValue.push(legendItem.text)
                    ci.hide(i)
                    d.hidden = true
                }
            } 
        })
        localStorage.setItem('hiddenValue', JSON.stringify(hiddenValue))
    }

    const onClickMaxUp = (e) => {
        const isX = e.target.className.charAt(0) === 'x'

        if (isX) {
            setXSlideMax(xSlideMax+10)
        } else {
            setSlideMax(slideMax+10)
        }
    }
    
    const onClickMaxDown = (e) => {
        const isX = e.target.className.charAt(0) === 'x'

        if (isX) {
            setXSlideMax(xSlideMax-10)
            if (xMax > xSlideMax-10) {
                setXMax(xSlideMax-10)
            }
        } else {
            setSlideMax(slideMax-10)
            if (yMax > slideMax-10) {
                setYMax(slideMax-10)
            }
        }
    }
    
    const onClickMinUp = (e) => {
        const isX = e.target.className.charAt(0) === 'x'

        if (isX) {
            setXSlideMin(xSlideMin+10)
            if (xMin < xSlideMin+10) {
                setXMin(xSlideMin+10)
            }
        } else {
            setSlideMin(slideMin+10)
            if (yMin < slideMin+10) {
                setYMin(slideMin+10)
            }
        }
    }
    
    const onClickMinDown = (e) => {
        const isX = e.target.className.charAt(0) === 'x'

        if (isX) {
            if (xSlideMin === 0) return
            setXSlideMin(xSlideMin-10)
        } else {
            setSlideMin(slideMin-10)
        }
    }

    const chartDataFiltered = {
        ...chartData,
        datasets: chartData.datasets.filter(data => visibleLines[data.label])
    }

    const chartLegend = (chartData) => {
        if(chartData?.datasets.length === 0) return
        return (
            <div className='legend-container'>
                <div className='player-boxs'>
                    <ul className='player-list'>
                        {carNumberColor && chartData.datasets.map((data, index) => (
                            <li key={index} 
                                onClick={() => handleLegendItemClick(data.label)} 
                                className={visibleLines[data.label] ? '' : 'legend-hidden'}
                                style={{width:'100%',border: followCar === data.label.split(' ')[0] ? 'solid 3px #BC1414' : ''}}
                            >
                                <div className='timeComparison-legend-circles'
                                    style={{backgroundColor: plotColor[index % 24]}}
                                >
                                </div>
                                <div className='driver-color' 
                                    style={{backgroundColor: carNumberColor[Number(data.label.split(' ')[0])] ? carNumberColor[Number(data.label.split(' ')[0])]['COLOR_BG'] : '',
                                            color: carNumberColor[Number(data.label.split(' ')[0])] ? carNumberColor[Number(data.label.split(' ')[0])]['COLOR_TEXT'] :  `#242424 !important`
                                    }}
                                >
                                    {[Number(data.label.split(' ')[0])]}
                                </div>
                                {data.label.split(' ')[1]}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className='timeComparison-chart-container'>
                <DropdownSelectBox
                    label="following CarNumber"
                    values={carNumbers.map(carNumber => ({ id: carNumber, name: `${carNumber}` }))}
                    handleSelectValueChange={handleSelectValueChange}
                    id='test'
                />
            
                <div className='timeComparison-flex-container'>
                    <img src={mapImage} className='map-background' alt='map背景'/>
                    <Line id='time-chart' options={options} data={chartDataFiltered}></Line>
                    {chartLegend(chartData)}
                    <Stack sx={{ height: '90%' }} spacing={1}>
                    <span className='stack-text'>
                        Gap
                        <ul className='stack-text-dec'>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </span>
                        <div className='flex updown'>
                            <img className='y-max-up' src={upImage} onClick={onClickMaxUp} alt=''/>
                            <img className='y-max-down' src={upImage} onClick={onClickMaxDown} style={{transform: 'rotate(180deg)'}} alt=''/>
                        </div>
                        <Slider
                            max={slideMax}
                            min={slideMin}
                            orientation='vertical'
                            value={[yMin, yMax]}
                            onChange={handleChange}
                            valueLabelDisplay='auto'
                        />
                        <div className='flex updown'>
                            <img className='y-min-up' src={upImage} onClick={onClickMinUp} alt=''/>
                            <img className='y-min-down' src={upImage} onClick={onClickMinDown} style={{transform: 'rotate(180deg)'}} alt=''/>
                        </div>
                    </Stack>
                    <Stack className='stack-slider-flex'spacing={1}>
                <span className='stack-text'>
                    Lap
                    <ul className='stack-text-dec'>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </span>
                    <div className='flex updown landscape'>
                        <img className='x-min-up' src={upImage} onClick={onClickMinUp} style={{transform: 'rotate(90deg)'}} alt=''/>
                        <img className='x-min-down' src={upImage} onClick={onClickMinDown} style={{transform: 'rotate(270deg)'}} alt=''/>
                    </div>
                    <Slider
                        max={xSlideMax}
                        min={xSlideMin}
                        value={[xMin, xMax]}
                        onChange={handleXChange}
                        orientation='horizontal'
                        valueLabelDisplay='auto'
                        className='chart-slider'
                    />
                    <div className='flex updown landscape'>
                        <img className='x-max-up' src={upImage} onClick={onClickMaxUp} style={{transform: 'rotate(90deg)'}} alt=''/>
                        <img className='x-max-down' src={upImage} onClick={onClickMaxDown} style={{transform: 'rotate(270deg)'}} alt=''/>
                    </div>
                </Stack>


                </div>
            </div>
            
        </>
    )
}

export default TimeComparisonChart