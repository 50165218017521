import React, { useState, useEffect } from 'react'

import SetupUserInputSummary from './SetupUserInput_Summary'
import SetupUserInputAlignment from './SetupUserInput_Alignment'
import SetupUserInputChassis from './SetupUserInput_Chassis'
import SetupUserInputAero from './SetupUserInput_Aero'
import SetupUserInputTableEdit from './SetupUserInput_TableEdit'

const SetupUserInput = (props) => {
    const [ selectedTab, setSelectedTab ] = useState('summary')
    const [ isEditMode, setIsEditMode ] = useState(false)
    const [ dataToEdit, setDataToEdit ] = useState()
    const [ editedData, setEditedData ] = useState()

    const [ elementKeyMap, setElementKeyMap ] = useState({})
    const [ selectedOptions, setSelectedOptions ] = useState()

    const df_baseSetup = props.df_baseSetup
    const df_showSetup = props.df_showSetup
    const df_scatInput = props.df_scatInput
    const Obj_parameter_combination = props.Obj_parameter_combination
    const setSelectedIndex = props.setSelectedIndex

    const alignmentData = props.alignmentData
    const setAlignmentData = props.setAlignmentData
    const chassisData = props.chassisData
    const setChassisData = props.setChassisData
    const aeroData = props.aeroData
    const setAeroData = props.setAeroData

    const setupOptions = props.setupOptions
    const setSetup = props.setSetup

    const isShowBase = props.isShowBase
    const isShowRecommend = props.isShowRecommend
    const setIsShowBase = props.setIsShowBase
    const setIsShowRecommend = props.setIsShowRecommend

    useEffect(()=> {
        const getElementKeyMap = async () => {
            const elementKeyMapping = {
                "alignment": {
                    "fl": [
                        { "displayName": "rh", "indexName": "RideHeight_Fr [mm]" },
                        { "displayName": "camber", "indexName": "Camber_Fr [deg]" },
                        { "displayName": "toe", "indexName": "Toe_Fr [']" }
                    ],
                    "fr": [
                        { "displayName": "rh", "indexName": "RideHeight_Fr [mm]" },
                        { "displayName": "camber", "indexName": "Camber_Fr [deg]" },
                        { "displayName": "toe", "indexName": "Toe_Fr [']" }
                    ],
                    "rl": [
                        { "displayName": "rh", "indexName": "RideHeight_Rr [mm]" },
                        { "displayName": "camber", "indexName": "Camber_Rr [deg]" },
                        { "displayName": "toe", "indexName": "Toe_Rr [']" }
                    ],
                    "rr": [
                        { "displayName": "rh", "indexName": "RideHeight_Rr [mm]" },
                        { "displayName": "camber", "indexName": "Camber_Rr [deg]" },
                        { "displayName": "toe", "indexName": "Toe_Rr [']" }
                    ]
                },
                "chassis": {
                    "front": [
                        { "displayName": "spring", "indexName": "Spring_Fr [kgf/mm]" },
                        { "displayName": "damper", "indexName": "" },
                        { "displayName": "arb", "indexName": "ARB_Fr [N/mm]" }
                    ],
                    "rear": [
                        { "displayName": "spring", "indexName": "Spring_Rr [kgf/mm]" },
                        { "displayName": "damper", "indexName": "" },
                        { "displayName": "arb", "indexName": "ARB_Rr [N/mm]" }
                    ]
                },
                "aero": {
                    "rear": [
                        { "displayName": "wing", "indexName": "Wing_Rr [deg]" }
                    ]
                }
            }
            setElementKeyMap(elementKeyMapping)
        }

        const getSelectedOptions = async () => {
            setSelectedOptions(setupOptions)
        }

        getElementKeyMap()
        getSelectedOptions()
    }, [props.setupOptions])

    useEffect(() => {
        if (!elementKeyMap || !df_showSetup) return

        const setBaseSelectedRecommend = (category, position, indexName) => {
            const returnValue = { base: '', selected: '', recommend: '' }

            const indexOf = df_showSetup.index.indexOf(indexName)

            if (indexOf === -1) return returnValue

            let existingData
            if (category === 'alignment') {
                existingData = alignmentData
            } else if (category === 'chassis') {
                existingData = chassisData
            } else if (category === 'aero') {
                existingData = aeroData
            }

            let baseValue, recommendValue, selectedValue

            if (isShowBase || isShowRecommend) {
                baseValue = df_showSetup.loc({ columns: ['Base'] }).values[indexOf][0]
                recommendValue = df_showSetup.loc({ columns: ['Recommend'] }).values[indexOf][0]
                selectedValue = isShowBase ? baseValue : recommendValue
            } else if (existingData) {
                existingData = existingData[position].find(element => element.indexName === indexName)
                baseValue = existingData.values.base
                recommendValue = existingData.values.recommend
                selectedValue = existingData.values.selected
            } else {
                baseValue = df_showSetup.loc({ columns: ['Base'] }).values[indexOf][0]
                recommendValue = df_showSetup.loc({ columns: ['Recommend'] }).values[indexOf][0]
                selectedValue = recommendValue
            }

            returnValue.base = baseValue
            returnValue.selected = selectedValue
            returnValue.recommend = recommendValue

            return returnValue
        }

        for (const [category, positions] of Object.entries(elementKeyMap)) {
            const childrenPositions = {}

            for (const [position, elements] of Object.entries(positions)) {
                const childrenElements = []

                elements.forEach(element => {
                    const dfIndex = df_baseSetup.index.indexOf(element.indexName)

                    childrenElements.push({ 
                        elementName: element.displayName,
                        values: setBaseSelectedRecommend(category, position, element.indexName),
                        indexName: element.indexName,
                        options: selectedOptions[element.indexName] || false,
                        constrain: dfIndex === -1 ? '-' : df_baseSetup.iloc({ rows: [dfIndex], columns: [0] }).values[0][0]
                    })
                })

                childrenPositions[position] = childrenElements
            }

            if (category === 'alignment') {
                setAlignmentData(childrenPositions)
                if (isShowBase || isShowRecommend) {
                    setEditedData({
                        'type': 'alignment',
                        'title': 'FR',
                        'indexName': 'RideHeight_Fr [mm]',
                        'newValue': childrenPositions.fr[0].values[isShowBase ? 'base' : 'recommend'],
                        'newData': childrenPositions.fr
                    })
                }
            } else if (category === 'chassis') {
                setChassisData(childrenPositions)
            } else if (category === 'aero') {
                setAeroData(childrenPositions)
            }
        }
    }, [elementKeyMap, df_showSetup, selectedOptions, isShowBase, isShowRecommend])

    useEffect(() => {
        if (!editedData || !editedData.indexName) return

        const { title, type, indexName, newValue, newData } = editedData

        const titleLowerCase = title.toLowerCase()

        if (type === 'alignment') {
            const sameIndexName = titleLowerCase === 'fr' ? 'fl' : 'rl'
            setAlignmentData({ ...alignmentData, [titleLowerCase]: newData, [sameIndexName]: newData})
        } else if (type === 'chassis') {
            setChassisData({ ...chassisData, [titleLowerCase]: newData })
        } else if (type === 'aero') {
            setAeroData({ ...aeroData, [titleLowerCase]: newData })
        }

        const indexColumn = df_scatInput.columns.indexOf(indexName)
        const indexValues = df_scatInput.iloc({ columns: [indexColumn] }).values

        const newListAns = findNearestValue(indexValues, newValue, indexName)
        if (newListAns === -1) return
        setSelectedIndex(isShowBase ? [-1] : [newListAns])
        setIsShowBase(false)
        setIsShowRecommend(false)
    }, [editedData])

    const findNearestValue = (arrayToCheck, valueToCompare, indexName) => {
        const getValue = (name) => {
            const availableValues = Obj_parameter_combination[indexName]

            if (!availableValues) return null

            const sameName = availableValues.find(row => row.name.includes(name))
            return sameName ? sameName.value : null
        }

        const flatArray = arrayToCheck.flat()

        const selectedValue = isNaN(valueToCompare) ? getValue(valueToCompare) : Number(valueToCompare)
        let nearestIndex = 0
        if (selectedValue) {
            let nearestNumber = flatArray[0]
            let nearestDifference = Math.abs(nearestNumber - selectedValue)

            flatArray.forEach((value, index) => {
                let currentDifference = Math.abs(value - selectedValue)
                if (currentDifference < nearestDifference) {
                    nearestDifference = currentDifference
                    nearestNumber = value
                    nearestIndex = index
                }
            })
        } else {
            nearestIndex = flatArray.indexOf(valueToCompare)
        }

        return nearestIndex
    }

    const tabChangeHandler = (e) => {
        document.querySelector('.selected-tab').classList.remove('selected-tab')
        e.target.classList.add('selected-tab')
        setSelectedTab(e.target.value)
    }

    const handleCloseEditMode = (editedData) => {
        setIsEditMode(false)
        setEditedData(editedData)
    }

    return (
        alignmentData && chassisData && aeroData && (
            <>
                <div className='button-container flex'>
                    <button className='selected-tab' value='summary' onClick={ tabChangeHandler }>Summary</button>
                    <button value='alignment' onClick={ tabChangeHandler }>Alignment</button>
                    <button value='chassis' onClick={ tabChangeHandler }>Chassis</button>
                    <button value='aero' onClick={ tabChangeHandler }>Aero</button>
                </div>
                <div className='user-input-container'>
                    { selectedTab === 'summary' &&
                        <SetupUserInputSummary
                            alignmentData={ alignmentData }
                            chassisData={ chassisData }
                            aeroData={ aeroData }
                            setIsEditMode={ setIsEditMode }
                            setDataToEdit={ setDataToEdit }
                            setEditedData={ setEditedData }
                            setSetup={ setSetup }
                            isShowBase={ isShowBase }
                            isShowRecommend={ isShowRecommend }
                        />
                    }
                    { selectedTab === 'alignment' &&
                        <SetupUserInputAlignment
                            alignmentData={ alignmentData }
                            setIsEditMode={ setIsEditMode }
                            setDataToEdit={ setDataToEdit }
                            setEditedData={ setEditedData }
                            setSetup={ setSetup }
                        />
                    }
                    { selectedTab === 'chassis' &&
                        <SetupUserInputChassis
                            chassisData={ chassisData }
                            setIsEditMode={ setIsEditMode }
                            setDataToEdit={ setDataToEdit }
                            setEditedData={ setEditedData }
                            setSetup={ setSetup }
                        />
                    }
                    { selectedTab === 'aero' &&
                        <SetupUserInputAero
                            aeroData={ aeroData }
                            setIsEditMode={ setIsEditMode }
                            setDataToEdit={ setDataToEdit }
                            setEditedData={ setEditedData }
                            setSetup={ setSetup }
                        />
                    }
                </div>
                {/* <div className='button-container flex'>
                    <button className='selected-tab' value='summary' onClick={ tabChangeHandler }>Summary</button>
                    <button value='alignment' onClick={ tabChangeHandler }>Alignment</button>
                    <button value='chassis' onClick={ tabChangeHandler }>Chassis</button>
                    <button value='aero' onClick={ tabChangeHandler }>Aero</button>
                </div> */}
                <SetupUserInputTableEdit
                    open={ isEditMode }
                    setIsEditMode={ setIsEditMode }
                    onClose= { handleCloseEditMode }
                    type={ selectedTab }
                    dataToEdit={ dataToEdit }
                    setEditedData={ setEditedData }
                    setSetup={ setSetup }
                />
            </>
        )
    )
}

export default SetupUserInput