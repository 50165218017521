const SimulationEstimatedTyreDegradation = ({ estimatedTyreDegradation }) => {

    return (
        <>
            <table>
                <thead>
                    <tr>
                        <td colSpan={3}>Estimated Tyre Degradation</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Soft</td>
                        <td>{estimatedTyreDegradation.tyreDegSoft}</td>
                        <td>[sec/Lap]</td>
                    </tr>
                    <tr>
                        <td>Medium</td>
                        <td>{estimatedTyreDegradation.tyreDegMedium}</td>
                        <td>[sec/Lap]</td>
                    </tr>
                    <tr>
                        <td className='colorBlack' colSpan={3}>-</td>
                    </tr>
                    <tr>
                        <td>Fuel Effect</td>
                        <td>{estimatedTyreDegradation.fuelEffect}</td>
                        <td>[sec/10kg]</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default SimulationEstimatedTyreDegradation