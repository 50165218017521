import { useTranslation } from 'react-i18next'

const DatabaseUpdateVehiclesKeysOrganism = (props) => {
    const { t } = useTranslation(['strategy'])

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div className='form-input from-select'>
                    <div className='form-text'>{t('editVehicleDatabase.vehicleSelection')}:</div>
                    <select name='vehicle' value={props.selectedVehicleId || ''} onChange={event => props.handleVehicleSelect(event.target.value)} style={{ fontSize: '20px' }}>
                        <option key={props.vehicleList.length + 1} value='notSelected'>{t('editVehicleDatabase.pleaseSelectVehicle')}</option>
                        {props.vehicleList.map((d, index) => (
                        <option key={index} value={d.ID} >{d.NAME}  </option>))
                        }
                        <option key={props.vehicleList.length + 2} value='addVehicle'>{t('editVehicleDatabase.addVehicle')}</option>
                    </select>
                </div>
                <div className='form-input from-select'>
                    <div className='form-text'>{t('editVehicleDatabase.circuitSelection')}:</div>
                    <select name='circuit' onChange={event => props.handleCircuitSelect(event.target.value)}  style={{ fontSize: '20px' }}>
                        <option key={props.circuitList.length + 1} value='notSelected'>{t('editVehicleDatabase.pleaseSelectCircuit')}</option>
                        {props.circuitList.map((d, index) => (
                        <option key={index} value={d.ID} >{d.NAME}  </option>))
                        }
                    </select>
                </div>
            </div>
        </>
    )
}

export default DatabaseUpdateVehiclesKeysOrganism