import { useState, useEffect } from 'react'
import Header from '../components/Header'
import { useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

const TirePressurePrediction = () => {
    const { t } = useTranslation(['strategy', 'general'])

    const { eventId } = useParams()
    const [vehicleList, setVehicleList] = useState([])
    const [vehicleId, setVehicleId] = useState(null)
    const [circuitId, setCircuitId] = useState(null)
    const [trackLength, setTrackLength] = useState(null)
    const [tireEnergyCoefficient, setTireEnergyCoefficient] = useState(null)
    const [d, setD] = useState()
    const [surfaceHeatTransferCoefficient, setSurfaceHeatTransferCoefficient] = useState(null)
    const [tireInnerHeatTransferCoefficient, setTireInnerHeatTransferCoefficient] = useState(null)
    const [brakeEnergyCoefficient, setBrakeEnergyCoefficient] = useState({
        frontLeft: null,
        frontRight: null,
        rearLeft: null,
        rearRight: null
    })
    const [vehicleEnergy, setVehicleEnergy] = useState({
        frontLeft: null,
        frontRight: null,
        rearLeft: null,
        rearRight: null
    })

    const [tireEnergy, setTireEnergy] = useState({
        frontLeft: null,
        frontRight: null,
        rearLeft: null,
        rearRight: null
    })
    const [brakeEnergy, setBrakeEnergy] = useState({
        frontLeft: null,
        frontRight: null,
        rearLeft: null,
        rearRight: null
    })

    const [atmosphereTemperature, setAtomosphereTemperature] = useState()
    const [roadSurfaceTemperature, setRoadSurfaceTemperature] = useState()
    const [atmospherePressure, setAtmospherePressure] = useState()
    const [outLap, setOutLap] = useState()
    const [warmUpLaps, setWarmUpLaps] = useState()
    const [attackLaps, setAttackLaps] = useState()
    const [targetPressure, setTargetPressure] = useState({
        frontLeft: null,
        frontRight: null,
        rearLeft: null,
        rearRight: null
    })
    const [predictedTemperature, setPredictedTemperature] = useState({
        frontLeft: null,
        frontRight: null,
        rearLeft: null,
        rearRight: null
    })
    const [initialSettingPressure, setInitialSettingPressure] = useState({
        frontLeft: null,
        frontRight: null,
        rearLeft: null,
        rearRight: null
    })
    //タイヤ内部へのエネルギー入力
    const [inputAllEnergy, setInputAllEnergy] = useState({
        frontLeft: null,
        frontRight: null,
        rearLeft: null,
        rearRight: null
    })

    //TargetPressureの指定されたキーの状態関数を更新する
    const handleTargetPressureChange = (key, value) => {
        setTargetPressure(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    //タイヤ内部のエネルギー入力Eallを算出する
    const inputAllEnergyIntoTire = (TIRE_ENERGY, BRAKE_ENEGY, M, B) => {
        return tireEnergyCoefficient * (d + Number(roadSurfaceTemperature)) * TIRE_ENERGY + B * BRAKE_ENEGY + M
    }

    const predictedTireTemperature = (inputAllEnegyIntoTire) => {
        return ((tireInnerHeatTransferCoefficient - 0.5 * surfaceHeatTransferCoefficient * trackLength * (Number(attackLaps) + 0.3 * (Number(outLap) + Number(warmUpLaps))))
            * Number(atmosphereTemperature) + trackLength * (Number(attackLaps) + 0.3 * (Number(outLap) + Number(warmUpLaps)))
            * (inputAllEnegyIntoTire + surfaceHeatTransferCoefficient * Number(roadSurfaceTemperature)))
            / (tireInnerHeatTransferCoefficient + 0.5 * surfaceHeatTransferCoefficient * trackLength * (Number(attackLaps) + 0.3 * (Number(outLap) + Number(warmUpLaps))))
    }

    //各タイヤごとの内圧調整値を算出
    const initialSettingTirePressure = (targetTirePressure, inputAllEnergyIntoTire) => {
        return (Number(targetTirePressure) + 0.1 * Number(atmospherePressure)) * (Number(atmosphereTemperature) + 273.15) / (predictedTireTemperature(inputAllEnergyIntoTire) + 273.15) - Number(atmospherePressure) * 0.1
    }

    const updateAdjustedTirePressure = () => {
        const positions = ['frontLeft', 'frontRight', 'rearLeft', 'rearRight']
        const newInputAllEnergy = {}
        const newPredictedTemperature = {}
        const newInitialSettingPressure = {}

        positions.forEach(position => {
            newInputAllEnergy[position] = inputAllEnergyIntoTire(tireEnergy[position], brakeEnergy[position], vehicleEnergy[position], brakeEnergyCoefficient[position])
            newPredictedTemperature[position] = predictedTireTemperature(newInputAllEnergy[position])
            newInitialSettingPressure[position] = initialSettingTirePressure(targetPressure[position], newInputAllEnergy[position])
        })

        setInputAllEnergy(prevState => ({ ...prevState, ...newInputAllEnergy }))
        setPredictedTemperature(prevState => ({ ...prevState, ...newPredictedTemperature }))
        setInitialSettingPressure(prevState => ({ ...prevState, ...newInitialSettingPressure }))
    }

    const getAllVehicles = () => {
        fetch(`/api/tirePressure/getAllVehicles`)
            .then(response => response.json())
            .then(json => {
                let data = json.data
                const list = data.map((d) => ([d.ID,d.NAME]))
                setVehicleList(data)
            })
    }

    const getVehicleEnergyInfo = (vehicleId) => {
        fetch(`/api/tirePressure/getVehicleEnergyInfoByVehicleId/${vehicleId}`)
            .then(response => response.json())
            .then(json => {
                setTireEnergyCoefficient(json.data.TIRE_ENERGY_COEFFICIENT)
                setD(json.data.D)
                setBrakeEnergyCoefficient({
                    frontLeft: json.data.BRAKE_FL_ENERGY_COEFFICIENT,
                    frontRight: json.data.BRAKE_FR_ENERGY_COEFFICIENT,
                    rearLeft: json.data.BRAKE_RL_ENERGY_COEFFICIENT,
                    rearRight: json.data.BRAKE_RR_ENERGY_COEFFICIENT
                })
                setVehicleEnergy({
                    frontLeft: json.data.VEHICLE_FL_ENERGY,
                    frontRight: json.data.VEHICLE_FR_ENERGY,
                    rearLeft: json.data.VEHICLE_RL_ENERGY,
                    rearRight: json.data.VEHICLE_RR_ENERGY
                })
            })
    }

    const getVehicleCircuitEnergyInfo = (vehicleId, circuitId) => {
        fetch(`/api/tirePressure/getVehicleCircuitEnergyInfoByVehicleIdCircuitId/${vehicleId}/${circuitId}`)
            .then(response => response.json())
            .then(json => {
                setTireInnerHeatTransferCoefficient(json.data.TIRE_INNER_HEAT_TRANSFER_COEFFICIENT)
                setSurfaceHeatTransferCoefficient(json.data.SURFACE_HEAT_TRANSFER_COEFFICIENT)
                setTireEnergy({
                    frontLeft: json.data.TIRE_FL_ENERGY,
                    frontRight: json.data.TIRE_FR_ENERGY,
                    rearLeft: json.data.TIRE_RL_ENERGY,
                    rearRight: json.data.TIRE_RR_ENERGY
                })
                setBrakeEnergy({
                    frontLeft: json.data.BRAKE_FL_ENERGY,
                    frontRight: json.data.BRAKE_FR_ENERGY,
                    rearLeft: json.data.BRAKE_RL_ENERGY,
                    rearRight: json.data.BRAKE_RR_ENERGY
                })
            })
    }

    const getEventWithCircuit = (eventId) => {
        fetch(`/api/event/getOneWithCircuit/${eventId}`)
        .then(response => response.json())
        .then(json => {
            setCircuitId(json.data.CIRCUIT_ID)
            setTrackLength(json.data.CIRCUIT.TRACK_LENGTH * 1000)
        })
    }

    useEffect(() => {
        getAllVehicles()
        getEventWithCircuit(eventId)
    }, [])

    useEffect(() => {
        if (!vehicleId || vehicleId === 'notSelected') { return }
        getVehicleEnergyInfo(vehicleId)
        getVehicleCircuitEnergyInfo(vehicleId, circuitId)
    }, [vehicleId])

    useEffect(() => { updateAdjustedTirePressure() }, [atmosphereTemperature, roadSurfaceTemperature, atmospherePressure, outLap, warmUpLaps, attackLaps, targetPressure])

    return (
        <>
            <Header />
            <div>
                <h1>SHORT RUN</h1>
                <h2>{`${t('tirePressure.preRaceTires')}`}</h2>
                <h3>{`${t('tirePressure.conditions')}`}</h3>
                <table border={3}>
                    <thead>
                        <tr>
                            <th rowSpan={2}>{`${t('tirePressure.carType')}`}</th>
                            <th rowSpan={2}>{`${t('tirePressure.temperature')}(℃)`}</th>
                            <th rowSpan={2}>{`${t('tirePressure.roadTemperature')}`}</th>
                            <th rowSpan={2}>{`${t('tirePressure.airPressure')}`}</th>
                            <th colSpan={3}>{`${t('tirePressure.lapCount')}`}</th>
                        </tr>
                        <tr>
                            <th>{`${t('tirePressure.outLap')}`}</th>
                            <th>{`${t('tirePressure.warmUp')}`}</th>
                            <th>{`${t('tirePressure.attack')}`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                <select name='vehicle' onChange={(event) => setVehicleId(event.target.value)}>
                                    <option value='notSelected' >{`${t('tirePressure.carSelection')}`}</option>
                                    {vehicleList.map((d, index) => (
                                        <option key={index} value={d.ID} >{d.NAME}  </option>))
                                    }
                                </select>
                            </th>
                            <th>
                                <input placeholder='℃' type='number' style={{ backgroundColor: 'yellow' }} value={atmosphereTemperature} onChange={(event) => setAtomosphereTemperature(event.target.value)} />
                            </th>
                            <th>
                                <input placeholder='℃' type='number' style={{ backgroundColor: 'yellow' }} value={roadSurfaceTemperature} onChange={(event) => setRoadSurfaceTemperature(event.target.value)} />
                            </th>
                            <th>
                                <input placeholder='hPa' type='number' style={{ backgroundColor: 'yellow' }} value={atmospherePressure} onChange={(event) => setAtmospherePressure(event.target.value)} />
                            </th>
                            <th>
                                <input placeholder={`${t('tirePressure.lap')}`} type='number' style={{ backgroundColor: 'yellow' }} value={outLap} onChange={(event) => setOutLap(event.target.value)} />
                            </th>
                            <th>
                                <input placeholder={`${t('tirePressure.lap')}`} type='number' style={{ backgroundColor: 'yellow' }} value={warmUpLaps} onChange={(event) => setWarmUpLaps(event.target.value)} />
                            </th>
                            <th>
                                <input placeholder={`${t('tirePressure.lap')}`} type='number' style={{ backgroundColor: 'yellow' }} value={attackLaps} onChange={(event) => setAttackLaps(event.target.value)} />
                            </th>
                        </tr>
                    </tbody>
                </table>

                <h3>{`${t('tirePressure.targetPressure')}`}</h3>
                <table border={3}>
                    <thead>
                    </thead>
                    <tbody>
                        <tr>
                            <th >{`FL${t('tirePressure.internalPressure')}(hPa)`}</th>
                            <th >
                                <input type='number' style={{ backgroundColor: 'yellow' }} value={targetPressure.frontLeft} onChange={(event) => handleTargetPressureChange('frontLeft', event.target.value)} />
                            </th>
                            <th>
                                <input type='number' style={{ backgroundColor: 'yellow' }} value={targetPressure.frontRight} onChange={(event) => handleTargetPressureChange('frontRight', event.target.value)} />
                            </th>
                            <th>{`FR${t('tirePressure.internalPressure')}(hPa)`}</th>
                        </tr>
                        <tr>
                            <th >{`RL${t('tirePressure.internalPressure')}(hPa)`}</th>
                            <th >
                                <input type='number' style={{ backgroundColor: 'yellow' }} value={targetPressure.rearLeft} onChange={(event) => handleTargetPressureChange('rearLeft', event.target.value)} />
                            </th>
                            <th>
                                <input type='number' style={{ backgroundColor: 'yellow' }} value={targetPressure.rearRight} onChange={(event) => handleTargetPressureChange('rearRight', event.target.value)} />
                            </th>
                            <th>{`RR${t('tirePressure.internalPressure')}(hPa)`}</th>
                        </tr>
                    </tbody>
                </table>

                <h3>{`${t('tirePressure.predictionTireTemperatures')}`}</h3>
                <table border={3}>
                    <thead>
                    </thead>
                    <tbody>
                        <tr>
                    <th>{`FL${t('tirePressure.internalTemperature')}(℃)`}</th>
                            <th>
                                <input type='number' value={predictedTemperature.frontLeft ? predictedTemperature.frontLeft.toFixed(1) : null} />
                            </th>
                            <th>
                                <input type='number' value={predictedTemperature.frontRight ? predictedTemperature.frontRight.toFixed(1) : null} />
                            </th>
                            <th>{`FR${t('tirePressure.internalTemperature')}(℃)`}</th>
                        </tr>
                        <tr>
                            <th>{`RL${t('tirePressure.internalTemperature')}(℃)`}</th>
                            <th>
                                <input type='number' value={predictedTemperature.rearLeft ? predictedTemperature.rearLeft.toFixed(1) : null} />
                            </th>
                            <th>
                                <input type='number' value={predictedTemperature.rearRight ? predictedTemperature.rearRight.toFixed(1) : null} />
                            </th>
                            <th>{`RR${t('tirePressure.internalTemperature')}(℃)`}</th>
                        </tr>
                    </tbody>
                </table>

                <h3>{`${t('tirePressure.initialTirePressureChanges')}`}</h3>
                <table border={3}>
                    <tbody>
                        <tr>
                            <th>{`FL${t('tirePressure.internalPressure')}(hPa)`}</th>
                            <th>
                                <input type='number' value={initialSettingPressure.frontLeft ? initialSettingPressure.frontLeft.toFixed(1) : null} />
                            </th>
                            <th>
                                <input type='number' value={initialSettingPressure.frontRight ? initialSettingPressure.frontRight.toFixed(1) : null} />
                            </th>
                            <th>{`FR${t('tirePressure.internalPressure')}(hPa)`}</th>
                        </tr>
                        <tr>
                            <th>{`RL${t('tirePressure.internalPressure')}(hPa)`}</th>
                            <th>
                                <input type='number' value={initialSettingPressure.rearLeft ? initialSettingPressure.rearLeft.toFixed(1) : null} />
                            </th>
                            <th>
                                <input type='number' value={initialSettingPressure.rearRight ? initialSettingPressure.rearRight.toFixed(1) : null} />
                            </th>
                            <th>{`RR${t('tirePressure.internalPressure')}(hPa)`}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default TirePressurePrediction