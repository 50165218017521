import { gsap } from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'

gsap.registerPlugin(MotionPathPlugin)

const MotionPathPitin = (trackId, sectorLength, lapSpeed, setCarMotion, gap, pitLossTime, followCarNumber, pitRoadExitTime, latestSectorLap, pitRoadExitProgressPos) => {
    // タイヤ発熱ロス&ニュータイヤによる加速
    // todo: wait for to store in db
    const tyreLostForFollowCar = {
        sec1: 1.3,
        sec2: 0.6,
        sec3: 0.1,
        sec4: -0.5,
        other: -0.5
    }

    let carMotionObj = {}

    Object.entries(latestSectorLap).forEach((carData) => {
        const carNumber = carData[0]
        const carSector = carData[1]

        if (carSector.length === 0 || carSector.length < sectorLength)  return

        carMotionObj[`${carNumber}`] = new gsap.timeline()

        // ピット後の見た目上のGAPは足すpit road loss(pitLossTime)
        const afterPitGap = gap[carNumber] + pitLossTime

        const startPointInfo = buildStartPointInfo(carSector, pitRoadExitTime, afterPitGap, sectorLength)

        if (startPointInfo) {
            let duration = startPointInfo.TIME
            let sectorNumberPath = startPointInfo.SECTOR_NUMBER
            let getPath = carSector.find(el => el.SECTOR_NUMBER === sectorNumberPath).PATH
            let progress = ( afterPitGap - startPointInfo.TOTAL_ACCUMULATE_TIME ) / startPointInfo.TIME

            if (followCarNumber === carNumber) {
                sectorNumberPath = 1
                const getSector1 = carSector.find(el => el.SECTOR_NUMBER === sectorNumberPath)
                duration = getSector1.TIME - pitRoadExitTime + tyreLostForFollowCar.sec1
                duration = duration > 0 ? duration : 0.1
                getPath = getSector1.PATH
                progress = pitRoadExitProgressPos
            }

            motionTimeline(carMotionObj[`${carNumber}`], carNumber, trackId, duration, getPath, lapSpeed)
            
            carMotionObj[`${carNumber}`].progress(progress)
            carMotionObj[`${carNumber}`].pause()

            for (let i=0; i<30; i++) {
                addSectorAnimation(carMotionObj[`${carNumber}`], carNumber, carSector, sectorNumberPath, sectorLength, trackId, lapSpeed, tyreLostForFollowCar, followCarNumber, i)
                sectorNumberPath++
                sectorNumberPath = sectorNumberPath % sectorLength === 0 ? sectorLength : sectorNumberPath % sectorLength
            }
        }

        setCarMotion(carMotionObj)
    })
}

export default MotionPathPitin

const buildStartPointInfo = (carSector, pitRoadExitTime, afterPitGapVal, sectorLength) => {
    let afterPitGap = afterPitGapVal

    if (typeof afterPitGap !== 'number') {
        afterPitGap = 0
    }

    const s1p = carSector.find(el => el.SECTOR_NUMBER === 1) ? carSector.find(el => el.SECTOR_NUMBER === 1).TIME - pitRoadExitTime : 0

    let totalAccumulateTime = s1p
    let cntLoop = 1
    let findSector = {}

    while (1) {
        const getSectorNumber = cntLoop % sectorLength === 0 ? 1 : cntLoop % sectorLength + 1

        findSector = carSector.find(el => el.SECTOR_NUMBER === getSectorNumber)

        const findSectorTime = findSector ? findSector.TIME : 0
        totalAccumulateTime += findSectorTime

        if (totalAccumulateTime > afterPitGap) {
            findSector.TOTAL_ACCUMULATE_TIME =  totalAccumulateTime - findSectorTime
            break
        }

        cntLoop++
    }
        return findSector
}

const addSectorAnimation = (carObject, carNumber, carSector, sectorNumberPath, sectorLength, trackId, lapSpeed, tyreLostForFollowCar, followCarNumber, i) => {
    let nextSectorNumber = sectorNumberPath === sectorLength ? 1 : sectorNumberPath + 1
    let nextSectorData = carSector.find(el => el.SECTOR_NUMBER === nextSectorNumber)
    if (nextSectorData) {
        let time = nextSectorData ? nextSectorData.TIME : 0
        let path = nextSectorData ? nextSectorData.PATH : 0
    
        if (followCarNumber === carNumber) {
            const completedLap = i / sectorLength
            const sectorIndex = (i % sectorLength) + 1
            const sectorKey = 'sec' + sectorIndex
            const getTyreLost = completedLap > 1 ? tyreLostForFollowCar[sectorKey] : tyreLostForFollowCar['other']
            time += getTyreLost
        }
    
        motionTimeline(carObject, carNumber, trackId, time, path, lapSpeed)
    }
}

const motionTimeline = (carObject, carNumber, trackId, time, path, lapSpeed) => {
    carObject.to( `#car${ carNumber }_${trackId}`, {
        duration: time / lapSpeed,
        repeat: 0,
        ease: 'power0.in',
        motionPath: {
            path: path,
            autoRotate: false,
            alignOrigin: [0.5, 0.5]
        }
    })
}