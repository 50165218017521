import React, { useEffect, useState } from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useTranslation } from 'react-i18next'

const PositionTableWithGap = ({ raceDetailId }) => {
    const { t } = useTranslation(['strategy', 'general']);

    const [carNumbers, setCarNumbers] = useState([]);
    const [selectedCarNumber, setSelectedCarNumber] = useState('');
    const [tableData, setTableData] = useState([]);
    const [carNumberColorObject, setCarNumberColorObject] = useState({});
    const [selectedLapIndex, setSelectedLapIndex] = useState(null);

    const colors = [
        '#7289DA',
        '#FFFFFF',
        '#E9967A',
        '#F08080',
        '#FFC0CB',
        '#FF69B4',
        '#FF1493',
        '#FF6347',
        '#FFD700',
        '#FFFACD',
        '#FFE4B5',
        '#BDB76B',
        '#EE82EE',
        '#BA55D3',
        '#4B0082',
        '#6A5ACD',
        '#7FFF00',
        '#2E8B57',
        '#00FFFF',
        '#4682B4',
        '#800000',
        '#008080',
        '#191970',
        '#D2691E',
    ];

    const handleCarNumberChange = (event) => {
        setSelectedCarNumber(event.target.value);
    };
    const handleHeaderClick = (index) => {
        setSelectedLapIndex(selectedLapIndex === index ? null : index);
    };

    const processPositionTableInfo = (inputData) => {
        const tableData = {};
        const colorObject = {};
        const lapNumbers = Object.keys(inputData);

        tableData['header'] = [...lapNumbers];

        tableData['rows'] = [];
        tableData['gaps'] = [];
        tableData['diffs'] = [];
        const maxRowLength = Math.max( ...Object.values(inputData).map((carData) => carData.length));

        for (let i = 0; i < maxRowLength; i++) {
            const row = [];
            const gap = [];
            const diff = [];
                for (const lapNumber of lapNumbers) {
                    const carNumber = inputData[lapNumber][i]
                    ? inputData[lapNumber][i].CAR_NUMBER
                    : null;
                    const gapTime = inputData[lapNumber][i]
                    ? inputData[lapNumber][i].GAP
                    : null;
                    const diffTime = inputData[lapNumber][i]
                    ? inputData[lapNumber][i].DIFF
                    : null;
                    row.push(carNumber);
                    gap.push(gapTime);
                    diff.push(diffTime);
                }
            tableData['rows'].push(row);
            tableData['gaps'].push(gap);
            tableData['diffs'].push(diff);
        }

        carNumbers.forEach((carNumber, index) => {
            const backgroundColor =
            carNumber === selectedCarNumber ? 'red' : colors[index % colors.length];
            const textColor = 'black';
            colorObject[carNumber] = { backgroundColor, textColor };
        });
        setCarNumberColorObject(colorObject);

        return tableData;
    };

    useEffect(() => {
        const getCarNumbers = () => {
            fetch(`/api/car/carNumbers/${raceDetailId}`)
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 200) {
                    setCarNumbers(json.data);
                }
            })
            .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}));
            });
        };

        getCarNumbers();
    }, []);

    useEffect(() => {
        const getPositionTableInfo = () => {
            fetch(`/api/lap/positionTableInfo/${raceDetailId}/${selectedCarNumber}`, {
            method: 'GET',
            })
            .then((response) => response.json())
            .then((json) => {
                if (json.status === 200) {
                    setTableData(processPositionTableInfo(json.data));
                }
            })
            .catch((error) => {
            alert(t('pleaseContactAdmin', {ns: 'general'}));
            });
        };

        selectedCarNumber && getPositionTableInfo();
    }, [selectedCarNumber]);

    return (
        <div style={{margin: '50px 0 0 0', }}>
            <div>
                <span className='carNo-info'>{t('general.pleaseSelectCarNumber')}</span>
                <FormControl className='car-num-selecter-box'>
                    <span className='car-num-selecter-label'>Car Number</span>
                    <Select labelId='demo-simple-select-autowidth-label' id='demo-simple-select-autowidth' value={selectedCarNumber} onChange={handleCarNumberChange} autoWidth label='carNumber' className='car-num-selecter'>
                        {carNumbers &&
                            carNumbers.map((carNumber, index) => (
                            <MenuItem key={`select-${index}`} value={carNumber} style={{ color: 'black', minWidth: 160 }}>
                                {carNumber}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        {tableData.header && tableData.rows ? (
            <>
                <div>{t('positionTableWithGap.showGap')}</div>
                <table border='1'>
                    <thead>
                        <tr>
                            {tableData.header.map((lapNumber, index) => (
                                <React.Fragment key={`header-${index}`}>
                                    <th style={{ textAlign: 'center', cursor: 'pointer', fontWeight: 'bold', width: '30px',}} onClick={() => handleHeaderClick(index)}>
                                        {lapNumber}
                                    </th>
                                    {selectedLapIndex === index && (
                                    <>
                                        <th>GAP</th>
                                        <th>DIFF</th>
                                    </>
                                    )}
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {tableData.rows.map((row, rowIndex) => (
                        <tr key={`tr-${rowIndex}`}>
                        {row.map((carNumber, cellIndex) => (
                            <React.Fragment key={`td-${rowIndex}-${cellIndex}`}>
                            <td style={{textAlign: 'center', backgroundColor:carNumberColorObject[carNumber]?.backgroundColor, color: carNumberColorObject[carNumber]?.textColor, cursor: 'pointer', fontWeight: 'bold',}} onClick={() => handleHeaderClick(cellIndex)}>
                                {carNumber}
                            </td>
                            {selectedLapIndex === cellIndex && (
                                <>
                                    <td style={{ textAlign: 'center', fontWeight: 'bold',}}>
                                        {tableData.gaps[rowIndex][selectedLapIndex] !== null
                                        ? tableData.gaps[rowIndex][
                                            selectedLapIndex
                                            ].toFixed(3)
                                        : null}
                                    </td>
                                    <td style={{textAlign: 'center', fontWeight: 'bold' }}>
                                        {tableData.diffs[rowIndex][selectedLapIndex] !==
                                        null
                                        ? tableData.diffs[rowIndex][
                                            selectedLapIndex
                                            ].toFixed(3)
                                        : null}
                                    </td>
                                </>
                            )}
                                </React.Fragment>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        ) : (
            <></>
        )}
        </div>
    );
};

export default PositionTableWithGap;
