import { useState, useEffect } from 'react'

import MotionPathPitin from '../services/MotionPathPitin'
import Track from '../services/Track'

import { useLiveData } from '../../DataContext'

import { gsap } from 'gsap'

import { useTranslation } from 'react-i18next'

const PitinMap = (props) => {
    const { t } = useTranslation(['strategy', 'general'])

    const { circuitId, raceDetailId, tableData, sectorLength, pitRoadExitTime, pitRoadExitProgressPos, pitInMapTop, followCarNumber, rivalCarNumbers } = useLiveData()

    const [latestSectorLap, setLatestSectorLap] = useState({})
    const [userData, setUserData] = useState({})
    const [gap, setGap] = useState({})
    const [displaySpeedButton, setDisplaySpeedButton] = useState(false)
    const [speedButtonValue, setSpeedButtonValue] = useState(false)
    const [startMovingButtonFlag, setStartMovingButtonFlag] = useState(true)
    const [carMotion, setCarMotion] = useState({})
    
    const drivers = props.drivers
    const pitLossTime = props.pitLossTime
    const currentProgressBySector = props.currentProgressBySector
    const carClasses = props.carClasses
    const mapSize = props.mapSize
    const mapLeaders = props.mapLeaders
    const mapDisplayLeaders = props.mapDisplayLeaders
    const battleData = props.battleData
    const currentSectorLocation = props.currentSectorLocation

    const trackId = 'preditMap'
    const lapSpeed = 5

    useEffect(() => {
        if (circuitId) {
            const elementsToKill = document.querySelectorAll('.car_preditMap')

            elementsToKill.forEach((element) => {
                gsap.killTweensOf(element)
            })
        }
    }, [circuitId])

    useEffect(() => {
        const fetchDataAndProcess = async () => {
            if (raceDetailId) {
                try {
                    const json = await getLatestSectorLap(raceDetailId)
                    const laterSectorLapObj = processLatestSectorLap(json)
                    setLatestSectorLap(laterSectorLapObj)
    
                    if (!Object.keys(userData).length && Object.keys(laterSectorLapObj).length) {
                        const { userDataObj, gapObj } = buildUserDataObjAndGapObj(tableData, laterSectorLapObj, currentProgressBySector, followCarNumber)
    
                        setUserData(userDataObj)
                        setGap(gapObj)
                    }
                } catch (error) {
                    alert(t('pleaseContactAdmin', {ns: 'general'}))
                }
            }
        }
    
        fetchDataAndProcess()
    }, [raceDetailId, currentProgressBySector])

    const processLatestSectorLap = (json) => {
        const laterSectorLapObj = {}
    
        json.forEach(data => {
            if (!laterSectorLapObj[data.CAR_NUMBER]) {
                laterSectorLapObj[data.CAR_NUMBER] = []
            }
            laterSectorLapObj[data.CAR_NUMBER].push({
                LAP_NUMBER: data.LAP_NUMBER,
                SECTOR_NUMBER: data.SECTOR_NUMBER,
                TIME: data.TIME,
                PATH: document.getElementById(`sec${data.SECTOR_NUMBER}${trackId}`).getAttribute('d')
            })
        })
    
        return laterSectorLapObj
    }
    
    const buildUserDataObjAndGapObj = (tableData, laterSectorLapObj, currentProgressBySector, followCarNumber) => {
        const userDataObj = {}
        const gapObj = {}
    
        Object.values(tableData).forEach(data => {
            const carNo = parseInt(data.carno.split('.')[1])
    
            if (!userDataObj[carNo]) {
                userDataObj[carNo] = {}
            }
    
            const carlatestSectorDatas = laterSectorLapObj[carNo]
            const currentProgressBySectorData = currentProgressBySector[carNo]
            const getFollowedCarProgressData = currentProgressBySector[followCarNumber]
    
            gapObj[carNo] = calculateGap(carlatestSectorDatas, currentProgressBySectorData, getFollowedCarProgressData)
        })
    
        return { userDataObj, gapObj }
    }

    // GAPの計算
    const calculateGap = (carlatestSectorDatas, currentProgressBySectorData, getFollowedCarProgressData) => {
        let gap = 0
        if (carlatestSectorDatas && currentProgressBySectorData && getFollowedCarProgressData) {
            const getFollowCarSectorNumber = getFollowedCarProgressData.POSITION
            const getFollowCarPreditProgress = getFollowedCarProgressData.PROGRESS
            const getCarSectorNumber = currentProgressBySectorData.POSITION
            const getCarPreditProgress = currentProgressBySectorData.PROGRESS

            gap = carlatestSectorDatas.find(el => el.SECTOR_NUMBER === getFollowCarSectorNumber)?.TIME * (1 - getFollowCarPreditProgress)

            for (let i=1; i<=sectorLength; i++) {
                const nextSector = (getFollowCarSectorNumber + i) % sectorLength === 0 ? sectorLength : (getFollowCarSectorNumber + i) % sectorLength
                const getNextSectorTime = carlatestSectorDatas.find(el => el.SECTOR_NUMBER === nextSector)?.TIME

                if(nextSector === getCarSectorNumber){
                    gap+=(getNextSectorTime*getCarPreditProgress)
                    break
                } else{
                    gap+=getNextSectorTime
                }
            }
        }
        return gap
    }
    
    useEffect(() => {
        const carsObjList = document.querySelector(`.car_${trackId}`)

        if (carsObjList && Object.keys(gap).length > 0) {
            MotionPathPitin(trackId, sectorLength, lapSpeed, setCarMotion, gap, pitLossTime, followCarNumber, pitRoadExitTime, latestSectorLap, pitRoadExitProgressPos)
        }
    }, [gap])

    useEffect(() => {
        const followedCarAtTop = document.getElementById(`use_${trackId}`)
        if(followedCarAtTop){
            followedCarAtTop.setAttribute('href', `#car${followCarNumber}_${trackId}`)
        }
    }, [followCarNumber])

    const startMovingHandler = (event) => {
        setStartMovingButtonFlag(false)
        setDisplaySpeedButton(true)
        
        Object.entries(carMotion).forEach((data) => {
            const eachTimelineData = data[1]

            eachTimelineData.play()
        })
    }

    const speedEditHandler = (event) => {
        Object.entries(carMotion).forEach((data) => {
            const eachTimelineData = data[1]

            if (!speedButtonValue) {
                eachTimelineData.timeScale(2.5)
            } else {
                eachTimelineData.timeScale(1)
            }
        })

        setSpeedButtonValue(!speedButtonValue)
    }

    return (
        <>
            <section 
                className='map_option_container'
                style={{
                    top: pitInMapTop
                }}
            >
                <div>Followed Car Number： <strong className='map_option_carNumber'>{followCarNumber}</strong></div>
                {
                    startMovingButtonFlag ?
                        <button onClick={startMovingHandler} className='map_option_button'>{t('pitInMap.start')}</button>
                        :
                        <></>
                }
                {
                    displaySpeedButton ?
                        <button onClick={speedEditHandler} className='map_option_button'>{speedButtonValue ? t('pitInMap.speedDown') : t('pitInMap.speedUp')}</button>
                        :
                        <></>
                }
            </section>
            <section>
                <Track
                    id={trackId}
                    drivers={drivers}
                    circuitId={props.circuitId}
                    users={ userData }
                    carNumberTeamColorObj={props.carNumberTeamColorObj}
                    mapSize={mapSize}
                    followCarNumber={ followCarNumber }
                    rivalCarNumbers={ rivalCarNumbers }
                    mapLeaders={ mapLeaders }
                    mapDisplayLeaders={ mapDisplayLeaders }
                    carClasses={ carClasses }
                    battleData={ battleData }
                    currentSectorLocation={ currentSectorLocation }
                />
            </section>
        </>
)}

export default PitinMap

function getLatestSectorLap(raceDetailId) {
    return new Promise ((resolve, reject) => {
        if (!raceDetailId) return resolve([])

        fetch(`/api/race/getLatestSectorLap/${raceDetailId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}