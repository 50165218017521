// BaseよりLaptimeが速いケース, ロール剛性が最大, 最小の3つを取得
const compare_laptime = (df, df2) => {
    let base_LapTime = df2.iloc({'rows':[0]}).loc({'columns':['LapTime']}).values[0][0]
    let df_ans = df.query(df['LapTime'].lt(base_LapTime))
    df_ans = df_ans.resetIndex()

    let numAns = df_ans.index.length
    let listAns

    if (numAns === 0) {
        let min_Laptime = df.loc({'columns':['LapTime']}).min({axis:0}).values[0]
        listAns = df.query(df['LapTime'].eq(min_Laptime))['id'].values
    } else{
        let min_Laptime = df_ans.loc({'columns':['LapTime']}).min({axis:0}).values[0]
        listAns = df.query(df['LapTime'].eq(min_Laptime))['id'].values
    }

    return [ listAns ]
}

export default compare_laptime