import React from 'react'

import '../css/headerMenu.css'

const Header = ({ className }) => {
    return (
        <header className={`strategy-header ${className}`}>
            <div className='logo-container' onClick={ () => {window.location.href = '/top'}}></div>
        </header>
    )
}

export default Header
