import React from 'react'
import { useEffect, useState } from 'react'
import EditVehicleDatabaseTemplate  from '../components/EditVehicleDatabaseTemplate'
import AddVehicleModal from '../components/AddVehicleModal'

const EditVehicleDatabase = (props) => {
    
    const [vehicleList, setVehicleList] = useState([])
    const [circuitList, setCircuitList] = useState([])
    const [selectedVehicleId, setSelectedVehicleId] = useState(null)
    const [selectedCircuitId, setSelectedCircuitId] = useState(null)
    const [vehicleEnergyInfoData, setVehicleEnergyInfoData] = useState([])
    const [vehicleCircuitEnergyInfoData, setVehicleCircuitEnergyInfoData] = useState([])
    const [isUpdateButtonClicked, setIsUpdateButtonClicked] = useState(false)
    const [updateVehicleEnergyInfoData, setUpdateVehicleEnergyInfoData] = useState([])
    const [updateVehicleCircuitEnergyInfoData, setUpdateVehicleCircuitEnergyInfoData] = useState([])
    const [updateButtonTypeForVehicleEnergyInfo, setUpdateButtonTypeForVehicleEnergyInfo] = useState('')
    const [updateButtonTypeForVehicleCircuitEnergyInfo, setUpdateButtonTypeForVehicleCircuitEnergyInfo] = useState('')
    const [openModal, setOpenModal] = useState(false)
    const [modalMessage, setModalMessage] = useState('')

    const handleVehicleSelect = (newVehicle) => {
        if (newVehicle === 'addVehicle') {
            setOpenModal(true)
        } else {
            setSelectedVehicleId(newVehicle)
        }
    }

    const handleCircuitSelect = (newCircuit) => {
        setSelectedCircuitId(newCircuit) 
    }

    const handleUpdateButtonClick = () => {
        setIsUpdateButtonClicked(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setModalMessage('')
    }

    const handleUpdateVehicleEnergyInfoDataChange = (newUpdateData) => {
        setUpdateVehicleEnergyInfoData(newUpdateData)
    }
    
    const handleUpdateVehicleCircuitEnergyInfoDataChange = (newUpdateData) => {
        setUpdateVehicleCircuitEnergyInfoData(newUpdateData)
    }
    
    const handleVehicleAdded = vehicleName => {
        if (vehicleName === '') {
            setModalMessage('車両名を入力してください')
            return
        }
        else if (vehicleList.find((vehicle) => vehicle.NAME === vehicleName)) {
            setModalMessage('既に同じ名前の車両が登録されています')
        }
        else {
            addVehicle(vehicleName)
            setOpenModal(false)
        }
    }

    const getVehicles = () => {
        fetch(`/api/tirePressure/getAllVehicles`)
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                setVehicleList(json.data)
            }
        })
        .catch((error) => {
                console.error(error)
                alert('システム管理者に連絡してください')
        })
    }

    const getCircuits = () => {
        fetch('/api/circuit/getAll')
        .then(response => response.json())
        .then(json => {
            if (json.status === 200) {
                setCircuitList(json.data)
            }
        })
        .catch((error) => {
            console.error(error)
            alert('システム管理者に連絡してください')
        })
    }

    const getVehicleEnergyInfo = (vehicleId) => {
        fetch(`api/tirepressure/getVehicleEnergyInfoByVehicleId/${vehicleId}`)
            .then(response => response.json())
            .then(json => {
                if (json.data === null) {
                    setVehicleEnergyInfoData({
                        'TIRE_ENERGY_COEFFICIENT':'',
                        'D':'',
                        'BRAKE_FR_ENERGY_COEFFICIENT':'',
                        'BRAKE_FL_ENERGY_COEFFICIENT':'',
                        'BRAKE_RR_ENERGY_COEFFICIENT':'',
                        'BRAKE_RL_ENERGY_COEFFICIENT':'',
                        'VEHICLE_FR_ENERGY':'',
                        'VEHICLE_FL_ENERGY':'',
                        'VEHICLE_RR_ENERGY':'',
                        'VEHICLE_RL_ENERGY':'',
                    })
                } else {
                    if (json.status === 200) {
                        setVehicleEnergyInfoData(json.data)
                    }
                }
            })            
            .catch((error) => {
                console.error(error)
                alert('システム管理者に連絡してください')
            })
    }
    
    const getVehicleCircuitEnergyInfo = (vehicleId, circuitId) => {
        fetch(`api/tirepressure/getVehicleCircuitEnergyInfoByVehicleIdCircuitId/${vehicleId}/${circuitId}`)
            .then(response => response.json())
            .then(json => {
                if (json.data === null) {
                    setVehicleCircuitEnergyInfoData({
                        'SURFACE_HEAT_TRANSFER_COEFFICIENT':'',
                        'TIRE_INNER_HEAT_TRANSFER_COEFFICIENT':'',
                        'TIRE_FR_ENERGY':'',
                        'TIRE_FL_ENERGY':'',
                        'TIRE_RR_ENERGY':'',
                        'TIRE_RL_ENERGY':'',
                        'BRAKE_FR_ENERGY':'',
                        'BRAKE_FL_ENERGY':'',
                        'BRAKE_RR_ENERGY':'',
                        'BRAKE_RL_ENERGY':'',
                    })
                } else {
                    if (json.status === 200) {
                        setVehicleCircuitEnergyInfoData(json.data)
                    }
                }
            })  
            .catch((error) => {
                console.error(error)
                alert('システム管理者に連絡してください')
            })
    }

    const addVehicle = vehicleName => {
        fetch('/api/tirepressure/addVehicle', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                NAME: vehicleName,
            })
        })
            .then(response => response.json())
            .then(json => {
                if (json.status !== 200) {
                    setModalMessage('システム管理者に連絡してください')
                    return
                }
                alert('車両を追加しました')
                getVehicles()
                setSelectedVehicleId(json.data.ID)
            })
                .catch((error) => {
                    console.error(error)                    
                    setModalMessage('システム管理者に連絡してください')
                })
    }

     const addVehicleEnergyInfoData = (vehicleId, vehicleEnergyInfoData) => {
        fetch(`api/tirepressure/addVehicleEnergyInfo`, {
                method: 'POST',
                headers: {
                     'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        vehicleId: vehicleId,
                        vehicleEnergyInfoData: vehicleEnergyInfoData
                    })
                })
            .then(response => response.json())
            .then(json => {
                if (json.status !== 200) {
                    return alert('システム管理者に連絡してください')
                }
                alert('データベースに追加しました')
            })
                .catch((error) => {
                    console.error(error)
                    alert('システム管理者に連絡してください')
        })
    }
    
    const addVehicleCircuitEnergyInfoData = (vehicleId, circuitId, vehicleCircuitEnergyInfoData) => {
        fetch(`api/tirepressure/addVehicleCircuitEnergyInfo`, {
                method: 'POST',
                headers: {
                     'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        vehicleId: vehicleId,
                        circuitId: circuitId,
                        vehicleCircuitEnergyInfoData: vehicleCircuitEnergyInfoData
                    })
                })
            .then(response => response.json())
            .then(json => {
                if (json.status !== 200) {
                    return alert('システム管理者に連絡してください')
                }
                alert('データベースに追加しました')
            })
                .catch((error) => {
                    console.error(error)
                    alert('システム管理者に連絡してください')
        })
    }

    const updateVehicleEnergyInfoDataByVehicleId = (vehicleId, columnName, value) => {
        fetch('api/tirepressure/updateVehicleEnergyInfoByVehicleId', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                vehicleId: vehicleId,
                columnName: columnName,
                value:value
            })
        })
            .catch((error) => {
                console.error(error)
                alert('システム管理者に連絡してください')
        })
    }

    const updateVehicleCircuitEnergyInfoDataByVehicleId = (vehicleId, circuitId, columnName, value) => {
        fetch('api/tirepressure/updateVehicleCircuitEnergyInfoByVehicleIdCircuitId', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                vehicleId: vehicleId,
                circuitId: circuitId,
                columnName: columnName,
                value:value
            })
        })
            .catch((error) => {
                console.error(error)
                alert('システム管理者に連絡してください')
        })
    }  

    useEffect(() => {
        getVehicles()
        getCircuits()
    },[])
    
    useEffect(() => {
        if (selectedVehicleId) { getVehicleEnergyInfo(selectedVehicleId) }
        if (selectedCircuitId) { getVehicleCircuitEnergyInfo(selectedVehicleId, selectedCircuitId) }
    }, [selectedVehicleId, selectedCircuitId])

    useEffect(() => {
        if (Object.values(vehicleEnergyInfoData).every(value => value === '')) {
            setUpdateButtonTypeForVehicleEnergyInfo('add')
        } else {
            setUpdateButtonTypeForVehicleEnergyInfo('update')
        }
    },[vehicleEnergyInfoData])

    useEffect(() => {
        if (Object.values(vehicleCircuitEnergyInfoData).every(value => value === '')) {
            setUpdateButtonTypeForVehicleCircuitEnergyInfo('add') 
        } else {
            setUpdateButtonTypeForVehicleCircuitEnergyInfo('update')
        }
    }, [vehicleCircuitEnergyInfoData])
    
    useEffect(() => {
        if (isUpdateButtonClicked) {
            if (updateButtonTypeForVehicleEnergyInfo === 'update') {
                Object.entries(updateVehicleEnergyInfoData).map(([columnName, cellData]) =>
                {
                    updateVehicleEnergyInfoDataByVehicleId(
                        selectedVehicleId,
                        columnName,
                        cellData
                    )
                })
                setIsUpdateButtonClicked(false)

            } else if (updateButtonTypeForVehicleEnergyInfo === 'add') {
                addVehicleEnergyInfoData(selectedVehicleId, updateVehicleEnergyInfoData)
                setUpdateButtonTypeForVehicleEnergyInfo('update')
            }
            
            if (updateButtonTypeForVehicleCircuitEnergyInfo === 'update') {
                   Object.entries(updateVehicleCircuitEnergyInfoData).map(([columnName, cellData]) =>
                {
                    updateVehicleCircuitEnergyInfoDataByVehicleId(
                        selectedVehicleId,
                        selectedCircuitId,
                        columnName,
                        cellData
                    )
                })
            } else if(updateButtonTypeForVehicleCircuitEnergyInfo === 'add') {
                addVehicleCircuitEnergyInfoData(selectedVehicleId,selectedCircuitId,updateVehicleCircuitEnergyInfoData)
                setUpdateButtonTypeForVehicleCircuitEnergyInfo('update')
            }
            setIsUpdateButtonClicked(false)
        } 
    },[isUpdateButtonClicked])
    
    return (
        <>
            <EditVehicleDatabaseTemplate
                vehicleList={vehicleList}
                circuitList={circuitList}
                handleVehicleSelect={handleVehicleSelect}
                handleCircuitSelect={handleCircuitSelect}
                selectedCircuitId={selectedCircuitId}
                selectedVehicleId={selectedVehicleId}
                vehicleEnergyInfoData={vehicleEnergyInfoData}
                vehicleCircuitEnergyInfoData={vehicleCircuitEnergyInfoData}
                updateData={updateVehicleEnergyInfoData}
                handleUpdateButtonClick={handleUpdateButtonClick}
                isUpdateButtonClicked={isUpdateButtonClicked}
                handleUpdateVehicleEnergyInfoDataChange={handleUpdateVehicleEnergyInfoDataChange}
                handleUpdateVehicleCircuitEnergyInfoDataChange={handleUpdateVehicleCircuitEnergyInfoDataChange}
            />

            <AddVehicleModal
                open={openModal}
                setOpenModal={setOpenModal}
                onVehicleAdded={handleVehicleAdded}
                onClose={handleCloseModal}
                modalMessage={modalMessage}
            />
        </>
    )
}

export default EditVehicleDatabase