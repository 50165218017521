const TableDefinedByBodyRows = ({ headers, bodyRows }) => {
    return (
        <>
            <style>
                {`
                    .table {
                        width: 100%;
                        border-collapse: collapse; /* 枠線を隣接させる */
                    }
                    .table th, .table td {
                        border: 1px solid black; /* 枠線のスタイル */
                        padding: 8px; /* セルの内側の余白 */
                        text-align: left; /* テキストの揃え方 */
                    }
                    .table thead {
                        background-color: #f2f2f2; /* ヘッダーの背景色 */
                    }
                `}
            </style>
            <div style={tableContainerStyle}>
                <table className='table'>
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {bodyRows}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default TableDefinedByBodyRows

const tableContainerStyle = {
    overflowX: 'auto', 
    maxWidth: '100%',
    maxHeight: '600px', 
}