import React from 'react'

const QFLive = ({ children }) => {

    return (
        <>
            {children}
        </>
    )
}

export default QFLive