import { useEffect, useState} from 'react'

import '../css/Simulation.css'

import { useTranslation } from 'react-i18next'

const SimulationStrategyInfomation = ({ setStrategyList }) => {
    const { t } = useTranslation(['strategy', 'general'])
    
    const [displayTable, setDisplayTable] = useState(false)
    const [infoObjArr, setInfoObArr] = useState({})

    useEffect(() => {
        getAllStrategyInfo()
            .then(json => {
                setStrategyList(json)
                setInfoObArr(json)
            })
            .catch(error => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
    }, [])

    useEffect(() => {
        if (displayTable) {
            document.getElementById('infoTable').style.display = 'block'
        } else {
            document.getElementById('infoTable').style.display = 'none'
        }
    }, [])

    const onClickHandlerToggleButton = (e) => {
        if (!displayTable) {
            document.getElementById('infoTable').style.display = 'block'
            setDisplayTable(true)
        } else {
            document.getElementById('infoTable').style.display = 'none'
            setDisplayTable(false)
        }
    }

    return (
        <>
            <button onClick={onClickHandlerToggleButton}>Toggle Info Table</button>
            <table border={1} id='infoTable'>
                <thead>
                    <tr>
                        <td>Strategy</td>
                        <td></td>
                        <td>1st Stint</td>
                        <td>2nd Stint</td>
                        <td>3rd Stint</td>
                        <td>Tyre Change</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.values(infoObjArr).map((eachStrategy, index) => {
                            return (
                                <tr key={index}>
                                    <td className='strategyName'>{eachStrategy.STRATEGY}</td>
                                    <td>{eachStrategy.BLANK}</td>
                                    <td>{eachStrategy.FIRSTSTINT}</td>
                                    <td>{eachStrategy.SECONDSTINT}</td>
                                    <td>{eachStrategy.THIRDSTINT}</td>
                                    <td>{eachStrategy.TYRECHANGE}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <br></br>
        </>
    )
}

export default SimulationStrategyInfomation

function getAllStrategyInfo() {
    return new Promise ((resolve, reject) => {
        fetch(`/api/strategyInfo/getAll`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}