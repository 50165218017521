import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TableContainer, Paper, Table, TableHead, TableRow } from '@mui/material'

import { colorScale } from '../services/Util'
import { StyledHeaderTableCell } from './TableStyle'
import TimeFormatter from './TimeFormatter'
import { useTranslation } from 'react-i18next'

const OutingCarNumberTable = (props) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [backgroundColors, setBackgroundColor] = useState([])
    const [tableData, setTableData] =useState(null)
    

   

    const handleButtonClick = (header) => {
        props.setSelectedPitStopNumber(header)
    }
 
    const sortTable = () => {
        const headers = Object.keys(props.outingDatasObject)
        const maxRowLength = Math.max(...Object.values(props.outingDatasObject).map(carData => carData.length))
    
        const rows = Array.from({ length: maxRowLength }, (_, rowIndex) => {
            return [
                (rowIndex + 1).toString(),
                ...headers.map(header => props.outingDatasObject[header][rowIndex] ? props.outingDatasObject[header][rowIndex].TIME : null)
            ]
        })
    
        const tableData = {
            headerName: 'Outing',
            header: headers,
            rows: rows
        }
        setTableData(tableData)
    }

    const setHeatmapArray = (tableData, bestLap, individualBestLap) => {
        const heatMapArray = tableData ? tableData.rows.map(array => array.slice(1)).flat() : undefined
        const backgroundColors = colorScale(heatMapArray, 0xff523c, 0x3a4eff, 'normal')

        if(individualBestLap){
            backgroundColors[heatMapArray.indexOf(individualBestLap)] = 'a1e6c3'
        }
        if(bestLap){
            backgroundColors[heatMapArray.indexOf(bestLap['TIME'])] = 'b8a1e6'
        }

        const numLapsDatas = tableData.rows[0].length
        for (let i = 0; i < tableData.rows.length; i++){
            backgroundColors.splice((i*numLapsDatas), 0, 'f2f2f2')
        }
        setBackgroundColor(backgroundColors)
    }


    useEffect(() => {
        sortTable(props.outingDatasObject)   
    },[props.outingDatasObject])

    useEffect(()=> {
        if (tableData){
            setHeatmapArray(tableData, props.bestLap, props.individualBestLap)
        }
    },[tableData, props.bestLap, props.individualBestLap])


    return (
        backgroundColors.length !== 0 ?
        <TableContainer className='outing-list-table'>
        <Table className= 'fp-table-container'>  
            <TableHead sx={{height: '50px'}} >
                <TableRow sx={{backgroundColor: '#bb1414'}}>
                    <StyledHeaderTableCell sx={{...TableheadStyle}}>{tableData.headerName}</StyledHeaderTableCell>
                    {tableData.header.map((header, index) =>
                        <th className={`outing-th ${props.selectedPitStopNumber == header ? 'active' : ''}`} onClick={() => 
                            {handleButtonClick(header)
                        }} key={index}>

                            <button className={`fp-table-button`}>
                                {header}
                            </button>                        
                        </th>
                    )}
                </TableRow>
            </TableHead>
            <tbody>
                {tableData.rows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (          
                        <td key={cellIndex}> 
                        {(backgroundColors[(cellIndex) + (row.length) * rowIndex]) !== undefined ?(
                            <div style={{textAlign:'center', color: 'black', backgroundColor: `#${backgroundColors[(cellIndex) +(row.length * rowIndex)].toString(16).padStart(6, '0')}` }}>
                            {typeof cell === 'string' ? cell : <TimeFormatter seconds={cell} />}
                            </div>
                        ): null}
                        </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
        </TableContainer> 
        :
        null
    )
}

export default OutingCarNumberTable

const TableheadStyle ={
    fontWeight:'bold',
    color:'#fff',
    textAlign:'center',
    width:'40px'
}  