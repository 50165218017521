import { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import BattleAnalysis from '../components/BattleAnalysis'
import ClassSelectElement from '../components/ClassSelectElement'
import Header from '../components/Header'
import PositionTableWithGap from '../components/PositionTableWithGap'
import RivalSectorComparison from '../components/RivalSectorComparison'
import SameTeamAnalysis from '../components/SameTeamAnalysis'
import '../css/analysis.css'

import { useTranslation } from 'react-i18next'

const RaceAnalysis = () => {
    const { t } = useTranslation(['strategy', 'general'])

    const { eventId, raceDetailId } = useParams()

    const viewHandlerButtonElement = useRef()
    const classHandlerButtonElement = useRef()
    const changeViewElement = useRef()

    const [ viewMode, setViewMode ] = useState('battle')
    const [circuitData, setCircuitData] = useState([])
    const [raceData, setRaceData] = useState([])

    const [ carClassDatas, setCarClassDatas ] = useState(null)
    const [ selectedClasses, setSelectedClasses] = useState(null)
    const [eventData, setEventData] = useState([])
    const [showClassSelectElement, setShowClassSelectElement] = useState(false)

    const navigate = useNavigate()  
    
    const handleBackToSessionClick = () => {
        navigate(`/event/${eventId}`)
    }

    const handleClassSelection = () => {
        showClassSelectElement !== true
          ? setShowClassSelectElement(true)
          : setShowClassSelectElement(false)
    }

    const handleSelectedClassesChange = (newSelectedClasses) => {
        setSelectedClasses(newSelectedClasses)
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (viewHandlerButtonElement.current && viewHandlerButtonElement.current.contains(event.target)) return
            if (classHandlerButtonElement.current && classHandlerButtonElement.current.contains(event.target)) return
            if (changeViewElement.current && changeViewElement.current.contains(event.target)) return
            setShowClassSelectElement(false)
        }
    
        document.addEventListener('click', handleOutsideClick)
    
        return () => {
            document.removeEventListener('click', handleOutsideClick)
        }
    }, [])

    useEffect(() => {  
        let eventId = ''

        const getCarClasses = () =>{
            fetch(`/api/car/getCarClasses/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                    setCarClassDatas(json.data)
                }
            })
            .catch((error) => {
                console.log(error)
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }
        
        const getCircuit = () => {
            fetch(`/api/circuit/getCircuitInfo/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200) {
                    let data = json.data
                    setCircuitData(data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getRace = () => {
            fetch(`/api/raceDetail/getRaceDetail/${raceDetailId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    let race = json.data
                    const formatDateTime = (dateTime) => {
                        const date = new Date(dateTime)
                        const hours = String(date.getHours()).padStart(2, '0')
                        const minutes = String(date.getMinutes()).padStart(2, '0')
                        const year = date.getFullYear()
                        const month = (date.getMonth() + 1).toString().padStart(2, '0')
                        const day = date.getDate().toString().padStart(2, '0')
                    
                        return {
                            time: `${hours}:${minutes}`,
                            date: `${year}-${month}-${day}`
                        }
                    }
                    
                    if (race.RACE_START_TIME !== undefined) {
                        const formattedStart = formatDateTime(race.RACE_START_TIME)
                        race.START_DATE = formattedStart.date
                        race.START_TIME = formattedStart.time
                    }
                    
                    if (race.RACE_END_TIME !== undefined) {
                        const formattedEnd = formatDateTime(race.RACE_END_TIME)
                        race.END_DATE = formattedEnd.date
                        race.END_TIME = formattedEnd.time
                    }

                    setRaceData(race)
                    eventId = race.EVENT_ID
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            }) 
        }

        const getEvent = () => {
            fetch(`/api/event/getAll`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    const event = json.data
                    event.forEach((e) => {
                        if (e.ID === eventId) {
                            setEventData(e.EVENT_NAME)
                        }
                    })
                }
            })
        }

        getCarClasses()
        getCircuit()
        getRace()
        getEvent()
    }, [raceDetailId])

    return (
        <div className='race-container'>
        <Header className='header-custom' />

    <div className='header-container'>
        <div class='bgCW'>
            <button className='event-button' onClick={handleBackToSessionClick}>{t('general.sessionList')}</button>
            <div className='event-tit-container'>
                <h1>RACE</h1>
                <span className='tit-decoration'><span className='small-text'>Event</span> {eventData}</span>
                <span className='tit-decoration'><span className='small-text'>Track</span> {circuitData[0]?.NAME}</span>
            </div>
        </div>
    </div>

    <div className='display-race-container'>

        <div className='main-table'>
        <div class='select-container'>
            <span class='box-title'>{t('general.displaySwitch')}</span>
            <select
                value={viewMode}
                onChange={(e) => setViewMode(e.target.value)}
                className='select-box'
            >
                <option value='battle'>{t('raceAnalysis.battleHistory')}</option>
                <option value='position'>{t('raceAnalysis.positionHistory')}</option>
                {/*<option value='rival'>{t('general.rivalComparison')}</option>*/}
                <option value='team'>{t('general.sameTeamComparison')}</option>
            </select>
        </div>
        <div className='class-select-molecule'>
            <button
              name='selectedClass'
              style={{ width: '150px', color: '#FFFF' }}
              onClick={handleClassSelection}
              ref={viewHandlerButtonElement}
            >
              {t('general.classSelection')}
            </button>
            <div className='class-select-element' ref={changeViewElement}>
              {showClassSelectElement && (
                <ClassSelectElement
                  state={showClassSelectElement}
                  carClassDatas={carClassDatas}
                  selectedClasses={selectedClasses}
                  handleSelectedClassesChange={handleSelectedClassesChange}
                  style={{ position: 'absolute' }}
                />
              )}
            </div>
          </div>

                {
                viewMode === 'battle' ?
                <BattleAnalysis raceDetailId={raceDetailId} selectedClasses={selectedClasses}/>
                : viewMode === 'position' ?
                <PositionTableWithGap raceDetailId = {raceDetailId}/>
                : viewMode === 'rival' ?
                <RivalSectorComparison raceDetailId = {raceDetailId}/> 
                : viewMode === 'team' ?
                <SameTeamAnalysis  raceDetailId = {raceDetailId}/>
                :<></>
                }
            </div> 
        </div>
    </div>
)
}

export default RaceAnalysis