import { TableContainer, Paper, Table, TableHead, TableRow, TableBody } from '@mui/material'
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { useState, useEffect, useRef, Fragment } from 'react'

import '../css/Modal.css'

import notFollowImage from '../image/not-follow.png'
import followImage from '../image/follow.png'

import notRivalImage from '../image/no-rival.png'
import rivalImage from '../image/rival.png'

import isInBattlesImage from '../image/battles.png'
import isInIntenseBattlesImage from '../image/intenseBattles.png'

import battleUpperImage from '../image/battleUpper.png'
import battleMiddleImage from '../image/battleMiddle.png'
import battleLowerImage from '../image/battleLower.png'

import { useLiveData } from '../../DataContext'

import SectorTableCell from './SectorTableCell'
import ModalComponent from './ModalComponent'
import ClassColorDisplay from './CarClass'

import TimeFormatter from './TimeFormatter'

import { toFixedIfNumber } from '../services/Util'
import { StyledHeaderTableCell, StyledTableCell, StyledHeaderUpperTableCell, StyledHeaderTableCellSmall, StyledTableCellSmall } from './TableStyle'
import LapValueSelectHeaderCell from './LapValueSelectHeaderCell'

import CalculateAverageAndDegradation from '../services/CalculateAverageAndDegradation'



export const SetTable = (props) => {

    const tableContainerRef = useRef()
    
    const [averageData, setAverageData] = useState([])
    const [averageHeatMap, setAverageHeatMap] = useState({})
    const [columnOrder, setColumnOrder] = useState()
    const [deg, setDeg] = useState([3, 5, 10, 15])
    const [degData, setDegData] = useState([])
    const [degHeatMap, setDegHeatMap] = useState({})
    const [driverInfo, setDriverInfo] = useState({car: ''})
    const [isSecterVisible, setIsSecterVisible] = useState(true)
    const [lapAverages, setLapAverages] = useState([3, 5, 10, 15])
    const [modalType, setModalType] = useState('')
    const [openModal, setOpenModal] = useState(false)

    
    const columnIdAndName = props.columnIdAndName
    const highlightUpdatedRows = props.highlightUpdatedRows
    const mobileTableContent = props.mobileTableContent
    const sectorTraffic = props.sectorTraffic
    const setFollowCarNumber = props.setFollowCarNumber
    const setMobileTableContent = props.setMobileTableContent
    const setPredictPitInMode = props.setPredictPitInMode
    const setRivalCarNumbers = props.setRivalCarNumbers
    const setTableScroll = props.setTableScroll
    const tableScroll = props.tableScroll


    const { tableData, tableDataWithBattle, lapData, sectorData, sectorLength, displayFollowAndRivals, followCarNumber , rivalCarNumbers, isRace, overallBest, raceSeries, raceDetailId, carClasses ,isLightMode, styleSheet } = useLiveData()

    const styles =  styleSheet ?  'switchStyle' : ''

    useEffect(() => {
        const calculatedAverage = CalculateAverageAndDegradation(lapData, lapAverages, 'average', followCarNumber)
        setAverageHeatMap(calculatedAverage.heatmap)
        setAverageData(calculatedAverage.averageData)
    }, [lapData, lapAverages, columnOrder, followCarNumber])

    useEffect(() => {
        const calculateDeg = CalculateAverageAndDegradation(lapData, deg, 'degradation', followCarNumber)
        setDegHeatMap(calculateDeg.heatmap)
        setDegData(calculateDeg.averageData)
    }, [lapData, deg, columnOrder, followCarNumber])

    useEffect(() => {
        setLapAverages([3, 5, 10, 15])
        setDeg([3, 5, 10, 15])
    }, [raceDetailId])

    useEffect(() => {
        if (!props.columnOrder) return
        setColumnOrder(props.columnOrder)
    }, [props.columnOrder])

    useEffect(() => {
        const leaders = {
            race: tableData.find(car => car.pos === 1),
            classLeaders: []
        }

        const findCarClasses = []
        tableData.forEach(car => {
            if (!findCarClasses.find(carClass => carClass.class === car.carClass)) {
                findCarClasses.push({class: car.carClass})
            }
        })          
        findCarClasses.forEach(carClass => {
            let topPositionEntry = null
            for (const car of tableData) {
                if (car.carClass === carClass.class) {
                    if (!topPositionEntry || car.position < topPositionEntry.position) {
                        topPositionEntry = car
                    }
                }
            }
            leaders.classLeaders.push({class: carClass.class, carno: topPositionEntry.carno.split('.')[1]})
        })
        props.setMapLeaders(leaders)
    }, [tableData])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIsSecterVisible(prevState => !prevState);
        }, 1500); 
    
        return () => clearInterval(intervalId); 
    }, []);


    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const newColumnOrder = [...columnOrder]
        newColumnOrder.splice(result.source.index, 1)
        newColumnOrder.splice(result.destination.index, 0, result.draggableId)
        props.setColumnOrder(newColumnOrder)
    }

    if (tableData.length < 1) return

    const currentLap = tableData.reduce((maxLap, currentData) => {
        if (currentData.hasOwnProperty('lap') && typeof currentData.lap === 'number') {
            return Math.max(maxLap, currentData.lap)
        }
        return maxLap
    }, -1)

    const personalDriverHistoryHandler = (carNumber, driverName) => {
        if (sectorData.length < 1) return
        setModalType('personalDriverHistory')
        setDriverInfo({car: carNumber, name: driverName})
        setOpenModal(true)
    }

    const beforeAfterPitAvgHandler = (carNumber, driverName) => {
        if (sectorData.length < 1) return
        setModalType('beforeAfterPitAvg')
        setDriverInfo({car: carNumber, name: driverName})
        setOpenModal(true)
    }

    const followDataHandler = (data) => {
        let carNumber = data.carno.replace('No.', '')
        setFollowCarNumber(carNumber === followCarNumber ? '' : carNumber)
        
        if (rivalCarNumbers.includes(carNumber)) {
            rivalDataHandler(data)
        }

        setPredictPitInMode(false);
        
        if (document.getElementById('pitInPrediction-checkbox')) {
            document.getElementById('pitInPrediction-checkbox').checked = false
        }

        if (props.tabletMode && carNumber === followCarNumber) {
            setRivalCarNumbers([])
        }
    }
    
    const rivalDataHandler = (data) => {
        let carNumber = data.carno.replace('No.', '')

        if (followCarNumber !== carNumber ) {
            let updatedRivalCarNumbers
        
            if (rivalCarNumbers.includes(carNumber)) {
                updatedRivalCarNumbers = rivalCarNumbers.filter(number => number !== carNumber)
            } else {
                updatedRivalCarNumbers = [...rivalCarNumbers, carNumber]
            }
        
            setRivalCarNumbers(updatedRivalCarNumbers)
        }
    }
    
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const showBattle = (row) => {
        const battleImages = {
            isInIntenseBattles: isInIntenseBattlesImage,
            isInBattles: isInBattlesImage,
        }
        const battlePositionImages = {
            upper: battleUpperImage,
            middle: battleMiddleImage,
            lower: battleLowerImage
        }

        if (row.hasOwnProperty('battleStatus')) {
            return (
                <div className='image-and-text-block battle'>
                    <p><img src = { battlePositionImages[row.battlePosition] } alt='戦' className='tableCell-icon battle-position' /></p>
                    <p><img src = { battleImages[row.battleStatus] } alt='戦' className='tableCell-icon center battle' /></p>
                </div>
            )
        } else {
            return (
                <div className='image-and-text-block' />
            )
        }
    }

    const showOtsLeft = (row) => {
        const widthPercentage = ((200 - row.otsLeft) / 2)
        const remainingPercentage = 100 - widthPercentage
        let bgColor = ''
        let className = 'ots-available'

        if (remainingPercentage >= 1 && remainingPercentage < 35) {
            bgColor = '#ff523d'
        } else if (remainingPercentage >= 35 && remainingPercentage < 71) {
            bgColor = '#ffe500'
        } else if (remainingPercentage >= 71) {
            bgColor = '#82ff80'
        }

        if (Number(row.otsUsing) > 0) {
            className = 'ots-using'
        }

        if (Number(row.otsLimit) > 0 && widthPercentage < 100) {
            className = 'ots-unavailable'
            bgColor = '#000'
        }

        if (widthPercentage === 100) {
            className = 'ots-max-used'
        }

        return (
            <div className={`ots-limit-bar-container ${className}`}>
                {className === 'ots-unavailable' ? <div className='ots-limit-border'>{row.otsLeft}</div>: '' }
                { widthPercentage === 100 ? (
                    <>
                        <span className='ots-limit-text'> ーー</span>
                    </>
                ) : (
                    <>
                        <span className='ots-limit-text'>{row.otsLeft}</span>
                        <div className='ots-limit-bar' style={{ width: `${remainingPercentage}%`, backgroundColor: bgColor }}></div>
                    </> 
                )}
            </div>
        )
    }

    

    const showNameAndNumber = (row) => {
        const displayName = raceSeries === 'SF' ? row.driverNameShort : row.driverName

        return (
            <div className='name-and-number'>
                <div className='table-name' style={{ width: '60px'}}>{displayName}</div>
                <div className='table-number' style={{ color: row.teamTextColor, fontWeight: '700', backgroundColor: row.teamBgColor,  border: `1px solid #000`, minWidth: '40px' }}>{row.carno.split('.')[1]}</div>
            </div>
        )
    }

    const showPosition = (row) => {
        return (
            <div className='image-and-position'>
                {row.posChange ? <img src = { row.posChange } alt = 'change' className='tableCell-icon' /> : ''}
                <div className='table-position'>{row.pos}</div>
            </div>
        )
    }

    const showAverage = (carNum, numberOfLaps) => {
        const currentCar = averageData.find((car) => car.carNo === Number(carNum.split('.')[1]))
        if (!currentCar) return '-'

        const currentCarAverages = currentCar.averages
        let currentCarAverage
        let heatMap

        if (lapAverages.includes(numberOfLaps)) {
            heatMap = averageHeatMap[numberOfLaps]?.find((car) => car.carNo === Number(carNum.split('.')[1]))
            currentCarAverage = currentCarAverages[lapAverages.indexOf(numberOfLaps)]
        } else {
            return '-'
        }
        if (!currentCarAverage) {
            return '-'
        } else {
            const color = (heatMap?.color !== undefined ? `#${heatMap.color?.toString(16).padStart(6, '0')}` : 'white')
            const actualNumberOfLaps = currentCarAverage.numberOfLaps
            const mainAverageReturn = currentCarAverage.average
            if (mainAverageReturn === '-') return '-'
            const condition = currentCarAverage.condition
            return (
                <div className = 'image-and-text-block averages' style = {{backgroundColor: color}}>  
                    <p>{condition ? <img src = { condition } alt = 'up' className = 'tableCell-icon value-up-down' /> : ''}</p>
                    <p className='average-value'><TimeFormatter seconds={mainAverageReturn} decimalPlaces={2} /></p>
                    {actualNumberOfLaps !== numberOfLaps && <p className='numberOfLaps'>&nbsp;{`(${actualNumberOfLaps})`}</p>}
                </div>
            )
        }
    }

    const showDeg = (carNum, numberOfLaps) => {
        const currentCar = degData.find((car) => car.carNo === Number(carNum.split('.')[1]))
        if (!currentCar) return '-'

        const carData = currentCar.averages
        let currentCarData
        let heatMap

        if (deg.includes(numberOfLaps)) {
            heatMap = degHeatMap[numberOfLaps]?.find((car) => car.carNo === Number(carNum.split('.')[1]))
            currentCarData = carData[deg.indexOf(numberOfLaps)]
        } else {
            return '-'
        }
        if (!currentCarData ) {
            return '-'
        } else {
            const color = (heatMap?.color !== undefined ? `#${heatMap.color?.toString(16).padStart(6, '0')}` : 'white')
            const actualNumberOfLaps = currentCarData.numberOfLaps
            const mainDegReturn = currentCarData.degradation
            if (mainDegReturn === '-') return '-'
            return (
                <div className = {typeof mainDegReturn === 'number' ? 'image-and-text-block averages' : 'image-and-text-block deg'} style = {{backgroundColor: color}}>
                    <p className='deg-value'>{typeof mainDegReturn === 'number' ? mainDegReturn.toFixed(3) : '-'}</p>
                    {actualNumberOfLaps !== numberOfLaps && <p className='numberOfLaps'>&nbsp;{`(${actualNumberOfLaps})`}</p>}
                </div>
            )
        }
    }

            const handleScroll = () => {  
                if (tableScroll !== undefined) {
                    const scrollPosition = tableContainerRef.current?.scrollTop
                    if (scrollPosition > 1 && tableScroll === false) {
                        setTableScroll(true)
                    } else if (scrollPosition <= 1 && tableScroll === true){
                        setTableScroll(false)
                    }

                    if (tableContainerRef.current) {
                        tableContainerRef.current.addEventListener('scroll', handleScroll)
                    }
                    return () => {
                        if (tableContainerRef.current) {
                            tableContainerRef.current.removeEventListener('scroll', handleScroll)
                        }
                    }
                }
            }


    const degHandler = (position, newValue) => {
        const newDeg = [...deg]
        newDeg[position] = newValue
        setDeg(newDeg)
    }

    const lapAveragesHandler = (position, newValue) => {
        const newLapAverages = [...lapAverages]
        newLapAverages[position] = newValue
        setLapAverages(newLapAverages)
    }  

    const renderTableHeader = (columnId, provided) => {
        const commonTableHeader = (label) => {
            const TableCellComponent = label === 'Battle' ? StyledHeaderTableCellSmall : StyledHeaderTableCell

            return (
                < TableCellComponent
                className={`${
                    label === 'Best Time' ? 'best-time-tr' : ''
                } ${provided.className || ''}`}
                    rowSpan={2}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {label === 'Best Time' ? <>Best&nbsp;TIME<span className='best-lap-count'>Lap</span></> : ['Follow', 'Pos', 'Battle', 'Rival'].includes(label) ? '' : label == 'Class' ? window.innerWidth <= 1366 ? '':label: label  }
                </TableCellComponent>
            )
        }

        const withUpperAndLowerHeader = (id, label) => {
            let lowerHeader

            if (id === 'ots') {
                lowerHeader =
                    <div className='header-div'>
                        <div className='header-inner-div'>
                            <div className='header-inner-div-row ots-header'>
                                <div className='header-inner-div-small-font ots-left-header'>{label}</div>
                                <div className='header-inner-div-small-font'>Limit</div>
                            </div>
                        </div>
                    </div>
            } else if (id === 'laptime') {
                lowerHeader = 
                    <div className='header-div'>
                            <div className='header-inner-div'>
                            {isSecterVisible ? 
                            <div>{label}</div>:
                            <div className='base-lapTime-font'><TimeFormatter seconds={isFinite(overallBest.lap) ? toFixedIfNumber(overallBest.lap, 3) : ''} /></div>
                            }
                        </div>
                    </div>
            } else if (id === 'sector') {
                lowerHeader = 
                <div className='header-div'>
                <div className='header-inner-div header-inner-div-width header-sector-cells'>
                {isSecterVisible ?
                    <><div className='secter-items'>S1&nbsp;</div><div className='number-of-cars'>{sectorTraffic.sec1}</div></>:
                    <div className=' base-secter-font'>{isFinite(overallBest.sec1) ? toFixedIfNumber(overallBest.sec1, 3) : '-'}</div>
                    }
                </div>
                <div className='header-inner-div header-inner-div-width header-sector-cells'>
                {isSecterVisible ?
                    <><div className='secter-items'>S2&nbsp;</div> <div className='number-of-cars'>{sectorTraffic.sec2}</div></>:
                    <div className=' base-secter-font'>{isFinite(overallBest.sec2) ? toFixedIfNumber(overallBest.sec2, 3) : '-'}</div>
                }
                </div>
                <div className='header-inner-div header-inner-div-width header-sector-cells'>
                {isSecterVisible ?
                    <><div className='secter-items'>S3&nbsp;</div><div className='number-of-cars'>{sectorTraffic.sec3}</div></>:
                    <div className='base-secter-font'>{isFinite(overallBest.sec3) ? toFixedIfNumber(overallBest.sec3, 3) : '-'}</div>
                }
                </div>
                {sectorLength === 4 && 
                    <div className='header-inner-div header-inner-div-width header-sector-cells'>
                        {isSecterVisible ?
                        <><div className='secter-items'>S4&nbsp;</div><div className='number-of-cars'>{sectorTraffic.sec4}</div></>:
                        <div className='base-secter-font'>{isFinite(overallBest.sec4) ? toFixedIfNumber(overallBest.sec4, 3) : '-'}</div>
                    }
                    </div>
                }
            </div>
            }

            return (
                <StyledHeaderUpperTableCell
                    align='center'
                    colSpan={id === 'sector' ? sectorLength : id === 'laptime' ? 1 : 2}
                    rowSpan={2}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {lowerHeader}
                </StyledHeaderUpperTableCell>
            )
        }
    
        const specialTableHeader = ['ots', 'laptime', 'sector']

        if (specialTableHeader.includes(columnId)) {
            if (columnId === 'ots' && raceSeries !== 'SF') return null

            return withUpperAndLowerHeader(columnId, columnIdAndName[columnId])
        } else if (columnId.includes('avg') || columnId.includes('deg')) {
            const delimeter = columnId.includes('avg') ? 'avg' : 'deg'

            const index = Number(columnId.split(delimeter)[1]) - 1

            let value = lapAverages[index]
            let onChangeHandler = (val) => lapAveragesHandler(index, val)

            if (columnId.includes('deg')) {
                value = deg[index]
                onChangeHandler = (val) => degHandler(index, val)
            }

            return (
                <LapValueSelectHeaderCell labelText={columnIdAndName[columnId]} value={value} onChangeHandler={onChangeHandler} currentLap={currentLap} provided={provided} />
            )
        }  else {
            return commonTableHeader(columnIdAndName[columnId])
        }
    }

    const renderTableBodyColumns = (row, index) => {
        // TODO: refactor code
        const createStyledTableCell = (text, key, align, style = {}, className = '') => (
            <StyledTableCell isLightMode={isLightMode} key={key} align={align} style={style} className={className} >
                {text}
            </StyledTableCell>
        )
        const columnMap = {
            follow: (
                props.tabletMode ? 
                    followCarNumber === '' ?
                        <StyledTableCell key={`follow-${index}`} align='right' onClick={() => followDataHandler(row)} className='race-data-clicker follow-parent cell-items'>
                            {followCarNumber === row.carno.replace('No.', '') ? <img className='follow-img' src={followImage} alt='Follow中' /> : <img className='follow-img' src={notFollowImage} alt='Follow' />}
                        </StyledTableCell>
                        :
                            followCarNumber === row.carno.replace('No.', '') ?
                                <StyledTableCell key={`follow-${index}`} align='right' onClick={() => followDataHandler(row)} className='race-data-clicker follow-parent cell-items'>
                                    {followCarNumber === row.carno.replace('No.', '') ? <img className='follow-img' src={followImage} alt='Follow中' /> : <img className='follow-img' src={notFollowImage} alt='Follow' />}
                                </StyledTableCell>
                                :
                                <StyledTableCell key={`rival-${index}`} align='right' onClick={() => rivalDataHandler(row)} className='race-data-clicker rival-parent cell-items'>
                                    {rivalCarNumbers.includes(row.carno.replace('No.', '')) ? <img className='rival-img' src={rivalImage} alt='ライバル車両マーク中' /> : <img className='rival-img' src={notRivalImage} alt='ライバル車両マーク' />}
                                </StyledTableCell>

                :
                    <StyledTableCell key={`follow-${index}`} align='right' onClick={() => followDataHandler(row)} className='race-data-clicker follow-parent cell-items'>
                        {followCarNumber === row.carno.replace('No.', '') ? <img className='follow-img' src={followImage} alt='Follow中' /> : <img className='follow-img' src={notFollowImage} alt='Follow' />}
                    </StyledTableCell>
            ),
            rival: (
                <StyledTableCell key={`rival-${index}`} align='right' onClick={() => rivalDataHandler(row)} className='race-data-clicker rival-parent cell-items'>
                    {rivalCarNumbers.includes(row.carno.replace('No.', '')) ? <img className='rival-img' src={rivalImage} alt='ライバル車両マーク中' /> : <img className='rival-img' src={notRivalImage} alt='ライバル車両マーク' />}
                </StyledTableCell>

            ),
            pos: (
                <StyledTableCell isLightMode={isLightMode} key={`pos-${index}`} align='right' className='cell-items position-cell' style={{textAlign: 'right' }} >
                    {showPosition(row)}
                </StyledTableCell>
            ),
            ots: raceSeries === 'SF' && (
                <Fragment key={`ots-${index}`}>
                    <StyledTableCell key={`otsLeft-${index}`} align='right' className={row.otsusing === '0' ? 'isUsingOts cell-items ots-td' : 'cell-items ots-td'}>
                        {showOtsLeft(row)}
                    </StyledTableCell>
                    <StyledTableCell key={`otsLimit-${index}`} align='right' className={row.otsusing === '0' ? 'isUsingOts cell-items' : 'cell-items'}>{row.otsLimit}</StyledTableCell>
                </Fragment>
            ),
            name: (
                <StyledTableCell key={`driverName-${index}`} align='right' onClick={() => personalDriverHistoryHandler((row.carno).split('.')[1], row.driverName)} className='race-data-clicker cell-items race-name' style={{  textAlign: 'left' }}>
                    {showNameAndNumber(row)}
                </StyledTableCell>
            ),
            team: (
                <StyledTableCell key={`team-${index}`} align='right' onClick={() => personalDriverHistoryHandler((row.carno).split('.')[1], row.driverName)} className='race-data-clicker cell-items' style={{ textAlign: 'center' }} >
                    <div style={{width: '150px', whiteSpace: 'nowrap', overflow: 'hidden'}}>{row.teamName}</div>
                </StyledTableCell>
            ),
            drType: createStyledTableCell(row.drType, `drType-${index}`, 'right', {}, 'cell-items'),
            class: (
                <StyledTableCell key={`class-${index}`} align='right' className='cell-items class-items'>
                    { ClassColorDisplay(row.carClass, 'table') }
                </StyledTableCell>
            ),
            e: createStyledTableCell(row.engine, `e-${index}`, 'right', {}, 'cell-items'),
            tire: createStyledTableCell(row.tire, `tire-${index}`, 'right', {}, 'cell-items'),
            lap: <StyledTableCell isLightMode={isLightMode} className='cell-items' key={`lap-${index}`} align='right'>{row.lap}</StyledTableCell>,
            gap: createStyledTableCell(toFixedIfNumber(row.gap, 3), `gap-${index}`, 'right', {}, 'cell-items'),
            diff: createStyledTableCell(toFixedIfNumber(row.diff, 3), `diff-${index}`, 'right', {}, 'cell-items'),
            laptime: createStyledTableCell(<TimeFormatter  seconds={row.laptime} />, `laptime-${index}`, 'right', {}, 'cell-items'),
            drivingTime: (
                <StyledTableCellSmall isLightMode={isLightMode} key={`driverTotalTime-${index}`} align={'right'} className={row.driverTotalTime >= 3600 ? 'race-data-driver-time-red cell-items' : 'race-data-driver-time-white cell-items'}>
                    <TimeFormatter seconds={row.driverTotalTime} decimalPlaces={0} />
                </StyledTableCellSmall>
            ),
            battle: (
                <StyledTableCellSmall key={`carNo-${index}`} align='right' className={`cell-items image-and-text ${row.hasOwnProperty('battleGroupClass') ? row.battleGroupClass : ''}`}>
                    {showBattle(row)}                
                </StyledTableCellSmall>
            ),
            sector: (
                <Fragment key={`sector-${index}`}>
                    <SectorTableCell isLightMode={isLightMode} key={`row1-${index}`} className='header-inner-div-width' sectorLength={ sectorLength } row={row} sectorNo='1' lapNo={row.lap} prevTime={row['prev_sec1'] || null} overallBest={overallBest} />
                    <SectorTableCell key={`row2-${index}`} className='header-inner-div-width' sectorLength={ sectorLength } row={row} sectorNo='2' lapNo={row.lap} prevTime={row['prev_sec2'] || null} overallBest={overallBest} />
                    <SectorTableCell key={`row3-${index}`} className='header-inner-div-width' sectorLength={ sectorLength } row={row} sectorNo='3' lapNo={row.lap} prevTime={row['prev_sec3'] || null} overallBest={overallBest} />
                    {sectorLength === 4 && (
                        <SectorTableCell key={`row4-${index}`} className='header-inner-div-width cell-items' sectorLength={ sectorLength } row={row} sectorNo='4' lapNo={row.lap} prevTime={row['prev_sec4'] || null} overallBest={overallBest} />
                    )}
                </Fragment>
            ),
            bestLap: toFixedIfNumber(row.bestLap, 0) ? (
                <StyledTableCell isLightMode={isLightMode} key={`bestLapTime-${index}`} align='right' className='cell-items bestLap-items '><TimeFormatter seconds={row.bestLaptime} /><strong className='best-lap-display'>{`${toFixedIfNumber(row.bestLap, 0)}`}</strong></StyledTableCell>
            ) : (
                <StyledTableCell key={`bestLapTime-${index}`} align='right'>-</StyledTableCell>
            ),
            pit: (
                <StyledTableCell key={`pit-${index}`} align='right' onClick={() => beforeAfterPitAvgHandler((row.carno).split('.')[1], row.driverName)} className='cell-items pit-cells' >{toFixedIfNumber(row.pit, 0)}</StyledTableCell>
            ),
            deg1: (
                <StyledTableCell key={`deg1-${index}`} align='right' className='cell-items'>{showDeg(row.carno, deg[0])}</StyledTableCell>
            ),
            deg2: (
                <StyledTableCell key={`deg2-${index}`} align='right' className='cell-items'>{showDeg(row.carno, deg[1])}</StyledTableCell>
            ),
            deg3: (
                <StyledTableCell key={`deg3-${index}`} align='right' className='cell-items'>{showDeg(row.carno, deg[2])}</StyledTableCell>
            ),
            deg4: (
                <StyledTableCell key={`deg4-${index}`} align='right' className='cell-items'>{showDeg(row.carno, deg[3])}</StyledTableCell>
            ),
            avg1: (
                <StyledTableCell key={`avg1-${index}`} align='right' className='image-and-text-average cell-items'>{showAverage(row.carno, lapAverages[0])}</StyledTableCell>
            ),
            avg2: (
                <StyledTableCell key={`avg2-${index}`} align='right' className='image-and-text-average cell-items'>{showAverage(row.carno, lapAverages[1])}</StyledTableCell>
            ),
            avg3: (
                <StyledTableCell key={`avg3-${index}`} align='right' className='image-and-text-average cell-items'>{showAverage(row.carno, lapAverages[2])}</StyledTableCell>
            ),
            avg4: (
                <StyledTableCell key={`avg4-${index}`} align='right' className='image-and-text-average cell-items'>{showAverage(row.carno, lapAverages[3])}</StyledTableCell>
            )
        }



        return (
            <TableRow
                key={index + 'table'}
                sx={{
                    height:'3vh',
                    border: followCarNumber === row.carno.replace('No.', '') ? 'solid 4px #BC1414' : '',
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                    background: rivalCarNumbers.includes(row.carno.replace('No.', '')) ? 'rgba(255, 241, 119, 0.2)' : '',
                    padding: '6px 16px',
                    backgroundColor: isLightMode ? (row.pos % 2 === 0 ? 'rgba(223, 223, 223, 1)' : 'rgba(255,255,255,1)') : (row.pos % 2 === 0 ? '#141414' : '#3C474C')
                }}
                className={`${row.isPitIn ? 'isInPit' : ''} ${row.hasOwnProperty('battleGroupClass') ? row.battleGroupClass : ''} ${index === 0 ? 'first-row live-table-row' : 'live-table-row'} ${highlightUpdatedRows.includes(row.carno) ? 'isUpdated' : ''}`}
            >
                {columnOrder.map((columnId) => {
                    const columnElement = columnMap[columnId]
                    return columnElement || null
                })}
            </TableRow>
        )     
    }


    const handleSelectChange = (event) => {
        setMobileTableContent(event.target.value);
    }

    
    return (
        <>
            {(props.tabletMode || props.mobileMode) && (
                <div className={`${styles} mobile-header`}>
                    <div className='select-box'><span className='select-dec'></span><span className='select-text'>{mobileTableContent}</span></div>
                    <div className='select-contents'>
                        <select className='lap-history-select' onChange={handleSelectChange} value={mobileTableContent}>
                            <option value='LAPS'>LAPS</option>
                            <option value='SECTORS'>SECTORS</option>
                            <option value='PITINS'>PIT INS</option>
                            <option value='TYREDEG'>TYRE DEG</option>
                            <option value='TYREAVG'>TYRE AVG</option>
                        </select>
                    </div>
                </div>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
                <TableContainer className={`${styles}`} ref={tableContainerRef} onScroll={ handleScroll}>
                    <Table className='live-table'>
                        <TableHead className='live-table-header'>
                            <Droppable droppableId='first-row' direction='horizontal'>
                                {(provided) => (
                                    <TableRow key='row_header' {...provided.droppableProps} ref={provided.innerRef}>
                                        {columnOrder && columnOrder.length > 0 && columnOrder.map((columnId, index) => {
                                            return (
                                                <Draggable key={columnId} draggableId={columnId} index={index}>
                                                    {(provided) => (
                                                        renderTableHeader(columnId, provided)
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                        {provided.placeholder}
                                        {provided.placeholder}
                                    </TableRow>
                                )}
                            </Droppable>
                        </TableHead>
                        <TableBody>                          
                            {Array.isArray(tableDataWithBattle[0]) && tableDataWithBattle[0].map((row, index) => (
                                row.isVisible && columnOrder !== undefined && renderTableBodyColumns(row, index)                              
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DragDropContext>
        <ModalComponent
            open={ openModal }
            onClose={ handleCloseModal }
            setOpenModal={ setOpenModal }
            modalType={ modalType }
            driverInfo={ driverInfo }
            raceSeries={ raceSeries }
        />
    </>
    )
}