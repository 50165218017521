import React from 'react'

import '../css/header.css'

import { useTranslation } from 'react-i18next'

const Header = (props) => {
    const { t } = useTranslation(['core'])
    const isSmallHeader = props.isSmallHeader

    return (
        <header>
            <div className='core-logo-container' onClick={ () => {
                window.location.href = '/top'
            }}></div>
            { !isSmallHeader && <span className='Core-display-sabtit'>{t('header.optimalSetupSearchTool')}</span> }
        </header>
    )
}

export default Header