import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

const style = {
    width: '150px',
    textAlign: 'center'
}

const MileageTyreSheetHeader = ({ setTyreSheetHeaderTable, tyreSheetInfo, eventId, selectedRaceDetailId, selectedCarNumber }) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [ enableEdit, setEnableEdit] = useState(false)
    
    useEffect(() => {
        if (selectedRaceDetailId && selectedCarNumber) {
            setEnableEdit(false)
            getEventWithCircuit(eventId)
                .then(json => {
                    const circuitId = parseInt(json.CIRCUIT_ID)

                    document.getElementById('setCircuitName').innerText = json.CIRCUIT.NAME
                    document.getElementById('setCiruitLength').innerText = json.CIRCUIT.TRACK_LENGTH + 'km'
                    setTyreSheetHeaderTable(prev => ({
                        ...prev,
                        EVENT: json.EVENT_NAME,
                        CIRCUIT_ID: circuitId,
                        EVENT_ID: eventId,
                        RACE_DETAIL_ID: selectedRaceDetailId,
                        CIRCUITNAME: json.CIRCUIT.NAME,
                        LENGTH: json.CIRCUIT.TRACK_LENGTH,
                        CAR_NUMBER: selectedCarNumber
                    }))
                })
                .catch(error => {
                    alert(t('pleaseContactAdmin', {ns: 'general'}))
                })
        }
    }, [selectedRaceDetailId, selectedCarNumber])

    useEffect(() => {
        if (tyreSheetInfo.length > 0 && !enableEdit) {
            document.getElementById('chassisTB').value = tyreSheetInfo[0].CHASSIS
            document.getElementById('engineerTB').value = tyreSheetInfo[0].ENGINEER
            document.getElementById('no1MechanicTB').value = tyreSheetInfo[0].NO1_MECHANIC
            setEnableEdit(true)
        }
    }, [tyreSheetInfo])

    const onChangeHandlerChassis = (e) => {
        const chassisVal = e.target.value
        setTyreSheetHeaderTable(prev => ({
            ...prev,
            CHASSIS: chassisVal
        }))
    }

    const onChangeHandlerEngineer = (e) => {
        const engineerVal = e.target.value
        setTyreSheetHeaderTable(prev => ({
            ...prev,
            ENGINEER: engineerVal
        }))
    }
    
    const onChangeHandlerNo1Mechanic = (e) => {
        const mechanicVal = e.target.value
        setTyreSheetHeaderTable(prev => ({
            ...prev,
            NO1_MECHANIC: mechanicVal
        }))
    }

    const onBlurHandler = (key) => async (e) => {
        if (tyreSheetInfo.length > 0) {
            const updatedObj = {
                [key]: e.target.value
            }
            const conditionObj = {
                CAR_NUMBER: tyreSheetInfo[0].CAR_NUMBER,
                CIRCUIT_ID: tyreSheetInfo[0].CIRCUIT_ID,
                EVENT_ID: tyreSheetInfo[0].EVENT_ID,
                RACE_DETAIL_ID: tyreSheetInfo[0].RACE_DETAIL_ID,
                DRIVER_NAME: tyreSheetInfo[0].DRIVER_NAME
            }
            const obj = {
                UPDATEDVALUE: updatedObj,
                CONDITIONS: conditionObj
            }
    
            try {
                const result = await updateCarTireHistory(obj)
                if (result.status === 200) {
                    alert(key + t('general.wasChanged'))
                }
            } catch {
                alert('Unable to perform update')
            }
        }
    }

    return (
        <>
            <table border='1'>
                <tbody>
                    <tr>
                        <td width={ style.width } textalign={ style.textAlign }>Circuit</td>
                        <td width={ style.width } textalign={ style.textAlign } id='setCircuitName'></td>
                        <td width={ style.width } textalign={ style.textAlign }>length</td>
                        <td width={ style.width } textalign={ style.textAlign } id='setCiruitLength'></td>
                    </tr>
                    <tr>
                        <td width={ style.width } textalign={ style.textAlign }>Chassis</td>
                        <td width={ style.width } textalign={ style.textAlign }>
                            <input type='text' className='disableInputTBHeader' id='chassisTB' onChange={onChangeHandlerChassis} onBlur={onBlurHandler('CHASSIS')}></input>
                        </td>
                        <td width={ style.width } textalign={ style.textAlign }>Engineer</td>
                        <td width={ style.width } textalign={ style.textAlign }>
                            <input type='text' className='disableInputTBHeader' id='engineerTB' onChange={onChangeHandlerEngineer} onBlur={onBlurHandler('ENGINEER')}></input>
                        </td>
                        <td width={ style.width } textalign={ style.textAlign }>No1 Mechanic</td>
                        <td width={ style.width } textalign={ style.textAlign }>
                            <input type='text' className='disableInputTBHeader' id='no1MechanicTB' onChange={onChangeHandlerNo1Mechanic} onBlur={onBlurHandler('NO1_MECHANIC')}></input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default MileageTyreSheetHeader

function getEventWithCircuit(eventId) {
    return new Promise ((resolve, reject) => {
        fetch(`/api/event/getOneWithCircuit/${eventId}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}

function updateCarTireHistory(obj) {
    return new Promise ((resolve, reject) => {
        fetch('/api/carTireHistory/updateCarTireHistoryValues', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj)
            })
            .then(response => response.json())
            .then(json => {
                resolve(json)
            })
            .catch((error) => {
                reject(error)
            })
    })
}