import { useEffect } from 'react'

import { useLiveData } from '../../DataContext'
import { groupLapDataByCar } from './Util'

import upImage from '../image/up.png'
import downImage from '../image/down.png'

const SetDiffGapPos = ({ pitRoadTime, pitStopTimeByRefueling, pitStopTimeByWork, tireWarmUpLossTime, setTableData, latestDataByCar, carClasses }) => {
    const { raceDetailId, tableData, lapData, sectorData, followCarNumber, displayFollowAndRivals, isRace, predictPitInMode, projectPositionMode, sectorLength } = useLiveData()

    useEffect(() => {
        const intervalId = setInterval(() => {
            tableSetDiffGapPos(lapData, sectorData);
        }, 2000);
    
        return () => clearInterval(intervalId);
    }, [followCarNumber, displayFollowAndRivals, predictPitInMode, projectPositionMode, carClasses]);

    async function tableSetDiffGapPos() {
        let gapDiffPos = []
        let referenceIndex = 0
        let sortedTableData = []
        let rankedDataArray = []
        let carsNotIncluded = []

        if (!isRace) {
            rankedDataArray = calculateRankingWithDiffForFPandQF(latestDataByCar)
        } else {
            carsNotIncluded = tableData.filter(car => !car.isVisible).map(car => Number(car.carno.replace('No.', '')))
            const gapDiffPosFromApi = await getGapAndDiffByRaceDetailId(raceDetailId, carsNotIncluded)
            gapDiffPosFromApi.forEach((data) => {
                rankedDataArray.push({ carNumber: data.CAR_NUMBER,
                                       latestLapNumber: data.LATEST_LAP_NUMBER,
                                       latestSectorNumber: data.LATEST_SECTOR_NUMBER,
                                       latestSectorLapNumber: data?.LATEST_SECTOR_LAP_NUMBER,
                                       gap: data.GAP,
                                       diff: data.DIFF,
                                       pos: Number(data.POS) })
            })
        }

        for (let rankedData of rankedDataArray) {
            sortedTableData.push(tableData.find(car => parseInt(car.carno.replace('No.', '')) === rankedData.carNumber))
        }

        referenceIndex = followCarNumber && !carsNotIncluded.includes(Number(followCarNumber)) ? sortedTableData.findIndex(car => parseInt(car.carno.replace('No.', '')) === parseInt(followCarNumber)) : 0
        let diff
        rankedDataArray.forEach((rankedData, index) => {
            const originalDiff = rankedData.diff
            if (referenceIndex === index) {
                diff = 0                
            } else {
                if (index > referenceIndex) {
                    diff = rankedData.diff
                } else {
                    diff = Number(rankedDataArray[index + 1].diff) ? -rankedDataArray[index + 1].diff : `-${rankedDataArray[index + 1].diff}`
                }
            }

            gapDiffPos.push({
                carNumber: rankedData.carNumber,
                diff,
                pos: rankedData.pos,
                latestLapNumber: rankedData.latestLapNumber,
                latestSectorNumber: rankedData.latestSectorNumber,
                originalDiff
            })
        })

        let diffSum = 0
        gapDiffPos.forEach((data, index) => {
            if (referenceIndex === index) {
                data.gap = 0
            } else if (index > referenceIndex) {
                if (isNaN(Number(data?.diff)) || isNaN(Number(gapDiffPos[referenceIndex]?.diff))) {
                    data.gap = `${gapDiffPos[referenceIndex].latestLapNumber - data.latestLapNumber}LAP`
                } else {
                    diffSum += data.diff
                    data.gap = diffSum
                }
            } else {
                if (isNaN(Number(data.originalDiff)) || isNaN(Number(gapDiffPos[referenceIndex].originalDiff))) {
                    data.gap = `${gapDiffPos[referenceIndex].latestLapNumber - data.latestLapNumber}LAP`
                } else {
                    data.gap = gapDiffPos.slice(index, referenceIndex + 1).reduce((sum, item) => sum + item.diff, 0)
                }
            }
        })

        // 裏の順位を表示
        if (projectPositionMode) {
            const notPitInCarNumbers = getNotPitInCarNumbers()
            gapDiffPos = projectPosition(gapDiffPos, referenceIndex, notPitInCarNumbers, (pitRoadTime + pitStopTimeByRefueling + pitStopTimeByWork), tireWarmUpLossTime)
        }

        // ピットインした場合の予測
        if (predictPitInMode && !projectPositionMode) {
            gapDiffPos = predictPitIn(gapDiffPos, referenceIndex, (pitRoadTime + pitStopTimeByRefueling + pitStopTimeByWork))
        }

        setTableData((prev) => {
            return prev.map((carData) => {
                let carNumber = parseInt(carData.carno.replace('No.', ''))
                let data = gapDiffPos.find((data) => data.carNumber === carNumber)
                if (data) {
                    let posChange
                    if (carData.pos === data.pos) {
                        posChange = carData.posChange
                    } else if (carData.pos > data.pos) {
                        posChange = upImage
                    } else if (carData.pos < data.pos) {
                        posChange = downImage
                    }

                    if (!carData.posChangeLapNumber || (data.latestSectorNumber === sectorLength && data.latestLapNumber > carData.posChangeLapNumber)) {
                        if (carData.pos === data.pos) {
                            posChange = null
                        }
                        carData.posChangeLapNumber = data.latestLapNumber
                    }

                    carData.posChange = posChange
                    carData.lap = data.latestLapNumber
                    carData.diff = data.diff === 0 ? data.diff : data.diff || ''
                    carData.gap = data.gap === 0 ? data.gap : data.gap || ''
                    carData.pos = data.pos
                }

                return carData
            })
        })
    }

    function getNotPitInCarNumbers() {
        const notPitInCarNumbers = []

        const lapDataByCar = groupLapDataByCar(lapData)

        for (const [carNumber, lapData] of Object.entries(lapDataByCar)) {
            let latestPitLap = 0
            let pitCount = 0

            lapData
                .sort((a, b) => a.LAP_NUMBER - b.LAP_NUMBER)
                .forEach(lap => {
                    if (lap.PIT_STOP_TIME > pitCount) {
                        latestPitLap = lap.LAP_NUMBER
                        pitCount++
                    }
                })

            if (pitCount === 0) {
                notPitInCarNumbers.push(carNumber)
            } else if (latestPitLap < 10) {
                notPitInCarNumbers.push(carNumber)
            }
        }
        return notPitInCarNumbers
    }

    function projectPosition(gapDiffPos, referenceIndex, notPitInCarNumbers, pitLossTime, tireWarmUpLossTime) {
        const foundData = gapDiffPos.find(data => data?.pos === 1)
        const currentLap = foundData !== undefined ? foundData.lap : 1
        
        for (const carNumber of notPitInCarNumbers) {
            const notPitInCarIndex = carNumber ? gapDiffPos.findIndex(data => data.carNumber === parseInt(carNumber)) : 0
            if (notPitInCarIndex !== -1) {
                gapDiffPos[notPitInCarIndex].gap += (pitLossTime + tireWarmUpLossTime)
                if (currentLap - gapDiffPos[notPitInCarIndex + 1]?.lap > 2 || currentLap - gapDiffPos[notPitInCarIndex]?.lap > 2) {
                    continue
                }
                let tempGapDiffPos = gapDiffPos.filter(item => item.carNumber !== gapDiffPos[notPitInCarIndex].carNumber)
                let newIndex = tempGapDiffPos.findIndex(item => item.gap >= gapDiffPos[notPitInCarIndex].gap)
                if (newIndex === -1) {
                    newIndex = tempGapDiffPos.length
                }
                tempGapDiffPos.splice(newIndex, 0, gapDiffPos[notPitInCarIndex])
                gapDiffPos = tempGapDiffPos            
            }
        }

        const followCarGap = gapDiffPos[referenceIndex]?.gap
        for (let i = 0; i < gapDiffPos.length; i++) {
            gapDiffPos[i].pos = i + 1
            gapDiffPos[i].gap -= followCarGap
        }

        const newReferenceIndex = followCarNumber ? gapDiffPos.findIndex(data => data.carNumber === parseInt(followCarNumber)) : 0
        gapDiffPos.forEach((data, index) => {
            if (newReferenceIndex=== index) {
                data.diff = 0
            } else if (index > newReferenceIndex) {
                data.diff = data.gap - gapDiffPos[index - 1].gap
            } else {
                data.diff = data.gap - gapDiffPos[index + 1].gap
            }
        })

        return gapDiffPos 
    }

    function calculateRankingsForFPandQF(latestDataByCar) {
        const filteredTableData = tableData.filter(carData => carData.isVisible)

        const sortedTableData = filteredTableData.sort((a, b) => {
            const bestLapTimeA = parseFloat(a.bestLaptime)
            const bestLapTimeB = parseFloat(b.bestLaptime)
            if (bestLapTimeA && bestLapTimeB) {
                return bestLapTimeA - bestLapTimeB
            } else if (!bestLapTimeA && bestLapTimeB) {
                return 1
            } else if (bestLapTimeA && !bestLapTimeB) {
                return -1
            }
        })
    
        const latestDataArray = []
        sortedTableData.forEach((carData, index) => {
            const latestData = latestDataByCar[carData.carno.replace('No.', '')]
            if (latestData) {
                latestData.pos = index + 1
                latestData.bestLapTime = carData.bestLaptime
                latestData.latestLapNumber = carData.lap
                latestDataArray.push(latestData)
            }
        })
    
        return latestDataArray
    }
    
    function calculateRankingWithDiffForFPandQF(latestDataByCar) {
        const rankedDataArray = calculateRankingsForFPandQF(latestDataByCar)
    
        if (rankedDataArray.length) {
            rankedDataArray[0].diff = 0
        }
    
        // diffを計算
        for (let i = 1; i < rankedDataArray.length; i++) {
            let leadCar = rankedDataArray[i-1]
            let currentCar = rankedDataArray[i]
    
            currentCar.diff = currentCar.bestLapTime - leadCar.bestLapTime
        }
    
        return rankedDataArray
    }

    function predictPitIn(gapDiffPos, referenceIndex, pitLossTime){
        if (!gapDiffPos[referenceIndex]) return gapDiffPos

        gapDiffPos[referenceIndex].gap += pitLossTime

        let tempGapDiffPos = gapDiffPos.filter(item => item.carNumber !== gapDiffPos[referenceIndex].carNumber)
        let newIndex = tempGapDiffPos.findIndex(item => item.gap >= gapDiffPos[referenceIndex].gap)
        if(newIndex === -1){
            newIndex = tempGapDiffPos.length
        }
        tempGapDiffPos.splice(newIndex, 0, gapDiffPos[referenceIndex])
        gapDiffPos = tempGapDiffPos

        for (let i = 0; i < gapDiffPos.length; i++) {
            gapDiffPos[i].pos = i + 1
            gapDiffPos[i].gap -= pitLossTime
        }

        gapDiffPos.forEach((data, index) => {
            if (newIndex === index) {
                data.diff = 0
            } else if (index > newIndex) {
                data.diff = data.gap - gapDiffPos[index - 1].gap
            } else {
                data.diff = data.gap - gapDiffPos[index + 1].gap
            }
        })

        return gapDiffPos
    }
}

export default SetDiffGapPos

function getGapAndDiffByRaceDetailId(raceDetailId, carsNotIncluded) {
    return new Promise((resolve, reject) => {
        if (!raceDetailId) {
            return resolve([])
        }

        const carsNotIncludedParams = carsNotIncluded.sort((a, b) => a - b).map(car => `carsNotIncluded[]=${car}`).join('&');

        fetch(`/api/race/getGapAndDiffByRaceDetailId/${raceDetailId}?${carsNotIncludedParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => {
            if (!res.ok) {
                console.error(`Request failed with status: ${res.status}`)
                return resolve([])
            }
            return res.json()
        })
        .then((json) => {
            if (json && json.data) {
                resolve(json.data)
            } else {
                console.error('Invalid response format');
                resolve([])
            }
        })
        .catch((error) => {
            resolve([])
        })
    })
}