import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions } from '@mui/material'

import { useTranslation } from 'react-i18next'

const TimeSeriesModalCorrelation = (props) => {
    const { t } = useTranslation(['core'])

    const [ userEmail, setUserEmail ] = useState('')
    const [ selectedTimeSeries, setSelectedTimeSeries ] = useState([])
    const [ offset, setOffset ] = useState(0)

    const timeSeriesList = props.timeSeriesList

    const handleCloseModal = () => {
        props.setOpenModal(false)
    }

    const startDataHandler = () => {
        if (!userEmail) {
            return alert(t('timeSeriesModalCorrelation.pleaseEnterEmail'))
        } else if (selectedTimeSeries.length === 0) {
            return alert(t('timeSeriesModalCorrelation.pleaseSelectData'))
        } else if (!offset) {
            return alert(t('timeSeriesModalCorrelation.pleaseEnterOffset'))
        }

        if (!startAutoCorrelation()) {
            return alert(t('timeSeriesModalCorrelation.autoCorrelationFailed'))
        }
    
        alert(t('timeSeriesModalCorrelation.autoCorrelationSuccess', { userEmail: userEmail }))
        props.onClose()
        handleCloseModal()
    }

    const handleEmailChange = (e) => {
        setUserEmail(e.target.value)
    }

    const handleOffsetChange = (e) => {
        setOffset(e.target.value)
    }

    const handleCheckboxChange = (e) => {
        const targetId = Number(e.target.dataset.id)
        const checked = e.target.checked

        const targetData = timeSeriesList.find(data => data.id === targetId)
        targetData.checked = checked

        setSelectedTimeSeries(prevData => {
            if (checked) {
                return [...prevData, targetData]
            } else {
                return prevData.filter(data => data.id !== targetId)
            }
        })
    }

    const startAutoCorrelation = () => {
        console.log('email', userEmail)
        console.log('selectedTimeSeries', selectedTimeSeries)
        console.log('offset', offset)

        return new Promise((resolve, reject) => {
            fetch('/core/api/autoCorrelation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: userEmail,
                    offset
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.status === 200) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    const dialogContentStyle = {
        width: 'auto'
    }

    return (
        <Dialog open={ props.open } onClose={ handleCloseModal } maxWidth='md' fullWidth={ true } >
            <div className='top-dialog core'>
                <div className='dialog-title long'>Auto Correlation</div>
                <DialogActions>
                    <div onClick={ handleCloseModal } className='modal-exit'>✕</div>
                </DialogActions>
            </div>
            <DialogContent className='custom-modal correlation-modal core' style={dialogContentStyle}>
                <div className='container-border'>
                    <div className='input-container'>
                        <div className='input-content'>
                            <div className='input-title-div'>Email</div>
                            <div className='input-div'>
                                <input type='text' className='input-text' onChange={ handleEmailChange }/>
                            </div>
                        </div>
                    </div>
                    <div className='time-series-container-modal'>
                        { timeSeriesList.map((data, i) => {
                            return ( data.filePath && (
                                <div className='data-file-content-modal' key={ data.id }>
                                    <div className='data-file-checkbox-div'>
                                        <input type='checkbox' className='data-file-checkbox' id={ `cb-${data.id}` } data-id={ data.id } onChange={ handleCheckboxChange }/>
                                        <label className='data-file-checkbox-label'>{ data.fileName }</label>
                                    </div>
                                </div>
                            ))
                        })}
                    </div>
                    <div className='input-container'>
                        <div className='input-content'>
                            <div className='input-title-div'>Offset</div>
                            <div className='input-div'>
                                <input type='number' className='input-text' onChange={ handleOffsetChange }/>m
                            </div>
                        </div>
                    </div>
                </div>
                <div className='modal-button-container'>
                    <div className='modal-button' onClick={ startDataHandler }>Start</div>
                    <div className='modal-button' onClick={ handleCloseModal }>Cancel</div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default TimeSeriesModalCorrelation