
import { useState } from 'react'

const EditDatabaseTable = (props) => {
    
    const isSectorData = props.selectedDatabaseTableName === 'SECTOR_DATA'
    const openModal = (carNumber, lapNumber, sectorNumber) => {
        if (props.handleModalOpen) {
            props.handleModalOpen({
                carNumber: carNumber,
                lapNumber: lapNumber,
                sectorNumber: sectorNumber
            })
        }
    }  

    if (!props.selectedDatabaseTableName) {
        return null
    }

    const determineColor = (missingDatas, lapNumber, sectorNumber, isSector) => {
        let color = 'inherit'       
        if (isSector) {
            const isMissingData = missingDatas.some(missingData => 
                missingData.missingSectorsLapNumber === lapNumber && 
                missingData.missingSectorNumbers && 
                missingData.missingSectorNumbers.includes(sectorNumber)
            )
            if (isMissingData) {
                color = '#fa6a6a'
            }
        } else {
            
            const isMissingData = missingDatas.some(missingData => 
                missingData.missingLapNumbers && 
                missingData.missingLapNumbers.includes(lapNumber)
            )
            if (isMissingData) {
                color = '#fa6a6a'
            }
        }
    
        return color
    }

    const keyColumnHeaders = props.keyColumns.map((keyColumn, index) => {
        const style = isSectorData ? {...columnHeaderStyle, left: 0 + 150 * index} : columnHeaderStyle
        return <th key={index} style={style}>{keyColumn}</th>
    })

    const carNumberHeaders = props.tableCarNumbers.map((tableCarNumber, headerIndex) => {
        const isMissingCarNumber = props.missingDatas.some(missingData => Number(missingData.carNumber) === tableCarNumber)
        const thStyle = isMissingCarNumber ? {...carNumberHeaderStyle, color: '#fa6a6a'} : carNumberHeaderStyle
        return <th key={headerIndex} style={thStyle}>{tableCarNumber}</th>
    })

    

    const createCellStyle = (isSector, lapNumber, sectorNumber) => ({
        ...stickyColumnStyle,
        left: isSector ? '150px' : 0,
        color: determineColor(props.missingDatas, lapNumber, sectorNumber, isSector)
    })

    const createCell = (carNumber, lapNumber, sectorNumber, isSectorData, headerIndex) => {
        
        const cellData = isSectorData ? props.cells.find(cell => cell.carNumber === carNumber 
                                                                && cell.lapNumber === lapNumber
                                                                && cell.sectorNumber === sectorNumber) || {}
                                        : props.cells.find(cell => cell.carNumber === carNumber
                                                                && cell.lapNumber === lapNumber) || {}
    

         
        const backgroundColor = props.determineBackgroundColor(
            carNumber, lapNumber, sectorNumber, props.missingDatas
        )

        return (
            <td key={headerIndex}>
                <button
                    onClick={(e) => {
                        e.stopPropagation()
                        openModal(carNumber, lapNumber, sectorNumber)
                    }}
                    style={{
                        color: cellStyle(cellData.value, props.threshold),
                        textAlign: 'center',
                        fontSize: '16px',
                        border: 'none',
                        backgroundColor: backgroundColor,
                        width: '100%', 
                        height: '100%',
                        minWidth: '100px',
                        minHeight: '50px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {cellData.value !== undefined && cellData.value !== null ? cellData.value : '　 '}
                </button>
            </td>
        )
    }

    const bodyRows = props.lapNumbers.map((lapNumber, lapIndex) => (
        (isSectorData ? props.sectorNumbers : [null]).map((sectorNumber, sectorIndex) => {
            const rowKey = `${lapIndex}_${sectorIndex}_${sectorNumber}`
            const lapCellStyle = createCellStyle(false, lapNumber, null)
            const sectorCellStyle = createCellStyle(true, lapNumber, sectorNumber)

            return (
                <tr style={{ textAlign: 'center' }} key={rowKey}>
                    <td style={lapCellStyle}>
                        {lapNumber}
                    </td>
                    {isSectorData && <td style={sectorCellStyle}>{sectorNumber}</td>}
                    {props.tableCarNumbers.map((tableCarNumber, headerIndex) => 
                        createCell(tableCarNumber, lapNumber, sectorNumber, isSectorData, headerIndex)
                    )}
                </tr>
            )
        })
    ))

   
    return (
        <>
            <div style={{ overflowX: 'auto', overflowY: 'auto', maxWidth: '100%', maxHeight: '600px', fontSize:'16px'}}>
                <table border='1'>
                    <thead>
                        <tr>
                            <th style={headerStyle} colSpan={props.tableCarNumbers.length + props.keyColumns.length}>
                                CarNumber
                            </th>
                        </tr>
                        <tr>
                            {keyColumnHeaders}
                            {carNumberHeaders}
                        </tr>
                    </thead>
                    <tbody>
                        {bodyRows}
                    </tbody>
                </table>
            </div>
        </>
    )
}
    
export default EditDatabaseTable

const headerStyle = {
    textAlign: 'left',
    position: 'sticky',
    top: 0,
    backgroundColor: '#242424',
    zIndex: 150,
    minHeight: '30px',
    maxHeight: '30px'
}

const carNumberHeaderStyle = {
    textAlign: 'center',
    position: 'sticky',
    top: '27px',
    backgroundColor: '#242424',
    zIndex: 50,
    minHeight: '30px',
    maxHeight: '30px'
}

const columnHeaderStyle = {
    position: 'sticky',
    top: '27px',
    left: 0, 
    zIndex: 200,
    backgroundColor: '#242424', 
    minWidth: '150px', 
    maxWidth: '150px', 
    minHeight: '30px',
    maxHeight: '30px'
}

const stickyColumnStyle = {
    position: 'sticky',
    left: 0, 
    zIndex: 100, 
    backgroundColor: '#242424',
    minWidth: '150px',
    maxWidth: '150px', 
    minHeight: '30px',
    maxHeight: '30px'
}



const cellStyle = (value, threshold) => {
    if (typeof value === 'undefined' || value === null || threshold.length !== 2) {
        return 'black' 
    } else if (value < threshold[0] || value > threshold[1]) {
        return 'red'
    } else {
        return 'black'
    }
}
