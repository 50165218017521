import React from "react"

const SetupHistoryTable = ({ setupData, vehicleMappingItems, setDefaultSetup, handleLapChart, handleCollection }) => {
    if (!setupData || !vehicleMappingItems) return null
    return (
        <table className='setup-history-table'>
            <tbody>
                {
                    vehicleMappingItems.map((vehicleMappingItem, index) => (
                        <tr key={index}>
                            <td>
                                {vehicleMappingItem.item.name}
                            </td>
                            {setupData.map((inner) => {
                                return inner.setup_items.map((data, dataIndex) => {
                                    if (data.vehicle_item_mapping_id === vehicleMappingItem.item.id) {
                                        return (
                                            <td key={dataIndex}>
                                                {data.value}
                                            </td>
                                        )
                                    }
                                    return null
                                })
                            })}
                        </tr>
                    ))
                }
            </tbody>
            <tbody>
                <tr>
                    <td>
                        
                    </td>
                    {setupData.map((inner, index) => {
                        return (
                            <td key={inner.id}>
                                <button onClick={() => setDefaultSetup(inner.id)}>Defaultモデルで最適化</button>
                                <button onClick={() => handleCollection(inner.id)}>コリレーションモデルで最適化</button>
                                <button onClick={() => handleLapChart(inner.id)}>Lap Chart</button>
                            </td>
                        )
                    })}
                </tr>
            </tbody>
        </table>
    )
}

export default SetupHistoryTable