import { StyledHeaderUpperTableCell } from './TableStyle'

const LapValueSelectHeaderCell = ({ 
  labelText, 
  value, 
  onChangeHandler, 
  currentLap, 
  provided 
}) => (
  <StyledHeaderUpperTableCell
    align='center'
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    <div className='header-div'>
      <div className='header-inner-div'>
        <div>{labelText}</div>
        <select
            value={value}
            onChange={(event) => onChangeHandler(Number(event.target.value))}
            className='average-selector'
        >
            {Array.from({ length: 99 }, (_, lapIndex) => {
                const lapValue = lapIndex + 2
                if (lapValue <= 10 || lapValue === 15 || (lapValue % 5 === 0 && lapValue <= currentLap)) {
                    return (
                        <option key={lapValue} value={lapValue}>
                            {lapValue}
                        </option>
                    )
                }
                return null
            })}
        </select>
      </div>
    </div>
  </StyledHeaderUpperTableCell>
)

export default LapValueSelectHeaderCell