const groupByModel = (shape, data, onnxModels) => {
    const tempOnnxObjectResult = []

    const onnxModelsLength = onnxModels.length
    for (let i = 0; i < onnxModelsLength; i++) {
        tempOnnxObjectResult[i] = { name: onnxModels[i], value: [] }

        for (let j = 0; j <= shape; j++) {
            const index = i + onnxModels.length * j
            if (data[index]) tempOnnxObjectResult[i].value.push(data[index])
        }
    }

    return tempOnnxObjectResult
}

export default groupByModel