import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogActions } from '@mui/material'
import * as mat4js from 'mat-for-js'

import { useTranslation } from 'react-i18next'

const TimeSeriesModalUpload = (props) => {
    const { t } = useTranslation(['core', 'general'])

    const emptyFileTypeSettings = {
        keys: {
            distance: '',
            time: '',
            velocity: '',
            accLat: '',
            accLong: ''
        },
        extension: ''
    }

    const emptyAddedDataInfo = {
        fileName: '',
        fileType: '',
        filePath: '',
        color: '#000000',
        distance: '',
        time: '',
        velocity: '',
        accLat: '',
        accLong: '',
        visible: true,
        channelNameRow: '',
        firstDataRow: '',
        extension: '',
        channelNameList: [],
    }

    const [ addedDataInfo, setAddedDataInfo ] = useState(emptyAddedDataInfo)
    const [ fileTypeSettings, setFileTypeSettings ] = useState(emptyFileTypeSettings)
    const [ timeSeriesData, setTimeSeriesData ] = useState(null)

    const fileTypeOptions = [ 'SF', 'ST86(AIM)', 'ST86(Windarab)', 'DST' ]

    useEffect(() => {
        if (!timeSeriesData || !addedDataInfo.channelNameRow || addedDataInfo.channelNameRow < 0) return

        const headers = timeSeriesData[addedDataInfo.channelNameRow - 1].split(/,/)
        headers.pop()
        const channelNames = headers.map(header => header.replace(/"/g, ''))
        setAddedDataInfo((prevData) => ({
            ...prevData,
            channelNameList: channelNames
        }))
    }, [addedDataInfo.channelNameRow, timeSeriesData])

    const updateFileTypeSettings = (fileType, extension = null) => {
        const settings = {
            'Simulation': {
                keys: { distance: 'Distance', time: 'Time', velocity: 'Velocity', accLat: 'ALAT', accLong: 'ALongitudinal' },
                extension: 'mat'
            },
            'SF': {
                keys: { distance: 'Distance', time: 'Time', velocity: 'Velocity', accLat: 'ALAT', accLong: 'ALongitudinal' },
                extension: 'mat'
            },
            'ST86(AIM)': {
                keys: { distance: 'Distance', time: 'Time', velocity: 'GPS_Speed', accLat: 'GPS_LatAcc', accLong: 'GPS_LonAcc' },
                extension: 'csv'
            },
            'ST86(Windarab)': {
                keys: { distance: 'xdist    [   m]', time: 'xtime    [   s]', velocity: 'gps_speed[km/h]', accLat: 'Gy       [   g]', accLong: 'Gx       [   g]' },
                extension: 'txt'
            },
            'DST': {
                keys: { distance: 'Distance', time: 'Time', velocity: 'Velocity', accLat: 'ALAT', accLong: 'ALongitudinal' },
                extension: 'mat'
            }
        }

        let setting

        if (!fileType && extension) {
            setting = Object.values(settings).find(setting => setting.extension === extension)
        } else {
            setting = settings[fileType] || emptyFileTypeSettings
        }
    
        setFileTypeSettings(setting)
        setAddedDataInfo((prevData) => ({
            ...prevData,
            distance: setting.keys.distance,
            time: setting.keys.time,
            velocity: setting.keys.velocity,
            accLat: setting.keys.accLat,
            accLong: setting.keys.accLong,
            extension: setting.extension
        }))

        return setting
    }

    const handleCloseModal = () => {
        setFileTypeSettings(emptyFileTypeSettings)
        setAddedDataInfo(emptyAddedDataInfo)
        props.setOpenModal(false)
    }

    const applyDataHandler = () => {
        if (!timeSeriesData) {
            return alert(t('timeSeriesModalUpload.pleaseSelectFile'))
        } else if (!addedDataInfo.fileName) {
            return alert(t('timeSeriesModalUpload.pleaseEnterFileName'))
        } else if (!addedDataInfo.extension === 'csv') {
            if (!addedDataInfo.channelNameRow) {
                return alert(t('timeSeriesModalUpload.pleaseEnterChannelNameRow'))
            } else if (!addedDataInfo.firstDataRow) return alert(t('timeSeriesModalUpload.pleaseEnterFirstDataRow'))
        }

        const fileNameExists = props.timeSeriesList.map(data => data.fileName).includes(addedDataInfo.fileName)
        if (fileNameExists) {
            return alert(t('timeSeriesModalUpload.fileNameExists'))
        }

        if (!addedDataInfo.channelNameList.includes(addedDataInfo.distance)) {
            return  alert('Error - distance channel name is invalid')
        } else if (!addedDataInfo.channelNameList.includes(addedDataInfo.velocity)) {
            return alert('Error - velocity channel name is invalid')
        } else if (!addedDataInfo.channelNameList.includes(addedDataInfo.accLat)) {
            return alert('Error - accLat channel name is invalid')
        } else if (!addedDataInfo.channelNameList.includes(addedDataInfo.accLong)) {
            return alert('Error - accLong channel name is invalid')
        }

        const tempAddedDataInfo = { ...addedDataInfo }

        const processedData = processResult()
        tempAddedDataInfo.timeSeriesData = processedData
        tempAddedDataInfo.id = Math.floor(10000 + Math.random() * 90000)

        props.onClose(tempAddedDataInfo)
        handleCloseModal()
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target

        let inputValue = value

        if (name === 'filePath') {
            inputValue = e.target.files[0].name
            fileUploadHandler(e.target.files[0])
        }

        setAddedDataInfo((prevData) => ({
            ...prevData,
            [name]: inputValue,
        }))

        if (name === 'fileType') {
            updateFileTypeSettings(inputValue)
        }

        function fileUploadHandler(file) {
            const reader = new FileReader()
            const extension = file.name.split('.').pop()
            const fileType = addedDataInfo.fileType

            reader.onload = (e) => {
                const readData = e.target.result

                if (extension === 'mat') {
                    const matjsData = mat4js.read(readData)
                    const channelNames = Object.keys(matjsData.data)
                    setTimeSeriesData(matjsData.data)
                    setAddedDataInfo((prevData) => ({
                        ...prevData,
                        channelNameList: channelNames
                    }))
                } else {
                    let channelNames, splitData
                    if (extension === 'csv') {
                        splitData = readData.split(/\r?\n/)
                        splitData.pop()
                    } else if (extension === 'txt') {
                        splitData = readData.split(/\r?\n/).filter(line => line[0] !== '#')
                        const headers = splitData[0].split(/\t/)
                        channelNames = headers.map(header => header.replace(/"/g, ''))
                        setAddedDataInfo((prevData) => ({
                            ...prevData,
                            channelNameList: channelNames
                        }))
                    }

                    setTimeSeriesData(splitData)
                    setAddedDataInfo((prevData) => ({
                        ...prevData
                    }))
                }

                // TODO: change channel name when input file changes?
                // TODO: change channel name when file type changes?
                let settings
                if (!fileType) {
                    settings = updateFileTypeSettings(null, extension)
                } else if (fileType === 'Simulation') {

                }

                let keys = fileTypeSettings.keys

                if (!keys.distance) {
                    const settings = updateFileTypeSettings(null, extension)
                    keys = settings.keys
                }
            }

            if (extension === 'mat') {
                reader.readAsArrayBuffer(file)
            } else if (extension === 'csv') {
                reader.readAsBinaryString(file)
            } else if (extension === 'txt') {
                reader.readAsText(file)
            }
        }
    }

    const processResult = () => {
        const extension = fileTypeSettings.extension

        const processMat = () => {
            const processedData = {}
            const headers = addedDataInfo.channelNameList

            headers.forEach(header => {
                processedData[header] = Array.isArray(timeSeriesData[header]) ? timeSeriesData[header].map(d => d[1]) : timeSeriesData[header]
            })

            return processedData
        }

        const processCsvOrTxt = (splitter, firstDataRow) => {
            const processedData = {}
            const keyIndex = {}

            const headers = addedDataInfo.channelNameList
            headers.forEach(header => {
                keyIndex[header] = headers.indexOf(header)
                processedData[header] = []
            })
            
            for (let i = firstDataRow; i < timeSeriesData.length; i++) {
                const line = timeSeriesData[i].split(splitter)

                headers.forEach(header => {
                    processedData[header].push(Number(line[keyIndex[header]]))
                })
            }

            return processedData
        }

        let processedData
        if (extension === 'mat') {
            processedData = processMat()
        } else if (extension === 'csv') {
            processedData = processCsvOrTxt(/,/, addedDataInfo.firstDataRow - 1)
        } else if (extension === 'txt') {
            processedData = processCsvOrTxt(/\t/, 1)
        }

        return processedData
    }

    const paperProps = {
        sx: {
            width: '100%',
            maxWidth: '1000px',
        }
    }

    const dialogContentStyle = {
        width: 'auto'
    }

    const SelectContent = ({ name, value, options }) => {
        return (
            options ? 
                <select name={ name } value={ value } onChange={ handleInputChange }>
                    <option value='' disabled>{t('pleaseSelect', {ns: 'general'})}</option>
                    { options.map(option => (
                        <option key={ option } value={ option }>
                            { option }
                        </option>
                    ))}
                </select>
            : 
                <input name={ name } value={ value } onChange={ handleInputChange } />
        )
    }

    return (
        <Dialog open={ props.open } onClose={ handleCloseModal } maxWidth='md' fullWidth={ true } >
            <div className='top-dialog core'>
                <div className='dialog-title'>Add Data</div>
                <DialogActions>
                    <div onClick={ handleCloseModal } className='modal-exit'>✕</div>
                </DialogActions>
            </div>
            <DialogContent className='custom-modal core' style={dialogContentStyle}>
                <div className='settings-container container-border'>
                    <div className='settings-content input-content'>
                        <div className='settings-title input-title-div'>File Name</div>
                        <div className='settings-input input-div'>
                            <input type='text' name='fileName' value={ addedDataInfo.fileName } onChange={ handleInputChange } />
                        </div>
                    </div>
                    <div className='settings-content input-content'>
                        <div className='settings-title input-title-div'>File Type</div>
                        <div className='settings-input input-div'>
                            <SelectContent name='fileType' value={ addedDataInfo.fileType } options={ fileTypeOptions } />
                        </div>
                    </div>
                    <div className='settings-content input-content'>
                        <div className='settings-title input-title-div'>File Path</div>
                        <div className='settings-input input-div'>
                            <input type='file' accept='.mat,.csv,.txt' name='filePath' id='file-path-input' onChange={ handleInputChange }/>
                            <label className='file-path-input-label' htmlFor='file-path-input'>
                                <div className='file-path-button'>ファイルを選択</div>
                                <div className='file-path-text'>{ addedDataInfo.filePath }</div>
                            </label>
                        </div>
                    </div>

                    { fileTypeSettings.extension === 'csv' &&
                        <>
                            <div className='settings-content input-content'>
                                <div className='settings-title input-title-div'>Channel Name Row</div>
                                <div className='settings-input input-div'>
                                    <input type='number' name='channelNameRow' value={ addedDataInfo.channelNameRow } onChange={ handleInputChange } />
                                </div>
                            </div>
                            <div className='settings-content input-content'>
                                <div className='settings-title input-title-div'>First Data Row</div>
                                <div className='settings-input input-div'>
                                    <input type='number' name='firstDataRow' value={ addedDataInfo.firstDataRow } onChange={ handleInputChange } />
                                </div>
                            </div>
                        </>
                    }

                    <div className='settings-content input-content'>
                        <div className='settings-title input-title-div'>Distance</div>
                        <div className='settings-input input-div'>
                            <input type='text' name='distance' value={ addedDataInfo.distance } onChange={ handleInputChange } />
                            {/* <SelectContent name='distance' value={ fileTypeSettings.keys.distance } options={ channelNameList } /> */}
                        </div>
                    </div>
                    <div className='settings-content input-content'>
                        <div className='settings-title input-title-div'>Time</div>
                        <div className='settings-input input-div'>
                            <input type='text' name='time' value={ addedDataInfo.time } onChange={ handleInputChange } />
                            {/* <SelectContent name='time' value={ fileTypeSettings.keys.time } options={ channelNameList } /> */}
                        </div>
                    </div>
                    <div className='settings-content input-content'>
                        <div className='settings-title input-title-div'>Velocity</div>
                        <div className='settings-input input-div'>
                            <input type='text' name='velocity' value={ addedDataInfo.velocity } onChange={ handleInputChange } />
                            {/* <SelectContent name='velocity' value={ fileTypeSettings.keys.velocity } options={ channelNameList } /> */}
                        </div>
                    </div>
                    <div className='settings-content input-content'>
                        <div className='settings-title input-title-div'>Acc Lat</div>
                        <div className='settings-input input-div'>
                            <input type='text' name='accLat' value={ addedDataInfo.accLat } onChange={ handleInputChange } />
                            {/* <SelectContent name='accLat' value={ fileTypeSettings.keys.accLat } options={ channelNameList } /> */}
                        </div>
                    </div>
                    <div className='settings-content input-content'>
                        <div className='settings-title input-title-div'>Acc Long</div>
                        <div className='settings-input input-div'>
                            <input type='text' name='accLong' value={ addedDataInfo.accLong } onChange={ handleInputChange } />
                            {/* <SelectContent name='accLong' value={ fileTypeSettings.keys.accLong } options={ channelNameList } /> */}
                        </div>
                    </div>
                </div>
                <div className='modal-button-container'>
                    <div className='modal-button' onClick={ applyDataHandler }>Apply</div>
                    <div className='modal-button' onClick={ handleCloseModal }>Cancel</div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default TimeSeriesModalUpload