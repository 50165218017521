import { useEffect, useState} from 'react'
import { convertDecimalToHMS, convertHMSToMS } from '../services/Util'

import { Line } from 'react-chartjs-2'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const plotColor = [
    'white',
    'blue',
    'green',
    'red'
]

const SimulationOptionGraph = ({ lapDatas }) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#424040'
                }
            },
            y: {
                ticks: {
                    callback: (value) => {
                        return tickFunction(value)
                    },
                },
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#424040'
                }
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Estimate Lap Time [mm:ss.000] & Fuel Load [kg]',
                font: {
                    size: 16
                }
            },
            legend: {
                position: 'bottom',
                labels: {
                    padding: 10,
                    font: {
                        size: 10
                    },
                    usePointStyle: true
                }
            }
        }
    }
    const [lapChartData, setLapChartData] = useState({
        labels: [],
        datasets:  []
    })

    const deltaOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#424040'
                }
            },
            y: {
                ticks: {
                    callback: (value) => {
                        return tickFunction(value)
                    },
                },
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#424040'
                }
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Race Time Delta [sec]',
                font: {
                    size: 16
                }
            },
            legend: {
                position: 'bottom',
                labels: {
                    padding: 10,
                    font: {
                        size: 10
                    },
                    usePointStyle: true
                }
            }
        }
    }
    const [deltaChartData, setDeltaChartData] = useState({
        labels: [],
        datasets:  []
    })

    useEffect(() => {
        let labels = []
        let setData = []
        let setDeltaData = []

        let deltaTimeObj = {}
        if (lapDatas[1]) {
            Object.entries(lapDatas[1].optionData).forEach(data => {
                const lapNo = parseInt(data[0])
                const lapData = data[1]
                labels.push(lapNo)
                
                deltaTimeObj[lapNo] = lapData.accumulatedLapTime
            })
        }
        
        Object.entries(lapDatas).forEach(data => {
            const optionNo = data[0]
            const optionData = data[1]
            let setEachGraphData = []
            let setEachDeltaData = []

            if (optionData.display) {
                Object.entries(optionData.optionData).forEach(data => {
                    const lapNo = parseInt(data[0])
                    const lapData = data[1]

                    // Estimate Lap time
                    setEachGraphData.push({x: lapNo, y: convertHMSToMS(lapData.lapTime)})

                    // Race Time Delta
                    const diff = lapData.accumulatedLapTime - deltaTimeObj[lapNo]
                    setEachDeltaData.push({x: lapNo, y: convertHMSToMS(convertDecimalToHMS(diff))})
                })

                // Estimate Lap time
                let eachDataset = {}
                eachDataset.label = `Option ${optionNo}`
                eachDataset.data = setEachGraphData
                eachDataset.borderColor = plotColor[optionNo-1]
                eachDataset.backgroundColor = plotColor[optionNo-1]
                setData.push(eachDataset)

                // Race Time Delta
                let eachDeltaDataset = {}
                eachDeltaDataset.label = `Reference Opt_${optionNo}`
                eachDeltaDataset.data = setEachDeltaData
                eachDeltaDataset.borderColor = plotColor[optionNo-1]
                eachDeltaDataset.backgroundColor = plotColor[optionNo-1]
                setDeltaData.push(eachDeltaDataset)
            }
        })

        setLapChartData({
            labels,
            datasets: setData
        })

        setDeltaChartData({
            labels,
            datasets: setDeltaData
        })
    }, [lapDatas])

    const tickFunction = (value) => {
        const sign = value < 0 ? '-' : ''
        const absValue = Math.abs(value) 
        const hours = Math.floor(absValue / 3600)
        const minutes = Math.floor((absValue % 3600) / 60)
        const seconds = absValue % 60

        return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    }

    return (
        <>
            <div className='graph-container'>
                <div className='border-line' style={{ background: 'black' }}>
                    <Line height={500} width={700} options={options} data={lapChartData}/>
                </div>
                <div className='border-line' style={{ background: 'black' }}>
                    <Line height={500} width={700} options={deltaOptions} data={deltaChartData}/>
                </div>
            </div>
        </>
    )
}

export default SimulationOptionGraph