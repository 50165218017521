import { useEffect, useState } from 'react'

import { useLiveData } from '../../DataContext'
import { groupLapDataByCar } from './Util'

const ChartSetGapByLapTime = ({ setHistoryGap }) => {
    const { tableData, lapData, followCarNumber, displayFollowAndRivals, historyGap, raceDetailId, carClasses } = useLiveData()
    const [ lapDataByCar, setLapDataByCar ] = useState({})
    const [ currentLapData, setCurrentLapData ] = useState({})
    const [ accumulatedDataByLap, setAccumulatedDataByLap ] = useState({})
    const [ carsNotIncluded, setCarsNotIncluded ] = useState([])
    
    useEffect(() => {
        setCurrentLapData(lapData)
        setGap()
    }, [lapData, followCarNumber, carsNotIncluded])

    useEffect(() => {
        const carsNotIncludedTemp = tableData.filter(car => !car.isVisible).map(car => Number(car.carno.replace('No.', '')))

        setCarsNotIncluded(carsNotIncludedTemp)
    }, [carClasses, displayFollowAndRivals])

    async function setGap(){
        let accumulatedDataByLapObj = {}

        if (Object.keys(historyGap).length === 0 || Object.keys(lapDataByCar).length === 0) {
            let data = await getAllLapData()
            const groupLapDataByCarObj = groupLapDataByCar(data)
            accumulatedDataByLapObj = accumulatedDataByLapFunc(groupLapDataByCarObj)

            setLapDataByCar(groupLapDataByCarObj)
            setAccumulatedDataByLap(accumulatedDataByLapObj)
        } else {
            accumulatedDataByLapObj = await insertNewDataToAccumulatedDataLap(accumulatedDataByLap)
            setAccumulatedDataByLap(accumulatedDataByLapObj)
        }

        for (const lapNumber in accumulatedDataByLapObj) {
            let lapData = accumulatedDataByLapObj[lapNumber]
            lapData = lapData.filter(eachCar => !carsNotIncluded.includes(eachCar.CAR_NUMBER))
            accumulatedDataByLapObj[lapNumber] = lapData
        }

        const arrangeEachLapInPos = await arrangeEachLapInPosFunc(accumulatedDataByLapObj)
        const createChartObj = createChartObjFunc(arrangeEachLapInPos)

        setHistoryGap(() => ({
            ...createChartObj
        }))
    }

    function accumulatedDataByLapFunc (groupLapDataByCarObj) {
        let accumulatedDataByLap = {}
        let arrangeEachLap = {}
        let accumulatedTime = {}

        for(const [carNumber, carData] of Object.entries(groupLapDataByCarObj)) {
            const sortByLapAsc = Object.values(carData).sort((a, b) => parseInt(a.LAP_NUMBER) - parseInt(b.LAP_NUMBER))
            
            sortByLapAsc.forEach(eachLap => {
                const strLap = eachLap.LAP_NUMBER.toString()
                if(!accumulatedTime[carNumber]) {
                    accumulatedTime[carNumber] = eachLap.TIME
                } else {
                    accumulatedTime[carNumber] += eachLap.TIME
                }

                if(typeof arrangeEachLap[strLap] === 'object'){
                    arrangeEachLap[strLap].push({
                        'CAR_NUMBER': eachLap.CAR_NUMBER,
                        'TIME': accumulatedTime[carNumber],
                        'LAP': eachLap.LAP_NUMBER,
                        'DRIVER_NAME': eachLap.DRIVER_NAME ? eachLap.DRIVER_NAME.slice(0, 5) : 'null'
                    })
                } else{
                    arrangeEachLap[strLap] = [{
                        'CAR_NUMBER': eachLap.CAR_NUMBER,
                        'TIME': accumulatedTime[carNumber],
                        'LAP': eachLap.LAP_NUMBER,
                        'DRIVER_NAME': eachLap.DRIVER_NAME ? eachLap.DRIVER_NAME.slice(0, 5) : 'null'
                    }]
                }
            })

            accumulatedDataByLap = arrangeEachLap
        }

        return accumulatedDataByLap
    }

    function arrangeEachLapInPosFunc(arrangeLapObj) {
        Object.values(arrangeLapObj).forEach(lapData => {
            if (lapData.length > 0) {
                if(!followCarNumber){
                    const lapSortByTimeAsc = Object.values(lapData).sort((a, b) => a.TIME - b.TIME)
                    let firstGap = lapSortByTimeAsc[0].TIME
                    for(let i=0; i<lapSortByTimeAsc.length; i++){
                        lapData[i].LAP = lapSortByTimeAsc[i].LAP
                        lapData[i].POS = i + 1
                        lapData[i].GAP = lapSortByTimeAsc[i].TIME - firstGap
                    }
                }
            }
        })

        if(parseInt(followCarNumber) > 0 ){
            Object.values(arrangeLapObj).forEach(lapData => {
                const getFollowCarData = Object.values(lapData).find(el => parseInt(el.CAR_NUMBER) === parseInt(followCarNumber))
                let getFollowGap = getFollowCarData ? getFollowCarData.TIME : 0
                if (getFollowCarData){
                    for (let i = 0; i < lapData.length; i++) {
                        if(lapData[i].POS < getFollowGap.POS) {
                            lapData[i].GAP = getFollowGap - lapData[i].TIME
                        } else {
                            lapData[i].GAP = lapData[i].TIME - getFollowGap
                        }
                    }
                }
            })
        }

        return arrangeLapObj
    }

    function createChartObjFunc (arrangeEachLapInPosObj) {
        const chartDataset = {}

        Object.values(arrangeEachLapInPosObj).forEach(lapData => {
            for(let i=0; i<lapData.length; i++){
                const eachData = lapData[i]
                const key = `${eachData.CAR_NUMBER}`

                chartDataset[key] = chartDataset[key] || []
                chartDataset[key].push({
                    x: eachData.LAP,
                    y: eachData.GAP,
                    driverName: eachData.DRIVER_NAME
                })
            } 
        })

        return chartDataset
    }

    async function insertNewDataToAccumulatedDataLap(accumulatedDataByLap) {
        let retrieveNewData = difference(currentLapData, lapData)

        const dataToAdd = await checkForRemovedData(accumulatedDataByLap)
        retrieveNewData = [...retrieveNewData, ...dataToAdd]

        for(let i=0; i<retrieveNewData.length; i++){

            const pastData = accumulatedDataByLap[retrieveNewData[i].LAP_NUMBER - 1]
            const carPastData = pastData ? pastData.find(el => el.CAR_NUMBER === retrieveNewData[i].CAR_NUMBER) : null
            const prevTime = carPastData ? carPastData.TIME : 0
            
            if (!accumulatedDataByLap[retrieveNewData[i].LAP_NUMBER]) {
                accumulatedDataByLap[retrieveNewData[i].LAP_NUMBER] = []
            }

            const currentCarDataExists = accumulatedDataByLap[retrieveNewData[i].LAP_NUMBER].find(el => el.CAR_NUMBER === retrieveNewData[i].CAR_NUMBER)

            if (!currentCarDataExists) {
                accumulatedDataByLap[retrieveNewData[i].LAP_NUMBER].push({
                    'CAR_NUMBER': retrieveNewData[i].CAR_NUMBER,
                    'TIME': prevTime + retrieveNewData[i].TIME,
                    'LAP': retrieveNewData[i].LAP_NUMBER,
                    'DRIVER_NAME': retrieveNewData[i].DRIVER_NAME.slice(0, 5)
                })
            }
        }

        return accumulatedDataByLap
    }

    function difference (currentLapArr, lapDataArr) {
        let result = []

        Object.values(lapDataArr).forEach(data => {
            const findExist = Object.values(currentLapArr).find(el => el.LAP_NUMBER === data.LAP_NUMBER && el.CAR_NUMBER === data.CAR_NUMBER)
            
            if (!findExist) {
                result.push(data)
            }
        })

        return result
    }

    async function checkForRemovedData(accumulatedDataByLap) {
        let data = await getAllLapData()
        const groupLapDataByCarObj = groupLapDataByCar(data)
        const lapDataToAdd = []

        const cars = Object.keys(groupLapDataByCarObj)
        const carsToInclude = cars
            .filter(car => !carsNotIncluded.includes(Number(car)))
            .map(car => Number(car))

        for (const [lapNumber, lapData] of Object.entries(accumulatedDataByLap)) {
            const carsInAccumulatedData = lapData.map(lap => lap.CAR_NUMBER)
            const carsNotInAccumulatedData = carsToInclude.filter(carNumber => !carsInAccumulatedData.includes(carNumber))

            carsNotInAccumulatedData.forEach(carNumber => {
                const carLap = groupLapDataByCarObj[carNumber].find(lap => lap.LAP_NUMBER === Number(lapNumber))
                if (carLap) {
                    lapDataToAdd.push(carLap)
                }
            })
        }


        return lapDataToAdd
    }

    async function getAllLapData(){
        let returnData = lapData

        const checkIfMoreThan50Lap = Object.keys(returnData).length > 0 ? Object.values(returnData).reduce(function(prev, current) {
            return (prev.LAP_NUMBER > current.LAP_NUMBER) ? prev : current
        }).LAP_NUMBER : 0

        if (checkIfMoreThan50Lap > 50) {
            returnData = await getPrevLapData(raceDetailId)
        }

        return returnData
    }
}

async function getPrevLapData(raceDetailId) {
    if (!raceDetailId) {
        throw new Error("raceDetailId is required")
    }

    try {
        const response = await fetch(`/api/race/getAllLapData/${raceDetailId}`)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        const jsonData = await response.json()
        return jsonData.data
    } catch (error) {
        throw error
    }
}


export default ChartSetGapByLapTime