import React, { useState, useEffect } from 'react'
import * as dfd from 'danfojs'

import Header from '../components/Header'
import SetupTableResult from '../components/SetupTable_SPM'
import SetupResultLeft from '../components/SetupResultLeft'
import SetupResultRight from '../components/SetupResultRight'
import SetupUserInput from '../components/SetupUserInput'
import SetupTableConstrain from '../components/SetupTable_Constrain'

import startOptimize from '../services/startOptimize'
import compare_drivermap from '../services/compare_drivermap'
import compare_laptime from '../services/compare_laptime'
import compare_setupRequest from '../services/compare_setupRequest'
import updateProgress from '../services/updateProgress'

import makeBaseDataframe from '../services/makeBaseDataframe'
import covertNameContinuous from '../services/covertNameContinuous'
import addKPI from '../services/addKPI'

import { useTranslation } from 'react-i18next'

const Setup = () => {
    const { t } = useTranslation(['core'])

    // TODO: get vehicle from db?
    // const [ vehicle, setVehicle ] = useState('Gr86')
    // const [ vehicleData, setVehicleData ] = useState()

    // TODO: get track from event
    const [ track, setTrack ] = useState('')

    // TODO: get time series data from db?
    const [ channelNameRow, setChannelNameRow ] = useState(13)
    const [ firstDataRow, setFirstDataRow ] = useState(18)
    const [ setupTimeSeriesFilePath, setSetupTimeSeriesFilePath ] = useState()

    // const defaultAvoidBottoming = vehicleData.avoid_bottoming
    const [defaultAvoidBottoming, setDefaultAvoidBottoming] = useState()

    const [ selectedTable, setSelectedTable ] = useState('Time')
    const [ resultTab, setResultTab ] = useState('left')

    const [ alignmentData, setAlignmentData ] = useState()
    const [ chassisData, setChassisData ] = useState()
    const [ aeroData, setAeroData ] = useState()

    const [ listAns, setListAns ] = useState(0)

    const [ df_baseSetup_raw, setDf_baseSetup_raw ] = useState()
    const [ df_mapDriver, setDf_mapDriver ] = useState()
    const [ df_baseSetup, setDf_baseSetup ] = useState()
    const [ df_showSetup, setDf_showSetup ] = useState()
    const [ df_showTime, setDf_showTime ] = useState()
    const [ df_showKPI, setDf_showKPI ] = useState()
    const [ df_setupRequest, setDf_setupRequest ] = useState()

    const [ df_baseTime, setDf_baseTime ] = useState()
    const [ df_baseHandling, setDf_baseHandling ] = useState()
    const [ df_baseAero, setDf_baseAero ] = useState()
    const [ df_baseMech, setDf_baseMech ] = useState()

    const [ df_scatTime, setDf_scatTime ] = useState()
    const [ df_scatHandling, setDf_scatHandling ] = useState()
    const [ df_scatAero, setDf_scatAero ] = useState()
    const [ df_scatMech, setDf_scatMech ] = useState()

    const [ df_baseKPI, setDf_baseKPI ] = useState()
    const [ df_scatKPI, setDf_scatKPI ] = useState()

    const [ Obj_KPI_base, setObj_KPI_base ] = useState({})
    const [ Obj_KPI_recommend, setObj_KPI_recommend ] = useState({})
    const [ Obj_KPI_selected, setObj_KPI_selected ] = useState({})
    const [ Obj_KPI_base_result, setObj_KPI_base_result ] = useState({})
    const [ Obj_KPI_recommend_result, setObj_KPI_recommend_result ] = useState({})

    const [ Obj_circuit_map, setObj_circuit_map ] = useState({})
    const [ Obj_circuit_sens, setObj_circuit_sens ] = useState({})
    const [ Obj_parameter_combination, setObj_parameter_combination ] = useState({})
    const [ Obj_chart_combination, setObj_chart_combination ] = useState({})

    const [ Obj_scatSetup, setObj_scatSetup ] = useState({})
    const [ Obj_baseSetup, setObj_baseSetup ] = useState({})
    const [ df_DOEcondition, setDf_DOEcondition ] = useState()

    const [ Obj_scat_result, setObj_scat_result ] = useState({})
    const [ df_scatInput, setDf_scatInput ] = useState()

    const [ selectedIndex, setSelectedIndex ] = useState(listAns)

    const [ constrainOptionOpen, setConstrainOptionOpen ] = useState(false)
    const [ constrainSelectedOptions, setConstrainSelectedOptions ] = useState()

    // const [ eventList, setEventList ] = useState()
    // const [ selectedEvent, setSelectedEvent ] = useState()
    const [ circuitList, setCircuitList ] = useState()
    const [ selectedCircuit, setSelectedCircuit ] = useState()

    const [ setupOptions, setSetupOptions ] = useState()
    const [ setup, setSetup ] = useState()
    const [ modelId, setModelId ] = useState()

    const [ isShowBase, setIsShowBase ] = useState(false)
    const [ isShowRecommend, setIsShowRecommend ] = useState(false)

    const methodOpt = ['SetupRequest', 'bottoming', 'handling', 'aero', 'mechanical']
    const round_num = 3

    const urlParams = new URLSearchParams(window.location.search)
    const vehicleId = urlParams.get('vId') || null
    // const updatedSetupFile = urlParams.get('usf') || null
    const teamOutingDetailId = urlParams.get('todId') || null

    const [updatedSetupFile, setUpdatedSetupFile] = useState(urlParams.get('usf') || null)
    const [ displayResult, setDisplayResult ] = useState(false)

    useEffect(() => {
        const fetchCircuits = async () => {
            const response = await fetch('/api/v1/circuits')
            const circuits = await response.json()
            setCircuitList(circuits.circuits)
        }
        fetchCircuits()
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            console.log("updatedSetupFile", updatedSetupFile)
            const startOptimizedResults = await startOptimize(teamOutingDetailId, vehicleId, selectedCircuit, updatedSetupFile, constrainSelectedOptions)
            setSetup(startOptimizedResults.setup)
            console.log("setup", startOptimizedResults.setup)
            // setVehicleData(startOptimizedResults.vehicleData)
            setModelId(startOptimizedResults.model.id)
            setDefaultAvoidBottoming(startOptimizedResults.vehicleData.avoid_bottoming)
            setObj_chart_combination(startOptimizedResults.Obj_chart_combination)
            setObj_parameter_combination(startOptimizedResults.Obj_parameter_combination)
            setObj_circuit_map(startOptimizedResults.Obj_circuit_map)
            setObj_circuit_sens(startOptimizedResults.Obj_circuit_sens)

            setDf_baseSetup_raw(startOptimizedResults.df_baseSetup_raw)
            setDf_baseSetup(startOptimizedResults.df_baseSetup)
            setDf_mapDriver(startOptimizedResults.df_mapDriver)
            setDf_setupRequest(startOptimizedResults.df_setupRequest)

            setObj_baseSetup(startOptimizedResults.optimizedResults.Obj_baseSetup)

            setObj_scatSetup(startOptimizedResults.optimizedResults.Obj_scatSetup)
            setObj_scat_result(startOptimizedResults.optimizedResults.Obj_scat_result)
            setDf_scatInput(startOptimizedResults.optimizedResults.df_scatInput)
            setDf_scatTime(startOptimizedResults.optimizedResults.df_scatTime)

            setDf_DOEcondition(startOptimizedResults.df_DOEcondition)

            // const timeSeriesSetupColumns = startOptimizedResults.df_baseSetup_raw.columns
            //     .filter(c => c.includes('Setup'))
            //     .map(c => `data/time_series/${vehicle}_${track}_${c}.csv`)
            // timeSeriesSetupColumns.unshift(timeSeriesFilePath)
            // setSetupTimeSeriesFilePath(timeSeriesSetupColumns)
            setSetupTimeSeriesFilePath([startOptimizedResults.model.id])

            setSetupOptions(startOptimizedResults.setupOptions)

            const labelBase = startOptimizedResults.df_baseSetup.iloc({'columns':['1:']}).columns
            const tempDf_baseInput = startOptimizedResults.optimizedResults.df_baseInput
            const tempDf_scatInput = startOptimizedResults.optimizedResults.df_scatInput
            const tempDf_scatTime = startOptimizedResults.optimizedResults.df_scatTime

            const [ chart1, chart2, chart3 ] = startOptimizedResults.optimizedResults.scat_chart_list

            let tempDf_baseHandling = startOptimizedResults.optimizedResults.Obj_base_result[chart1]
            let tempDf_baseAero = startOptimizedResults.optimizedResults.Obj_base_result[chart2]
            let tempDf_baseMech = startOptimizedResults.optimizedResults.Obj_base_result[chart3]
            let tempDf_baseTime = startOptimizedResults.optimizedResults.df_baseTime
            let tempDf_baseKPI = startOptimizedResults.optimizedResults.df_baseKPI

            tempDf_baseHandling = dfd.concat({ dfList: [tempDf_baseInput, tempDf_baseTime, tempDf_baseHandling], axis: 1 })
            tempDf_baseAero = dfd.concat({ dfList: [tempDf_baseInput, tempDf_baseTime, tempDf_baseAero], axis: 1 })
            tempDf_baseMech = dfd.concat({ dfList: [tempDf_baseInput, tempDf_baseTime, tempDf_baseMech], axis: 1 })
            tempDf_baseKPI = dfd.concat({ dfList: [tempDf_baseKPI, tempDf_baseTime], axis: 1 })

            tempDf_baseHandling.setIndex({index: labelBase, inplace: true})
            tempDf_baseAero.setIndex({index: labelBase, inplace: true})
            tempDf_baseMech.setIndex({index: labelBase, inplace: true})
            tempDf_baseTime.setIndex({index: labelBase, inplace: true})
            tempDf_baseKPI.setIndex({index: labelBase, inplace: true})

            setDf_baseHandling(tempDf_baseHandling)
            setDf_baseAero(tempDf_baseAero)
            setDf_baseMech(tempDf_baseMech)
            setDf_baseTime(tempDf_baseTime)
            setDf_baseKPI(tempDf_baseKPI)

            let tempDf_scatHandling = startOptimizedResults.optimizedResults.Obj_scat_result[chart1]
            let tempDf_scatAero = startOptimizedResults.optimizedResults.Obj_scat_result[chart2]
            let tempDf_scatMech = startOptimizedResults.optimizedResults.Obj_scat_result[chart3]
            let tempDf_scatKPI = startOptimizedResults.optimizedResults.df_scatKPI

            tempDf_scatHandling = dfd.concat({ dfList: [tempDf_scatInput, tempDf_scatTime, tempDf_scatHandling], axis: 1 })
            tempDf_scatAero = dfd.concat({ dfList: [tempDf_scatInput, tempDf_scatTime, tempDf_scatAero], axis: 1 })
            tempDf_scatMech = dfd.concat({ dfList: [tempDf_scatInput, tempDf_scatTime, tempDf_scatMech], axis: 1 })
            tempDf_scatKPI = dfd.concat({ dfList: [tempDf_scatKPI, tempDf_scatTime], axis: 1 })

            setDf_scatHandling(tempDf_scatHandling)
            setDf_scatAero(tempDf_scatAero)
            setDf_scatMech(tempDf_scatMech)
            setDf_scatKPI(tempDf_scatKPI)

            setObj_KPI_base_result(startOptimizedResults.optimizedResults.Obj_KPI_base_result || {})
            setObj_KPI_recommend_result(startOptimizedResults.optimizedResults.Obj_KPI_recommend_result || {})
        }

        if (!selectedCircuit && !teamOutingDetailId) return
        updateProgress(5)
        fetchData()
    }, [selectedCircuit, updatedSetupFile])

    useEffect(() => {
        if (!df_scatHandling) return
        getListAns()
    }, [df_scatHandling])

    useEffect(() => {
        if (!listAns) return
        compareOptimizedResults()
    }, [listAns, selectedIndex])

    const getListAns = () => {
        let listDriver = df_scatHandling.index
        methodOpt.forEach(m => {
            if (listDriver.length === 0){
                alert('Could not find the answer... Please try again with decrease your request.')
            }else{
                if (m === 'SetupRequest') {
                    listDriver = compare_setupRequest(listDriver, df_baseKPI, df_scatKPI, df_setupRequest)
                } else if (m === 'bottoming') {
                    // listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseRHFL, df_scatRHFL, df_mapDriver, 'Ride Height', defaultAvoidBottoming)
                    // listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseRHFR, df_scatRHFR, df_mapDriver, 'Ride Height', defaultAvoidBottoming)
                    // listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseRHRL, df_scatRHRL, df_mapDriver, 'Ride Height', defaultAvoidBottoming)
                } else if (m === 'handling') {
                    listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseHandling, df_scatHandling, df_mapDriver, 'Handling', defaultAvoidBottoming)
                } else if (m === 'aero') {
                    listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseAero, df_scatAero, df_mapDriver, 'Aero', defaultAvoidBottoming)
                } else if (m === 'mechanical') {
                    listDriver = compare_drivermap(listDriver, Obj_circuit_map, df_baseMech, df_scatMech, df_mapDriver, 'mechanical', defaultAvoidBottoming)
                }
            }
        })

        if (listDriver.length === 0) {
            setListAns(0)
            setSelectedIndex(0)
        } else {
            let [ listAns_temp ] = compare_laptime(df_scatTime.iloc({'rows':listDriver}).resetIndex(), df_baseTime)
            setListAns(listAns_temp)
            setSelectedIndex(listAns_temp)
        }
    }

    const compareOptimizedResults = async () => {
        if (listAns === 0) {
            setDf_showSetup(df_baseSetup_raw.iloc({'columns':['1:']}).T)

            const tempDf_showTime = df_baseTime.iloc({'columns':['1:']}).round(round_num).T
            tempDf_showTime.setIndex({index: df_baseTime.iloc({'columns':['1:']}).columns, inplace: true})
            setDf_showTime(tempDf_showTime)

            setDf_showKPI(df_baseKPI.iloc({'columns':['1:']}).round(round_num).T)
        } else {
            //表示用Setupテーブル作成
            let listSetup = []
            for (let i = 0; i < df_baseSetup_raw.index.length; i++){
                listSetup[i] = Obj_scatSetup[Object.keys(Obj_scatSetup)[i]].data[listAns[0]]
            }

            const df_showSetup_base = makeBaseDataframe(df_baseSetup_raw)
            let tempDf_showSetup = new dfd.DataFrame({'Recommend': listSetup}, {index: df_baseSetup_raw.index})
            tempDf_showSetup = covertNameContinuous(tempDf_showSetup, Obj_parameter_combination, 1)
            tempDf_showSetup = dfd.concat({ dfList: [tempDf_showSetup, df_showSetup_base.iloc({'columns':['1:']})], axis: 1 })
            tempDf_showSetup.setIndex({index: df_baseSetup_raw.index, inplace: true})
            setDf_showSetup(tempDf_showSetup)

            let labelConstrain = df_baseSetup.iloc({'columns':[0]}).columns
            let labelBase = df_baseSetup.iloc({'columns':['1:']}).columns

            labelConstrain = labelConstrain.concat(labelBase)
            let tempDf_baseSetup_download = (new dfd.DataFrame(df_baseSetup.values, {columns: labelConstrain}))
            tempDf_baseSetup_download.setIndex({index: df_baseSetup.index, inplace: true})
            df_baseTime.setIndex({index: labelBase, inplace: true})
            df_baseKPI.setIndex({index: labelBase, inplace: true})

            let tempDf_showTime = new dfd.DataFrame({
                'Recommend': df_scatTime.iloc({'rows':[listAns[0]], 'columns':['1:']}).round(round_num).T.values,
                'Selected': selectedIndex[0] === -1 ? df_baseTime.iloc({'columns':['1:']}).values[0] : df_scatTime.iloc({'rows':[selectedIndex[0]], 'columns':['1:']}).round(round_num).T.values},
                {index: df_baseTime.iloc({'columns':['1:']}).columns})
            const df_showTimeRecommend = tempDf_showTime['Recommend']
            tempDf_showTime.drop({ columns: ['Recommend'], inplace: true })
            tempDf_showTime = dfd.concat({ dfList: [tempDf_showTime, df_baseTime.iloc({'columns':['1:']}).round(round_num).T], axis: 1 })
            tempDf_showTime = dfd.concat({ dfList: [tempDf_showTime, df_showTimeRecommend], axis: 1 })
            tempDf_showTime.setIndex({index: df_baseTime.iloc({'columns':['1:']}).columns, inplace: true})
            setDf_showTime(tempDf_showTime)

            let tempDf_showKPI = new dfd.DataFrame({
                'Recommend': df_scatKPI.iloc({'rows':[listAns[0]], 'columns':['1:']}).round(round_num).T.values,
                'Selected': selectedIndex[0] === -1 ? df_baseKPI.iloc({'columns':['1:']}).values[0] : df_scatKPI.iloc({'rows':[selectedIndex[0]], 'columns':['1:']}).round(round_num).T.values
            }, {index: df_baseKPI.iloc({'columns':['1:']}).columns})
            const df_showKPIRecommend = tempDf_showKPI['Recommend']
            tempDf_showKPI.drop({ columns: ['Recommend'], inplace: true })
            tempDf_showKPI = dfd.concat({ dfList: [tempDf_showKPI, df_baseKPI.iloc({'columns':['1:']}).round(round_num).T], axis: 1 })
            tempDf_showKPI = dfd.concat({ dfList: [tempDf_showKPI, df_showKPIRecommend], axis: 1 })
            tempDf_showKPI.setIndex({index: df_baseKPI.iloc({'columns':['1:']}).columns, inplace: true})
            setDf_showKPI(tempDf_showKPI)

            const addKPIresult = await addKPI(Obj_circuit_sens, listAns, Obj_KPI_base_result, Obj_KPI_recommend_result, selectedIndex)
            setObj_KPI_base(addKPIresult.Obj_KPI_base)
            setObj_KPI_recommend(addKPIresult.Obj_KPI_recommend)
            setObj_KPI_selected(addKPIresult.Obj_KPI_selected)

            const updatedSetup = setup
            updatedSetup.forEach((s, i) => {
                const index = tempDf_showSetup.index.indexOf(s.Parameter)
                const newValue = tempDf_showSetup.values[index][0]
                s.Base = typeof newValue === 'number' ? String(newValue.toFixed(3)) : newValue
            })
            setSetup(updatedSetup)
            updateProgress(100)
            setDisplayResult(true)
        }
    }

    const changeTabHandler = () => {
        if (resultTab === 'left') {
            setResultTab('right')
        } else {
            setResultTab('left')
        }
    }

    const setConstrainHandler = () => {
        setConstrainOptionOpen(true)
    }

    const onCloseConstrainSelectedOptions = (editedConstrainOption) => {
        const updatedSetup = setup
        updatedSetup.forEach((s, i) => {
            const parameter = editedConstrainOption.find((option) => option.parameter === s.Parameter)
            const newValue = parameter.value
            s.Constrain = newValue
        })
        postUpdatedSetup(updatedSetup).then((fileName) => {
            setUpdatedSetupFile(fileName)
        })
    }

    const saveClickHandler = () => {
        const setupName = prompt(t('setup.pleaseenterSetupName'));
        if (!setupName) return
        fetch(`/api/v1/setups/new?vehicleId=${vehicleId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                setupItems: setup,
                setupName: setupName,
            })
        })
        .then(response => response.json())
        .then((response) => {
            console.log("save response", response)
            alert('Setup saved successfully')
        })
        .catch((error) => {
            alert('Error saving setup')
            console.error('Error:', error)
        })
    }

    const showBaseHandler = () => {
        setIsShowBase(true)
    }

    const showRecommendHandler = () => {
        setIsShowRecommend(true)
    }

    return (
        <>
            <div className='setup-page'>
                <div className='setup-header'>
                    <Header isSmallHeader={true} />
                    <div className='header-center'>SETUP MANAGER</div>
                    <div className='setup-circuit-selector-div'>
                        <select onChange={(e) => {
                            setTrack(e.target.selectedOptions[0].dataset.name)
                            setSelectedCircuit(e.target.value)
                        }}>
                            <option value=''>Select Circuit</option>
                            {
                                circuitList && circuitList.map((circuit) => {
                                    return <option key={circuit.id} value={circuit.id} data-name={circuit.name}>{circuit.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <span className='action-save' onClick={saveClickHandler}>保存</span>
                </div>

                <div className='loading-container'>
                    <progress id='myProgress' value='' max='100'></progress>
                </div>

                { displayResult && (
                    <>
                        <div className='time-kpi-table-container'>
                            {
                                selectedTable === 'Time' && df_showTime
                                ? 
                                    SetupTableResult(df_showTime, 'laptime', selectedTable, setSelectedTable)
                                :
                                    selectedTable === 'KPI' && df_showKPI ? SetupTableResult(df_showKPI, 'kpi', selectedTable, setSelectedTable)
                                : ''
                            }
                        </div>

                        {
                            resultTab === 'left'
                            ?
                                <SetupResultLeft
                                    track={ track }
                                    df_baseSetup={ df_baseSetup }
                                    df_mapDriver={ df_mapDriver }
                                    listAns={ listAns }
                                    df_DOEcondition={ df_DOEcondition }
                                    df_scatKPI={ df_scatKPI }
                                    df_baseKPI={ df_baseKPI }
                                    df_baseHandling={ df_baseHandling }
                                    df_baseAero={ df_baseAero }
                                    df_baseMech={ df_baseMech }
                                    df_scatHandling={ df_scatHandling }
                                    df_scatAero={ df_scatAero }
                                    df_scatMech={ df_scatMech }
                                    Obj_chart_combination={ Obj_chart_combination }
                                    Obj_KPI_base={ Obj_KPI_base }
                                    Obj_KPI_recommend={ Obj_KPI_recommend }
                                    Obj_circuit_sens={ Obj_circuit_sens }
                                    selectedIndex={ selectedIndex }
                                />
                            :
                                <SetupResultRight
                                    setupTimeSeriesFilePath={ setupTimeSeriesFilePath }
                                    channelNameRow={ channelNameRow }
                                    firstDataRow={ firstDataRow }
                                    Obj_chart_combination={ Obj_chart_combination }
                                    Obj_KPI_base={ Obj_KPI_base }
                                    Obj_KPI_recommend={ Obj_KPI_recommend }
                                    Obj_KPI_selected={ Obj_KPI_selected }
                                    Obj_circuit_sens={ Obj_circuit_sens }
                                />
                        }

                        <div className='user-setup-container'>
                            <div className='user-setup-options' onClick={ setConstrainHandler }></div>
                            <div className='user-show-container'>
                                <div className={`user-show-button user-setup-show-base`} onClick={ showBaseHandler }>Base</div>
                                <div className={`user-show-button user-setup-show-recommend`} onClick={ showRecommendHandler }>Recommend</div>
                            </div>

                            <SetupUserInput
                                df_baseSetup={ df_baseSetup }
                                df_showSetup={ df_showSetup }
                                Obj_scat_result={ Obj_scat_result }
                                setDf_showSetup={ setDf_showSetup }
                                df_scatInput={ df_scatInput }
                                Obj_parameter_combination={ Obj_parameter_combination }
                                alignmentData={ alignmentData }
                                setAlignmentData={ setAlignmentData }
                                chassisData={ chassisData }
                                setChassisData={ setChassisData }
                                aeroData={ aeroData }
                                setAeroData={ setAeroData }
                                setSelectedIndex = { setSelectedIndex }
                                setupOptions={ setupOptions }
                                setSetup={ setSetup }
                                isShowBase={ isShowBase }
                                setIsShowBase={ setIsShowBase }
                                isShowRecommend={ isShowRecommend }
                                setIsShowRecommend={ setIsShowRecommend }
                            />
                        </div>

                    </>
                )}
            </div>
            <div className={ `swiper-button ${resultTab}` } onClick={ changeTabHandler }></div>
            <SetupTableConstrain constrainOptionOpen={ constrainOptionOpen } setConstrainOptionOpen={ setConstrainOptionOpen } df_baseSetup={ df_baseSetup } onClose={ onCloseConstrainSelectedOptions } />
        </>
    )
}

export default Setup

const postUpdatedSetup = async (updatedSetup) => {
    try {
        const fileName = `${Date.now()}.json`
        const response = await fetch("/api/v1/files/json", {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ data: {items: updatedSetup}, fileName: fileName }),
        });
        await response.json();
        return fileName;
    } catch(error) {
        console.log(error)
        alert("Failed to post updated setup");
    }
  };