import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import enCore from './core/en.json'
import jpCore from './core/jp.json'
import enGeneral from './general/en.json'
import jpGeneral from './general/jp.json'
import enStrategy from './strategy/en.json'
import jpStrategy from './strategy/jp.json'

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                core: enCore,
                general: enGeneral,
                strategy: enStrategy
            },
            jp: {
                core: jpCore,
                general: jpGeneral,
                strategy: jpStrategy
            }
        },
        fallbackLng: 'jp',
        interpolation: {
            escapeValue: false
        }
    })