import React, { useState } from 'react'

import TimeSeriesFile from './TimeSeriesFile'
import TimeSeriesDragResize from './TimeSeriesDragResize'

import '../css/common.css'
import '../css/timeSeries.css'

import { useTranslation } from 'react-i18next'

const TimeSeries = (props) => {
    const { t } = useTranslation(['core'])

    const [ timeSeriesList, setTimeSeriesList ] = useState([])
    const [ graphData, setGraphData ] = useState([])

    const [ selectedGraph, setSelectedGraph ] = useState()

    const vehicleId = props.trackId
    const trackId = props.vehicleId

    // const [ groupedByLap, setGroupedByLap ] = useState({})
    // const [ groupedByLapCorner, setGroupedByLapCorner ] = useState({})

    const addChartHandler = (e) => {
        const type = e.currentTarget.dataset.type

        const newGraphData = {
            id: Math.floor(10000 + Math.random() * 90000),
            type,
            layout: { x: 0, y: 0, w: 6, h: 6 },
            plotData: {},
            channels: []
        }

        setGraphData(prevData => [...prevData, newGraphData])
        setSelectedGraph(newGraphData)
    }

    return (
        <>
            <div className='time-series-button-container action-button-container view-buttons'>
                <div className='time-series-side-button action-button'>{t('timeSeries.saveView')}</div>
                <div className='time-series-side-button action-button'>{t('timeSeries.readView')}</div>
            </div>
            <div className='content-container display-flex'>
                <div className='time-series-side-menu'>
                    <TimeSeriesFile
                        timeSeriesList={ timeSeriesList }
                        setTimeSeriesList={ setTimeSeriesList }
                        selectedGraph={ selectedGraph }
                        setSelectedGraph={ setSelectedGraph }
                        setGraphData={ setGraphData }
                        vehicleId={ vehicleId }
                        trackId={ trackId }
                    />
                </div>
                <div className='time-series-result-container'>
                    {/* { timeSeriesList.length > 0 && ( */}
                    {(
                        <div className='time-series-button-container'>
                            <div className='time-series-chart-button' data-type='timeDistance' onClick={ addChartHandler }>Time/Distance</div>
                            <div className='time-series-chart-button' data-type='xY' onClick={ addChartHandler }>X-Y</div>
                            <div className='time-series-chart-button' data-type='bar' onClick={ addChartHandler }>Bar</div>
                            <div className='time-series-chart-button' data-type='radar' onClick={ addChartHandler }>Radar</div>
                        </div>
                    )}

                    <div className='time-series-chart-container'>
                        <TimeSeriesDragResize
                            graphData={ graphData }
                            setGraphData={ setGraphData }
                            timeSeriesList={ timeSeriesList }
                            selectedGraph={ selectedGraph }
                            setSelectedGraph={ setSelectedGraph }
                            vehicleId={ vehicleId }
                            trackId={ trackId }
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimeSeries