import React from 'react'

const SetupUserInputSummaryTab = (props) => {
    const alignmentData = props.alignmentData
    const chassisData = props.chassisData
    const aeroData = props.aeroData

    const setIsEditMode = props.setIsEditMode
    const setDataToEdit = props.setDataToEdit
    const setEditedData = props.setEditedData
    const setSetup = props.setSetup

    const isShowBase = props.isShowBase
    const isShowRecommend = props.isShowRecommend

    const truncateValue = (value) => {
        if (!value) return ''
        if (isNaN(value)) return value

        const decimalPlaces = value.toString().split('.')[1]
        return decimalPlaces?.length > 3 ? Number(value).toFixed(3) : Number(value)
    }

    const Select = ({ options, selectedValue, name, title, type, data }) => {
        return (
            <select name={name} value={ selectedValue } onChange={ (e) => inputChangeHandler(e, type, title, data) }>
                {
                    options.map(option => {
                        return (
                            <option key={ option } value={ option }>{ option }</option>
                        )
                    })
                }
            </select>
        )
    }

    const inputChangeHandler = (e, type, title, data) => {
        const elementName = e.target.name
        const newValue = e.target.value

        let indexName
        const tempNewData = data.map(row => {
            if (row.elementName === elementName) {
                indexName = row.indexName
                return { ...row, values: { ...row.values, selected: newValue } }
            }

            return row
        })

        setEditedData({ type, title, indexName, newValue, newData: tempNewData })
        setSetup((prev) => {
            return prev.map(setup => {
                if (setup.Parameter === indexName) {
                    return { ...setup, Base: newValue }
                }

                return setup
            })
        })
    }

    const maximizeOnClickHandler = (e) => {
        const { title, type } = e.target.dataset

        let data
        if (type === 'alignment') {
            data = alignmentData[title.toLowerCase()]
        } else if (type === 'chassis') {
            data = chassisData[title.toLowerCase()]
        } else if (type === 'aero') {
            data = aeroData[title.toLowerCase()]
        }

        setIsEditMode(true)
        setDataToEdit({ title, data, type })
    }

    const AlignmentAeroTable = ({ type, title, data }) => {
        return (
            <div>
                <table className={ type === 'alignment' ? 'summary-small-table' : 'summary-single-row' }>
                    { type === 'alignment' &&  
                        <thead>
                            <tr>
                                <td className='center td-dark' colSpan={2}>
                                    { title }
                                    <span className='table-maximize' data-title={ title } data-type={ type } onClick={ maximizeOnClickHandler }></span>
                                </td>
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {
                            data.map((row, index) => {
                                return (
                                    <tr key={ index }>
                                        <td className='td-dark short'>
                                            { row.elementName.toUpperCase() }
                                            { type === 'aero' && <span className='table-maximize' data-title={ title } data-type={ type } onClick={ maximizeOnClickHandler }></span> }
                                        </td>
                                        <td className={title === 'FL' || title === 'RL' || row.constrain === 'ON' ? 'td-input-disabled' : 'td-input-enabled'}>
                                            {
                                                title === 'FL' || title === 'RL' || row.constrain === 'ON' ?
                                                    truncateValue(row.values['selected'])
                                                : row.options ? 
                                                    <Select
                                                        options= { row.options }
                                                        selectedValue={ row.values['selected'] }
                                                        name={ row.elementName }
                                                        type={ type}
                                                        title={ title }
                                                        data={ data }
                                                    />
                                                : <input
                                                        type='number'
                                                        name={ row.elementName }
                                                        value={ truncateValue(row.values['selected']) }
                                                        onChange={ (e) => inputChangeHandler(e, type, title, data) }
                                                        data={ data }
                                                    />
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    const ChassisTable = ({ data }) => {
        return (
            <div>
                <table className='summary-chassis-table'>
                    <thead>
                        <tr>
                            <td className='td-transparent'></td>
                            <td className='center td-dark'>
                                FRONT
                                <span className='table-maximize' data-title='FRONT' data-type='chassis' onClick={ maximizeOnClickHandler }></span>
                            </td>
                            <td className='center td-dark'>
                                REAR
                                <span className='table-maximize' data-title='REAR' data-type='chassis' onClick={ maximizeOnClickHandler }></span>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.front.map((row, index) => {
                                return (
                                    <tr key={ index }>
                                        <td className='td-dark short'>{ row.elementName.toUpperCase() }</td>
                                        <td className={row.elementName === 'damper' || row.constrain === 'ON' ? 'td-input-disabled' : 'td-input-enabled' }>
                                        {
                                            row.constrain === 'ON' ?
                                                truncateValue(row.values['selected'])
                                            : row.options ?
                                                <Select
                                                    options= { row.options }
                                                    selectedValue={ row.values['selected'] }
                                                    name={ row.elementName }
                                                    type='chassis'
                                                    title='front'
                                                    data={ data.front }
                                                />
                                            : row.elementName === 'damper' ?
                                                ''
                                            : <input
                                                    type='number'
                                                    name={ row.elementName }
                                                    value={ truncateValue(row.values['selected']) }
                                                    onChange={ (e) => inputChangeHandler(e, 'chassis', 'front', data.front) }
                                                />
                                        }
                                        </td>
                                        <td className={row.elementName === 'damper' ? 'td-input-disabled' : 'td-input-enabled' }>
                                            {
                                                row.options ? 
                                                    <Select
                                                        options= { data.rear[index].options }
                                                        selectedValue={ data.rear[index].values['selected'] }
                                                        name={ data.rear[index].elementName }
                                                        type='chassis'
                                                        title='rear'
                                                        data={ data.rear }
                                                    />
                                                : row.elementName === 'damper' ?
                                                    ''
                                                :
                                                    <input
                                                        type='number'
                                                        name={ data.rear[index].elementName }
                                                        value={ truncateValue(data.rear[index].values['selected']) }
                                                        onChange={ (e) => inputChangeHandler(e, 'chassis', 'rear', data.rear) }
                                                    />
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <>
            <div className='summary-container flex'>
                <div className='summary-left flex'>
                    <div className='summary-left-left'>
                        <AlignmentAeroTable type='alignment' title='FL' data={ alignmentData.fl } />
                        <AlignmentAeroTable type='alignment' title='RL' data={ alignmentData.rl } />
                    </div>
                    <div className='summary-left-middle car-top'></div>
                    <div className='summary-left-right'>
                        <AlignmentAeroTable type='alignment' title='FR' data={ alignmentData.fr } />
                        <AlignmentAeroTable type='alignment' title='RR' data={ alignmentData.rr } />
                    </div>
                </div>
                <div className='summary-right'>
                    <div>
                        <AlignmentAeroTable type='aero' title='REAR' data={ aeroData.rear } />
                    </div>
                    <div className='summary-right-middle car-side'></div>
                    <div>
                        <ChassisTable data={ chassisData } />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SetupUserInputSummaryTab