import { useEffect, useState, useRef } from 'react'

import ClassSelectElement from './ClassSelectElement'
import CircularProgress from '@mui/material/CircularProgress'
import OutingCarNumberTable from './OutingCarNumberTable'
import TimingDatasTable from './TimingDatasTable'
import OutingTopTable from './OutingTopTable'
import OutingBarGraph from './OutingBarGraph'

import { useTranslation } from 'react-i18next'

const OutingAnalysis = (props) => {
    const { t } = useTranslation(['strategy', 'general'])

    const raceDetailId = props.raceDetailId

    const viewHandlerButtonElement = useRef()
    const classHandlerButtonElement = useRef()
    const changeViewElement = useRef()

    const [ bestLap, setBestLap ] = useState(null)
    const [ bestOutingDatas, setBestOutingDatas ] = useState([])
    const [ carClassDatas, setCarClassDatas ] = useState([])
    const [ carNumberColor, setCarNumberColor ] = useState(null)
    const [ dataMode, setdatatMode ] = useState('bestLapTime')
    const [ outingDatasObject , setOutingDatasObject ] = useState({})
    const [ individualBestLap, setIndividualBestLap ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)
    const [ lastOutingData, setLastOutingData ] = useState([])
    const [ selectedClasses, setSelectedClasses ] = useState([])
    const [ timingDatas, setTimingDatas ] = useState([])
    const [ selectedCarNumber, setSelectedCarNumber] = useState(null)
    const [ sectorLength, setSectorLength ] = useState(null)
    const [ selectedPitStopNumber, setSelectedPitStopNumber ]= useState(null)
    const [ sortMode, setSortMode ] = useState('lapTime')
    const [ showClassSelectElement, setShowClassSelectElement ] = useState(false)
    
    const TopButtonStyle = {
        color: 'white',
        margin: '0 5px 0 0',
        width: '120px'
    }

    const handleClassSelection = () => {
        showClassSelectElement !== true ? 
            setShowClassSelectElement(true) : 
            setShowClassSelectElement(false) 
    }

    const handleSelectedClassesChange = (newSelectedClasses) => {
        setSelectedClasses(newSelectedClasses)
    }

    const findCarNumberOfMinTimeCar = (bestOutingDatas) => {
        if (bestOutingDatas) {
            const bestOutingDatasValues = Object.values(bestOutingDatas).flat()
            const minTimeData = bestOutingDatasValues.find(lap => lap.TIME === Math.min(...bestOutingDatasValues.map(lap => lap.TIME)))
            const minTimeCarNumber = minTimeData ? minTimeData.CAR_NUMBER : null

            if(minTimeCarNumber){
                setSelectedCarNumber(minTimeCarNumber)
            }    
        }
    }

    const findPitStopTimeOfMinTimeCar = (bestOutingDatas) => {
        if (bestOutingDatas) { 
            const bestOutingDatasValues = Object.values(bestOutingDatas).flat()
            const minTimeData = bestOutingDatasValues.find(lap => lap.TIME === Math.min(...bestOutingDatasValues.map(lap => lap.TIME)))
            const minTimePitStopTime = minTimeData ? minTimeData.PIT_STOP_TIME : null
            
            if(minTimePitStopTime !== null){
                setSelectedPitStopNumber(minTimePitStopTime)
            }
        }
    }

    const findMinTimePitStopTime = (bestOutingDatas, carNumber) => {       
        const lapDatas = bestOutingDatas[carNumber]
        const minTimeData = lapDatas.find(lap => lap.TIME === Math.min(...lapDatas.map(lap => lap.TIME)))  
        const minTimePitStopTime = minTimeData ? minTimeData.PIT_STOP_TIME : null
            
        if(minTimePitStopTime !== null){
            setSelectedPitStopNumber(minTimePitStopTime)
        }
    }

    useEffect(() => {
        const getLastOuting = (raceDetailId) => {
            fetch(`/api/race/getLapDataOfAllCarsMaxPitStopTimeByRaceDetailId/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                    setLastOutingData(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getBestLap = (raceDetailId) => {
            fetch(`/api/lap/getBestLapByRaceDetailedId/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                    setBestLap(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getBestOuting = (raceDetailId) => {
            fetch(`/api/lap/getBestLapPitStopTimeByRaceDetailId/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                    setBestOutingDatas(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getCarClasses = (raceDetailId) =>{
            fetch(`/api/car/getCarClasses/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                    setCarClassDatas(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getCarNumberColor = (raceDetailId) => {
            fetch(`/api/car/getCarNumberColor/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                    setCarNumberColor(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getCircuit = (raceDetailId) => {
            fetch(`/api/circuit/getCircuitInfo/${raceDetailId}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200) {
                    let data = json.data
                    setSectorLength(data[0].SECTOR_LENGTH)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }
        setIsLoading(true)
        getLastOuting(raceDetailId)
        getBestLap(raceDetailId)
        getBestOuting(raceDetailId)
        getCarClasses(raceDetailId)
        getCarNumberColor(raceDetailId)
        getCircuit(raceDetailId)
    }, [raceDetailId])

    useEffect(() => {
        const uniqueClasses = [...new Set(carClassDatas.map(carClassData => carClassData.CLASS))].sort()
        setSelectedClasses(uniqueClasses)
    }, [carClassDatas])

    useEffect(()=>{
        if(bestOutingDatas !== undefined){
            findCarNumberOfMinTimeCar(bestOutingDatas)
            findPitStopTimeOfMinTimeCar(bestOutingDatas)

    }}, [bestOutingDatas])

    useEffect(() => {     
        const handleCarNumberClick = (carNumber) => {
            fetch(`/api/lap/getOutingDatasByRaceDetailIdCarNumber/${raceDetailId}/${carNumber}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                    setOutingDatasObject(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getIndividualBestLapByRaceDetailIdAndCarNumber = (raceDetailId, carNumber) =>{
            fetch(`/api/lap/getIndividualBestLapByRaceDetailIdAndCarNumber/${raceDetailId}/${carNumber}`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                setIndividualBestLap(json.data[0]['MIN(TIME)'])
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        if (selectedCarNumber !== null){
            findMinTimePitStopTime(bestOutingDatas, selectedCarNumber)
            getIndividualBestLapByRaceDetailIdAndCarNumber(raceDetailId, selectedCarNumber)
            handleCarNumberClick(selectedCarNumber)
            setIsLoading(false)
        }
    }, [selectedCarNumber])

    useEffect(() => {
        if (selectedPitStopNumber !== null){
            const handlePitStopNumberClick = () => {   
                fetch(`/api/race/getTimingDatasByRaceDetailIdCarNumberPitStopTime/${raceDetailId}/${selectedCarNumber}/${selectedPitStopNumber}`)
                .then(response => response.json())
                .then(json => {
                    if(json.status === 200) 
                        setTimingDatas(json.data)
                    })
                    .catch((error) => {
                        alert(t('pleaseContactAdmin', {ns: 'general'}))
                    })
            }
            handlePitStopNumberClick()
        }
        }, [outingDatasObject ,selectedPitStopNumber])
    
    useEffect(() => {
        function handleOutsideClick(event) {
            if (viewHandlerButtonElement.current && viewHandlerButtonElement.current.contains(event.target)) return
            if (classHandlerButtonElement.current && classHandlerButtonElement.current.contains(event.target)) return
            if (changeViewElement.current && changeViewElement.current.contains(event.target)) return
            setShowClassSelectElement(false)
        }
    
        document.addEventListener('click', handleOutsideClick)
    
        return () => {
            document.removeEventListener('click', handleOutsideClick)
        }
    }, [])
    
    return(
        <>
            <div className='fp-selecter-boxs' style={{margin: '30px 0 0 0', width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                <div className='selecter-container' >
                    <div>
                        <p>{t('outingAnalysis.dataSelection')}：</p>
                        <input checked={dataMode === 'bestLapTime'} type='radio' id='data-select-bast-lap' className='visually-hidden' name='dataselect' onChange={() => setdatatMode('bestLapTime')}/>
                        <label htmlFor='data-select-bast-lap'>Best Lap</label>
                        <input type='radio' id='data-select-last-outing' className='visually-hidden' name='dataselect' onChange={() => setdatatMode('lastOutingTime')}/>
                        <label checked={dataMode === 'lastOutingTime'} htmlFor='data-select-last-outing'>{t('outingAnalysis.lastOuting')}</label>
                    </div> 

                    <div>
                        <p>{t('general.sort')}：</p>
                        <input checked={sortMode === 'lapTime'} type='radio' id='lap-order' className='visually-hidden' name='sortselect'  onChange={() => setSortMode('lapTime')}/>
                        <label htmlFor='lap-order'>{t('outingAnalysis.lapTimeOrder')}</label>
                        <input checked={sortMode === 'carNo'} type='radio' id='car-no' className='visually-hidden' name='sortselect' onChange={() => setSortMode('carNo')}/>
                        <label htmlFor='car-no'>{t('outingAnalysis.carNoOrder')}</label>
                    </div>

                    
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>           
                    <button name="selectedClass" style={{width : '150px', color:'#FFFF'}} onClick={handleClassSelection} ref={viewHandlerButtonElement}>
                        {t('general.classSelection')}
                    </button>
                    <div className='class-select-element' ref={changeViewElement}>
                        {showClassSelectElement && 
                            <ClassSelectElement 
                                state={showClassSelectElement} 
                                carClassDatas = {carClassDatas} 
                                selectedClasses={selectedClasses}
                                handleSelectedClassesChange={handleSelectedClassesChange} 
                                style={{ position: 'absolute'}}
                            />}
                    </div>
                </div>  
            </div>

            {isLoading ? 
                <div style={overlayStyle}>
                    <h1>{t('general.readingData')}</h1>
                    <CircularProgress />
                </div>
            :null}
            
            {dataMode === 'bestLapTime' ?
                <OutingTopTable
                    bestLap = { bestLap }
                    carClassDatas={carClassDatas}
                    carNumberColor = {carNumberColor}
                    inputData={bestOutingDatas}
                    selectedCarNumber={selectedCarNumber}
                    selectedClasses={selectedClasses}
                    setSelectedCarNumber={setSelectedCarNumber}
                    sort={sortMode}
                /> 
            :
                <OutingTopTable
                    bestLap = { bestLap }
                    carClassDatas={carClassDatas}
                    carNumberColor = {carNumberColor}
                    inputData = {lastOutingData} 
                    selectedCarNumber = {selectedCarNumber}
                    selectedClasses={selectedClasses}
                    setSelectedCarNumber = {setSelectedCarNumber}                    
                    sort = {sortMode} 
                />
            }
            
            <br></br>
            
            <div className='draiver-detail-table' style={{display: 'flex', justifyContent:'space-between',}}>
                {Object.keys(outingDatasObject).length !== 0 ? (
                    <div className='outing-list-container'>
                        <div className='outing-list'>
                            <span>{t('outingAnalysis.outingList')}</span>
                            <span>Car Number : {selectedCarNumber}</span>
                        </div>
                    <OutingCarNumberTable
                        bestLap = { bestLap }
                        individualBestLap = { individualBestLap }  
                        outingDatasObject={outingDatasObject} 
                        selectedCarNumber ={selectedCarNumber} 
                        selectedPitStopNumber = {selectedPitStopNumber} 
                        setSelectedPitStopNumber={setSelectedPitStopNumber} 
                        
                    />
                </div>
                ) : null}
                {timingDatas.length !== 0 && selectedPitStopNumber !== null ? (
                <div className='sector-list-container'>
                <div className='sector-list' >
                    <span>SectorTime</span>
                    <span>CarNumber : {selectedCarNumber},  Outing { selectedPitStopNumber }</span>
                </div>
                    <TimingDatasTable
                        bestLap = { bestLap }
                        individualBestLap = { individualBestLap }
                        timingDatas={ timingDatas }
                        sectorLength = { sectorLength }
                        selectedCarNumber = {selectedCarNumber}/>
                </div>
                ) : null}
            </div>

            <br></br> 
            
            <OutingBarGraph lapDatas = {dataMode === 'bestLapTime' ? bestOutingDatas : lastOutingData}/>
            
            <br></br>           
        </>
    )
}

export default OutingAnalysis

const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    color: '#FFFFFF',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000
}