import DropdownSelectBox from './DropdownSelectBox'
import SelectButtons from './SelectButtons'

import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'

const DatabaseUpdateKeysOrganism = ( props ) => {
    const { t } = useTranslation(['strategy'])

    return (
        <>
            <div style={{ display: 'flex'}}>
                <DropdownSelectBox 
                    label={props.eventLabel}
                    values = {props.eventList}
                    selectedValue = {props.selectedEventId}
                    handleSelectValueChange={props.handleEventSelect}
                    handleLabelChange={props.handleEventLabelChange}
                />
                <br></br>
                <DropdownSelectBox
                    label={props.databaseLabel}
                    values={[
                        {id: 'LAP_DATA', name: 'LAP_DATA'},
                        {id: 'SECTOR_DATA', name: 'SECTOR_DATA'}
                    ]}
                    selectedValue = {props.selectedDatabaseTable}
                    handleSelectValueChange={props.handleSelectedDatabaseTableChange}
                    handleLabelChange={props.handleDatabaseLabelChange}
                />
            </div>
            <br></br>
            <div>
                {(props.selectedDatabaseTableName !== '' &&  props.raceDetails.length !== 0) ?
                    <SelectButtons
                        titleText = {t('databaseUpdateKeysOrganism.raceSelection')}
                        buttons={props.raceDetails.map(raceDetail => ({
                            id: raceDetail.ID,
                            label: raceDetail.RACE_DETAIL_NAME
                        }))}
                        handleButtonSelect={props.handleRaceDetailIdChange}
                        selectedButtonId={props.selectedRaceDetailId}/>    
                : null}
                 <br></br>
                {(props.databaseColumns && props.databaseColumns.length !== 0 && !props.isLoading) ?
                    <SelectButtons
                        titleText = {t('databaseUpdateKeysOrganism.databaseColumnSelection')}
                        buttons={props.databaseColumns.map(column => ({id: column, label: column}))}
                        handleButtonSelect={props.handleDatabaseColumnSelectChange}
                        selectedButtonId={props.selectedDatabaseColumnName}
                    />
                : (props.isLoading) ?
                    <div className='display-race-none'>
                        <h1>{t('general.readingData')}</h1>
                        <CircularProgress />
                    </div>
                : null}                   
            </div>
        </>
    )
}

export default DatabaseUpdateKeysOrganism