import { useState, useEffect } from 'react'

import FormControl from '@mui/material/FormControl'

const DropdownSelectBox = ( props ) => {    
    const [ selectedValue, setSelectedValue] = useState(props.selectedValue)
    const [label, setLabel] = useState(props.label)
    
    const handleSelectValueChange = (event) => {
        const valueAsString = event.target.value.toString()
        setSelectedValue(valueAsString)
        props.handleSelectValueChange(valueAsString)
        const selectedOption = props.values.find(option => option.id.toString() === valueAsString)
        if (selectedOption) {
            setLabel(selectedOption.name)
            if (props.handleLabelChange) {
                props.handleLabelChange(selectedOption.name)
            }
        }
    }
    
    return(
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <div>
                    <FormControl sx={{ m: 1, minWidth: 180, color: 'white', borderBottom: '1px solid white' }}>
                        <select
                            value={selectedValue}
                            onChange={handleSelectValueChange}
                        >
                            <option value = ''>{label}</option>
                            {
                                props.values && props.values
                                    .filter(event => event.id.toString() !== selectedValue)
                                    .map((event, index) => (
                                        <option
                                            key={index}
                                            value={event.id}
                                            name={event.name}
                                        >
                                            {event.name}
                                        </option>
                                    ))
                            }
                        </select>
                    </FormControl>
                </div>
            </div>
        </>
    )
}

export default DropdownSelectBox