import React from 'react'

import CarObject from '../components/CarObject'

/*
    track value
    1 = Suzuka/4sector
    2 = Sugo
    3 = FSW/3sector
    4 = Autopolis
    5 = motegi/4sector
    6 = Okayama
    7 = Sepang
    8 = Buriram
*/
const Track = ({id, circuitId, drivers, carNumberTeamColorObj, mapSize, followCarNumber, rivalCarNumbers, mapLeaders, mapDisplayLeaders, battleData, carClasses, displayMapGroups, currentSectorLocation}) => {

    let width = '400px' 
    let height = undefined

    if (mapSize === 'small') {
        width = '400px'
    }
    else if (mapSize === 'medium') {
        width = '460px'
    }
    else if (mapSize === 'large') {
        width = '520px'
    } else if (mapSize === 'fullScreen') {
        width = '70%'
        height = '48vh'
    }

    const svgStyle = {
        width,
        height,
        style: {
            background: '#FFFFFF'
        },
        viewBox_Suzuka: mapSize === 'fullScreen' ? '-500 -75 600 700' : '-900 -400 1450 1400',
        viewBox_Sugo: mapSize === 'fullScreen' ? '-150 -600 600 900' : '-525 -900 1400 1400',
        viewBox_FSW: mapSize === 'fullScreen' ? '-200 -100 600 600' : '-600 -500 1400 1400',
        viewBox_Autopolis: mapSize === 'fullScreen' ? '-1000 -450 1000 800' : '-1175 -750 1400 1400',
        viewBox_Motegi: mapSize === 'fullScreen' ? '-500 -100 600 1250' : '-850 -200 1400 1400',
        viewBox_Okayama: mapSize === 'fullScreen' ? '-800 -100 1000 1000' : '-1050 -250 1400 1400',
        // viewBox_Sepang: mapSize === 'fullScreen' ? '-500 -250 600 600' : '-50 -150 1400 1400',
        viewBox_Chang: mapSize === 'fullScreen' ? '-700 -1150 600 1250' : '-1050 -1200 1400 1400'
    }

    if (circuitId === 1) {
        /* Suzuka svg track */
        return (
            <svg className = 'svg_view' id = {id} width = {svgStyle.width} height = {svgStyle.height} style = {svgStyle.style} viewBox = {svgStyle.viewBox_Suzuka}>
                <path id = {`track${id}`} stroke='#000' strokeWidth='2' strokeDasharray='3 1 1' fill='none' d='m0,0h349.1c87.41,0,123.95,51.91,90.85,91.21-37.54,44.57-88.4-7.14-126.12,1.91s-47.87,50.23-80.92,50.23-50.27-31.03-85.02-27.65c-30.96,3.01-53.14,60.38-102.14,49.68-15.43-3.37-23.41-15.76-30.56-29.86-11.71-23.09-21.17-50.73-57.42-50.73-58.54,0-140.3,43.13-126.75,176.26,2.83,27.77,7.77,45.51,1.37,69.93s-39.79,72.14-62.33,75.33c-13.98,1.98-46.85-14.81-79.27-34.65-35.08-21.48-69.62-46.53-79.07-55.26-29.45-27.19-31.5-90.14-59.85-79.5s-.67,58.23,7.54,96.57c7.58,35.41,9.19,99.8-108.36,152.26-74.07,33.06-131.9,7.85-194.93-15.42-31.85-11.76-74.9,4.63-84.5,42.72-11.24,44.57,35.5,70.66,76.95,63.37,60.4-10.61,199.64-55.79,325.7-117.02,30.44-14.79,55.23-26.29,75.7-35.8,67.43-31.32,88.11-41.02,110.12-75.03,32.97-50.94,18.13-171.96,18.13-191.64,0-21.25-3.2-41.41,27.06-41.41s22.71-16.26,22.71-35.35c0-37.04,45.12-80.2,91.65-80.16s80.37,0,80.37,0Z'/>
                <path id = {`sec1${id}`} fill = 'none' d = 'M0,0h349.1c87.4,0,123.9,51.9,90.8,91.2-37.5,44.6-88.4-7.1-126.1,1.9s-47.9,50.2-80.9,50.2-50.3-31-85-27.6c-31,3-53.1,60.4-102.1,49.7-15.4-3.4-23.4-15.8-30.6-29.9' />
                <path id = {`sec2${id}`} fill = 'none' d = 'M15.2,135.5c-11.7-23.1-21.2-50.7-57.4-50.7-58.5,0-140.3,43.1-126.8,176.3,2.8,27.8,7.8,45.5,1.4,69.9s-39.8,72.1-62.3,75.3c-14,2-46.8-14.8-79.3-34.7' />
                <path id = {`sec3${id}`} fill = 'none' d = 'm -309.2,371.7 c -35.1,-21.5 -69.6,-46.5 -79.1,-55.3 -29.5,-27.2 -31.5,-90.1 -59.9,-79.5 -28.4,10.6 -0.7,58.2 7.5,96.6 7.6,35.4 9.2,99.8 -108.4,152.3 -74.1,33.1 -131.9,7.8 -194.9,-15.4 -31.8,-11.8 -74.9,4.6 -84.5,42.7 -11.2,44.6 35.5,70.7 76.9,63.4 60.4,-10.6 199.6,-55.8 325.7,-117 30.4,-14.8 55.2,-26.3 75.7,-35.8' />
                <path id = {`sec4${id}`} fill = 'none' d = 'M-425.7,459.4c30.4-14.8,55.2-26.3,75.7-35.8,67.4-31.3,88.1-41,110.1-75,33-50.9,18.1-172,18.1-191.6s-3.2-41.4,27.1-41.4,22.7-16.3,22.7-35.4C-172,43.1-126.9,0-80.4,0S0,0,0,0' />
                <CarObject
                    drivers={drivers}
                    id={id}
                    carNumberTeamColorObj={carNumberTeamColorObj}
                    followCarNumber={followCarNumber}
                    rivalCarNumbers={rivalCarNumbers}
                    mapLeaders={mapLeaders}
                    mapDisplayLeaders={mapDisplayLeaders}
                    battleData={battleData}
                    carClasses={carClasses}
                    mapSize={mapSize}
                    displayMapGroups={displayMapGroups}
                    currentSectorLocation={currentSectorLocation}
                />
                <use id={`use_${id}`} href='#'/>
            </svg>
        )
    } else if (circuitId === 2) {
        /* Sugo svg track */
        return (
            <svg className = 'svg_view' id = {id} width = {svgStyle.width} height = {svgStyle.height} style = {svgStyle.style} viewBox = {svgStyle.viewBox_Sugo}>
                <path id = {`track${id}`} stroke='#000' strokeWidth='2' strokeDasharray='3 1 1' fill='none' d='M0,0s-247.05-85.86-259.32-89.36-62.15-12.7-49.4-63.72,10.2-40.82,12.76-51.02,11.9-54.42,56.97-43.37,118.2,37.42,134.35,42.52,55.27,11.9,81.63-28.06,96.09-134.35,103.74-146.26,16.16-58.67-39.97-56.12c-14.41.65-26.24,1.2-36.08,1.69-28.49,1.41-40.32,2.37-49.8,4.27-12.76,2.55-23.44,7.35-26.6,25.78s-11.66,30.34-28.67,36.29-229.59,79.93-249.15,87.59-40.82,8.5-62.08-26.36-28.06-46.77-42.52-68.03-27.21-85.88,22.11-92.69,547.56-46.36,593.23-51.93,62.39-5.57,84.67-7.8,137.03,6.68,158.2,5.57,56.59,2,66.62,26.51,2.61,44.22-38.99,103.61c-24.19,34.53-70.81,96.83-85.08,117.3-13.98,20.07-17.19,40.01-1.59,66.75s41.22,86.9,52.36,102.5,17.83,25.62,52.36,28.97c20.07,1.94,75.52-1.01,123.47-3.82,34.55-2.03,65.22-3.98,75.95-3.98,25.62,0,174.04-10.93,174.21,137.51.16,140.22-160.31,173.52-253.05,144.11-65.24-20.69-570.34-198.44-570.34-198.44Z'/>
                <path id = {`sec1${id}`} fill = 'none' d = 'M0,0s-247.1-85.9-259.3-89.4-62.1-12.7-49.4-63.7,10.2-40.8,12.8-51,11.9-54.4,57-43.4c45.1,11,118.2,37.4,134.3,42.5s55.3,11.9,81.6-28.1,96.1-134.4,103.7-146.3,16.2-58.7-40-56.1c-14.4.6-26.2,1.2-36.1,1.7' />
                <path id = {`sec2${id}`} fill = 'none' d = 'M4.7-433.6c-28.5,1.4-40.3,2.4-49.8,4.3-12.8,2.5-23.4,7.4-26.6,25.8s-11.7,30.3-28.7,36.3-229.6,79.9-249.1,87.6-40.8,8.5-62.1-26.4-28.1-46.8-42.5-68-27.2-85.9,22.1-92.7c49.3-6.8,547.6-46.4,593.2-51.9' />
                <path id = {`sec3${id}`} fill = 'none' d = 'M161.3-518.4c45.7-5.6,62.4-5.6,84.7-7.8s137,6.7,158.2,5.6,56.6,2,66.6,26.5,2.6,44.2-39,103.6c-24.2,34.5-70.8,96.8-85.1,117.3-14,20.1-17.2,40-1.6,66.8s41.2,86.9,52.4,102.5,17.8,25.6,52.4,29c20.1,1.9,75.5-1,123.5-3.8' />
                <path id = {`sec4${id}`} fill = 'none' d = 'M573.2-79.2c34.6-2,65.2-4,75.9-4,25.6,0,174-10.9,174.2,137.5.2,140.2-160.3,173.5-253.1,144.1C505.1,177.8,0,0,0,0' />
                <CarObject
                    drivers={drivers}
                    id={id}
                    carNumberTeamColorObj={carNumberTeamColorObj}
                    followCarNumber={followCarNumber}
                    rivalCarNumbers={rivalCarNumbers}
                    mapLeaders={mapLeaders}
                    mapDisplayLeaders={mapDisplayLeaders}
                    battleData={battleData}
                    carClasses={carClasses}
                    mapSize={mapSize}
                    displayMapGroups={displayMapGroups}
                    currentSectorLocation={currentSectorLocation}
                />
                <use id={`use_${id}`} href='#'/>
            </svg>
        )
    } 
    else if (circuitId === 3) {
        /* FSW svg track */
        return (
            <svg className = 'svg_view' id = {id} width = {svgStyle.width} height= {svgStyle.height} style = {svgStyle.style} viewBox = {svgStyle.viewBox_FSW}>
                <path id = {`track${id}`}  stroke = '#000' strokeWidth = '2' strokeDasharray = '3 1 1' fill = 'none' d = 'M0,0c51.5-.3,103.6-.8,154-1.5,189.1-2.4,355.2-6.5,383.4-6.3,68.6.5,202-28.4,203.1,30.4.6,30.3-47.3,76.4-140,106.7-56.4,18.5-107,22.9-147.7,29.9-29.6,5.1-53.9,11.6-71.5,25.9-40.4,32.9-29.3,76.2-38.1,115.2-8,35.5-34.4,117-124.3,109.5s-84.4-108.5-78.5-136.5c6-28.5,20.4-111.5-54.1-90.3-48.6,13.8-57.8,89.7-122.2,155.8-69.9,71.8-203.7,78.8-269.4,79.4-14.6.1-25.8,0-32.3.1-62,1.4-106.8,2.5-92.3-63.3,7.2-33.1-30.9-58.3-44.6-76.1-28-36.4-33.3-109.6,30.4-110.3,72.2-.8,96.2-4.5,112.5-28.3,20.4-29.8,6.9-69.9-43.2-62.4s-108.5,23.5-132.4-4.5C-530.1,46.5-516.5-2.5-427.5-2.5c0,0,209.4,3.8,427.4,2.5h.1Z'/>
                <path id = {`sec1${id}`} fill = 'none' d = 'M154.1-1.5c189.1-2.4,355.2-6.5,383.4-6.3,68.6.5,202-28.4,203.1,30.4.6,30.3-47.3,76.4-140,106.7-56.4,18.5-107,22.9-147.7,29.9' />
                <path id = {`sec2${id}`} fill = 'none' d = 'M452.9,159.2c-29.6,5.1-53.9,11.6-71.5,25.9-40.4,32.9-29.3,76.2-38.1,115.2-8,35.5-34.4,117-124.3,109.5s-84.4-108.5-78.5-136.5c6-28.5,20.4-111.5-54.1-90.3-48.6,13.8-57.8,89.7-122.2,155.8-69.9,71.8-203.7,78.8-269.4,79.4' />
                <path id = {`sec3${id}`} fill = 'none' d = 'M-304.8,418.1c-14.6.1-25.8,0-32.3.1-62,1.4-106.8,2.5-92.3-63.3,7.2-33.1-30.9-58.3-44.6-76.1-28-36.4-33.3-109.6,30.4-110.3,72.2-.8,96.2-4.5,112.5-28.3,20.4-29.8,6.9-69.9-43.2-62.4-50.1,7.5-108.5,23.5-132.4-4.5-22.9-26.9-12-80.6,78.7-75.9C-428-2.6-217.6,1.3.4,0c51.5-.3,103.6-.8,154-1.5' />
                <CarObject
                    drivers={drivers}
                    id={id}
                    carNumberTeamColorObj={carNumberTeamColorObj}
                    followCarNumber={followCarNumber}
                    rivalCarNumbers={rivalCarNumbers}
                    mapLeaders={mapLeaders}
                    mapDisplayLeaders={mapDisplayLeaders}
                    battleData={battleData}
                    carClasses={carClasses}
                    mapSize={mapSize}
                    displayMapGroups={displayMapGroups}
                    currentSectorLocation={currentSectorLocation}
                />
                <use id={`use_${id}`} href='#'/>
            </svg>
        )
    } else if (circuitId === 4) {
        /* Autopolis svg track */
        return (
            <svg className = 'svg_view' id = {id} width = {svgStyle.width} height= {svgStyle.height} style = {svgStyle.style} viewBox = {svgStyle.viewBox_Autopolis}>
                <path id = {`track${id}`}  stroke = '#000' strokeWidth = '2' strokeDasharray = '3 1 1' fill = 'none' d = 'M0,0s-65.45,80.61-90.22,104.46-37.61,36.69-77.97,11.92-65.43-43.95-83.47-47.7c-17.12-3.55-68.07,19.55-83.46,37.58-16.93,19.83-17.19,37.79-13.52,79.07s-3.03,51.96-23.19,53.96c-22.9,2.28-38.48,2.69-45.05-70.46-5.77-64.28,19.76-83.34,43.71-114.18,16.88-21.74,48.12-68.54,26.1-124.5-22.02-55.96-95.97-63.92-143.18-54.36-54.18,10.98-127.65,9.45-167.09-8.9-19.12-8.89-54.64-30.04-97.68-62.16-47.18-35.21-93.08-72.49-102.06-80.06-17.43-14.68-44.95-40.36-62.38-25.68s-7.34,51.37,15.59,86.23,140.88,297.18,147.69,309.13c7.74,13.59,19.05,40.67,19.35,60.19.41,26.72-37.7,59.98-51.46,70.07s-60.54,55.96-109.16,46.78-55.96-31.19-66.96-55.04-23.05-81.61-37.44-99.74c-13.05-16.43-25.06-28.14-59.49-32.15-22.04-2.56-70.02-11.22-70.02-70.84s47.7-67.88,72.47-59.62,37.61,6.42,51.37-3.67,44.72-29.6,60.54-44.03c19.76-18.02,15.89-52.63,8.09-78.72-7.34-24.56-30.82-66.83-49.53-90.9-20.41-26.25-24.52-79.67,1.08-108.08,28.73-31.88,68.54-35.06,92.51-23.42,29.17,14.15,44.17,33.27,52.42,36.02s186.21,2.75,227.49,2.75,538.59-3.38,574.12-3.38,135.32-3.38,159.01,1.69,35.95,16.57,37.48,41.11-5.6,126.6-29.37,167.23c-23.77,40.63-118.31,139.57-126.32,149.37Z'/>
                <path id = {`sec1${id}`} fill = 'none' d = 'M-614.9-356c41.3,0,538.6-3.4,574.1-3.4s135.3-3.4,159,1.7,35.9,16.6,37.5,41.1-5.6,126.6-29.4,167.2S8-9.8,0,0' />
                <path id = {`sec2${id}`} fill = 'none' d = 'M0,0s-65.4,80.6-90.2,104.5-37.6,36.7-78,11.9-65.4-44-83.5-47.7c-17.1-3.5-68.1,19.5-83.5,37.6-16.9,19.8-17.2,37.8-13.5,79.1s-3,52-23.2,54c-22.9,2.3-38.5,2.7-45-70.5-5.8-64.3,19.8-83.3,43.7-114.2,16.9-21.7,48.1-68.5,26.1-124.5s-96-63.9-143.2-54.4c-54.2,11-127.7,9.5-167.1-8.9-19.1-8.9-54.6-30-97.7-62.2-47.2-35.2-93.1-72.5-102.1-80.1' />
                <path id = {`sec3${id}`} fill = 'none' d = 'M-857.1-275.3c-17.4-14.7-44.9-40.4-62.4-25.7s-7.3,51.4,15.6,86.2,140.9,297.2,147.7,309.1c7.7,13.6,19,40.7,19.4,60.2.4,26.7-37.7,60-51.5,70.1s-60.5,56-109.2,46.8-56-31.2-67-55-23.1-81.6-37.4-99.7c-13.1-16.4-25.1-28.1-59.5-32.2-22-2.6-70-11.2-70-70.8s47.7-67.9,72.5-59.6c24.8,8.3,37.6,6.4,51.4-3.7,13.8-10.1,44.7-29.6,60.5-44,19.8-18,15.9-52.6,8.1-78.7-7.3-24.6-30.8-66.8-49.5-90.9-20.4-26.2-24.5-79.7,1.1-108.1,28.7-31.9,68.5-35.1,92.5-23.4,29.2,14.1,44.2,33.3,52.4,36s186.2,2.8,227.5,2.8' />
                <CarObject
                    drivers={drivers}
                    id={id}
                    carNumberTeamColorObj={carNumberTeamColorObj}
                    followCarNumber={followCarNumber}
                    rivalCarNumbers={rivalCarNumbers}
                    mapLeaders={mapLeaders}
                    mapDisplayLeaders={mapDisplayLeaders}
                    battleData={battleData}
                    carClasses={carClasses}
                    mapSize={mapSize}
                    displayMapGroups={displayMapGroups}
                    currentSectorLocation={currentSectorLocation}
                />
                <use id={`use_${id}`} href='#'/>
            </svg>
        )
    } else if (circuitId === 5) {
        /* Motegi svg track */
        return (
            <svg className = 'svg_view' id = {id} width = {svgStyle.width} height = {svgStyle.height} style = {svgStyle.style} viewBox = {svgStyle.viewBox_Motegi}>
                <path id = {`track${id}`} stroke='#000' strokeWidth='2' strokeDasharray='3 1 1' fill='none' d='M0,0c36.88,0,221.62,4.46,254.4,4.46s108.58,2.05,139.31,24.58,47.12,116.78-38.93,131.12-432.28-10.24-477.35-10.24-104.48-18.44-139.31,0c-29.1,15.41-65.41,136.92,60.92,176.25,24.86,7.74,56.02,12.3,94.78,12.23,122.63-.2,184.38-8,266.33-8s178.24.37,223.31,1.73,61.81,42.61,28.63,100.51c-36.11,63.03-137.21,186.43-200.72,200.77-79.56,17.97-190.73.27-254.04-16.39-77.85-20.49-118.83,20.49-143.41,49.17s-65.56,92.19-133.17,77.85-142.52-139.37-155.7-153.65c-24.58-26.63-65.56-45.07-88.09,16.39s-14.34,409.74-16.39,434.33-26.3,47.11-47.12,47.12-53.27-12.29-61.46-106.53c-4.25-48.89-15.12-244.99-24.59-427.27-8.79-169.09-16.38-326.29-16.38-343.05,0-45.62,24.58-77.85,84-65.56s102.44,34.83,153.65,26.63,97.3-52.93,114.73-106.53c26.9-82.74,65.03-74.44,153.65-71.7,98.48,3.04,222.95,5.78,222.95,5.78Z'/>
                <path id = {`sec1${id}`} fill = 'none' d = 'M.4,0c36.9,0,221.6,4.5,254.4,4.5s108.6,2,139.3,24.6c30.7,22.5,47.1,116.8-38.9,131.1s-432.3-10.2-477.3-10.2-104.5-18.4-139.3,0c-29.1,15.4-65.4,136.9,60.9,176.2' />
                <path id = {`sec2${id}`} fill = 'none' d = 'M-200.5,326.2c24.9,7.7,56,12.3,94.8,12.2,122.6-.2,184.4-8,266.3-8s178.2.4,223.3,1.7,61.8,42.6,28.6,100.5c-36.1,63-137.2,186.4-200.7,200.8-79.6,18-190.7.3-254-16.4-77.8-20.5-118.8,20.5-143.4,49.2s-65.6,92.2-133.2,77.8' />
                <path id = {`sec3${id}`} fill = 'none' d = 'M-319.2,743.9c-67.6-14.3-142.5-139.4-155.7-153.6-24.6-26.6-65.6-45.1-88.1,16.4s-14.3,409.7-16.4,434.3-26.3,47.1-47.1,47.1-53.3-12.3-61.5-106.5c-4.2-48.9-15.1-245-24.6-427.3' />
                <path id = {`sec4${id}`} fill = 'none' d = 'M-712.6,554.4c-8.8-169.1-16.4-326.3-16.4-343,0-45.6,24.6-77.9,84-65.6s102.4,34.8,153.7,26.6,97.3-52.9,114.7-106.5C-349.7-16.8-311.6-8.5-223-5.8-124.5-2.7,0,0,0,0' />
                <CarObject
                    drivers={drivers}
                    id={id}
                    carNumberTeamColorObj={carNumberTeamColorObj}
                    followCarNumber={followCarNumber}
                    rivalCarNumbers={rivalCarNumbers}
                    mapLeaders={mapLeaders}
                    mapDisplayLeaders={mapDisplayLeaders}
                    battleData={battleData}
                    carClasses={carClasses}
                    mapSize={mapSize}
                    displayMapGroups={displayMapGroups}
                    currentSectorLocation={currentSectorLocation}
                />
                <use id={`use_${id}`} href='#'/>
            </svg>
        )
    } else if (circuitId === 6) {
        /* Okayama svg track */
        return (
            <svg className = 'svg_view' id = {id} width = {svgStyle.width} height= {svgStyle.height} style = {svgStyle.style} viewBox = {svgStyle.viewBox_Okayama}>
                <path id = {`track${id}`}  stroke = '#000' strokeWidth = '2' strokeDasharray = '3 1 1' fill = 'none' d = 'M0,0s59.26-60.74,80-60.74,158.51,63.7,168.88,68.15,48.89,29.63,13.33,88.89c-22.18,36.97-156.78,225.55-259.22,369.04-61.77,86.53-111.86,156.65-118.54,167.24-17.78,28.15-41.48,53.33-84.44,53.33s-65.18-53.33-82.96-106.66c-17.78-53.33-32.59-155.55-84.44-158.51-51.85-2.96-80,4.44-120,26.67-40,22.22-100.74,51.85-125.92,69.63-25.18,17.78-48.89,41.48-56.29,84.44-7.41,42.96-38.52,62.22-85.92,77.03-17.78,5.56-37.43,20.49-55.91,37.84-30.8,28.92-58.35,64.57-68.53,74.75-16.3,16.3-53.33,59.26-90.37,26.67-37.04-32.59-54.81-74.07-23.7-118.51,31.11-44.44,91.85-91.85,109.63-103.7,17.78-11.85,179.25-100.74,216.29-127.4,37.04-26.67,373.32-256.29,402.95-277.03,29.63-20.74,65.18-31.11,75.55-20.74,10.37,10.37,22.22,29.63-32.59,77.03s-77.03,60.74-59.26,88.89c17.78,28.15,72.59,88.89,87.4,102.22,14.81,13.33,38.52,10.37,68.15-19.26,18.11-18.11,49.01-57.44,73.54-89.09,15.6-20.14,28.63-37.16,34.14-43.64,140.03-164.5,86.39-178.37,43.43-165.03-42.96,13.33-59.26,34.07-91.85,32.59-32.59-1.48-54.81-25.18-34.07-56.29,20.74-31.11,85.77-83.12,100.74-97.77Z'/>
                <path id = {`sec1${id}`} fill = 'none' d = 'M3,465.3c-61.8,86.5-111.9,156.6-118.5,167.2-17.8,28.2-41.5,53.3-84.4,53.3s-65.2-53.3-83-106.7-32.6-155.5-84.4-158.5-80,4.4-120,26.7c-40,22.2-100.7,51.8-125.9,69.6s-48.9,41.5-56.3,84.4c-7.4,43-38.5,62.2-85.9,77' />
                <path id = {`sec2${id}`} fill = 'none' d = 'M-755.2,678.8c-17.8,5.6-37.4,20.5-55.9,37.8-30.8,28.9-58.4,64.6-68.5,74.8-16.3,16.3-53.3,59.3-90.4,26.7-37-32.6-54.8-74.1-23.7-118.5,31.1-44.4,91.9-91.8,109.6-103.7,17.8-11.8,179.2-100.7,216.3-127.4,37-26.7,373.3-256.3,403-277s65.2-31.1,75.5-20.7c10.4,10.4,22.2,29.6-32.6,77s-77,60.7-59.3,88.9c17.8,28.2,72.6,88.9,87.4,102.2,14.8,13.3,38.5,10.4,68.2-19.3s49-57.4,73.5-89.1' />
                <path id = {`sec3${id}`} fill = 'none' d = 'M-52.4,330.1c15.6-20.1,28.6-37.2,34.1-43.6C121.7,122,68.1,108.1,25.1,121.5c-43,13.3-59.3,34.1-91.8,32.6-32.6-1.5-54.8-25.2-34.1-56.3S-15,14.7,0,0C0,0,59.2-60.7,80-60.7S238.5,3,248.8,7.4s48.9,29.6,13.3,88.9C240,133.3,105.4,321.9,2.9,465.3' />

                <CarObject
                    drivers={drivers}
                    id={id}
                    carNumberTeamColorObj={carNumberTeamColorObj}
                    followCarNumber={followCarNumber}
                    rivalCarNumbers={rivalCarNumbers}
                    mapLeaders={mapLeaders}
                    mapDisplayLeaders={mapDisplayLeaders}
                    battleData={battleData}
                    carClasses={carClasses}
                    mapSize={mapSize}
                    displayMapGroups={displayMapGroups}
                    currentSectorLocation={currentSectorLocation}
                />
                <use id={`use_${id}`} href='#'/>
            </svg>
        )
    } else if (circuitId === 8) {
        /* Buriram */
        return (
            <svg className = 'svg_view' id = {id} width = {svgStyle.width} height = {svgStyle.height} style = {svgStyle.style} viewBox = {svgStyle.viewBox_Chang}>
                <path id = {`track${id}`} stroke='#000' strokeWidth='2' strokeDasharray='3 1 1' fill='none' d='M0,0c-58.82-8.56-599.1-66.42-671.87-75.18-85.31-10.27-172.35-21.6-193.98-77.69-19.17-49.7,11.62-137.22,8.3-205.72-2.15-44.44-.48-272.66-21.55-351.82-15.62-58.68-42.44-139.28-61.65-196.78-10.82-32.37-19.22-57.41-21.86-67.09-7.33-26.87-26.24-89.07,65.97-100.17,82.51-9.93,130.91,24.75,142.7,87.07,11.7,61.79,77.18,521.28,77.18,592.13s12.22,124.6,117.27,175.91,258.98,92.84,322.5,112.39,163.69-7.33,178.35-43.98-7.33-95.28-188.12-129.49c-180.79-34.2-204.07-95.25-199.23-130.49,10.07-73.24-1.04-156.15-1.56-168.35-1.58-37.19,23.2-72.98,68.85-92.07s171.26-29.79,199.9-36.09c72.54-15.97,236.6,9.14,166.14,85.62-29.44,31.95-53.94,46.86-63.46,68.33-11.61,26.17,1.52,63.32,29.7,87.47,51.31,43.98,120.35,59.67,155.13,139.86,41.02,94.59,130.72,243.6,142.94,260.7s65.21,110.04-70.85,92.28c-118.53-15.47-144.21-21.55-180.79-26.87Z'/>
                <path id = {`sec1${id}`} fill = 'none' d = 'M0,0c-58.8-8.6-599.1-66.4-671.9-75.2-85.3-10.3-172.3-21.6-194-77.7-19.2-49.7,11.6-137.2,8.3-205.7-2.2-44.4-.5-272.7-21.5-351.8-15.6-58.7-42.4-139.3-61.7-196.8' />
                <path id = {`sec2${id}`} fill = 'none' d = 'M-940.8-907.2c-10.8-32.4-19.2-57.4-21.9-67.1-7.3-26.9-26.2-89.1,66-100.2,82.5-9.9,130.9,24.8,142.7,87.1,11.7,61.8,77.2,521.3,77.2,592.1s12.2,124.6,117.3,175.9S-300.5-126.5-237-107' />
                <path id = {`sec3${id}`} fill = 'none' d = 'M-237-107.1c63.5,19.6,163.7-7.3,178.4-44s-7.3-95.3-188.1-129.5c-180.8-34.2-204.1-95.2-199.2-130.5,10.1-73.2-1-156.2-1.6-168.3-1.6-37.2,23.2-73,68.9-92.1s171.3-29.8,199.9-36.1' />
                <path id = {`sec4${id}`} fill = 'none' d = 'M-178.8-707.4c72.5-16,236.6,9.1,166.1,85.6-29.4,32-53.9,46.9-63.5,68.3-11.6,26.2,1.5,63.3,29.7,87.5,51.3,44,120.3,59.7,155.1,139.9,41,94.6,130.7,243.6,142.9,260.7s65.2,110-70.9,92.3C62.3,11.4,36.6,5.3,0,0' />
                <CarObject
                    drivers={drivers}
                    id={id}
                    carNumberTeamColorObj={carNumberTeamColorObj}
                    followCarNumber={followCarNumber}
                    rivalCarNumbers={rivalCarNumbers}
                    mapLeaders={mapLeaders}
                    mapDisplayLeaders={mapDisplayLeaders}
                    battleData={battleData}
                    carClasses={carClasses}
                    mapSize={mapSize}
                    displayMapGroups={displayMapGroups}
                    currentSectorLocation={currentSectorLocation}
                />
                <use id={`use_${id}`} href='#'/>
            </svg>
        )
    }
}

export default Track