// import * as ort from 'onnxruntime-web'

// import getOnnxFiles from './getOnnxFiles'
// import GetFile from './getFile'
// import transArray from './transArray'

// const addKPI = async (VEHICLE_ID, TRACK_ID, Obj_baseSetup, Obj_scatSetup, Obj_chart_combination, Obj_circuit_sens, listAns, Obj_KPI_base_result, Obj_KPI_recommend_result, selectedIndex = [0]) => {
const addKPI = async (Obj_circuit_sens, listAns, Obj_KPI_base_result, Obj_KPI_recommend_result, selectedIndex = [0]) => {
    // let Obj_KPI_base = {}
    // let Obj_KPI_recommend = {}
    // let Obj_KPI_selected = {}

    // if (Object.keys(Obj_KPI_base_result).length === 0 || Object.keys(Obj_KPI_recommend_result).length === 0) {
    //     ({ Obj_KPI_base, Obj_KPI_recommend, Obj_KPI_selected } = await localGetKPI())
    // } else {
        // ({ Obj_KPI_base, Obj_KPI_recommend, Obj_KPI_selected } = areneGetKPI())
        const { Obj_KPI_base, Obj_KPI_recommend, Obj_KPI_selected } = areneGetKPI()
    // }

    return { Obj_KPI_base, Obj_KPI_recommend, Obj_KPI_selected }

    // async function localGetKPI() {
    //     let Obj_baseSetup_tmp = findObj(Obj_baseSetup, 0)
    //     let Obj_scatSetup_tmp = findObj(Obj_scatSetup, listAns[0])
    //     let Obj_selectedSetup_tmp = findObj(Obj_scatSetup, selectedIndex[0])

    //     let KPI_list = []
    //     for (let i = 0; i < Obj_chart_combination['performance'].length; i++){
    //         KPI_list.push(Obj_chart_combination['performance'][i].name)
    //         Obj_KPI_base[Obj_chart_combination['performance'][i].name] = {}
    //         Obj_KPI_recommend[Obj_chart_combination['performance'][i].name] = {}
    //         Obj_KPI_selected[Obj_chart_combination['performance'][i].name] = {}
    //     }

    //     return new Promise (async (resolve, reject) => {
    //         const onnxFile = await getOnnxFiles(VEHICLE_ID, TRACK_ID)
    
    //         const onnxLength = onnxFile.length
    //         const KPILength = KPI_list.length
    
    //         for (let i = 0; i < onnxLength; i++) {
    //             for (let j = 0; j < KPILength; j++) {
    //                 if (onnxFile[i].includes(KPI_list[j])){
    //                     const arrayBuffer = await GetFile(`/data/ONNX/ONNX_${VEHICLE_ID}_${TRACK_ID}/${onnxFile[i]}`, 'buffer')
    //                     let myOnnxSession = await ort.InferenceSession.create(arrayBuffer)
    
    //                     let ansBase = await myOnnxSession.run(Obj_baseSetup_tmp)
    //                     ansBase = transArray(ansBase.variable.data)
    
    //                     let ansScat = await myOnnxSession.run(Obj_scatSetup_tmp)
    //                     ansScat = transArray(ansScat.variable.data)

    //                     let ansSelected = await myOnnxSession.run(Obj_selectedSetup_tmp)
    //                     ansSelected = transArray(ansSelected.variable.data)
    
    //                     const objname = KPI_list[j]
    //                     const modelname = Obj_circuit_sens[Object.keys(Obj_KPI_base[objname]).length].name
    
    //                     Obj_KPI_base[objname][[Object.keys(Obj_KPI_base[objname]).length]] = {"name":modelname, "value":ansBase[0]}
    //                     Obj_KPI_recommend[objname][[Object.keys(Obj_KPI_recommend[objname]).length]] = {"name":modelname, "value":ansScat[0]}
    //                     Obj_KPI_selected[objname][[Object.keys(Obj_KPI_selected[objname]).length]] = {"name":modelname, "value":ansSelected[0]}                        
    //                 }
    //             }
    //         }
    
    //         resolve({ Obj_KPI_base, Obj_KPI_recommend, Obj_KPI_selected })
    //     })

    //     function findObj(obj, ans){
    //         let new_obj = {}
    
    //         for(let i = 0; i<Object.keys(obj).length; i++){
    //             let new_value = [obj[Object.keys(obj)[i]].data[ans]]
    //             let new_type = obj[Object.keys(obj)[i]].type
    //             let tensor_input = new ort.Tensor(new_type, new_value, [new_value.length, 1])
    //             new_obj[Object.keys(obj)[i]] = tensor_input
    //         }
    
    //         return new_obj
    //     }
    // }

    function areneGetKPI() {
        let Obj_KPI_base = {}
        let Obj_KPI_recommend = {}
        let Obj_KPI_selected = {}
        for (let [key, value] of Object.entries(Obj_KPI_base_result)) {
            if (!Obj_KPI_base[key]) {
                Obj_KPI_base[key] = {}
                Obj_KPI_recommend[key] = {}
                Obj_KPI_selected[key] = {}
            }

            if (Object.keys(Obj_circuit_sens).length === 0) {
                return { Obj_KPI_base, Obj_KPI_recommend, Obj_KPI_selected }
            }

            for (let [corner, cornerValue] of Object.entries(value)) {
                const modelname = Obj_circuit_sens[Object.keys(Obj_KPI_base[key]).length]?.name    
                Obj_KPI_base[key][Object.keys(Obj_KPI_base[key]).length] = { name: modelname, value: cornerValue[0] }
                Obj_KPI_recommend[key][Object.keys(Obj_KPI_recommend[key]).length] = { name: modelname, value: Obj_KPI_recommend_result[key][corner][listAns[0]] }
                Obj_KPI_selected[key][Object.keys(Obj_KPI_recommend[key]).length] = { name: modelname, value: Obj_KPI_recommend_result[key][corner][selectedIndex[0]] }
            }
        }
        return { Obj_KPI_base, Obj_KPI_recommend, Obj_KPI_selected }    
    }
}

export default addKPI