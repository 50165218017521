import { Scatter } from 'react-chartjs-2'
import { useEffect, useState } from 'react'
import LapDataWithFuelDataTable from './LapDataWithFuelDataTable'
import { useDebounce } from '../lib/hooks/useDebounce'

import { useTranslation } from 'react-i18next'

const FuelManagement = ({eventId, selectedRaceDetailId, selectedCarNumber, userId, totalSummaryViewMode}) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [ carLapData, setCarLapData ] = useState([])

    const [fuelStartEndData, setFuelStartEndData] = useState([]);
    const [fuelStartEndDiffData, setFuelStartEndDiffData] = useState([]);
    const [fuelEndPreviousEndDiffData, setFuelEndPreviousEndDiffData] = useState([]);
    const [correctionFactor, setCorrectionFactor] = useState(1)

    const [fuelOperationData, setFuelOperationData] = useState([])

    const [totalFuelUsed, setTotalFuelUsed] = useState(0)
    const [totalFuelIn, setTotalFuelIn] = useState(0)
    const [totalFuelOut, setTotalFuelOut] = useState(0)

    const [scatterData, setScatterData] = useState()

    const [eventTotalFuelUsed, setEventTotalFuelUsed] = useState([])
    const [correctionFactorByRaceDetailName, setCorrectionFactorByRaceDetailName] = useState({})
    const [eventLapTimeAndEcuFuelUsed, setEventLapTimeAndEcuFuelUsed] = useState({})
    const [totalSummaryViewScatterData, setTotalSummaryViewScatterData] = useState()

    const debouncedFuelStartEndData = useDebounce(fuelStartEndData, 1000)
    const debouncedFuelOperationData = useDebounce(fuelOperationData, 1000)

    const gridColor = '#5A5A5A'
    const labelColor = 'white'

    useEffect(() => {
        const getCarLapData = () => {
            fetch(`/api/lap/allWithFuelData/${parseInt(selectedCarNumber)}/${parseInt(selectedRaceDetailId)}/${parseInt(userId)}`)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    let data = json.data
                    setCarLapData(data)
                    setFuelStartEndData([...Array(data.length)].map((_, index) => 
                            data[index].FUEL_DATA ?
                            [
                                data[index].FUEL_DATA.ECU_START_KG ? data[index].FUEL_DATA.ECU_START_KG : '',
                                data[index].FUEL_DATA.ECU_END_KG ? data[index].FUEL_DATA.ECU_END_KG : ''
                            ] :
                            Array(2).fill('')
                        )
                    )
                    setFuelStartEndDiffData([...Array(data.length)])
                    setFuelEndPreviousEndDiffData([...Array(data.length)])
                    setFuelOperationData([...Array(data.length)].map((_, index) => 
                            data[index].FUEL_DATA ?
                            [
                                data[index].FUEL_DATA.ORDER_KG ? data[index].FUEL_DATA.ORDER_KG : '',
                                data[index].FUEL_DATA.IN_KG ? data[index].FUEL_DATA.IN_KG : '',
                                data[index].FUEL_DATA.OUT_KG ? data[index].FUEL_DATA.OUT_KG : ''
                            ] :
                            Array(3).fill('')
                        )
                    )
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        selectedCarNumber && selectedRaceDetailId && getCarLapData()
    }, [selectedCarNumber, selectedRaceDetailId])

    useEffect(() => {
        setFuelStartEndDiffData(fuelStartEndData.map(rowData => rowData[1] - rowData[0]))
        setFuelEndPreviousEndDiffData(fuelStartEndData.map((rowData, index) =>
            index !== 0 ?
            rowData[1] - fuelStartEndData[index - 1][1] :
            0
        ))
        setTotalFuelUsed(Math.max(...fuelStartEndData.map(row => row[1])))
    }, [fuelStartEndData])

    useEffect(() => {
        setTotalFuelIn(fuelOperationData.map(row => row[1]).reduce((total, value) => value ? total + parseFloat(value) : total, 0))
        setTotalFuelOut(fuelOperationData.map(row => row[2]).reduce((total, value) => value ? total + parseFloat(value) : total, 0))   
    }, [fuelOperationData])

    useEffect(() => {
        setCorrectionFactor(totalFuelUsed && totalFuelIn ? (totalFuelIn - totalFuelOut) / totalFuelUsed : 1)
    }, [totalFuelUsed, totalFuelIn, totalFuelOut])

    useEffect(() => {
        const scatterData = {
            datasets: [{
                label: 'Fuel Data',
                data: carLapData.map((item, index) => ({
                    x: item.TIME,
                    y: correctionFactor * fuelEndPreviousEndDiffData[index] || 0
                })),
                backgroundColor: 'blue',
                borderColor: 'blue',
                pointRadius: 4,
                pointHoverRadius: 6
            }]
        }
        setScatterData(scatterData)
    }, [carLapData, fuelEndPreviousEndDiffData])

    useEffect(() => {
        const createOrUpdateFuelData = (eventId, raceDetailId, lapDataId, userId, ecuStartKg, ecuEndKg, orderKg, inKg, outKg) => {
            fetch('/api/fuel/createOrUpdate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    eventId, raceDetailId, lapDataId, userId, ecuStartKg, ecuEndKg, orderKg, inKg, outKg
                })
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const combinedLapAndFuelDatas = carLapData
                                        .map((lapdata, index) => ({
                                            lapDataId: lapdata.ID,
                                            data: [...fuelStartEndData[index], ...fuelOperationData[index]]
                                        }))
                                        .filter(item => item.data.some(cell => cell !== ''))
                                        .map(item => [item.lapDataId, ...item.data])
        combinedLapAndFuelDatas.forEach(row => {
            createOrUpdateFuelData(eventId, selectedRaceDetailId, row[0], parseInt(userId), parseFloat(row[1]), parseFloat(row[2]), parseFloat(row[3]), parseFloat(row[4]), parseFloat(row[5]))
        })
    }, [debouncedFuelStartEndData, debouncedFuelOperationData])

    useEffect(() => {
        const getEventTotalFuelUsed = () => {
            fetch(`/api/fuel/eventTotalFuelUsed/${eventId}/${selectedCarNumber}/${parseInt(userId)}`)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    setEventTotalFuelUsed(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        const getEventLapTimeAndEcuFuelUsed = () => {
            fetch(`/api/fuel/eventLapTimeAndEcuFuelUsed/${eventId}/${selectedCarNumber}/${parseInt(userId)}`)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    setEventLapTimeAndEcuFuelUsed(json.data)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }
        
        if (eventId && totalSummaryViewMode && selectedCarNumber) {
            getEventTotalFuelUsed()
            getEventLapTimeAndEcuFuelUsed()
        }

    }, [selectedCarNumber, totalSummaryViewMode])

    useEffect(() => {
        const correctionFactorByRaceDetailName = {}
        eventTotalFuelUsed.forEach(item =>
            {correctionFactorByRaceDetailName[item.RACE_DETAIL_NAME] =
                item.ACTUAL_TOTAL_USED / item.ECU_TOTAL_USED
            }
        )
        setCorrectionFactorByRaceDetailName(correctionFactorByRaceDetailName)
    }, [eventTotalFuelUsed])

    useEffect(() => {
        const buildtotalSummaryViewScatterData = () => {
            const colors = [
                '#7289DA', '#FFFFFF', '#E9967A', '#F08080', '#FFC0CB',
                '#FF69B4', '#FF1493','#FF6347', '#FFD700', '#FFFACD',
                '#FFE4B5','#BDB76B', '#EE82EE', '#BA55D3', '#4B0082', 
                '#6A5ACD','#7FFF00', '#2E8B57', '#00FFFF', '#4682B4',
                '#800000','#008080', '#191970', '#D2691E'
            ]
    
            const datasets = Object.keys(eventLapTimeAndEcuFuelUsed).map((raceDetailName, index) => {
                if (correctionFactorByRaceDetailName[raceDetailName]) {
                    return {
                        label: raceDetailName,
                        data: eventLapTimeAndEcuFuelUsed[raceDetailName].map(item => ({
                            x: item.LAP_TIME,
                            y: correctionFactorByRaceDetailName[raceDetailName] * item.ECU_FUEL_USED
                        })),
                        backgroundColor: colors[index % colors.length],
                        borderColor: colors[index % colors.length],
                        borderWidth: 1
                    };
                }
                return null
            }).filter(dataset => dataset !== null)
    
            setTotalSummaryViewScatterData({ datasets })
        };
    
        if (correctionFactorByRaceDetailName && eventLapTimeAndEcuFuelUsed) {
            buildtotalSummaryViewScatterData()
        }
    }, [correctionFactorByRaceDetailName, eventLapTimeAndEcuFuelUsed])

    const scatterOptions = {
        responsive: true,
        scales: {
            x: {
                min: 60,
                max: 140,
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: gridColor
                },
                title: {
                    display: true,
                    text: 'Lap Time',
                    color: labelColor
                },
                ticks: {
                  color: 'white'
                }
            },
            y: {
                min: 1,
                max: 2,
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: gridColor
                },
                title: {
                    display: true,
                    text: 'Actual Fuel Used',
                    color: labelColor
                },
                ticks: {
                  color: 'white'
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: 'white'
                }
            }
        }
    }

    const handleCellChange = (e, rowIndex, colIndex, targetArray, setTargetArrayCallback) => {
        const newData = [...targetArray];
        newData[rowIndex][colIndex] = e.target.value;
        setTargetArrayCallback(newData);
    };

    const handlePaste = (e, rowIndex, colIndex, targetArray, setTargetArrayCallback) => {
        e.preventDefault()

        const pastedData = e.clipboardData.getData('Text');
        const rowDatas = pastedData.split(/\r?\n/).map(row => row.split('\t'));

        const newData = [...targetArray];

        rowDatas.forEach((rowData, rIdx) => {
            rowData.forEach((cellData, cIdx) => {
                const targetRow = rowIndex + rIdx;
                const targetCol = colIndex + cIdx;

                if (newData[targetRow] && typeof newData[targetRow][targetCol] !== 'undefined') {
                    newData[targetRow][targetCol] = cellData;
                }
            });
        });

        setTargetArrayCallback(newData);
    };  

    return (
        <>
            {
            !totalSummaryViewMode ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    margin: '10px 0 0 0'
                }}>
                    <LapDataWithFuelDataTable
                        carLapData={carLapData}
                        fuelStartEndData={fuelStartEndData}
                        setFuelStartEndData={setFuelStartEndData}
                        fuelStartEndDiffData={fuelStartEndDiffData}
                        fuelEndPreviousEndDiffData={fuelEndPreviousEndDiffData}
                        correctionFactor={correctionFactor}
                        fuelOperationData={fuelOperationData}
                        setFuelOperationData={setFuelOperationData}
                        handleCellChange={handleCellChange}
                        handlePaste={handlePaste}
                    />
                    
                    <div
                        style={{
                            width: '800px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                    }}>
                        <table border='1' style={{ width: '750px'}}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} colSpan={2}> Total Fuel Used (kg) </th>
                                    <th style={{ textAlign: 'center' }} rowSpan={2}> CorrectionFactor </th>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'center', width: '200px' }}> ECU </th>
                                    <th style={{ textAlign: 'center', width: '200px' }}> Actual </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ height: '16px' }}>
                                    <td style={{ textAlign: 'center' }}>{totalFuelUsed.toFixed(2)}</td>
                                    <td style={{ textAlign: 'center' }}>{(totalFuelIn - totalFuelOut).toFixed(2)}</td>
                                    <td
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '24px',
                                            backgroundColor: '#fa8072'
                                        }}>
                                            {correctionFactor.toFixed(3)}
                                        </td>
                                </tr>
                            </tbody>
                        </table>
    
                        <div style={{ width: '800px', margin: '50px 0 0 0'}}>
                            {scatterData && <Scatter data={scatterData} options={scatterOptions}/>}
                        </div>
                    </div>
                </div> )
                :
                (<div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    margin: '10px 0 0 0'
                }}>
                    <div>
                        <table border='1' style={{ width: '750px'}}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }} rowSpan={2}></th>
                                    <th style={{ textAlign: 'center' }} colSpan={2}> Total Fuel Used (kg) </th>
                                    <th style={{ textAlign: 'center' }} rowSpan={2}> CorrectionFactor </th>
                                </tr>
                                <tr>
                                    <th style={{ textAlign: 'center', width: '200px' }}> ECU </th>
                                    <th style={{ textAlign: 'center', width: '200px' }}> Actual </th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventTotalFuelUsed.map((row, rowIndex) => 
                                    row.ECU_TOTAL_USED && row.ACTUAL_TOTAL_USED &&
                                    <tr key={rowIndex} style={{ height: '16px' }}>
                                        <td style={{ textAlign: 'center' }}>{row.RACE_DETAIL_NAME}</td>
                                        <td style={{ textAlign: 'center' }}>{row.ECU_TOTAL_USED.toFixed(2)}</td>
                                        <td style={{ textAlign: 'center' }}>{row.ACTUAL_TOTAL_USED.toFixed(2)}</td>
                                        <td
                                            style={{
                                                textAlign: 'center',
                                                fontSize: '24px',
                                                backgroundColor: '#fa8072'
                                            }}>
                                                {(row.ACTUAL_TOTAL_USED / row.ECU_TOTAL_USED).toFixed(3)}
                                            </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div style={{ width: '800px', margin: '50px 0 0 0'}}>
                        {totalSummaryViewScatterData && <Scatter data={totalSummaryViewScatterData} options={scatterOptions}/>}
                    </div>
                </div>

                )
            }
        </>
    )
}

export default FuelManagement