import { useState, useEffect } from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import TimeFormatter from './TimeFormatter'

import { useLiveData } from '../../DataContext'

import { colorScale } from '../services/Util'

import tableBg from '../image/totalLap-driver-bg.png'

import { useTranslation } from 'react-i18next';

const PersonalDriverHistory = (props) => {
    const { t } = useTranslation(['strategy', 'general'])

    const color1 = 0xfa6a6a
    const color2 = 0xa2c2e6
    const carNumber = props.driverInfo.car

    const [showData, setShowData] = useState('lap')
    const [driverLapDatas, setDriverLapDatas] = useState([])
    const [sortedOstDatas, setSortedOstDatas] = useState([])
    const [ostDatas, setOstDatas] = useState([])
    const [bestLapData, setBestLapData] = useState()
    const [bestSectorData, setBestSectorData] = useState()
    const [bestSpeedData, setBestSpeedData] = useState()
    const [pitLapData, setPitLapData] = useState()
    const [heatMaps, setHeatMaps] = useState([])
    const { sectorData, raceDetailId, overallBest, sectorLength, raceSeries } = useLiveData()
     
    const findBestLap = (driverLapDatas) => {
        if (driverLapDatas.length === 0) return
        
        let bestLap
        driverLapDatas.forEach(lap => {
            if (lap.lapTime === '-') return
            if (!bestLap || Number(lap.lapTime) < Number(bestLap.lapTime)) {
                bestLap = lap
            }
        })
        setBestLapData(bestLap)
    }

    const findBestSector = (driverLapDatas) => {
        if (driverLapDatas.length === 0) return
    
        const bestSector = driverLapDatas.reduce((accumulator, lap) => {
            Array.from({ length: sectorLength }, (_, i) => i + 1).forEach(sectorNumber => {
                const sectorKey = `sec${sectorNumber}`
                const sectorTime = lap[sectorKey] 
                if (sectorTime !== '-' && (!accumulator[sectorKey] || Number(sectorTime) < Number(accumulator[sectorKey].time))) {
                    accumulator[sectorKey] = { time: sectorTime, lap: lap.lapNumber }
                }
            })   
            return accumulator
        }, {})
    
        setBestSectorData(bestSector)
    }

    const findBestSpeed = (driverLapDatas) => {
        if (driverLapDatas.length === 0) return

        let bestSpeed
        driverLapDatas.forEach(lap => {
            if (lap.speed === '-') return

            if (!bestSpeed || Number(lap.speed) > Number(bestSpeed.speed)) {
                bestSpeed = lap
            }
        })
        setBestSpeedData(bestSpeed)
    }

    const findPitLap = (driverLapDatas) => {
        const pitLaps = []
        let pitLap = 0
        const reversedLapData = driverLapDatas.slice().reverse()
        reversedLapData.forEach((lap, index) => {
            if (lap.pitStopTime !== pitLap && lap.pitStopTime !== '-') {
                pitLap = lap.lapNumber
                pitLaps.push(lap.lapNumber)
                pitLap = lap.pitStopTime
            }
        })
        setPitLapData(pitLaps)
    }

    const createHeatMap = (driverLapDatas) => {
        if (driverLapDatas.length === 0) return

        const heatMapArrays = [
            driverLapDatas.map(driverLapData => driverLapData.lapTime === '-' ? '-' : Number(driverLapData.lapTime)),
            driverLapDatas.map(driverLapData => driverLapData.sec1 === '-' ? '-' : Number(driverLapData.sec1)),
            driverLapDatas.map(driverLapData => driverLapData.sec2 === '-' ? '-' : Number(driverLapData.sec2)),
            driverLapDatas.map(driverLapData => driverLapData.sec3 === '-' ? '-' : Number(driverLapData.sec3)),
            driverLapDatas.map(driverLapData => driverLapData.sec4 === '-' ? '-' : Number(driverLapData.sec4)),
            driverLapDatas.map(driverLapData => driverLapData.speed === '-' ? '-' : Number(driverLapData.speed)),
        ]

        const filledHeatMaps = heatMapArrays.map((heatMapArray, index) => {
            return index === 5 ? colorScale(heatMapArray, color2, color1, 'personalDriverHistory') : colorScale(heatMapArray, color1, color2, 'personalDriverHistory')
        })
        setHeatMaps(filledHeatMaps)
    }

    const driverLapDataHandler = () => {
        setShowData('lap')
    }

    const otsLapDataHandler = () => {
        setShowData('ots')
    }

    const getColorFromHeatMap = (heatMap, index) => 
        heatMap[index] !== undefined ? `#${heatMap[index].toString(16).padStart(6, '0')}` : 'transparent'

    useEffect(() => { 
        const getAllRaceDataByCarNumberRaceDetailId = (carNumber, raceDetailId) => {
            fetch(`/api/race/getAllRaceDataByCarNumberRaceDetailId/${carNumber}/${raceDetailId}/`)
            .then(response => response.json())
            .then(json => {
                if(json.status === 200){
                    setDriverLapDatas(json.data.lapData)
                    setOstDatas(json.data.otsDataArray)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }

        getAllRaceDataByCarNumberRaceDetailId(carNumber, raceDetailId)
    }, [props, props.driverInfo, sectorData])


    useEffect(() =>{
        const processOtsData = (otsDataArray) => {
            if (!otsDataArray) return
        
            const updatedOtsDatas = []
    
            otsDataArray
                .sort((a, b) => b.END_LAP_NUMBER - a.END_LAP_NUMBER)
                .forEach(otsData => {
                    const otsLap = {
                        startLap: otsData.START_LAP_NUMBER,
                        startSector: otsData.START_SECTOR_NUMBER,
                        endLap: otsData.END_LAP_NUMBER,
                        endSector: otsData.END_SECTOR_NUMBER,
                        duration: otsData.DURATION,
                        remainingTime: otsData.REMAINING_TIME
                    }
                    updatedOtsDatas.push(otsLap)
            })
    
            setSortedOstDatas(updatedOtsDatas)
        }

        processOtsData(ostDatas)
    }, [ostDatas])
    

    useEffect(() => {
        if (driverLapDatas.length === 0) return
        findBestLap(driverLapDatas)
        findBestSector(driverLapDatas)
        findBestSpeed(driverLapDatas)
        findPitLap(driverLapDatas)
        createHeatMap(driverLapDatas)
    }, [driverLapDatas])


    const LapDataRow = ({ lap, bestSectorData, bestLapData, heatMaps, index }) => {
        if (!heatMaps || heatMaps.length === 0 || index < 0 || index >= heatMaps[0].length) {
            return null
        }

        const [lapColor, sec1Color, sec2Color, sec3Color, sec4Color, speedColor] = heatMaps.map((heatMap, i) => getColorFromHeatMap(heatMap, index))

        const getClassName = (sectorNumber, lapNumber) => {
            let isBest = false
            let color = ''
        
            if (sectorNumber) {
                const sectorTime = lap[`sec${sectorNumber}`]
                isBest = sectorTime === bestSectorData?.[`sec${sectorNumber}`]?.time
                color = isBest ? (sectorTime <= overallBest[`sec${sectorNumber}`] ? 'overall-best' : 'personal-best') : heatMaps?.[sectorNumber]?.[index]?.color || ''
            } else {
                isBest = lapNumber === 'lap' && lap.lapTime === bestLapData?.lapTime
                color = lapNumber === 'lap' ? (isBest ? 'personal-best' : heatMaps?.[0]?.[index]?.color || '') : color
        
                isBest = lapNumber === 'speed' && lap.speed === bestSpeedData?.speed
                color = lapNumber === 'speed' ? (isBest ? 'personal-best' : heatMaps?.[5]?.[index]?.color || '') : color
            }
        
            return color
        }
    
        return (
            <TableRow key={`${lap.lapNumber}TR`}>
                <TableCell padding='none'>{lap.lapNumber}</TableCell>
                <TableCell padding='none' className={getClassName(1, false)} style={{ fontWeight: 'bold', backgroundColor: sec1Color }}>{lap.sec1 !== '-' ? <TimeFormatter seconds={lap.sec1} decimalPlaces={3}/> : lap.sec1}</TableCell>
                <TableCell padding='none' className={getClassName(2, false)} style={{ fontWeight: 'bold', backgroundColor: sec2Color }}>{lap.sec2 !== '-' ? <TimeFormatter seconds={lap.sec2} decimalPlaces={3}/> : lap.sec2}</TableCell>
                <TableCell padding='none' className={getClassName(3, false)} style={{ fontWeight: 'bold', backgroundColor: sec3Color }}>{lap.sec3 !== '-' ? <TimeFormatter seconds={lap.sec3} decimalPlaces={3}/> : lap.sec3}</TableCell>
                {sectorLength === 4 ? (
                    <TableCell padding='none' className={getClassName(4, false)} style={{ fontWeight: 'bold', backgroundColor: sec4Color }}>{lap.sec4 !== '-' ? <TimeFormatter seconds={lap.sec4} decimalPlaces={3}/> : lap.sec4}</TableCell>
                ) : null}
                <TableCell padding='none' className={getClassName(false, 'lap')} style={{ fontWeight: 'bold', backgroundColor: lapColor }}>{lap.lapTime !== '-' ? <TimeFormatter seconds={lap.lapTime} decimalPlaces={3}/>: lap.lapTime}</TableCell>
                <TableCell padding='none' className={getClassName(false, 'speed')} style={{ fontWeight: 'bold', backgroundColor: speedColor }}>{lap.speed !== '-' && lap.speed ? lap.speed.toFixed(3) : '-' }</TableCell>
                <TableCell padding='none'>{ pitLapData.includes(lap.lapNumber) ? 'IN' : pitLapData.includes(lap.lapNumber - 1) ? 'OUT' : '' }</TableCell>
                { props.raceSeries === 'SF' ? <TableCell padding='none' className='ots-remainingtime-time'>{lap.ots}</TableCell> : null }
            </TableRow>
        )
    }

    return (
        <div className='race-data'>
            <div className='top-table'>
                <div className='driver-information'>
                    <div className='driver-name-base'>
                        <h2 className='driver-name-info'> { props.driverInfo.name }</h2>
                    </div>
                    <div className='driver-carNo-base'>
                        <h2 className='driver-carNo-info'> { carNumber }</h2>
                    </div>
                </div>
            </div>
            <div className='driver-table'>
                {showData === 'lap' && bestLapData && bestSectorData ? (
                    <>
                        <Table className='best-race-data-table'>
                            <TableHead>
                                <TableRow className='noBeforeAfter'>
                                    <TableCell className='table-tit' padding='none' align='center' colSpan={bestSectorData.sec4 ? 7 : 6} style={{ borderBottom: 'none', fontSize: '28px' }}>
                                        BEST
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell padding='none'></TableCell>
                                    <TableCell padding='none'>Sec1</TableCell>
                                    <TableCell padding='none'>Sec2</TableCell>
                                    <TableCell padding='none'>Sec3</TableCell>
                                    {bestSectorData.sec4 && <TableCell padding='none'>Sec4</TableCell>}
                                    <TableCell padding='none'>Laptime</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell padding='none'>{t('general.lap')}</TableCell>
                                    <TableCell padding='none'>{ bestSectorData.sec1?.lap }</TableCell>
                                    <TableCell padding='none'>{ bestSectorData.sec2?.lap }</TableCell>
                                    <TableCell padding='none'>{ bestSectorData.sec3?.lap }</TableCell>
                                    { bestSectorData.sec4 && <TableCell padding='none'>{ bestSectorData.sec4?.lap }</TableCell> }
                                    <TableCell padding='none'>{ bestLapData.lapNumber }</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell padding='none'>{t('personalDriverHistory.time')}</TableCell>
                                    <TableCell padding='none'><TimeFormatter seconds={bestSectorData.sec1?.time} /></TableCell>
                                    <TableCell padding='none'><TimeFormatter seconds={bestSectorData.sec1?.time} /></TableCell>
                                    <TableCell padding='none'><TimeFormatter seconds={bestSectorData.sec1?.time} /></TableCell>
                                    { bestSectorData.sec4 && <TableCell padding='none'><TimeFormatter seconds={bestSectorData.sec4?.time} decimalPlaces={3}/></TableCell> }
                                    <TableCell padding='none'><TimeFormatter seconds={bestLapData.lapTime} decimalPlaces={3}/></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <br></br>
                        <Table className='heatmap-example-table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell className = 'heatmap-example' style = {{ textAlign: 'left' }}>{`Fast > > >`}</TableCell>
                                    <TableCell className = 'heatmap-example'></TableCell>
                                    <TableCell className = 'heatmap-example' style = {{ textAlign: 'center' }}>{' > > > '}</TableCell>
                                    <TableCell className = 'heatmap-example'></TableCell>
                                    <TableCell className = 'heatmap-example' style = {{ textAlign: 'right' }}>{`> > > Slow`}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className='heatmap-bar'>
                                <TableRow>
                                    <TableCell className = 'heatmap1 heatmap-example'></TableCell>
                                    <TableCell className = 'heatmap2 heatmap-example'></TableCell>
                                    <TableCell className = 'heatmap3 heatmap-example'></TableCell>
                                    <TableCell className = 'heatmap4 heatmap-example'></TableCell>
                                    <TableCell className = 'heatmap5 heatmap-example'></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </>
                ) : ''}

                <p></p>
                <div className='totalLap-driver-table-cover'>
                   {/*<img className='tableBg' src={tableBg}/>*/}  
                <div className='top-table-buttons'>
                    <button className={`lap-data-button${showData === 'lap' ? ' display-on' : ''}`} onClick = { driverLapDataHandler }>Lap Data</button>
                    { raceSeries === 'SF' ? <button className={`ots-usage-button${showData === 'ots' ? ' display-on' : ''}`} onClick = { otsLapDataHandler }>OTS Usage</button> : ''}
                </div>

                {showData === 'lap' ? (
                    <div class="lapdata_table">
                    <Table className='totalLap-driver-table'>
                        <TableHead>  
                            <TableRow>
                                <TableCell style={{ color: '#000000' }}>Lap</TableCell>
                                <TableCell style={{ color: '#000000' }}>Sec1</TableCell>
                                <TableCell style={{ color: '#000000' }}>Sec2</TableCell>
                                <TableCell style={{ color: '#000000' }}>Sec3</TableCell>
                                {sectorLength === 4 && <TableCell style={{ color: '#000000' }}>Sec4</TableCell>}
                                <TableCell style={{ color: '#000000' }}>Laptime</TableCell>
                                <TableCell style={{ color: '#000000' }}>Speed</TableCell>
                                <TableCell style={{ color: '#000000' }}>Pit</TableCell>
                                { props.raceSeries === 'SF' ? <TableCell>{t('personalDriverHistory.remainingOTSTime')}</TableCell> : null }
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            {driverLapDatas.map((lap, index) => (
                            <LapDataRow
                                key = { index }
                                lap = { lap }
                                bestSectorData = { bestSectorData }
                                bestLapData = { bestLapData }
                                heatMaps = { heatMaps }
                                index = { index }
                            />
                            ))}
                        </TableBody>
                    </Table>
                    </div>
                ) : (sortedOstDatas.length > 1 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Start Lap</TableCell>
                                <TableCell>Start Sector</TableCell>
                                <TableCell>End Lap</TableCell>
                                <TableCell>End Sector</TableCell>
                                <TableCell>Duration</TableCell>
                                <TableCell>Remaining Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {sortedOstDatas.map((sortedOstData, index) => (
                            <TableRow key={index}>
                            <TableCell>{sortedOstData.startLap}</TableCell>
                            <TableCell>{sortedOstData.startSector}</TableCell>
                            <TableCell>{sortedOstData.endLap}</TableCell>
                            <TableCell>{sortedOstData.endSector}</TableCell>
                            <TableCell>{sortedOstData.duration}</TableCell>
                            <TableCell>{sortedOstData.remainingTime}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    ) : 
                    <h2>{t('personalDriverHistory.otsHasNotBeenUsed')}</h2>
                )}
            </div>
        </div>
    </div>
    )
}
export default PersonalDriverHistory