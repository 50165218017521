const SetBattle = (sortedTableData, isRace, followCarNumber, displayFollowAndRivals, carClasses) => {
    if (!isRace) return [sortedTableData, []]

    const findCarInGroup = (group, carNumber) => group.find(car => car.carno === carNumber)
    const isCarVisible = car => car.isVisible
    const allCarsVisible = () => carClasses.every(classObj => classObj.isVisible)
    const sortCarsByPosition = group => group.sort((a, b) => a.pos - b.pos)
    const clearBattleProperties = car => {
        delete car.battlePosition
        delete car.battleStatus
    }

    const addToGroup = (group, cars) => {
        return cars.reduce((updatedGroup, car, index) => {
            if (!isCarVisible(car)) return updatedGroup

            const carData = findCarInGroup(updatedGroup, car.carno)
            if (!carData) {
                car.battleStatus = 'isInBattles'
                car.battlePosition = index === 0 ? 'upper' : 'lower'
                updatedGroup.push(car)
            } else {
                carData.battlePosition = 'middle'
            }
            return updatedGroup
        }, group)
    }

    const updateGroupVisibility = (group) => {
        if (!allCarsVisible()) {
            const lowestCar = group.reduce((prev, current) => (prev.pos > current.pos) ? prev : current)
            lowestCar.battlePosition = 'lower'
        }
    }

    const updateSingleCarGroup = (group) => {
        if (group.length === 1) {
            clearBattleProperties(group[0])
        }
    }

    const updateBattlePositions = (group) => {
        group.forEach((car, index) => {
            const nextCar = group[index + 1]
            if (!nextCar || nextCar.gap - car.gap > 1.5) {
                if (index === 0) {
                    clearBattleProperties(car)
                } else {
                    car.battlePosition = 'lower'
                }
            } else if (nextCar.gap - car.gap <= 1.5) {
                car.battlePosition = index === 0 ? 'upper' : 'middle'
                car.battleStatus = 'isInBattles'
            }
        })
    }

    const processGroups = (groups, referenceIndex) => {
        groups.forEach(group => {
            group.forEach((car, index) => {
            const nextCar = group[index + 1]
                if (!nextCar) return

                const currentCarIndex = sortedTableData.findIndex(c => c.carno === car.carno)
                const smallerBattleRange = currentCarIndex >= referenceIndex ? 0.5 : -0.5
                if ((currentCarIndex >= referenceIndex && nextCar.diff <= smallerBattleRange) || (currentCarIndex < referenceIndex && car.diff >= smallerBattleRange)) {
                    car.battleStatus = 'isInIntenseBattles'
                    nextCar.battleStatus = 'isInIntenseBattles'
                }
            })
        })
    }

    const referenceIndex = followCarNumber ? sortedTableData.findIndex(car => parseInt(car.carno.replace('No.', '')) === parseInt(followCarNumber)) : 0
    sortedTableData.forEach(car => clearBattleProperties(car))

    let battleGroup = []
    const battlesInGroup = sortedTableData.reduce((groups, currentCar, index) => {
    const nextCar = sortedTableData.find(car => car.pos === currentCar.pos + 1 && isCarVisible(car))
    if (!nextCar || currentCar.isPitIn || nextCar.isPitIn) {
        if (battleGroup.length) {
            groups.push(battleGroup)
        }
        battleGroup = []
        return groups
    }

    const battleRange = index >= referenceIndex ? 1.5 : -1.5
    if ((index >= referenceIndex && nextCar.diff > battleRange) || (index < referenceIndex && currentCar.diff < battleRange)) {
        if (battleGroup.length) {
            groups.push(battleGroup)
        }
        battleGroup = []
    } else {
        battleGroup = addToGroup(battleGroup, [currentCar, nextCar])
    }
    return groups
    }, [])

    if (battleGroup.length) battlesInGroup.push(battleGroup)

    battlesInGroup.forEach(group => {
        updateGroupVisibility(group)
        if (displayFollowAndRivals) {
            updateSingleCarGroup(group)
            sortCarsByPosition(group)
            updateBattlePositions(group)
        }
    })

    processGroups(battlesInGroup, referenceIndex)

    return [sortedTableData, battlesInGroup]
}
    
export default SetBattle
  