import { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'

import AuthContext from '../src/authContext'

// TODO: create a common folder?
import Login from './strategy/pages/Login'
import TopPage from './TopPage'
import NoPage from './strategy/pages/NoPage'
import Settings from './settings'
// TODO: move to admin?
import Admin from './strategy/pages/Admin'

// TODO: move to dashboard
import Event from './strategy/pages/Event'
import Live from './strategy/pages/Live'

import EditDatabase from './strategy/pages/EditDatabase'
import EditVehicleDatabase from './strategy/pages/EditVehicleDatabase.jsx'
import FuelAndTyreManagement from './strategy/pages/FuelAndTyreManagement'
import RaceAnalysis from './strategy/pages/RaceAnalysis'
import FPAnalysis from './strategy/pages/FPAnalysis'
import QFAnalysis from './strategy/pages/QFAnalysis'
import QFPlan from './strategy/pages/QFPlan'
import Simulation from './strategy/pages/Simulation'
import TirePressurePrediction from './strategy/pages/TirePressurePrediction'
import TrackTemperature from './strategy/pages/TrackTemperature'

import Core from './core/pages/Optimize.jsx'
import Setup from './core/pages/Setup'
import SetupHistory from './core/pages/SetupHistory'
import CoreAdmin from './core/pages/Admin'

import WebSocketService from './WebSocketService'

WebSocketService.connect()

const App = () => {
    const ctx = useContext(AuthContext)
    const PrivateRoute = ({ children }) => {
        if (!ctx.isLoginChecked) return null;
        return ctx.isLoggedIn ? children : <Login />
    }

    const AdminRoute = ({ children }) => {
        if (!ctx.isLoginChecked) return null;
        if (!ctx.isLoggedIn) return <Login />

        return ctx.isAdmin ? children : <NoPage />
    }

    const RestrictedRoute = ({ acceptedRole, children }) => {
        if (!ctx.isLoginChecked) return null;
        if (!ctx.isLoggedIn) return <Login />
        return acceptedRole.includes(ctx.role) || ctx.role === 'admin' || ctx.route === 'user' ? children : <NoPage />
    }

    return (
        <div className='App'>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/top' element={<PrivateRoute><TopPage /></PrivateRoute>} />
                <Route path='/admin' element={<AdminRoute><Admin /></AdminRoute>} />

                {/* <Route path='/top' element={ <RestrictedRoute acceptedRole={['core', 'strategy']}><TopPage/></RestrictedRoute> } /> */}

                <Route path='/trackTemperature' element={<AdminRoute><TrackTemperature /></AdminRoute>} />

                <Route path='/editvehicledatabase' element={<RestrictedRoute acceptedRole={['user', 'strategy', 'dashboard']}><EditVehicleDatabase /></RestrictedRoute>} />
                <Route path='/editdatabase' element={<RestrictedRoute acceptedRole={['user', 'strategy', 'dashboard']}><EditDatabase /></RestrictedRoute>} />
                <Route path='/event' element={<RestrictedRoute acceptedRole={['user', 'strategy', 'dashboard']}><Event /></RestrictedRoute>} />
                <Route path='/event/:eventId' element={<RestrictedRoute acceptedRole={['user', 'strategy', 'dashboard']}><Event /></RestrictedRoute>} />
                <Route path='/live' element={<RestrictedRoute acceptedRole={['user', 'strategy', 'dashboard']}><Live /></RestrictedRoute>} />

                <Route path='/event/:eventId/race-analysis/:raceDetailId' element={<RestrictedRoute acceptedRole={['user', 'strategy']}><RaceAnalysis /></RestrictedRoute>} />
                <Route path='/event/:eventId/qf-plan/:raceDetailId' element={<RestrictedRoute acceptedRole={['user', 'strategy']}><QFPlan /></RestrictedRoute>} />
                <Route path='/event/:eventId/fp-analysis/:raceDetailId' element={<RestrictedRoute acceptedRole={['user', 'strategy']}><FPAnalysis /></RestrictedRoute>} />
                <Route path='/event/:eventId/qf-analysis/:raceDetailId' element={<RestrictedRoute acceptedRole={['user', 'strategy']}><QFAnalysis /></RestrictedRoute>} />
                <Route path='/event/:eventId/fuel-and-tyre-management' element={<RestrictedRoute acceptedRole={['user', 'strategy']}><FuelAndTyreManagement /></RestrictedRoute>} />
                <Route path='/event/:eventId/tire-pressure-prediction' element={<RestrictedRoute acceptedRole={['user', 'strategy']}><TirePressurePrediction /></RestrictedRoute>} />
                <Route path='/event/:eventId/simulation' element={<RestrictedRoute acceptedRole={['user', 'strategy']}><Simulation /></RestrictedRoute>} />

                <Route path='/core/optimize' element={<RestrictedRoute acceptedRole={['user', 'core']}><Core /></RestrictedRoute>} />
                <Route path='/core/admin' element={<RestrictedRoute acceptedRole={['coreAdmin']}><CoreAdmin /></RestrictedRoute>} />
                <Route path='/setup' element={<RestrictedRoute acceptedRole={['user', 'core']}><Setup /></RestrictedRoute>} />
                <Route path='/setup-history' element={<RestrictedRoute acceptedRole={['user', 'core']}><SetupHistory /></RestrictedRoute>} />

                <Route path='*' element={ <NoPage/> } />
            </Routes>
            <Settings/>
        </div>
    )
}

export default App
