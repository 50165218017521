import { useEffect, useState } from 'react'

import MileageEachRowInput from './MileageEachRowInput'

const MileageInfoTable = ({ userId, tyreSheetHeaderTable, pressureTargets, tyreSheetInfo, prevTyreSheetInfo }) => {
    const [groupByRow, setGroupByRow] = useState({})
    const [prevGroupByRow, setPrevGroupByRow] = useState({})
    const [mileageInfo, setMileageInfo] = useState({})
    const [ifNewRowInsert, setIfNewRowInsert] = useState(false)
    const [displayRowIndices, setDisplayRowIndices] = useState([])

    useEffect(() => {
        if (ifNewRowInsert) {
            let groupByRowObj = groupByRow
            if (!groupByRowObj[Object.keys(groupByRowObj).length + 1]) {
                groupByRowObj[Object.keys(groupByRowObj).length + 1] = []
            }
            
            setGroupByRow(groupByRowObj)
            setIfNewRowInsert(false)
        }
    }, [ifNewRowInsert])
    
    useEffect(() => {
        let groupByRowObj = {}
        setMileageInfo({})
        if (tyreSheetInfo.length > 0) {
            tyreSheetInfo.forEach(eachRow => {
                if (!groupByRowObj[eachRow.ROW_ID]) {
                    groupByRowObj[eachRow.ROW_ID] = []
                }
                groupByRowObj[eachRow.ROW_ID].push(eachRow)
            })
        }
        setGroupByRow(groupByRowObj)
    }, [tyreSheetInfo])

    useEffect(() => {
        let groupByRowObj = {}
        if (prevTyreSheetInfo.length > 0) {
            prevTyreSheetInfo.forEach(eachRow => {
                if (!groupByRowObj[eachRow.ROW_ID]) {
                    groupByRowObj[eachRow.ROW_ID] = []
                }
                groupByRowObj[eachRow.ROW_ID].push(eachRow)
            })
        }
        setPrevGroupByRow(groupByRowObj)
    }, [prevTyreSheetInfo])


    useEffect(() => {
        let displayRowIndices = Array.from(new Set([...Object.keys(prevGroupByRow), ...Object.keys(groupByRow)]))
        setDisplayRowIndices(displayRowIndices)
    }, [groupByRow, prevGroupByRow])

    return (
        <>
            <table border={3}>
                <thead>
                    <tr>
                        <th>Set n°</th>
                        <th>Laps done</th>
                        <th>Spec type</th>
                        <th>Type</th>
                        <th colSpan={2}>Weight Start</th>
                        <th colSpan={2}>Weight Used</th>
                        <th colSpan={2}>Wheel No.</th>
                        <th colSpan={2}>TPMS No.</th>
                        <th>Mileage from<br></br>previous session</th>
                        <th>Total mileage</th>
                        <th>Comment</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        displayRowIndices.map(index => {
                            const key = index
                            const value = groupByRow[index]
                            const prevValue = prevGroupByRow[index]

                            return (
                                <MileageEachRowInput
                                    key={key}
                                    rowId={key}
                                    userId={userId}
                                    tyreSheetHeaderTable={tyreSheetHeaderTable}
                                    pressureTargets={pressureTargets}
                                    setMileageInfo={setMileageInfo}
                                    mileageInfo={mileageInfo}
                                    value={value}
                                    setIfNewRowInsert={setIfNewRowInsert}
                                    prevTyreSheetInfo={prevTyreSheetInfo}
                                    prevValue={prevValue}
                                />
                            )
                        })
                    }
                    <MileageEachRowInput
                        rowId={Math.max(displayRowIndices) + 1}
                        userId={userId}
                        tyreSheetHeaderTable={tyreSheetHeaderTable}
                        pressureTargets={pressureTargets}
                        setMileageInfo={setMileageInfo}
                        mileageInfo={mileageInfo}
                        value={undefined}
                        setIfNewRowInsert={setIfNewRowInsert}
                        prevTyreSheetInfo={prevTyreSheetInfo}
                        prevValue={undefined}
                    />
                </tbody>
            </table>
        </>
    )
}

export default MileageInfoTable