import React, { useState, useEffect } from 'react'

import processTimeSeriesData from '../services/processTimeSeriesData'

import { useTranslation } from 'react-i18next'

const TimeSeriesChannels = (props) => {
    const { t } = useTranslation(['core'])

    const [ channelList, setChannelList ] = useState([])
    const [ filteredChannelList, setFilteredChannelList ] = useState([])
    const [ selectedXYChannel, setSelectedXYChannel ] = useState({})
    const selectedTimeSeries = props.selectedTimeSeries
    const timeSeriesList = props.timeSeriesList

    const selectedGraph = props.selectedGraph
    const setSelectedGraph = props.setSelectedGraph

    const setGraphData = props.setGraphData

    useEffect(() => {
        // TODO: if no selectedTimeSeries
        if (!selectedTimeSeries) return

        const channelListOfSelected = selectedTimeSeries.channelNameList    
        const timeSeriesListAllChannel = timeSeriesList.map(data => data.channelNameList)

        const findChannelInList = (channelName) => {
            let found = 0
    
            timeSeriesListAllChannel.forEach(channelList => {
                if (channelList.includes(channelName)) {
                    found++
                }
            })

            // return -1 if not found, 0 if all found, or number of found
            return found === timeSeriesListAllChannel.length ? 0 : found === 1 ? -1 : found
        }

        const channelListWithCount = channelListOfSelected.map(channelName => {
            const count = findChannelInList(channelName)
            return { channelName, count }
        })

        setChannelList(channelListWithCount)
        setFilteredChannelList(channelListWithCount)
    }, [selectedTimeSeries, timeSeriesList])

    const addChannelHandler = (e) => {
        if (!selectedGraph) return

        const channelName = e.currentTarget.dataset.channelname

        let newChannel = { x: '', y: '', id: Math.floor(10000 + Math.random() * 90000) }

        if (selectedGraph.type === 'timeDistance') {
            newChannel.x = selectedTimeSeries.distance
            newChannel.xTime = selectedTimeSeries.time
            newChannel.y = channelName
        } else if (selectedGraph.type === 'xY') {
            const updateAxis = (channelObject, channelName) => {
                let axisToUpdate = 'x'
                if (channelObject.x) {
                    axisToUpdate = 'y'
                }

                newChannel[axisToUpdate] = channelName
                channelObject[axisToUpdate] = channelName
                setSelectedGraph(prevData => {
                    return {
                        ...prevData,
                        channels: prevData.channels.map(channel => {
                            return channel.id === channelObject.id ? channelObject : channel
                        })
                    }
                })
            }

            const createNewChannel = (channelName) => {
                newChannel.x = channelName
                setSelectedXYChannel({ ...newChannel })
            }

            const { id } = selectedXYChannel

            if (id) {
                const existingChannel = selectedGraph.channels.find(channel => channel.id === id)
                if (!existingChannel) {
                    createNewChannel(channelName)
                } else if (existingChannel.x && existingChannel.y) {
                    const incompleteChannel = selectedGraph.channels.find(channel => !channel.x || !channel.y)
                    if (incompleteChannel) {
                        updateAxis(incompleteChannel, channelName)
                    } else {
                        createNewChannel(channelName)
                    }
                } else {
                    updateAxis(existingChannel, channelName)
                }
            } else {
                createNewChannel(channelName)
            }
        } else if (selectedGraph.type === 'bar') {
            // TODO: add in if type === 'timeDistance' if nothing else to add
            newChannel.x = selectedTimeSeries.distance
            newChannel.y = channelName
        }
        // else if (selectedGraph.type === 'radar') {
        //     newChannel.x = channelName
        //     newChannel.y = 'value'
        // }

        const newPlotData = processTimeSeriesData(timeSeriesList, selectedGraph, newChannel)

        if (selectedGraph.type === 'timeDistance') {
            const xChannels = [selectedTimeSeries.distance, selectedTimeSeries.time]
            for (const [channel, data] of Object.entries(newPlotData)) {
                if (!xChannels.includes(channel)) continue

                const dataToChange = data.map(d => {
                    if (!d.data) return d

                    return {
                        ...d,
                        data: d.data.map(x => x - d.data[0])
                    }
                })

                newPlotData[channel] = dataToChange
            }
        } if (selectedGraph.type === 'bar') {
            newChannel.selectedOption = 'average'
        }

        const newChannelState = (newChannel.x || (newChannel.x && newChannel.y)) ? [ ...selectedGraph.channels, newChannel ] : [ ...selectedGraph.channels ]
        setSelectedGraph(prevData => {
            return {
                ...prevData,
                channels: newChannelState,
                plotData: newPlotData
            }
        })

        setGraphData(prevData => {
            return prevData.map(data => {
                if (data.id === selectedGraph.id) {
                    return {
                        ...data,
                        channels: newChannelState,
                        plotData: newPlotData
                    }
                } else {
                    return data
                }
            })
        })
    }

    const removeChannelHandler = (e) => {
        const element = e.currentTarget
        const channelName = element.dataset.channelname
        const elementId = element.dataset.id
        let channelId = elementId

        const selectedGraphChannels = selectedGraph.channels

        const channelToRemove = {}
        let newSelectedGraphChannels

        if (selectedGraph.type === 'timeDistance') {
            if (selectedGraph.channels.length === 1) channelToRemove.x = selectedTimeSeries.distance
            channelToRemove.y = channelName

            newSelectedGraphChannels = selectedGraphChannels.filter(channel => channel.id !== Number(channelId))
        } else if (selectedGraph.type === 'xY') {
            channelId = elementId.split('_')[0]
            const axis = elementId.split('_')[1]

            const existingChannel = selectedGraph.channels.find(channel => channel.id === Number(channelId))
            channelToRemove[axis] = channelName.split(':')[1].trim()
            delete existingChannel[axis]

            const existingAxis = axis === 'x' ? 'y' : 'x'
            if (!existingChannel[existingAxis]) {
                setSelectedXYChannel({})
                newSelectedGraphChannels = selectedGraphChannels.filter(channel => channel.id !== Number(channelId))
            } else {
                newSelectedGraphChannels = selectedGraphChannels.map(channel => {
                    return channel.id === existingChannel.id ? existingChannel : channel
                })
            }
        } else if (selectedGraph.type === 'bar') {
            // TODO: add in if type === 'timeDistance' if nothing else to add
            if (selectedGraph.channels.length === 1) channelToRemove.x = selectedTimeSeries.distance
            channelToRemove.y = channelName

            newSelectedGraphChannels = selectedGraphChannels.filter(channel => channel.id !== Number(channelId))
        }
        // else if (selectedGraph.type === 'radar') {
        //     newChannel.x = channelName
        //     newChannel.y = 'value'
        // }

        const newPlotData = processTimeSeriesData(timeSeriesList, selectedGraph, channelToRemove, true)
        setSelectedGraph(prevData => {
            return {
                ...prevData,
                channels: newSelectedGraphChannels,
                plotData: newPlotData
            }
        })

        setGraphData(prevData => {
            return prevData.map(data => {
                if (data.id === selectedGraph.id) {
                    return {
                        ...data,
                        channels: newSelectedGraphChannels,
                        plotData: newPlotData
                    }
                } else {
                    return data
                }
            })
        })
    }

    const selectOptionChangeHandler = (e) => {
        const element = e.currentTarget
        const channelId = element.parentElement.dataset.id
        const selectedOption = element.value

        setSelectedGraph(prevData => {
            return {
                ...prevData,
                channels: prevData.channels.map(channel => {
                    if (channel.id === Number(channelId)) {
                        return {
                            ...channel,
                            selectedOption
                        }
                    } else {
                        return channel
                    }
                })
            }
        })

        setGraphData(prevData => {
            return prevData.map(data => {
                if (data.id === selectedGraph.id) {
                    return {
                        ...data,
                        channels: data.channels.map(channel => {
                            if (channel.id === Number(channelId)) {
                                return {
                                    ...channel,
                                    selectedOption
                                }
                            } else {
                                return channel
                            }
                        })
                    }
                } else {
                    return data
                }
            })
        })
    }

    const searchChannelHandler = (e) => {
        const searchValue = e.target.value
        const filteredChannels = channelList.filter(channel => channel.channelName.toLowerCase().includes(searchValue.toLowerCase()))
        setFilteredChannelList(filteredChannels)
    }

    return (
        <>
            <div className='data-channel-container'>
                { channelList.length > 0 && (
                    <>
                        <div className='data-file-channel-header-container'>
                            <input type='text' placeholder={t('timeSeriesChannels.channelSearch')} onChange={ searchChannelHandler } />
                        </div>
                        { filteredChannelList.map((channel, i) => {
                            return (
                                <div className='time-series-channel-container' key={ i } data-channelname={ channel.channelName } onDoubleClick={ addChannelHandler }>
                                    <div className='time-series-count-checker'>
                                        { channel.count === 0 ? '✓' : 
                                            channel.count === -1 ? '✗' : '△'
                                        }
                                    </div>
                                    <div className='time-series-channel-title'>{ channel.channelName }</div>
                                </div>
                            )
                        }) }
                    </>
                )}
            </div>
            <div className='channel-info-container'>
                { selectedGraph && (
                    selectedGraph.channels.map((channel, i) => {
                        return (
                            <div key={ i }>
                                { selectedGraph.type === 'timeDistance' && (
                                    <div className='channel-info-title' data-id={ channel.id } data-channelname={ channel.y } onDoubleClick={ removeChannelHandler }>{ channel.y }</div>
                                )}

                                { selectedGraph.type === 'xY' && (
                                    <>
                                        <div className='channel-info-div' data-id={ channel.id } onClick={() => { setSelectedXYChannel(channel) }}>
                                            { `(${channel.x || ''}, ${channel.y || ''})` }
                                            <div className='channel-info-title' data-id={ `${channel.id}_x` } data-channelname={ channel.x } onDoubleClick={ removeChannelHandler }>{ `x: ${channel.x || ''}` }</div>
                                            <div className='channel-info-title' data-id={ `${channel.id}_y` } data-channelname={ channel.y } onDoubleClick={ removeChannelHandler }>{ `y: ${channel.y || ''}` }</div>
                                        </div>
                                    </>
                                )}

                                { selectedGraph.type === 'bar' && (
                                    <div className='channel-info-div bar' data-id={ channel.id }>
                                        <div className='channel-info-title' data-id={ channel.id } data-channelname={ channel.y } onDoubleClick={ removeChannelHandler }>{ channel.y }</div>
                                        <select className='bar-select' value={ channel.selectedOption } onChange={ selectOptionChangeHandler }>
                                            <option value='min'>Min</option>
                                            <option value='max'>Max</option>
                                            <option value='average'>Average</option>
                                        </select>
                                    </div>
                                )}

                            </div>
                        )
                    })
                )}
            </div>
        </>
    )
}

export default TimeSeriesChannels