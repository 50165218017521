import React from 'react'

const TimeSeriesChartRadar = (props) => {
    return (
        <div>
            <h1>Radar Chart</h1>
        </div>
    )
}

export default TimeSeriesChartRadar