import readSetupFile from './readSetupFile'

import optimizeArene from './optimizeArene'
import optimizeLocal from './optimizeLocal'

const StartOptimize = async (teamOutingDetailId, vehicleId, circuitId, updatedSetupFile, constrainSelectedOption = null) => {
    
    // let groupedByLapCorner
    let df_DOEcondition

    // Setup file data
    let df_baseSetup_raw
    let df_baseSetup
    let df_mapDriver
    let df_setupRequest

    let Obj_circuit_map
    let Obj_circuit_sens
    let Obj_parameter_combination
    let Obj_chart_combination

    let onnxModels
    let model
    let setupOptions
    let vehicleData
    let trackData
    let setup

    const DOEnum = 5000

    const readSetupFileResults = await readSetupFile(teamOutingDetailId, vehicleId, circuitId, updatedSetupFile, constrainSelectedOption)
    console.log('StartOptimize readSetupFileResults', readSetupFileResults)
    if (readSetupFileResults) {
        ({
            Obj_parameter_combination,
            Obj_chart_combination,
            Obj_circuit_map,
            Obj_circuit_sens,
            df_DOEcondition,
            df_baseSetup_raw,
            df_baseSetup,
            df_mapDriver,
            df_setupRequest,
            onnxModels,
            model,
            setupOptions,
            vehicleData,
            trackData,
            setup
        } = readSetupFileResults)
    }

    const modelId = model.id
    const optimizedResults = await optimizeArene(modelId, df_DOEcondition, Obj_circuit_map, Obj_chart_combination, df_baseSetup, DOEnum, Obj_parameter_combination, vehicleData, onnxModels)

    // const VEHICLE_ID = vehicle.name
    // const TRACK_ID = circuit.name
    // let optimizedResults
    // if ((VEHICLE_ID === 'SuperFormula' && TRACK_ID === 'Suzuka')
    //     || (VEHICLE_ID === 'Gr86' && TRACK_ID === 'Burirum')
    //     || (VEHICLE_ID === 'Gr86' && TRACK_ID === 'FSW')
    // ) {
    //     optimizedResults = await optimizeArene(modelId, df_DOEcondition, Obj_circuit_map, Obj_chart_combination, df_baseSetup, DOEnum, Obj_parameter_combination, vehicleData, onnxModels)
    //     // optimizedResults = await optimizeLocal(VEHICLE_ID, TRACK_ID, df_DOEcondition, Obj_circuit_map, Obj_chart_combination, df_baseSetup, DOEnum, Obj_parameter_combination, vehicleData)
    // } else {
    //     optimizedResults = await optimizeLocal(VEHICLE_ID, TRACK_ID, df_DOEcondition, Obj_circuit_map, Obj_chart_combination, df_baseSetup, DOEnum, Obj_parameter_combination, vehicleData)
    // }

    return ({
        Obj_parameter_combination, Obj_chart_combination, Obj_circuit_map, Obj_circuit_sens,
        df_baseSetup_raw, df_baseSetup, df_mapDriver, df_setupRequest, df_DOEcondition, optimizedResults,
        setupOptions, vehicleData, trackData, model, setup
    })
}

export default StartOptimize