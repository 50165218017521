import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

const WeatherCard = ({ location, data, onClick, precipitationForecast }) => {
    const { t } = useTranslation(['strategy'])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    function convertDirection(direction) {
        let result = '';
    
        for (let char of direction) {
            switch(char) {
                case 'N':
                    result += t('weatherApp.north');
                    break;
                case 'E':
                    result += t('weatherApp.east');
                    break;
                case 'S':
                    result += t('weatherApp.south');
                    break;
                case 'W':
                    result += t('weatherApp.west');
                    break;
                default:
                    result += '';
            }
        }
    
        return result;
    }


    useEffect(() => {
        const handleResize = () => {
        setWindowWidth(window.innerWidth);
        }
    
        window.addEventListener('resize', handleResize);
    
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        }, [])

        if (windowWidth <= 743) {
            return (
            <div className='sp-weather-card' onClick={onClick}>
                <img src={data.condition.icon} alt={data.condition.text} width='100' height='100'></img>
                <div className=''>
                    <div className=''>{`${location.name}`}</div>
                    <div className=''>{`${location.region}`}</div>
                    <div className=''>{`${location.country}`}</div>
                </div>
            </div>
            )
        }
        

    return (
        <div className='sidemenu-weather-card' onClick={onClick}>
            <img src={data.condition.icon} alt={data.condition.text} width='100' height='100'></img>
            <div className='sidemenu-weather-container'>
                {location && (
                    <div className='sidemenu-weather-inner precipitation'>
                        {location.name}, {location.region}, {location.country}
                    </div>
                )}
                {data.time && (
                    <div className='sidemenu-weather-inner precipitation'>
                        {data.time}
                    </div>
                )}

                <div className='sidemenu-weather-inner precipitation'>
                    <div className='sidemenu-weather-wrapper'>
                        <span className='sidemenu-weather-text big'>{data.temp_c.toFixed(1)}</span>
                        <span className='sidemenu-weather-title'>°C&emsp;</span>
                    </div>
                    <div className='sidemenu-weather-wrapper'>
                        <span className='sidemenu-weather-text big'>{data.pressure_mb}</span>
                        <span className='sidemenu-weather-title'>hPa</span>
                    </div>
                </div>
                <div className='sidemenu-weather-inner precipitation'>
                    <div className='sidemenu-weather-wrapper'>
                        <span className='sidemenu-weather-text big'>{(data.wind_kph/3.6).toFixed(1)}</span>
                        <span className='sidemenu-weather-title'>m/s</span>
                    </div>
                    <div className='sidemenu-weather-wrapper'><span className='sidemenu-weather-title'>{convertDirection(data.wind_dir)}</span></div>
                </div>
                <div className='sidemenu-weather-inner precipitation'>
                    {precipitationForecast && (
                        <>
                            <div className='sidemenu-weather-wrapper precipitation-forecast'>
                                <span className='sidemenu-weather-text big'>{precipitationForecast.minute}</span>
                                <span className='sidemenu-weather-title'>{t('weatherApp.afterMinutes')}</span>
                            </div>
                            <div className='sidemenu-weather-wrapper'>
                                <span className='sidemenu-weather-text big'>{ precipitationForecast.precipitation }</span>
                                <span className='sidemenu-weather-title'>{t('weatherApp.precipitation')}</span>
                            </div>
                        </>
                    )}
                </div>

            </div>
        </div>
    )
}

const ForecastDialog = ({ open, onClose, weatherData }) => {
    if (
        weatherData &&
        weatherData.forecast &&
        weatherData.forecast.forecastday &&
        weatherData.forecast.forecastday.length > 0 &&
        weatherData.forecast.forecastday[0].hour
    ) {
        const lastUpdatedEpoch = weatherData.current.last_updated_epoch
        return (
            <Dialog onClose={onClose} open={open}>
                <DialogTitle>Weather Forecast</DialogTitle>
                <div className='sidemenu-weather-container'>
                    {weatherData.forecast.forecastday[0].hour.map((data, index) => (
                        (data.time_epoch > lastUpdatedEpoch) ?
                        <div key={data.time_epoch}>
                            <WeatherCard data={data} />
                        </div>
                        : 
                        <div key={index}></div> 
                    ))}
                </div>
            </Dialog>
        );
    } else {
        return (
            <Dialog onClose={onClose} open={open}>
                <DialogTitle>Weather Forecast</DialogTitle>
                <DialogContent>
                    <p>Sorry, weather data is not available at the moment.</p>
                </DialogContent>
            </Dialog>
        );
    }
};

const WeatherApp = (props) => {
    const [weatherData, setWeatherData] = useState(null);
    const [isForecastOpen, setIsForecastOpen] = useState(false);
    
    const handleForecastOpen = () => {
        setIsForecastOpen(true);
    };

    const handleForecastClose = () => {
        setIsForecastOpen(false);
    };

    if (!props.weatherData.current) return <div className='sidemenu-weather-card loading'>Loading...</div>

    return (
        <>
            <iframe src='https://www.weatherlink.com/embeddablePage/show/07b6f8b0a58d4826a0df1f0fcacedb00/signature' width='760' height='200' frameborder='0'></iframe>
            {/*<ForecastDialog open={isForecastOpen} onClose={handleForecastClose} weatherData={props.weatherData} />*/}
        </>
    );
};

export default WeatherApp;