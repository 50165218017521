import { useEffect, useState} from 'react'
import * as DOMPurify from 'dompurify'

import { convertHMStoDecimal, convertDecimalToHMS } from '../services/Util'

import '../css/Simulation.css'

import { useTranslation } from 'react-i18next'

const SimulationOption = ({ option, strategyList, pitStopLap, pitStopLapTb2, circuitTableChanged, setResultGraphData, raceStrategyList, circuitRaceDistance }) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [ formationLapOption, setFormationLapOption ] = useState([])
    const [ strategyName, setStrategyName ] = useState('')
    const [ circuitId, setCircuitId ] = useState(0)
    const [ refuelingOptionEnable, setRefuelingOptionEnable ] = useState(false)

    useEffect(() => {
        if (raceStrategyList) {
            if(raceStrategyList.length > 0) {
                setCircuitId(raceStrategyList[0].CIRCUIT)
                document.getElementById('raceDistance').innerText = circuitRaceDistance[raceStrategyList[0].CIRCUIT]
                
                const getOptionStrategy = raceStrategyList.find(el => el.OPTIONNO === parseInt(option))
                document.getElementById(`pitstopLapTb_${option}`).value = getOptionStrategy.PITSTOPLAP
                document.getElementById(`pitstopLap2Tb2_${option}`).value = getOptionStrategy.PITSTOPLAP2
                document.getElementById(`selectStrategy_${option}`).selectedIndex = getOptionStrategy.STRATEGY

                const getValue = document.getElementById(`selectStrategy_${option}`).options[getOptionStrategy.STRATEGY]?.innerText.replace('&gt;', '>')
                onChangeSelectStrategyFunc(getValue)
                calculateStrategy()
            }
        }
    }, [raceStrategyList])

    useEffect(() => {
        calculateStrategy()
    }, [circuitTableChanged, refuelingOptionEnable])

    useEffect(() => {
        if (strategyName) calculateStrategy()
    }, [strategyName])

    useEffect(() => {
        const getRaceDistance = circuitRaceDistance[circuitId]
        document.getElementById('raceDistance').innerText = getRaceDistance
        calculateStrategy()
    }, [circuitRaceDistance])

    const calculateStrategy = (inputStartFuel) => {
        const raceDistance = parseFloat(DOMPurify.sanitize(document.getElementById('raceDistance').innerText))
        const estimateAve = parseFloat(DOMPurify.sanitize(document.getElementById('estimateAve').value))
        const maxFuelLoadTb = parseFloat(DOMPurify.sanitize(document.getElementById('maxFuelLoadTb').value))
        const pitStopLap = parseFloat(DOMPurify.sanitize(document.getElementById(`pitstopLapTb_${option}`).value))
        const pitStopLap2 = parseInt(DOMPurify.sanitize(document.getElementById(`pitstopLap2Tb2_${option}`).value))
        const refuelingSpeedSecPerLap = parseFloat(DOMPurify.sanitize(document.getElementById('refuelingSpeedCalculationHidden').innerText))
        const pitworkTime = parseFloat(DOMPurify.sanitize(document.getElementById('pitworkTimeTb').value))
        const blankVal = parseInt(DOMPurify.sanitize(document.getElementById(`setBlankVal_${option}`).innerText))
        const refuelingSpeed = parseFloat(DOMPurify.sanitize(document.getElementById('refuelingSpeed').value))

        pitstopWindowFunc(raceDistance, estimateAve, maxFuelLoadTb)
        const startFuel = typeof inputStartFuel === 'number' ? inputStartFuel : startFuelFunc(estimateAve, raceDistance, pitStopLap, refuelingSpeedSecPerLap, refuelingSpeed, maxFuelLoadTb)

        const refuelingTime = refuelingTimeFunc(raceDistance, blankVal, pitStopLap, pitStopLap2, estimateAve, refuelingSpeed, startFuel)
        pitStopTimeFunc(strategyName, raceDistance, pitStopLap, refuelingSpeedSecPerLap, pitworkTime, refuelingTime)
        firstStintRefuelFunc(startFuel)

        const secondStintRefuelWeight = secondStintRefuelFunc(refuelingTime, refuelingSpeed)

        const refueling2Time = refuelingTime2Func(blankVal, raceDistance, estimateAve, startFuel, secondStintRefuelWeight, refuelingSpeed)
        const thirdStintRefuelWeight = thirdStintRefuelFunc(refueling2Time, refuelingSpeed)
        totalRefuelTimeFunc(refuelingTime, refueling2Time)
        totalRefuelWeightFunc(startFuel, refuelingTime, refuelingSpeed, thirdStintRefuelWeight)
        pitStopTime2Func(pitStopLap2, raceDistance, pitworkTime, refueling2Time, pitStopLap, refuelingSpeedSecPerLap)

        let setFuelObj = {}
        setFuelObj[1] = formationLap1(startFuel, estimateAve, blankVal)
        createFormationLapRow(setFuelObj, raceDistance, blankVal, pitStopLap, pitStopLap2, secondStintRefuelWeight, thirdStintRefuelWeight, estimateAve)
        secondStintStartFuel(setFuelObj, secondStintRefuelWeight, estimateAve)

        // TODO: change to function. fixed value for now
        const raceTotalInMs = raceTotalTime(setFuelObj)
        const aveTimeInMs = setAveTime(raceTotalInMs, raceDistance)

        setResultGraphData(prev => ({
            ...prev,
            [option]: {
                ...prev[option],
                optionData: setFuelObj,
                race_time: raceTotalInMs,
                lap_time_average: aveTimeInMs
            }
        }))
    }

    const onChangeHandlerSelectStrategy = async (e) => {
        const selectedStrategy = e.target.options[e.target.selectedIndex].text
        setStrategyName(selectedStrategy)

        onChangeSelectStrategyFunc(selectedStrategy)

        const obj = {
            UPDATEDVALUE: {
                STRATEGY: parseInt(document.getElementById(`selectStrategy_${option}`).selectedIndex)
            },
            CONDITIONS: {
                USERID: parseInt(localStorage.getItem('userId')),
                OPTIONNO: parseInt(option)
            }
        }

        try {
            await update(obj)
        } catch (error) {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        }
    }

    const onChangeSelectStrategyFunc = (selectedStrategy) => {
        const strategy = strategyList.find(el => el.STRATEGY === selectedStrategy)
        if (strategy) {
            document.getElementById(`setBlankVal_${option}`).innerText = strategy.BLANK

            const getTyreValue = (tyre) => {
                if(!tyre) {
                    return ''
                }
                
                return tyre === 'Soft Tyre' ? 1 : 2
            }

            document.getElementById(`firstStintTyre_${option}`).innerText = strategy.FIRSTSTINT
            document.getElementById(`firstStintVal_${option}`).innerText = getTyreValue(strategy.FIRSTSTINT)
            document.getElementById(`secondStintTyre_${option}`).innerText = strategy.SECONDSTINT
            document.getElementById(`secondStintVal_${option}`).innerText = getTyreValue(strategy.SECONDSTINT)
            document.getElementById(`thirdStintTyre_${option}`).innerText = strategy.THIRDSTINT
            document.getElementById(`thirdStintVal_${option}`).innerText = getTyreValue(strategy.THIRDSTINT)

            setResultGraphData(prev => ({
                ...prev,
                [option]: {
                    ...prev[option],
                    strategy: selectedStrategy,
                    display: true
                }
            }))
        }
    }

    const totalRefuelTimeFunc = (refuelingTime, refueling2Time) => {
        const totalRefuelTime = parseFloat(refuelingTime) + parseFloat(refueling2Time)
        document.getElementById(`totalRefuelTime_${option}`).innerText = totalRefuelTime.toFixed(2)
    }
    
    const totalRefuelWeightFunc = (getStartFuel, refuelingTime, refuelingSpeed, thirdStintRefuelWeight) => {
        const totalRefuelTime = parseFloat(getStartFuel) + parseFloat(refuelingTime*refuelingSpeed) + parseFloat(thirdStintRefuelWeight)
        document.getElementById(`totalRefuelWeight_${option}`).innerText = totalRefuelTime.toFixed(2)
    }

    const pitstopWindowFunc = (getRaceDistance, getEstimateAve, getMaxFuelLoadTb) => {
        const leftVal = ((getRaceDistance*getEstimateAve+3) + (2*getEstimateAve*0.7)-getMaxFuelLoadTb)/getEstimateAve
        const rightVal = (getMaxFuelLoadTb - (2*getEstimateAve*0.7) -3)/getEstimateAve
        document.getElementById(`pitStopWindow_${option}`).innerText = Math.round(leftVal) + '-' + Math.floor(rightVal)
    }

    const pitStopTimeFunc = (strategyName, getRaceDistance, pitStopLap, refuelingSpeedSecPerLap, pitworkTime, refuelingTime) => {
        let pitStopTime = 0

        if (strategyName === 'Refuel Only') {
            pitStopTime = (getRaceDistance - pitStopLap) * refuelingSpeedSecPerLap
        } else {
            if (pitworkTime<refuelingTime) {
                pitStopTime = (getRaceDistance - pitStopLap) * refuelingSpeedSecPerLap
            } else{
                pitStopTime = pitworkTime
            }
        }

        document.getElementById(`pitStopTime_${option}`).innerText = pitStopTime.toFixed(2)
        document.getElementById(`pitStopTimeHidden_${option}`).innerText = pitStopTime
    }

    const startFuelFunc = (getEstimateAve, getRaceDistance, pitStopLap, refuelingSpeedSecPerLap, refuelingSpeed, getMaxFuelLoadTb, ) => {
        let startFuel
        if (refuelingOptionEnable) {
            startFuel = (getEstimateAve*getRaceDistance+3)-((getRaceDistance-pitStopLap)*refuelingSpeedSecPerLap*refuelingSpeed)+getEstimateAve*0.7*2
            startFuel = startFuel > getMaxFuelLoadTb ? getMaxFuelLoadTb : startFuel
            startFuel = startFuel+11.56*refuelingSpeed
        } else {
            startFuel = (getEstimateAve*getRaceDistance+3) + getEstimateAve*0.7*2
        }
        
        startFuel = startFuel ? startFuel : 0
        document.getElementById(`startFuel_${option}`).value = startFuel.toFixed(1)
        return startFuel
    }

    const refuelingTimeFunc = (raceDistance, blankVal, pitStopLap, pitStopLap2, estimateAve, refuelingSpeed, startFuel) => {
        let refuelingTime = 0
        if (refuelingOptionEnable) {
            if (blankVal >= 5) {
                refuelingTime = ((pitStopLap2 - pitStopLap) * estimateAve) / refuelingSpeed
            } else {
                const ifCalculation = ((raceDistance*estimateAve+(estimateAve*0.7*2)+3)-startFuel)/refuelingSpeed
                if (ifCalculation <= 0) {
                    refuelingTime = 0
                } else {
                    refuelingTime = ((raceDistance*estimateAve+(estimateAve*0.7*2)+3)-startFuel)/refuelingSpeed
                }
            }
        }

        document.getElementById(`refuelingTime_${option}`).innerText = refuelingTime.toFixed(2)

        return refuelingTime
    }

    const firstStintRefuelFunc = (getStartFuel) => {
        document.getElementById(`firstStintRefuelWeight_${option}`).innerText = getStartFuel ? getStartFuel.toFixed(2) : 0
    }

    const secondStintRefuelFunc = (refuelingTime, refuelingSpeed) => {
        document.getElementById(`secondStintRefuelTime_${option}`).innerText = refuelingTime.toFixed(2)
        document.getElementById(`secondStintRefuelWeight_${option}`).innerText = (refuelingTime * refuelingSpeed).toFixed(2)

        return (refuelingTime * refuelingSpeed)
    }

    const secondStintStartFuel = (setFuelObj, secondStintRefuelWeight, getEstimateAve) => {
        let secondStintStartFuelVal = 0
        const arr = Object.values(setFuelObj)
        const findFuel = arr.find(el => el.pit_sc === 'Pit_1')
        if (!findFuel) return
        const fuelStart = findFuel.fuelStartLap
        secondStintStartFuelVal = fuelStart + secondStintRefuelWeight - getEstimateAve
        document.getElementById(`secondStingStartFuel_${option}`).value = secondStintStartFuelVal.toFixed(1)
    }

    const thirdStintRefuelFunc = (refueling2Time, refuelingSpeed) => {
        document.getElementById(`thirdStintRefuelTime_${option}`).innerText = refueling2Time.toFixed(2)
        document.getElementById(`thirdStintRefuelWeight_${option}`).innerText = (refueling2Time*refuelingSpeed).toFixed(2)

        return (refueling2Time*refuelingSpeed)
    }

    const refuelingTime2Func = (blankVal, getRaceDistance, getEstimateAve, getStartFuel, secondStintRefuelWeight, refuelingSpeed) => {
        let refuelTime2 = 0

        if (blankVal >= 5) {
            refuelTime2 = (((getRaceDistance*getEstimateAve)+(2*getEstimateAve*0.7)+3)-getStartFuel-secondStintRefuelWeight)/refuelingSpeed
        } else {
            refuelTime2 = 0
        }

        document.getElementById(`refuelingTime2_${option}`).innerText = refuelTime2

        return refuelTime2
    }

    const pitStopTime2Func = (pitStopLap2, getRaceDistance, pitworkTime, refueling2Time, pitStopLap, refuelingSpeedSecPerLap) => {
        let pitStopTime2 = 0

        if (pitStopLap2 > getRaceDistance) {
            pitStopTime2 = ''
        } else {
            if (pitworkTime<refueling2Time) {
                pitStopTime2 = (getRaceDistance-pitStopLap)*refuelingSpeedSecPerLap
            } else {
                pitStopTime2 = pitworkTime
            }
        }

        document.getElementById(`pitStopTime2_${option}`).innerText = pitStopTime2
    }

    const raceTotalTime = (setFuelObj) => {
        let time = 0

        Object.values(setFuelObj).forEach(data => {
            const eachLaptime = convertHMStoDecimal(data.lapTime)
            time += eachLaptime
        })

        document.getElementById(`raceTotalTime_${option}`).innerText = convertDecimalToHMS(time)

        return time
    }

    const setAveTime = (raceTotalInMs, getRaceDistance) => {
        document.getElementById(`lapAveTime_${option}`).innerText = convertDecimalToHMS(raceTotalInMs/getRaceDistance)

        return convertDecimalToHMS(raceTotalInMs/getRaceDistance)
    }

    const formationLap1 = (getStartFuel, getEstimateAve, blankVal, pitStopLap, pitStopLap2) => {
        const fuelStartLap = getStartFuel-(getEstimateAve*0.7*2)
        let fuelEndLap = 0
        if (typeof parseInt(document.getElementById(`lap1_laps_${option}`).innerText) === 'number') {
            if (document.getElementById(`lap1_pit_sc_${option}`).innerText === 'SC') {
                fuelEndLap = fuelStartLap-getEstimateAve*0.7
            } else {
                fuelEndLap = fuelStartLap-getEstimateAve
            }
        } else {
            fuelEndLap = ''
        }

        const fuelAveLap = (fuelStartLap + fuelEndLap) / 2

        const pit_sc = getPit_Sc(1, blankVal, pitStopLap, pitStopLap2)

        const prev = {
            pit_sc: document.getElementById(`lap_pit_sc_${option}`).innerText
        }
        const retObj = {
            pit_sc,
            fuelStartLap,
            fuelEndLap,
            fuelAveLap
        }
        const lapTime = lapTimeCalculation(1, prev, retObj, pitStopLap2, pitStopLap)

        document.getElementById(`lap1_pit_sc_${option}`).innerText = pit_sc
        document.getElementById(`lap1_laptime_${option}`).innerText = convertDecimalToHMS(lapTime)
        document.getElementById(`lap1_fuelStart_${option}`).innerText = fuelStartLap.toFixed(1)
        document.getElementById(`lap1_fuelEnd_${option}`).innerText = fuelEndLap.toFixed(1)
        document.getElementById(`lap1_fuelAve_${option}`).innerText = fuelAveLap.toFixed(2)

        retObj.lapTime = convertDecimalToHMS(lapTime)
        retObj.accumulatedLapTime = lapTime
        return retObj
    }

    const createFormationLapRow = (setFuelObj, getRaceDistance, blankVal, pitStopLap, pitStopLap2, secondStintRefuelWeight, thirdStintRefuelWeight, getEstimateAve) => {
        let i = 1
        let tr = []

        while (Object.keys(setFuelObj).length+1 <= getRaceDistance) {
            i++

            const pit_sc = getPit_Sc(i, blankVal, pitStopLap, pitStopLap2, setFuelObj[i], secondStintRefuelWeight, getEstimateAve)
            const fuelStartLap = fuelStartN(setFuelObj[i-1], secondStintRefuelWeight, thirdStintRefuelWeight)
            const fuelEndLap = fuelEndN(pit_sc, fuelStartLap ? fuelStartLap : 0, getEstimateAve)
            const fuelAveLap = fuelAveN(fuelStartLap, fuelEndLap)

            setFuelObj[i] = {
                pit_sc,
                fuelStartLap,
                fuelEndLap,
                fuelAveLap
            }

            const lapTime = lapTimeCalculation(i, setFuelObj[i-1], setFuelObj[i], pitStopLap2, pitStopLap)

            tr.push(<tr key={i}>
                <td>{i}</td>
                <td>{convertDecimalToHMS(lapTime)}</td>
                <td>{pit_sc}</td>
                <td>{fuelStartLap.toFixed(1)}</td>
                <td>{fuelEndLap.toFixed(1)}</td>
                <td>{fuelAveLap.toFixed(2)}</td>
            </tr>)

            setFuelObj[i].lapTime = convertDecimalToHMS(lapTime)
            const accumulatedLapTime = lapTime + parseFloat(setFuelObj[i-1].accumulatedLapTime)
            setFuelObj[i].accumulatedLapTime = accumulatedLapTime
        }
        setFormationLapOption(tr)
    }

    const fuelStartN = (prevLapInfo, secondStintRefuelWeight, thirdStintRefuelWeight) => {
        let fuelStart = 0
        const prevFuelEnd = prevLapInfo.fuelEndLap
        if (prevLapInfo.pit_sc === 'Pit_1') {
            fuelStart = prevFuelEnd + secondStintRefuelWeight
        } else if(prevLapInfo.pit_sc === 'Pit_2') {
            fuelStart = prevFuelEnd + thirdStintRefuelWeight
        } else {
            fuelStart = prevFuelEnd
        }

        return fuelStart
    }

    const fuelEndN = (pit_sc, fuelStart, getEstimateAve) => {
        let fuelEnd = 0

        if (pit_sc === 'SC') {
            fuelEnd = fuelStart - getEstimateAve * 0.7
        } else {
            fuelEnd = fuelStart - getEstimateAve
        }

        return fuelEnd
    }
    
    const fuelAveN = (fuelStartLap, fuelEndLap) => {
        let fuelAve = (fuelStartLap + fuelEndLap) / 2

        return fuelAve
    }

    const lapTimeCalculation = (lapNo, prev, retObj, pitStopLap2, pitStopLap) => {
        let retTime = 0
        const firstStintVal = parseFloat(document.getElementById(`firstStintVal_${option}`).innerText)
        const secondStintVal = parseFloat(document.getElementById(`secondStintVal_${option}`).innerText)
        const thirdStintVal = parseFloat(document.getElementById(`thirdStintVal_${option}`).innerText)

        const firstStintTyreMileage = parseFloat(document.getElementById(`firstStintTyreMileage_${option}`).value)
        const secondStintTyreMileage = parseFloat(document.getElementById(`secondStintTyreMileage_${option}`).value)
        const thirdStintTyreMileage = parseFloat(document.getElementById(`thirdStintTyreMileage_${option}`).value)

        const pitStopTime2 = parseFloat(document.getElementById(`pitStopTime2_${option}`).innerText === '' ? 0 : document.getElementById(`pitStopTime2_${option}`).innerText)

        const fc_soft_mmss = parseFloat(convertHMStoDecimal(document.getElementById('fc_soft_mmss').innerText))
        const fastInMediumMMSS = parseFloat(convertHMStoDecimal(document.getElementById('fastInMediumMMSS').value))
        const pitRoadLossTime = parseFloat(document.getElementById('pitRoadLossTime').innerText)
        const fuelEffect = parseFloat(document.getElementById('fuelEffect').innerText)
        
        const tyre_deg_in_soft = parseFloat(document.getElementById('tyre_deg_in_soft').value)
        const tyreDegInMedium = parseFloat(document.getElementById('tyreDegInMedium').value)

        const pitStopTime = parseFloat(document.getElementById(`pitStopTimeHidden_${option}`).innerText)
        const fuelWeightInAttackLapOfQF = parseFloat(document.getElementById('fuelWeightInAttackLapOfQF').value)
        const reactionTime = parseFloat(document.getElementById('reactionTime').value)
        const tyreWUTime = parseFloat(document.getElementById('tyreWUTime').value)

        const fuelAve = retObj.fuelAveLap

        const prevPitSc = prev.pit_sc

        if (prevPitSc === 'Pit_2') {
            if (thirdStintVal === 1) {
                retTime = fc_soft_mmss+(((thirdStintTyreMileage+(lapNo-pitStopLap2))*tyre_deg_in_soft)/(24*60*60))+(((fuelAve-fuelWeightInAttackLapOfQF)*fuelEffect/10)/(24*60*60))+((pitRoadLossTime+pitStopTime2+reactionTime+tyreWUTime)/(24*60*60))
            } else if (thirdStintVal === 2) {
                retTime = fastInMediumMMSS+(((thirdStintTyreMileage+(lapNo-pitStopLap2))*tyreDegInMedium)/(24*60*60))+(((fuelAve-fuelWeightInAttackLapOfQF)*fuelEffect/10)/(24*60*60))+((pitRoadLossTime+pitStopTime2+reactionTime+tyreWUTime)/(24*60*60))
            }
        } else {
            if (lapNo>pitStopLap2) {
                if (secondStintVal === 1) {
                    retTime = fc_soft_mmss+(((secondStintTyreMileage+(lapNo-pitStopLap2))*tyre_deg_in_soft)/(24*60*60))+(((fuelAve-fuelWeightInAttackLapOfQF)*fuelEffect/10)/(24*60*60))
                } else if (secondStintVal === 2) {
                    retTime = fastInMediumMMSS+(((secondStintTyreMileage+(lapNo-pitStopLap2))*tyreDegInMedium)/(24*60*60))+(((fuelAve-fuelWeightInAttackLapOfQF)*fuelEffect/10)/(24*60*60))
                }
            } else {
                if (prevPitSc === 'Pit_1') {
                    if (secondStintVal === 1) {
                        retTime = fc_soft_mmss+(((secondStintTyreMileage+(lapNo-pitStopLap))*tyre_deg_in_soft)/(24*60*60))+(((fuelAve-fuelWeightInAttackLapOfQF)*fuelEffect/10)/(24*60*60))+((pitRoadLossTime+pitStopTime+reactionTime+tyreWUTime)/(24*60*60))
                    } else if (secondStintVal === 2) {
                        retTime = fastInMediumMMSS+(((secondStintTyreMileage+(lapNo-pitStopLap))*tyreDegInMedium)/(24*60*60))+(((fuelAve-fuelWeightInAttackLapOfQF)*fuelEffect/10)/(24*60*60))+((pitRoadLossTime+pitStopTime+reactionTime+tyreWUTime)/(24*60*60))
                    }
                } else {
                    if (lapNo>pitStopLap) {
                        if (secondStintVal === 1) {
                            retTime = fc_soft_mmss+(((secondStintTyreMileage+(lapNo-pitStopLap))*tyre_deg_in_soft)/(24*60*60))+(((fuelAve-fuelWeightInAttackLapOfQF)*fuelEffect/10)/(24*60*60))
                        } else if (secondStintVal === 2) {
                            retTime = fastInMediumMMSS+(((secondStintTyreMileage+(lapNo-pitStopLap))*tyreDegInMedium)/(24*60*60))+(((fuelAve-fuelWeightInAttackLapOfQF)*fuelEffect/10)/(24*60*60))
                        }
                    } else {
                        if (firstStintVal === 1) {
                            retTime = fc_soft_mmss+(((firstStintTyreMileage+lapNo)*tyre_deg_in_soft)/(24*60*60))+(((fuelAve-fuelWeightInAttackLapOfQF)*fuelEffect/10)/(24*60*60))
                        } else if (firstStintVal === 2) {
                            retTime = fastInMediumMMSS+(((firstStintTyreMileage+lapNo)*tyreDegInMedium)/(24*60*60))+(((fuelAve-fuelWeightInAttackLapOfQF)*fuelEffect/10)/(24*60*60))
                        }
                    }
                }
            }
        }

        return retTime
    }

    const getPit_Sc = (lapNo, blankVal, pitStopLap, pitStopLap2) => {
        let pit_sc = ''
        if (blankVal>=5) {
            if (lapNo === pitStopLap2) {
                pit_sc = 'Pit_2'
            } else {
                if (lapNo === pitStopLap) {
                    pit_sc = 'Pit_1'
                }
            }
        } else {
            if (lapNo === pitStopLap) {
                pit_sc = 'Pit_1'
            }
        }

        return pit_sc
    }

    const onBlurHandlerUpdate = (key) => async (e) => {
        const obj = {
            UPDATEDVALUE: {
                [key]: parseInt(e.target.value)
            },
            CONDITIONS: {
                USERID: parseInt(localStorage.getItem('userId')),
                OPTIONNO: parseInt(option)
            }
        }

        try {
            await update(obj)
        } catch (error) {
            alert(t('pleaseContactAdmin', {ns: 'general'}))
        }
    }

    const onBlurHandlerCalculateFromStartFuel = (e) => {
        const startFuel = parseFloat(e.target.value)
        calculateStrategy(typeof startFuel === 'number' ? startFuel : 0)
    }

    const onBlurHandlerCalculateFromSecondStingStartFuel = (e) => {

    }
    
    return (
        <>
            <table border={1}>
                <thead>
                    <tr>
                        <td colSpan={6}>Option {option}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Strategy</td>
                        <td colSpan={2}>
                            <select id={'selectStrategy_' + option} onChange={onChangeHandlerSelectStrategy}>
                                <option>{t('pleaseSelect', {ns: 'general'})}</option>
                                {
                                    strategyList.map(data => {
                                        return <option key={data.ID} value={data.BLANK}>{data.STRATEGY}</option>
                                    })
                                }
                            </select>
                        </td>
                        <td id={'setBlankVal_' + option}></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colSpan={3}></td>
                        <td>Tyre Mileage [lu]</td>
                        <td>Refuel Time [sec]</td>
                        <td>Refuel Weight [kg]</td>
                    </tr>
                    <tr>
                        <td>1st Stint</td>
                        <td id={'firstStintTyre_' + option}></td>
                        <td id={'firstStintVal_' + option}></td>
                        <td>
                            <input id={'firstStintTyreMileage_' + option} className='inputNumber' type='number' defaultValue='0' onChange={calculateStrategy}></input>
                        </td>
                        <td id={'firstStintRefuelTime_' + option}>0.00</td>
                        <td id={'firstStintRefuelWeight_' + option}>-</td> 
                    </tr>
                    <tr>
                        <td>2nd Stint</td>
                        <td id={'secondStintTyre_' + option}></td>
                        <td id={'secondStintVal_' + option}></td>
                        <td>
                            <input id={'secondStintTyreMileage_' + option} className='inputNumber' type='number' defaultValue='0' onChange={calculateStrategy}></input>
                        </td>
                        <td id={'secondStintRefuelTime_' + option}>-</td>
                        <td id={'secondStintRefuelWeight_' + option}>-</td> 
                    </tr>
                    <tr>
                        <td>3rd Stint</td>
                        <td id={'thirdStintTyre_' + option}></td>
                        <td id={'thirdStintVal_' + option}></td>
                        <td>
                            <input id={'thirdStintTyreMileage_' + option} className='inputNumber' type='number' defaultValue='0'></input>
                        </td>
                        <td id={'thirdStintRefuelTime_' + option}>-</td>
                        <td id={'thirdStintRefuelWeight_' + option}>-</td> 
                    </tr>
                    <tr>
                        <td colSpan={3}></td>
                        <td>Total</td>
                        <td id={'totalRefuelTime_' + option}></td>
                        <td id={'totalRefuelWeight_' + option}></td>
                    </tr>
                    <tr>
                        <td colSpan={6}>
                            <span>Refueling Option Enable ? </span>
                            <label className='switch'>
                                <input
                                    type='checkbox'
                                    className='switch-input'
                                    checked={refuelingOptionEnable}
                                    onChange={() => setRefuelingOptionEnable(!refuelingOptionEnable)}
                                />
                                <span className='slider round'></span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>Pitstop Lap</td>
                        <td>
                            <input id={`pitstopLapTb_${option}`} className='inputNumber' defaultValue={pitStopLap}
                            onChange={calculateStrategy} onBlur={onBlurHandlerUpdate('PITSTOPLAP')}></input>
                        </td>
                        <td>[Laps]</td>
                        <td>Pitstop Window</td>
                        <td id={'pitStopWindow_' + option}></td>
                        <td>[Laps]</td>
                    </tr>
                    <tr>
                        <td>Pit Stop Time</td>
                        <td>
                            <div id={'pitStopTimeHidden_' + option} style={{display:'none'}}></div>
                            <div id={'pitStopTime_' + option}>-</div>
                        </td>
                        <td>[sec]</td>
                        <td>RefuelingTime</td>
                        <td id={'refuelingTime_' + option}>-</td>
                        <td>[sec]</td>
                    </tr>
                    <tr>
                        <td>Start Fuel</td>
                        <td>
                            <input className='inputNumber' type='number' id={'startFuel_' + option} defaultValue={0} onBlur={onBlurHandlerCalculateFromStartFuel} />
                        </td>
                        <td>[kg]</td>
                        <td colSpan={3}>incld. GoGrid&FormationLap</td>
                    </tr>
                    <tr>
                        <td className='colorBlack' colSpan={6}>-</td>
                    </tr>
                    <tr>
                        <td className='colorBlack' colSpan={6}>-</td>
                    </tr>
                    <tr>
                        <td>Pitstop Lap</td>
                        <td>
                            <input id={'pitstopLap2Tb2_' + option} className='inputNumber' defaultValue={pitStopLapTb2} onChange={calculateStrategy} onBlur={onBlurHandlerUpdate('PITSTOPLAP2')}></input>
                        </td>
                        <td>[Laps]</td>
                        <td colSpan={3}></td>
                    </tr>
                    <tr>
                        <td>Pit Stop Time</td>
                        <td id={'pitStopTime2_' + option}>-</td>
                        <td>[sec]</td>
                        <td>RefuelingTime</td>
                        <td id={'refuelingTime2_' + option}>-</td>
                        <td>[sec]</td>
                    </tr>
                    <tr>
                        <td>2nd Stint Start Fuel</td>
                        <td>
                            <input className='inputNumber' type='number' id={'secondStingStartFuel_' + option} defaultValue={0} onBlur={onBlurHandlerCalculateFromSecondStingStartFuel} />
                        </td>
                        <td>[kg]</td>
                        <td colSpan={3}></td>
                    </tr>
                    <tr>
                        <td className='colorBlack' colSpan={6}>-</td>
                    </tr>
                    <tr>
                        <td className='bottomBold'>Race Total Time</td>
                        <td id={'raceTotalTime_' + option} className='bottomBold'>-</td>
                        <td></td>
                        <td className='bottomBold'>Lap Time Ave.</td>
                        <td id={'lapAveTime_' + option} className='bottomBold'>-</td>
                    </tr>
                    <tr>
                        <td className='colorBlack' colSpan={6}>-</td>
                    </tr>

                    <tr>
                        <td>Laps</td>
                        <td>Lap Time</td>
                        <td>Pit / SC</td>
                        <td>Fuel start</td>
                        <td>Fuel end</td>
                        <td>Fuel Ave</td>
                    </tr>
                    <tr>
                        <td>Formation Lap</td>
                        <td></td>
                        <td id={'lap_pit_sc_' + option}>SC</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    {/* lap 1 only */}
                    <tr>
                        <td id={'lap1_laps_' + option}>1</td>
                        <td id={'lap1_laptime_' + option}>-</td>
                        <td id={'lap1_pit_sc_' + option}></td>
                        <td id={'lap1_fuelStart_' + option}>-</td>
                        <td id={'lap1_fuelEnd_' + option}>-</td>
                        <td id={'lap1_fuelAve_' + option}>-</td>
                    </tr>
                    {formationLapOption}
                </tbody>
            </table>
        </>
    )
}

export default SimulationOption

function update(obj) {
    return new Promise ((resolve, reject) => {
        fetch('/api/raceStrategy/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        })
        .then(response => response.json())
        .then(json => {
            resolve(json)
        })
        .catch((error) => {
            reject(error)
        })
    })
}