import React, { useRef, useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { TableContainer, Paper, Table, TableRow, TableBody } from '@mui/material'
import { StyledTableCell } from './TableStyle'
import ClassColorDisplay from './CarClass'

import { useLiveData } from '../../DataContext'

import setting from '../image/setting-icon.png'
import download from '../image/download-icon.png'
import display from '../image/display-icon.svg'
import prediction from '../image/future-predictions.svg'
import stint  from  '../image/stint.svg'
import light from '../image/light.svg'
import dark from '../image/dark.svg'

import chart  from  '../image/chart-on.png'
import table  from  '../image/table-off.png'
import map  from  '../image/map-on.png'
import management from  '../image/management.png'

import lap  from  '../image/lap.svg'

import '../css/headerMenu.css'

import TimeFormatter from './TimeFormatter'
import WeatherApp from './WeatherApp'

import { useTranslation } from 'react-i18next'

const HeaderWithMenu = (props) => {
    const { t } = useTranslation(['strategy', 'general'])

    const [ columnOrder, setColumnOrder ] = useState([])
    const [ columns, setColumns ] = useState([])
    const [ currentUrl, setCurrentUrl ] = useState('')
    const [ remainingTimeAndLaps, setRemainingTimeAndLaps ] = useState()

    const sideMenuHintElement = useRef()
    const viewHandlerButtonElement = useRef()
    const classHandlerButtonElement = useRef()
    const changeViewElement = useRef()

    const { tableData, isRace, followCarNumber, predictPitInMode, projectPositionMode, raceSeries, isLightMode, styleSheet } = useLiveData()
    
    const columnIdAndName = props.columnIdAndName
    const raceTimeAndLaps = props.raceTimeAndLaps
    const totalTimeForRemainingTime = props.totalTimeForRemainingTime
    const flagInfo = props.flagInfo
    const displayRivalHeaders = props.displayRivalHeaders
    const setDisplayRivalHeaders = props.setDisplayRivalHeaders
    const mapDisplayLeaders = props.mapDisplayLeaders
    const mapDisplayGroups = props.displayMapGroups
    const setDisplayMapLeaders = props.setDisplayMapLeaders
    const setDisplayMapGroups = props.setDisplayMapGroups

    const tabletMode = props.tabletMode
    const mobileMode = props.mobileMode
    const setTabletMode = props.setTabletMode
    const setMobileMode = props.setMobileMode
    const [ pcMode, setPcMode ] = useState(false)

    const closeSideMenuHint = (event) => {
        event.stopPropagation()
        if (sideMenuHintElement.current) {
            sideMenuHintElement.current.classList.remove('show')
        }
    }

    document.addEventListener('click', closeSideMenuHint)

    useEffect(()=> {
        const tabletMode = window.innerWidth <= 1080 && window.innerWidth >= 768
        const mobileMode = window.innerWidth < 768
        setTabletMode(tabletMode)
        setMobileMode(mobileMode)
    }, [])

    useEffect(()=> {
        if(mobileMode) {
            setTabletMode(false)
            setPcMode(false)
        }
        if(tabletMode) {
            setMobileMode(false)
            setPcMode(false)
        }
        if(pcMode) {
            setMobileMode(false)
            setTabletMode(false)
        }
    }, [tabletMode, mobileMode, pcMode])

    useEffect(() => {
        const getRaceDetailList = () => {
            fetch('/api/raceDetail/getAll')
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    let data = json.data
                    props.originalRaceDetailListUpdate(data)
                    const list = data.map((race, index) => (
                        <option key = { index + 1 } value = { race.ID } data-name = { race.RACE_DETAIL_NAME }>{ race.RACE_DETAIL_NAME }</option>
                    ))

                    list.unshift(<option key = { 0 } value = { 0 } data-name = { t('pleaseSelect', {ns: 'general'}) }>{ t('pleaseSelect', {ns: 'general'}) }</option>)

                    props.raceDetailListUpdate(list)
                }
            })
            .catch((error) => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
        }
            getRaceDetailList()
    }, [])

    useEffect(() => {
        setRemainingTimeAndLaps(props.remainingTimeAndLaps)
    }, [props.remainingTimeAndLaps])

    useEffect(() => {
        if (!props.columnOrder) return

        const url = window.location.href
        if (url !== currentUrl) {
            setCurrentUrl(url)
            if(raceSeries === 'SE') {
                setColumns(Object.keys(columnIdAndName).filter((el) => el !== 'ots'))
            } else if(raceSeries === 'SF') {
                setColumns(  Object.keys(columnIdAndName).filter((el) => el !== 'class' && el !== 'drType' && el !== 'drivingTime'))
            }
            setColumnOrder(props.columnOrder)
            return
        }

        const hiddenColumns = columns.filter((el) => !props.columnOrder.includes(el))
        const updatedColumns = [...props.columnOrder]

        hiddenColumns.forEach((el) => {
            const index = columns.indexOf(el)
            updatedColumns.splice(index, 0, el)
        })

        setColumns(updatedColumns)
        setColumnOrder(props.columnOrder)
    }, [props.columnOrder, window.location.href])

    useEffect(() => {
        let intervalId
      
        const decreaseRemainingTimeAndLaps = () => {
            setRemainingTimeAndLaps((prevRemainingTimeAndLaps) => prevRemainingTimeAndLaps - 1)
        }
      
        if (raceSeries === 'SE' && remainingTimeAndLaps !== '' && raceTimeAndLaps) {
            if ((raceTimeAndLaps - totalTimeForRemainingTime) <= 0) {
                setRemainingTimeAndLaps('')
            } else {
                setRemainingTimeAndLaps(raceTimeAndLaps - totalTimeForRemainingTime)
            }
      
            intervalId = setInterval(() => {
                if (raceTimeAndLaps - totalTimeForRemainingTime <= 1) {
                    setRemainingTimeAndLaps('')
                } else {
                    decreaseRemainingTimeAndLaps()
                }
            }, 1000)
        }
      
        return () => {
          clearInterval(intervalId)
        }
    }, [totalTimeForRemainingTime])
    
    const allDriverLapsTableHandler = () => {
        props.setModalType('alldriverlaps')
        props.setOpenModal(true)
    }

    const paceCatcherHandler = () => {
        props.setModalType('paceCatcher')
        props.setOpenModal(true)
    }

    const tableChartMapHandler = (e) => {
        const view = e.currentTarget.value
        if(view === 'table') {
            props.setTableChartToggle('table')
            props.setDisplayMap(true)
        } else if(view === 'chart') {
            props.setTableChartToggle('chart')
            props.setDisplayMap(false)
            if (window.innerWidth <= 743) {
                alert('横向きでお使いください');
            }
        } else if(view === 'map') {
            props.setTableChartToggle('map')
            props.setDisplayMap(true)
            
        } else if(view === 'management') {
            props.setTableChartToggle('management')
            props.setDisplayMap(false)
        }


    }

    const stintHistoryHandler = () => {
        props.setModalType('stintHistory')
        props.setOpenModal(true)
    }

    const pitInPrediction = (e) => {
        const isChecked = e.target.checked
        props.setPredictPitInMode(isChecked)
    }

    const handleProjectPosition = (e) => {
        const isChecked = e.target.checked
        props.setProjectPositionMode(isChecked)
    } 

    const handleRivalHeaders = (e) => {
        const isChecked = e.target.checked
        setDisplayRivalHeaders(isChecked)
    }

    const handleDisplayMap = (e) => {
        const isChecked = e.target.checked
        props.setDisplayMap(isChecked)
    }

    const handleMapLeaders = (e) => {
        const isChecked = e.target.checked
        setDisplayMapLeaders(isChecked)
    }

    const handleMapGroups = (e) => {
        const isChecked = e.target.checked
        setDisplayMapGroups(isChecked)
    }

    const handleDisplayFollowAndRivals = (e) => {
        const isChecked = e.target.checked
        props.setDisplayFollowAndRivals(isChecked)
    } 

    const settingViewHandler = () => {
        props.setModalType('settingView')
        props.setOpenModal(true)
    } 

    const reportViewHandler = () => {
        props.setModalType('reportDownloadView')
        props.setOpenModal(true)
    } 

    const viewHandler = () => {
        if (props.changeViewMode) {
            props.setChangeViewMode(false)
        } else {
            props.setChangeViewMode(true)
        }
    }



    const toggle = document.getElementById('toggle');
    const label = document.getElementById('toggle-label');

    const handleToggleChange = () => {
        document.body.classList.toggle('light-mode');
        if (toggle.checked) {
            label.textContent = 'Dark Mode';
        } else {
            label.textContent = 'Light Mode';
        }

        props.setIsLightMode(prevState => !prevState);
    }

    const onChangedashboadstyle = () => {
        props.setStyleSheet(prevState => !prevState);
    }


    const renderViewTable = (column, provided = {}, snapshot = {}) => {
        // SFのみ
        if (column === 'ots' && raceSeries !== 'SF') return null

        // SEのみ
        if ((column === 'class' || column === 'drType') && raceSeries !== 'SE') return null

        let displayName
        let checked
        let onChangeHandler

        if (column === 'all') {
            displayName = 'All'
            checked = columnOrder.length === columns.length
            onChangeHandler = (e) => {
                const isChecked = e.target.checked

                if (isChecked) {
                    props.setColumnOrder(columns)
                } else {
                    props.setColumnOrder([])
                }
            }
        } else if(column === 'rivalHeaders') {
            displayName = 'Follow/Rivals'
            checked = displayRivalHeaders
            onChangeHandler = handleRivalHeaders
        }else if (column === 'map') {
            displayName = 'Map'
            checked = props.displayMap
            onChangeHandler = handleDisplayMap
        } else if (column === 'mapLeaders') {
            displayName = 'Map Leaders'
            checked = mapDisplayLeaders
            onChangeHandler = handleMapLeaders
        } else if(column === 'mapGroups') {
            displayName = 'Map Groups'
            checked = mapDisplayGroups
            onChangeHandler = handleMapGroups
        } else if(column === 'markedCars') {
            displayName = 'Marked Cars'
            checked = props.displayFollowAndRivals
            onChangeHandler = handleDisplayFollowAndRivals
        } else {
            displayName = columnIdAndName[column]
            checked = columnOrder.includes(column)
            onChangeHandler = (e) => {
                const isChecked = e.target.checked
                if (isChecked) {
                    const newColumnOrder = [...columnOrder, column].sort((a, b) => columns.indexOf(a) - columns.indexOf(b))
                    props.setColumnOrder(newColumnOrder)
                } else {
                    props.setColumnOrder(columnOrder.filter((el) => el !== column))
                }
            }
        }

        if (!displayName) return null

        return (
            <TableRow
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                sx={{
                    height: '40px', 
                    backgroundColor: isLightMode 
                        ? (snapshot.isDragging ? '#ffffff' : '#242424') 
                        : (snapshot.isDragging ? '#535353' : '#242424'), 
                    ...provided.draggableProps?.style, 
                }}
            >
                <StyledTableCell style={{ color: isLightMode ? '#000' : '#fff', fontWeight: '400', textAlign: 'left', paddingLeft: '12%' }}>
                    {displayName}
                </StyledTableCell>
                <StyledTableCell>
                    <label className='switch'>
                        <input
                            type='checkbox'
                            className='switch-input'
                            checked={checked}
                            onChange={onChangeHandler}
                        />
                        <span className='slider round'></span>
                    </label>
                </StyledTableCell>
            </TableRow>
        )
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return

        const newColumnOrder = [...columns]
        const [movedRow] = newColumnOrder.splice(result.source.index, 1)
        newColumnOrder.splice(result.destination.index, 0, movedRow)
        setColumns(newColumnOrder)

        const newColumnOrderFiltered = newColumnOrder.filter((el) => columnOrder.includes(el))
        props.setColumnOrder(newColumnOrderFiltered)
    }

    const isMobileView = window.innerWidth < 1024

    return (
        <header className={`live-header ${props.tableScroll || props.isScroll || isMobileView ? 'scroll-headaer' : 'live-header'}`}>
            <div className='header-left-area'>
                <div className='header-left-items header-logo'>
                    <div className='logo-container' onClick={ () => {window.location.href = '/top'}}></div>
                    <div className='side-menu-time'>
                        <span className='time-label'>{t('headerWithMenu.lastUpdate')}</span> <span className='time-value'>{ props.latestReceivedTime }</span>
                    </div> 
                </div>

                <div className='header-left-items'>
                {(!(props.tableScroll || props.isScroll) || isMobileView) && (
                    <WeatherApp weatherData={props.weatherData} precipitationForecast={props.precipitationForecast}/>
                )}
                    <div className='display-div'>
                        <div className='display-title'>Pit In</div>
                        <div className='display-value double-border'>{ tableData.length !== 0 ? tableData.filter(el => el.isPitIn == true).length : 0 }</div>
                    </div>
                    <div className='display-div'>
                        <div className='display-title'>{ isRace && raceSeries === 'SF' ? 'Laps to go' : 'Remaining Time'}</div>
                        <div className='display-value double-border'>{ remainingTimeAndLaps ? isRace && raceSeries === 'SF' ? remainingTimeAndLaps : <TimeFormatter seconds={remainingTimeAndLaps} decimalPlaces={0} /> :  '--' }</div>
                    </div>
                    <div className='menu-display'>
                        <div className='display-div'>
                            <div className='display-title'>Flag</div>
                            <div className='display-value flag'>
                                <div className={`flag-color ${flagInfo.color}`}></div>
                                <div className='flag-text'>{ flagInfo.text }</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { props.raceDetailId && (
                <>
                <div className='header-right-area'>
                    <div className='opciton-menu'>
                    <div className='option-radio-buttons'>
                        <div className='radio-button'>
                            <input type='radio' id='pcMode' name='deviceMode' value='PC' checked={pcMode} onChange={() => { setPcMode(true); setTabletMode(false); setMobileMode(false); props.setStyleSheet(false)}} />
                            <label htmlFor='pcMode'>PC</label>
                        </div>
                        <div className='radio-button'>
                            <input type='radio' id='tabletMode' name='deviceMode' value='Tablet' checked={tabletMode} onChange={() => { setTabletMode(true); setPcMode(false); setMobileMode(false); props.setStyleSheet(true)} } />
                            <label htmlFor='tabletMode'>Tablet</label>
                        </div>
                        <div className='radio-button'>
                            <input type='radio' id='mobileMode' name='deviceMode' value='Mobile' checked={mobileMode} onChange={() => { setMobileMode(true); setPcMode(false); setTabletMode(false); props.setStyleSheet(true)}} />
                            <label htmlFor='mobileMode'>Mobile</label>
                        </div>
                    </div>
                    <div className='light-mode-switch-Box'>
                        <div className='dark'><img src= { dark }></img></div>
                        <label className='switch light-mode-switch'>
                            <input 
                            type='checkbox' 
                            id='toggle' 
                            class='toggle-input'  
                            onChange={handleToggleChange} />
                            <span className='slider round'></span>
                        </label>
                        <div className='light'><img src= { light }></img></div>
                    </div>
                    {/* <input type='checkbox' id='toggle' class='toggle-input'  onChange={handleToggleChange} />
                    <label for='toggle' id='toggle-label' class='toggle-label'>Light Mode</label> */}
                    <button className='circle-button' title={t('headerWithMenu.downloadReport')} onClick={ reportViewHandler }><img src= { download } className='download-icon' alt={t('headerWithMenu.downloadReport')}/></button>
                    <button className='circle-button' title={t('general.variousSettings')} onClick={ settingViewHandler }><img src= { setting } alt={t('headerWithMenu.settings')}/></button>
                        <button className='ellipse-button' title={t('general.sessionList')} onClick={() => window.location.href = `/event/${props.eventId}`}>← Back</button>
                    </div>

                            <div className='hamburger-menu'>
                                <input type='checkbox' id='menu-btn-check'/>
                                <label for='menu-btn-check' className='menu-btn'><span className='menu-line'></span><span className='menu-text'>MENU</span></label>
                                <div className='menu-content'>
                                <input type='checkbox' id='toggle' class='toggle-input'  onChange={handleToggleChange} />
                                <label for='toggle' id='toggle-label' class='toggle-label'>Light Mode</label>
                                    <div className='hamburger-menu-item'>
                                        <div className='hamburger-display-switching'>
                                            <div className='display-switching-tit'>{t('general.displaySwitch')}</div>
                                            <div className='button-area'>
                                                <button className={ props.tableChartToggle === 'table' ?'display-switching-btn btn-on': 'display-switching-btn'}  value='table' onClick={tableChartMapHandler} title={t('general.table')}><img src=  { table }  alt={t('general.table')}/></button>
                                                <button className={ props.tableChartToggle === 'chart' ?'display-switching-btn btn-on': 'display-switching-btn'} value='chart' onClick={tableChartMapHandler} title={t('general.chart')}><img src= { chart } alt={t('general.chart')}/></button>
                                                <button className={ props.tableChartToggle === 'map' ?'display-switching-btn btn-on': 'display-switching-btn'} value='map' onClick={tableChartMapHandler}title={t('headerWithMenu.map')}><img src=  { map }  alt={t('headerWithMenu.map')}/></button>
                                                <button className={ props.tableChartToggle === 'management' ?'display-switching-btn btn-on': 'display-switching-btn'} value='management' onClick={tableChartMapHandler}title={t('headerWithMenu.management')}><img src=  { management }  alt={t('headerWithMenu.management')}/></button>
                                            </div>

                                            <div className='checkbox-area'>
                                            {isRace ? (
                                                <div className='checkbox-div'>
                                                    <label className='switch square' htmlFor='projectPosition-checkbox'>
                                                        <input type='checkbox' className='switch-input' onChange={handleProjectPosition} id='projectPosition-checkbox' checked={projectPositionMode}/>
                                                        <span className='slider square'></span>
                                                    </label>
                                                    <div>{t('headerWithMenu.backOrder')}</div>
                                                </div>
                                            ) : null}
                                                <div className={`checkbox-div ${followCarNumber ? 'disabled-false' : 'disabled-true'}`} onClick={() => {!props.pitStopTimeSettingStatus && settingViewHandler()} }>
                                                    <label className='switch square'  htmlFor='pitInPrediction-checkbox'>
                                                        <input type='checkbox' className='switch-input' onChange={pitInPrediction} id='pitInPrediction-checkbox' disabled={followCarNumber ? false : true} checked={predictPitInMode}/>
                                                        <span className='slider square'></span>
                                                    </label>
                                                    <div>{t('general.pitStopPrediction')}</div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className='hamburger-menu-item'>
                                        <button className='hamburger-menu-btn' onClick={ viewHandler } ref={viewHandlerButtonElement}>
                                            <div className='btn-box'>
                                                <img className='' src= { display } alt='表示変更'/>
                                                <span>{t('headerWithMenu.displaySettings')}</span>
                                            </div>
                                        </button>
                                        <button className='hamburger-menu-btn' onClick={ paceCatcherHandler }>
                                            <div className='btn-box'>
                                                <img className='' src= { prediction } alt='今後の予測'/>
                                                <span>{t('headerWithMenu.predictions')}</span>
                                            </div> 
                                        </button>
                                        <button className='hamburger-menu-btn' onClick={ allDriverLapsTableHandler }>
                                            <div className='btn-box'>
                                                <img className='' src= { lap } alt='ラップ表'/>
                                                <span>{t('headerWithMenu.lapChart')}</span>
                                            </div> 
                                        </button>
                                        <button className='hamburger-menu-btn' onClick={ stintHistoryHandler }> 
                                            <div className='btn-box'> 
                                                <img className='' src= { stint } alt='スティント履歴'/>
                                                <span>{t('headerWithMenu.stintHistory')}</span>
                                            </div> 
                                        </button>
                                    </div>
                                </div>

                                { props.changeViewMode && (
                                    <div className='top-view-mode-container' ref={changeViewElement}>
                                        <div className='view-mode-container-upper'>
                                            <div className='view-mode-div'>
                                                <div>テーブル設定</div>
                                                <DragDropContext onDragEnd={handleDragEnd}>
                                                    <TableContainer component={Paper} size='small' sx={{maxHeight: 500}}>
                                                        <Table sx={{minWidth: 250, color: '#fff' }} aria-label='customized table'>
                                                            <Droppable droppableId='columnOrder'>
                                                                {(provided) => (
                                                                    <TableBody
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        { renderViewTable('all') }
                                                                    {columns?.map((column, index) => {
                                                                        return (
                                                                            <Draggable key={column} draggableId={column} index={index}>
                                                                                {(provided, snapshot) => (
                                                                                    renderViewTable(column, provided, snapshot)
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })}
                                                                        {provided.placeholder}
                                                                    </TableBody>
                                                                )}
                                                            </Droppable>
                                                        </Table>
                                                    </TableContainer>
                                                </DragDropContext>
                                            </div>
                                            <div className='tablet-box'>
                                            { raceSeries === 'SE' && 
                                                <div className='view-mode-div'>
                                                    <div>Class選択</div>
                                                    <TableContainer component={Paper} size='small' sx={{height: 500, backgroundColor: isLightMode ? '#ffffff' : '#242424',}}>
                                                        <Table sx={{ minWidth: 250 }} aria-label='customized table'>
                                                            <TableBody >
                                                            <TableRow key='class-all-option' sx={{ backgroundColor: isLightMode ? '#ffffff' : '#242424', height: '30px'}}>
                                                                {console.log()}
                                                                <StyledTableCell className='allclass-display-header'>
                                                                    All
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    <label className='switch'>
                                                                        <input
                                                                            type='checkbox'
                                                                            className='switch-input'
                                                                            value='all'
                                                                            checked={props.carClasses.find(el => el.isVisible === false) ? false : true}
                                                                            onChange={props.handleCarClassCheckboxChange}
                                                                        />
                                                                        <span className='slider round'></span>
                                                                    </label>
                                                                </StyledTableCell>
                                                            </TableRow>
                                                            {props.carClasses?.map(column => (
                                                                column.class === '' ? (
                                                                    <React.Fragment></React.Fragment>
                                                                ) : (
                                                                    <TableRow key={column.class} sx={{ backgroundColor: isLightMode ? '#ffffff' : '#242424', height: '30px'}}>
                                                                        <StyledTableCell style={{ color: '#fff', fontWeight: '400', textAlign: 'left', paddingLeft: '12%' }}>
                                                                            {ClassColorDisplay(column.class, 'header')}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell>
                                                                            <label className='switch'>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    className='switch-input'
                                                                                    value={column.class}
                                                                                    checked={column.isVisible}
                                                                                    onChange={props.handleCarClassCheckboxChange}
                                                                                />
                                                                                <span className='slider round'></span>
                                                                            </label>
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                )
                                                            ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            }
                                            <div className='view-mode-div'>
                                                <div>表示設定</div>
                                                <TableContainer component={Paper} size='small' sx={{height: 500, backgroundColor: isLightMode ? '#ffffff' : '#242424',}}>
                                                    <Table sx={{ minWidth: 250 }} aria-label='customized table'>
                                                        <TableBody>
                                                            {renderViewTable('rivalHeaders')}
                                                            {renderViewTable('map')}
                                                            {renderViewTable('mapLeaders')}
                                                            {renderViewTable('markedCars')}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                            </div>
                                        </div>
                                        <div className='view-mode-bottom'>
                                            <button onClick={ viewHandler } className='change-view-close-button'>
                                                閉じる
                                            </button>
                                        </div>
                                    </div>
                                )}                               
                            </div>
                    
                            <div className='menu-buttons'>
                                <div className='header-btn-area display-switch-btn menu-button-items'>
                                    <button className='square-btn' onClick={ viewHandler } ref={viewHandlerButtonElement}>
                                        <img className='square-icon' src= { display } alt={t('headerWithMenu.displaySettings')}/>
                                        <span>{t('headerWithMenu.displaySettings')}</span>
                                    </button>
                                    <button className='square-btn' onClick={ paceCatcherHandler }>
                                        <img className='square-icon' src= { prediction } alt={t('headerWithMenu.predictions')}/>
                                        <span>{t('headerWithMenu.predictions')}</span>
                                    </button>
                                    <button className='square-btn' onClick={ allDriverLapsTableHandler }>
                                        <img className='square-icon' src= { lap } alt={t('headerWithMenu.lapChart')}/>
                                        <span>{t('headerWithMenu.lapChart')}</span>
                                    </button>
                                    <button className='square-btn' onClick={ stintHistoryHandler }>   
                                        <img className='square-icon' src= { stint } alt={t('headerWithMenu.stintHistory')}/>
                                        <span>{t('headerWithMenu.stintHistory')}</span>
                                    </button>
                                </div>


                                { props.changeViewMode && (
                                    <div className='top-view-mode-container' ref={changeViewElement}>
                                        <div className='view-mode-container-upper'>
                                            <div className='view-mode-div'>
                                                <div>{t('headerWithMenu.tableSettings')}</div>
                                                <DragDropContext onDragEnd={handleDragEnd}>
                                                    <TableContainer component={Paper} size='small' sx={{maxHeight: 500}}>
                                                        <Table sx={{minWidth: 250, color: '#fff' }} aria-label='customized table'>
                                                            <Droppable droppableId='columnOrder'>
                                                                {(provided) => (
                                                                    <TableBody
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        { renderViewTable('all') }
                                                                    {columns?.map((column, index) => {
                                                                        return (
                                                                            <Draggable key={column} draggableId={column} index={index}>
                                                                                {(provided, snapshot) => (
                                                                                    renderViewTable(column, provided, snapshot)
                                                                                )}
                                                                            </Draggable>
                                                                        )
                                                                    })}
                                                                        {provided.placeholder}
                                                                    </TableBody>
                                                                )}
                                                            </Droppable>
                                                        </Table>
                                                    </TableContainer>
                                                </DragDropContext>
                                            </div>
                                            { raceSeries === 'SE' && 
                                                <div className='view-mode-div'>
                                                    <div>{t('headerWithMenu.classSelect')}</div>
                                                    <TableContainer component={Paper} size='small' sx={{height: 500, backgroundColor: isLightMode ? '#ffffff' : '#242424',}}>
                                                        <Table sx={{ minWidth: 250 }} aria-label='customized table'>
                                                            <TableBody >
                                                            <TableRow key='class-all-option' sx={{ backgroundColor: isLightMode ? '#ffffff' : '#242424', height: '30px'}}>
                                                                <StyledTableCell className='allclass-display-header'>
                                                                    All
                                                                </StyledTableCell>
                                                                <StyledTableCell>
                                                                    <label className='switch'>
                                                                        <input
                                                                            type='checkbox'
                                                                            className='switch-input'
                                                                            value='all'
                                                                            checked={props.carClasses.find(el => el.isVisible === false) ? false : true}
                                                                            onChange={props.handleCarClassCheckboxChange}
                                                                        />
                                                                        <span className='slider round'></span>
                                                                    </label>
                                                                </StyledTableCell>
                                                            </TableRow>
                                                            {props.carClasses?.map(column => (
                                                                column.class === '' ? (
                                                                    <React.Fragment></React.Fragment>
                                                                ) : (
                                                                    <TableRow key={column.class} sx={{ backgroundColor: isLightMode ? '#ffffff' : '#242424', height: '30px'}}>
                                                                        <StyledTableCell style={{ color: '#fff', fontWeight: '400', textAlign: 'left', paddingLeft: '12%' }}>
                                                                            {ClassColorDisplay(column.class, 'header')}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell>
                                                                            <label className='switch'>
                                                                                <input
                                                                                    type='checkbox'
                                                                                    className='switch-input'
                                                                                    value={column.class}
                                                                                    checked={column.isVisible}
                                                                                    onChange={props.handleCarClassCheckboxChange}
                                                                                />
                                                                                <span className='slider round'></span>
                                                                            </label>
                                                                        </StyledTableCell>
                                                                    </TableRow>
                                                                )
                                                            ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            }
                                            <div className='view-mode-div'>
                                                <div>{t('headerWithMenu.displaySettings')}</div>
                                                <TableContainer component={Paper} size='small' sx={{height: 500, backgroundColor:isLightMode ? '#ffffff' : '#242424',}}>
                                                    <Table sx={{ minWidth: 250 }} aria-label='customized table'>
                                                        <TableBody>
                                                            {renderViewTable('rivalHeaders')}
                                                            {renderViewTable('map')}
                                                            {renderViewTable('mapLeaders')}
                                                            {renderViewTable('mapGroups')}
                                                            {renderViewTable('markedCars')}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                        <div className='view-mode-bottom'>
                                            <button onClick={ viewHandler } className='change-view-close-button'>
                                                {t('general.close')}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        <div className='menu-checkbox'>
                            <div className='menu-checkbox-left'>
                                {isRace ? (
                                    <div className='checkbox-div'>
                                        <label className='switch square'  htmlFor='projectPosition-checkbox'>
                                            <input
                                                type='checkbox'
                                                className='switch-input'
                                                onChange={handleProjectPosition}
                                                id='projectPosition-checkbox'
                                                checked={projectPositionMode}
                                            />
                                            <span className='slider square'></span>
                                        </label>
                                        <div>{t('headerWithMenu.backOrder')}</div>
                                    </div>
                                ) : null}
                                
                                <div
                                    className={`checkbox-div ${followCarNumber ? 'disabled-false' : 'disabled-true'}`}
                                >
                                    <label className='switch square'  htmlFor='pitInPrediction-checkbox'>
                                        <input
                                            type='checkbox'
                                            className='switch-input'
                                            onChange={pitInPrediction}
                                            id='pitInPrediction-checkbox'
                                            disabled={followCarNumber ? false : true}
                                            checked={predictPitInMode}
                                        />
                                        <span className='slider square'></span>
                                    </label>
                                    <div>{t('general.pitStopPrediction')}</div>
                                </div>
                            </div>


                            <div className='display-switching-area'>
                                    <span className=''>{t('general.displaySwitch')}</span>
                                    <div className='display-switching-btns'>
                                        {/* <button className={ props.tableChartToggle === 'table' ?'display-switching-btn': 'display-switching-btn btn-off'}  value='table' onClick={tableChartMapHandler}><img src= { tableOn } alt={t('general.table')}/></button>
                                        <button className='display-switching-btn' value='chart' onClick={tableChartMapHandler}>{ props.tableChartToggle === 'chart' ?<img src= { chartOn } alt={t('general.chart')}/>: <img src= { chartOff } alt={t('general.chart')}/> }</button>
                                        <button className='display-switching-btn' value='map' onClick={tableChartMapHandler}>{ props.tableChartToggle === 'map'   ?<img src=  { mapOn }  alt={t('headerWithMenu.map')}/>  : <img src=  { mapOff }  alt={t('headerWithMenu.map')}/> }</button> */}

                                        <button className={ props.tableChartToggle === 'table' ?'display-switching-btn btn-on': 'display-switching-btn'}  value='table' onClick={tableChartMapHandler} title={t('general.table')}><img src=  { table }  alt={t('general.table')}/></button>
                                        <button className={ props.tableChartToggle === 'chart' ?'display-switching-btn btn-on': 'display-switching-btn'} value='chart' onClick={tableChartMapHandler} title={t('general.chart')}><img src= { chart } alt={t('general.chart')}/></button>
                                        <button className={ props.tableChartToggle === 'map' ?'display-switching-btn btn-on': 'display-switching-btn'} value='map' onClick={tableChartMapHandler}title={t('headerWithMenu.map')}><img src=  { map }  alt={t('headerWithMenu.map')}/></button>
                                        <button className={ props.tableChartToggle === 'management' ?'display-switching-btn btn-on': 'display-switching-btn'} value='management' onClick={tableChartMapHandler}title={t('headerWithMenu.management')}><img src=  { management }  alt={t('headerWithMenu.management')}/></button>                                  </div>
                                </div>
                        </div>
                </div>
                </>
            )}
        </header>
    )
}



export default HeaderWithMenu