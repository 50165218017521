import { useEffect, useState} from 'react'
import Header from '../components/Header'
import SimulationCircuitTable from '../components/SimulationCircuitTable'
import SimulationCircuitInformation from '../components/SimulationCircuitInformation'
import SimulationStrategyInfomation from '../components/SimulationStrategyInfomation'
import SimulationOption from '../components/SimulationOption'
import SimulationResultGraph from '../components/SimulationResultGraph'

import { useTranslation } from 'react-i18next'

const Simulation = () => {
    const { t } = useTranslation(['strategy', 'general'])
    
    const [ strategyList, setStrategyList ] = useState([])
    const [ circuitTableChanged, setCircuitTableChanged ] = useState(0)
    const [ resultGraphData, setResultGraphData ] = useState({})
    const [ estimatedTyreDegradation, setEstimatedTyreDegradation ] = useState({})
    const [ strategyCircuitList, setStrategyCircuitList ] = useState([])
    const [ raceStrategyList, setRaceStrategyList ] = useState()
    const [ circuitRaceDistance, setCircuitRaceDistance ] = useState({
        1: 31,
        2: 51,
        3: 41,
        4: 41,
        5: 37,
        6: 100,
        7: 0
    })

    useEffect(() => {
        const userid = localStorage.getItem('userId')
        getAllRaceStrategy(userid)
            .then(json => {
                setRaceStrategyList(json)
            })
            .catch(error => {
                alert(t('pleaseContactAdmin', {ns: 'general'}))
            })
    }, [])

    const onInputChangeHandler = (value) => {
        setCircuitTableChanged(value)
    }

    return (
        <>
            <Header />
            <SimulationCircuitInformation
                setStrategyCircuitList={setStrategyCircuitList}
                setCircuitRaceDistance={setCircuitRaceDistance}
                circuitRaceDistance={circuitRaceDistance}
            />
            <br></br>
            <SimulationStrategyInfomation
                setStrategyList={setStrategyList}
            />
            <br></br>
            <SimulationCircuitTable
                strategyCircuitList={strategyCircuitList}
                changedCounter={circuitTableChanged}
                onInputChange={onInputChangeHandler}
                setEstimatedTyreDegradation={setEstimatedTyreDegradation}
                raceStrategyList={raceStrategyList}
            />
            <br></br>
            
            <div className='options-container'>
                {[1, 2, 3, 4].map((index) => (
                    <SimulationOption
                        key={index}
                        option={index.toString()}
                        strategyList={strategyList}
                        pitStopLap={index % 2 === 0 ? 20 : 10}
                        pitStopLapTb2={45}
                        circuitTableChanged={circuitTableChanged}
                        setResultGraphData={setResultGraphData}
                        raceStrategyList={raceStrategyList}
                        circuitRaceDistance={circuitRaceDistance}
                    />
                ))}
            </div>
            <br></br>
            <SimulationResultGraph
                resultGraphData={resultGraphData}
                estimatedTyreDegradation={estimatedTyreDegradation}
            />
        </>
    )

}

export default Simulation

function getAllRaceStrategy(userid) {
    return new Promise ((resolve, reject) => {
        fetch(`/api/raceStrategy/findByUserId/${userid}`)
            .then(res => res.json())
            .then(json => resolve(json.data))
            .catch(error => {
                reject(error)
            })
    })
}