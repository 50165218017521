class WebSocketService {
    constructor() {
        this.socket = null
        this.messageHandler = null
    }

    connect() {
        const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:8001';
        this.socket = new WebSocket(websocketUrl)


        this.socket.onopen = () => {
            console.log('Connected to WebSocket server')
        }

        this.socket.onmessage = (event) => {
            if (this.messageHandler) {
                this.messageHandler(event.data)
            }
        }

        this.socket.onclose = () => {
            console.log('Disconnected from WebSocket server')
        }
    }

    send(message) {
        if (this.socket) {
            this.socket.send(message)
        }
    }

    close() {
        if (this.socket) {
            this.socket.close()
        }
    }

    onMessage(handler) {
        this.messageHandler = handler
    }
}

const webSocketService = new WebSocketService()
export default webSocketService