import { InferenceSession } from 'onnxruntime-web'
import * as dfd from 'danfojs'

import transRange from './transRange'
import transArray from './transArray'
import makeBaseONNXobject from './makeBaseONNXobject'
import makeScatONNXobject from './makeScatONNXobject'
import makeInputdf from './makeInputdf'
import makeInputKPI from './makeInputKPI'
import getOnnxFiles from './getOnnxFiles'
import GetFile from './getFile'
import updateProgress from './updateProgress'

const optimizeLocal = async (vehicleId, trackId, df_DOEcondition, Obj_circuit_map, Obj_chart_combination, df_baseSetup, DOEnum, Obj_parameter_combination, vehicleData) => {
    const VEHICLE_ID = vehicleId
    const TRACK_ID = trackId

    let onnxFile = await getOnnxFiles(VEHICLE_ID, TRACK_ID)

    let Obj_base_result = {}
    let Obj_scat_result = {}

    let df_baseTime
    let df_scatTime

    let scat_chart_list = []
    for (let i=0; i<Object.keys(Obj_chart_combination).length-1; i++){
        for(let j=0; j<3; j++){
            scat_chart_list.push(Obj_chart_combination[Object.keys(Obj_chart_combination)[i]][j].name)
        }
    }

    const Obj_baseSetup = makeBaseONNXobject(df_DOEcondition, df_baseSetup)
    const Obj_scatSetup = makeScatONNXobject(df_DOEcondition, df_baseSetup, DOEnum, Obj_parameter_combination)

    let df_baseInput = makeInputdf(Obj_baseSetup, df_DOEcondition)
    let df_scatInput = makeInputdf(Obj_scatSetup, df_DOEcondition)

    const df_baseKPI = makeInputKPI(Obj_baseSetup, df_DOEcondition, vehicleData)
    const df_scatKPI = makeInputKPI(Obj_scatSetup, df_DOEcondition, vehicleData)

    df_baseInput = dfd.concat({ dfList: [df_baseInput, df_baseKPI], axis: 1 })
    df_scatInput = dfd.concat({ dfList: [df_scatInput, df_scatKPI], axis: 1 })
    
    let labelBase = df_baseSetup.iloc({'columns':['1:']}).columns
    await addColumns()

    return {
        Obj_baseSetup, Obj_scatSetup,
        df_baseInput, df_scatInput,
        Obj_base_result, Obj_scat_result,
        df_baseTime, df_scatTime,
        df_baseKPI, df_scatKPI,
        scat_chart_list
    }

    function addColumns() {
        return new Promise (async (resolve, reject) => {
            for (let i = 0; i < onnxFile.length; i++) {
                let modelname = onnxFile[i].split('.')[0]
                for (let j = 0; j < Obj_circuit_map.length; j++){
                    if (modelname.indexOf(Obj_circuit_map[j].Sim_Parameter) !== -1){
                        modelname = modelname.replace(Obj_circuit_map[j].Sim_Parameter, Obj_circuit_map[j].UI_Parameter+'_')
                    }
                }

                if(i===0){
                    
                    let arrayBase = {'id': transRange(labelBase.length)}
                    let arrayScat = { 'id': transRange(DOEnum) }

                    for(let j=0;j<scat_chart_list.length;j++){
                        Obj_base_result[scat_chart_list[j]] = new dfd.DataFrame(arrayBase)
                        Obj_scat_result[scat_chart_list[j]] = new dfd.DataFrame(arrayScat)
                    }

                    df_baseTime = new dfd.DataFrame(arrayBase)
                    df_scatTime = new dfd.DataFrame(arrayScat)
                }

                for(let j=0;j<scat_chart_list.length;j++){
                    if (onnxFile[i].includes(scat_chart_list[j])) {
                        let modelname = onnxFile[i].split('.')[0]
                        for (let j = 0; j < Obj_circuit_map.length; j++){
                            if (modelname.indexOf(Obj_circuit_map[j].Sim_Parameter) !== -1){
                                modelname = modelname.replace(Obj_circuit_map[j].Sim_Parameter, Obj_circuit_map[j].UI_Parameter+'_')
                            }
                        }
                        const arrayBuffer = await GetFile(`/data/ONNX/ONNX_${VEHICLE_ID}_${TRACK_ID}/${onnxFile[i]}`, 'buffer')
                        let myOnnxSession = await InferenceSession.create(arrayBuffer)

                        let ansBase = await myOnnxSession.run(Obj_baseSetup)
                        ansBase = transArray(ansBase.variable.data)
                
                        let ansScat = await myOnnxSession.run(Obj_scatSetup)
                        ansScat = transArray(ansScat.variable.data)

                        if(onnxFile[i].includes(Obj_chart_combination['Balance'][2].name)){
                            ansBase = ansBase.map(m => (100-m))
                            ansScat = ansScat.map(m => (100-m))
                        }

                        Obj_base_result[scat_chart_list[j]].addColumn(modelname, ansBase, {inplace: true})
                        Obj_scat_result[scat_chart_list[j]].addColumn(modelname, ansScat, {inplace: true})
                    }
                }
                
                if (onnxFile[i].includes('Sector')) {

                    let modelname = onnxFile[i].split('.')[0]
                    for (let j = 0; j < Obj_circuit_map.length; j++){
                        if (modelname.indexOf(Obj_circuit_map[j].Sim_Parameter) !== -1){
                            modelname = modelname.replace(Obj_circuit_map[j].Sim_Parameter, Obj_circuit_map[j].UI_Parameter+'_')
                        }
                    }

                    const arrayBuffer = await GetFile(`/data/ONNX/ONNX_${VEHICLE_ID}_${TRACK_ID}/${onnxFile[i]}`, 'buffer')
                    let myOnnxSession = await InferenceSession.create(arrayBuffer)

                    let ansBase = await myOnnxSession.run(Obj_baseSetup)
                    ansBase = transArray(ansBase.variable.data)
            
                    let ansScat = await myOnnxSession.run(Obj_scatSetup)
                    ansScat = transArray(ansScat.variable.data)

                    df_baseTime.addColumn(modelname, ansBase, {inplace:true})
                    df_scatTime.addColumn(modelname, ansScat, {inplace:true})
                }

                updateProgress(90/onnxFile.length * i + 5)
            }

            df_baseTime.addColumn('LapTime', df_baseTime.iloc({columns: ["1:"]}).sum().values, {inplace: true})
            df_scatTime.addColumn('LapTime', df_scatTime.iloc({columns: ["1:"]}).sum().values, {inplace: true})

            // Add Vmax value to df_baseTime, df_scatTime
            let modelname = 'v Max'

            const arrayBuffer = await GetFile(`/data/ONNX/ONNX_${VEHICLE_ID}_${TRACK_ID}/${modelname}.onnx`, true)
            let myOnnxSession = await InferenceSession.create(arrayBuffer)

            let ansBase = await myOnnxSession.run(Obj_baseSetup)
            ansBase = transArray(ansBase.variable.data)
    
            let ansScat = await myOnnxSession.run(Obj_scatSetup)
            ansScat = transArray(ansScat.variable.data)

            df_baseTime.addColumn(modelname, ansBase, {inplace:true})
            df_scatTime.addColumn(modelname, ansScat, {inplace:true})

            resolve()
        })
    }
}

export default optimizeLocal