import React from 'react'

const colorCode = [
    '#FABED4',
    '#E6194B',
    '#E619B9',
    '#CA32F0',
    '#C59BD9',
    '#FFFAC8',
    '#FFE119',
    '#F58231',
    '#E57E7E',
    '#CE4646',
    '#AAFFC3',
    '#BFEF45',
    '#808000',
    '#318000',
    '#ADC3A2',
    '#42D4F4',
    '#7FB8D7',
    '#4363D8',
    '#AAAAE8',
    '#B6E5FF',
    '#CECECE',
    '#B3B3B3',
    '#807B7B',
    '#FFC397',
    '#FFEED5'
]

const classColorCode = {
    'ST-1': '#f39800',
    'ST-2': '#92308d',
    'ST-3': '#0081cc',
    'ST-4': '#e60012',
    'ST-5': '#008b4f',
    'ST-Q': '#808080',
    'ST-TCR': '#fadce9',
    'ST-X': '#ffea00',
    'ST-Z': '#d3edfb',
}

let groupColor = []

const CarObject = ({drivers, id, carNumberTeamColorObj, followCarNumber, rivalCarNumbers, mapLeaders, mapDisplayLeaders, battleData, carClasses, mapSize, displayMapGroups, currentSectorLocation}) => {
    const classLeaders = mapLeaders?.classLeaders
    const allBattleCars = battleData?.flat().flat()  
    const visibleCars = carClasses?.filter((carClass) => carClass.isVisible).map((carClass) => carClass.cars).flat()

    const determineRadius = (carNo) => {
        return carNo === followCarNumber 
        ? '43' 
        : rivalCarNumbers?.includes(carNo) 
            ? '40' 
            : mapLeaders !== undefined && carNo === mapLeaders.race?.carno.split('.')[1] && mapDisplayLeaders 
                ? '43' 
                : '36'
    }

    const hexToRgb = (hex) => {
        if (undefined === hex) return 'rgb(255, 255, 255)'
        hex = hex.replace('#', '')
    
        const r = parseInt(hex.substring(0, 2), 16)
        const g = parseInt(hex.substring(2, 4), 16)
        const b = parseInt(hex.substring(4, 6), 16)
    
        return `rgb(${r}, ${g}, ${b})`
    }

    const groupColorCoordinator = (carNo, group) => {
        for (let i = 0; i < battleData.length; i++) {
            if (battleData[i].includes(Number(carNo))) {
                groupColor = colorCode[i]
                if (document.getElementById(`circle${carNo}_${id}`)) {
                    const currentFillColor = window.getComputedStyle(document.getElementById(`circle${carNo}_${id}`)).fill
                    if (currentFillColor !== hexToRgb(groupColor)) {
                        groupColor = colorCode[(i + 1) % colorCode.length]
                    }
                }
                break
            }
        }

        return groupColor
    }

    const getFillColor = (carNo, defaultColor) => {
        if (carNumberTeamColorObj[parseInt(carNo)]) {
            const teamColorObj = carNumberTeamColorObj[parseInt(carNo)]
            return teamColorObj.teamBgColor ? teamColorObj.teamBgColor : defaultColor
        } else {
            return defaultColor
        }
    }

    const determineBattleGroupLeaderOrLast = (carNo, group) => {
        let battleGroupLeaderOrLast = false
    
        const firstCarNumber = group[0]
        const lastCarNumber = group[group.length - 1]
        
        if (firstCarNumber === Number(carNo) || (lastCarNumber === Number(carNo) && group.length > 2)) {
            battleGroupLeaderOrLast = true
        }
        
        return battleGroupLeaderOrLast
    }

    const checkGapBattle = (carNo) => {
        let isGapBattle = false
        const withoutG = carNo.split('G')[0]
        const group = battleData.find((group) => group.includes(Number(withoutG)))
        
        if (!group) return isGapBattle
    
        const firstCar = currentSectorLocation[withoutG]
        const lastCar = currentSectorLocation[group[group.length - 1]]
        const currentG = currentSectorLocation[carNo]
    
        if (!currentG || currentG.PROGRESS === 0 || currentG.PROGRESS === 1 || currentG.PROGRESS === undefined ||
            document.getElementById(`carpulse${group[group.length - 1]}_${id}`) === null) {
            return isGapBattle
        }
        
        if (group[0] === Number(withoutG)) {
            const firstCarSector = firstCar?.SECTOR
            const lastCarSector = lastCar?.SECTOR
            const currentGProgress = currentG.PROGRESS
            const firstCarProgress = firstCar?.PROGRESS
            const lastCarProgress = lastCar?.PROGRESS
    
            if (!firstCarSector || !lastCarSector || !firstCarProgress || !lastCarProgress) {
                return isGapBattle
            }
    
            isGapBattle = true
    
            if (currentGProgress - lastCarProgress < 0.05) {
                isGapBattle = false
            }

            if (currentGProgress > .95) {
                isGapBattle = false
            }
        }
    
        return isGapBattle
    }    

    const movingSVG = (battle, carNo, id, type, gaps, radius, fillColor, isGapBattle) => {
        let groupColor = '#33b8a9'

        if (type === 'carpulse') {
            const currentCarCircle = document.getElementById(`circle${carNo}_${id}`) || document.getElementById(`gap${carNo}_${id}`)
            const currentFillColor = currentCarCircle ? window.getComputedStyle(currentCarCircle).fill : null
            if (currentFillColor !== hexToRgb(groupColor)) {
                if (carNo.includes('G')) {
                    groupColor = groupColorCoordinator(Number(carNo.split('G')[0]), battleData.find((group) => group.includes(Number(carNo))))
                } else {
                    groupColor = groupColorCoordinator(carNo, battleData.find((group) => group.includes(Number(carNo))))
                }
            }
        }

        return (
            <g 
                style={{opacity: 1}} 
                className={`car_${id}`} 
                id={`${type}${carNo}_${id}${gaps}`} 
            >
                <defs>
                    <filter id='f1' x='0' y='0' width='150%' height='150%'>
                        <feOffset result='offOut' in='SourceAlpha' dx='5' dy='5'/>
                        <feGaussianBlur result='blurOut' in='offOut' stdDeviation='3'/>
                        <feBlend in='SourceGraphic' in2='blurOut' mode='normal'/>
                    </filter>
                </defs>

                {
                    type === 'car' ?
                        <circle
                            id={`circle${carNo}_${id}`} 
                            r={radius * (mapSize === 'fullScreen' ? 0.7 : 1)} 
                            display={battle ? 'none' : 'block'}
                            fill={
                                classLeaders !== undefined && classLeaders.length > 1 && mapDisplayLeaders 
                                ? (() => {
                                    const classLeader = classLeaders.find((leader) => leader.carno === carNo)
                                    return classLeader ? classColorCode[classLeader.class] : fillColor
                                    })()
                                : 
                                fillColor
                            }
                            style={{filter: 'url(#f1)'}}
                            stroke={carNo === followCarNumber ? 'red' : rivalCarNumbers?.includes(carNo) ? 'gold' : 'black'}
                            strokeWidth={carNo === followCarNumber ? '8' : rivalCarNumbers?.includes(carNo) ? '5' : '2'}
                        />
                    :

                        gaps === 'G' ?

                            <circle
                                id={`gap${carNo}_${id}`}
                                r={radius * (mapSize === 'fullScreen' ? .25 : .35)} 
                                fill={groupColor}
                                display={isGapBattle ? 'block' : 'none'}
                                style={{ 
                                    filter: 'url(#f1)',
                                    animation: 'pulseColor 2s infinite',
                                }}
                                stroke={'black'}
                                strokeWidth={'3'}
                            />

                        :

                            <circle
                                id={`circle${carNo}_${id}`} 
                                r={radius * (mapSize === 'fullScreen' ? 0.8 : 1)} 
                                fill={groupColor}
                                style={{ 
                                    filter: 'url(#f1)',
                                    animation: 'pulseColor 2s infinite',
                                }}
                                stroke={carNo === followCarNumber ? 'red' : rivalCarNumbers.includes(carNo) ? 'gold' : 'black'}
                                strokeWidth={carNo === followCarNumber ? '8' : rivalCarNumbers.includes(carNo) ? '5' : '3'}
                            />

                }

                {type === 'carpulse' &&
                    <style>
                        {`
                            @keyframes pulseColor {
                                0% {
                                    stroke: black; 
                                }
                                50% {
                                    stroke: rgb(75, 255, 231); 
                                }
                                100% {
                                    stroke: black; 
                                }
                            }
                        `}
                    </style>
                }

                {type === 'car' ? 
                    <text 
                        id={`text${carNo}_${id}`} 
                        display={battle ? 'none' : 'block'}
                        textAnchor='middle' 
                        dominantBaseline='central' 
                        className='text'
                        style={{
                            fill: carNumberTeamColorObj[parseInt(carNo)] ? carNumberTeamColorObj[parseInt(carNo)].teamTextColor ? carNumberTeamColorObj[parseInt(carNo)].teamTextColor : '#000' : '#000',
                            fontFamily: 'Arial, sans-serif',
                            fontSize: mapSize === 'fullScreen' ? '1.7em' : '2.8em',
                            fontWeight: 'bold',
                            pointerEvents: 'none',
                            stroke: 'none'
                        }}
                    >
                        {carNo}
                    </text>

                    : gaps !== 'G' ?
                        <text 
                            id={`text${carNo}_${id}`} 
                            textAnchor='middle' 
                            dominantBaseline='central' 
                            className='text'
                            style={{
                                fill: carNumberTeamColorObj[parseInt(carNo)] ? carNumberTeamColorObj[parseInt(carNo)].teamTextColor ? carNumberTeamColorObj[parseInt(carNo)].teamTextColor : '#000' : '#000',
                                fontFamily: 'Arial, sans-serif',
                                fontSize: mapSize === 'fullScreen' ? '1.9em' : '2.8em',
                                fontWeight: 'bold',
                                pointerEvents: 'none',
                                stroke: 'none',
                            }}
                        >
                            {carNo}
                        </text>
                    : ''
                }
            </g>
        )
    }

    return (
        <>
            {
                drivers && carNumberTeamColorObj ? (
                    Object.values(drivers).map((data, index) => {
                        let battle = false
                        let battleGroupLeaderOrLast = false
                        let isGapBattle = false

                        const carNo = Object.keys(drivers)[index]
                        const includesG = /G/.test(carNo)

                        if (!visibleCars?.includes(Number(carNo)) && !includesG) return null

                        const color = colorCode[ index % colorCode.length ]
                        const radius = determineRadius(carNo)
                        const fillColor = getFillColor(carNo, color)

                        if ((allBattleCars && allBattleCars.includes(Number(carNo)) || includesG) && displayMapGroups) {
                            if (followCarNumber !== carNo && !rivalCarNumbers.includes(carNo)) {
                                battle = true
                                battleData.forEach((group, index) => {
                                    if (group.includes(Number(carNo))) {
                                        battleGroupLeaderOrLast = determineBattleGroupLeaderOrLast(carNo, group, currentSectorLocation)
                                    }
                                    if (includesG && group.length > 2) {
                                        isGapBattle = checkGapBattle(carNo)
                                    }
                                })
                            }
                        }                      

                        return (
                            <React.Fragment key={`car${carNo}_${id}`}>
                                {battleGroupLeaderOrLast ?
                                    movingSVG(battle, carNo, id, 'carpulse', '', radius, fillColor, isGapBattle)
                                    : includesG ?
                                        movingSVG(battle, carNo, id, 'carpulse', 'G', radius, fillColor, isGapBattle)
                                        :
                                        movingSVG(battle, carNo, id, 'car', '', radius, fillColor, isGapBattle)
                                }
                            </React.Fragment>
                        )
                    })
                )
                : <></>
            }
        </>
    )
}

export default CarObject