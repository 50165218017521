import React, { useEffect, useState } from 'react'

import { Bar } from 'react-chartjs-2'

import GetFile from '../services/getFile'

const TimeSeriesChartBar = (props) => {
    const initialChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    color: '#555',
                    borderColor: '#ccc'
                }
            },
            y: {
                grid: {
                    color: '#555',
                    borderColor: '#ccc'
                }
            }
        }
    }

    const [ chartData, setChartData ] = useState({
        labels: [],
        datasets: [{
            label: '',
            data: [],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(0, 0, 0, 0)',
        }]
    })
    const [ chartOptions, setChartOptions ] = useState(initialChartOptions)

    const timeSeriesList = props.timeSeriesList
    const graphData = props.graphData
    const plotData = graphData.plotData

    const vehicleId = props.vehicleId
    const trackId = props.trackId

    const groupByLapCorner = async () => {
        if (Object.keys(plotData).length === 0) return

        const circuitMapFile = `/data/circuit_map/circuit_map_${vehicleId}_${trackId}.json`
        const circuitMap = await GetFile(circuitMapFile)

        const timeSeriesListWithGroupedLapCorner = {}
        timeSeriesList.forEach((timeSeries, iTimeSeries) => {
            const { fileName } = timeSeries
            const timeSeriesData = Object.keys(plotData).map(channel => {
                return { ...plotData[channel][iTimeSeries], channelName: channel }
            })

            // TODO: change if multiple lap, 0 if single lap
            const dataToGroup = { 0: timeSeriesData }

            if (!timeSeriesListWithGroupedLapCorner[fileName]) {
                timeSeriesListWithGroupedLapCorner[fileName] = []
            }

            for (const [lapNumber, lapTimeSeriesData] of Object.entries(dataToGroup)) {
                if (!timeSeriesListWithGroupedLapCorner[fileName][lapNumber]) {
                    timeSeriesListWithGroupedLapCorner[fileName][lapNumber] = {}
                }

                const xAxisData = lapTimeSeriesData.find(d => d.axis === 'x')
                const distance = xAxisData.data.map(x => { return xAxisData.data[1] < 1 ? (x - xAxisData.data[0]) * 1000 : x - xAxisData.data[0] })
                const lapTimeSeriesWithoutXAxis = lapTimeSeriesData.filter(d => d.axis !== 'x')

                circuitMap.forEach(cm => {
                    const cornerName = cm.UI_Parameter
                    const startPoint = cm.Start_Point
                    const endPoint = cm.End_Point

                    if (!timeSeriesListWithGroupedLapCorner[fileName][lapNumber][cornerName]) {
                        const plotData = lapTimeSeriesWithoutXAxis.reduce((accPlotData, channelData) => { 
                            accPlotData[channelData.channelName] = []
                            return accPlotData
                         }, {})

                        timeSeriesListWithGroupedLapCorner[fileName][lapNumber][cornerName] = {
                            plotData,
                            startPoint,
                            endPoint
                        }
                    }

                    distance.forEach((d, dIndex) => {
                        if (d >= startPoint && d <= endPoint) {
                            lapTimeSeriesWithoutXAxis.forEach(lts => {
                                timeSeriesListWithGroupedLapCorner[fileName][lapNumber][cornerName].plotData[lts.channelName].push(lts.data[dIndex])
                            })
                        }
                    })
                })
            }
        })

        const { graphDataLabels, graphDataToPlot} = processDataToPlot(timeSeriesListWithGroupedLapCorner)

        const datasets = graphDataToPlot.map((data, i) => {
            return {
                label: data.label,
                data: data.plotData,
                backgroundColor: data.color
            }
        })

        setChartData({
            labels: graphDataLabels,
            datasets: datasets.length > 0 ? datasets : [{
                label: '',
                data: [],
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(0, 0, 0, 0)',
            }]
        })
    }

    const processDataToPlot = (timeSeriesListWithGroupedLapCorner) => {
        const graphDataToPlot = []
        const graphDataLabels = []

        for (const [fileName, lapData] of Object.entries(timeSeriesListWithGroupedLapCorner)) {
            const sameTimeSeries = timeSeriesList.find(ts => ts.fileName === fileName)
            if (!sameTimeSeries.visible) return

            const color = sameTimeSeries.color

            const singleTimeSeriesData = {
                label: fileName,
                plotData:[],
                color
            }

            lapData.forEach((ld, ldIndex) => {
                for (const [cornerName, cornerData] of Object.entries(ld)) {
                    const plotData = cornerData.plotData
                    for (const [channelName, channelData] of Object.entries(plotData)) {
                        if (ldIndex === 0 && fileName === timeSeriesList[0].fileName) graphDataLabels.push(`${cornerName}_${channelName}`)

                        const channelFound = graphData.channels.find(c => c.y === channelName)
                        const selectedOption = channelFound.selectedOption

                        let value
                        if (selectedOption === 'min') {
                            value = Math.min(...channelData)
                        } else if (selectedOption === 'max') {
                            value = Math.max(...channelData)
                        } else if (selectedOption === 'average') {
                            value = channelData.reduce((a, b) => a + b, 0) / channelData.length
                        }

                        singleTimeSeriesData.plotData.push(value)
                    }
                }
            })

            graphDataToPlot.push(singleTimeSeriesData)
        }

        return { graphDataLabels, graphDataToPlot}
    }

    useEffect(() => {
        groupByLapCorner()
    }, [timeSeriesList, graphData])


    return (
        chartData && <Bar data={chartData} options={chartOptions} />
    )
}

export default TimeSeriesChartBar